import { useEffect } from 'react';
import { styled, Container, ContainerProps } from '@mui/material';

import Loader from '../../../components/Loader';

import { getBackgroundLinearGradient } from '../../../app/utils/helpers';

interface IProps extends ContainerProps {
  loading?: boolean;
}

const Wrapper = ({ loading = false, children, ...props }: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledContainer {...props}>
      {children}
      <Loader loading={loading} />
    </StyledContainer>
  );
};

export default Wrapper;

const opacityBreakpoints = ['14', '08', '02'];

const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: 'calc(100vh - 56px)',
  [theme.breakpoints.up('mobile')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 11)}`,
    paddingTop: 24,
  },
  [theme.breakpoints.up('tablet')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 10)}`,
    paddingTop: 36,
  },
  [theme.breakpoints.up('desktop')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 9)}`,
    paddingTop: 48,
  },
  paddingBottom: 48,
}));
