import { useState, useEffect, useMemo } from 'react';
import { Typography, Tooltip, IconButton, Grid, Container } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import useLocale from '../../../app/hooks/useLocale';

import moment from '../../../app/utils/dateTimeHelpers';

interface IProps {
  startCountdownAt?: number;
  onCountdownEnd?: () => void;
  countdownStartedAt?: moment.Moment;
}

const Countdown = ({ startCountdownAt = 0, onCountdownEnd, countdownStartedAt }: IProps) => {
  const { t } = useLocale();

  const initialTimeRemaining = useMemo(() => startCountdownAt * 60, [startCountdownAt]);

  const [timeRemaining, setTimeRemaining] = useState(
    countdownStartedAt === undefined
      ? initialTimeRemaining
      : initialTimeRemaining - moment().diff(countdownStartedAt, 'seconds') > 0
      ? initialTimeRemaining - moment().diff(countdownStartedAt, 'seconds')
      : 0
  );

  useEffect(() => {
    if (timeRemaining === 0 && onCountdownEnd) {
      onCountdownEnd();
    }
  }, [timeRemaining, onCountdownEnd]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(intervalId);
        }

        return prevTime > 0 ? prevTime - 1 : prevTime;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const timerText = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

  return (
    <Container maxWidth="mobile" sx={{ paddingTop: 2 }}>
      <Grid container flexDirection="row" alignItems="center">
        <Grid item flex={1} sx={{ textAlign: 'right' }}>
          <Tooltip title={t.TIMER_TOOLTIP} data-testid="tooltip" enterTouchDelay={0} leaveTouchDelay={5000}>
            <IconButton>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="body2">{t.TIME_REMAINING}:</Typography>
        </Grid>
        <Grid flex={1} sx={{ paddingLeft: 1 }}>
          <Typography variant="body2" data-testid="countdown-timer">
            {timerText}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Countdown;
