import { IAppointmentsSearchParams } from './types';

export const argumentifyAppointmentsSearchParams = ({
  isAllAppointments,
  appointmentStatusEnums,
  appointmentTypeEnums,
  startDateTimeBefore,
  startDateTimeAfter,
  sorting,
  skipCount,
  maxResultCount,
}: IAppointmentsSearchParams) =>
  [
    isAllAppointments,
    appointmentStatusEnums,
    appointmentTypeEnums,
    startDateTimeBefore,
    startDateTimeAfter,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;
