import { identityClientConfig } from '../config/identityClientConfig';
import { ILocale } from '../providers/LocaleProvider/types';

/**
 * Base api service, works for inject local value (works for multiple languages support)
 * and oauth single sign on access token into the http request header.
 * Note: This is not auto generated code.
 */
export class BaseService {
  protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
    //
    // Selected language preference stored in local storage key called "locale".
    //
    let locale = localStorage.getItem('locale');
    if (locale) locale = JSON.parse(locale);
    const bearerToken = this.getAccessTokenHeader();

    if (bearerToken) {
      //
      // For authenticated user, inject access token to http request header.
      //
      options.headers = {
        ...options.headers,
        Authorization: `${bearerToken}`,
        'accept-language': locale === ILocale.fr ? ILocale.fr : ILocale.en,
        'Content-Security-Policy': "frame-ancestors 'none'",
      };
    } else {
      //
      // For no authenticated access, no access token injected.
      //
      options.headers = {
        ...options.headers,
        'accept-language': locale === ILocale.fr ? ILocale.fr : ILocale.en,
        'Content-Security-Policy': "frame-ancestors 'none'",
      };
    }
    return Promise.resolve(options);
  };

  /**
   * Corporate with http const to inject access_token to http request header.
   * Work for ABP single sign on.
   * @returns Bearer token header string.
   */
  getAccessTokenHeader = () => {
    if (sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`)) {
      const oidc = JSON.parse(
        /**
         * Note: It is OIDC-Client library default storage.
         * The key format is defined by oidc-client library.
         * Note: Developer no needs to modify the session key.
         * */
        sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`) ??
          ''
      );
      if (!!oidc && !!oidc.access_token && !oidc.expired) {
        return `Bearer ${oidc.access_token}`;
      } else return '';
    } else return '';
  };
}
