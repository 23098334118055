import { useMemo } from 'react';
import { Grid, Typography, Link, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppointmentDetails from '../../../components/AppointmentDetails/AppointmentDetails';
import NoAppointmentsCard from '../../../components/NoAppointmentsCard/NoAppointmentsCard';

import useLocale from '../../../app/hooks/useLocale';

import { useUpcomingAppointmentsQuery } from '../queries';
import { ROUTES } from '../../../app/routes';

const UpcomingAppointmentsCard = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const { data: upcomingAppointments } = useUpcomingAppointmentsQuery({});

  const firstUpcomingAppointment = useMemo(
    () =>
      upcomingAppointments?.items?.sort((a, b) =>
        a?.startDateTimeLocal?.isAfter(b?.startDateTimeLocal) ? 1 : -1
      )?.[0],
    [upcomingAppointments?.items]
  );

  if (!firstUpcomingAppointment) {
    return <NoAppointmentsCard hasViewAllButton />;
  }

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <Grid container pb={3}>
          <Grid item mobile>
            <Typography variant="h6" fontWeight={600} noWrap={false} aria-label={t.UPCOMING_APPOINTMENTS}>
              {t.UPCOMING_APPOINTMENTS}
            </Typography>
          </Grid>
          <Grid item mobile="auto">
            <Link
              component="button"
              sx={{ fontSize: 16, fontWeight: 600 }}
              noWrap
              onClick={() => navigate(ROUTES.APPOINTMENTS)}
            >
              {t.VIEW_ALL}
            </Link>
          </Grid>
        </Grid>
        <AppointmentDetails {...firstUpcomingAppointment} />
      </Stack>
    </>
  );
};

export default UpcomingAppointmentsCard;
