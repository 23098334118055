import { useState, useMemo, useCallback } from 'react';

import {
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  styled,
  ListItemProps,
  ListItemButton,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';

import { ILocale } from '../../../app/providers/LocaleProvider/types';

import useLocale from '../../../app/hooks/useLocale';

export type ILanguageMenuType = 'permanent-drawer' | 'app-drawer';

interface ILanguageMenuProps extends ListItemProps {
  handleMenuToggle?: () => void;
  type?: ILanguageMenuType;
}

const LanguageMenu = ({ handleMenuToggle, type = 'app-drawer', ...props }: ILanguageMenuProps) => {
  const { t, locale, setLocale } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleLanguageSelect = useCallback(
    (lang: ILocale) => {
      setLocale(lang);
      setAnchorEl(null);
      handleMenuToggle?.();
    },
    [handleMenuToggle, setLocale]
  );

  const languageMenu = useMemo(
    () => (
      <Menu
        id="basic-menu"
        data-testid="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleLanguageSelect(locale)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { width: type === 'app-drawer' ? 'auto' : 200 },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: type === 'app-drawer' ? 'right' : 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: type === 'app-drawer' ? 'right' : 'center' }}
      >
        <LanguageMenuItemText
          onClick={() => handleLanguageSelect(ILocale.en)}
          selected={locale === ILocale.en}
          data-testid="english-menu-item"
        >
          {t.ENGLISH}
        </LanguageMenuItemText>
        <LanguageMenuItemText
          onClick={() => handleLanguageSelect(ILocale.fr)}
          selected={locale === ILocale.fr}
          data-testid="french-menu-item"
        >
          {t.FRENCH}
        </LanguageMenuItemText>
      </Menu>
    ),
    [anchorEl, open, type, locale, t.ENGLISH, t.FRENCH, handleLanguageSelect]
  );

  const ListTextByType = type === 'app-drawer' ? AppDrawerTypeItemText : PermanentDrawerTypeItemText;

  const ListItemButtonByType = type === 'app-drawer' ? ListItemButton : PermanentDrawerTypeListItemButton;

  return (
    <ListItem {...props} sx={{ ...props.sx, width: { mobile: 'auto' }, p: 0 }}>
      <ListItemButtonByType data-testid="language-button" onClick={handleClick}>
        {type === 'permanent-drawer' && (
          <PermanentDrawerTypeListItemIcon>
            <LanguageIcon />
          </PermanentDrawerTypeListItemIcon>
        )}
        <ListTextByType data-testid="app-menu-locale">{locale === ILocale.en ? t.ENGLISH : t.FRENCH}</ListTextByType>
        {type === 'app-drawer' && (
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <KeyboardArrowDownIcon />
          </ListItemIcon>
        )}
      </ListItemButtonByType>
      {languageMenu}
    </ListItem>
  );
};

export default LanguageMenu;

const AppDrawerTypeItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: 20,
    [theme.breakpoints.up('desktop')]: {
      fontSize: 16,
    },
    textAlign: 'end',
    fontWeight: 600,
  },
}));

const PermanentDrawerTypeItemText = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: 16,
    fontWeight: 600,
  },

  '.MuiTypography-root': {
    fontWeight: 500,
  },
}));

const LanguageMenuItemText = styled(MenuItem)`
  font-size: 20px;
`;

const PermanentDrawerTypeListItemButton = styled(ListItemButton)(() => ({
  borderRadius: 4,
  padding: '8px 12px',
}));

const PermanentDrawerTypeListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.slate,
}));
