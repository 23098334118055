import { useMemo, useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import yup from '../../../../app/utils/customYup';

import { DatePickerField, TextField } from '../../../../components/FormFields';

import useLocale from '../../../../app/hooks/useLocale';

import { DATE_FORMAT } from '../../../../app/utils/dateTimeHelpers';
import { useUpdatePaymentDetailsMutation } from '../../queries';
import { IPaymentUpdateParams } from '../../types';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';

interface IRequestHoldForm {
  fileId?: string;
  data?: ClientPublicService.PADScheduleDto;
  onOk?: () => void;
}

type IFormValues = Pick<ClientPublicService.PADScheduleUpdatePublicDto, 'holdUntil' | 'holdReason'>;

const RequestHoldForm = ({ fileId, data, onOk }: IRequestHoldForm) => {
  const { t } = useLocale();

  const { mutate: updatePaymentDetails } = useUpdatePaymentDetailsMutation() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        holdUntil: yup
          .date()
          .typeError(t.INVALID_DATE)
          .min(new Date(), t.DATE_CANNOT_BE_IN_THE_PAST)
          .required(getRequiredByLabel(t.HOLD_UNTIL)),
        holdReason: yup.string().required(getRequiredByLabel(t.HOLD_REASON)),
      }),
    [getRequiredByLabel, t.DATE_CANNOT_BE_IN_THE_PAST, t.HOLD_REASON, t.HOLD_UNTIL, t.INVALID_DATE]
  );

  const handleHoldRequest = useCallback(
    (values?: IFormValues) => {
      const body = {
        id: data?.id as string,
        state: ClientPublicService.PADScheduleStateEnum.HoldRequested,
        ...values,
      } as ClientPublicService.IPADScheduleDto;

      updatePaymentDetails({ fileId, ...body } as IPaymentUpdateParams, {
        onSuccess: (data) => {
          if (data?.result === ClientPublicService.Result.Successful) {
            toast.success(t.HOLD_REQUESTED);
            onOk?.();
          } else {
            toast.error(data?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
          }
        },
        onError: () => {
          toast.error(t.SOMETHING_WENT_WRONG);
        },
      });
    },
    [data?.id, fileId, onOk, t.HOLD_REQUESTED, t.SOMETHING_WENT_WRONG, updatePaymentDetails]
  );

  return (
    <Formik
      initialValues={{
        holdUntil: undefined,
        holdReason: '',
      }}
      onSubmit={(_: IFormValues, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
    >
      {({ values, isValid }) => {
        return (
          <>
            <DatePickerField label={t.HOLD_UNTIL} format={DATE_FORMAT} name="holdUntil" required disablePast />

            <TextField label={t.HOLD_REASON} multiline rows={4} maxLength={1000} name="holdReason" required />

            <Grid container flexDirection="row" justifyContent="center">
              <Grid item>
                <Button variant="contained" fullWidth disabled={!isValid} onClick={() => handleHoldRequest(values)}>
                  {t.REQUEST_HOLD}
                </Button>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default RequestHoldForm;
