import { useMemo } from 'react';
import { List, ListSubheader, ListItem, ListItemText, Tooltip, Box, Typography, useTheme, Stack } from '@mui/material';
import { Info } from '@mui/icons-material';

import useLocale from '../../../app/hooks/useLocale';

interface ISupportingDocsInfoListProps {
  tooltip?: string | string[] | React.ReactElement;
  list?: string[];
}

const SupportDocsList = ({ tooltip, list }: ISupportingDocsInfoListProps) => {
  const { t } = useLocale();
  const theme = useTheme();

  const uniqueValues = useMemo(() => [...new Set(list)], [list]);

  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.lightBlue, p: 0.75 }}>
        <Typography variant="body2" fontWeight={600}>
          {t.IF_YOU_HAVE_YOUR_DOCUMENTS_READY_CLICK_ON_THE_UPLOAD_BUTTON}
        </Typography>
      </Box>

      <List
        sx={{
          '.MuiListItemText-primary': {
            fontSize: 14,
          },
          '.MuiListItem-root': {
            pt: 0,
            pb: 0,
          },
        }}
      >
        <ListSubheader disableSticky sx={{ display: 'flex', alignItems: 'center', pl: 0, gap: 1 }}>
          {tooltip && (
            <Tooltip
              title={
                Array.isArray(tooltip) ? (
                  <Stack spacing={2}>
                    {tooltip?.map((item, index) => (
                      <Box key={index}>{item}</Box>
                    ))}
                  </Stack>
                ) : (
                  tooltip
                )
              }
              enterTouchDelay={0}
              leaveTouchDelay={5000}
            >
              <Info />
            </Tooltip>
          )}
          {t.SUPPORTING_DOCUMENTS_NEEDED}
        </ListSubheader>

        {uniqueValues?.map((item, index) => (
          <ListItem key={String(index)} sx={{ display: 'list-item', pl: 3, pr: 0 }}>
            <ListItemText
              primary={`${index + 1}. ${item}`}
              sx={{
                '.MuiListItemText-primary': {
                  fontSize: 14,
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SupportDocsList;
