import { useRef, useEffect, useCallback, useState } from 'react';

import GoogleMapsLoaderWrapper from './GoogleMapsLoaderWrapper';
import TargetPin from '../../../app/assets/images/pins/target-pin.svg';

import { IOfficeLocationGoogleMap } from '../types';
import { getPinByIndexNumber } from '../utils';

const DEFAULT_LAT = 43.653225;
const DEFAULT_LNG = -79.383186;

export interface IProps {
  officesNearby?: IOfficeLocationGoogleMap[];
  userCoordinates?: google.maps.LatLngLiteral;
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  height?: number;
}

const OfficesMapComponent = ({ center, zoom, officesNearby, userCoordinates, height }: IProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>();

  const invalidateMarkers = useCallback((markers?: google.maps.Marker[]) => {
    markers?.forEach((marker) => {
      if (marker) {
        marker.setMap(null);
      }
    });
  }, []);

  const fitBoundsToShowAllMarkers = useCallback(
    (markers?: google.maps.Marker[]) => {
      if (!markers || !map) return;

      const bounds = new google.maps.LatLngBounds();
      markers?.forEach((marker) => {
        bounds.extend(marker?.getPosition() as google.maps.LatLng);
      });

      map.fitBounds(bounds);
    },
    [map]
  );

  const placeMarkersOnMap = useCallback(() => {
    if (!map) return;

    const markers = officesNearby?.map(
      (item, index) =>
        new google.maps.Marker({
          position: item?.position,
          map,
          icon: getPinByIndexNumber(index),
        })
    );

    markers?.push(
      new google.maps.Marker({
        position: userCoordinates,
        map,
        icon: TargetPin,
      })
    );

    setMarkers(markers);
    fitBoundsToShowAllMarkers(markers);
  }, [fitBoundsToShowAllMarkers, map, officesNearby, userCoordinates]);

  useEffect(() => {
    if (ref?.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (ref?.current && map) {
      map.setOptions({
        center: center || { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
        zoom: 16,
        mapTypeControl: false,
      });
    }
  }, [center, zoom, map, ref]);

  useEffect(() => {
    placeMarkersOnMap();
  }, [placeMarkersOnMap]);

  useEffect(() => {
    return () => invalidateMarkers(markers);
  }, [invalidateMarkers, markers]);

  return (
    <div ref={ref} id="map" data-testid="map" style={{ width: '100%', height, borderRadius: 6, overflow: 'hidden' }} />
  );
};

const OfficesMap = (props: IProps) => <GoogleMapsLoaderWrapper component={<OfficesMapComponent {...props} />} />;

export default OfficesMap;
