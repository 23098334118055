import { handlers as bookAppointmentHandlers } from '../../features/BookAppointment/handlers';
import { handlers as sharedComponentsHandlers } from '../../components/handlers';
import { handlers as assetsDebtsHandlers } from '../../features/AppForm/modules/AssetsDebts/handlers';
import { handlers as appFormHandlers } from '../../features/AppForm/handlers';

export const handlers = [
  ...bookAppointmentHandlers,
  ...sharedComponentsHandlers,
  ...assetsDebtsHandlers,
  ...appFormHandlers,
];
