import { Stack } from '@mui/material';

import PageContentContainer from '../../components/PageContentContainer';
import PreviousList from './components/PreviousList';
import UpcomingList from './components/UpcomingList';

import useLocale from '../../app/hooks/useLocale';

const Appointments = () => {
  const { t } = useLocale();

  return (
    <PageContentContainer headerProps={{ title: t.MY_APPOINTMENTS }}>
      <Stack>
        <UpcomingList />
        <PreviousList />
      </Stack>
    </PageContentContainer>
  );
};

export default Appointments;
