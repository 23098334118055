import { useContext } from 'react';
import { IconButton, Stack, Box, Typography, styled } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { EditExpenseContext } from './EditExpenseProvider';

const EditExpenseHeader = () => {
  const { currentTab, tabsCount, goToNextTab, goToPreviousTab } = useContext(EditExpenseContext);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" data-testid="expense-header">
      <Ruler
        disabled={currentTab?.orderNumber === 0}
        onClick={goToPreviousTab}
        data-testid="back-button"
        aria-label="Back"
      >
        <ArrowBack />
      </Ruler>

      <Box alignItems="center" display="flex">
        <Typography textAlign="center" variant="body1" fontWeight={600}>
          {currentTab?.name}
        </Typography>
      </Box>

      <Ruler
        disabled={currentTab?.orderNumber === tabsCount - 1}
        onClick={goToNextTab}
        data-testid="next-button"
        aria-label="Next"
      >
        <ArrowForward />
      </Ruler>
    </Stack>
  );
};

export default EditExpenseHeader;

const Ruler = styled(IconButton)(({ theme, disabled }) => ({
  borderRadius: 8,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: disabled ? theme.palette.disabledGray : theme.palette.error.main,
  color: theme.palette.error.main,
  padding: 6,
}));
