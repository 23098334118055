import { Typography, useTheme, Skeleton } from '@mui/material';

import ElevatedContainer from '../../../components/ElevatedContainer';

interface IProps {
  title: string;
  content?: Array<string | undefined>;
}

const ConfirmationBlock = ({ title, content }: IProps) => {
  const theme = useTheme();

  return (
    <ElevatedContainer>
      <Typography variant="body3" color={theme.palette.secondary.main} fontWeight={600}>
        {title}
      </Typography>

      {content?.map((item, i) => (
        <Typography variant="body2" key={i} fontWeight={600}>
          {item?.trim() || <Skeleton />}
        </Typography>
      ))}
    </ElevatedContainer>
  );
};

export default ConfirmationBlock;
