import { useMutation, useQuery } from '@tanstack/react-query';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import {
  APPOINTEMENT_TYPES_QUERY_KEY,
  COUNTRIES_QUERY_KEY,
  FILTER_USERS_QUERY_KEY,
  GOOGLE_API_QUERY_KEY,
  MEETING_TYPES_QUERY_KEY,
  PHONE_TYPES_QUERY_KEY,
  PROVINCES_QUERY_KEY,
  RECAPTCHA_TOKEN_VALIDATION_MUTATION,
  REFERRAL_SOURCES_QUERY_KEY,
  TIMESLOTS_QUERY_KEY,
  APPOINTMENT_GET_QUERY_KEY,
  UPCOMING_APPOINTMENTS_GET_QUERY_KEY,
  PREVIOUS_APPOINTMENTS_GET_QUERY_KEY,
  APPOINTMENTS_GET_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import {
  argumentifyFilterUsersParams,
  argumentifyInitialTimeslotsSearchParams,
  argumentifyLocationSearchParams,
  argumentifyTimeslotsSearchParams,
  argumentifyUnreserveParams,
} from './utils';
import { IFilterUsersParams, ITimeslotsSearchParams, IUnreserveParams } from './types';
import { queryClient } from '../../app/config';

import API from '../../app/api/api';

export const useGoogleApiQuery = () =>
  useQuery({
    queryKey: [GOOGLE_API_QUERY_KEY],
    queryFn: async () => {
      return await API.getGoogleApiKey();
    },
  });

export const usePhoneTypesQuery = () =>
  useQuery({
    queryKey: [PHONE_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listPhoneTypes();
    },
  });

export const useReferralSourcesQuery = () =>
  useQuery({
    queryKey: [REFERRAL_SOURCES_QUERY_KEY],
    queryFn: async () => {
      return await API.listReferralSourceTypes(true);
    },
  });

export const useMeetingTypesQuery = () =>
  useQuery({
    queryKey: [MEETING_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listMeetingTypes();
    },
  });

export const useAppointmentTypesQuery = () =>
  useQuery({
    queryKey: [APPOINTEMENT_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listAppointmentTypes(undefined);
    },
  });

export const useAppointmentQuery = (appointmentId?: string) =>
  useQuery({
    queryKey: [APPOINTMENT_GET_QUERY_KEY, appointmentId],
    queryFn: async () => {
      const data = await API.getAppointmentInfo(appointmentId);
      return data;
    },
    enabled: !!appointmentId,
  });

export const useProvincesQuery = (countryId?: string) =>
  useQuery({
    queryKey: [PROVINCES_QUERY_KEY, countryId],
    queryFn: async () => {
      return await API.listProvinces(countryId);
    },
  });

export const useCountriesQuery = () =>
  useQuery({
    queryKey: [COUNTRIES_QUERY_KEY],
    queryFn: async () => {
      return await API.listCountries();
    },
  });

export const useFilterUsersQuery = (requestParams: IFilterUsersParams) =>
  useQuery({
    queryKey: [FILTER_USERS_QUERY_KEY],
    queryFn: async () => {
      return await API.filterUsers(...argumentifyFilterUsersParams(requestParams)).catch(() => []);
    },
  });

export const useCreateAppointmentMutation = () =>
  useMutation<
    ClientPublicService.SaveResult,
    ClientPublicService.RemoteServiceErrorResponse,
    ClientPublicService.IBookInitialAppointmenInputPublicDto,
    unknown
  >({
    mutationFn: (values: ClientPublicService.IBookInitialAppointmenInputPublicDto) => {
      return API.createAppointmentAndFile(values as ClientPublicService.BookInitialAppointmenInputPublicDto);
    },
  });

export const useCancelAppointmentMutation = () =>
  useMutation({
    mutationFn: (appointmentId?: string) => {
      return API.cancelAppointment(appointmentId);
    },
  });

export const useTimeslotsQuery = (requestParams: ITimeslotsSearchParams, agentId?: string) =>
  useQuery({
    queryKey: [
      TIMESLOTS_QUERY_KEY,
      requestParams?.officeLocationId,
      requestParams?.isPreferedLanguageEnglish,
      requestParams?.meetingTypeEnum,
      requestParams?.date,
    ],
    queryFn: async () => {
      const response = requestParams?.date
        ? await API.getAvailableTimeSlots(...argumentifyTimeslotsSearchParams(requestParams))
        : await API.getInitialAppointmentTimeSlots(...argumentifyInitialTimeslotsSearchParams(requestParams));

      return { ...response, isInitial: !requestParams?.date };
    },
    enabled:
      !!requestParams?.officeLocationId &&
      requestParams?.isPreferedLanguageEnglish !== undefined &&
      requestParams?.meetingTypeEnum !== undefined &&
      !agentId, // do not fetch timeslots if previous timeslot is not unreserved

    refetchOnWindowFocus: false,
  });

export const useLocationsMutation = () =>
  useMutation({
    mutationFn: (requestParams?: google.maps.LatLngLiteral) => {
      return API.officeLocations(
        ...argumentifyLocationSearchParams({ nearbyLatitude: requestParams?.lat, nearbyLongitude: requestParams?.lng })
      );
    },
  });

export const useReserveAppointmentMutation = () =>
  useMutation({
    mutationFn: (requestParams: ClientPublicService.IReserveAppointmentDto) =>
      API.reserveAppointment(requestParams as ClientPublicService.ReserveAppointmentDto),
  });

export const useBookAppointmentMutation = () =>
  useMutation({
    mutationFn: (requestParams: ClientPublicService.IBookAppointmentInputPublicDto) =>
      API.bookAppointment(requestParams as ClientPublicService.BookAppointmentInputPublicDto),
  });

export const useUnreserveAppointmentMutation = () =>
  useMutation({
    mutationFn: (requestParams: IUnreserveParams) =>
      API.unreserveAppointment(...argumentifyUnreserveParams(requestParams)),
  });

export const useRecaptchaTokenValidationMutation = () =>
  useMutation({
    mutationFn: async (token: string | null) =>
      await API.verifyReCaptchaToken(new ClientPublicService.VerifyReCaptchaTokenDto({ token: token || '' })),
    mutationKey: [RECAPTCHA_TOKEN_VALIDATION_MUTATION],
  });

export const useAppointmentByIdMutation = () =>
  useMutation({
    mutationFn: (appointmentId?: string) => API.getAppointmentInfo(appointmentId),
  });

export const useRescheduleAppountmentMutation = () =>
  useMutation({
    mutationFn: (body?: ClientPublicService.UpdateAppointmentPublicDto) => API.updateAppointment(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UPCOMING_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PREVIOUS_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APPOINTMENTS_GET_QUERY_KEY] });
    },
  });
