import { createContext, useState, ReactElement } from 'react';

interface IAccordionContext {
  expanded: string | false;
  setExpanded: (expanded: string | false) => void;
  showErrorsList: boolean;
  setShowErrorsList: (showErrorsList: boolean) => void;
}

export const initialAccordionContextValues: IAccordionContext = {
  expanded: false,
  setExpanded: () => {},
  showErrorsList: false,
  setShowErrorsList: () => {},
};

export const AccordionContext = createContext<IAccordionContext>(initialAccordionContextValues);

const AccordionProvider = ({ children }: any): ReactElement => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showErrorsList, setShowErrorsList] = useState<boolean>(false);

  return (
    <AccordionContext.Provider
      value={{
        expanded,
        setExpanded,
        showErrorsList,
        setShowErrorsList,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

export default AccordionProvider;
