import { Grid, useMediaQuery, useTheme } from '@mui/material';

import ElevatedContainer from '../../../components/ElevatedContainer';

interface IResponsiveGridLayoutProps {
  items: React.ReactElement[];
  fullHeight?: boolean;
}

const ResponsiveGridLayoutPostSignup = ({ items, fullHeight = true }: IResponsiveGridLayoutProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  return (
    <Grid
      container
      columns={{
        mobile: 1,
        desktop: 2,
      }}
      spacing={2}
      sx={{ maxWidth: 800 + 36, pb: 4 }}
    >
      {items?.map((item, index) => (
        <Grid item mobile={1} tablet={1} desktop={1} key={`responsive-grid-item-${index}`}>
          <ElevatedContainer
            sx={{
              borderRadius: 1.5,
              m: 0,
              height: fullHeight ? '100%' : 'auto',
              pt: 3,
              pb: 3,
              maxWidth: isDesktop ? 400 : 'auto',
            }}
          >
            {item}
          </ElevatedContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default ResponsiveGridLayoutPostSignup;
