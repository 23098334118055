import { useCallback, useMemo } from 'react';
import { Stack, useTheme, Grid, useMediaQuery, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableCard from '../../../../components/TableCard';
import SummaryCard from '../../../../components/SummaryCard';
import EditAssetModal from './components/EditAssetModal/EditAssetModal';
import EditDebtModal from './components/EditDebtModal/EditDebtModal';
import ElevatedContainer from '../../../../components/ElevatedContainer';
import LabelWithError from '../../../../components/LabelWithError';
import AmountCellWithDeleteAction from '../../../../components/AmountCellWithDeleteAction';

import useLocale from '../../../../app/hooks/useLocale';
import useModal from '../../../../app/hooks/useModal';
import useAppForm from '../../hooks/useAppForm';

import { ROUTES } from '../../../../app/routes/constants';
import { useListAssetsQuery, useListDebtsQuery, useDeleteAssetMutation, useDeleteDebtMutation } from './queries';
import { useApplicationFileQuery } from '../../queries';
import { IAssetDescription } from './types';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import SectionLockMessage from '../../components/SectionLockMessage';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';
import NavigationButtons from '../../components/NavigationButtons';

const AssetsDebts = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const navigate = useNavigate();

  const { showModal, closeModal } = useModal();
  const { updateAppFormStatus, appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.assetsDebtsStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.assetsDebtsStatus]
  );

  const { data: appFile } = useApplicationFileQuery() || {};

  const { data: assets } = useListAssetsQuery(appFile?.id) || {};

  const { data: debts } = useListDebtsQuery(appFile?.id) || {};

  const { mutate: deleteAsset } = useDeleteAssetMutation() || {};

  const { mutate: deleteDebt } = useDeleteDebtMutation() || {};

  const totalAssets = useMemo(
    () => assets?.map((item) => item?.value || 0).reduce((sum, cur) => sum + cur, 0),
    [assets]
  );
  const totalDebts = useMemo(() => debts?.map((item) => item?.amount || 0).reduce((sum, cur) => sum + cur, 0), [debts]);
  const totalUnsecuredDebts = useMemo(
    () => debts?.reduce((sum, cur) => sum + (cur?.isSecured ? 0 : cur?.amount || 0), 0),
    [debts]
  );
  const totalSecuredDebts = useMemo(
    () => debts?.reduce((sum, cur) => sum + (!cur?.isSecured ? 0 : cur?.amount || 0), 0),
    [debts]
  );

  const handleEditDebtButtonClick = useCallback(
    (debt?: ClientPublicService.IAppFormAssetsDebtsDebtListItem) => {
      showModal(
        <EditDebtModal
          debtId={debt?.id}
          onOk={() => {
            closeModal();
            updateAppFormStatus?.();
          }}
          onCancel={closeModal}
          isLocked={isLocked}
        />
      );
    },
    [closeModal, isLocked, showModal, updateAppFormStatus]
  );

  const handleEditAssetButtonClick = useCallback(
    (asset?: ClientPublicService.IAppFormAssetsDebtsAssetListItem) => {
      showModal(
        <EditAssetModal
          assetId={asset?.id}
          onOk={() => {
            closeModal();
            updateAppFormStatus?.();
          }}
          onCancel={closeModal}
          isMissingDetails={asset?.isMissingDetails}
          isMissingDocuments={asset?.isMissingSupportingDocuments}
          isLocked={isLocked}
        />
      );
    },
    [closeModal, isLocked, showModal, updateAppFormStatus]
  );

  const assetsColumns = [
    {
      title: t.DESCRIPTION,
      key: 'assetDescription',
      dataIndex: 'assetDescription',
      render: (item: IAssetDescription, record: ClientPublicService.IAppFormAssetsDebtsAssetListItem) => (
        <LabelWithError
          label={`${getLocalizedDtoName(item)} ${
            record?.specifiedDescription ? `- ${record?.specifiedDescription}` : ''
          }`}
          hasError={record?.isMissingDetails || record?.isMissingSupportingDocuments}
        />
      ),
    },
    {
      title: t.VALUE,
      key: 'value',
      dataIndex: 'value',
      render: (value: number, record: ClientPublicService.IAppFormAssetsDebtsAssetListItem) => (
        <AmountCellWithDeleteAction
          value={value}
          onDelete={() =>
            deleteAsset(record?.id as string, {
              onSuccess: updateAppFormStatus,
            })
          }
          disabled={isLocked}
        />
      ),
      width: '40%',
    },
  ];

  const debtsColumns = [
    {
      title: t.DESCRIPTION,
      key: 'creditorName',
      dataIndex: 'creditorName',
      render: (item: string, record: ClientPublicService.IAppFormAssetsDebtsDebtListItem) => (
        <Stack>
          <div>{getLocalizedDtoName(record?.debtDescription)}</div>
          <div>{item}</div>
        </Stack>
      ),
    },
    {
      title: t.VALUE,
      key: 'amount',
      dataIndex: 'amount',
      render: (value: number, record: ClientPublicService.IAppFormAssetsDebtsDebtListItem) => (
        <AmountCellWithDeleteAction
          value={value}
          onDelete={() =>
            deleteDebt(record?.id as string, {
              onSuccess: updateAppFormStatus,
            })
          }
          disabled={isLocked}
        />
      ),
      width: '40%',
    },
  ];

  const Container = isDesktop ? ElevatedContainer : Box;

  return (
    <>
      {isLocked && <SectionLockMessage />}

      <Grid container rowSpacing={{ mobile: 2.5, desktop: 0 }} columnSpacing={{ mobile: 0, desktop: 2 }}>
        <Grid item mobile={12} desktop={6}>
          <Container sx={isDesktop ? { borderRadius: 1.5 } : {}}>
            <Stack spacing={1} data-testid="assets">
              <SummaryCard
                items={[
                  {
                    label: t.TOTAL_ASSETS,
                    value: totalAssets,
                    labelColor: 'green',
                  },
                ]}
              />
              <TableCard
                title={t.ASSETS}
                hasAddButton
                onAddButtonClick={() => handleEditAssetButtonClick()}
                isAddButtonDisabled={isLocked}
                tableProps={{
                  data: assets,
                  columns: assetsColumns,
                  emptyValue: t.CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_ASSETS,
                  onRowClick: handleEditAssetButtonClick,
                }}
              />
            </Stack>
          </Container>
        </Grid>
        <Grid item mobile={12} desktop={6}>
          <Container sx={isDesktop ? { borderRadius: 1.5 } : {}}>
            <Stack spacing={1} data-testid="debts">
              <SummaryCard
                items={[
                  {
                    label: t.TOTAL_DEBTS,
                    value: totalDebts,
                    labelColor: 'red',
                  },
                  {
                    label: t.TOTAL_UNSECURED,
                    value: totalUnsecuredDebts,
                    labelColor: 'red',
                  },
                  {
                    label: t.TOTAL_SECURED,
                    value: totalSecuredDebts,
                    labelColor: 'red',
                  },
                ]}
              />
              <TableCard
                title={t.DEBTS}
                hasAddButton
                onAddButtonClick={() => handleEditDebtButtonClick()}
                isAddButtonDisabled={isLocked}
                tableProps={{
                  data: debts,
                  columns: debtsColumns,
                  emptyValue: t.CLICK_ON_THE_ADD_BUTTON_ABOVE_TO_ADD_YOUR_DEBTS,
                  onRowClick: handleEditDebtButtonClick,
                }}
              />
            </Stack>
          </Container>
        </Grid>
      </Grid>
      <NavigationButtons
        onNext={() => {
          if (!isLocked) toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.ASSETS_DEBTS));
          navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_EXPENSE}`);
        }}
        onPrev={() => {
          navigate(`${ROUTES.APP_FORM}/${ROUTES.PERSONAL_INFORMATION}`);
        }}
        currentSection={AppformSection.AssetDebts}
        disabled={isLocked}
      />
    </>
  );
};

export default AssetsDebts;
