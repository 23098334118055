import { useCallback, useState, useMemo, useEffect } from 'react';
import { Box, useTheme, useMediaQuery, Stack, Typography, Link } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { toast } from 'react-toastify';

import CustomTable, { IColumnType } from '../../../components/CustomTable';
import ListLayout from '../../../components/ListLayout/ListLayout';
import UploadDetailsModal from './UploadDetailsModal';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';

import { useSupportingDocumentsQuery, useDebtorProfileQuery } from '../queries';
import { SupportingDocumentsResponse, ISupportingDocumentsSearchParams } from '../types';
import { ClientPublicService } from '../../../app/api/ClientPublicService';
import { BLACK_SCREEN_SESSION_STORAGE_KEY } from '../../../app/constants/common';
import { isDeviceiOS } from '../../../app/utils/helpers';

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SEARCH_PARAMS: ISupportingDocumentsSearchParams = {
  skipCount: 0,
  maxResultCount: DEFAULT_PAGE_SIZE,
  sorting: 'documentName asc',
};

const Outstanding = () => {
  const { t, getLocalizedDocumentName, locale } = useLocale();
  const theme = useTheme();
  const { showModal, closeModal } = useModal();
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const isFileStagePostSignup = useMemo(
    () => (debtorProfile?.profile ? debtorProfile?.profile?.isFileStagePostSignup : undefined),
    [debtorProfile?.profile]
  );

  const [searchParams, setSearchParams] = useState<ISupportingDocumentsSearchParams>(DEFAULT_SEARCH_PARAMS);

  const [prevPageDocuments, setPrevPageDocuments] = useState<SupportingDocumentsResponse>();

  const { data: currentPageDocuments, refetch } = useSupportingDocumentsQuery(searchParams) || {};

  const documents = useMemo(() => currentPageDocuments || prevPageDocuments, [currentPageDocuments, prevPageDocuments]);

  const handleSort = useCallback((column: any, sortDirection: any) => {
    setSearchParams((prev) => ({ ...prev, ...DEFAULT_SEARCH_PARAMS, sorting: `${column} ${sortDirection}` }));
  }, []);

  const handleWarningiOS = useCallback(() => {
    if (isDeviceiOS() && !sessionStorage.getItem(BLACK_SCREEN_SESSION_STORAGE_KEY)) {
      toast.warning(t.BLACK_SCREEN_WARNING);
      sessionStorage.setItem(BLACK_SCREEN_SESSION_STORAGE_KEY, 'true');
    }
  }, [t.BLACK_SCREEN_WARNING]);

  const handleUpload = useCallback(
    (supportingDocumentDetails?: ClientPublicService.SupportingDocumentDto) => {
      handleWarningiOS();

      showModal(
        <UploadDetailsModal
          fileId={debtorProfile?.profile?.fileId as string}
          supportingDocumentDetails={supportingDocumentDetails}
          onOk={closeModal}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, debtorProfile?.profile?.fileId, handleWarningiOS, showModal]
  );

  const isSpouseSpecificUpload = useCallback(
    (supportingDocumentType?: ClientPublicService.SupportingDocumentType) =>
      supportingDocumentType?.enumValue === ClientPublicService.SupportingDocumentTypeEnum.ID_Spouse ||
      supportingDocumentType?.enumValue === ClientPublicService.SupportingDocumentTypeEnum.IncomeTax_Spouse,
    []
  );

  const isApplicantSpecificUpload = useCallback(
    (supportingDocumentType?: ClientPublicService.SupportingDocumentType) =>
      supportingDocumentType?.enumValue === ClientPublicService.SupportingDocumentTypeEnum.ID ||
      supportingDocumentType?.enumValue === ClientPublicService.SupportingDocumentTypeEnum.IncomeTax,
    []
  );

  const columns: IColumnType[] = [
    {
      title: t.DETAILS,
      dataIndex: 'detail',
      key: 'detail',
      width: '40%',
      render: (value?: string, record?: ClientPublicService.SupportingDocumentDto) =>
        isSpouseSpecificUpload(record?.supportingDocumentType) ||
        isApplicantSpecificUpload(record?.supportingDocumentType) ? (
          <>{`${value} - ${isSpouseSpecificUpload(record?.supportingDocumentType) ? t.SPOUSE : t.APPLICANT}`}</>
        ) : (
          value
        ),
    },
    {
      title: t.DOCUMENT_NAME,
      dataIndex: 'documentName',
      key: 'documentName',
      width: '40%',
      render: (_?: any, record?: ClientPublicService.SupportingDocumentDto) => (
        <>{getLocalizedDocumentName({ ...record, name: record?.documentName })}</>
      ),
    },

    {
      title: t.ACTION,
      dataIndex: 'action',
      key: 'action',
      sortable: false,
      render: (_: any, record?: ClientPublicService.SupportingDocumentDto) => (
        <Link
          component="button"
          onClick={() => handleUpload(record)}
          sx={{ color: theme.palette.cobalt, fontWeight: 600 }}
        >
          {t.UPLOAD}
        </Link>
      ),
    },
  ];

  const paginationProps = useMemo(
    () => ({
      page: (searchParams.skipCount || 0) / DEFAULT_PAGE_SIZE,
      rowsPerPage: DEFAULT_PAGE_SIZE,
      onPageChange: (_: any, newPage: number) => {
        setPrevPageDocuments(currentPageDocuments);
        setSearchParams((prev) => ({ ...prev, skipCount: newPage * DEFAULT_PAGE_SIZE }));
      },
      count: documents?.totalCount || 0,
    }),
    [searchParams.skipCount, currentPageDocuments, documents?.totalCount]
  );

  useEffect(() => {
    if (locale) {
      refetch(); // this refetch is needed to get a translated detail prop of SupportingDocumentDto using request language header
    }
  }, [locale, refetch]);

  if (!documents?.totalCount || isFileStagePostSignup) {
    return <></>;
  }

  return (
    <>
      <Stack mb={4}>
        <Typography
          variant="body3"
          fontWeight={600}
          sx={{ mb: { mobile: 2, desktop: 0 }, color: theme.palette.urgentRed }}
        >
          {t.OUTSTANDING} ({documents?.totalCount || 0})
        </Typography>

        {isMobile ? (
          <Box data-testid="list-layout">
            <ListLayout
              items={documents?.items?.map((item) => ({
                id: item?.id as string,
                title: getLocalizedDocumentName({ ...item, name: item?.documentName }),
                subtitle:
                  isSpouseSpecificUpload(item?.supportingDocumentType) ||
                  isApplicantSpecificUpload(item?.supportingDocumentType) ? (
                    <>{`${item?.detail} - ${
                      isSpouseSpecificUpload(item?.supportingDocumentType) ? t.SPOUSE : t.APPLICANT
                    }`}</>
                  ) : (
                    item?.detail
                  ),
                actionButtonIcon: (
                  <UploadIcon style={{ color: theme.palette.slate, width: 40 }} fontSize="large" aria-label="Upload" />
                ),
                actionButtonProps: {
                  onClick: () => handleUpload(item),
                },
                primaryTextProps: {
                  sx: {
                    fontSize: `16px !important`,
                    fontWeight: 600,
                  },
                },
                secondaryTextProps: {
                  sx: {
                    fontSize: '13px !important',
                  },
                },
              }))}
              paginationProps={paginationProps}
            />
          </Box>
        ) : (
          <Box data-testid="table-layout">
            <CustomTable
              data={documents?.items}
              columns={columns}
              paginationProps={paginationProps}
              onColumnSort={handleSort}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Outstanding;
