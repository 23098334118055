import { useCallback, useMemo } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import { Button, Divider, Stack, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { SelectField, YesNoToggleField, TextField } from '../../../../../components/FormFields';
import { LocationFields } from '../../../../../components/FormFieldsPresets';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormQuestionnaireValues } from '../types';
import { getCoSignedOrGuaranteedLoan } from '../utils';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const CoSignsGuaranteedLoans = () => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  const newCoSignedOrGuaranteedLoanItem = useMemo(() => getCoSignedOrGuaranteedLoan(), []);

  const handleCoSignedOrGuaranteedLoansToggle = useCallback(
    (hasCoSignedOrGuaranteedLoan?: boolean) => {
      const noLoansRecords = !values?.coSignedOrGuaranteedLoans || values?.coSignedOrGuaranteedLoans?.length === 0;
      if (hasCoSignedOrGuaranteedLoan && noLoansRecords) {
        setFieldValue('coSignedOrGuaranteedLoans', [newCoSignedOrGuaranteedLoanItem]);
      }

      if (!hasCoSignedOrGuaranteedLoan) {
        setFieldValue('coSignedOrGuaranteedLoans', []);
      }
    },
    [newCoSignedOrGuaranteedLoanItem, setFieldValue, values?.coSignedOrGuaranteedLoans]
  );

  return (
    <>
      <AppFormSectionSubtitle>{t.DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM}</AppFormSectionSubtitle>

      <YesNoToggleField
        label={t.ANY_COSIGNED_OR_GUARANTEED_LOANS}
        name="hasCoSignedOrGuaranteedLoan"
        onChange={handleCoSignedOrGuaranteedLoansToggle}
        disabled={isLocked}
      />

      {values?.hasCoSignedOrGuaranteedLoan &&
        values?.coSignedOrGuaranteedLoans &&
        values?.coSignedOrGuaranteedLoans?.length > 0 && (
          <>
            <FieldArray name="coSignedOrGuaranteedLoans">
              {({ remove, insert }: ArrayHelpers) => (
                <>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  {values?.coSignedOrGuaranteedLoans?.map((_, index: number) => (
                    <Stack key={index}>
                      <SelectField
                        name={`coSignedOrGuaranteedLoans.${index}.isBySpouse`}
                        label={t.BY}
                        options={[
                          { id: 'applicant', name: t.APPLICANT },
                          { id: 'spouse', name: t.SPOUSE },
                        ]}
                        disabled={isLocked}
                      />

                      <TextField
                        name={`coSignedOrGuaranteedLoans.${index}.lendersName`}
                        label={t.LENDERS_NAME}
                        required
                        disabled={isLocked}
                        inputProps={{ maxLength: 100 }}
                      />

                      <LocationFields
                        presetType="short"
                        addressFieldName={`coSignedOrGuaranteedLoans.${index}.lendersAddress`}
                        addressFieldLabel={t.LENDERS_ADDRESS}
                        streetNameFieldName={`coSignedOrGuaranteedLoans.${index}.lendersStreetName`}
                        streetNumberFieldName={`coSignedOrGuaranteedLoans.${index}.lendersStreetNumber`}
                        unitFieldName={`coSignedOrGuaranteedLoans.${index}.lendersUnit`}
                        unitFieldLabel={t.LENDERS_ADRS_UNIT}
                        cityFieldName={`coSignedOrGuaranteedLoans.${index}.lendersCity`}
                        provinceIdFieldName={`coSignedOrGuaranteedLoans.${index}.lendersProvinceId`}
                        countryIdFieldName={`coSignedOrGuaranteedLoans.${index}.lendersCountryId`}
                        postalCodeFieldName={`coSignedOrGuaranteedLoans.${index}.lendersPostalCode`}
                        requiredFields={[`coSignedOrGuaranteedLoans.${index}.lendersAddress`]}
                        disabledFields={isLocked ? 'all' : undefined}
                      />

                      <TextField
                        name={`coSignedOrGuaranteedLoans.${index}.borrowersName`}
                        label={t.BORROWERS_NAME}
                        required
                        disabled={isLocked}
                        inputProps={{ maxLength: 100 }}
                      />

                      <LocationFields
                        presetType="short"
                        addressFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersAddress`}
                        addressFieldLabel={t.BORROWERS_ADDRESS}
                        streetNameFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersStreetName`}
                        streetNumberFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersStreetNumber`}
                        unitFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersUnit`}
                        unitFieldLabel={t.BORROWERS_ADDRESS_UNIT}
                        cityFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersCity`}
                        provinceIdFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersProvinceId`}
                        countryIdFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersCountryId`}
                        postalCodeFieldName={`coSignedOrGuaranteedLoans.${index}.borrowersPostalCode`}
                        requiredFields={[`coSignedOrGuaranteedLoans.${index}.borrowersAddress`]}
                        disabledFields={isLocked ? 'all' : undefined}
                      />

                      <IconButton
                        onClick={() => remove(index)}
                        disabled={values?.coSignedOrGuaranteedLoans?.length === 1 || isLocked}
                        sx={{ mt: 2, alignSelf: 'start' }}
                        color="error"
                        aria-label="Delete"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                    </Stack>
                  ))}

                  <Button
                    variant="text"
                    onClick={() =>
                      insert(values?.coSignedOrGuaranteedLoans?.length || 0, newCoSignedOrGuaranteedLoanItem)
                    }
                    disabled={isLocked}
                  >
                    {t.ADD_LOAN}
                  </Button>
                </>
              )}
            </FieldArray>
          </>
        )}
    </>
  );
};

export default CoSignsGuaranteedLoans;
