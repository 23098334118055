import { Typography, Box, styled } from '@mui/material';

import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export interface IDonutDiagramItemProps {
  color: string;
  value: number;
}

export interface IDonutDiagramProps {
  size?: number;
  thickness?: number;
  items?: IDonutDiagramItemProps[];
  innerText?: string;
  innerComponent?: React.ReactElement;
  strokeLinecap?: 'butt' | 'round';
  backgroundColor?: string;
}

const DonutDiagram = ({
  size = 100,
  thickness = 6,
  items = [],
  innerText,
  innerComponent,
  strokeLinecap = 'round',
  backgroundColor = '#9DA8B2',
}: IDonutDiagramProps) => {
  return (
    <Box sx={{ position: 'relative', width: size }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: backgroundColor,
        }}
        size={size}
        thickness={thickness}
        value={100}
        data-testid="figure"
      />
      {items?.map((item, index) => (
        <CircularProgress
          variant="determinate"
          key={index}
          sx={{
            color: item?.color,
            position: 'absolute',

            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap,
            },
            [`& svg`]: {
              transform: `rotate(${index > 0 ? (items?.[index - 1]?.value || 0) * 3.6 : 0}deg)`,
              transformOrigin: `50% 50%`,
            },
          }}
          size={size}
          thickness={thickness}
          value={item?.value}
        />
      ))}
      <InnerTextContainer>
        {innerComponent || (
          <Typography variant="body1" component="span" color="text.secondary" fontWeight={600}>
            {innerText}
          </Typography>
        )}
      </InnerTextContainer>
    </Box>
  );
};

export default DonutDiagram;

const InnerTextContainer = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.2em;
`;
