import { useEffect } from 'react';
import useAuthService from '../../hooks/useAuthService';
import { ROUTES } from '../../routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocale from '../../hooks/useLocale';
import { ILocale } from '../../providers/LocaleProvider/types';

const LoginRedirect = () => {
  const { signin, isAuthenticated } = useAuthService();
  const navigate = useNavigate();
  const { setLocale } = useLocale();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setLocale(searchParams.get('ui_locales') === 'fr' ? ILocale.fr : ILocale.en);

    if (isAuthenticated()) {
      navigate(ROUTES.DASHBOARD);
    } else {
      signin();
    }
  }, [signin, isAuthenticated, navigate, setLocale, searchParams]);

  return null;
};

export default LoginRedirect;
