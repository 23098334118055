import { useMemo, useContext } from 'react';

import PageContentContainer from '../../components/PageContentContainer';
import OnlineAppFormCard from './components/OnlineAppFormCard';
import MyDocumentsCard from './components/MyDocumentsCard';
import UpcomingAppointmentsCard from './components/UpcomingAppointmentsCard';
import ProgressAndStatusInitialStep from './components/ProgressAndStatusInitialStep';
import ProgressAndStatusInfo from './components/ProgressAndStatusInfo';
import PaymentsBankingCard from './components/PaymentBankingCard';
import ResponsiveGridLayout from '../../components/ResponsiveGridLayout';
import IncomeExpenseReportsCard from './components/IncomeExpenseReportsCard';
import FileDetailsCard from './components/FileDetailsCard';
import ResponsiveGridLayoutPostSignup from './components/ResponsiveGridLayoutPostSignup';

import useLocale from '../../app/hooks/useLocale';
import DashboardProvider, { DashboardContext } from './DashboardProvider';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const DashboardComponent = () => {
  const { t } = useLocale();

  const { debtorProfile, signupStatus, applicationFile } = useContext(DashboardContext);

  const isNotStarted = useMemo(
    () =>
      signupStatus
        ? signupStatus?.preSignupProgress === ClientPublicService.PreSignupProgressEnum.NotStarted
        : undefined,
    [signupStatus]
  );

  const isFileStagePostSignup = useMemo(
    () => (debtorProfile?.profile ? debtorProfile?.profile?.isFileStagePostSignup : undefined),
    [debtorProfile?.profile]
  );

  const isConsumerProposalInsolvencyType = useMemo(
    () =>
      applicationFile
        ? applicationFile?.filingTypeEnum === ClientPublicService.FilingTypeEnum.DivisionI ||
          applicationFile?.filingTypeEnum === ClientPublicService.FilingTypeEnum.DivisionII
        : undefined,
    [applicationFile]
  );

  const isPostSignupConsumerProposal = useMemo(
    () => isConsumerProposalInsolvencyType && isFileStagePostSignup,
    [isConsumerProposalInsolvencyType, isFileStagePostSignup]
  );

  const isPostSignupBankruptcy = useMemo(
    () => !isConsumerProposalInsolvencyType && isFileStagePostSignup,
    [isConsumerProposalInsolvencyType, isFileStagePostSignup]
  );

  const title = useMemo(() => {
    const firstName = debtorProfile?.profile?.firstName;
    const lastName = debtorProfile?.profile?.lastName;

    if (!firstName && !lastName) return t.WELCOME;

    return `${t.WELCOME}, ${firstName} ${lastName}`;
  }, [debtorProfile?.profile?.firstName, debtorProfile?.profile?.lastName, t.WELCOME]);

  const StageBasedLayout = isFileStagePostSignup ? ResponsiveGridLayoutPostSignup : ResponsiveGridLayout;

  if (
    isFileStagePostSignup === undefined ||
    isConsumerProposalInsolvencyType === undefined ||
    isNotStarted === undefined
  ) {
    return <PageContentContainer headerProps={{ hasBackButton: false }} />;
  }

  return (
    <PageContentContainer headerProps={{ title, hasBackButton: false, titleProps: { sx: { fontSize: 18 } } }}>
      <StageBasedLayout
        items={
          isPostSignupConsumerProposal
            ? [
                <PaymentsBankingCard key="payments-banking-card" />,
                <FileDetailsCard key="file-details-card" />,
                <UpcomingAppointmentsCard key="upcoming-appointments-card" />,
                <MyDocumentsCard key="my-documents-card" />,
              ]
            : isPostSignupBankruptcy
            ? [
                <PaymentsBankingCard key="payments-banking-card" />,
                <FileDetailsCard key="file-details-card" />,
                <IncomeExpenseReportsCard key="income-expense-reports-card" />,
                <UpcomingAppointmentsCard key="upcoming-appointments-card" />,
                <MyDocumentsCard key="my-documents-card" />,
              ]
            : isNotStarted
            ? [
                <ProgressAndStatusInitialStep key="initial-step-card" />,
                <OnlineAppFormCard key="app-form-card" />,
                <MyDocumentsCard key="my-documents-card" />,
              ]
            : [
                <ProgressAndStatusInfo key="progress-signupStatus-card" />,
                <OnlineAppFormCard key="app-form-card" />,
                <MyDocumentsCard key="my-documents-card" />,
                <UpcomingAppointmentsCard key="upcoming-appointments-card" />,
              ]
        }
      />
    </PageContentContainer>
  );
};

const Dashboard = () => (
  <DashboardProvider>
    <DashboardComponent />
  </DashboardProvider>
);

export default Dashboard;
