import { useMemo } from 'react';
import { Grid, useTheme, Stack, Typography, FormHelperText } from '@mui/material';
import { Circle, CheckCircle } from '@mui/icons-material';
import { useFormikContext } from 'formik';

import { PasswordField } from '../../../components/FormFields';

import useLocale from '../../../app/hooks/useLocale';

import { ClientPublicService } from '../../../app/api/ClientPublicService';

const ChangePasswordForm = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const { errors, touched } = useFormikContext<ClientPublicService.IDebtorPasswordUpdatePublicDto>();

  const requiredNewPasswordErrorText = useMemo(
    () => t.FIELD_IS_REQUIRED?.replace('{0}', t.NEW_PASSWORD),
    [t.FIELD_IS_REQUIRED, t.NEW_PASSWORD]
  );
  const requiredConfirmNewPasswordErrorText = useMemo(
    () => t.FIELD_IS_REQUIRED?.replace('{0}', t.CONFIRM_NEW_PASSWORD),
    [t.CONFIRM_NEW_PASSWORD, t.FIELD_IS_REQUIRED]
  );

  const hasRequiredNewPasswordError = useMemo(
    () => touched.newPassword && errors.newPassword?.includes(requiredNewPasswordErrorText),
    [errors.newPassword, requiredNewPasswordErrorText, touched.newPassword]
  );

  const hasRequiredConfirmNewPasswordError = useMemo(() => {
    return touched.confirmNewPassword && errors.confirmNewPassword?.includes(requiredConfirmNewPasswordErrorText);
  }, [errors.confirmNewPassword, requiredConfirmNewPasswordErrorText, touched.confirmNewPassword]);

  return (
    <>
      <PasswordField name="oldPassword" label={t.OLD_PASSWORD} required inputProps={{ maxLength: 100 }} />

      <PasswordField
        name="newPassword"
        label={t.NEW_PASSWORD}
        required
        inputProps={{ maxLength: 100 }}
        sx={{
          '.MuiFormHelperText-root.Mui-error': {
            display: 'none !important',
          },
        }}
      />
      {hasRequiredNewPasswordError && (
        <FormHelperText style={{ marginTop: -3, marginLeft: 14, color: theme.palette.error.main }}>
          {requiredNewPasswordErrorText}
        </FormHelperText>
      )}

      <PasswordField
        name="confirmNewPassword"
        label={t.CONFIRM_NEW_PASSWORD}
        required
        inputProps={{ maxLength: 100 }}
        sx={{
          '.MuiFormHelperText-root.Mui-error': {
            display: 'none !important',
          },
        }}
      />
      {hasRequiredConfirmNewPasswordError && (
        <FormHelperText style={{ marginTop: -3, marginLeft: 14, color: theme.palette.error.main }}>
          {requiredConfirmNewPasswordErrorText}
        </FormHelperText>
      )}

      <Grid container mt={1}>
        {[
          t.ONE_LOWERCASE_CHARACTER,
          t.ONE_UPPERCASE_CHARACTER,
          t.ONE_NUMBER,
          t.ONE_SPECIAL_CHARACTER,
          t.EIGHT_CHARACTERS_MINIMUM,
          t.PASSWORDS_MATCH,
        ].map((item, index) => {
          const isFailed = [...(errors?.confirmNewPassword || []), ...(errors?.newPassword || [])]?.indexOf(item) >= 0;
          const Icon = isFailed ? Circle : CheckCircle;
          const color = isFailed ? theme.palette.secondary.main : theme.palette.cobalt;
          return (
            <Grid item key={index} mobile={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon sx={{ color, fontSize: 14 }} />
                <Typography variant="body4">{item}</Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ChangePasswordForm;
