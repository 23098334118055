import { useQuery, useMutation } from '@tanstack/react-query';

import { NEW_NOTIFICATIONS_QUERY_KEY, NEW_NOTIFICATIONS_EXIST_QUERY_KEY } from '../../app/constants/reactQueryKeys';
import { argumentifyNotificationsSearchParams } from './utils';
import { INotificationsSearchParams } from './types';

import { queryClient } from '../../app/config';
import API from '../../app/api/api';

export const useNewNotificationsQuery = () => {
  return useQuery({
    queryKey: [NEW_NOTIFICATIONS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.notificationsGET(
        ...argumentifyNotificationsSearchParams({
          isUnread: true,
          maxResultCount: 1000,
        })
      );
      return data;
    },
  });
};

export const usePreviousNotificationsMutation = () =>
  useMutation({
    mutationFn: (requestParams: INotificationsSearchParams) =>
      API.notificationsGET(...argumentifyNotificationsSearchParams(requestParams)),
  });

export const useMarkNotificationsAsReadMutation = () =>
  useMutation({
    mutationFn: () => API.notificationsPUT(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [NEW_NOTIFICATIONS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [NEW_NOTIFICATIONS_EXIST_QUERY_KEY] });
    },
  });
