import { useState } from 'react';
import { TextFieldProps, TextField, InputAdornment, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import useLocale from '../app/hooks/useLocale';

const SearchInput = (props: TextFieldProps) => {
  const theme = useTheme();
  const { t } = useLocale();
  const [value, setValue] = useState<string>('');
  return (
    <TextField
      {...props}
      value={value}
      label={t.SEARCH}
      onChange={(e) => {
        setValue(e.target.value);
        if (props.onChange) props.onChange(e);
      }}
      data-testid="search-input"
      id="outlined-start-adornment"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon data-testid="search-icon" />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment
            position="end"
            onClick={() => {
              setValue('');
              if (props?.onChange) {
                props?.onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
              }
            }}
          >
            <ClearIcon data-testid="clear-icon" />
          </InputAdornment>
        ) : null,
      }}
      sx={{
        width: '100%',
        '.MuiOutlinedInput-input': {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 1.5,
          paddingBottom: 1.5,
          borderRadius: 1.5,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        ...props?.sx,
      }}
    />
  );
};

export default SearchInput;
