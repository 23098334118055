import {
  Badge,
  Typography,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
  StackProps,
  TypographyProps,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BellIcon from '../../app/assets/icons/bellIncon.svg';

import useLocale from '../../app/hooks/useLocale';

import { useNewNotificationsExistQuery } from './queries';
import { ROUTES } from '../../app/routes';

type IStackProps = Omit<StackProps, 'title'>;

export interface IPageHeaderProps extends IStackProps {
  title?: string | React.ReactElement;
  hasBackButton?: boolean;
  backButtonText?: string;
  onBackButtonClick?: () => void;
  hasNotificationsIcon?: boolean;
  actionButton?: React.ReactElement;
  titleProps?: TypographyProps;
}

const PageHeader = ({
  title,
  hasBackButton = true,
  backButtonText,
  onBackButtonClick,
  hasNotificationsIcon = true,
  actionButton,
  titleProps,
  ...props
}: IPageHeaderProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const { data: newNotificationsExist } = useNewNotificationsExistQuery(hasNotificationsIcon) || {};

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems={isDesktop ? 'center' : 'end'}
      {...props}
      sx={{ pb: 2, ...props?.sx }}
    >
      <Stack alignItems="flex-start">
        {hasBackButton && (
          <Link
            variant="button"
            onClick={() => (onBackButtonClick ? onBackButtonClick?.() : navigate(ROUTES.DASHBOARD))}
            data-testid="back-button"
            sx={{
              color: theme.palette.cobalt,
              mb: 1.5,
              cursor: 'pointer',
            }}
          >
            <Stack flexDirection="row" alignContent="center" columnGap={1}>
              <ArrowBackIcon sx={{ color: theme.palette.cobalt }} />
              <Typography
                variant="body3"
                fontWeight={600}
                sx={{ color: theme.palette.cobalt, textTransform: 'none !important' }}
              >
                {backButtonText || t.BACK_TO_DASHBOARD}
              </Typography>
            </Stack>
          </Link>
        )}
        {typeof title === 'string' ? (
          <Typography variant="h3" {...titleProps} sx={{ fontWeight: { desktop: 600 }, ...titleProps?.sx }}>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Stack>
      <Stack direction="row" spacing={5}>
        {actionButton}
        {hasNotificationsIcon && isDesktop && (
          <IconButton
            data-testid="notification-button"
            sx={{
              backgroundColor: theme.palette.white,
              boxShadow:
                '0px 2px 4px -2px rgba(0, 0, 0, 0.12), 0px 4px 9px 3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.012)',
              borderRadius: 1,
              padding: 0.5,
              color: theme.palette.primary.main,
              width: 40,
              height: 40,
            }}
            aria-label="Notifications"
            onClick={() => navigate(ROUTES.NOTIFICATIONS)}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
              color="error"
              invisible={!newNotificationsExist}
            >
              <img src={BellIcon} alt="notification-icon" width={36} />
            </Badge>
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default PageHeader;
