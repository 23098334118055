import { Stack, useTheme, useMediaQuery, Box, Typography } from '@mui/material';

import DataItem from './DataItem';
import ElevatedContainer from './ElevatedContainer';

import { currencyFormatter } from '../app/utils/helpers';

type ISummaryCardItemProps = {
  label?: string;
  labelColor?: string;
  value?: number;
};

interface ISummaryCardProps {
  items?: ISummaryCardItemProps[];
}

const SummaryCard = ({ items }: ISummaryCardProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const Container = !isDesktop ? ElevatedContainer : Box;

  return (
    <Container sx={!isDesktop ? { borderRadius: 1.5 } : {}} data-testid="summary-card">
      <Stack direction="row" spacing={1}>
        {items?.map((item, index) => (
          <Box
            key={String(index)}
            sx={{ p: 0, pl: 1, pr: 1, border: `1px solid ${theme.palette.paleCharcoal}`, borderRadius: 1, flex: 1 }}
          >
            <DataItem
              title={
                <Typography
                  variant="body3"
                  sx={{
                    color: item?.labelColor === 'red' ? theme.palette.error.main : theme.palette.successGreen,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {item?.value === undefined
                    ? '$'
                    : item?.value === 0
                    ? '$0'
                    : currencyFormatter(item?.value, { maximumFractionDigits: 0 })}
                </Typography>
              }
              content={
                <Typography variant="body4" fontWeight={600}>
                  {item?.label}
                </Typography>
              }
            />
          </Box>
        ))}
      </Stack>
    </Container>
  );
};

export default SummaryCard;
