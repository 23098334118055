import { useContext, useEffect, useMemo } from 'react';
import { Typography, Grid, Divider, styled, Box } from '@mui/material';

import { FieldArray, useFormikContext } from 'formik';
import { CurrencyField, TextField } from '../FormFields';
import TableHead from '../ExpenderHeader';
import ExpenderTabs, { IExpenderType } from '../ExpenderTabs';
import EditExpenseDocumentsTab from './EditExpenseDocumentsTab';

import useLocale from '../../app/hooks/useLocale';

import { IUnifiedExpenseDto, IFormValues } from './types';
import { isOther, isExpenseFilled } from './utils';
import { DOCUMENTS_TAB, EditExpenseContext } from './EditExpenseProvider';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditExpenseForm = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const { currentTab, expenderTab, setExpenderTab, setIsDocumentsTabVisible, isEditable, documentsUploadProps } =
    useContext(EditExpenseContext);

  const { values } = useFormikContext<IFormValues>();

  const isChildSupportFilled = useMemo(
    () => isExpenseFilled(ClientPublicService.ExpenseDescriptionEnum.ChildSupportPayments, values),
    [values]
  );

  const isSpousalSupportFilled = useMemo(
    () => isExpenseFilled(ClientPublicService.ExpenseDescriptionEnum.SpousalSupportPayments, values),
    [values]
  );

  const isDocumentsTabVisible = useMemo(
    () => isChildSupportFilled || isSpousalSupportFilled,
    [isChildSupportFilled, isSpousalSupportFilled]
  );

  useEffect(() => {
    if (currentTab?.orderNumber !== 0) {
      setExpenderTab('applicantAmount');
    }
  }, [currentTab, setExpenderTab]);

  useEffect(() => {
    setIsDocumentsTabVisible?.(isDocumentsTabVisible);
  }, [isDocumentsTabVisible, setIsDocumentsTabVisible, values]);

  if (!currentTab?.key) {
    return <></>;
  }

  if (currentTab?.key === DOCUMENTS_TAB) {
    return <EditExpenseDocumentsTab {...documentsUploadProps} />;
  }

  return (
    <Box data-testid="expense-form">
      {values?.expenseDetails && values?.expenseDetails?.length > 0 && (
        <>
          <ExpenderTabs
            expenderTab={expenderTab}
            setExpenderTab={setExpenderTab}
            isMarried={values?.isMarried && currentTab?.orderNumber === 0}
            isExpense={currentTab?.orderNumber !== 0}
          />

          <ModalDivider />

          <TableHead
            namesColumnTitle={t.TYPE}
            expenderTab={currentTab?.orderNumber === 0 ? expenderTab : 'applicantAmount'}
            isExpense={true}
          />

          <FieldArray name="expenseDetails">
            {() => (
              <>
                {values?.expenseDetails?.map(
                  (item: IUnifiedExpenseDto, index: number) =>
                    item?.expenseTypeId === currentTab?.key && (
                      <Grid container key={index} columnSpacing={2}>
                        <Grid item mobile={6} alignItems="top" display="flex">
                          {isOther(item?.expenseDescription) ? (
                            <TextField
                              name={`expenseDetails.${index}.specifiedDescription`}
                              label={t.OTHER}
                              sx={{ '&.MuiTextField-root': { ml: 0, mt: 1, mb: 1 } }}
                              size="small"
                              required
                              fullWidth
                              disabled={!isEditable}
                            />
                          ) : (
                            <Typography variant="body2" alignSelf="center">
                              {getLocalizedDtoName(item?.expenseDescription)}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== ('applicantAmount' as IExpenderType)}
                          data-testid={`${index}-applicantAmount`}
                        >
                          <CurrencyField
                            name={`expenseDetails.${index}.applicantAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== ('spouseAmount' as IExpenderType)}
                          data-testid={`${index}-spouseAmount`}
                        >
                          <CurrencyField
                            name={`expenseDetails.${index}.spouseAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>
                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== ('otherHouseholdMemberAmount' as IExpenderType)}
                          data-testid={`${index}-otherHouseholdMemberAmount`}
                        >
                          <CurrencyField
                            name={`expenseDetails.${index}.otherHouseholdMemberAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </>
            )}
          </FieldArray>
        </>
      )}
    </Box>
  );
};

export default EditExpenseForm;

const ModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
}));
