import { useState, useMemo } from 'react';
import { Stack, useTheme, Typography } from '@mui/material';
import { useParams, useNavigate, Outlet, matchPath, useLocation } from 'react-router-dom';

import PageContentContainer from '../../components/PageContentContainer';

import useLocale from '../../app/hooks/useLocale';

import { DATE_FORMAT5, DATE_FORMAT3, serverDateToInputFormat } from '../../app/utils/dateTimeHelpers';
import { useActiveSubmissionQuery, usePeriodStatusQuery, useReportByIdQuery } from './queries';
import { ROUTES } from '../../app/routes';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditIEReport = () => {
  const { t, getLocalizedDate, getLocalizedDtoName } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { submissionPeriodId } = useParams();

  const [previousSubmission, setPreviousSubmission] = useState<ClientPublicService.IncomeExpenseSubmissionPublicDto>();

  const { data: activeSubmission } = useActiveSubmissionQuery(submissionPeriodId) || {};

  const { data: periodStatuses } = usePeriodStatusQuery() || {};

  const { data: reportById } = useReportByIdQuery(submissionPeriodId) || {};

  const report = useMemo(() => previousSubmission || activeSubmission, [activeSubmission, previousSubmission]);

  const isReportEditPage = useMemo(
    () =>
      matchPath({ path: `${ROUTES.IE_REPORT}/${submissionPeriodId}/${ROUTES.IE_REPORT_EXPENSE}` }, location.pathname) ||
      matchPath({ path: `${ROUTES.IE_REPORT}/${submissionPeriodId}/${ROUTES.IE_REPORT_INCOME}` }, location.pathname),
    [location.pathname, submissionPeriodId]
  );

  const isReportSummaryPage = useMemo(
    () => matchPath({ path: `${ROUTES.IE_REPORT}/${submissionPeriodId}` }, location.pathname),
    [location.pathname, submissionPeriodId]
  );

  return (
    <PageContentContainer
      headerProps={{
        title: (
          <>
            <Stack>
              <Typography variant="h3" fontWeight={600}>
                {`${t.INCOME_AND_EXPENSE} ${
                  reportById?.dueDate
                    ? `- ${getLocalizedDate(
                        serverDateToInputFormat(reportById?.dueDate)?.subtract(1, 'months'),
                        DATE_FORMAT5
                      )}`
                    : ''
                }`}
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                sx={{
                  color:
                    reportById?.status === ClientPublicService.IncomeExpensePeriodStatusEnum.Pending ||
                    reportById?.status === ClientPublicService.IncomeExpensePeriodStatusEnum.MissingInformation ||
                    reportById?.status === ClientPublicService.IncomeExpensePeriodStatusEnum.DiscrepancyNotice ||
                    reportById?.status === ClientPublicService.IncomeExpensePeriodStatusEnum.AmendmentRequired
                      ? theme.palette.urgentRed
                      : theme.palette.successGreen,
                }}
              >
                {getLocalizedDtoName(periodStatuses?.find((item) => item?.enumValue === reportById?.status))}
                {report?.commentsFromStaffPortal && ` - ${report?.commentsFromStaffPortal}`}
              </Typography>
              <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.secondary.main }}>
                {t.DUE_DATE}
                {reportById?.dueDate
                  ? `: ${getLocalizedDate(serverDateToInputFormat(reportById?.dueDate), DATE_FORMAT3)}`
                  : ''}
              </Typography>
            </Stack>
          </>
        ),
        backButtonText: isReportEditPage
          ? t.BACK_TO_REPORT_DETAILS
          : isReportSummaryPage
          ? t.BACK_TO_REPORTS
          : undefined,
        onBackButtonClick: () => {
          if (isReportEditPage) {
            navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}`, { replace: true });
          } else {
            navigate(ROUTES.INCOME_EXPENSE_REPORTS, { replace: true });
          }
        },
      }}
    >
      <Stack spacing={1.5}>
        <Outlet context={{ report, setPreviousSubmission }} />
      </Stack>
    </PageContentContainer>
  );
};

export default EditIEReport;
