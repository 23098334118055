import { useFormikContext } from 'formik';
import { IAppFormIncomeTax } from './types';
import AppFormSectionSubtitle from '../../components/AppFormSectionSubtitle';
import { CurrencyField, DatePickerField } from '../../../../components/FormFields';
import useLocale from '../../../../app/hooks/useLocale';
import { Divider } from '@mui/material';

const IncomeTaxForm = ({ isMarried, isLocked, ...props }: any) => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IAppFormIncomeTax>();

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>

      <DatePickerField
        name="yearLastReturnFiled"
        label={t.YEAR_LAST_RETURN_FILED}
        testId="yearLastReturnFiled"
        disableFuture
        views={['year']}
        format={'YYYY'}
        recommended
        disabled={isLocked}
      />

      <CurrencyField
        name="refundAmount"
        label={t.REFUND_AMOUNT}
        data-testid="refundAmount"
        onChange={(e: any) => {
          setFieldValue('refundAmount', e.target.value);
          if (values.amountOwing) setFieldValue('amountOwing', '');
        }}
        disabled={isLocked}
      />

      <CurrencyField
        name="amountOwing"
        label={t.AMOUNT_OWING}
        data-testid="amountOwing"
        onChange={(e: any) => {
          setFieldValue('amountOwing', e.target.value);
          if (values.refundAmount) setFieldValue('refundAmount', '');
        }}
        disabled={isLocked}
      />

      {isMarried && (
        <>
          <Divider light sx={{ mb: 2, mt: 1 }} />

          <AppFormSectionSubtitle>{t.SPOUSE_PARTNER}</AppFormSectionSubtitle>

          <DatePickerField
            name="spouseYearLastReturnFiled"
            label={t.YEAR_LAST_RETURN_FILED}
            data-testid="spouseYearLastReturnFiled"
            disableFuture
            views={['year']}
            format={'YYYY'}
            disabled={isLocked}
          />

          <CurrencyField
            name="spouseRefundAmount"
            label={t.REFUND_AMOUNT}
            data-testid="spouseRefundAmount"
            onChange={(e: any) => {
              setFieldValue('spouseRefundAmount', e.target.value);
              if (values.spouseAmountOwing) setFieldValue('spouseAmountOwing', '');
            }}
            disabled={isLocked}
          />

          <CurrencyField
            name="spouseAmountOwing"
            label={t.AMOUNT_OWING}
            data-testid="spouseAmountOwing"
            onChange={(e: any) => {
              setFieldValue('spouseAmountOwing', e.target.value);
              if (values.spouseRefundAmount) setFieldValue('spouseRefundAmount', '');
            }}
            disabled={isLocked}
          />
        </>
      )}
    </>
  );
};

export default IncomeTaxForm;
