import { useMemo, useContext } from 'react';
import { Stack, Typography, Button, Tooltip, IconButton, useTheme, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

import useLocale from '../../../app/hooks/useLocale';

import { ROUTES } from '../../../app/routes';
import { useAppFormStatusQuery } from '../queries';
import { DashboardContext } from '../DashboardProvider';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const OnlineAppFormCard = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const { applicationFile } = useContext(DashboardContext);
  const { data: status } = useAppFormStatusQuery(applicationFile?.id);

  const appFormStatus = useMemo(
    () => ({
      personalInfoStatus: status?.personalInfoStatus,
      assetsDebtsStatus: status?.assetsDebtsStatus,
      incomeExpenseStatus: status?.incomeExpenseStatus,
      incomeTaxStatus: status?.incomeTaxStatus,
      bankingInformationStatus: status?.bankingInformationStatus,
      questionnaireStatus: status?.questionnaireStatus,
    }),
    [
      status?.assetsDebtsStatus,
      status?.bankingInformationStatus,
      status?.incomeExpenseStatus,
      status?.incomeTaxStatus,
      status?.personalInfoStatus,
      status?.questionnaireStatus,
    ]
  );

  const uncompletedCount = useMemo(
    () =>
      Object.keys(appFormStatus || {}).filter(
        (key) =>
          appFormStatus?.[key as keyof typeof appFormStatus] === ClientPublicService.AppFormStatusEnum.NotStarted ||
          appFormStatus?.[key as keyof typeof appFormStatus] === ClientPublicService.AppFormStatusEnum.Incomplete ||
          appFormStatus?.[key as keyof typeof appFormStatus] === ClientPublicService.AppFormStatusEnum.Incomplete_Review
      ).length,
    [appFormStatus]
  );

  const totalCount = useMemo(() => Object.keys(appFormStatus || {})?.length, [appFormStatus]);

  const completedCount = useMemo(() => totalCount - uncompletedCount, [totalCount, uncompletedCount]);

  const isCompleted = useMemo(() => completedCount === totalCount, [completedCount, totalCount]);

  const isNotStarted = useMemo(
    () =>
      Object.keys(appFormStatus || {}).filter(
        (key) => appFormStatus?.[key as keyof typeof appFormStatus] === ClientPublicService.AppFormStatusEnum.NotStarted
      ).length === totalCount,
    [appFormStatus, totalCount]
  );

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <Stack>
          <Typography variant="h6" fontWeight={600} aria-label={t.ONLINE_APPLICATION_FORM}>
            {t.ONLINE_APPLICATION_FORM}
            <Tooltip
              title={t.THE_APPLICATION_TAKES_TIME_TO_COMPLETE_AND_CAN_BE_REVISITED_ANY_TIME}
              sx={{ color: theme.palette.primary.main }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          {Boolean(appFormStatus) ? (
            <Stack flexDirection="row" columnGap={0.75} alignItems="center">
              {!isCompleted && <ErrorIcon sx={{ color: theme.palette.urgentRed }} />}
              <Typography variant="body2" fontWeight={600} color="secondary">
                {isNotStarted
                  ? t.NOT_STARTED
                  : t.N_OUT_OF_TOTAL_COMPLETE?.replace('{0}', String(completedCount)).replace(
                      '{1}',
                      String(totalCount)
                    )}
              </Typography>
            </Stack>
          ) : (
            <Skeleton width="60%" />
          )}
        </Stack>

        {Boolean(appFormStatus) && (
          <Stack flex={1} justifyContent="center">
            <Typography variant="body2" sx={{ pb: 3, pt: 3, fontSize: 13 }} textAlign="center">
              {t.THE_INFORMATION_ENTERED_ON_THIS_FORM_WILL_BE_IMPORTANT_FOR_YOUR_FIRST_CONSULTATION_MEETING}
            </Typography>
          </Stack>
        )}

        {Boolean(appFormStatus) && (
          <Button
            variant={isNotStarted ? 'outlined' : 'contained'}
            sx={{ alignSelf: 'center', width: 200 }}
            onClick={() => navigate(ROUTES.APP_FORM)}
          >
            {isNotStarted ? t.START_NOW : t.CONTINUE}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default OnlineAppFormCard;
