import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Stack, Button, Grid, Link, Skeleton } from '@mui/material';
import { toast } from 'react-toastify';

import ConfirmationModal from '../ConfirmationModal';

import useLocale from '../../app/hooks/useLocale';
import useModal from '../../app/hooks/useModal';

import {
  usePendingFollowUpRequestQuery,
  useFollowUpRequestMutation,
  usePastInitialConsultationsAppointmentsCountQuery,
  useDebtorProfileQuery,
} from './queries';
import { ROUTES } from '../../app/routes';

interface INoUpcomingAppointmentsCardProps {
  hasViewAllButton?: boolean;
}

const NoAppointmentsCard = ({ hasViewAllButton = false }: INoUpcomingAppointmentsCardProps) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { data: pastInitialConsultationsAppointmentsCount } = usePastInitialConsultationsAppointmentsCountQuery() || {};

  const { data: pendingFollowUpRequestCount } = usePendingFollowUpRequestQuery() || {};

  const { mutate: requestFollowUp } = useFollowUpRequestMutation() || {};

  const isFileStagePostSignup = useMemo(
    () => (debtorProfile?.profile ? debtorProfile?.profile?.isFileStagePostSignup : undefined),
    [debtorProfile?.profile]
  );

  const handleConfirmRequestFollowUp = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST}
        message={t.OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME}
        cancelButtonText={t.GO_BACK}
        onCancel={closeModal}
        okButtonText={t.REQUEST_FOLLOWUP}
        onOk={() => {
          closeModal();
          requestFollowUp(undefined, {
            onSuccess: () => {
              toast.success(t.REQUEST_SENT_SUCCESSFULLY);
            },
          });
        }}
      />
    );
  }, [
    closeModal,
    requestFollowUp,
    showModal,
    t.GO_BACK,
    t.OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME,
    t.REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST,
    t.REQUEST_FOLLOWUP,
    t.REQUEST_SENT_SUCCESSFULLY,
  ]);

  const handleBookInitialConsultation = useCallback(() => {
    navigate(ROUTES.BOOK_APPOINTMENT);
  }, [navigate]);

  const isBookConsultationCard = useMemo(
    () => !Boolean(pastInitialConsultationsAppointmentsCount) && !isFileStagePostSignup,
    [isFileStagePostSignup, pastInitialConsultationsAppointmentsCount]
  );

  if (pastInitialConsultationsAppointmentsCount === undefined || pendingFollowUpRequestCount === undefined) {
    return (
      <Stack data-testid="no-appointments-card">
        <Typography variant="h6" fontWeight={600} aria-label="Loading">
          <Skeleton />
        </Typography>

        <Typography variant="body2" sx={{ pb: 3, pt: 3, fontSize: 13 }} textAlign="center">
          <Skeleton />
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <Stack flex={1}>
          <Grid container>
            <Grid item mobile>
              <Typography variant="h6" fontWeight={600} noWrap={false}>
                {isBookConsultationCard ? t.BOOK_YOUR_INITIAL_CONSULTATION : t.NO_UPCOMING_APPOINTMENTS}
              </Typography>
            </Grid>

            {hasViewAllButton && !isBookConsultationCard && (
              <Grid item mobile="auto">
                <Link
                  component="button"
                  sx={{ fontSize: 16, fontWeight: 600 }}
                  noWrap
                  onClick={() => navigate(ROUTES.APPOINTMENTS)}
                >
                  {t.VIEW_ALL}
                </Link>
              </Grid>
            )}
          </Grid>

          <Stack flex={1} justifyContent="center">
            <Typography variant="body2" sx={{ fontSize: 13, pt: 3, pb: 3 }} textAlign="center">
              {isBookConsultationCard
                ? t.BOOK_A_FREE_NOOBLIGATION_CONSULTATION_WITH_A_BDO_LICENSED_INSOLVENCY_TRUSTEE
                : Boolean(pendingFollowUpRequestCount)
                ? t.YOU_HAVE_REQUESTED_A_FOLLOWUP_MEETING_YOUR_DEBT_RELIEF_PROFESSIONAL_WILL_CONTACT_YOU
                : t.YOU_CAN_REQUEST_A_FOLLOWUP_MEETING_WITH_YOUR_DEBT_RELIEF_SPECIALIST_USING_THE_BUTTON_BELOW}
            </Typography>
          </Stack>
        </Stack>

        <Button
          variant={isBookConsultationCard ? 'contained' : 'outlined'}
          sx={{ alignSelf: 'center', width: 200 }}
          disabled={!isBookConsultationCard && Boolean(pendingFollowUpRequestCount)}
          onClick={isBookConsultationCard ? handleBookInitialConsultation : handleConfirmRequestFollowUp}
        >
          {isBookConsultationCard ? t.BOOK_APPOINTMENT : t.REQUEST_FOLLOWUP}
        </Button>
      </Stack>
    </>
  );
};

export default NoAppointmentsCard;
