import { useMemo } from 'react';
import { Typography, Grid } from '@mui/material';

import useLocale from '../app/hooks/useLocale';

import { IExpenderType } from './ExpenderTabs';

interface ITableHeadProps {
  namesColumnTitle?: string;
  expenderTab?: IExpenderType;
  isExpense?: boolean;
}

const TableHead = ({ namesColumnTitle, expenderTab, isExpense }: ITableHeadProps) => {
  const { t } = useLocale();

  const amountColumnTitle = useMemo(() => {
    switch (expenderTab) {
      case 'applicantAmount':
        return isExpense ? t.AMOUNT : t.APPLICANT;
      case 'spouseAmount':
        return isExpense ? t.AMOUNT : t.SPOUSE;
      case 'otherHouseholdMemberAmount':
        return isExpense ? t.AMOUNT : t.OTHER;
      default:
        return isExpense ? t.AMOUNT : t.APPLICANT;
    }
  }, [expenderTab, t.APPLICANT, t.OTHER, t.SPOUSE, t.AMOUNT, isExpense]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item mobile={6}>
        <Typography variant="body2" fontWeight={600} flexGrow={1}>
          {namesColumnTitle}
        </Typography>
      </Grid>
      <Grid item mobile={6}>
        <Typography variant="body2" fontWeight={600} flexGrow={1}>
          {amountColumnTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TableHead;
