import { useMemo } from 'react';
import { Stack, Typography, Button, useTheme, Grid, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import useLocale from '../../../app/hooks/useLocale';

import moment from '../../../app/utils/dateTimeHelpers';
import { ROUTES } from '../../../app/routes';
import { usePendingIEReportsQuery } from '../queries';

const IncomeExpenseReportsCard = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: pending } = usePendingIEReportsQuery() || {};

  const pendingCount = useMemo(() => pending?.length, [pending]);
  const hasPendingReports = useMemo(() => Boolean(pendingCount), [pendingCount]);

  const firstOutstandingReportId = useMemo(
    () => pending?.sort((a, b) => moment(a?.dueDate).diff(moment(b?.dueDate)))?.[0]?.externalIeSubmissionPeriodId,
    [pending]
  );

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <Box>
          <Grid container>
            <Grid item mobile>
              <Typography variant="h6" fontWeight={600} noWrap={false} aria-label={t.INCOME_AND_EXPENSE_REPORTS}>
                {t.INCOME_AND_EXPENSE_REPORTS}
              </Typography>
            </Grid>
            {hasPendingReports && (
              <Grid item mobile="auto">
                <Link
                  component="button"
                  sx={{ fontSize: 16, fontWeight: 600 }}
                  noWrap
                  onClick={() => navigate(ROUTES.INCOME_EXPENSE_REPORTS)}
                >
                  {t.VIEW_ALL}
                </Link>
              </Grid>
            )}
          </Grid>

          {hasPendingReports && (
            <Stack flexDirection="row" columnGap={0.75} alignItems="center">
              <ErrorIcon sx={{ color: theme.palette.urgentRed }} />
              <Typography variant="body2" fontWeight={600} color="secondary">
                {t.N_MONTHLY_REPORTS_PENDING?.replace('{0}', String(pendingCount))}
              </Typography>
            </Stack>
          )}
        </Box>

        <Button
          variant={firstOutstandingReportId ? 'contained' : 'outlined'}
          sx={{ alignSelf: 'center', mt: 3, width: 200 }}
          onClick={() => {
            if (firstOutstandingReportId) {
              navigate(`${ROUTES.IE_REPORT}/${firstOutstandingReportId}`);
            } else {
              navigate(ROUTES.INCOME_EXPENSE_REPORTS);
            }
          }}
        >
          {firstOutstandingReportId ? t.SUBMIT_REPORT : t.VIEW_ALL}
        </Button>
      </Stack>
    </>
  );
};

export default IncomeExpenseReportsCard;
