import { useQuery } from '@tanstack/react-query';

import { NEW_NOTIFICATIONS_EXIST_QUERY_KEY } from '../../app/constants/reactQueryKeys';

import API from '../../app/api/api';

export const useNewNotificationsExistQuery = (isUserAuthenticated?: boolean) =>
  useQuery({
    queryKey: [NEW_NOTIFICATIONS_EXIST_QUERY_KEY],
    queryFn: async () => {
      const data = await API.newExist();
      return data;
    },
    enabled: !!isUserAuthenticated,
    //refetchInterval: 1000 * 60,
  });
