import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import useLocale from '../../../../../app/hooks/useLocale';
import { IFormValuesBankingInfo } from '../types';
import { getAppFormBankingInfoDetailDto } from '../utils';
import { useMemo } from 'react';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';
import { TextField, YesNoToggleField } from '../../../../../components/FormFields';

import { Button, Divider, IconButton, Stack } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface IBankingInfoProps {
  disabled?: boolean;
}

const BankingInfo = ({ disabled }: IBankingInfoProps) => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormValuesBankingInfo>();

  const newBankingInfoDetail = useMemo(() => getAppFormBankingInfoDetailDto(), []);

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>
      <FieldArray name="details">
        {({ remove, insert }: ArrayHelpers) => (
          <>
            {values?.details && values?.details?.length > 0 && (
              <>
                {values.details.map((_, index: number) => {
                  return (
                    <Stack key={index}>
                      <TextField
                        name={`details.${index}.institution`}
                        data-testid={`details.${index}.institution`}
                        label={t.INSTITUTION}
                        recommend
                        inputProps={{ maxLength: 100 }}
                        disabled={disabled}
                      />
                      <TextField
                        name={`details.${index}.accountNumber`}
                        data-testid={`details.${index}.accountNumber`}
                        label={t.ACCOUNT_NUMBER}
                        recommend
                        inputProps={{ maxLength: 100 }}
                        disabled={disabled}
                      />
                      <TextField
                        name={`details.${index}.comments`}
                        data-testid={`details.${index}.comments`}
                        label={t.COMMENTS}
                        inputProps={{ maxLength: 200 }}
                        disabled={disabled}
                      />
                      <YesNoToggleField
                        label={t.OVERDRAFT_OWED}
                        name={`details.${index}.isOverdraftOwed`}
                        data-testid={`details.${index}.isOverdraftOwed`}
                        onChange={() => {}}
                        disabled={disabled}
                      />
                      <YesNoToggleField
                        label={t.JOINT_ACCOUNT}
                        name={`details.${index}.isJointAccount`}
                        data-testid={`details.${index}.isJointAccount`}
                        onChange={() => {}}
                        disabled={disabled}
                      />
                      <IconButton
                        onClick={() => remove(index)}
                        sx={{ mt: 2, alignSelf: 'start' }}
                        color="error"
                        data-testid={`details.${index}.delete-button`}
                        disabled={disabled || values?.details?.length === 1}
                        aria-label="Delete"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  );
                })}
              </>
            )}
            <Divider sx={{ mt: 2, mb: 1 }} />
            <Button
              variant="text"
              onClick={() => insert(values?.details?.length || 0, newBankingInfoDetail)}
              data-testid="details.add-button"
              disabled={disabled}
              sx={{ alignSelf: 'start' }}
            >
              {t.ADD_BANKING_INFORMATION}
            </Button>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </>
        )}
      </FieldArray>

      {values.isMarried && (
        <>
          <AppFormSectionSubtitle>{t.SPOUSE_PARTNER}</AppFormSectionSubtitle>
          <FieldArray name="spouseDetails">
            {({ remove, insert }: ArrayHelpers) => (
              <>
                {values?.spouseDetails && values?.spouseDetails?.length > 0 && (
                  <>
                    {values.spouseDetails.map((_, index: number) => (
                      <Stack key={index}>
                        <TextField
                          name={`spouseDetails.${index}.institution`}
                          data-testid={`spouseDetails.${index}.institution`}
                          label={t.INSTITUTION}
                          inputProps={{ maxLength: 100 }}
                          recommend
                          disabled={disabled}
                        />
                        <TextField
                          name={`spouseDetails.${index}.accountNumber`}
                          data-testid={`spouseDetails.${index}.accountNumber`}
                          label={t.ACCOUNT_NUMBER}
                          inputProps={{ maxLength: 100 }}
                          recommend
                          disabled={disabled}
                        />
                        <TextField
                          name={`spouseDetails.${index}.comments`}
                          data-testid={`spouseDetails.${index}.comments`}
                          label={t.COMMENTS}
                          inputProps={{ maxLength: 200 }}
                          disabled={disabled}
                        />
                        <YesNoToggleField
                          label={t.OVERDRAFT_OWED}
                          name={`spouseDetails.${index}.isOverdraftOwed`}
                          data-testid={`spouseDetails.${index}.isOverdraftOwed`}
                          onChange={() => {}}
                          disabled={disabled}
                        />
                        <YesNoToggleField
                          label={t.JOINT_ACCOUNT}
                          name={`spouseDetails.${index}.isJointAccount`}
                          data-testid={`spouseDetails.${index}.isJointAccount`}
                          onChange={() => {}}
                          disabled={disabled}
                        />
                        <IconButton
                          onClick={() => remove(index)}
                          sx={{ mt: 2, alignSelf: 'start' }}
                          color="error"
                          data-testid={`spouseDetails.${index}.delete-button`}
                          disabled={disabled}
                          aria-label="Delete"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Stack>
                    ))}
                  </>
                )}
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Button
                  variant="text"
                  onClick={() => insert(values?.details?.length || 0, newBankingInfoDetail)}
                  data-testid="spouseDetails.add-button"
                  disabled={disabled}
                  sx={{ alignSelf: 'start' }}
                >
                  {t.ADD_BANKING_INFORMATION}
                </Button>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </>
            )}
          </FieldArray>
        </>
      )}
    </>
  );
};

export default BankingInfo;
