import { useMemo, useCallback } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  useTheme,
  styled,
  Stack,
  Button,
  Grid,
  Skeleton,
} from '@mui/material';
import { toast } from 'react-toastify';

import RescheduleButton from './components/RescheduleButton';
import ConfirmationModal from '../ConfirmationModal';
import CalendarIcon from '../../app/assets/icons/calendar.svg';
import ClockIcon from '../../app/assets/icons/clock.svg';
import LocationIcon from '../../app/assets/icons/location.svg';

import useLocale from '../../app/hooks/useLocale';
import useModal from '../../app/hooks/useModal';

import {
  useAppointmentStatusesQuery,
  useCancelAppointmentMutation,
  useAppointmentTypesQuery,
  useMeetingTypesQuery,
  useRequestChangeFollowUpCounsellingCountQuery,
  useRequestChangeFollowUpCounsellingMutation,
  useDebtorProfileQuery,
} from './queries';
import { MEETING_DATE_FORMAT, MEETING_TIME_FORMAT, utcDateToMeetingDateFormat } from '../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../app/api/ClientPublicService';

type IProps = Pick<
  ClientPublicService.AppointmentInfoDto,
  | 'appointmentTypeId'
  | 'meetingTypeId'
  | 'appointmentType'
  | 'agentName'
  | 'appointmentStatusId'
  | 'startDateTimeLocal'
  | 'timeZoneCode'
  | 'officeAddress'
  | 'id'
> & {
  isPastAppointment?: boolean;
};

const AppointmentDetails = ({
  id,
  appointmentTypeId,
  meetingTypeId,
  appointmentType,
  agentName,
  appointmentStatusId,
  startDateTimeLocal,
  timeZoneCode,
  officeAddress,
  isPastAppointment = false,
}: IProps) => {
  const { t, getLocalizedDate, getLocalizedDtoName } = useLocale();
  const theme = useTheme();
  const { closeModal, showModal } = useModal();

  const { data: debtorProfile, isLoading: isLoadingDebtorProfile } = useDebtorProfileQuery() || {};

  const { data: appointmentTypes, isLoading: isLoadingTypes } = useAppointmentTypesQuery() || {};

  const { data: statuses, isLoading: isLoadingStatuses } = useAppointmentStatusesQuery() || {};

  const { data: meetingTypes, isLoading: isLoadingMeetingTypes } = useMeetingTypesQuery() || {};

  const { data: requestChangeCount } = useRequestChangeFollowUpCounsellingCountQuery() || {};

  const { mutate: cancelAppointment } = useCancelAppointmentMutation() || {};

  const { mutate: requestChange } = useRequestChangeFollowUpCounsellingMutation() || {};

  const status = useMemo(() => statuses?.find((x) => x.id === appointmentStatusId), [appointmentStatusId, statuses]);

  const isAppointmentPending = useMemo(
    () => status?.enumValue === ClientPublicService.AppointmentStatusEnum.Pending,
    [status]
  );

  const statusName = useMemo(
    () => (status?.enumValue !== ClientPublicService.AppointmentStatusEnum.Unknown ? getLocalizedDtoName(status) : ''),
    [status, getLocalizedDtoName]
  );

  const meetingTypeDto = useMemo(
    () => meetingTypes?.find((x) => x.id === meetingTypeId),
    [meetingTypeId, meetingTypes]
  );

  const appointmentTypeDto = useMemo(
    () => appointmentTypes?.find((x) => x.id === appointmentTypeId),
    [appointmentTypeId, appointmentTypes]
  );

  const isFileStagePostSignup = useMemo(
    () => (debtorProfile?.profile ? debtorProfile?.profile?.isFileStagePostSignup : undefined),
    [debtorProfile?.profile]
  );

  const isInitialConsultation = useMemo(
    () => appointmentTypeDto?.enumValue === ClientPublicService.AppointmentTypeEnum.InitialConsultation,
    [appointmentTypeDto?.enumValue]
  );

  const isFollowUpMeeting = useMemo(
    () => appointmentTypeDto?.enumValue === ClientPublicService.AppointmentTypeEnum.FollowUpConsultation,
    [appointmentTypeDto?.enumValue]
  );

  const isCounsellingSession = useMemo(
    () =>
      appointmentTypeDto?.enumValue === ClientPublicService.AppointmentTypeEnum.Counselling_1st ||
      appointmentTypeDto?.enumValue === ClientPublicService.AppointmentTypeEnum.Counselling_2nd ||
      appointmentTypeDto?.enumValue === ClientPublicService.AppointmentTypeEnum.Counselling_3rd,
    [appointmentTypeDto?.enumValue]
  );

  const appointmentTypeName = useMemo(
    () => getLocalizedDtoName(appointmentTypeDto) || appointmentType,
    [appointmentType, appointmentTypeDto, getLocalizedDtoName]
  );

  const timeAndZone = useMemo(() => {
    if (!timeZoneCode || !startDateTimeLocal) return undefined;
    const localizedTime = getLocalizedDate(utcDateToMeetingDateFormat(startDateTimeLocal), MEETING_TIME_FORMAT);

    return `${localizedTime} ${timeZoneCode ? `${timeZoneCode}` : ''}`;
  }, [getLocalizedDate, startDateTimeLocal, timeZoneCode]);

  const meetingWithAgent = useMemo(() => {
    return `${appointmentTypeName} ${t.WITH} ${agentName || t.UNKNOWN_AGENT}`;
  }, [agentName, appointmentTypeName, t.UNKNOWN_AGENT, t.WITH]);

  const meetingPlace = useMemo(
    () => (meetingTypeDto?.enumValue === ClientPublicService.MeetingTypeEnum.InPerson ? officeAddress : t.phone),
    [meetingTypeDto?.enumValue, officeAddress, t.phone]
  );

  const showCancelAppointmentDialog = useCallback(
    (appointmentId?: string) => {
      showModal(
        <ConfirmationModal
          title={t.YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT}
          message={t.BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY}
          cancelButtonText={t.GO_BACK}
          okButtonText={t.CANCEL_APPOINTMENT}
          onOk={() => {
            cancelAppointment(appointmentId, {
              onSuccess: (response) => {
                if (response?.result === ClientPublicService.Result.Successful) {
                  toast.success(t.APPOINTMENT_SUCCESSFULLY_CANCELLED);
                } else {
                  toast.error(response?.messages?.[0]?.body);
                }
              },
            });
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      showModal,
      t.YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT,
      t.BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY,
      t.GO_BACK,
      t.CANCEL_APPOINTMENT,
      t.APPOINTMENT_SUCCESSFULLY_CANCELLED,
      closeModal,
      cancelAppointment,
    ]
  );

  const showRequestChangeAppointmentDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.REQUEST_A_CHANGE_TO_THIS_COUNSELLING_SESSION}
        message={t.OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME}
        cancelButtonText={t.GO_BACK}
        okButtonText={t.REQUEST_CHANGE}
        onOk={() => {
          requestChange(undefined, { onSuccess: () => toast.success(t.CHANGE_REQUESTED) });
          closeModal();
        }}
        onCancel={closeModal}
      />
    );
  }, [
    showModal,
    t.REQUEST_A_CHANGE_TO_THIS_COUNSELLING_SESSION,
    t.OUR_TEAM_WILL_CONTACT_YOU_TO_CONFIRM_THE_DATE_AND_TIME,
    t.GO_BACK,
    t.REQUEST_CHANGE,
    t.CHANGE_REQUESTED,
    closeModal,
    requestChange,
  ]);

  const isLoading = isLoadingTypes || isLoadingStatuses || isLoadingMeetingTypes || isLoadingDebtorProfile;

  return (
    <>
      <Stack data-testid="appointment-card" justifyContent="space-between" flex={1}>
        <Stack>
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Grid item flex="auto">
              <Typography variant="body2" fontWeight="600">
                {isLoading ? <Skeleton width="60%" /> : meetingWithAgent}
              </Typography>
            </Grid>

            {statusName && !isAppointmentPending && !isLoading && (
              <Grid item width={100} display="flex" justifyContent="flex-end">
                <Chip
                  label={statusName}
                  size="small"
                  style={{
                    backgroundColor:
                      status?.enumValue === ClientPublicService.AppointmentStatusEnum.Completed
                        ? theme.palette.cobalt
                        : theme.palette.urgentRed,
                    color: theme.palette.common.white,
                    fontWeight: 600,
                  }}
                  data-testid="status"
                />
              </Grid>
            )}
          </Grid>
          <List dense>
            <ListItem disablePadding>
              <AppointmentDetailsIcon>
                <img src={CalendarIcon} alt="calendar-icon" width={20} height={20} />
              </AppointmentDetailsIcon>
              <ListItemText
                sx={{ fontSize: 13, mt: 0.25, mb: 0.25 }}
                disableTypography
                primary={
                  isLoading || !startDateTimeLocal ? (
                    <Skeleton />
                  ) : (
                    getLocalizedDate(utcDateToMeetingDateFormat(startDateTimeLocal), MEETING_DATE_FORMAT)
                  )
                }
              />
            </ListItem>
            <ListItem disablePadding>
              <AppointmentDetailsIcon>
                <img src={ClockIcon} alt="clock-icon" width={20} height={20} />
              </AppointmentDetailsIcon>
              <ListItemText
                sx={{ fontSize: 13, mt: 0.25, mb: 0.25 }}
                disableTypography
                primary={isLoading || !startDateTimeLocal ? <Skeleton /> : timeAndZone}
              />
            </ListItem>
            <ListItem disablePadding>
              <AppointmentDetailsIcon>
                <img src={LocationIcon} alt="location-icon" width={20} height={20} />
              </AppointmentDetailsIcon>
              <ListItemText
                sx={{ fontSize: 13, mt: 0.25, mb: 0.25 }}
                disableTypography
                primary={isLoading || !meetingPlace ? <Skeleton /> : meetingPlace}
              />
            </ListItem>
          </List>
        </Stack>

        {isLoading || !isAppointmentPending ? (
          <></>
        ) : (
          <>
            {!isFileStagePostSignup && isInitialConsultation && (
              <Stack flexDirection="row" spacing={2.5} useFlexGap flexWrap="nowrap" pt={3}>
                <RescheduleButton appointmentId={id} />
                <Button
                  data-testid="cancel-button"
                  fullWidth
                  variant="outlined"
                  onClick={() => showCancelAppointmentDialog(id)}
                >
                  {t.CANCEL}
                </Button>
              </Stack>
            )}

            {isFileStagePostSignup && isCounsellingSession && (
              <Button
                sx={{ alignSelf: 'center', mt: 3, width: 200 }}
                variant="contained"
                onClick={() => showRequestChangeAppointmentDialog()}
                disabled={Boolean(requestChangeCount) || isPastAppointment}
              >
                {t.REQUEST_CHANGE}
              </Button>
            )}

            {isFollowUpMeeting && (
              <Button
                data-testid="cancel-button"
                sx={{ alignSelf: 'center', mt: 3, width: 200 }}
                variant="outlined"
                onClick={() => showCancelAppointmentDialog(id)}
              >
                {t.CANCEL}
              </Button>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default AppointmentDetails;

const AppointmentDetailsIcon = styled(ListItemIcon)`
  min-width: 32px;
`;
