import { Grid, Button, ButtonProps } from '@mui/material';

const FooterButton = (props: ButtonProps) => (
  <Grid container flexDirection="row" justifyContent="center">
    <Grid item mobile={12} tablet={6}>
      <Button {...props} />
    </Grid>
  </Grid>
);

export default FooterButton;
