import { useContext } from 'react';
import { AuthContext } from '../auth/components/AuthProvider';

/**
 * Return AuthService instance. Work for access AuthService's internal properties and methods defined in IAuthService.
 * such as {isAuthenticated, getUser, signin, signout} = useAuthService
 * @returns AuthService instance.
 */
const useAuthService = () => {
  return useContext(AuthContext);
};

export default useAuthService;
