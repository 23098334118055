import { useMemo, useCallback, useEffect } from 'react';
import { List, ListItemText, useMediaQuery, useTheme, Grid, Divider, Stack } from '@mui/material';
import { FieldArray, ArrayHelpers, useFormikContext } from 'formik';
import * as yup from 'yup';

import { TextField, SelectField, CheckboxField } from '../FormFields';

import UploadButton from './components/UploadButton';
import ActionButton from './components/ActionButton';
import SupportDocsList from './components/SupportDocsList';

import useLocale from '../../app/hooks/useLocale';
import useUnsavedForm from '../../features/AppForm/hooks/useUnsavedForm';

import { downloadFile, readableBytes } from '../../app/utils/helpers';
import { getDocumentFormItem } from './utils';
import {
  useAppFormSupportingDocumentsQuery,
  useFoldersQuery,
  usePrefixesQuery,
  useDownloadBlobMutation,
  useDocumentDeleteMutation,
} from './queries';
import { IFormValuesDocumentItem } from './types';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import eventBus from '../../app/utils/eventBus';
import { DOCUMENTS_BY_IDS_RETRIEVED_EVENT } from '../../app/constants/eventBusKeys';
import { EMPTY_GUID } from '../../app/constants/common';

export interface IFormValues {
  documents?: IFormValuesDocumentItem[];
}

export const getDocumentsValidationSchema = (requiredMessage?: string) =>
  yup.object({
    documents: yup.array().of(
      yup.object({
        documentPrefixId: yup.string().required(requiredMessage),
      })
    ),
  });

export interface ISupportDocsUploadProps {
  onlyRetrieveByDocumentIds?: boolean;
  documentFolderCode?: string;
  documentPrefixCodes?: string[];
  documentIds?: string[];
  tooltip?: string | string[] | React.ReactElement;
  message?: string;
  disabled?: boolean;
  businessNames?: string[];
  isForSpouse?: boolean;
  isSoftDeleteAllowed?: boolean;
  assetId?: string;
  presetPrefixes?: ClientPublicService.DocumentPrefixDto[];
  showPrefixesList?: boolean;
}

const SupportDocsUpload = ({
  onlyRetrieveByDocumentIds,
  documentFolderCode,
  documentPrefixCodes,
  documentIds,
  tooltip,
  disabled,
  businessNames,
  isForSpouse,
  isSoftDeleteAllowed = false,
  assetId,
  presetPrefixes,
  showPrefixesList = true,
}: ISupportDocsUploadProps) => {
  const { t, getLocalizedDtoDescription, getLocalizedDocumentName } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { values, setFieldValue, initialValues } = useFormikContext<IFormValues>();
  const { updateExclusionArray } = useUnsavedForm();
  const { mutateAsync: deleteDocument } = useDocumentDeleteMutation();

  const enableReinitialize = useMemo(() => !Boolean(initialValues?.documents), [initialValues?.documents]);

  const { data: folders } = useFoldersQuery(enableReinitialize && !presetPrefixes) || {};

  const documentFolderId = useMemo(
    () => (presetPrefixes ? EMPTY_GUID : folders?.find((folder) => folder.code === documentFolderCode)?.id),
    [documentFolderCode, folders, presetPrefixes]
  );

  const { data: loadedPrefixes } =
    usePrefixesQuery(
      { documentFolderId, prefixCodes: documentPrefixCodes, assetId },
      enableReinitialize && !presetPrefixes
    ) || {};

  const prefixes = useMemo(
    () =>
      presetPrefixes?.map((item) => ({ ...item, name: item?.documentPrefixName } as ClientPublicService.LookupDto)) ||
      loadedPrefixes,
    [loadedPrefixes, presetPrefixes]
  );

  const { data: previouslyUploaded } =
    useAppFormSupportingDocumentsQuery(
      { documentFolderId, documentPrefixCodes, documentIds },
      onlyRetrieveByDocumentIds,
      enableReinitialize
    ) || {};

  const { mutateAsync: downloadBlob } = useDownloadBlobMutation();

  const handleDownload = useCallback(
    async (id?: string, name?: string) => {
      const blob = await downloadBlob(id as string);
      downloadFile(blob, name as string);
    },
    [downloadBlob]
  );

  const removeDocument = useCallback(
    async (id: string) => {
      deleteDocument(id);
    },
    [deleteDocument]
  );

  const newDocumentFormItem = useMemo(
    () =>
      getDocumentFormItem({
        documentFolderId,
        documentPrefixId: prefixes && prefixes?.length === 1 ? prefixes?.[0]?.id : undefined,
      } as ClientPublicService.DocumentDto),
    [documentFolderId, prefixes]
  );

  useEffect(() => {
    if (!values?.documents && previouslyUploaded?.items?.length && prefixes) {
      // do not change the condition to avoid rerenders
      const previouslyUploadedDocuments = (previouslyUploaded?.items || [])?.map((item) =>
        getDocumentFormItem({
          ...item,
          documentPrefixName: getLocalizedDtoDescription(
            prefixes?.find((prefix) => prefix?.id === item?.documentPrefixId)
          ),
        } as ClientPublicService.DocumentDto)
      );

      setFieldValue('documents', [...(values?.documents || []), ...previouslyUploadedDocuments], true);

      updateExclusionArray(
        previouslyUploaded?.items
          ?.map(
            (_, index) =>
              [
                `documents.${index}.documentFolderId`,
                `documents.${index}.documentPrefixId`,
                `documents.${index}.additionalInformation`,
                `documents.${index}.documentPrefixName`,
                `documents.${index}.id`,
                `documents.${index}.name`,
                `documents.${index}.fileSizeInBytes`,
                `documents.${index}.businessName`,
                `documents.${index}.isForSpouse`,
              ] || []
          )
          .flat()
      );
    }
  }, [
    documentIds,
    getLocalizedDtoDescription,
    prefixes,
    previouslyUploaded?.items,
    setFieldValue,
    updateExclusionArray,
    values?.documents,
  ]);

  useEffect(() => {
    if (previouslyUploaded?.items) {
      eventBus.dispatch(DOCUMENTS_BY_IDS_RETRIEVED_EVENT, {
        providedDocumentsIds: documentIds,
        previouslyUploadedIds: previouslyUploaded?.items?.map((item) => item?.id),
      });
    }
  }, [documentIds, previouslyUploaded?.items]);

  return (
    <>
      {prefixes && prefixes?.length && showPrefixesList && (
        <SupportDocsList tooltip={tooltip} list={prefixes?.map((item) => getLocalizedDtoDescription(item))} />
      )}

      <FieldArray name="documents" validateOnChange>
        {({ remove, insert }: ArrayHelpers) => (
          <>
            <UploadButton
              onUploadSuccess={(files) => {
                files.forEach((file) => {
                  insert((values?.documents || [])?.length, {
                    ...newDocumentFormItem,
                    ...file,
                  });
                });
              }}
              disabled={disabled}
            />

            {values?.documents && values?.documents?.length > 0 && (
              <List sx={{ pt: 2 }}>
                {values?.documents?.map((item: IFormValuesDocumentItem, index: number) => (
                  <Stack key={`documents.${index}`}>
                    <Grid container alignContent="center" spacing={2}>
                      <Grid item mobile desktop>
                        <ListItemText
                          primary={getLocalizedDocumentName(item)}
                          secondary={readableBytes(item?.fileSizeInBytes)}
                          primaryTypographyProps={{ fontSize: 16, fontWeight: 600 }}
                          secondaryTypographyProps={{ fontSize: 14, fontWeight: 600 }}
                        />
                      </Grid>

                      {!isDesktop && (
                        <Grid item mobile="auto" textAlign="end">
                          {!disabled &&
                            (!item?.id ||
                              (Boolean(item?.id) && isSoftDeleteAllowed) ||
                              (Boolean(item?.id) && !Boolean(item?.isSupportingDocumentReviewed))) && (
                              <ActionButton
                                type="delete"
                                onClick={() => {
                                  remove(index);
                                  if (item?.id) removeDocument(item?.id);
                                }}
                              />
                            )}
                          {item?.id && (
                            <ActionButton
                              type="download"
                              onClick={() => handleDownload(item?.id, getLocalizedDocumentName(item))}
                            />
                          )}
                        </Grid>
                      )}

                      {isDesktop && isForSpouse && (
                        <Grid item desktop="auto">
                          <CheckboxField
                            name={`documents.${index}.isForSpouse`}
                            label={t.FOR_SPOUSE}
                            disabled={Boolean(item?.id)}
                          />
                        </Grid>
                      )}

                      <Grid item mobile={12} desktop>
                        {!item?.id ? (
                          <SelectField
                            name={`documents.${index}.documentPrefixId`}
                            label={t.DOCUMENT}
                            required
                            options={prefixes}
                            sx={{
                              '.MuiSelect-select': {
                                whiteSpace: 'normal !important',
                              },
                            }}
                          />
                        ) : (
                          <TextField
                            name={`documents.${index}.documentPrefixName`}
                            label={t.DOCUMENT}
                            required
                            disabled
                          />
                        )}
                      </Grid>

                      {Boolean(businessNames) && (
                        <Grid item mobile={12} desktop>
                          {!item?.id ? (
                            <SelectField
                              name={`documents.${index}.businessName`}
                              label={t.BUSINESS_NAME}
                              required
                              options={businessNames?.map((item) => ({ id: item, name: item }))}
                            />
                          ) : (
                            <TextField
                              name={`documents.${index}.businessName`}
                              label={t.BUSINESS_NAME}
                              required
                              disabled
                            />
                          )}
                        </Grid>
                      )}

                      <Grid item mobile={12} desktop>
                        <TextField
                          name={`documents.${index}.additionalInformation`}
                          label={t.ADDITIONAL_INFORMATION}
                          disabled={!!item?.id}
                        />
                      </Grid>

                      {isDesktop && (
                        <Grid item desktop={1}>
                          {!disabled &&
                            (!item?.id ||
                              (Boolean(item?.id) && isSoftDeleteAllowed) ||
                              (Boolean(item?.id) && !Boolean(item?.isSupportingDocumentReviewed))) && (
                              <ActionButton
                                type="delete"
                                onClick={() => {
                                  remove(index);
                                  if (item?.id) removeDocument(item?.id);
                                }}
                              />
                            )}
                          {item?.id && (
                            <ActionButton
                              type="download"
                              onClick={() => handleDownload(item?.id, getLocalizedDocumentName(item))}
                            />
                          )}
                        </Grid>
                      )}

                      {!isDesktop && isForSpouse && (
                        <Grid item mobile={12}>
                          <CheckboxField
                            name={`documents.${index}.isForSpouse`}
                            label={t.FOR_SPOUSE}
                            disabled={Boolean(item?.id)}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {index < (values?.documents || [])?.length - 1 && <Divider light sx={{ mb: 2, mt: 1 }} />}
                  </Stack>
                ))}
              </List>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};

export default SupportDocsUpload;
