import { Stack, Typography, Button, useMediaQuery, Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import ElevatedContainer from './ElevatedContainer';
import CustomTable, { ICustomTableProps } from './CustomTable';

import useLocale from '../app/hooks/useLocale';

interface ITableCardProps {
  title?: string;
  hasAddButton?: boolean;
  onAddButtonClick?: () => void;
  isAddButtonDisabled?: boolean;
  hasEditButton?: boolean;
  isEditButtonDisabled?: boolean;
  onEditButtonClick?: () => void;
  hasViewButton?: boolean;
  onViewButtonClick?: () => void;
  tableProps?: ICustomTableProps;
}

const TableCard = ({
  title,
  hasAddButton = false,
  onAddButtonClick,
  isAddButtonDisabled = false,
  hasEditButton = false,
  onEditButtonClick,
  isEditButtonDisabled = false,
  hasViewButton = false,
  onViewButtonClick,
  tableProps,
}: ITableCardProps) => {
  const { t } = useLocale();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const Container = !isDesktop ? ElevatedContainer : Box;

  return (
    <Container sx={!isDesktop ? { borderRadius: 1.5 } : {}} data-testid="table-card">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {title && (
          <Typography variant="body2" fontWeight={600}>
            {title}
          </Typography>
        )}
        {hasAddButton && (
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            size="small"
            onClick={onAddButtonClick}
            sx={{
              '.MuiButton-startIcon': {
                mr: 0.5,
              },
              '.MuiButton-startIcon>*:nth-of-type(1)': {
                fontSize: 24,
              },
            }}
            data-testid="add-button"
            disabled={isAddButtonDisabled}
          >
            {t.ADD}
          </Button>
        )}
        {hasEditButton && (
          <Button
            startIcon={<EditOutlinedIcon />}
            variant="contained"
            size="small"
            onClick={onEditButtonClick}
            data-testid="edit-button"
            disabled={isEditButtonDisabled}
          >
            {t.EDIT}
          </Button>
        )}
        {hasViewButton && (
          <Button variant="contained" size="small" onClick={onViewButtonClick} data-testid="view-button">
            {t.VIEW}
          </Button>
        )}
      </Stack>
      <CustomTable {...tableProps} />
    </Container>
  );
};

export default TableCard;
