import { useMemo } from 'react';
import {
  Button,
  IconButton,
  Box,
  Typography,
  TablePaginationProps,
  useMediaQuery,
  useTheme,
  ButtonBaseProps,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useLocale from '../app/hooks/useLocale';

export type ICustomPaginationProps = Pick<TablePaginationProps, 'page' | 'onPageChange' | 'count' | 'rowsPerPage'>;

type IRulerProps = Pick<ButtonBaseProps, 'disabled' | 'onClick'> & {
  icon?: React.ReactElement;
  text?: string;
};

const Ruler = ({ icon, text, ...props }: IRulerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  return isDesktop ? (
    <Button variant="outlined" size="small" {...props}>
      {text}
    </Button>
  ) : (
    <IconButton
      sx={{
        borderRadius: 2,
        border: `1px solid ${props?.disabled ? theme.palette.disabledGray : theme.palette.error.main}`,
        p: 0.75,
        color: theme.palette.error.main,
      }}
      {...props}
    >
      {icon}
    </IconButton>
  );
};

const CustomPagination = ({ page = 0, onPageChange, count = 0, rowsPerPage = 10 }: ICustomPaginationProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const pages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);

  return (
    <Box display="flex" flex={1} justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
      <Ruler
        disabled={page === 0}
        onClick={(event) => {
          onPageChange(event as any, page - 1);
        }}
        data-testid="previous-button"
        text={t.PREVIOUS}
        icon={<ArrowBackIcon />}
      />
      <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.secondary.main }}>{`${t.PAGE} ${
        page + 1
      } ${t.OF} ${pages}`}</Typography>
      <Ruler
        disabled={page === pages - 1}
        onClick={(event) => {
          onPageChange(event as any, page + 1);
        }}
        data-testid="next-button"
        text={t.NEXT}
        icon={<ArrowForwardIcon />}
      />
    </Box>
  );
};

export default CustomPagination;
