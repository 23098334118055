import { useMemo, useCallback } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import useLocale from '../../app/hooks/useLocale';
import { Formik } from 'formik';
import yup from '../../app/utils/customYup';
import ElevatedContainer from '../../components/ElevatedContainer';
import { useSendContactStaffMessageMutation } from './queries';
import { IContactStaff } from './types';
import { convertFormValuesToDto } from './utils';
import { TextField } from '../../components/FormFields';
import theme from '../../app/layout/theme';
import PageContentContainer from '../../components/PageContentContainer';
import useModal from '../../app/hooks/useModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import { ROUTES } from '../../app/routes';
import { useNavigate } from 'react-router-dom';

const ContactStaff = () => {
  const { t } = useLocale();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { mutate: sendMessageMutation } = useSendContactStaffMessageMutation() || {};
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();

  const initialValues: IContactStaff = {
    teamId: null,
    message: null,
    subject: null,
  };

  const showSuccessMessageDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.MESSAGE_SENT}
        message={t.YOU_WILL_RECEIVE_A_REPLY_WITHIN_2_BUSINESS_DAYS}
        cancelButtonText={t.OK}
        hasCancelButton={false}
        onOk={() => {
          closeModal();
          navigate(ROUTES.ROOT);
        }}
      />
    );
  }, [showModal, t.MESSAGE_SENT, t.YOU_WILL_RECEIVE_A_REPLY_WITHIN_2_BUSINESS_DAYS, t.OK, closeModal, navigate]);

  const handleSubmit = useCallback(
    async (values: IContactStaff, onSuccess?: () => void) => {
      sendMessageMutation(convertFormValuesToDto(values), {
        onSuccess: () => {
          showSuccessMessageDialog();
        },
      });
    },
    [sendMessageMutation, showSuccessMessageDialog]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        subject: yup.string().required(t.SUBJECT_IS_REQUIRED),
        message: yup.string().required(t.MESSAGE_IS_REQUIRED),
      }),
    [t.SUBJECT_IS_REQUIRED, t.MESSAGE_IS_REQUIRED]
  );

  return (
    <PageContentContainer
      headerProps={{
        title: t.CONTACT_STAFF,
      }}
    >
      <Typography variant="body3" fontWeight={500}>
        {t.SEND_MESSAGE_TO_DEBT_RELIEF_SPCETIALIST_INFO}
      </Typography>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_: IContactStaff, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnBlur={true}
      >
        {({ values, submitForm, isValid }) => (
          <>
            <ElevatedContainer sx={{ borderRadius: 1.5, mt: 4, pt: 2, pb: 2, pl: 1.5, pr: 1.5 }}>
              <Stack>
                <TextField name="subject" label={t.SUBJECT} data-testid="subject" required />

                <TextField name="message" label={t.MESSAGE} data-testid="message" multiline minRows={5} required />
              </Stack>
            </ElevatedContainer>
            <Grid container justifyContent={isDesktop ? 'left' : 'center'} data-testid="button-container">
              <Grid item mobile={12} tablet={6} desktop={3}>
                <Button
                  variant="contained"
                  data-testid="ok-button"
                  fullWidth
                  onClick={async () => {
                    await submitForm();
                    if (isValid) handleSubmit(values);
                  }}
                  disabled={!isValid}
                  sx={{ mt: 2 }}
                >
                  {t.SUBMIT}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </PageContentContainer>
  );
};

export default ContactStaff;
