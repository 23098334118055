import { useCallback, useMemo } from 'react';
import { Grid, useTheme, Button, Link } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { toast } from 'react-toastify';

import useLocale from '../../../app/hooks/useLocale';

import { IUploadedFileProps } from '../types';
import { useDocumentsTypesQuery } from '../queries';
import { nameRemovedExtraDots } from '../../../app/utils/helpers';
import { isDeviceiOS } from '../../../app/utils/helpers';
import { BLACK_SCREEN_SESSION_STORAGE_KEY } from '../../../app/constants/common';

interface IUploadButtonProps {
  id: string;
  onUploadSuccess?: (uploadedFile: IUploadedFileProps) => void;
  buttonType?: 'default' | 'icon' | 'link';
}

const UploadButton = ({ id, onUploadSuccess, buttonType = 'default' }: IUploadButtonProps) => {
  const { t } = useLocale();
  const theme = useTheme();

  const { data: types } = useDocumentsTypesQuery() || {};

  const verifyAllowedFileType = useCallback(
    (file: File) =>
      types?.find(
        (item) =>
          item?.code === file?.type || file?.name?.split('.')?.pop()?.toLowerCase() === item?.name?.toLowerCase()
      ),
    [types]
  );

  const getFileFromInput = useCallback((file: File): Promise<any> => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const handleWarningiOS = useCallback(() => {
    if (isDeviceiOS() && !sessionStorage.getItem(BLACK_SCREEN_SESSION_STORAGE_KEY)) {
      toast.warning(t.BLACK_SCREEN_WARNING);
      sessionStorage.setItem(BLACK_SCREEN_SESSION_STORAGE_KEY, 'true');
    }
  }, [t.BLACK_SCREEN_WARNING]);

  const handleFileChange = useCallback(
    async (event: any) => {
      event?.persist();

      if (!event?.target?.files || !event?.target?.files?.[0]) {
        event.target.value = null;
        return;
      }

      const file = event.target.files[0];

      if (!verifyAllowedFileType(file)) {
        toast.error(t.DOCUMENTS_INVALID_TYPE_MESSAGE);
        event.target.value = null;
        return;
      }

      if (file?.size / 1024 / 1024 > 100) {
        toast.error(t.THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB);
        event.target.value = null;
        return;
      }

      const binary = await getFileFromInput(file);
      const name = nameRemovedExtraDots(file?.name);

      if (name) {
        onUploadSuccess?.({
          name,
          fileSizeInBytes: file.size,
          blob: new Blob([binary], { type: file.type }),
          type: file.type,
        });
      }

      event.target.value = null;
    },
    [
      getFileFromInput,
      onUploadSuccess,
      t.DOCUMENTS_INVALID_TYPE_MESSAGE,
      t.THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB,
      verifyAllowedFileType,
    ]
  );

  const handleClick = useCallback(
    (e?: any) => {
      handleWarningiOS();
      e.stopPropagation();
    },
    [handleWarningiOS]
  );

  const buttonComponent = useMemo(() => {
    switch (buttonType) {
      case 'icon':
        return <UploadIcon style={{ color: theme.palette.slate, width: 40 }} fontSize="large" aria-label="Upload" />;
      case 'link':
        return (
          <Link
            data-testid="upload-link"
            component="span"
            onClick={handleClick}
            sx={{ cursor: 'pointer', color: theme.palette.cobalt, fontWeight: 600 }}
          >
            {t.UPLOAD}
          </Link>
        );
      default:
        return (
          <Button
            data-testid="upload-button"
            component="span"
            variant="contained"
            onClick={handleClick}
            aria-label="Upload"
          >
            {t.UPLOAD}
          </Button>
        );
    }
  }, [buttonType, handleClick, t.UPLOAD, theme.palette.cobalt, theme.palette.slate]);

  return (
    <>
      <Grid container>
        <Grid item mobile={12}>
          <input id={id} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
          <label htmlFor={id}>{buttonComponent}</label>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadButton;
