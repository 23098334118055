import { Grid, Button } from '@mui/material';

import useLocale from '../../../app/hooks/useLocale';
import { AppformSection } from '../../../components/AppFormProgress/components/ProgressBar';

interface IContinueButtonProps {
  onNext?: () => void;
  onPrev?: () => void;
  currentSection: AppformSection;
  disabled?: boolean;
}

const NavigationButtons = ({ onNext, onPrev, currentSection }: IContinueButtonProps) => {
  const { t } = useLocale();

  return (
    <Grid container data-testid="button-container">
      <Grid item mobile={6} tablet={6} desktop={3} justifyContent={'left'} sx={{ pr: 1 }}>
        <Button
          variant={currentSection === AppformSection.PersonalInfo ? 'contained' : 'outlined'}
          data-testid="back-button"
          fullWidth
          onClick={async () => {
            if (onPrev) onPrev();
          }}
          sx={{ mt: 2 }}
          disabled={currentSection === AppformSection.PersonalInfo}
        >
          {t.BACK}
        </Button>
      </Grid>
      <Grid item mobile={6} tablet={6} desktop={3} marginLeft={'auto'} sx={{ pl: 1 }}>
        <Button
          variant="contained"
          data-testid="ok-button"
          fullWidth
          onClick={() => {
            if (onNext) onNext();
          }}
          sx={{ mt: 2 }}
        >
          {currentSection === AppformSection.Questionnaire ? t.SAVE : t.NEXT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NavigationButtons;
