import {
  IOfficeLocationSearchParams,
  ITimeslotsSearchParams,
  IInitialTimeslotsSearchParams,
  IOfficeLocationGoogleMap,
  IUnreserveParams,
  IFilterUsersParams,
} from './types';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import NumberedPin1 from '../../app/assets/images/pins/numbered-pin-1.svg';
import NumberedPin2 from '../../app/assets/images/pins/numbered-pin-2.svg';
import NumberedPin3 from '../../app/assets/images/pins/numbered-pin-3.svg';
import NumberedPin4 from '../../app/assets/images/pins/numbered-pin-4.svg';
import NumberedPin5 from '../../app/assets/images/pins/numbered-pin-5.svg';

export const argumentifyLocationSearchParams = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationSearchParams) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const argumentifyTimeslotsSearchParams = ({
  date,
  officeLocationId,
  isPreferedLanguageEnglish,
  meetingTypeEnum,
}: ITimeslotsSearchParams) => [date, officeLocationId, isPreferedLanguageEnglish, meetingTypeEnum] as const;

export const argumentifyInitialTimeslotsSearchParams = ({
  officeLocationId,
  isPreferedLanguageEnglish,
  meetingTypeEnum,
}: IInitialTimeslotsSearchParams) => [officeLocationId, isPreferedLanguageEnglish, meetingTypeEnum] as const;

export const argumentifyFilterUsersParams = ({ userIds, roleName, roleNames, isActive }: IFilterUsersParams) =>
  [userIds, roleName, roleNames, isActive] as const;

export const argumentifyUnreserveParams = ({ agentId, dateTime, officeLocationId }: IUnreserveParams) =>
  [agentId, dateTime, officeLocationId] as const;

export const convertOfficeLocationDtoToReadableAddress = (officeLocation: any) => {
  let address = officeLocation?.streetNumber || '';
  if (officeLocation?.streetName) {
    address += ` ${officeLocation?.streetName}`;
  }
  if (officeLocation?.city) {
    address += `, ${officeLocation?.city}`;
  }
  if (officeLocation?.province) {
    address += `, ${officeLocation?.province}`;
  }
  return address;
};

export const updateOfficesWithLatLng = (offices?: ClientPublicService.OfficeLocationDto[]) =>
  offices?.map((item) => ({
    ...item,
    position: new google.maps.LatLng(item?.latitude as number, item?.longitude as number),
  }));

export const sortOfficesByProximityToTargetLatLng = (
  targetPosition: google.maps.LatLng,
  offices?: IOfficeLocationGoogleMap[]
) => {
  if (!offices) return;

  const computeDistanceBetween = google.maps.geometry.spherical.computeDistanceBetween;

  return offices?.sort(
    (office1, office2) =>
      computeDistanceBetween(targetPosition, office1?.position) -
      computeDistanceBetween(targetPosition, office2?.position)
  );
};

export const getPinByIndexNumber = (index: number) => {
  const icons = [NumberedPin1, NumberedPin2, NumberedPin3, NumberedPin4, NumberedPin5];

  return icons[index];
};
