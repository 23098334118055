import { useMemo, useContext, useCallback } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers, getIn } from 'formik';
import { Divider, Stack, Button, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  SelectField,
  TextField,
  DatePickerField,
  YesNoToggleField,
  ToggleGroupField,
} from '../../../../../components/FormFields';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormValuesPersonalInfo } from '../types';
import { PersonalInfoContext } from '../PersonalInformationProvider';

import { useInsolvencyTypesQuery, useProposalResultsQuery } from '../queries';
import { getInsolvencyFormItem } from '../utils';
import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const PreviousInsolvencies = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { isMarried } = useContext(PersonalInfoContext);
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const { data: insolvencyTypes } = useInsolvencyTypesQuery() || {};
  const { data: proposalResults } = useProposalResultsQuery() || {};

  const newInsolvencyItem = useMemo(() => getInsolvencyFormItem(), []);

  const bankruptcyType = useMemo(
    () => insolvencyTypes?.find((item) => item?.enumValue === ClientPublicService.InsolvencyTypeEnum.Bankruptcy),
    [insolvencyTypes]
  );
  const proposalType = useMemo(
    () => insolvencyTypes?.find((item) => item?.enumValue === ClientPublicService.InsolvencyTypeEnum.Proposal),
    [insolvencyTypes]
  );

  const toggleInsolvencyTypes = useMemo(
    () =>
      insolvencyTypes?.map((item) => ({
        value: item?.id as string,
        label: getLocalizedDtoName(item) as string,
      })),
    [getLocalizedDtoName, insolvencyTypes]
  );

  const handleHasPreviousInsolvencyToggle = useCallback(
    (hasPreviousInsolvency?: boolean) => {
      const noPreviousInsolvenciesDetails = !values?.previousInsolvencies || values?.previousInsolvencies?.length === 0;
      if (hasPreviousInsolvency && noPreviousInsolvenciesDetails) {
        setFieldValue('previousInsolvencies', [newInsolvencyItem]);
      }

      if (!hasPreviousInsolvency) {
        setFieldValue('previousInsolvencies', []);
      }
    },
    [newInsolvencyItem, setFieldValue, values?.previousInsolvencies]
  );

  const handleHasSpousePreviousInsolvencyToggle = useCallback(
    (hasSpousePreviousInsolvency?: boolean) => {
      const noSpousePreviousInsolvenciesDetails =
        !values?.spousePreviousInsolvencies || values?.spousePreviousInsolvencies?.length === 0;
      if (hasSpousePreviousInsolvency && noSpousePreviousInsolvenciesDetails) {
        setFieldValue('spousePreviousInsolvencies', [newInsolvencyItem]);
      }

      if (!hasSpousePreviousInsolvency) {
        setFieldValue('spousePreviousInsolvencies', []);
      }
    },
    [newInsolvencyItem, setFieldValue, values?.spousePreviousInsolvencies]
  );

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>

      <YesNoToggleField
        label={t.HAVE_FILED_INSOLVENCY}
        required
        name="hasPreviousInsolvency"
        onChange={handleHasPreviousInsolvencyToggle}
        disabled={isLocked}
      />

      {values?.hasPreviousInsolvency && values?.previousInsolvencies && values?.previousInsolvencies?.length > 0 && (
        <FieldArray name="previousInsolvencies">
          {({ remove, insert }: ArrayHelpers) => (
            <>
              <Divider sx={{ mt: 2, mb: 1 }} />
              {values?.previousInsolvencies?.map((_, index: number) => (
                <Stack key={index}>
                  <ToggleGroupField
                    name={`previousInsolvencies.${index}.insolvencyTypeId`}
                    label={t.TYPE}
                    data={toggleInsolvencyTypes}
                    required
                    exclusive
                    disabled={isLocked}
                  />
                  <TextField
                    name={`previousInsolvencies.${index}.applicantName`}
                    label={t.UNDER_WHAT_NAME}
                    inputProps={{ maxLength: 100 }}
                    disabled={isLocked}
                  />
                  <TextField
                    name={`previousInsolvencies.${index}.trusteeName`}
                    label={t.TRUSTEE_NAME}
                    inputProps={{ maxLength: 100 }}
                    disabled={isLocked}
                  />
                  {getIn(values, `previousInsolvencies.${index}.insolvencyTypeId`) === bankruptcyType?.id && (
                    <>
                      <DatePickerField
                        name={`previousInsolvencies.${index}.dischargeDate`}
                        label={t.BANKRUPTCY_DISCHARGE_DATE}
                        disableFuture
                        format={DATE_FORMAT}
                        disabled={isLocked}
                      />
                      <DatePickerField
                        name={`previousInsolvencies.${index}.bankruptcyDate`}
                        label={t.BANKRUPTCY_DATE}
                        disableFuture
                        format={DATE_FORMAT}
                        recommended
                        disabled={isLocked}
                      />
                    </>
                  )}
                  {getIn(values, `previousInsolvencies.${index}.insolvencyTypeId`) === proposalType?.id && (
                    <>
                      <DatePickerField
                        name={`previousInsolvencies.${index}.proposalDate`}
                        label={t.PROPOSAL_DATE}
                        disableFuture
                        format={DATE_FORMAT}
                        recommended
                        disabled={isLocked}
                      />
                      <SelectField
                        label={t.RESULT_OF_PROPOSAL}
                        name={`previousInsolvencies.${index}.proposalResultId`}
                        options={proposalResults}
                        disabled={isLocked}
                      />
                    </>
                  )}
                  <TextField
                    name={`previousInsolvencies.${index}.filingLocation`}
                    label={t.PLACE_FILED}
                    inputProps={{ maxLength: 100 }}
                    disabled={isLocked}
                  />
                  <TextField
                    name={`previousInsolvencies.${index}.estateNumber`}
                    label={t.ESTATE_NUMBER}
                    inputProps={{ maxLength: 100 }}
                    disabled={isLocked}
                  />
                  <IconButton
                    onClick={() => remove(index)}
                    disabled={values?.previousInsolvencies?.length === 1 || isLocked}
                    sx={{ alignSelf: 'start' }}
                    color="error"
                    aria-label="Delete"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                </Stack>
              ))}

              <Button
                variant="text"
                onClick={() => insert(values?.previousInsolvencies?.length || 0, newInsolvencyItem)}
                disabled={isLocked}
              >
                {t.ADD_INSOLVENCY}
              </Button>
            </>
          )}
        </FieldArray>
      )}

      <Divider sx={{ mt: 2, mb: 2 }} />

      {isMarried && values?.isJointFile && (
        <>
          <AppFormSectionSubtitle>{t.SPOUSE}</AppFormSectionSubtitle>

          <YesNoToggleField
            label={t.HAVE_FILED_INSOLVENCY}
            required
            name="hasSpousePreviousInsolvency"
            onChange={handleHasSpousePreviousInsolvencyToggle}
            disabled={isLocked}
          />

          {values?.hasSpousePreviousInsolvency &&
            values?.spousePreviousInsolvencies &&
            values?.spousePreviousInsolvencies?.length > 0 && (
              <FieldArray name="spousePreviousInsolvencies">
                {({ remove, insert }: ArrayHelpers) => (
                  <>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    {values?.spousePreviousInsolvencies?.map((_, index: number) => (
                      <Stack key={index}>
                        <ToggleGroupField
                          name={`spousePreviousInsolvencies.${index}.insolvencyTypeId`}
                          label={t.TYPE}
                          data={toggleInsolvencyTypes}
                          exclusive
                          disabled={isLocked}
                        />
                        <TextField
                          name={`spousePreviousInsolvencies.${index}.applicantName`}
                          label={t.UNDER_WHAT_NAME}
                          inputProps={{ maxLength: 100 }}
                          disabled={isLocked}
                        />
                        <TextField
                          name={`spousePreviousInsolvencies.${index}.trusteeName`}
                          label={t.TRUSTEE_NAME}
                          inputProps={{ maxLength: 100 }}
                          disabled={isLocked}
                        />
                        {getIn(values, `spousePreviousInsolvencies.${index}.insolvencyTypeId`) ===
                          bankruptcyType?.id && (
                          <>
                            <DatePickerField
                              name={`spousePreviousInsolvencies.${index}.dischargeDate`}
                              label={t.BANKRUPTCY_DISCHARGE_DATE}
                              disableFuture
                              format={DATE_FORMAT}
                              disabled={isLocked}
                            />
                            <DatePickerField
                              name={`spousePreviousInsolvencies.${index}.bankruptcyDate`}
                              label={t.BANKRUPTCY_DATE}
                              disableFuture
                              format={DATE_FORMAT}
                              disabled={isLocked}
                            />
                          </>
                        )}
                        {getIn(values, `spousePreviousInsolvencies.${index}.insolvencyTypeId`) === proposalType?.id && (
                          <>
                            <DatePickerField
                              name={`spousePreviousInsolvencies.${index}.proposalDate`}
                              label={t.PROPOSAL_DATE}
                              disableFuture
                              format={DATE_FORMAT}
                              disabled={isLocked}
                            />
                            <SelectField
                              label={t.RESULT_OF_PROPOSAL}
                              name={`spousePreviousInsolvencies.${index}.proposalResultId`}
                              options={proposalResults}
                              disabled={isLocked}
                            />
                          </>
                        )}
                        <TextField
                          name={`spousePreviousInsolvencies.${index}.filingLocation`}
                          label={t.PLACE_FILED}
                          inputProps={{ maxLength: 100 }}
                          disabled={isLocked}
                        />
                        <TextField
                          name={`spousePreviousInsolvencies.${index}.estateNumber`}
                          label={t.ESTATE_NUMBER}
                          inputProps={{ maxLength: 100 }}
                          disabled={isLocked}
                        />
                        <IconButton
                          onClick={() => remove(index)}
                          disabled={values?.spousePreviousInsolvencies?.length === 1 || isLocked}
                          sx={{ alignSelf: 'start' }}
                          color="error"
                          aria-label="Delete"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                        <Divider sx={{ mt: 2, mb: 1 }} />
                      </Stack>
                    ))}

                    <Button
                      variant="text"
                      onClick={() => insert(values?.spousePreviousInsolvencies?.length || 0, newInsolvencyItem)}
                      disabled={isLocked}
                    >
                      {t.ADD_INSOLVENCY}
                    </Button>
                  </>
                )}
              </FieldArray>
            )}
        </>
      )}
    </>
  );
};

export default PreviousInsolvencies;
