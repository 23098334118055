import { Grid, useMediaQuery } from '@mui/material';

import ElevatedContainer from './ElevatedContainer';

interface IResponsiveGridLayoutProps {
  items: React.ReactElement[];
  fullHeight?: boolean;
}

const ResponsiveGridLayout = ({ items, fullHeight = true }: IResponsiveGridLayoutProps) => {
  const mobileTwoRowsLayout = useMediaQuery('(min-width:644px)');
  const tabletThreeRowsLayout = useMediaQuery('(min-width:964px)');
  const desktopThreeRowsLayout = useMediaQuery('(min-width:1204px)');
  const desktopFourRowsLayout = useMediaQuery('(min-width:1920px)');

  return (
    <Grid
      container
      columns={{
        mobile: mobileTwoRowsLayout ? 2 : 1,
        tablet: tabletThreeRowsLayout ? 3 : 2,
        desktop: desktopFourRowsLayout ? 4 : desktopThreeRowsLayout ? 3 : 2,
      }}
      spacing={2}
    >
      {items?.map((item, index) => (
        <Grid item mobile={1} tablet={1} desktop={1} key={`responsive-grid-item-${index}`}>
          <ElevatedContainer sx={{ borderRadius: 1.5, m: 0, height: fullHeight ? '100%' : 'auto', pt: 3, pb: 3 }}>
            {item}
          </ElevatedContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default ResponsiveGridLayout;
