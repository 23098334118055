import PageContentContainer from '../../components/PageContentContainer';

import BalanceCard from './components/BalanceCard';
import DebitInfoCard from './components/DebitInfoCard';
import PaymentsCard from './components/PaymentsCard';
import ResponsiveGridLayoutPaymentBanking from './components/ResponsiveGridLayoutPaymentBanking';

import useLocale from '../../app/hooks/useLocale';

import { useBalanceQuery } from './queries';

const PaymentsBanking = () => {
  const { t } = useLocale();

  const { data: balance } = useBalanceQuery() || {};

  return (
    <PageContentContainer headerProps={{ title: t.PAYMENTS_AND_BANKING }}>
      <ResponsiveGridLayoutPaymentBanking
        items={
          balance?.startingBalance
            ? [
                <BalanceCard key="balance-card" />,
                <PaymentsCard key="payments-card" />,
                <DebitInfoCard key="debit-info-card" />,
              ]
            : [<BalanceCard key="balance-card" />]
        }
      />
    </PageContentContainer>
  );
};

export default PaymentsBanking;
