import { useMemo, useContext } from 'react';

import RealEstateDetails from './RealEstateDetails';
import InvestmentsPoliciesDetails from './InvestmentsPoliciesDetails';
import MotorVehiclesDetails from './MotorVehiclesDetails';
import RecreationalEquipmentDetails from './RecreationalEquipmentDetails';

import { ClientPublicService } from '../../../../../../../app/api/ClientPublicService';
import { EditAssetModalContext } from '../EditAssetModalProvider';

const DetailsTab = () => {
  const { assetTypeEnum } = useContext(EditAssetModalContext);

  const renderComponent = useMemo(() => {
    switch (assetTypeEnum) {
      case ClientPublicService.AssetTypeEnum.RealEstate:
        return <RealEstateDetails />;
      case ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies:
        return <InvestmentsPoliciesDetails />;
      case ClientPublicService.AssetTypeEnum.MotorVehicles:
        return <MotorVehiclesDetails />;
      case ClientPublicService.AssetTypeEnum.RecreationalEquipment:
        return <RecreationalEquipmentDetails />;
      default:
        return <></>;
    }
  }, [assetTypeEnum]);

  return renderComponent;
};

export default DetailsTab;
