import { useState, useCallback, useMemo, useEffect } from 'react';
import { Grid, Link, useTheme, useMediaQuery, Typography, Stack, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import CustomTable, { IColumnType } from '../../../components/CustomTable';
import ListLayout from '../../../components/ListLayout/ListLayout';
import SearchInput from '../../../components/SearchInput';

import useLocale from '../../../app/hooks/useLocale';
import useDebounce from '../../../app/hooks/useDebounce';

import { readableBytes, nameRemovedExtraDotsWithoutExtension } from '../../../app/utils/helpers';
import moment, { DATE_FORMAT } from '../../../app/utils/dateTimeHelpers';
import { getImgDataByType, downloadFile } from '../utils';
import { UploadedDocumentsResponse, IDocumentsSearchParams } from '../types';
import {
  useDownloadBlobMutation,
  useDocumentsQuery,
  useApplicationFileQuery,
  useAllDocumentsCountQuery,
} from '../queries';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_SEARCH_PARAMS: IDocumentsSearchParams = {
  skipCount: 0,
  maxResultCount: DEFAULT_PAGE_SIZE,
  sorting: 'name asc',
};

const Uploaded = () => {
  const { t, getLocalizedDate, getLocalizedDocumentName } = useLocale();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));

  const [searchText, setSearchText] = useState<string>('');
  const filterText = useDebounce(searchText);

  const [searchParams, setSearchParams] = useState<IDocumentsSearchParams>(DEFAULT_SEARCH_PARAMS);

  const [prevPageDocuments, setPrevPageDocuments] = useState<UploadedDocumentsResponse>();

  const { data: appFile } = useApplicationFileQuery() || {};

  const { data: allDocumentsCount } = useAllDocumentsCountQuery(appFile?.id) || {};

  const { data: currentPageDocuments } = useDocumentsQuery(searchParams) || {};

  const { mutateAsync: downloadBlob } = useDownloadBlobMutation() || {};

  const documents = useMemo(() => currentPageDocuments || prevPageDocuments, [currentPageDocuments, prevPageDocuments]);

  useEffect(() => {
    setPrevPageDocuments(undefined);
    setSearchParams((prev) => ({ ...prev, ...DEFAULT_SEARCH_PARAMS, fileId: appFile?.id, filterText }));
  }, [appFile?.id, filterText]);

  const handleDownload = useCallback(
    async (id?: string, name?: string) => {
      const blob = await downloadBlob(id as string);
      downloadFile(blob, name as string);
    },
    [downloadBlob]
  );

  const handleSort = useCallback((column: any, sortDirection: any) => {
    setSearchParams((prev) => ({ ...prev, ...DEFAULT_SEARCH_PARAMS, sorting: `${column} ${sortDirection}` }));
  }, []);

  const columns: IColumnType[] = [
    {
      title: t.NAME,
      dataIndex: 'name',
      key: 'name',
      render: (_?: any, record?: ClientPublicService.IDocumentDto) =>
        nameRemovedExtraDotsWithoutExtension(getLocalizedDocumentName(record)),
      width: '50%',
    },
    {
      title: t.TYPE,
      dataIndex: 'documentTypeName',
      key: 'documentTypeName',
      width: '10%',
    },
    {
      title: t.SIZE,
      dataIndex: 'fileSizeInBytes',
      key: 'fileSizeInBytes',
      sortable: false,
      render: (value?: number) => <>{readableBytes(value)}</>,
      width: '10%',
    },
    {
      title: t.UPLOADED,
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (value?: moment.Moment) => <>{getLocalizedDate(value, DATE_FORMAT)}</>,
      width: '10%',
    },
    {
      title: t.ACTION,
      dataIndex: 'action',
      key: 'action',
      sortable: false,
      render: (_: any, item: ClientPublicService.IDocumentDto) => (
        <Link
          component="button"
          onClick={() => handleDownload(item?.id, getLocalizedDocumentName(item))}
          sx={{ color: theme.palette.cobalt, fontWeight: 600 }}
        >
          {t.DOWNLOAD}
        </Link>
      ),
    },
  ];

  const paginationProps = useMemo(
    () => ({
      page: (searchParams.skipCount || 0) / DEFAULT_PAGE_SIZE,
      rowsPerPage: DEFAULT_PAGE_SIZE,
      onPageChange: (_: any, newPage: number) => {
        setPrevPageDocuments(currentPageDocuments);
        setSearchParams((prev) => ({ ...prev, skipCount: newPage * DEFAULT_PAGE_SIZE }));
      },
      count: documents?.totalCount || 0,
    }),
    [searchParams.skipCount, currentPageDocuments, documents?.totalCount]
  );

  if (allDocumentsCount === undefined) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={1.5}>
        <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.secondary.main }}>
          {t.UPLOADED} ({allDocumentsCount || 0})
        </Typography>
        <Grid container>
          <Grid item mobile={12} tablet={8} desktop={6}>
            <SearchInput
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ backgroundColor: theme.palette.white, m: 0, mb: { desktop: 1 } }}
            />
          </Grid>
        </Grid>

        {isMobile ? (
          <Box data-testid="list-layout">
            <ListLayout
              items={documents?.items?.map((item) => {
                const { alt, src } = getImgDataByType(item?.documentTypeName);
                return {
                  id: item?.id as string,
                  title: nameRemovedExtraDotsWithoutExtension(getLocalizedDocumentName(item)),
                  subtitle: (
                    <Stack flexDirection="row" columnGap={2}>
                      <Box>{item?.documentTypeName}</Box>
                      <Box>{readableBytes(item?.fileSizeInBytes)}</Box>
                      <Box>{getLocalizedDate(item?.uploadDate, DATE_FORMAT)}</Box>
                    </Stack>
                  ),
                  icon: <img alt={alt || ''} src={src} />,
                  actionButtonIcon: <DownloadIcon style={{ color: theme.palette.slate }} fontSize="large" />,
                  actionButtonProps: {
                    onClick: () => handleDownload(item?.id, getLocalizedDocumentName(item)),
                  },
                  primaryTextProps: {
                    sx: {
                      fontSize: `16px !important`,
                      fontWeight: 600,
                    },
                  },
                  secondaryTextProps: {
                    sx: {
                      fontWeight: 400,
                      fontSize: '13px !important',
                    },
                  },
                };
              })}
              paginationProps={paginationProps}
              emptyValue={t.NO_DOCUMENTS_FOUND}
            />
          </Box>
        ) : (
          <Box sx={{ pb: 4 }} data-testid="table-layout">
            <CustomTable
              data={documents?.items}
              columns={columns}
              emptyValue={t.NO_DOCUMENTS_FOUND}
              onColumnSort={handleSort}
              paginationProps={paginationProps}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Uploaded;
