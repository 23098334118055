import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Stack, Button, Container, Typography, Box, Link, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from './Header';
import CustomToggleButton from '../../../components/CustomToggleButton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import TimeslotGroup from './TimeslotGroup';
import Wrapper from './Wrapper';
import ConfirmationModal from '../../../components/ConfirmationModal';
import DateCalendarComponent from './DateCalendarComponent';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';
import useBookAppointment from '../hooks/useBookAppointment';

import moment, {
  TIME_FORMAT,
  isTimeSameOrBefore,
  isTimeSameOrAfter,
  inputDateToServerFormat,
  convertToUtcMoment,
  utcDateToMeetingDateFormat,
} from '../../../app/utils/dateTimeHelpers';
import {
  useMeetingTypesQuery,
  useReserveAppointmentMutation,
  useUnreserveAppointmentMutation,
  useBookAppointmentMutation,
  useTimeslotsQuery,
  useRescheduleAppountmentMutation,
} from '../queries';
import { ILocale } from '../../../app/providers/LocaleProvider/types';
import { ClientPublicService } from '../../../app/api/ClientPublicService';
import { ROUTES } from '../../../app/routes/constants';
import useAuthService from '../../../app/hooks/useAuthService';

const SelectTime = () => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const { isAuthenticated } = useAuthService();

  const { t, locale, getLocalizedDtoName, getLocalizedDate } = useLocale();
  const { showModal, closeModal } = useModal();
  const { appointmentDetails, updateAppointmentDetails } = useBookAppointment();

  const [timeslot, setTimeslot] = useState<moment.Moment | undefined>();

  const [date, setDate] = useState<moment.Moment | null>(inputDateToServerFormat(appointmentDetails?.dateTime) || null);
  const [firstAvailableDate, setFirstAvailableDate] = useState<moment.Moment | null>(null);

  const [noTimeslotsAvailableForOfficeDialog, showNoTimeslotsAvailableForOffice] = useState<boolean>(false);
  const [meetingTypeEnum, setMeetingTypeEnum] = useState<ClientPublicService.MeetingTypeEnum | undefined>(
    appointmentDetails?.meetingTypeEnum
  );

  const { data: meetingTypes, isLoading: isLoadingMeetingTypes } = useMeetingTypesQuery() || {};

  const scheduledAppointmentTimeslot = useMemo(
    () => (appointmentId ? utcDateToMeetingDateFormat(appointmentDetails?.dateTime) : undefined),
    [appointmentDetails?.dateTime, appointmentId]
  );

  const isScheduledAppointmentOnSameDay = useMemo(
    () => date && scheduledAppointmentTimeslot && date?.isSame(scheduledAppointmentTimeslot, 'day'),
    [date, scheduledAppointmentTimeslot]
  );

  const {
    data: timeslots,
    isFetching: isTimeslotsLoading,
    isSuccess,
    refetch,
    remove,
  } = useTimeslotsQuery(
    {
      meetingTypeEnum,
      date: date || undefined,
      officeLocationId: appointmentDetails?.officeLocationId,
      isPreferedLanguageEnglish: locale === ILocale.en,
    },
    appointmentDetails?.agentId
  ) || {};

  const { mutate: reserve, isLoading: isReserving } = useReserveAppointmentMutation() || {};
  const { mutate: unreserve, isLoading: isUnreserving } = useUnreserveAppointmentMutation() || {};
  const { mutate: reschedule, isLoading: isRescheduling } = useRescheduleAppountmentMutation() || {};
  const { mutate: book, isLoading: isBooking } = useBookAppointmentMutation() || {};

  const timeranges = useMemo(
    () => [
      {
        from: '07:00',
        to: '11:30',
        name: t.MORNING,
      },
      {
        from: '12:00',
        to: '16:30',
        name: t.AFTERNOON,
      },
      {
        from: '17:00',
        to: '23:30',
        name: t.EVENING,
      },
    ],
    [t.AFTERNOON, t.EVENING, t.MORNING]
  );

  const getTimeslotsWithinTimerange = useCallback(
    (from: string, to: string) => {
      let timeSlots = timeslots?.timeSlots;
      if (scheduledAppointmentTimeslot && isScheduledAppointmentOnSameDay) {
        const filetered = [...(timeSlots || [])]?.filter(
          (item) => !item.isSame(scheduledAppointmentTimeslot, 'minute')
        );
        timeSlots = [...filetered, scheduledAppointmentTimeslot]?.sort((a, b) => a.diff(b, 'minute'));
      }

      return timeSlots?.filter(
        (item?: moment.Moment) =>
          isTimeSameOrAfter(getLocalizedDate(item, TIME_FORMAT), from) &&
          isTimeSameOrBefore(getLocalizedDate(item, TIME_FORMAT), to)
      );
    },
    [getLocalizedDate, isScheduledAppointmentOnSameDay, scheduledAppointmentTimeslot, timeslots?.timeSlots]
  );

  const showTimeslotTakenDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE}
        message={t.PLEASE_SELECT_ANOTHER_TIME_SLOT}
        hasCancelButton={false}
        okButtonText={t.SELECT_ANOTHER_TIME_SLOT}
        onOk={() => {
          closeModal();
          refetch();
        }}
      />
    );
  }, [
    showModal,
    t.THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE,
    t.PLEASE_SELECT_ANOTHER_TIME_SLOT,
    t.SELECT_ANOTHER_TIME_SLOT,
    closeModal,
    refetch,
  ]);

  const navigateToSelectOffice = useCallback(
    () =>
      appointmentId
        ? navigate(`${ROUTES.RESCHEDULE_APPOINTMENT}/${appointmentId}/${ROUTES.SELECT_OFFICE}`)
        : navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_OFFICE}`),
    [appointmentId, navigate]
  );

  const handleRescheduleAppointment = useCallback(async () => {
    const body = {
      appointmentId,
      dateTime: convertToUtcMoment(timeslot) || undefined,
      officeLocationId: appointmentDetails?.officeLocationId,
      meetingTypeEnum,
    };
    reschedule(body as ClientPublicService.UpdateAppointmentPublicDto, {
      onSuccess: (data) => {
        if (data?.result === ClientPublicService.Result.Successful && data?.returnId) {
          navigate(`${ROUTES.RESCHEDULE_APPOINTMENT}/${data?.returnId}/${ROUTES.CONFIRMATION}`);
        } else {
          toast.error(data?.messages?.[0]?.body);
        }
      },
    });
  }, [appointmentDetails?.officeLocationId, appointmentId, meetingTypeEnum, navigate, reschedule, timeslot]);

  const handleBookTimeslot = useCallback(() => {
    const body = {
      dateTime: convertToUtcMoment(timeslot) || undefined,
      meetingTypeEnum,
      officeLocationId: appointmentDetails?.officeLocationId,
    };
    book(body as ClientPublicService.BookAppointmentInputPublicDto, {
      onSuccess: (data) => {
        if (data?.result === ClientPublicService.Result.Successful && data?.returnId) {
          navigate(`${ROUTES.RESCHEDULE_APPOINTMENT}/${data?.returnId}/${ROUTES.CONFIRMATION}`);
        } else {
          toast.error(data?.messages?.[0]?.body);
        }
      },
    });
  }, [appointmentDetails?.officeLocationId, book, meetingTypeEnum, navigate, timeslot]);

  const handleReserveTimeslot = useCallback(() => {
    const body = {
      meetingTypeEnum,
      dateTime: convertToUtcMoment(timeslot) || undefined,
      officeLocationId: appointmentDetails?.officeLocationId,
      isPreferedLanguageEnglish: locale === ILocale.en,
    };

    reserve(body as ClientPublicService.IReserveAppointmentDto, {
      onSuccess: (response) => {
        if (response?.result === ClientPublicService.Result.Successful) {
          updateAppointmentDetails?.({
            meetingTypeEnum,
            dateTime: timeslot || undefined,
            isPreferedLanguageEnglish: locale === ILocale.en,
            agentId: response?.returnId,
            countdownStartedAt: moment(),
          });
          navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.CONTACT_DETAILS}`);
          return;
        }

        if (
          response?.hasErrors &&
          response?.messages?.[0]?.body === 'Public_AppointmentScheduling_Appointment_NotAvailable'
        ) {
          showTimeslotTakenDialog();
        }
      },
    });
  }, [
    meetingTypeEnum,
    timeslot,
    appointmentDetails?.officeLocationId,
    locale,
    reserve,
    updateAppointmentDetails,
    navigate,
    showTimeslotTakenDialog,
  ]);

  useEffect(() => {
    if (
      timeslots?.timeSlots &&
      timeslots?.timeSlots?.length === 0 &&
      (!scheduledAppointmentTimeslot || !isScheduledAppointmentOnSameDay)
    ) {
      if (!timeslots?.isInitial) {
        // No timeslots available for a specific date
        toast.error(t.NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_DATE);
      } else {
        // No initial timeslots available for office
        showNoTimeslotsAvailableForOffice(true);
      }
    }
  }, [
    date,
    firstAvailableDate,
    isScheduledAppointmentOnSameDay,
    scheduledAppointmentTimeslot,
    t.NO_TIMESLOTS_AVAILABLE_FOR_THE_SELECTED_DATE,
    timeslots?.isInitial,
    timeslots?.timeSlots,
  ]);

  useEffect(() => {
    if (timeslots?.timeSlots && timeslots?.timeSlots?.length > 0) {
      setTimeslot(undefined);
    }
  }, [isSuccess, timeslots?.timeSlots]);

  useEffect(() => {
    if (timeslots?.timeSlots && timeslots?.timeSlots?.[0] && timeslots?.isInitial && !date && !firstAvailableDate) {
      const firstAvailableDate = inputDateToServerFormat(timeslots?.timeSlots?.[0]);
      if (firstAvailableDate) setFirstAvailableDate(firstAvailableDate);
    }
  }, [date, firstAvailableDate, timeslots?.isInitial, timeslots?.timeSlots]);

  useEffect(() => {
    // Unreserve appointment if user returs from contact details page
    if (
      appointmentDetails?.agentId &&
      appointmentDetails?.dateTime &&
      appointmentDetails?.officeLocationId &&
      !appointmentId
    ) {
      unreserve(
        {
          agentId: appointmentDetails?.agentId,
          dateTime: convertToUtcMoment(appointmentDetails?.dateTime),
          officeLocationId: appointmentDetails?.officeLocationId,
        },
        {
          onSuccess: () => {
            updateAppointmentDetails?.({ agentId: undefined, countdownStartedAt: undefined });
          },
        }
      );
    }
  }, [
    appointmentDetails?.agentId,
    appointmentDetails?.dateTime,
    appointmentDetails?.officeLocationId,
    unreserve,
    timeslots,
    updateAppointmentDetails,
    appointmentId,
  ]);

  useEffect(() => {
    if (!appointmentDetails?.officeLocationId && !appointmentId) {
      navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_OFFICE}`, { replace: true });
    }
  }, [appointmentDetails?.officeLocationId, appointmentId, navigate]);

  return (
    <Wrapper
      data-testid="select-time-page"
      loading={
        isTimeslotsLoading || isUnreserving || isReserving || isLoadingMeetingTypes || isRescheduling || isBooking
      }
    >
      <ErrorBoundary>
        <Container maxWidth="tablet">
          <Header
            title={appointmentId ? t.RESCHEDULE_YOUR_APPOINTMENT : t.BOOK_YOUR_APPOINTMENT}
            subtitle={appointmentDetails?.officeLocationAddress}
            hasBackButton
            backButtonAction={navigateToSelectOffice}
            backButtonText={t.CHANGE_OFFICE}
          />

          <Grid container flexDirection="row" justifyContent="center">
            <Grid item mobile={12} tablet={8}>
              <Stack direction="row" spacing={2} data-testid="meeting-type-buttons">
                {meetingTypes &&
                  (meetingTypes?.filter((item) => item.code !== 'unknown') || [])?.map((item, id) => (
                    <CustomToggleButton
                      key={`meeting-type-toggle-button-${id}`}
                      value={item?.enumValue as any}
                      selected={meetingTypeEnum === item?.enumValue}
                      onClick={() => {
                        if (meetingTypeEnum !== item?.enumValue) {
                          remove(); // removes previous results from cache for smooth UI
                          setMeetingTypeEnum(item?.enumValue);
                          setFirstAvailableDate(null);
                          setDate(null);
                        }
                      }}
                      fullWidth
                    >
                      {getLocalizedDtoName(item)}
                    </CustomToggleButton>
                  ))}
              </Stack>

              <DateCalendarComponent value={date || firstAvailableDate} onChange={setDate} />
            </Grid>
          </Grid>

          {timeslots?.timeSlots &&
          (timeslots?.timeSlots?.length || (scheduledAppointmentTimeslot && isScheduledAppointmentOnSameDay)) ? (
            <>
              <Typography variant="h6" textAlign="center" sx={{ pt: 3.5, pb: 2.5 }}>
                {t.SELECT_ONE_HOUR_TIMESLOT} {timeslots?.timeZone ? `(${timeslots?.timeZone})` : ''}
              </Typography>
              <Box data-testid="timeslots">
                {timeranges?.map((item, index) => (
                  <TimeslotGroup
                    key={`timerange-${index}`}
                    timeslots={getTimeslotsWithinTimerange(item.from, item.to)}
                    title={item.name}
                    onTimeslotSelect={setTimeslot}
                    selectedTimeslot={timeslot}
                    scheduledAppointmentTimeslot={scheduledAppointmentTimeslot}
                  />
                ))}
              </Box>
              <Grid container flexDirection="row" justifyContent="center" pt={4}>
                <Grid item mobile={12} tablet={6}>
                  <Stack spacing={2}>
                    <Button
                      variant="contained"
                      disabled={!Boolean(timeslot)}
                      fullWidth
                      onClick={
                        appointmentId
                          ? handleRescheduleAppointment
                          : isAuthenticated()
                          ? handleBookTimeslot
                          : handleReserveTimeslot
                      }
                      tabIndex={-1}
                    >
                      {appointmentId || isAuthenticated() ? t.SAVE : t.CONTINUE}
                    </Button>

                    {Boolean(appointmentId) && (
                      <Button variant="outlined" onClick={() => navigate(ROUTES.APPOINTMENTS)} fullWidth>
                        {t.BACK_TO_APPOINTMENTS}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Container>
      </ErrorBoundary>

      <ConfirmationModal
        open={noTimeslotsAvailableForOfficeDialog}
        title={t.THERE_ARE_NO_AVAILABLE_TIMESLOTS_FOR_THIS_OFFICE}
        message={
          <>
            {t.CLICK_ON_CHANGE_OFFICE_TO_SELECT_ANOTHER_NEARBY_OFFICE}
            <PhoneLink target="_blank" rel="noopener" href="tel:1-855-236-3328">
              1-855-BDO DEBT
            </PhoneLink>
          </>
        }
        okButtonText={t.CLOSE}
        onOk={() => showNoTimeslotsAvailableForOffice(false)}
        cancelButtonText={t.CHANGE_OFFICE}
        onCancel={navigateToSelectOffice}
      />
    </Wrapper>
  );
};

export default SelectTime;

const PhoneLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
