/**
 * ABP Auth Server (OpenIdDict) client associated config.
 *
 */
export const identityClientConfig = () => {
  return {
    /**
     * (string): The URL of the OIDC provider. It is Auth Server url.
     */
    authority: process.env.REACT_APP_AUTH_URL ?? '',
    /**
     * (string): Your client application's identifier as registered with the OIDC provider.
     */
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID ?? '',
    /**
     * The URI of your client application to receive a response from the OIDC provider.
     *  */
    redirect_uri: process.env.REACT_APP_REDIRECT_URL ?? '',
    automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL ?? '', //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL ?? '', // (string): The OIDC post-logout redirect URI.
    /**
     * A way to specific the audience when making the jwt. Optional.
     */
    audience: process.env.REACT_APP_AUDIENCE ?? '',
    response_type: 'code', // "code" is PECK approach. //"id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    filterProtocolClaims: true, // PECK way.
    grant_type: 'authorization_code', //"password",

    /**
     * (string, default: 'openid'): The scope being requested from the OIDC provider.
     */
    scope: process.env.REACT_APP_SCOPE ?? '',
    /**
     * revoke (reference) access tokens at logout time
     */
    revokeAccessTokenOnSignout: true,
    /**
     * integer value of seconds. Default value = 300.
     * Work for resolve login infinity looping if client machine datetime is in correct.
     * Set clockSkew is 15 minutes now.
     *
     * */
    clockSkew: 900,

    /**
     * url from Auth0, used to manuall call to signout from Auth0.
     */
    auth0_logoff_url: process.env.REACT_APP_AUTH0_LOGOFF_URL ?? '',
    /**
     * Client Id of Auth0, used to manually signout from Auth0.
     */
    auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    /**
     * Current client side application's default home page url.
     * Work for redirect back to home page after sign out from Auth0.
     */
    app_url: process.env.REACT_APP_PUBLIC_URL ?? '',
  };
};
