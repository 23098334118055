import { useCallback, useState } from 'react';
import { Button, MenuList, MenuItem, Stack, Box, styled, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik, Form } from 'formik';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { TextField } from '../../../components/FormFields';
import GoogleMapsLoaderWrapper from './GoogleMapsLoaderWrapper';

import useLocale from '../../../app/hooks/useLocale';
import { IUserLocation } from '../types';

interface FormValues {
  address?: string;
}

export interface ILocationSearchForm {
  userLocation?: IUserLocation;
  onSubmit?: (userLocation?: IUserLocation) => void;
}

const LocationSearchFormComponent = ({ userLocation, onSubmit }: ILocationSearchForm) => {
  const { t } = useLocale();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!values?.address) return;

      const result = await geocodeByAddress(values?.address);

      if (result && result[0]) {
        const geocode = result[0];
        const coordinates = await getLatLng(geocode);

        onSubmit?.({ address: values?.address, coordinates });
      }
    },
    [onSubmit]
  );

  return (
    <Box data-testid="location-search-form" sx={{ paddingBottom: 2 }}>
      <Formik
        initialValues={{
          address: userLocation?.address || '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          handleSubmit(values);
          setSubmitting(false);
          resetForm({ values });
        }}
      >
        {(form) => (
          <StyledForm>
            <Stack spacing={4}>
              <PlacesAutocomplete
                value={form.values?.address}
                onChange={(value) => {
                  setDisabledSubmit(true);
                  form.setFieldValue('address', value);
                }}
                onSelect={(value) => {
                  setDisabledSubmit(false);
                  form.setFieldValue('address', value);
                }}
                searchOptions={{
                  componentRestrictions: {
                    country: 'ca',
                  },
                  types: ['geocode'],
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <Stack>
                    <TextField
                      key={t.ADDRESS_OR_POSTAL_CODE}
                      inputProps={{ ...getInputProps() }}
                      name="address"
                      label={t.ADDRESS_OR_POSTAL_CODE}
                      data-testid="address-input"
                      required
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: form?.values?.address ? 'visible' : 'hidden', p: 0 }}
                            onClick={() => {
                              setDisabledSubmit(true);
                              form.setFieldValue('address', '');
                            }}
                            aria-label="clear address field"
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      onKeyDown={(e: any) => {
                        if (e?.key === 'Enter') {
                          e?.preventDefault?.();
                        }
                      }}
                    />
                    {Boolean(suggestions?.length) && (
                      <Box style={{ position: 'relative' }}>
                        <SuggestionsList>
                          {suggestions?.map((suggestion, index) => (
                            <MenuItem
                              {...getSuggestionItemProps(suggestion)}
                              key={`suggestion-${index}`}
                              style={{ whiteSpace: 'normal' }}
                              data-testid={suggestion.description}
                              onClick={(e) => {
                                getSuggestionItemProps(suggestion)?.onClick(e);
                                setDisabledSubmit(false);
                                form.setFieldValue('address', suggestion.description);
                              }}
                            >
                              {suggestion.description}
                            </MenuItem>
                          ))}
                        </SuggestionsList>
                      </Box>
                    )}
                  </Stack>
                )}
              </PlacesAutocomplete>
              <Button
                data-testid="search-for-offices-button"
                disabled={disabledSubmit}
                type="submit"
                variant="contained"
                size="large"
              >
                {t.SEARCH_FOR_OFFICES}
              </Button>
            </Stack>
          </StyledForm>
        )}
      </Formik>
    </Box>
  );
};

const LocationSearchForm = (props: ILocationSearchForm) => (
  <GoogleMapsLoaderWrapper component={<LocationSearchFormComponent {...props} />} />
);

export default LocationSearchForm;

const SuggestionsList = styled(MenuList)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: 'white',
  padding: 0,
}));

const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
