import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import GoogleMapsLoaderWrapper from '../../GoogleMapsLoaderWrapper/GoogleMapsLoaderWrapper';
import Loader from '../../Loader';

import useLocale from '../../../app/hooks/useLocale';

import { useAppointmentByIdMutation, useMeetingTypesQuery } from '../../../features/Appointments/queries';
import { ROUTES } from '../../../app/routes';
import { useCallback } from 'react';

const ActionButton = ({ appointmentId }: { appointmentId?: string }) => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const { mutate: requestAppointmentById, isLoading: isLoadingAppointmentDetails } = useAppointmentByIdMutation() || {};
  const { data: meetingTypes, isLoading: isLoadingMeetingTypes } = useMeetingTypesQuery() || {};

  const handleReschedule = useCallback(() => {
    requestAppointmentById(appointmentId, {
      onSuccess: async (data) => {
        const address = data?.officeAddress as string;
        const result = await geocodeByAddress(address);
        const coordinates = await getLatLng(result[0]);

        const userLocation = {
          address,
          coordinates,
        };

        const appointmentDetails = {
          dateTime: data?.startDateTimeLocal,
          meetingTypeEnum: meetingTypes?.find((x) => x.id === data?.meetingTypeId)?.enumValue,
          officeLocationId: data?.officeLocationId,
          officeLocationAddress: address,
        };

        sessionStorage.setItem('userLocation', JSON.stringify(userLocation));
        sessionStorage.setItem('appointmentDetails', JSON.stringify(appointmentDetails));

        navigate(`${ROUTES.RESCHEDULE_APPOINTMENT}/${appointmentId}/${ROUTES.SELECT_TIME}`);
      },
    });
  }, [appointmentId, meetingTypes, navigate, requestAppointmentById]);

  return (
    <>
      <Loader loading={isLoadingAppointmentDetails || isLoadingMeetingTypes} />
      <Button data-testid="reschedule-button" fullWidth variant="outlined" onClick={handleReschedule}>
        {t.RESCHEDULE}
      </Button>
    </>
  );
};

const RescheduleButton = (props: any) => <GoogleMapsLoaderWrapper component={<ActionButton {...props} />} />;

export default RescheduleButton;
