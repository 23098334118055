import { createContext, useState, useCallback, useMemo } from 'react';

import { IIncomeModalTab } from './types';

import useLocale from '../../app/hooks/useLocale';

import EditIncomeForm from './EditIncomeForm';
import EditIncomeDocumentsTab from './EditIncomeDocumentsTab';

import { IExpenderType } from '../ExpenderTabs';
import { ISupportDocsUploadProps } from '../DocumentsUpload/SupportDocsUpload';

export const SOURCE_TAB = 'source';
export const DOCUMENTS_TAB = 'documents';

interface IEditIncomeModalContext {
  tabs?: IIncomeModalTab[];
  setIsDocumentTabVisible?: (isVisible: boolean) => void;
  currentTab: number;
  tabsCount: number;
  goToNextTab?: () => void;
  goToPreviousTab?: () => void;
  expenderTab: IExpenderType;
  setExpenderTab: (tab: IExpenderType) => void;
  isEditable?: boolean;
  isMissingDocuments?: boolean;
}

export const initialEditIncomeModalContextValues: IEditIncomeModalContext = {
  tabs: [],
  setIsDocumentTabVisible: () => {},
  currentTab: 0,
  tabsCount: 0,
  goToNextTab: () => {},
  goToPreviousTab: () => {},
  expenderTab: 'applicantAmount',
  setExpenderTab: () => {},
  isEditable: true,
  isMissingDocuments: false,
};

export const EditIncomeModalContext = createContext<IEditIncomeModalContext>(initialEditIncomeModalContextValues);

interface IProps {
  children: React.ReactElement;
  isEditable?: boolean;
  documentsUploadProps?: ISupportDocsUploadProps;
  isMissingDocuments?: boolean;
}

const EditIncomeModalProvider = ({
  children,
  isEditable,
  documentsUploadProps,
  isMissingDocuments,
}: IProps): React.ReactElement => {
  const { t } = useLocale();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isDocumentTabVisible, setIsDocumentTabVisible] = useState<boolean>(false);
  const [expenderTab, setExpenderTab] = useState<IExpenderType>('applicantAmount');

  const tabs = useMemo(
    () =>
      [
        {
          label: t.SOURCE,
          key: SOURCE_TAB,
          children: <EditIncomeForm />,
          conditionalRender: true,
        },
        {
          label: t.DOCUMENTS,
          key: DOCUMENTS_TAB,
          children: <EditIncomeDocumentsTab {...documentsUploadProps} />,
          conditionalRender: isDocumentTabVisible,
        },
      ]?.filter((tab) => Boolean(tab?.conditionalRender)),
    [documentsUploadProps, isDocumentTabVisible, t.DOCUMENTS, t.SOURCE]
  );

  const tabsCount = useMemo(() => tabs?.length || 0, [tabs]);

  const goToNextTab = useCallback(() => {
    setCurrentTab(currentTab < tabsCount - 1 ? currentTab + 1 : currentTab);
  }, [currentTab, tabsCount]);

  const goToPreviousTab = useCallback(() => {
    setCurrentTab(currentTab > 0 ? currentTab - 1 : 0);
  }, [currentTab]);

  return (
    <EditIncomeModalContext.Provider
      value={{
        tabs,
        setIsDocumentTabVisible,
        currentTab,
        tabsCount,
        goToNextTab,
        goToPreviousTab,
        expenderTab,
        setExpenderTab,
        isEditable,
        isMissingDocuments,
      }}
    >
      {children}
    </EditIncomeModalContext.Provider>
  );
};

export default EditIncomeModalProvider;
