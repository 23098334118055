import moment from 'moment-timezone';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

const DAY_END = '24:00';
const DAY_START = '00:00';

export const YEAR_FORMAT = 'YYYY';
export const DATE_FORMAT = 'DD/MMM/YYYY';
export const DATE_FORMAT2 = 'YYYY-MM-DD';
export const DATE_FORMAT3 = 'MMMM DD, YYYY';
export const DATE_FORMAT4 = 'ddd, MMM DD, YYYY';
export const DATE_FORMAT5 = 'MMMM YYYY';
export const DATE_FORMAT6 = 'MMM DD, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MEETING_TIME_FORMAT = 'hh:mm A';
export const MEETING_DATE_FORMAT = 'dddd, MMM DD, YYYY';
export const DATE_TIME_FORMAT = 'dddd, MMM DD, YYYY hh:mm A';
export const DATE_TIME_FORMAT2 = 'dddd, MMM DD, YYYY, hh:mm A';
export const DATE_TIME_FORMAT3 = 'ddd, MMM DD, YYYY';
export const DATE_TIME_FORMAT4 = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FORMAT5 = 'MMM DD, YYYY hh:mm a';

export const ISO_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const SERVER_MEETING_TIME_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const disableDateBefore = (date?: moment.Moment, beforeDate?: moment.Moment) =>
  moment(date, true)?.isBefore(beforeDate || moment(), 'd');
const disableDateAfter = (date?: moment.Moment, afterDate?: moment.Moment) =>
  moment(date, true)?.isAfter(afterDate || moment(), 'd');

const getToday = () => moment();
const getTomorrow = () => moment().add(1, 'd');
const getDateNTimeunitsFromToday = (n: number, timeunit: moment.unitOfTime.DurationConstructor) =>
  moment().add(n, timeunit);
const getDifference = (date1: moment.Moment, date2: moment.Moment, timeunit: moment.unitOfTime.DurationConstructor) =>
  moment(date1).diff(date2, timeunit);

const isTimeSameOrAfter = (item: string, from: string | undefined) => {
  return moment(item, TIME_FORMAT).isSameOrAfter(moment(from || DAY_START, TIME_FORMAT));
};

const isTimeSameOrBefore = (item: string, to: string | undefined) => {
  return moment(item, TIME_FORMAT).isSameOrBefore(moment(to || DAY_END, TIME_FORMAT));
};

const getDateFromString = (date: string, format?: string) => (format ? moment(date, format) : moment(date, true));

const isSame = (date1: moment.Moment, date2: moment.Moment) => moment(date1, true).isSame(date2);

const inputDateToServerFormat = (date?: moment.Moment | null) =>
  date ? moment(date?.format(DATE_FORMAT2)).utc(true) : undefined;

const serverDateToInputFormat = (date?: moment.Moment) => (date ? moment.utc(date) : undefined);

const convertToUtcMoment = (date?: moment.Moment | undefined) => {
  if (!date) return date;

  const utcDate = moment.utc(Date.UTC(date.year(), date.month(), date.date(), date.hour(), date.minute()));
  return utcDate;
};

const convertValueToISOString = (value?: moment.Moment, format?: string) =>
  moment(value, format).format(ISO_STRING_FORMAT);

const isInputStringConvertableToValidDate = (value?: string, format?: string) => {
  const formattedValueToISOString = moment(value, format).format(ISO_STRING_FORMAT);
  const timestampFromISOString = Date.parse(formattedValueToISOString);
  const isISOStringValidDate = !isNaN(timestampFromISOString);
  const isISODateEqualToValue = moment(timestampFromISOString).format(format) === value;
  return isISOStringValidDate && isISODateEqualToValue;
};

const utcDateToMeetingDateFormat = (date?: moment.Moment) =>
  date ? moment(date?.utc().format(SERVER_MEETING_TIME_STRING_FORMAT)) : undefined;

export {
  disableDateBefore,
  disableDateAfter,
  getToday,
  getTomorrow,
  getDateNTimeunitsFromToday,
  isTimeSameOrAfter,
  isTimeSameOrBefore,
  getDateFromString,
  isSame,
  inputDateToServerFormat,
  convertToUtcMoment,
  getDifference,
  serverDateToInputFormat,
  isInputStringConvertableToValidDate,
  utcDateToMeetingDateFormat,
  convertValueToISOString,
};

export { default } from 'moment';
