import { useCallback, useEffect, useMemo } from 'react';
import { Button, Typography, Stack, Container, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmationBlock from './ConfirmationBlock';
import Wrapper from './Wrapper';
import ConfirmCheck from './ConfirmCheck';
import ConfirmationModal from '../../../components/ConfirmationModal';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';
import useBookAppointment from '../hooks/useBookAppointment';

import { DATE_TIME_FORMAT2 } from '../../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../../app/api/ClientPublicService';
import {
  useAppointmentQuery,
  useAppointmentTypesQuery,
  useMeetingTypesQuery,
  usePhoneTypesQuery,
  useCancelAppointmentMutation,
} from '../queries';
import { ROUTES } from '../../../app/routes/constants';

const Confirmation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { appointmentId } = useParams<{ appointmentId?: string }>();

  const isRescheduled = useMemo(
    () => location?.pathname?.includes(ROUTES.RESCHEDULE_APPOINTMENT) && appointmentId,
    [appointmentId, location?.pathname]
  );

  const { t, getLocalizedDtoName, getLocalizedDate } = useLocale();
  const { showModal, closeModal } = useModal();
  const { setIsConfirmed, resetAppointmentDetails, setUserLocation } = useBookAppointment();

  const { data: appointmentTypes } = useAppointmentTypesQuery() || {};

  const { data: meetingTypes } = useMeetingTypesQuery() || {};

  const { data: phoneTypes } = usePhoneTypesQuery() || {};

  const { data, isLoading: isLoadingAppointment } = useAppointmentQuery(appointmentId) || {};

  const { mutate, isLoading: isCancellingAppointment } = useCancelAppointmentMutation() || {};

  const showCancelAppointmentDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT}
        message={t.BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY}
        cancelButtonText={t.GO_BACK}
        okButtonText={t.CANCEL_APPOINTMENT}
        onOk={() => {
          mutate(appointmentId, {
            onSuccess: (response) => {
              if (response?.result === ClientPublicService.Result.Successful) {
                toast.success(t.APPOINTMENT_SUCCESSFULLY_CANCELLED);
                navigate(ROUTES.ROOT);
              } else {
                toast.error(response?.messages?.[0]?.body);
              }
            },
          });
          closeModal();
        }}
        onCancel={closeModal}
      />
    );
  }, [
    showModal,
    t.YOURE_ABOUT_TO_CANCEL_YOUR_APPOINTMENT,
    t.BUT_YOU_CAN_ALWAYS_COME_BACK_LATER_TO_BOOK_ANOTHER_APPOINTMENT_WHEN_YOU_ARE_READY,
    t.GO_BACK,
    t.CANCEL_APPOINTMENT,
    t.APPOINTMENT_SUCCESSFULLY_CANCELLED,
    closeModal,
    mutate,
    appointmentId,
    navigate,
  ]);

  useEffect(() => {
    setIsConfirmed?.(true);
    resetAppointmentDetails?.(undefined);
    setUserLocation?.(undefined);
  }, [resetAppointmentDetails, setIsConfirmed, setUserLocation]);

  const fullName = useMemo(() => `${data?.firstName || ''} ${data?.lastName || ''}`, [data]);

  const timeZone = useMemo(() => (data?.timeZoneCode ? `(${data?.timeZoneCode})` : ''), [data]);

  const meetingDuration = useMemo(
    () => (data?.duration && !isNaN(data?.duration) ? `(${data?.duration / 60} ${t.HOUR || ''})` : ''),
    [data?.duration, t.HOUR]
  );

  const phoneTypeDetails = useMemo(() => {
    const phoneType = phoneTypes?.find((item) => item?.id === data?.mainPhoneTypeId);
    const localizedPhoneType = getLocalizedDtoName(phoneType);
    return localizedPhoneType ? `(${localizedPhoneType})` : '';
  }, [data?.mainPhoneTypeId, getLocalizedDtoName, phoneTypes]);

  const meetingTypeDetails = useMemo(() => {
    const meetingType = meetingTypes?.find((item) => item?.id === data?.meetingTypeId);
    return getLocalizedDtoName(meetingType);
  }, [data?.meetingTypeId, getLocalizedDtoName, meetingTypes]);

  const appointmentTypeDetails = useMemo(() => {
    const appointmentType = appointmentTypes?.find((item) => item?.id === data?.appointmentTypeId);
    return getLocalizedDtoName(appointmentType);
  }, [appointmentTypes, getLocalizedDtoName, data?.appointmentTypeId]);

  const appointmentTypeEnum = useMemo(
    () => appointmentTypes?.find((item) => item?.id === data?.appointmentTypeId)?.enumValue,
    [appointmentTypes, data?.appointmentTypeId]
  );

  return (
    <Wrapper loading={isLoadingAppointment || isCancellingAppointment}>
      <Container maxWidth="tablet">
        <Stack flexDirection="column" alignItems="center" sx={{ paddingBottom: 4 }}>
          <ConfirmCheck />
          <Typography variant="h1" textAlign="center" sx={{ lineHeight: 1 }}>
            {!isRescheduled
              ? t.YOURE_BOOKED_WITH_ONE_OF_OUR_DEBT_SOLUTION_ADVISORS
              : appointmentTypeEnum === ClientPublicService.AppointmentTypeEnum.InitialConsultation
              ? t.YOUR_APPOINTMENT_HAS_BEEN_BOOKED
              : t.YOURE_APPOINTMENT_HAS_BEEN_RESCHEDULED}
          </Typography>
          <Typography variant="body2" sx={{ paddingTop: 1.5 }}>
            {t.WEVE_SENT_YOU_AN_EMAIL_CONFIRMATION}
          </Typography>
        </Stack>

        <ConfirmationBlock
          title={t.CONTACT_INFORMATION}
          content={[fullName, data?.emailAddress, `${data?.mainPhoneNumber || ''} ${phoneTypeDetails}`]}
        />
        <ConfirmationBlock title={t.LOCATION} content={[data?.officeAddress || '']} />
        <ConfirmationBlock
          title={t.DATE_AND_TIME}
          content={[`${getLocalizedDate(data?.startDateTimeLocal?.utc(), DATE_TIME_FORMAT2)} ${timeZone}`]}
        />
        <ConfirmationBlock
          title={t.APPOINTMENT_TYPE}
          content={[`${meetingTypeDetails} ${meetingDuration}`, appointmentTypeDetails]}
        />

        <Stack flexDirection="column" alignItems="center" spacing={3}>
          {!isRescheduled && (
            <Button
              data-testid="cancel-appointment-button"
              variant="text"
              startIcon={<CancelOutlinedIcon style={{ fontSize: 24 }} />}
              onClick={showCancelAppointmentDialog}
              sx={{
                color: theme.palette.urgentRed,
                fontSize: 14,
                p: 0,
                '.MuiButton-startIcon': {
                  mr: 0.25,
                },
              }}
            >
              {t.CANCEL_APPOINTMENT}
            </Button>
          )}
          <Button
            data-testid="finish-button"
            variant="contained"
            size="large"
            onClick={() => (isRescheduled ? navigate(ROUTES.DASHBOARD) : navigate(ROUTES.ROOT))}
            sx={{ width: { mobile: '100%', tablet: '50%' } }}
          >
            {isRescheduled ? t.BACK_TO_DASHBOARD : t.BACK_TO_HOMEPAGE}
          </Button>
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default Confirmation;
