import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { identityClientConfig } from '../config/identityClientConfig';
import { identityMETAConfig } from '../config/identityMetaConfig';
import { IAuthService } from './iauthService';
import { IUser } from './types';
import { ILocale } from '../providers/LocaleProvider/types';

/**
 * Authentication/Authorization service.
 *
 * Reference: https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
 *
 * Note: As for ABP framework, developer is able to get Logon user's detail information from end point: api/abp/application-configuration.
 * In current design, sytem already consumed above web api data and save it into Redux Storage, propert called "appContext".
 * Check appContext.currentUser property to get current logon user detail information.
 * Further, get logon user's assigned permissions from appContext.auth.grantedPolicies.
 */
export class AuthService implements IAuthService {
  userManager: UserManager;
  /**
   * Current selected language code. default value = 'en'.
   */
  currentLanguage = 'en';
  constructor() {
    this.userManager = new UserManager({
      ...identityClientConfig(),
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...identityMETAConfig(),
      },
    });

    this.userManager.events.addUserLoaded(() => {
      if (window.location.href.indexOf('auth-callback') !== -1) {
        this.navigateToHome();
      }
    });

    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
      this.signout();
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.userManager.signinRedirectCallback().then(
      () => {
        const redirectUrl = localStorage.getItem('redirectUri');
        const whitelist = [
          process.env.REACT_APP_PUBLIC_URL,
          process.env.REACT_APP_AUTH_URL,
          process.env.REACT_APP_REDIRECT_URL,
          process.env.REACT_APP_LOGOFF_REDIRECT_URL,
          process.env.REACT_APP_SILENT_REDIRECT_URL,
        ];

        if (whitelist.includes(redirectUrl || '')) {
          window.location.replace(redirectUrl || '');
        } else {
          this.navigateToHome();
        }
      },
      (error) => {
        // Handle authentication errors
        console.log(error);
        window.location.replace('/');
      }
    );
  };

  /**
   *  Get logon user object if system has. Otherwises, return null.
   *  Note: Here only try to return user profile info. It does not require system login.
   *  If no login user, then, return null.
   *  Work for UI dispolay user profile information purpose.
   *  For example, as for home page which is public page,
   *  system also will try to publish logon user info on the menu
   *  if there is any user login success.
   *
   *
   * Return User object json format:
   * {
   *   id,
   *   userName,
   *   firstName,
   *   lastName,
   *   email,
   *   roles
   * }
   *
   */
  getUser = () => {
    if (
      sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`) !=
      null
    ) {
      const oidcStorage = JSON.parse(
        /**
         * Note: It is OIDC-Client library default storage.
         * The key format is defined by oidc-client library.
         * Note: Developer no needs to modify the session key.
         * */
        sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`) ??
          ''
      );

      if (oidcStorage && oidcStorage.access_token && !oidcStorage.expired) {
        return this.toAppUser(oidcStorage);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  /**
   * Convert ID4 user to DTO user. Only called by getUser.
   * @param user Auth0 returned login user identity. 
   
  */
  toAppUser = (user: User) => {
    // console.log('toAppUser', user);
    const result: IUser = {};
    if (user && user.profile) {
      result.id = user.profile.sub;
      result.userName = (user.profile.unique_name as string) ?? '';
      result.email = (user.profile.email as string) ?? '';
      result.firstName = (user.profile.given_name as string) ?? '';
      result.lastName = (user.profile.family_name as string) ?? '';
      result.accessToken = user.access_token;
      //result.roles = user.profile.role as Role[]; // Support multiple roles. Note: Roles setting in dbo.AbpRoles -> dbo.AbpUserRoles -> dbo.AbpUsers.
    }

    return result;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signin = () => {
    this.signinRedirect();
  };

  /** Redirect to Identity Server 4 login page for login requirement. */
  signinRedirect = () => {
    //
    // Get current selected language code.
    //
    let locale = localStorage.getItem('locale');

    if (locale) this.currentLanguage = JSON.parse(locale);
    else this.currentLanguage = 'en';

    //
    // Temporary keep the current url.
    // Work for redirection after login.
    //
    localStorage.setItem('redirectUri', window.location.pathname);
    //
    // Note: Following "ui-culture" additional setting is specially for ABP framework Identity Server design.
    // Passing current selected localization to Identity Server login page.
    //
    this.userManager.signinRedirect({
      extraQueryParams: { ui_locales: this.currentLanguage === 'en' ? 'en' : 'fr-FR' },
    });
  };

  /** Redirect to Home page which is public page. */
  navigateToHome = () => {
    window.location.replace('/');
  };

  isAuthenticated = () => {
    try {
      if (
        sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`) !=
        null
      ) {
        const oidcStorage = JSON.parse(
          sessionStorage.getItem(`oidc.user:${identityClientConfig().authority}:${identityClientConfig().client_id}`) ??
            ''
        );
        return !!oidcStorage && !!oidcStorage.access_token && !oidcStorage.expired;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  signinSilent = () => {
    this.userManager
      .signinSilent()
      .then(() => {
        console.log('silent signed in success');
      })
      .catch((err) => {
        console.log('silent signed in error', err);
      });
  };

  signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
  };

  signout = () => {
    this.userManager
      .signoutRedirect({
        id_token_hint: localStorage.getItem('id_token') ?? '',
      })
      .finally(() => {
        this.userManager.revokeTokens();
        this.userManager.removeUser();
        this.userManager.clearStaleState();

        let locale = localStorage.getItem('locale');

        localStorage.clear();
        sessionStorage.clear();

        if (locale) localStorage.setItem('locale', locale);
      });
  };

  signoutRedirectCallback = () => {
    this.userManager.signoutRedirectCallback().finally(() => {
      this.userManager.revokeTokens();
      this.userManager.removeUser();
      this.userManager.clearStaleState();

      let locale = localStorage.getItem('locale');
      if (locale) locale = JSON.parse(locale);

      localStorage.clear();
      sessionStorage.clear();

      //
      // Note: Important step.
      // Call Auth0 logout endpoint to manually logout Auth0 session.
      //
      const authLogoutUrl = `${identityClientConfig().auth0_logoff_url}?client_id=${
        identityClientConfig().auth0_client_id
      }&returnTo=${identityClientConfig().app_url}/login?ui_locales=${locale === ILocale.fr ? ILocale.fr : ILocale.en}`;
      window.location.replace(authLogoutUrl);
    });
  };
}
