import { createContext, useState, useCallback } from 'react';
import { translations, LocaleObj } from './translations';
import { ILocale } from './types';
import LS from '../../../app/utils/localStorage';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ClientPublicService } from '../../api/ClientPublicService';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

export const LOCALE_STORAGE_KEY = 'locale';
export const UI_LOCALES_QUERY_STRING = 'ui_locales';
export const FRENCH_QUERY_STRING_LOCALE_VALUE = 'fr';
export const ENGLISH_QUERY_STRING_LOCALE_VALUE = 'en';

export interface ILocaleDto {
  id?: string;
  code?: string;
  name?: string;
  localizationString?: string;
  description?: string;
  descriptionLocalizationString?: string;
  displayName?: string;
  frenchName?: string;
}

type ILocaleDocument = Pick<ClientPublicService.DocumentDto, 'name' | 'frenchName'>;
type ILocaleSupportingDocument = Pick<ClientPublicService.SupportingDocumentDto, 'documentName' | 'frenchName'>;

interface ILocaleContext {
  locale: ILocale;
  setLocale: (locale: ILocale) => void;
  t: LocaleObj;
  getLocalizedDtoName: (dto?: ILocaleDto) => string;
  getLocalizedDtoDescription: (dto?: ILocaleDto) => string;
  getLocalizedDate: (date?: moment.Moment, format?: string) => string;
  getLocalizedDocumentName: (document?: ILocaleDocument) => string;
  getLocalizedSupporingDocumentName: (supportingDocument?: ILocaleSupportingDocument) => string;
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: ILocale.en,
  setLocale: () => {},
  t: translations.en,
  getLocalizedDtoName: () => '',
  getLocalizedDtoDescription: () => '',
  getLocalizedDate: () => '',
  getLocalizedDocumentName: () => '',
  getLocalizedSupporingDocumentName: () => '',
});

interface IProps {
  children: React.ReactElement;
}

const LocaleProvider = ({ children }: IProps): React.ReactElement => {
  const location = useLocation();

  const [locale, setLocale] = useState<ILocale>(() => {
    let browserLocale = navigator.language?.includes('fr') ? ILocale.fr : ILocale.en;
    let storageLocale = LS.get<ILocale>(LOCALE_STORAGE_KEY);
    let queryLocale = undefined;

    const params = new URLSearchParams(location?.search);

    if (params.has(UI_LOCALES_QUERY_STRING)) {
      params?.forEach((value) => {
        if (value === FRENCH_QUERY_STRING_LOCALE_VALUE) {
          queryLocale = ILocale.fr;
        }

        if (value === ENGLISH_QUERY_STRING_LOCALE_VALUE) {
          queryLocale = ILocale.en;
        }
      });

      params.delete(UI_LOCALES_QUERY_STRING);
    }

    const initialLocale = queryLocale || storageLocale || browserLocale;

    LS.set<ILocale>(LOCALE_STORAGE_KEY, initialLocale);

    return initialLocale;
  });

  const setAndSaveLocale = (locale: ILocale) => {
    setLocale(locale);
    LS.set<ILocale>(LOCALE_STORAGE_KEY, locale);
  };

  const getLocalizedDtoName = useCallback(
    (dto?: ILocaleDto) =>
      dto?.frenchName && locale === ILocale.fr
        ? dto?.frenchName
        : dto?.localizationString && translations[locale]?.[dto?.localizationString]
        ? translations[locale]?.[dto?.localizationString]
        : dto?.code && translations[locale]?.[dto?.code]
        ? translations[locale]?.[dto?.code]
        : dto?.name || '',
    [locale]
  );

  const getLocalizedDtoDescription = useCallback(
    (dto?: ILocaleDto) =>
      dto?.frenchName && locale === ILocale.fr
        ? dto?.frenchName
        : dto?.descriptionLocalizationString && translations[locale]?.[dto?.descriptionLocalizationString]
        ? translations[locale]?.[dto?.descriptionLocalizationString]
        : dto?.description || getLocalizedDtoName(dto) || '',
    [getLocalizedDtoName, locale]
  );

  const getLocalizedDate = useCallback(
    (date?: moment.Moment, format?: string) =>
      date
        ? moment(date)
            .locale(locale === ILocale.fr ? 'fr-ca' : 'en-ca')
            .format(format)
        : '',
    [locale]
  );

  const getLocalizedDocumentName = useCallback(
    (document?: ILocaleDocument) =>
      locale === ILocale.fr && document?.frenchName ? document?.frenchName : document?.name || '',
    [locale]
  );

  const getLocalizedSupporingDocumentName = useCallback(
    (supportingDocument?: ILocaleSupportingDocument) =>
      locale === ILocale.fr && supportingDocument?.frenchName
        ? supportingDocument?.frenchName
        : supportingDocument?.documentName || '',
    [locale]
  );

  return (
    <LocaleContext.Provider
      value={{
        setLocale: setAndSaveLocale,
        locale,
        t: translations[locale],
        getLocalizedDtoName,
        getLocalizedDtoDescription,
        getLocalizedDate,
        getLocalizedDocumentName,
        getLocalizedSupporingDocumentName,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
