import { useCallback, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';

import EditProfileModal from './components/EditProfileModal';
import ProfileDetailsList from './components/ProfileDetailsList';
import SettingsDetailsList from './components/SettingsDetailsList';
import PageContentContainer from '../../components/PageContentContainer';

import useLocale from '../../app/hooks/useLocale';
import useModal from '../../app/hooks/useModal';

const MyProfile = () => {
  const { t } = useLocale();
  const { showModal, closeModal, modal } = useModal();

  const handleProfileEdit = useCallback(() => {
    showModal(<EditProfileModal onCancel={closeModal} onOk={closeModal} />);
  }, [closeModal, showModal]);

  useEffect(() => {
    return () => {
      if (modal) closeModal();
    };
  }, [closeModal, modal]);

  return (
    <PageContentContainer
      headerProps={{
        title: t.MY_PROFILE,
        actionButton: (
          <Button variant="contained" sx={{ width: 120 }} onClick={handleProfileEdit}>
            {t.EDIT}
          </Button>
        ),
        sx: { pb: 2.5 },
      }}
    >
      <Box>
        <ProfileDetailsList />

        <Typography variant="h5" sx={{ mt: 2, mb: 2 }} fontWeight={600}>
          {t.SETTINGS}
        </Typography>

        <SettingsDetailsList />
      </Box>
    </PageContentContainer>
  );
};

export default MyProfile;
