import { AuthConsumer } from './AuthProvider';

export const Logout = () => (
  <AuthConsumer>
    {({ signout }) => {
      signout();
      return <span>loading</span>;
    }}
  </AuthConsumer>
);
