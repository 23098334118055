import NumberField from './NumberField';

const PercentField = (props: any) => (
  <NumberField
    {...props}
    inputProps={{
      allowNegative: false,
      decimalScale: 2,
      suffix: '%',
      isAllowed: (values: any) => values?.floatValue <= 100 || values?.floatValue === undefined,
      ...props?.inputProps,
    }}
  />
);

export default PercentField;
