import { Box, styled } from '@mui/material';
import CheckIcon from '../../../app/assets/icons/check-icon.svg';

const ConfirmCheck = () => (
  <Wrapper>
    <img src={CheckIcon} alt="check" />
  </Wrapper>
);

export default ConfirmCheck;

const Wrapper = styled(Box)(({ theme }) => ({
  width: 160,
  height: 160,
  backgroundColor: theme.palette.siteBlue,
  borderRadius: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
}));
