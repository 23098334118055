import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

import { IUnifiedExpenseUpdateDto } from '../../../../../../components/EditExpenseComponent/types';

const getExpenseFormItem = (item?: ClientPublicService.IAppFormIncomeExpenseExpenseDetailDto) =>
  ({
    id: item?.id || undefined,
    isInitial: item?.isInitial || false,
    expenseTypeId: item?.expenseTypeId || undefined,
    expenseDescriptionId: item?.expenseDescriptionId || undefined,
    specifiedDescription: item?.specifiedDescription || '',
    applicantAmount: item?.applicantAmount || undefined,
    spouseAmount: item?.spouseAmount || undefined,
    otherHouseholdMemberAmount: item?.otherHouseholdMemberAmount || undefined,
  } as IUnifiedExpenseUpdateDto);

const convertExpenseFormItemToDto = (formValue?: IUnifiedExpenseUpdateDto) =>
  ({
    id: formValue?.id,
    isInitial: formValue?.isInitial || false,
    expenseTypeId: formValue?.expenseTypeId,
    expenseDescriptionId: formValue?.expenseDescriptionId,
    specifiedDescription: formValue?.specifiedDescription || undefined,
    applicantAmount: formValue?.applicantAmount || 0,
    spouseAmount: formValue?.spouseAmount || 0,
    otherHouseholdMemberAmount: formValue?.otherHouseholdMemberAmount || 0,
  } as ClientPublicService.AppFormIncomeExpenseExpenseDetailDto);

export { getExpenseFormItem, convertExpenseFormItemToDto };
