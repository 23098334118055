import { useMemo, useContext } from 'react';
import { styled, Box, Divider, Tabs, Tab, BoxProps } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import CustomTabPanel, { a11yProps } from '../CustomTabPanel';
import LabelWithError from '../LabelWithError';
import Footer from '../NextOrCancelFooter';

import useLocale from '../../app/hooks/useLocale';

import { useApplicationFileQuery } from '../../features/AppForm/modules/IncomeExpense/queries';
import { IIncomeFormValues, IIncomeSubmitValues } from './types';
import {
  useDocumentsUploadMutation,
  IFormValuesDocumentItem,
  getDocumentsValidationSchema,
  ISupportDocsUploadProps,
} from '../DocumentsUpload';

import EditIncomeModalProvider, { EditIncomeModalContext, DOCUMENTS_TAB } from './EditIncomeModalProvider';
import { ClientPublicService } from '../../app/api/ClientPublicService';

interface IEditIncomeProps {
  fileId?: string;
  initialValues: IIncomeFormValues;
  onSubmit?: (values: IIncomeSubmitValues) => void;
  onCancel?: () => void;
  footerProps?: BoxProps;
  headerProps?: BoxProps;
  isMissingDocuments?: boolean;
}

interface IEditIncomeComponentProps extends IEditIncomeProps {
  isEditable?: boolean;
  documentsUploadProps?: ISupportDocsUploadProps;
}

const EditIncome = ({ fileId, initialValues, onSubmit, onCancel, ...props }: IEditIncomeProps) => {
  const { t } = useLocale();

  const { tabs, currentTab, tabsCount, goToNextTab, goToPreviousTab, isEditable, isMissingDocuments } =
    useContext(EditIncomeModalContext);

  const { data: appFile } = useApplicationFileQuery() || {};

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        incomeDetails: yup.array().of(
          yup.object({
            description: yup
              .string()
              .when(
                ['incomeType', 'applicantAmount', 'spouseAmount', 'otherHouseholdMemberAmount'],
                ([incomeType, applicantAmount, spouseAmount, otherHouseholdMemberAmount], schema) =>
                  (incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other ||
                    incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.GovernmentBenefits ||
                    incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.OtherBenefits) &&
                  (applicantAmount > 0 || spouseAmount > 0 || otherHouseholdMemberAmount > 0)
                    ? schema.required(t.THIS_IS_A_REQUIRED_FIELD)
                    : schema
              ),
          })
        ),
        ...getDocumentsValidationSchema(t.THIS_IS_A_REQUIRED_FIELD).fields,
      }),
    [t.THIS_IS_A_REQUIRED_FIELD]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          if (currentTab < tabsCount - 1) {
            goToNextTab?.();
          } else {
            const newDocumentsIds = await uploadDocuments({
              fileId: appFile?.id as string,
              files: values?.documents as IFormValuesDocumentItem[],
            });

            const previouslyUploadedDocumentsIds = (values?.documents || [])
              ?.filter((item) => item.id)
              ?.map((item) => item?.id);

            onSubmit?.({
              documentIds: [...previouslyUploadedDocumentsIds, ...newDocumentsIds] as string[],
              incomeDetails: values?.incomeDetails,
            });
          }
          setSubmitting(false);
        }}
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
      >
        {({ submitForm, isValid }) => (
          <>
            <Tabs
              value={currentTab}
              onChange={(_, newTab) => {
                if (newTab < currentTab) {
                  goToPreviousTab?.();
                } else {
                  submitForm().then(() => {
                    if (isValid) {
                      goToNextTab?.();
                    }
                  });
                }
              }}
              aria-label="edit income tabs"
              TabScrollButtonProps={{ sx: { position: 'sticky' } }}
            >
              {tabs?.map((item, index) => (
                <Tab
                  label={
                    <LabelWithError label={item?.label} hasError={item.key === DOCUMENTS_TAB && isMissingDocuments} />
                  }
                  key={`tab-${index}`}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>

            {tabs?.map((item, index) => (
              <CustomTabPanel value={currentTab} index={index} key={`tab-panel-${index}`}>
                {item.children}
              </CustomTabPanel>
            ))}

            <FooterContainer {...props?.footerProps}>
              <ModalDivider />

              <Footer
                onCancel={onCancel}
                nextButtonText={currentTab === tabsCount - 1 ? t.SAVE : t.NEXT}
                onNext={submitForm}
                isNextDisabled={!isValid}
                isEditable={isEditable}
              />
            </FooterContainer>
          </>
        )}
      </Formik>
    </>
  );
};

const EditIncomeComponent = ({
  isEditable,
  documentsUploadProps,
  isMissingDocuments,
  ...props
}: IEditIncomeComponentProps) => (
  <EditIncomeModalProvider
    isEditable={isEditable}
    documentsUploadProps={documentsUploadProps}
    isMissingDocuments={isMissingDocuments}
  >
    <EditIncome {...props} />
  </EditIncomeModalProvider>
);

export default EditIncomeComponent;

const FooterContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.white,
  zIndex: 1,
}));

const ModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
}));
