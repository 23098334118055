import { Typography, Box, Link, useTheme, BoxProps, TypographyProps } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

interface IHeaderProps {
  title?: string;
  subtitle?: string;
  hasBackButton?: boolean;
  backButtonAction?: () => void;
  backButtonText?: string;
  containerProps?: BoxProps;
  titleProps?: TypographyProps;
}

const Header = ({
  title,
  subtitle,
  hasBackButton,
  backButtonAction,
  backButtonText,
  containerProps,
  titleProps,
}: IHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      {...containerProps}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 2.5,
        '.MuiLink-root': {
          color: `${theme.palette.cobalt} !important`,
        },
        ...containerProps?.sx,
      }}
    >
      <Typography variant="h1" textAlign="center" {...titleProps} sx={{ pb: 2.5, lineHeight: 1, ...titleProps?.sx }}>
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {subtitle}
      </Typography>
      {hasBackButton && (
        <Link
          onClick={backButtonAction}
          component="button"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: 600,
          }}
          data-testid="back-button"
        >
          <ChevronLeft />
          {backButtonText}
        </Link>
      )}
    </Box>
  );
};

export default Header;
