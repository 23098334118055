import { Alert, AlertTitle } from '@mui/material';
import useLocale from '../../../app/hooks/useLocale';

const SectionLockMessage = () => {
  const { t } = useLocale();

  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      <AlertTitle>{t.SECTION_LOCKED_TITLE}</AlertTitle>
      {t.SECTION_LOCKED_BODY}
    </Alert>
  );
};

export default SectionLockMessage;
