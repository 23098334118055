import { getDateFromString } from '../../app/utils/dateTimeHelpers';
import { rest } from 'msw';

export const locations = [
  {
    id: '4d23e46b-e358-5411-fb08-3a0af994b2d5',
    name: 'Ajax',
    streetNumber: '65',
    streetName: 'Old Kingston Road',
    unit: 'Lower Level',
    city: 'Ajax',
    postalCode: 'L1T 3A5',
    province: null,
    country: null,
    officeRegion: '[ENTITY: OfficeRegion] Id = 935373d2-d03e-7f46-47cc-3a0af994b066',
    latitude: 43.85417807,
    longitude: -79.05807827,
    provinceId: '768816c6-c87f-c320-21fc-3a0af994af98',
    isActive: true,
    hours: '{"Hours":"Mo=08:30-17:00;Tu=08:30-17:00;We=08:30-17:00;Th=08:30-17:00;Fr=08:30-17:00\\r\\n"}',
  },
];

export const timeslots = ['09:00', '09:30', '10:00', '11:00', '12:00', '14:30', '16:30', '17:00', '18:30'];

export const meetingTypes = [
  {
    id: '1',
    name: 'In-Person',
  },
  {
    id: '2',
    name: 'Phone/Video',
  },
];

export const phoneTypes = [
  {
    id: '1',
    name: 'Mobile',
  },
  {
    id: '2',
    name: 'Home',
  },
];

export const sources = [
  {
    id: '1',
    name: 'Advertisement',
  },
  {
    id: '2',
    name: 'Recommendation',
  },
];

export const appointmentDetails = {
  id: '1',
  firstName: 'John',
  lastName: 'Smith',
  phoneTypeName: 'Mobile',
  phoneNumber: '(416) 123-4567',
  email: 'jsmith@gmail.com',
  officeLocationAddress: '14 Martin Street, Milton, ON',
  time: getDateFromString('21-07-2022 12:00', 'DD-MM-YYYY hh:mm a'),
  timezone: 'EST',
  appointmentTypeName: 'Initial Consultation',
};

const appointments = [appointmentDetails];

const locationsGET = rest.get('/office-locations', (req, res, ctx) => {
  return res(
    ctx.json({
      data: { items: locations, totalCount: locations.length },
    })
  );
});

const timeslotsGET = rest.post('/timeslots', (req, res, ctx) => {
  return res(
    ctx.json({
      data: timeslots,
    })
  );
});

const meetingTypesGET = rest.get('/meetingTypes', (req, res, ctx) => {
  return res(
    ctx.json({
      data: meetingTypes,
    })
  );
});

const phoneTypesGET = rest.get('/phoneTypes', (req, res, ctx) => {
  return res(
    ctx.json({
      data: phoneTypes,
    })
  );
});

const sourcesGET = rest.get('/sources', (req, res, ctx) => {
  return res(
    ctx.json({
      data: sources,
    })
  );
});

const appointmentPOST = rest.post('/appointment', (req, res, ctx) => {
  return res(
    ctx.json({
      returnId: '1',
    })
  );
});

const appointmentByIdGET = rest.get('/appointment/:id', (req, res, ctx) => {
  const { id } = req.params;

  const isFound = appointments?.find((item) => item.id === id);

  if (isFound) {
    return res(
      ctx.json({
        data: appointmentDetails,
      })
    );
  } else {
    return res(ctx.status(404), ctx.json({ errorMessage: 'Appointment not found' }));
  }
});

const appointmentDelete = rest.delete('/appointment/:id', (req, res, ctx) => {
  const { id } = req.params;

  const isFound = appointments?.find((item) => item.id === id);

  if (isFound) {
    return res(ctx.status(200), ctx.json({}));
  } else {
    return res(ctx.status(404), ctx.json({ errorMessage: 'Appointment not found' }));
  }
});

const closestAppointmentDateGET = rest.get('/closestDate', (req, res, ctx) => {
  return res(
    ctx.json({
      data: getDateFromString('07-08-2022'),
    })
  );
});

export const handlers = [
  locationsGET,
  timeslotsGET,
  meetingTypesGET,
  phoneTypesGET,
  sourcesGET,
  appointmentPOST,
  appointmentByIdGET,
  appointmentDelete,
  closestAppointmentDateGET,
];
