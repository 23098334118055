import { useQuery, useMutation } from '@tanstack/react-query';

import {
  PENDING_FOLLOWUP_QUERY_KEY,
  APPOINTEMENT_TYPES_QUERY_KEY,
  APPOINTMENT_STATUSES_QUERY_KEY,
  COMPLETED_INITIAL_CONSULTATIONS_COUNT_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  DEBTOR_INFORMATION_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import { argumentifyTaskActionCountRequestParams, argumentifyAppointmentsSearchParams } from './utils';

import moment from '../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import { queryClient } from '../../app/config';
import API from '../../app/api/api';

export const usePendingFollowUpRequestQuery = () =>
  useQuery({
    queryKey: [PENDING_FOLLOWUP_QUERY_KEY],
    queryFn: async () => {
      const data = await API.count2(
        ...argumentifyTaskActionCountRequestParams({
          appTaskActionStatus: ClientPublicService.AppTaskActionStatusEnum.Pending,
          appTaskActionNames: [ClientPublicService.AppTaskActionNameEnum.AppointmentRequested],
        })
      );

      return data;
    },
  });

export const useFollowUpRequestMutation = () =>
  useMutation({
    mutationFn: () => API.followUpRequest(),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PENDING_FOLLOWUP_QUERY_KEY] }),
  });

export const usePastInitialConsultationsAppointmentsCountQuery = () =>
  useQuery({
    queryKey: [COMPLETED_INITIAL_CONSULTATIONS_COUNT_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAppointmentInfoList(
        ...argumentifyAppointmentsSearchParams({
          appointmentTypeEnums: [ClientPublicService.AppointmentTypeEnum.InitialConsultation],
          startDateTimeBefore: moment(),
        })
      );

      return data?.totalCount || 0;
    },
  });

export const useAppointmentTypesQuery = () =>
  useQuery({
    queryKey: [APPOINTEMENT_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentTypes(undefined);
      return data;
    },
  });

export const useAppointmentStatusesQuery = () =>
  useQuery({
    queryKey: [APPOINTMENT_STATUSES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentStatuses();
      return data;
    },
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
  });
