import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  Grid,
  Link,
  useMediaQuery,
  Stack,
  Badge,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BellIcon from '../../app/assets/icons/bellIncon.svg';
import HamburgerIcon from '../../app/assets/icons/hamburgerIcon.svg';

import logo from '../../app/assets/images/bdo-logo-en.svg';
import LanguageMenu from '../AppDrawer/components/LanguageMenu';
import LoginButton from '../LoginButton';
import FeatureFlag, { FEATUREFLAG_LOGIN_BUTTON } from '../FeatureFlag/FeatureFlag';

import useLocale from '../../app/hooks/useLocale';
import useAuthService from '../../app/hooks/useAuthService';

import { ROUTES } from '../../app/routes/constants';
import { useNewNotificationsExistQuery } from './queries';

const SiteBar = ({ handleMenuToggle }: { handleMenuToggle: () => void }) => {
  const { t } = useLocale();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthService();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const { data: newNotificationsExist } = useNewNotificationsExistQuery(isAuthenticated()) || {};

  if (isAuthenticated() && isDesktop) {
    return <></>;
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.white,
        boxShadow: 'none',
        color: theme.palette.primary.main,
      }}
    >
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" flex={1} alignItems="center">
          <Grid item mobile>
            <Box sx={{ height: 32 }}>
              <Link onClick={() => navigate(ROUTES.ROOT)} sx={{ cursor: 'pointer' }}>
                <img src={logo} alt="bdo-logo" />
              </Link>
            </Box>
          </Grid>

          <Grid item mobile textAlign="center">
            <Typography variant="body1" sx={{ fontWeight: 600, color: theme.palette.slate }} data-testid="debthub">
              {t.DEBT_HUB}
            </Typography>
          </Grid>
          <Grid item mobile textAlign="right" sx={{ display: 'flex', justifyContent: 'end' }}>
            {isDesktop ? (
              <Stack flexDirection="row" gap={2}>
                {/* TODO: Delete this code block after the login redirectis implemented. */}
                <FeatureFlag flag={FEATUREFLAG_LOGIN_BUTTON && pathname !== '/login'}>
                  <LoginButton />
                </FeatureFlag>
                {/* TODO: end of code block to delete */}

                <LanguageMenu data-testid="language-menu" />
              </Stack>
            ) : (
              <Stack flexDirection="row">
                {isAuthenticated() && (
                  <IconButton color="primary" onClick={() => navigate(ROUTES.NOTIFICATIONS)}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      variant="dot"
                      color="error"
                      invisible={!newNotificationsExist}
                      aria-label="Notifications"
                    >
                      <img src={BellIcon} alt="notification-icon" width={36} />
                    </Badge>
                  </IconButton>
                )}

                <IconButton
                  data-testid="toggle-button"
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleMenuToggle}
                  sx={{ mr: 0, pl: 0 }}
                >
                  <img src={HamburgerIcon} alt="menu-icon" width={36} />
                </IconButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SiteBar;
