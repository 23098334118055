import { useQuery, useMutation } from '@tanstack/react-query';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { INCOME_TAX_QUERY_KEY } from '../../../../app/constants/reactQueryKeys';
import { queryClient } from '../../../../app/config';

import API from '../../../../app/api/api';

export const useIncomeTaxQuery = (fileId?: string) =>
  useQuery({
    queryKey: [INCOME_TAX_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormIncomeTax(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useUpdateIncomeTaxMutation = () =>
  useMutation({
    mutationFn: (incomeTaxInfo: ClientPublicService.IAppFormIncomeTaxDto) =>
      API.updateAppFormIncomeTax(incomeTaxInfo as ClientPublicService.AppFormIncomeTaxDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [INCOME_TAX_QUERY_KEY] }),
  });
