import { useMemo } from 'react';
import { TextField, Autocomplete, TextFieldProps, Box, useTheme, FormHelperText } from '@mui/material';
import { useFormikContext, useField } from 'formik';

import CustomLabel from '../CustomLabel';

import useLocale from '../../app/hooks/useLocale';

interface IFormValue {
  [key: string]: any;
}

export interface IAutocompleteOption {
  id?: string;
  name?: string;
}

const AutocompleteField = (props: any) => {
  const theme = useTheme();
  const { t, getLocalizedDtoName } = useLocale();
  const { setFieldValue, setTouched } = useFormikContext<IFormValue>();
  const [, meta] = useField(props?.name);

  const localizedOptions = useMemo(
    () => props?.options?.map((item: any) => ({ ...item, name: getLocalizedDtoName(item) })),
    [getLocalizedDtoName, props?.options]
  );

  const itemFound = useMemo(
    () => props?.options?.find((item: IAutocompleteOption) => item?.id === meta?.value),
    [meta?.value, props?.options]
  );

  const options = localizedOptions || [];

  const isRecommendedError = useMemo(() => meta.error && meta.error?.includes('[recommended]'), [meta.error]);

  return (
    <>
      <Autocomplete
        value={{
          id: meta.value || '',
          name: localizedOptions?.find((item: IAutocompleteOption) => meta.value === item?.id)?.name || '',
        }}
        onChange={(_: any, newValue: IAutocompleteOption | null) => {
          setFieldValue(props?.name, newValue?.id);
          props?.onChange?.(newValue?.id);
        }}
        options={itemFound ? options : [{ id: meta.value, name: meta.value }, ...options]}
        isOptionEqualToValue={(option: IAutocompleteOption, value: IAutocompleteOption) =>
          value?.id === undefined || value?.id === '' || option?.id === value?.id
        }
        getOptionLabel={(option?: IAutocompleteOption) => option?.name || ''}
        noOptionsText={t.NO_OPTIONS}
        renderOption={(props, option: IAutocompleteOption) =>
          option?.id && (
            <Box
              key={option?.id}
              {...props}
              component="li"
              sx={{
                p: 1,
              }}
            >
              {option?.name}
            </Box>
          )
        }
        disabled={props?.disabled}
        renderInput={(params: TextFieldProps) => {
          return (
            <>
              <TextField
                {...params}
                name={props?.name}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && !!meta.error ? meta.error : props?.helperText}
                fullWidth
                label={<CustomLabel label={props?.label} required={props?.required} recommended={props?.recommended} />}
                variant="outlined"
                sx={{
                  width: '100%',
                  m: 1,
                  marginLeft: 0,
                  marginRight: 0,
                  ...props?.sx,
                  ...(isRecommendedError
                    ? {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: `${
                            props?.disabled ? theme.palette.disabledGray : theme.palette.warningOrange
                          } !important`,
                        },
                        '.MuiFormHelperText-root.Mui-error': {
                          display: 'none !important',
                        },
                        '.Mui-error': {
                          color: `${theme.palette.warningOrange} !important`,
                        },
                      }
                    : {}),
                }}
                onBlur={() => setTouched({ [props?.name]: true })}
              />
            </>
          );
        }}
        data-testid={props?.['data-testid']}
      />
      {isRecommendedError && (
        <FormHelperText
          style={{
            marginTop: -3,
            marginLeft: 14,
            color: theme.palette.warningOrange,
          }}
        >
          {meta?.error?.replace('[recommended]', '')}
        </FormHelperText>
      )}
    </>
  );
};

export default AutocompleteField;
