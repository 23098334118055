/**
 *  ABP Auth Server (OpenIdDict) endpoints config.
 *  Reference: https://medium.com/@franciscopa91/how-to-implement-oidc-authentication-with-react-context-api-and-react-router-205e13f2d49
 *  Note: No need to change.
 */
export const identityMETAConfig = () => {
  return {
    issuer: process.env.REACT_APP_ISSUER,
    jwks_uri: process.env.REACT_APP_AUTH_URL + '/.well-known/openid-configuration/jwks',
    authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/authorize',
    token_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/token',
    userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/userinfo',
    end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/logout',
    check_session_iframe: process.env.REACT_APP_AUTH_URL + '/connect/checksession',
    revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/revocat',
    introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/introspect',
  };
};
