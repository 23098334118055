import { useQuery } from '@tanstack/react-query';

import { APP_FORM_STATUS_QUERY_KEY, APPLICATION_FILE_QUERY_KEY } from '../../app/constants/reactQueryKeys';
import API from '../../app/api/api';

export const useAppFormStatusQuery = (fileId?: string) =>
  useQuery({
    queryKey: [APP_FORM_STATUS_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormStatus(fileId);

      return data;
    },
    enabled: !!fileId,
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });
