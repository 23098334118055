import { useContext } from 'react';
import { Button, Grid, Box, styled } from '@mui/material';

import { useFormikContext } from 'formik';

import useLocale from '../../../../../../app/hooks/useLocale';

import { EditAssetModalContext, BASIC_TAB } from './EditAssetModalProvider';

import { IAssetFormValues } from './types';

const EditAssetFooter = ({ onCancel, onOk }: { onCancel?: () => void; onOk?: () => void }) => {
  const { t } = useLocale();

  const { tabs, tabsCount, currentTab, isLocked } = useContext(EditAssetModalContext);

  const { isValid } = useFormikContext<IAssetFormValues>();

  return (
    <FooterContainer>
      <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap">
        <Grid item mobile={6} tablet={4}>
          <Button onClick={onCancel} variant="outlined" data-testid="cancel-button" fullWidth>
            {t.CANCEL}
          </Button>
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Button
            variant="contained"
            data-testid="ok-button"
            fullWidth
            onClick={onOk}
            disabled={(tabs?.[currentTab]?.key === BASIC_TAB && !isValid) || isLocked}
          >
            {currentTab === tabsCount - 1 ? t.SAVE : t.NEXT}
          </Button>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

export default EditAssetFooter;

const FooterContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.white,
  zIndex: 1,
}));
