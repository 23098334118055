import { isMoment } from 'moment';
import { ClientPublicService } from '../api/ClientPublicService';
import theme from '../layout/theme';
import { getIn } from 'formik';

export const currencyFormatter = (value?: number, options?: Intl.NumberFormatOptions) =>
  value !== undefined
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        ...options,
      })?.format(value)
    : undefined;

export const readableBytes = (bytes?: number) => {
  if (!bytes) return '0 KB';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export const convertStringToTranslationsKeyValue = (value?: string) => {
  // playground: https://jsfiddle.net/4d2qgz6f/
  const strippedString = value?.replace(/[^0-9a-zA-Z ]/g, '');

  const key = strippedString?.replace(/[a-zA-Z ]/g, (match) => {
    if (match === ' ') {
      return '_';
    } else {
      return match.toUpperCase();
    }
  });

  return `${key}: ${value}`;
};

export const getBackgroundLinearGradient = (
  breakpoints: string[],
  gradienBreakPointNumber = 42,
  basicColor = theme.palette.siteBlue,
  angle = -35
) => {
  const opacityBreakpoints = [...breakpoints];
  const numberOfBreakpoints = opacityBreakpoints?.length;
  const step = gradienBreakPointNumber / numberOfBreakpoints;

  const array: string[] = [];

  opacityBreakpoints?.forEach((breakpoint, index) => {
    array?.push(`${basicColor}${breakpoint} ${index * step + 0.1}%`);
    if (index < numberOfBreakpoints) {
      array?.push(`${basicColor}${breakpoint} ${(index + 1) * step}%`);
    }
  });

  opacityBreakpoints?.reverse().forEach((breakpoint, index) => {
    array?.push(`${basicColor}${breakpoint} ${100 - gradienBreakPointNumber + index * step + 0.1}%`);
    if (index < numberOfBreakpoints) {
      array?.push(`${basicColor}${breakpoint} ${100 - gradienBreakPointNumber + (index + 1) * step}%`);
    }
  });

  return `linear-gradient(${angle}deg, ${array?.join(', ')})`;
};

/** Clean up browser cache. */
export default function emptyCache() {
  caches.keys().then((names) => {
    // Delete all the cache files
    names.forEach((name) => {
      caches.delete(name);
    });
  });
}

export const sortCountriesWithDefaultsOnTop = (countriesUnsorted?: ClientPublicService.LookupDto[]) => {
  const CANADA_COUNTRY_CODE = 'CA';
  const UNITED_STATES_COUNTRY_CODE = 'US';
  if (!countriesUnsorted) return [];

  const canadaIndex = countriesUnsorted?.findIndex((country) => country.code === CANADA_COUNTRY_CODE);
  const canadaElement = countriesUnsorted?.splice(canadaIndex, 1)?.[0];
  countriesUnsorted?.splice(0, 0, canadaElement);

  const unitedStatesIndex = countriesUnsorted?.findIndex((country) => country.code === UNITED_STATES_COUNTRY_CODE);
  const unitedStatesElement = countriesUnsorted?.splice(unitedStatesIndex, 1)?.[0];
  countriesUnsorted?.splice(1, 0, unitedStatesElement);

  return countriesUnsorted;
};

export const downloadFile = (blob: Blob, documentName: string) => {
  const url = URL.createObjectURL(blob);
  const link = window.document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName);
  link.click();
  URL.revokeObjectURL(url);
};

export const getAllObjectProps = (obj: any, parentProp = ''): string[] => {
  let props: string[] = [];

  for (const prop in obj) {
    if (Array.isArray(obj[prop])) {
      props.push(`${parentProp ? parentProp + '.' : ''}${prop}`);

      if (obj[prop].length > 0) {
        for (let i = 0; i < obj[prop].length; i++) {
          const nestedProps = getAllObjectProps(obj[prop][i], `${parentProp}${parentProp ? '.' : ''}${prop}.${i}`);
          props = props.concat(nestedProps);
        }
      }
    } else if (
      typeof obj[prop] === 'object' &&
      !Array.isArray(obj[prop]) &&
      !isMoment(obj[prop]) &&
      obj[prop] !== null
    ) {
      const nestedProps = getAllObjectProps(obj[prop], `${parentProp}${parentProp ? '.' : ''}${prop}`);
      props = props.concat(nestedProps);
    } else {
      props.push(`${parentProp ? parentProp + '.' : ''}${prop}`);
    }
  }

  return props;
};

export const convertMaskedPhoneInputToNumber = (value?: string) => value?.replace(/[^0-9]/g, '');

export const checkIfUnsavedFormChanges = (values?: any, initialValues?: any, propsToExclude?: string[]) => {
  return Boolean(
    getAllObjectProps(values)
      ?.filter((item) => (propsToExclude || [])?.indexOf(item) < 0)
      ?.find((key) => {
        if (isMoment(getIn(values, key)) && isMoment(getIn(initialValues, key))) {
          return !getIn(values, key)?.isSame(getIn(initialValues, key));
        }

        if (key?.toLowerCase()?.includes('phonenumber')) {
          return (
            convertMaskedPhoneInputToNumber(getIn(values, key)) !==
            convertMaskedPhoneInputToNumber(getIn(initialValues, key))
          );
        }

        return getIn(values, key) !== getIn(initialValues, key);
      })
  );
};

export const regexSIN = new RegExp(/\d{9}$|(\d\d\d \d\d\d \d\d\d)/);

export const regexPhone = new RegExp(/\d{10}$|([(]\d\d\d[)] \d\d\d-\d\d\d\d)/);

export const validateGreaterThan = (value?: number, target = 0) => {
  if (value === undefined) return false;
  if (value <= target) return false;
  return true;
};

export const nameRemovedExtraDots = (name?: string) => {
  const namePartsArray = name?.split('.') || [];

  const fileExtension = namePartsArray.pop();
  const nameWithoutDots = namePartsArray?.join('');

  return fileExtension && nameWithoutDots ? `${nameWithoutDots}.${fileExtension}` : '';
};

export const nameRemovedExtraDotsWithoutExtension = (name?: string) => {
  const namePartsArray = name?.split('.') || [];
  namePartsArray.pop();

  return namePartsArray?.join('');
};

export const isDeviceiOS = () => {
  if (navigator.userAgent.match(/iPhone|iPad|Macintosh/i)) {
    return true;
  }
  return false;
};
