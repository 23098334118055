import { Container, Typography } from '@mui/material';

import useLocale from '../../app/hooks/useLocale';

const PageNotFound = () => {
  const { t } = useLocale();
  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h1" color="error">
        404
      </Typography>
      <Typography variant="h6" aria-label={t.PAGE_NOT_FOUND}>
        {t.PAGE_NOT_FOUND}
      </Typography>
    </Container>
  );
};

export default PageNotFound;
