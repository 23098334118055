import { IIEReportsSearchParams } from './types';

const argumentifyIEReportsSearchParams = ({
  dueDate,
  dueDateStart,
  dueDateEnd,
  statuses,
  sorting,
  skipCount,
  maxResultCount,
}: IIEReportsSearchParams) =>
  [dueDate, dueDateStart, dueDateEnd, statuses, sorting, skipCount, maxResultCount] as const;

export { argumentifyIEReportsSearchParams };
