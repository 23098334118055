import { useMemo, useCallback } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import { Divider, Stack, Button, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  SelectField,
  TextField,
  DatePickerField,
  YesNoToggleField,
  NumberField,
} from '../../../../../components/FormFields';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormValuesPersonalInfo } from '../types';
import { useRelationshipsQuery } from '../queries';
import { getDependantFormItem } from '../utils';
import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const Dependants = () => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const { data: relationships } = useRelationshipsQuery() || {};

  const newDependantItem = useMemo(() => getDependantFormItem(), []);

  const handleHasDependantsToggle = useCallback(
    (hasDependants?: boolean) => {
      const noDependantsRecords = !values?.dependants || values?.dependants?.length === 0;
      if (hasDependants && noDependantsRecords) {
        setFieldValue('dependants', [newDependantItem]);
      }
      if (!hasDependants) {
        setFieldValue('dependants', []);
      }
    },
    [newDependantItem, setFieldValue, values?.dependants]
  );

  return (
    <>
      <NumberField
        name="numberOfPersonsInHousehold"
        label={t.PERSONS_IN_HOUSEHOLD}
        required
        inputProps={{
          isAllowed: (values: any) => values?.floatValue < 100 || values?.floatValue === undefined,
          allowNegative: false,
          decimalScale: 0,
        }}
        disabled={isLocked}
      />

      <YesNoToggleField
        label={t.ANY_DEPENDANT_CHILDREN}
        name="hasDependants"
        onChange={handleHasDependantsToggle}
        disabled={isLocked}
      />

      {values?.hasDependants && (
        <FieldArray name="dependants">
          {({ remove, insert }: ArrayHelpers) => (
            <>
              {values?.dependants && values?.dependants?.length > 0 && (
                <>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  {values?.dependants?.map((_, index: number) => (
                    <Stack key={index}>
                      <TextField
                        name={`dependants.${index}.firstName`}
                        label={t.DEPENDANT_FIRST_NAME}
                        recommended
                        inputProps={{ maxLength: 100 }}
                        disabled={isLocked}
                      />

                      <TextField
                        name={`dependants.${index}.lastName`}
                        label={t.DEPENDANT_LAST_NAME}
                        recommended
                        inputProps={{ maxLength: 100 }}
                        disabled={isLocked}
                      />

                      <SelectField
                        label={t.RELATIONSHIP}
                        name={`dependants.${index}.relationshipTypeId`}
                        options={relationships}
                        recommended
                        disabled={isLocked}
                      />

                      <DatePickerField
                        name={`dependants.${index}.birthDate`}
                        label={t.DATE_OF_BIRTH}
                        disableFuture
                        format={DATE_FORMAT}
                        recommended
                        disabled={isLocked}
                      />

                      <IconButton
                        onClick={() => remove(index)}
                        disabled={values?.dependants?.length === 1 || isLocked}
                        sx={{ alignSelf: 'start' }}
                        color="error"
                        aria-label="Delete"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                    </Stack>
                  ))}
                </>
              )}
              <Button
                variant="text"
                onClick={() => insert(values?.dependants?.length || 0, newDependantItem)}
                disabled={isLocked}
              >
                {t.ADD_DEPENDENT}
              </Button>
            </>
          )}
        </FieldArray>
      )}
    </>
  );
};

export default Dependants;
