import { useCallback, useMemo } from 'react';
import {
  ListItemButton,
  ListItemButtonProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Circle, Error, CheckCircle } from '@mui/icons-material';

import useLocale from '../../app/hooks/useLocale';

import { ClientPublicService } from '../../app/api/ClientPublicService';
import AppFormProgressComponent from '../AppFormProgress/AppFormProgressComponent';
import { AppformSection } from '../AppFormProgress/components/ProgressBar';

export interface IAppFormMenuItem extends ListItemButtonProps {
  appModuleName?: string;
  appModuleId?: string;
  appModuleStatus?: ClientPublicService.AppFormStatusEnum;
  appModuleRoute?: string;
  displayNavBar?: boolean;
  children?: React.ReactElement;
}

const ASSETS_DEBTS_KEY = 'assetsDebts';
const INCOME_EXPENSE_KEY = 'incomeExpense';
const INCOME_TAX_KEY = 'incomeTax';
const BANKING_INFORMATION_KEY = 'bankingInformation';
const QUESTIONNAIRE_KEY = 'questionnaire';

const AppFormMenuItem = ({
  appModuleName,
  appModuleId,
  appModuleStatus,
  displayNavBar,
  children,
  ...props
}: IAppFormMenuItem) => {
  const { t } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const getAppFormStatusDetailsByEnum = useCallback(
    (status?: ClientPublicService.AppFormStatusEnum) => {
      switch (status) {
        case ClientPublicService.AppFormStatusEnum.NotStarted:
          return {
            statusName: t.NOT_STARTED,
            statusIcon: Circle,
            iconColor: theme.palette.secondary.main,
          };
        case ClientPublicService.AppFormStatusEnum.Incomplete:
          return {
            statusName: t.INCOMPLETE,
            statusIcon: Error,
            iconColor: theme.palette.urgentRed,
          };
        case ClientPublicService.AppFormStatusEnum.Incomplete_Review:
          return {
            statusName: t.INCOMPLETE,
            statusIcon: Error,
            iconColor: theme.palette.urgentRed,
          };
        case ClientPublicService.AppFormStatusEnum.ReadyForReview:
          return {
            statusName: t.READY_FOR_REVIEW,
            statusIcon: Circle,
            iconColor: theme.palette.successGreen,
          };
        case ClientPublicService.AppFormStatusEnum.Reviewed:
          return {
            statusName: t.REVIEWED,
            statusIcon: Circle,
            iconColor: theme.palette.successGreen,
          };
        case ClientPublicService.AppFormStatusEnum.Complete:
          return {
            statusName: t.COMPLETE,
            statusIcon: CheckCircle,
            iconColor: theme.palette.successGreen,
          };
        default:
          return {
            statusName: t.NOT_STARTED,
            statusIcon: Circle,
            iconColor: theme.palette.secondary.main,
          };
      }
    },
    [
      t.COMPLETE,
      t.INCOMPLETE,
      t.NOT_STARTED,
      t.READY_FOR_REVIEW,
      t.REVIEWED,
      theme.palette.secondary.main,
      theme.palette.successGreen,
      theme.palette.urgentRed,
    ]
  );

  const activeSection: AppformSection = useMemo(() => {
    switch (appModuleId) {
      case ASSETS_DEBTS_KEY:
        return AppformSection.AssetDebts;
      case BANKING_INFORMATION_KEY:
        return AppformSection.BankingInfo;
      case INCOME_EXPENSE_KEY:
        return AppformSection.IncomeExpense;
      case INCOME_TAX_KEY:
        return AppformSection.IncomeTax;
      case QUESTIONNAIRE_KEY:
        return AppformSection.Questionnaire;
      default:
        return AppformSection.PersonalInfo;
    }
  }, [appModuleId]);

  const appFormStatusDetails = useMemo(
    () => getAppFormStatusDetailsByEnum(appModuleStatus),
    [getAppFormStatusDetailsByEnum, appModuleStatus]
  );

  const StatusIcon = appFormStatusDetails?.statusIcon;

  return (
    <ListItem disablePadding component="div">
      <ListItemButton
        dense
        {...props}
        sx={{
          bgcolor: theme.palette.white,
          flexWrap: displayNavBar ? 'wrap' : 'unset',
          ...props.sx,
        }}
      >
        <ListItemIcon sx={{ mt: 1, minWidth: 32, alignSelf: 'start' }}>
          <StatusIcon fontSize={isDesktop ? 'small' : 'medium'} sx={{ color: appFormStatusDetails?.iconColor }} />
        </ListItemIcon>
        <ListItemText
          primary={appModuleName}
          secondary={appFormStatusDetails?.statusName}
          sx={{
            '.MuiListItemText-primary': { fontSize: isDesktop ? 14 : 16 },
            '.MuiListItemText-secondary': { fontSize: isDesktop ? 12 : 14 },
            mt: isDesktop ? 0 : 0.75,
            mb: isDesktop ? 0 : 0.75,
          }}
        />
        {children}
        {displayNavBar && <AppFormProgressComponent activeSection={activeSection} />}
      </ListItemButton>
    </ListItem>
  );
};

export default AppFormMenuItem;
