import { useMemo, useState, useEffect, useCallback } from 'react';
import { IconButton, Stack, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { toast } from 'react-toastify';

import ProfileDataItem, { IProfileDataItemProps } from '../components/ProfileDataItem';
import StyledSwitch from '../../../components/StyledSwitch';
import ChangePasswordModal from './ChangePasswordModal';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';

import { useDebtorProfileQuery, useApplicationFileQuery, useSettingsMutation } from '../queries';

const SettingsDetailsList = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const { showModal, closeModal } = useModal();

  const [isAllowedEmailNotifications, setIsAllowedEmailNotifications] = useState<boolean>(false);
  const [isAllowedSMSNotifications, setIsAllowedSMSNotifications] = useState<boolean>(false);

  const { data: appFileData } = useApplicationFileQuery() || {};

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { mutate: updateSettings } = useSettingsMutation() || {};

  const handleEmailNotificationsToggle = useCallback(() => {
    setIsAllowedEmailNotifications(!isAllowedEmailNotifications);
    updateSettings(
      {
        isAllowedEmailNotifications: !isAllowedEmailNotifications,
        isAllowedSMSNotifications,
        fileId: appFileData?.id,
      },
      {
        onSuccess: () => toast.success(t.SETTINGS_ARE_UPDATED),
      }
    );
  }, [appFileData?.id, isAllowedEmailNotifications, isAllowedSMSNotifications, t.SETTINGS_ARE_UPDATED, updateSettings]);

  const handleSMSNotificationsToggle = useCallback(() => {
    setIsAllowedSMSNotifications(!isAllowedSMSNotifications);
    updateSettings(
      {
        isAllowedSMSNotifications: !isAllowedSMSNotifications,
        isAllowedEmailNotifications,
        fileId: appFileData?.id,
      },
      {
        onSuccess: () => toast.success(t.SETTINGS_ARE_UPDATED),
      }
    );
  }, [appFileData?.id, isAllowedEmailNotifications, isAllowedSMSNotifications, t.SETTINGS_ARE_UPDATED, updateSettings]);

  const handleChangePasswordModal = useCallback(() => {
    showModal(<ChangePasswordModal onOk={closeModal} onCancel={closeModal} />);
  }, [closeModal, showModal]);

  const settingsListItems: IProfileDataItemProps[] = useMemo(
    () => [
      {
        title: t.SECURITY,
        content: t.CHANGE_YOUR_PASSWORD,
        actionComponent: (
          <IconButton
            edge="end"
            sx={{ color: theme.palette.cobalt }}
            onClick={handleChangePasswordModal}
            aria-label="Change Password"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        ),
      },
      {
        title: t.EMAIL_NOTIFICATIONS,
        content: t.EMAIL_NOTIFICATIONS_TEXT,
        actionComponent: (
          <StyledSwitch
            onChange={handleEmailNotificationsToggle}
            checked={isAllowedEmailNotifications}
            inputProps={{
              'aria-labelledby': 'switch-label-email-notifications',
              'aria-label': 'email-notifications-switch',
            }}
          />
        ),
      },
      {
        title: t.SMS_NOTIFICATIONS,
        content: t.SMS_NOTIFICATIONS_TEXT,
        actionComponent: (
          <StyledSwitch
            onChange={handleSMSNotificationsToggle}
            checked={isAllowedSMSNotifications}
            inputProps={{
              'aria-labelledby': 'switch-label-sms-notifications',
              'aria-label': 'sms-notifications-switch',
            }}
          />
        ),
      },
    ],
    [
      handleChangePasswordModal,
      handleEmailNotificationsToggle,
      handleSMSNotificationsToggle,
      isAllowedEmailNotifications,
      isAllowedSMSNotifications,
      t.CHANGE_YOUR_PASSWORD,
      t.EMAIL_NOTIFICATIONS,
      t.EMAIL_NOTIFICATIONS_TEXT,
      t.SECURITY,
      t.SMS_NOTIFICATIONS,
      t.SMS_NOTIFICATIONS_TEXT,
      theme.palette.cobalt,
    ]
  );

  useEffect(() => {
    setIsAllowedEmailNotifications(!debtorProfile?.profile?.doNotEmail);
  }, [debtorProfile?.profile?.doNotEmail]);

  useEffect(() => {
    setIsAllowedSMSNotifications(!debtorProfile?.profile?.mainPhoneDoNotSMS);
  }, [debtorProfile?.profile?.mainPhoneDoNotSMS]);

  return (
    <Stack data-testid="settings-details">
      {settingsListItems?.map((item, index) => (
        <ProfileDataItem key={index} {...item} />
      ))}
    </Stack>
  );
};

export default SettingsDetailsList;
