import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { IAppFormIncomeTax } from './types';

export const getInitialIncomeTaxValues = (
  data?: ClientPublicService.IAppFormIncomeTaxDto,
  documents?: ClientPublicService.DocumentDto[]
) =>
  ({
    id: data?.id || null,
    fileId: data?.fileId || null,
    isMarried: data?.isMarried || false,
    yearLastReturnFiled: data?.yearLastReturnFiled || null,
    refundAmount: data?.refundAmount || 0,
    amountOwing: data?.amountOwing || 0,
    spouseYearLastReturnFiled: data?.spouseYearLastReturnFiled || null,
    spouseRefundAmount: data?.spouseRefundAmount || 0,
    spouseAmountOwing: data?.spouseAmountOwing || 0,
    documentIds: data?.documentIds || [],
    documents,
  } as IAppFormIncomeTax);

export const convertFormValuesToDto = (data?: IAppFormIncomeTax) =>
  ({
    id: data?.id || undefined,
    fileId: data?.fileId || undefined,
    isMarried: data?.isMarried || false,
    yearLastReturnFiled: data?.yearLastReturnFiled,
    refundAmount: data?.refundAmount || 0,
    amountOwing: data?.amountOwing || 0,
    spouseYearLastReturnFiled: data?.spouseYearLastReturnFiled,
    spouseRefundAmount: data?.spouseRefundAmount || 0,
    spouseAmountOwing: data?.spouseAmountOwing || 0,
    documentIds: data?.documentIds || [],
  } as ClientPublicService.IAppFormIncomeTaxDto);
