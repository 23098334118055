import { Typography } from '@mui/material';
import ElevatedContainer from '../../../components/ElevatedContainer';
import AppointmenDetails from '../../../components/AppointmentDetails/AppointmentDetails';

import useLocale from '../../../app/hooks/useLocale';

import { usePreviousAppointmentsQuery } from '../queries';

const PreviousList = () => {
  const { t } = useLocale();

  const { data } = usePreviousAppointmentsQuery({}) || {};

  if (!data || !data?.items?.length) return null;

  return (
    <>
      <Typography variant="body3" fontWeight={600} pb={2.5} color="secondary">
        {t.PREVIOUS}
      </Typography>
      {data?.items?.map((item) => (
        <ElevatedContainer sx={{ borderRadius: 1.5, pt: 3, pb: 3, mb: 2.5, maxWidth: 528 }} key={item?.id}>
          <AppointmenDetails {...item} isPastAppointment />
        </ElevatedContainer>
      ))}
    </>
  );
};

export default PreviousList;
