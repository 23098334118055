import { useMemo, useCallback } from 'react';
import { Grid, Button, Divider } from '@mui/material';
import { Formik } from 'formik';
import yup from '../../../app/utils/customYup';
import { toast } from 'react-toastify';

import EditProfileForm from './EditProfileForm';
import EditModal, { IEditModalProps } from '../../../components/EditModal';

import useLocale from '../../../app/hooks/useLocale';

import { useDebtorProfileQuery, useApplicationFileQuery, useDebtorProfileMutation } from '../queries';
import { regexPhone } from '../../../app/utils/helpers';
import { getDebtorProfileFormValues, convertDebtorProfileFormValuesToDto } from '../utils';
import { IDebtorProfileFormValues } from '../types';

interface IEditDebtModalProps extends Pick<IEditModalProps, 'onCancel'> {
  onOk?: () => void;
}

const EditProfileModal = ({ onCancel, onOk }: IEditDebtModalProps) => {
  const { t } = useLocale();

  const { data: appFileData } = useApplicationFileQuery() || {};
  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { mutate: updateDebtorProfile } = useDebtorProfileMutation() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(getRequiredByLabel(t.FIRST_NAME)),
        lastName: yup.string().required(getRequiredByLabel(t.LAST_NAME)),
        dateOfBirth: yup
          .date()
          .typeError(t.INVALID_DATE)
          .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
          .required(getRequiredByLabel(t.DATE_OF_BIRTH)),
        address: yup.string().required(getRequiredByLabel(t.ADDRESS)),
        mainPhoneTypeId: yup.string().required(getRequiredByLabel(t.TYPE)),
        mainPhoneNumber: yup
          .string()
          .required(getRequiredByLabel(t.PHONE_NUMBER))
          .matches(regexPhone, t.PHONE_NOT_VALID),
      }),
    [
      getRequiredByLabel,
      t.ADDRESS,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.DATE_OF_BIRTH,
      t.FIRST_NAME,
      t.INVALID_DATE,
      t.LAST_NAME,
      t.PHONE_NOT_VALID,
      t.PHONE_NUMBER,
      t.TYPE,
    ]
  );

  const initialValues = useMemo(() => getDebtorProfileFormValues(debtorProfile?.profile), [debtorProfile?.profile]);

  const handleSubmit = useCallback(
    (values?: IDebtorProfileFormValues) => {
      updateDebtorProfile(
        { fileId: appFileData?.id, ...convertDebtorProfileFormValuesToDto(values) },
        {
          onSuccess: () => {
            toast.success(t.DEBTOR_PROFILE_IS_UPDATED);
            onOk?.();
          },
        }
      );
    },
    [appFileData?.id, onOk, t.DEBTOR_PROFILE_IS_UPDATED, updateDebtorProfile]
  );

  return (
    <EditModal title={t.EDIT_PROFILE} onCancel={onCancel}>
      <Formik
        initialValues={initialValues}
        onSubmit={(_: IDebtorProfileFormValues, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, values }) => (
          <>
            <EditProfileForm />

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center">
              <Grid item mobile={6} tablet={4}>
                <Button variant="contained" fullWidth disabled={!isValid} onClick={() => handleSubmit(values)}>
                  {t.SAVE}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </EditModal>
  );
};

export default EditProfileModal;
