import { useMemo } from 'react';
import { useFormikContext, getIn } from 'formik';

import { ToggleWithInputFields } from '../../../../../components/FormFieldsPresets';
import { DatePickerField } from '../../../../../components/FormFields';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { IFormQuestionnaireValues } from '../types';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const DebtAreaSpouse = () => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  return (
    <>
      <AppFormSectionSubtitle>{t.DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM}</AppFormSectionSubtitle>

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromFineOrPenaltyImposedByCourt"
        toggleFieldLabel={t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT}
        inputFieldName="spouseDebtFromFineOrPenaltyImposedByCourtDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromRecognizanceOfBailBond"
        toggleFieldLabel={t.RECOGNIZANCE_OF_BAIL_BOND}
        inputFieldName="spouseDebtFromRecognizanceOfBailBondDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromAlimony"
        toggleFieldLabel={t.ALIMONY}
        inputFieldName="spouseDebtFromAlimonyDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromMaintenanceOfAffiliationOrder"
        toggleFieldLabel={t.MAINTENANCE_OF_AFFILIATION_ORDER}
        inputFieldName="spouseDebtFromMaintenanceOfAffiliationOrderDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily"
        toggleFieldLabel={t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY}
        inputFieldName="spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromFraud"
        toggleFieldLabel={t.FRAUD}
        inputFieldName="spouseDebtFromFraudDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromEmbezzlement"
        toggleFieldLabel={t.EMBEZZLEMENT}
        inputFieldName="spouseDebtFromEmbezzlementDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromMisappropriation"
        toggleFieldLabel={t.MISAPPROPRIATION}
        inputFieldName="spouseDebtFromMisappropriationDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity"
        toggleFieldLabel={t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY}
        inputFieldName="spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud"
        toggleFieldLabel={t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD}
        inputFieldName="spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseDebtFromStudentLoansOutstanding"
        toggleFieldLabel={t.STUDENT_LOANS_OUTSTANDING}
        inputFieldName="spouseDebtFromStudentLoansOutstandingDetails"
        disabled={isLocked}
      />

      {Boolean(getIn(values, 'isSpouseDebtFromStudentLoansOutstanding')) && (
        <DatePickerField
          name="spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent"
          label={t.DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT}
          required
          disableFuture
          format={DATE_FORMAT}
          disabled={isLocked}
        />
      )}
    </>
  );
};

export default DebtAreaSpouse;
