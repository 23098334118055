import { useMemo, forwardRef } from 'react';
import { Skeleton, Box } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import ListLayout from '../../../components/ListLayout/ListLayout';
import Loader from '../../../components/Loader';
import { IAppointmentDetailsUpdate, IOfficeLocationGoogleMap } from '../types';
import { convertOfficeLocationDtoToReadableAddress, getPinByIndexNumber } from '../utils';
import { useCountriesQuery, useProvincesQuery, useRecaptchaTokenValidationMutation } from '../queries';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { BookConsultation } from '../../../app/constants/ReCaptchaActions';

interface IProps {
  locations?: IOfficeLocationGoogleMap[];
  appointmentDetails?: IAppointmentDetailsUpdate;
  onItemClick?: (appointmentDetails: IAppointmentDetailsUpdate) => void;
}

const LocationsList = forwardRef(({ locations, appointmentDetails, onItemClick }: IProps, ref) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { data: countries, isLoading: isLoadingCountries } = useCountriesQuery() || {};

  const canadaCountryCode = useMemo(() => countries?.find((item) => item.code === 'CA')?.id, [countries]);

  const { data: provinces, isLoading: isLoadingProvinces } = useProvincesQuery(canadaCountryCode) || {};
  const { mutate } = useRecaptchaTokenValidationMutation();

  return (
    <Box ref={ref} data-testid="closest-offices-list">
      <ListLayout
        items={(locations || Array(5)?.fill(undefined))?.map((item, index) => {
          const province = provinces?.find((province) => province.id === item?.provinceId);
          const officeLocationAddress = convertOfficeLocationDtoToReadableAddress({
            ...item,
            province: province?.code,
          });
          return {
            id: item?.id || index,
            title: item ? item?.name : <Skeleton />,
            subtitle: item ? officeLocationAddress : <Skeleton />,
            icon: <img src={getPinByIndexNumber(index)} alt="pin" />,
            iconProps: { sx: { ml: 1.5 } },
            actionArea: 'container',
            actionButtonIcon: <ChevronRight fontSize="large" />,
            actionButtonProps: {
              name: 'action-button',
              onClick: async () => {
                if (!executeRecaptcha) return;
                mutate(await executeRecaptcha(BookConsultation), {
                  onSuccess: () => {
                    onItemClick?.({ officeLocationId: item?.id, officeLocationAddress });
                  },
                });
              },
            },
            primaryTextProps: { sx: { fontSize: 18, fontWeight: 700 } },
            secondaryTextProps: { sx: { fontSize: 14 } },
            containerProps: {
              custom: { bordered: true },
              sx: {
                visibility: item ? 'visible' : 'hidden',
                ...{
                  borderRadius: 5,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  '& .MuiListItemIcon-root': {
                    minWidth: 48,
                  },
                },
              },
            },
          };
        })}
        paginationProps={{
          rowsPerPage: 5,
        }}
      />
      <Loader loading={isLoadingCountries || isLoadingProvinces} />
    </Box>
  );
});

export default LocationsList;
