import { Grid, Skeleton, Stack, Typography } from '@mui/material';

const BalanceCardSkeleton = () => {
  return (
    <>
      <Stack alignItems="center">
        <Skeleton variant="circular" width={140} height={140} />
      </Stack>

      <Grid container justifyContent="center" alignItems="center" gap={1} pt={2}>
        <Grid item mobile="auto">
          <Stack alignItems="flex-end" rowGap={1}>
            <Skeleton variant="circular" width={20} height={20} />

            <Skeleton variant="circular" width={20} height={20} />
          </Stack>
        </Grid>
        <Grid item mobile="auto">
          <Stack rowGap={1}>
            <Typography variant="body2">
              <Skeleton width={200} />
            </Typography>

            <Typography variant="body2">
              <Skeleton width={200} />
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default BalanceCardSkeleton;
