import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useTheme, useMediaQuery, Typography, Stack, Box } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import CustomTable, { IColumnType } from '../../../components/CustomTable';
import ListLayout from '../../../components/ListLayout/ListLayout';

import useLocale from '../../../app/hooks/useLocale';

import { ROUTES } from '../../../app/routes';
import moment, { DATE_FORMAT5, DATE_FORMAT3, serverDateToInputFormat } from '../../../app/utils/dateTimeHelpers';
import { IIEReportsSearchParams } from '../types';
import { usePendingIEReportsQuery } from '../queries';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const DEFAULT_PAGE_SIZE = 10;

const PendingIEReports = () => {
  const { t, getLocalizedDate } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const [searchParams, setSearchParams] = useState<IIEReportsSearchParams>({ maxResultCount: DEFAULT_PAGE_SIZE });

  const { data: reports } = usePendingIEReportsQuery(searchParams);

  const handleSort = useCallback((column: any, sortDirection: any) => {
    setSearchParams((prev) => ({ ...prev, sorting: `${column} ${sortDirection}` }));
  }, []);

  const getReadableStatus = useCallback(
    (status?: ClientPublicService.IncomeExpensePeriodStatusEnum) => {
      switch (status) {
        case ClientPublicService.IncomeExpensePeriodStatusEnum.Pending:
          return t.PENDING;
        case ClientPublicService.IncomeExpensePeriodStatusEnum.Received:
          return t.RECEIVED;
        case ClientPublicService.IncomeExpensePeriodStatusEnum.Completed:
          return t.COMPLETED;
        case ClientPublicService.IncomeExpensePeriodStatusEnum.MissingInformation:
          return t.MISSING_INFORMATION;
        case ClientPublicService.IncomeExpensePeriodStatusEnum.DiscrepancyNotice:
          return t.DISCREPANCY_NOTICE;
        case ClientPublicService.IncomeExpensePeriodStatusEnum.AmendmentRequired:
          return t.AMENDMENT_REQUIRED;
        default:
          return t.PENDING;
      }
    },
    [t.AMENDMENT_REQUIRED, t.COMPLETED, t.DISCREPANCY_NOTICE, t.MISSING_INFORMATION, t.PENDING, t.RECEIVED]
  );

  const columns: IColumnType[] = [
    {
      title: t.PERIOD,
      dataIndex: 'dueDate',
      key: 'period',
      sortable: false,
      render: (date?: moment.Moment) => (
        <>{getLocalizedDate(serverDateToInputFormat(date)?.subtract(1, 'months'), DATE_FORMAT5)}</>
      ),
      width: '30%',
    },
    {
      title: t.STATUS,
      dataIndex: 'status',
      key: 'status',
      render: (
        status?: ClientPublicService.IncomeExpensePeriodStatusEnum,
        item?: ClientPublicService.IncomeExpenseSubmissionPeriodDto
      ) => (
        <>
          {getReadableStatus(status)}
          {item?.comments && ` - ${item?.comments}`}
        </>
      ),
      sortable: false,
      width: '30%',
    },
    {
      title: t.DUE_DATE,
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (date?: moment.Moment) => <>{getLocalizedDate(serverDateToInputFormat(date), DATE_FORMAT3)}</>,
      width: '30%',
    },

    {
      title: t.ACTION,
      dataIndex: 'action',
      key: 'action',
      sortable: false,
      render: (_: any, item: ClientPublicService.IncomeExpenseSubmissionPeriodDto) => (
        <Link
          component="span"
          sx={{ cursor: 'pointer', color: theme.palette.cobalt, fontWeight: 600 }}
          onClick={() => navigate(`${ROUTES.IE_REPORT}/${item?.externalIeSubmissionPeriodId}`)}
        >
          {t.EDIT}
        </Link>
      ),
    },
  ];

  if (reports === undefined) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={isDesktop ? 0 : 1.5} mt={2}>
        <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.urgentRed }}>
          {t.PENDING} ({reports?.length || 0})
        </Typography>

        {isMobile ? (
          <Box data-testid="list-layout">
            <ListLayout
              items={reports?.map((item: ClientPublicService.IncomeExpenseSubmissionPeriodDto) => ({
                id: item?.externalIeSubmissionPeriodId as string,
                title: (
                  <span style={{ fontWeight: 600 }}>
                    {getLocalizedDate(serverDateToInputFormat(item?.dueDate)?.subtract(1, 'months'), DATE_FORMAT5)}
                  </span>
                ),
                subtitle: (
                  <Stack>
                    <Box sx={{ color: theme.palette.urgentRed, fontWeight: 600 }}>
                      {getReadableStatus(item?.status)}
                      {item?.comments && ` - ${item?.comments}`}
                    </Box>
                    <div>{`${t.DUE_DATE}: ${getLocalizedDate(
                      serverDateToInputFormat(item?.dueDate),
                      DATE_FORMAT3
                    )}`}</div>
                  </Stack>
                ),
                actionButtonIcon: <ChevronRight fontSize="large" sx={{ color: theme.palette.cobalt }} />,
                actionButtonProps: {
                  onClick: () => navigate(`${ROUTES.IE_REPORT}/${item?.externalIeSubmissionPeriodId}`),
                },
              }))}
              paginationProps={{ rowsPerPage: DEFAULT_PAGE_SIZE }}
              emptyValue={t.NO_DOCUMENTS_FOUND}
            />
          </Box>
        ) : (
          <Box sx={{ pb: 2 }} data-testid="table-layout">
            <CustomTable
              data={reports}
              columns={columns}
              emptyValue={t.NO_DOCUMENTS_FOUND}
              onColumnSort={handleSort}
              paginationProps={{ rowsPerPage: DEFAULT_PAGE_SIZE }}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default PendingIEReports;
