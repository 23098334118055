import { useEffect, useMemo } from 'react';
import useLocale from '../../../app/hooks/useLocale';
import { Link, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { useFormikContext } from 'formik';
import { getAllObjectProps } from '../../../app/utils/helpers';

interface IErrorAndWarningListProps {
  disabled?: boolean;
}

const ErrorAndWarningList = ({ disabled }: IErrorAndWarningListProps) => {
  const theme = useTheme();
  const { t } = useLocale();
  const { errors, getFieldMeta, submitCount, submitForm } = useFormikContext();

  const errorsAndWarningsInputNames = useMemo(() => getAllObjectProps(errors), [errors]);
  const warningInputNames = useMemo(() => {
    return errorsAndWarningsInputNames.filter((item) => getFieldMeta(item)?.error?.includes('[recommended]'));
  }, [errorsAndWarningsInputNames, getFieldMeta]);
  const errorInputNames = useMemo(() => {
    return errorsAndWarningsInputNames.filter((item) => !getFieldMeta(item)?.error?.includes('[recommended]'));
  }, [errorsAndWarningsInputNames, getFieldMeta]);

  useEffect(() => {
    if (
      !disabled &&
      submitCount === 0 &&
      ((warningInputNames && warningInputNames?.length !== 0) || (errorInputNames && errorInputNames?.length !== 0))
    ) {
      submitForm();
    }
  }, [submitCount, submitForm, warningInputNames, errorInputNames, disabled]);

  if ((!warningInputNames || !warningInputNames?.length) && (!errorInputNames || !errorInputNames?.length))
    return <></>;

  return (
    <List
      sx={{
        backgroundColor: `${theme.palette.error.main}10`,
        mb: 2.5,
      }}
    >
      {errorInputNames &&
        errorInputNames.length !== 0 &&
        errorInputNames?.map((item, index) => {
          const { error } = getFieldMeta(item);

          if (typeof error !== 'string') return null;

          return (
            <ListItem key={index} alignItems="flex-start" dense data-testid={`error-item-${index}`}>
              <Stack direction="row" spacing={1}>
                <CancelIcon style={{ fontSize: 16, color: theme.palette.urgentRed }} />
                <Link
                  component="button"
                  onClick={() => {
                    const element = document.querySelector(`[name="${item}"]`) as HTMLInputElement;
                    if (element) {
                      setTimeout(() => {
                        element.focus();
                      }, 500);
                    }
                  }}
                  sx={{
                    '&.MuiLink-root': {
                      color: theme.palette.urgentRed,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: 12,
                    },
                  }}
                >
                  {t.GO_TO_ERROR}
                </Link>
                <Typography variant="body4">{error}</Typography>
              </Stack>
            </ListItem>
          );
        })}
      {warningInputNames &&
        warningInputNames?.length !== 0 &&
        warningInputNames?.map((item, index) => {
          const { error } = getFieldMeta(item);

          if (typeof error !== 'string') return null;

          return (
            <ListItem key={index} alignItems="flex-start" dense>
              <Stack direction="row" spacing={1} data-testid={`warning-item-${index}`}>
                <ErrorIcon style={{ fontSize: 16, color: theme.palette.warningOrange }} />
                <Link
                  component="button"
                  onClick={() => {
                    const element = document.querySelector(`[name="${item}"]`) as HTMLInputElement;
                    if (element) {
                      setTimeout(() => {
                        element.focus();
                      }, 500);
                    }
                  }}
                  sx={{
                    '&.MuiLink-root': {
                      color: theme.palette.urgentRed,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: 12,
                    },
                  }}
                >
                  {t.GO_TO_WARNING}
                </Link>
                <Typography variant="body4">{error?.replace('[recommended]', '')}</Typography>
              </Stack>
            </ListItem>
          );
        })}
    </List>
  );
};

export default ErrorAndWarningList;
