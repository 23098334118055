import { Box } from '@mui/material';
import { useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';
import { TextField, CheckboxField, NumberField, DatePickerField } from '../../../../../../../components/FormFields';

import useLocale from '../../../../../../../app/hooks/useLocale';
import { IAssetFormValues } from '../types';
import { EditAssetModalContext } from '../EditAssetModalProvider';
import { YEAR_FORMAT } from '../../../../../../../app/utils/dateTimeHelpers';

const MotorVehiclesDetails = () => {
  const { t } = useLocale();

  const { assetId, isLocked } = useContext(EditAssetModalContext);
  const { submitForm } = useFormikContext<IAssetFormValues>();

  useEffect(() => {
    if (assetId) submitForm();
  }, [assetId, submitForm]);

  return (
    <Box data-testid="motor-vehicles">
      <DatePickerField
        views={['year']}
        name="year"
        required
        label={t.YEAR}
        disableFuture
        format={YEAR_FORMAT}
        disabled={isLocked}
      />

      <TextField name="make" label={t.MAKE} required inputProps={{ maxLength: 100 }} disabled={isLocked} />

      <TextField name="model" label={t.MODEL} required inputProps={{ maxLength: 100 }} disabled={isLocked} />

      <TextField name="trim" label={t.TRIM} inputProps={{ maxLength: 100 }} disabled={isLocked} />

      <TextField name="style" label={t.STYLE} inputProps={{ maxLength: 100 }} disabled={isLocked} />

      <NumberField
        name="kilometers"
        label={t.KMS}
        inputProps={{
          allowNegative: false,
          decimalScale: 0,
          isAllowed: (values: any) => values?.floatValue <= 999999999 || values?.floatValue === undefined,
        }}
        disabled={isLocked}
      />

      <TextField name="vin" label={t.VIN} inputProps={{ maxLength: 100 }} recommended disabled={isLocked} />

      <CheckboxField
        name="isVehicleNeededForEmployment"
        label={t.VEHICLE_NEEDED_FOR_EMPLOYMENT}
        sx={{ fontWeight: 600 }}
        disabled={isLocked}
      />
    </Box>
  );
};

export default MotorVehiclesDetails;
