import { rest } from 'msw';

export const appFormStatus = {
  personalInfoStatus: 1,
  assetsDebtsStatus: 0,
  incomeExpenseStatus: 3,
  incomeTaxStatus: 0,
  bankingInformationStatus: 4,
  questionnaireStatus: 5,
};

const appFormStatusGET = rest.get('/app-form-status', (req, res, ctx) => {
  return res(ctx.json(appFormStatus));
});

export const handlers = [appFormStatusGET];
