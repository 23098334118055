import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

import { IUnifiedIncomeDto } from '../../../../../../components/EditIncomeComponent/types';

const getIncomeFormItem = (item?: ClientPublicService.IAppFormIncomeExpenseIncomeDetailDto) => ({
  id: item?.id || undefined,
  isInitial: item?.isInitial || false,
  incomeTypeId: item?.incomeTypeId || undefined,
  description: item?.description || '',
  applicantAmount: item?.applicantAmount || undefined,
  spouseAmount: item?.spouseAmount || undefined,
  otherHouseholdMemberAmount: item?.otherHouseholdMemberAmount || undefined,
});

const convertFormItemToIncomeDto = (formValue?: IUnifiedIncomeDto) =>
  ({
    id: formValue?.id,
    isInitial: formValue?.isInitial || false,
    incomeTypeId: formValue?.incomeTypeId,
    description: formValue?.description || undefined,
    applicantAmount: formValue?.applicantAmount || 0,
    spouseAmount: formValue?.spouseAmount || 0,
    otherHouseholdMemberAmount: formValue?.otherHouseholdMemberAmount || 0,
  } as ClientPublicService.AppFormIncomeExpenseIncomeDetailDto);

export { getIncomeFormItem, convertFormItemToIncomeDto };
