import { Wrapper, Status } from '@googlemaps/react-wrapper';

import ErrorBoundary from '../ErrorBoundary';

import useLocale from '../../app/hooks/useLocale';

import { useGoogleApiQuery } from './queries';

const WEEKLY_VERSION = 'weekly';
const PLACES_LIBRARY = 'places';
const GEOMETRY_LIBRARY = 'geometry';

interface IProps {
  component: React.ReactElement;
}

const GoogleMapsLoaderWrapper = ({ component }: IProps): React.ReactElement => {
  const { t } = useLocale();

  const { data } = useGoogleApiQuery() || {};

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <div>{t.LOADING_MAP}</div>;
      case Status.FAILURE:
        return <div>{t.ERROR_LOADING_MAP}</div>;
      case Status.SUCCESS:
        return component;
    }
  };

  if (!data?.apiKey) return <></>;

  return (
    <ErrorBoundary>
      <Wrapper
        render={render}
        {...{
          version: WEEKLY_VERSION,
          libraries: [PLACES_LIBRARY, GEOMETRY_LIBRARY],
          apiKey: data?.apiKey || '',
        }}
      />
    </ErrorBoundary>
  );
};

export default GoogleMapsLoaderWrapper;
