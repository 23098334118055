import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { useEffect, useContext } from 'react';

import { CheckboxField, NumberField, PercentField } from '../../../../../../../components/FormFields';
import { LocationFields } from '../../../../../../../components/FormFieldsPresets';

import useLocale from '../../../../../../../app/hooks/useLocale';

import { IAssetFormValues } from '../types';
import { useAppFormAssetsConfigQuery, useApplicationFileQuery } from '../../../queries';
import { EditAssetModalContext } from '../EditAssetModalProvider';

const RealEstateDetails = () => {
  const { t } = useLocale();
  const { values, setFieldValue, submitForm, initialValues } = useFormikContext<IAssetFormValues>();
  const { assetId, isLocked } = useContext(EditAssetModalContext);

  const { data: appFile } = useApplicationFileQuery() || {};
  const { data: config } = useAppFormAssetsConfigQuery(appFile?.id) || {};

  useEffect(() => {
    if (assetId) submitForm();
  }, [assetId, submitForm]);

  return (
    <Box data-testid="real-estate-details">
      <LocationFields
        presetType="short"
        addressFieldName="address"
        streetNameFieldName="streetName"
        streetNumberFieldName="streetNumber"
        unitFieldName="unit"
        unitFieldLabel={t.ADDRESS_UNIT}
        cityFieldName="city"
        provinceIdFieldName="provinceId"
        countryIdFieldName="countryId"
        postalCodeFieldName="postalCode"
        requiredFields={['address']}
        disabledFields={isLocked ? 'all' : undefined}
      />

      <PercentField
        name="applicantOwnershipPercentage"
        recommended
        label={t.APPLICANT_OWNERSHIP_PERCENTAGE}
        data-testid="applicantOwnershipPercentage"
        onChange={(e: any) => {
          const value = e?.target?.value;
          setFieldValue('applicantOwnershipPercentage', value);
          if (value + (values?.spouseOwnershipPercentage || 0) > 100) {
            setFieldValue('spouseOwnershipPercentage', 100 - value);
            setFieldValue('otherOwnershipPercentage', 0);
          } else {
            setFieldValue('otherOwnershipPercentage', 100 - value - (values?.spouseOwnershipPercentage || 0));
          }
        }}
        disabled={isLocked}
      />

      <PercentField
        name="spouseOwnershipPercentage"
        recommended
        label={t.SPOUSE_OWNERSHIP_PERCENTAGE}
        data-testid="spouseOwnershipPercentage"
        onChange={(e: any) => {
          const value = e?.target?.value;
          setFieldValue('spouseOwnershipPercentage', value);
          if (value + (values?.applicantOwnershipPercentage || 0) > 100) {
            setFieldValue('applicantOwnershipPercentage', 100 - value);
            setFieldValue('otherOwnershipPercentage', 0);
          } else {
            setFieldValue('otherOwnershipPercentage', 100 - value - (values?.applicantOwnershipPercentage || 0));
          }
        }}
        disabled={isLocked}
      />

      <NumberField
        name="totalNumberOfOwners"
        label={t.TOTAL_NUMBER_OF_OWNERS}
        data-testid="totalNumberOfOwners"
        required
        inputProps={{
          allowNegative: false,
          decimalScale: 0,
          isAllowed: (values: any) => values?.floatValue <= 99 || values?.floatValue === undefined,
        }}
        disabled={isLocked}
      />

      <PercentField
        name="otherOwnershipPercentage"
        label={t.OTHER_OWNERSHIP_PERCENTAGE}
        data-testid="otherOwnershipPercentage"
        disabled
      />

      {config?.isManitoba && (
        <CheckboxField
          name="isOwnershipHeldInJointTenancy"
          label={t.OWNERSHIP_HELD_IN_JOINT_TENANCY}
          data-testid="isOwnershipHeldInJointTenancy"
          sx={{ fontWeight: 600 }}
          disabled={isLocked}
        />
      )}

      <CheckboxField
        name="isPrincipalResidence"
        label={t.PRINCIPAL_RESIDENCE}
        data-testid="isPrincipalResidence"
        disabled={initialValues?.isPrincipalResidence || isLocked}
        sx={{ fontWeight: 600 }}
      />
    </Box>
  );
};

export default RealEstateDetails;
