import { useContext, useEffect, useMemo } from 'react';
import { Typography, Grid, styled, Divider } from '@mui/material';

import { FieldArray, useFormikContext } from 'formik';
import { CurrencyField, TextField } from '../FormFields';
import TableHead from '../ExpenderHeader';
import ExpenderTabs from '../ExpenderTabs';

import useLocale from '../../app/hooks/useLocale';

import { EditIncomeModalContext } from './EditIncomeModalProvider';

import { IUnifiedIncomeDto, IIncomeFormValues } from './types';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditIncomeForm = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const { values, isValid, setFieldValue } = useFormikContext<IIncomeFormValues>();
  const { setIsDocumentTabVisible, expenderTab, setExpenderTab, isEditable } = useContext(EditIncomeModalContext);

  const isDocumentsTabVisible = useMemo(
    () =>
      Boolean(
        values?.incomeDetails
          ?.filter((detail) => detail?.applicantAmount || detail?.spouseAmount || detail?.otherHouseholdMemberAmount)
          .some(
            (item?: IUnifiedIncomeDto) =>
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.EmploymentIncome ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.PensionOrAnnuities ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.EmploymentInsuranceBenefits ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.SocialAssistance ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.SelfEmploymentIncome ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.ChildTaxBenefit ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.RentalIncome ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.GovernmentBenefits ||
              item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.OtherBenefits
          )
      ),
    [values?.incomeDetails]
  );

  useEffect(() => {
    setIsDocumentTabVisible?.(isDocumentsTabVisible);
  }, [setIsDocumentTabVisible, isDocumentsTabVisible]);

  return (
    <>
      <ExpenderTabs
        expenderTab={expenderTab}
        setExpenderTab={setExpenderTab}
        disabled={!isValid}
        isMarried={values?.isMarried}
      />
      <>
        {values?.incomeDetails && values?.incomeDetails?.length > 0 && (
          <>
            <ModalDivider />

            <TableHead namesColumnTitle={t.TYPE} expenderTab={expenderTab} />

            <FieldArray name="incomeDetails">
              {() => (
                <>
                  {values?.incomeDetails?.map((item?: IUnifiedIncomeDto, index?: number) => {
                    return (
                      <Grid container key={index} columnSpacing={2}>
                        <Grid item mobile={6} alignItems="top" display="flex" data-testid={`description.${index}`}>
                          {item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other ||
                          item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.OtherBenefits ||
                          item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.GovernmentBenefits ? (
                            <TextField
                              name={`incomeDetails.${index}.description`}
                              label={
                                item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other
                                  ? t.OTHER
                                  : item?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.OtherBenefits
                                  ? t.OTHER_BENEFITS
                                  : item?.incomeType?.enumValue ===
                                    ClientPublicService.IncomeTypeEnum.GovernmentBenefits
                                  ? t.GOVERNMENT_BENEFITS
                                  : t.OTHER
                              }
                              sx={{ '&.MuiTextField-root': { ml: 0, mt: 1, mb: 1 } }}
                              size="small"
                              required
                              fullWidth
                              onChange={(event: any) => {
                                setFieldValue(`incomeDetails.${index}.description`, event?.target?.value, true);
                              }}
                              disabled={!isEditable}
                            />
                          ) : (
                            <Typography variant="body2" alignSelf="center">
                              {getLocalizedDtoName(item?.incomeType)}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== 'applicantAmount'}
                          data-testid={`${index}-applicantAmount`}
                        >
                          <CurrencyField
                            name={`incomeDetails.${index}.applicantAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== 'spouseAmount'}
                          data-testid={`${index}-spouseAmount`}
                        >
                          <CurrencyField
                            name={`incomeDetails.${index}.spouseAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>

                        <Grid
                          item
                          mobile={6}
                          hidden={expenderTab !== 'otherHouseholdMemberAmount'}
                          data-testid={`${index}-otherHouseholdMemberAmount`}
                        >
                          <CurrencyField
                            name={`incomeDetails.${index}.otherHouseholdMemberAmount`}
                            size="small"
                            disabled={!isEditable}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              )}
            </FieldArray>
          </>
        )}
      </>
    </>
  );
};

export default EditIncomeForm;

const ModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
}));
