import { createContext, useState, useMemo, useCallback, useEffect } from 'react';

import { IExpenseModalTab } from './types';
import { IExpenderType } from '../ExpenderTabs';
import { ISupportDocsUploadProps } from '../DocumentsUpload/SupportDocsUpload';

import { useListExpenseTypesQuery } from '../../features/AppForm/modules/IncomeExpense/queries';
import useLocale from '../../app/hooks/useLocale';

export const APPLICANT_TAB = 'applicant';
export const SPOUSE_TAB = 'spouse';
export const OTHER_TAB = 'other';

export const DOCUMENTS_TAB = 'documents';

interface IEditExpenseContext {
  tabs?: IExpenseModalTab[];
  currentTab?: IExpenseModalTab;
  setCurrentTab: (tab?: IExpenseModalTab) => void;
  currentTabNumber: number;
  tabsCount: number;
  expenderTab: IExpenderType;
  setExpenderTab: (tab: IExpenderType) => void;
  goToNextTab?: () => void;
  goToPreviousTab?: () => void;
  isDocumentsTabVisible?: boolean;
  setIsDocumentsTabVisible?: (isDocumentsTabVisible: boolean) => void;
  isEditable?: boolean;
  documentsUploadProps?: ISupportDocsUploadProps;
}

export const initialEditExpenseModalContextValues: IEditExpenseContext = {
  tabs: [],
  currentTab: undefined,
  setCurrentTab: () => {},
  currentTabNumber: 0,
  tabsCount: 0,
  expenderTab: 'applicantAmount',
  setExpenderTab: () => {},
  goToNextTab: () => {},
  goToPreviousTab: () => {},
  isDocumentsTabVisible: false,
  setIsDocumentsTabVisible: () => {},
  isEditable: true,
  documentsUploadProps: {},
};

export const EditExpenseContext = createContext<IEditExpenseContext>(initialEditExpenseModalContextValues);

interface IProps {
  children: React.ReactElement;
  isEditable?: boolean;
  documentsUploadProps?: ISupportDocsUploadProps;
}

const EditExpenseProvider = ({ children, isEditable, documentsUploadProps }: IProps): React.ReactElement => {
  const { t, getLocalizedDtoName } = useLocale();

  const [currentTab, setCurrentTab] = useState<IExpenseModalTab>();
  const [isDocumentsTabVisible, setIsDocumentsTabVisible] = useState<boolean>(false);
  const [expenderTab, setExpenderTab] = useState<IExpenderType>('applicantAmount');

  const { data: expenseTypes, isSuccess } = useListExpenseTypesQuery() || {};

  const tabs = useMemo(() => {
    if (!expenseTypes || expenseTypes.length === 0) {
      return [];
    }
    return [
      ...expenseTypes.map((item) => ({
        orderNumber: (item.orderNumber || 1) - 1,
        name: getLocalizedDtoName(item),
        key: item.id,
        conditionalRender: true,
      })),
      {
        orderNumber: expenseTypes.length,
        name: t.DOCUMENTS,
        key: DOCUMENTS_TAB,
        conditionalRender: isDocumentsTabVisible,
      },
    ]?.filter((item) => item?.conditionalRender);
  }, [expenseTypes, getLocalizedDtoName, isDocumentsTabVisible, t.DOCUMENTS]);

  const tabsCount = useMemo(() => tabs?.length || 0, [tabs]);

  const currentTabNumber = useMemo(() => currentTab?.orderNumber || 0, [currentTab]);

  const goToNextTab = useCallback(() => {
    setCurrentTab(tabs?.find((item) => item?.orderNumber === currentTabNumber + 1));
  }, [tabs, currentTabNumber]);

  const goToPreviousTab = useCallback(() => {
    setCurrentTab(tabs?.find((item) => item?.orderNumber === currentTabNumber - 1));
  }, [tabs, currentTabNumber]);

  useEffect(() => {
    if (tabs?.length && currentTab === undefined) {
      setCurrentTab(tabs?.find((tab) => tab?.orderNumber === 0));
    }
  }, [expenseTypes, currentTab, isSuccess, tabs, setCurrentTab]);

  return (
    <EditExpenseContext.Provider
      value={{
        tabs,
        currentTab,
        setCurrentTab,
        currentTabNumber,
        tabsCount,
        expenderTab,
        setExpenderTab,
        goToNextTab,
        goToPreviousTab,
        isDocumentsTabVisible,
        setIsDocumentsTabVisible,
        isEditable,
        documentsUploadProps,
      }}
    >
      {children}
    </EditExpenseContext.Provider>
  );
};

export default EditExpenseProvider;
