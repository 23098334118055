import { useQuery } from '@tanstack/react-query';

import { DEBTOR_INFORMATION_QUERY_KEY } from '../../app/constants/reactQueryKeys';

import API from '../../app/api/api';

export const useDebtorProfileQuery = (isAuthenticated?: boolean) =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
    enabled: !!isAuthenticated,
  });
