import { useMemo } from 'react';
import { MenuItem, useTheme, CircularProgress, Box } from '@mui/material';
import { Select } from 'formik-mui';
import { useField } from 'formik';

import CustomLabel from '../CustomLabel';
import useLocale from '../../app/hooks/useLocale';
import RecommendableField, { IRecommendableFieldProp } from './RecommendableField';

export interface ISelectItem {
  id?: string;
  name?: string;
  code?: string;
  localizedString?: string;
}

interface ISelectField extends IRecommendableFieldProp {
  options?: ISelectItem[];
  loading?: boolean;
}

const SelectField = ({ loading, label, required, recommended, ...props }: ISelectField) => {
  const { getLocalizedDtoName } = useLocale();
  const [, meta] = useField(props?.name);
  const theme = useTheme();

  const itemFound = useMemo(
    () => props?.options?.find((item: ISelectItem) => item?.id === meta?.value),
    [meta?.value, props?.options]
  );

  const options = useMemo(() => props?.options || [], [props?.options]);

  const menu = useMemo(
    () =>
      loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1.5 }}>
          <CircularProgress sx={{ color: theme.palette.cobalt }} size={24} />
        </Box>
      ) : (
        (itemFound ? options : [{ id: meta.value, name: '' }, ...options])?.map((item: ISelectItem) => (
          <MenuItem key={item?.id + ''} value={item?.id + ''} sx={{ display: item?.id ? 'block' : 'none' }}>
            {getLocalizedDtoName(item) || ''}
          </MenuItem>
        ))
      ),
    [getLocalizedDtoName, itemFound, loading, meta.value, options, theme.palette.cobalt]
  );

  const isRecommendedError = useMemo(
    () => meta.error && meta.touched && meta.error?.includes('[recommended]'),
    [meta.error, meta.touched]
  );

  return (
    <RecommendableField
      {...props}
      isFastField={false}
      component={Select}
      label={<CustomLabel label={label} required={required} recommended={recommended} />}
      formControl={{
        fullWidth: true,
        margin: 'dense',
        sx: {
          ...(isRecommendedError
            ? {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${
                    props?.disabled ? theme.palette.disabledGray : theme.palette.warningOrange
                  } !important`,
                },
                '.MuiFormHelperText-root.Mui-error': {
                  display: 'none !important',
                },
                '.Mui-error': {
                  color: `${theme.palette.warningOrange} !important`,
                },
              }
            : {}),
          ...props?.sx,
        },
      }}
      inputProps={{ ...props.inputProps }}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 400,
          },
        },
      }}
    >
      {menu}
    </RecommendableField>
  );
};

export default SelectField;
