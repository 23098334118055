import { useMemo, useCallback } from 'react';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import yup from '../../../../app/utils/customYup';

import EditModal, { IEditModalProps } from '../../../../components/EditModal';
import ChangeRequestForm from './ChangeRequestForm';
import Loader from '../../../../components/Loader';

import useLocale from '../../../../app/hooks/useLocale';

import { useUpdateDebitInformationMutation } from '../../queries';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { IDebitInfoUpdateParams } from '../../types';

interface IEditPaymentDetailsModal extends Pick<IEditModalProps, 'onCancel'> {
  fileId: string;
  onOk?: () => void;
}

const RequestChangeModal = ({ fileId, onCancel, onOk }: IEditPaymentDetailsModal) => {
  const { t } = useLocale();

  const { mutate: updateDebitInformation, isLoading: isUpdating } = useUpdateDebitInformationMutation() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        accountName: yup.string().required(getRequiredByLabel(t.ACCOUNT_NAME)),
        bankName: yup.string().required(getRequiredByLabel(t.BANK_NAME)),
        bankAddress: yup.string().required(getRequiredByLabel(t.BANK_ADDRESS)),
        accountNumber: yup.string().required(getRequiredByLabel(t.ACCOUNT_NUMBER)),
        transitNumber: yup.string().required(getRequiredByLabel(t.TRANSIT_NUMBER)),
        branchNumber: yup.string().required(getRequiredByLabel(t.BRANCH_NUMBER)),
      }),
    [
      getRequiredByLabel,
      t.ACCOUNT_NAME,
      t.ACCOUNT_NUMBER,
      t.BANK_ADDRESS,
      t.BANK_NAME,
      t.BRANCH_NUMBER,
      t.TRANSIT_NUMBER,
    ]
  );

  const handleUpdateDebitInformation = useCallback(
    (values?: ClientPublicService.IPADInfoChangeRequestCreatePublicDto) => {
      updateDebitInformation({ fileId, ...values } as IDebitInfoUpdateParams, {
        onSuccess: (data) => {
          if (data?.result === ClientPublicService.Result.Successful) {
            onOk?.();
          } else {
            toast.error(data?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
          }
        },
        onError: () => {
          toast.error(t.SOMETHING_WENT_WRONG);
        },
      });
    },
    [fileId, onOk, t.SOMETHING_WENT_WRONG, updateDebitInformation]
  );

  return (
    <EditModal title={t.CHANGE_PREAUTHORIZED_DEPOSIT} onCancel={onCancel}>
      <Loader loading={isUpdating} />
      <Formik
        initialValues={{
          accountName: '',
          bankName: '',
          bankAddress: '',
          accountNumber: '',
          transitNumber: '',
          branchNumber: '',
        }}
        onSubmit={(_: ClientPublicService.IPADInfoChangeRequestCreatePublicDto, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ values, isValid }) => {
          return (
            <>
              <ChangeRequestForm />

              <Button
                variant="contained"
                fullWidth
                onClick={() => handleUpdateDebitInformation(values)}
                sx={{ mt: 3 }}
                disabled={!isValid}
              >
                {t.REQUEST_CHANGE}
              </Button>
            </>
          );
        }}
      </Formik>
    </EditModal>
  );
};

export default RequestChangeModal;
