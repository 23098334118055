import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { TextField, DatePickerField } from '../../../components/FormFields';
import { LocationFields, PhoneTypeNumberFields } from '../../../components/FormFieldsPresets';

import useLocale from '../../../app/hooks/useLocale';

import { useDebtorProfileQuery } from '../queries';
import { DATE_FORMAT } from '../../../app/utils/dateTimeHelpers';

const EditProfileForm = () => {
  const { t } = useLocale();

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { submitCount, submitForm } = useFormikContext();

  useEffect(() => {
    if (submitCount === 0) {
      submitForm();
    }
  }, [submitCount, submitForm]);

  return (
    <>
      <TextField
        name="firstName"
        label={t.FIRST_NAME}
        required
        inputProps={{ maxLength: 100 }}
        disabled={debtorProfile?.profile?.isFileStagePostSignup}
      />

      <TextField name="middleName" label={t.MIDDLE_NAME} inputProps={{ maxLength: 100 }} />

      <TextField
        name="lastName"
        label={t.LAST_NAME}
        required
        inputProps={{ maxLength: 100 }}
        disabled={debtorProfile?.profile?.isFileStagePostSignup}
      />

      <DatePickerField name="dateOfBirth" label={t.DATE_OF_BIRTH} required disableFuture format={DATE_FORMAT} />

      <LocationFields
        presetType="short"
        addressFieldName="address"
        streetNameFieldName="streetName"
        streetNumberFieldName="streetNumber"
        unitFieldName="unit"
        cityFieldName="city"
        provinceIdFieldName="provinceId"
        countryIdFieldName="countryId"
        postalCodeFieldName="postalCode"
        requiredFields={['address']}
      />

      <PhoneTypeNumberFields
        phoneTypeFieldName="mainPhoneTypeId"
        phoneTypeFieldProps={{ required: true }}
        phoneNumberFieldName="mainPhoneNumber"
        phoneNumberFieldProps={{ required: true }}
      />
    </>
  );
};

export default EditProfileForm;
