import { Paper, PaperProps, styled } from '@mui/material';

export interface IElevatedContainerProps extends PaperProps {
  custom?: {
    bordered?: boolean;
  };
}

const ElevatedContainer = (props: IElevatedContainerProps) => <StyledContainer {...props} />;

const StyledContainer = styled(Paper)<IElevatedContainerProps>((props) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow:
    '0px 2px 4px -2px rgba(0, 0, 0, 0.12), 0px 4px 9px 3px rgba(0, 0, 0, 0.04), 0px 1px 5px 0px rgba(0, 0, 0, 0.012)',
  padding: 12,
  marginBottom: 12,
  border: props?.custom?.bordered ? `solid 0.2px ${props?.theme?.palette?.primary?.main}40` : 'none',
  borderRadius: '20px 0 20px 0',
}));

export default ElevatedContainer;
