import { useEffect, useContext, useMemo, useCallback } from 'react';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import yup from '../../../../../../app/utils/customYup';

import EditModal, { IEditModalProps } from '../../../../../../components/EditModal';
import EditAssetForm from './EditAssetForm';
import EditAssetFooter from './EditAssetFooter';

import useLocale from '../../../../../../app/hooks/useLocale';

import {
  useDocumentsUploadMutation,
  IFormValuesDocumentItem,
  getDocumentsValidationSchema,
} from '../../../../../../components/DocumentsUpload';

import {
  useAssetByIdQuery,
  useAppFormAssetsConfigQuery,
  useUpdateAssetMutation,
  useCreateAssetMutation,
  useApplicationFileQuery,
  useOwnershipTypesQuery,
} from '../../queries';
import EditAssetModalProvider, {
  EditAssetModalContext,
  BASIC_TAB,
  DETAILS_TAB,
  DOCUMENTS_TAB,
} from './EditAssetModalProvider';
import { getInitialAssetValue, convertFormValueToAssetDto } from './utils';
import { validateGreaterThan } from '../../../../../../app/utils/helpers';
import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

type IEditAssetModalProps = Pick<IEditModalProps, 'onCancel'> & {
  assetId?: string;
  isMissingDetails?: boolean;
  isMissingDocuments?: boolean;
  onOk?: (updatedAsset: ClientPublicService.IAppFormAssetsDebtsAssetDto) => void;
  isLocked?: boolean;
};

const GREATER_THAN_VALIDATION = 'greater-than-validation';

const EditAssetComponent = ({
  assetId,
  isMissingDetails,
  isMissingDocuments,
  onCancel,
  onOk,
}: IEditAssetModalProps) => {
  const { t } = useLocale();

  const {
    setAssetId,
    isOther,
    tabs,
    tabsCount,
    currentTab,
    goToNextTab,
    assetTypeEnum,
    assetDescriptionEnum,
    isLocked,
  } = useContext(EditAssetModalContext);

  const { data: appFile, isInitialLoading: isAppFileLoading } = useApplicationFileQuery() || {};

  const { data: asset, isInitialLoading: isLoadingAsset } = useAssetByIdQuery(assetId) || {};
  const { data: config } = useAppFormAssetsConfigQuery(appFile?.id) || {};
  const { data: ownershipTypes } = useOwnershipTypesQuery() || {};

  const { mutate: updateAsset, isLoading: isUpdatingAsset } = useUpdateAssetMutation() || {};
  const { mutate: createAsset, isLoading: isCreatingAsset } = useCreateAssetMutation() || {};
  const { mutateAsync: uploadDocuments, isLoading: isUploadingDocuments } = useDocumentsUploadMutation() || {};

  useEffect(() => {
    if (assetId) setAssetId(assetId);
  }, [assetId, setAssetId]);

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED?.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const getGreaterThanZeroByLabel = useCallback(
    (fieldName: string) => t.SHOULD_BE_GREATER_THAN.replace('{0}', fieldName).replace('{1}', '0'),
    [t.SHOULD_BE_GREATER_THAN]
  );

  const validateNotZero = useCallback((value?: number) => validateGreaterThan(value, 0), []);

  const validationSchemaBasicTab = useMemo(
    () =>
      yup.object().shape({
        assetTypeId: yup.string().required(getRequiredByLabel(t.TYPE)),
        assetDescriptionId: yup.string().required(getRequiredByLabel(t.DESCRIPTION)),
        value: yup.number().test(GREATER_THAN_VALIDATION, getGreaterThanZeroByLabel(t.VALUE), validateNotZero),
        ownershipTypeId: yup.string().required(getRequiredByLabel(t.OWNERSHIP)),
        specifiedDescription: yup.string().when('assetDescriptionId', {
          is: isOther,
          then: (schema) => schema.required(getRequiredByLabel(t.OTHER_SPECIFY)),
          otherwise: (schema) => schema,
        }),
      }),
    [
      getGreaterThanZeroByLabel,
      getRequiredByLabel,
      isOther,
      t.DESCRIPTION,
      t.OTHER_SPECIFY,
      t.OWNERSHIP,
      t.TYPE,
      t.VALUE,
      validateNotZero,
    ]
  );

  const validationSchemaDetailsTab = useMemo(
    () =>
      yup.object().shape({
        address: yup
          .string()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.RealEstate
              ? schema.required(getRequiredByLabel(t.ADDRESS))
              : schema
          ),
        applicantOwnershipPercentage: yup.number().recommended(getRecommendedByLabel(t.APPLICANT_OWNERSHIP_PERCENTAGE)),
        spouseOwnershipPercentage: yup.number().recommended(getRecommendedByLabel(t.SPOUSE_OWNERSHIP_PERCENTAGE)),
        totalNumberOfOwners: yup
          .number()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.RealEstate
              ? schema.test(
                  GREATER_THAN_VALIDATION,
                  getGreaterThanZeroByLabel(t.TOTAL_NUMBER_OF_OWNERS),
                  validateNotZero
                )
              : schema
          ),
        year: yup
          .date()
          .typeError(t.INVALID_DATE)
          .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles
              ? schema.required(getRequiredByLabel(t.YEAR))
              : schema
          ),
        make: yup
          .string()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles
              ? schema.required(getRequiredByLabel(t.MAKE))
              : schema
          ),
        model: yup
          .string()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles
              ? schema.required(getRequiredByLabel(t.MODEL))
              : schema
          ),
        vin: yup
          .string()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles
              ? schema.recommended(getRecommendedByLabel(t.VIN))
              : schema
          ),
        nameOfInstitution: yup
          .string()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies
              ? schema.required(getRequiredByLabel(t.NAME_OF_INSTITUTION))
              : schema
          ),
        contributionsInLast12Months: yup
          .number()
          .when(['assetTypeId'], ([assetTypeId], schema) =>
            assetTypeId && assetTypeEnum === ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies
              ? schema.test(
                  GREATER_THAN_VALIDATION,
                  getGreaterThanZeroByLabel(t.CONTRIBUTIONS_IN_LAST_12_MONTHS),
                  validateNotZero
                )
              : schema
          ),
        grantToBeRepaid: yup.number().recommended(getRecommendedByLabel(t.GRANT_TO_BE_REPAID)),
      }),
    [
      assetTypeEnum,
      getGreaterThanZeroByLabel,
      getRecommendedByLabel,
      getRequiredByLabel,
      t.ADDRESS,
      t.APPLICANT_OWNERSHIP_PERCENTAGE,
      t.CONTRIBUTIONS_IN_LAST_12_MONTHS,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.GRANT_TO_BE_REPAID,
      t.INVALID_DATE,
      t.MAKE,
      t.MODEL,
      t.NAME_OF_INSTITUTION,
      t.SPOUSE_OWNERSHIP_PERCENTAGE,
      t.TOTAL_NUMBER_OF_OWNERS,
      t.VIN,
      t.YEAR,
      validateNotZero,
    ]
  );

  const validationSchema = useMemo(() => {
    switch (tabs?.[currentTab]?.key) {
      case BASIC_TAB:
        return validationSchemaBasicTab;
      case DETAILS_TAB:
        return validationSchemaDetailsTab;
      case DOCUMENTS_TAB:
        return getDocumentsValidationSchema(t.THIS_IS_A_REQUIRED_FIELD);
      default:
        return yup.object();
    }
  }, [currentTab, t.THIS_IS_A_REQUIRED_FIELD, tabs, validationSchemaBasicTab, validationSchemaDetailsTab]);

  return (
    <EditModal
      title={assetId ? t.EDIT_ASSET : t.NEW_ASSET}
      loading={isLoadingAsset || isUpdatingAsset || isCreatingAsset || isAppFileLoading || isUploadingDocuments}
      onCancel={onCancel}
    >
      <Formik
        initialValues={{
          ...getInitialAssetValue(asset, config, ownershipTypes),
        }}
        enableReinitialize
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={Boolean(asset?.id)}
        validateOnChange
        validateOnBlur
        validationSchema={isLocked ? undefined : validationSchema}
      >
        {({ values, setSubmitting, isValid, submitForm }) => (
          <Form>
            <EditAssetForm isMissingDetails={isMissingDetails} isMissingDocuments={isMissingDocuments} />

            <Divider light sx={{ mb: 2, mt: 1 }} />

            <EditAssetFooter
              onCancel={onCancel}
              onOk={async () => {
                await submitForm();
                if (tabs?.[currentTab]?.key === BASIC_TAB && !isValid) return;

                if (currentTab < tabsCount - 1) {
                  goToNextTab?.();
                } else {
                  const newDocumentsIds = await uploadDocuments({
                    fileId: appFile?.id as string,
                    files: values?.documents as IFormValuesDocumentItem[],
                  });

                  const mutateAsset = assetId ? updateAsset : createAsset;

                  const body = {
                    ...asset,
                    fileId: appFile?.id as string,
                    ...convertFormValueToAssetDto(values),
                    assetType: assetTypeEnum,
                    assetDescription: assetDescriptionEnum,
                    documentIds: [...(asset?.documentIds || []), ...newDocumentsIds] as string[],
                  };

                  mutateAsset(body, {
                    onSuccess: (data) => {
                      toast.success(t.SUCCESSFULLY_SAVED);
                      onOk?.({ ...body, id: data?.returnId });
                    },
                    onError: () => {
                      toast.error(t.SOMETHING_WENT_WRONG);
                    },
                  });
                }
                setSubmitting(false);
              }}
            />
          </Form>
        )}
      </Formik>
    </EditModal>
  );
};

const EditAssetModal = ({ isLocked, ...props }: IEditAssetModalProps) => (
  <EditAssetModalProvider isLocked={isLocked}>
    <EditAssetComponent {...props} />
  </EditAssetModalProvider>
);

export default EditAssetModal;
