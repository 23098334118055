import { useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';

import { SupportDocsUpload } from '../../../../../../components/DocumentsUpload';
import Loader from '../../../../../../components/Loader';

import { useAssetsTypesQuery, useAssetsDescriptionsQuery } from '../../queries';
import { IAssetFormValues } from './types';
import { EditAssetModalContext } from './EditAssetModalProvider';

import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

const DocumentsTab = () => {
  const { values } = useFormikContext<IAssetFormValues>();
  const { isLocked } = useContext(EditAssetModalContext);

  const { data: assetsTypes, isLoading: isLoadingAssetsTypes } = useAssetsTypesQuery() || {};
  const { data: assetDescriptions, isLoading: isLoadingDescriptions } =
    useAssetsDescriptionsQuery(values?.assetTypeId) || {};

  const convertAssetTypeIdToCodeName = useCallback(
    (assetTypeId?: string) => {
      for (const enumMember in ClientPublicService.AssetTypeEnum) {
        const value = Number(ClientPublicService.AssetTypeEnum[enumMember]);
        const isValueProperty = value >= 0;
        if (isValueProperty && value === assetsTypes?.find((item) => item.id === assetTypeId)?.enumValue) {
          return enumMember;
        }
      }
    },
    [assetsTypes]
  );

  const convertAssetDescriptionIdToCodeName = useCallback(
    (assetDescriptionId?: string) => {
      for (const enumMember in ClientPublicService.AssetDescriptionEnum) {
        const value = Number(ClientPublicService.AssetDescriptionEnum[enumMember]);
        const isValueProperty = value >= 0;
        if (isValueProperty && value === assetDescriptions?.find((item) => item.id === assetDescriptionId)?.enumValue) {
          return enumMember;
        }
      }
    },
    [assetDescriptions]
  );

  const documentPrefixCodes =
    values?.assetTypeId && values?.assetDescriptionId
      ? [
          `${convertAssetTypeIdToCodeName(values?.assetTypeId)}.${convertAssetDescriptionIdToCodeName(
            values?.assetDescriptionId
          )}`,
        ]
      : undefined;

  return (
    <>
      <Loader loading={isLoadingAssetsTypes || isLoadingDescriptions} />

      <SupportDocsUpload
        documentFolderCode="assets"
        documentPrefixCodes={documentPrefixCodes}
        documentIds={values?.documentIds}
        onlyRetrieveByDocumentIds
        disabled={isLocked}
        assetId={values?.id}
      />
    </>
  );
};

export default DocumentsTab;
