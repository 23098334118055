import { useCallback, useState } from 'react';
import { Stack, Divider, IconButton, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { toast } from 'react-toastify';

import EditModal, { IEditModalProps } from '../../../../components/EditModal';
import PaymentDataItem from '../PaymentDataItem';
import RequestHoldForm from './RequestHoldForm';
import FooterButton from './FooterButton';
import DataItem from '../../../../components/DataItem';
import ElevatedContainer from '../../../../components/ElevatedContainer';

import useLocale from '../../../../app/hooks/useLocale';

import { serverDateToInputFormat, DATE_FORMAT } from '../../../../app/utils/dateTimeHelpers';
import { currencyFormatter, downloadFile } from '../../../../app/utils/helpers';
import {
  usePaymentDetailsQuery,
  useUpdatePaymentDetailsMutation,
  usePaymentReceiptsQuery,
  useReceiptDownloadBlobMutation,
} from '../../queries';
import { isUpcoming } from '../../utils';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { IPaymentUpdateParams } from '../../types';

interface IEditPaymentDetailsModal extends Pick<IEditModalProps, 'onCancel'> {
  paymentId: string;
  fileId: string;
  onOk?: () => void;
}

const PaymentDetailsModal = ({ paymentId, fileId, onCancel, onOk }: IEditPaymentDetailsModal) => {
  const { t, getLocalizedDate, locale } = useLocale();

  const [isRequestHoldInitiated, setIsRequestHoldInitiated] = useState(false);

  const { data: paymentDetails } = usePaymentDetailsQuery(paymentId, fileId) || {};
  const { data: receipts } = usePaymentReceiptsQuery(paymentId) || {};
  const { mutate: updatePaymentDetails } = useUpdatePaymentDetailsMutation() || {};
  const { mutateAsync: downloadReceipt } = useReceiptDownloadBlobMutation() || {};

  const handleDownload = useCallback(
    async (id?: string, name?: string) => {
      const blob = await downloadReceipt({ receiptId: id as string, locale });
      downloadFile(blob, name as string);
    },
    [downloadReceipt, locale]
  );

  const handleCancelHold = useCallback(() => {
    const body = {
      id: paymentDetails?.id as string,
      state: ClientPublicService.PADScheduleStateEnum.Canceled,
      holdUntil: undefined,
      holdReason: undefined,
    } as ClientPublicService.IPADScheduleUpdatePublicDto;

    updatePaymentDetails({ fileId, ...body } as IPaymentUpdateParams, {
      onSuccess: (data) => {
        if (data?.result === ClientPublicService.Result.Successful) {
          toast.success(t.HOLD_CANCELLED);
          onOk?.();
        } else {
          toast.error(data?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
        }
      },
      onError: () => {
        toast.error(t.SOMETHING_WENT_WRONG);
      },
    });
  }, [paymentDetails?.id, updatePaymentDetails, fileId, t.HOLD_CANCELLED, t.SOMETHING_WENT_WRONG, onOk]);

  return (
    <EditModal title={t.PAYMENT_DETAILS} onCancel={onCancel}>
      <>
        <PaymentDataItem data={paymentDetails} extended />
        <Stack mt={2}>
          {isRequestHoldInitiated && <RequestHoldForm data={paymentDetails} onOk={onOk} fileId={fileId} />}

          {isUpcoming(paymentDetails?.dueDate) &&
            paymentDetails?.state === ClientPublicService.PADScheduleStateEnum.Active &&
            !isRequestHoldInitiated && (
              <FooterButton variant="outlined" fullWidth onClick={() => setIsRequestHoldInitiated(true)}>
                {t.REQUEST_HOLD}
              </FooterButton>
            )}

          {(paymentDetails?.state === ClientPublicService.PADScheduleStateEnum.OnHold ||
            paymentDetails?.state === ClientPublicService.PADScheduleStateEnum.HoldRequested) && (
            <FooterButton variant="contained" fullWidth onClick={handleCancelHold}>
              {t.CANCEL_HOLD}
            </FooterButton>
          )}

          {paymentDetails?.hasReceipts && receipts && receipts.length > 0 && (
            <>
              <Divider sx={{ mb: 2, mt: 3 }} />
              <Typography variant="body3" fontWeight={600}>
                {t.RECEIPTS}
              </Typography>
              <Stack spacing={2} pb={2} pt={1}>
                {receipts?.map((receipt, index) => (
                  <ElevatedContainer
                    key={`receipt-${index}`}
                    sx={{ p: 0, pl: 2, pr: 2, borderRadius: 3 }}
                    custom={{ bordered: true }}
                  >
                    <DataItem
                      title={receipt?.receiptNumber}
                      content={`${
                        receipt?.paymentDate
                          ? getLocalizedDate(serverDateToInputFormat(receipt?.paymentDate), DATE_FORMAT)
                          : ''
                      } - ${currencyFormatter(receipt?.paymentAmount)}`}
                      actionComponent={
                        <IconButton
                          onClick={() => handleDownload(receipt?.id, `${receipt?.receiptNumber}.pdf`)}
                          edge="end"
                          aria-label="Download"
                        >
                          <Download fontSize="large" />
                        </IconButton>
                      }
                    />
                  </ElevatedContainer>
                ))}
              </Stack>
            </>
          )}
        </Stack>
      </>
    </EditModal>
  );
};

export default PaymentDetailsModal;
