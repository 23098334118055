import { forwardRef } from 'react';
import { TextField as FormikTextField } from 'formik-mui';

import { NumericFormat, NumericFormatProps } from 'react-number-format';

import RecommendableField, { IRecommendableFieldProp } from './RecommendableField';
import CustomLabel from '../CustomLabel';

interface CustomProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

const NumberFieldFormat = forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) =>
        onChange?.({
          target: {
            name: other.name,
            value: values.floatValue,
          },
        })
      }
    />
  );
});

const NumberField = ({ label, required, recommended, ...props }: IRecommendableFieldProp) => (
  <RecommendableField
    {...props}
    isFastField={false}
    component={FormikTextField}
    label={label ? <CustomLabel label={label} required={required} recommended={recommended} /> : undefined}
    InputProps={{
      inputComponent: NumberFieldFormat as any,
      inputProps: { thousandSeparator: true, ...props?.inputProps },
      ...props?.InputProps,
    }}
    sx={{ width: '100%', m: 1, marginLeft: 0, marginRight: 0, ...props?.sx }}
  />
);

export default NumberField;
