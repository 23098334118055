import { Box } from '@mui/material';
import { useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';

import { TextField } from '../../../../../../../components/FormFields';

import useLocale from '../../../../../../../app/hooks/useLocale';
import { IAssetFormValues } from '../types';
import { EditAssetModalContext } from '../EditAssetModalProvider';

const RecreationalEquipmentDetails = () => {
  const { t } = useLocale();

  const { assetId, isLocked } = useContext(EditAssetModalContext);
  const { submitForm } = useFormikContext<IAssetFormValues>();

  useEffect(() => {
    if (assetId) submitForm();
  }, [assetId, submitForm]);

  return (
    <Box data-testid="recreational-equipment">
      <TextField
        name="basisOfValue"
        label={t.BASIS_OF_VALUE}
        data-testid="basisOfValue"
        inputProps={{ maxLength: 200 }}
        disabled={isLocked}
      />
    </Box>
  );
};

export default RecreationalEquipmentDetails;
