import { useMemo } from 'react';
import { toast } from 'react-toastify';

import EditModal, { IEditModalProps } from '../../../../../../components/EditModal';
import EditExpenseComponent from '../../../../../../components/EditExpenseComponent/EditExpenseComponent';

import useLocale from '../../../../../../app/hooks/useLocale';

import {
  useListExpenseTypesQuery,
  useApplicationFileQuery,
  useAllListExpenseDescriptionsQuery,
  useAppFormExpensesQuery,
  useUpdateExpenseMutation,
} from '../../queries';
import {
  IExtendedExpenseDescriptionType,
  IExpenseSubmitValues,
} from '../../../../../../components/EditExpenseComponent/types';
import { convertExpenseFormItemToDto, getExpenseFormItem } from './utils';

type IEditExpenseModalProps = Pick<IEditModalProps, 'onCancel'> & {
  onOk?: () => void;
};

const EditExpenseModal = ({ onOk, onCancel }: IEditExpenseModalProps) => {
  const { t } = useLocale();

  const { data: appFile } = useApplicationFileQuery() || {};
  const { data: expenses } = useAppFormExpensesQuery(appFile?.id) || {};
  const { data: expenseTypes } = useListExpenseTypesQuery() || {};

  const responses = useAllListExpenseDescriptionsQuery(expenseTypes);
  const expenseDescriptions = useMemo(() => responses?.map((item) => item?.data)?.flat(), [responses]);

  const isLocked = useMemo(
    () => appFile?.isLocked || appFile?.isAppFormLocked,
    [appFile?.isLocked, appFile?.isAppFormLocked]
  );

  const { mutate: updateExpense } = useUpdateExpenseMutation() || {};

  const initialValues = useMemo(
    () => ({
      isMarried: expenses?.isMarried,
      expenseDetails: expenseDescriptions?.map((description?: IExtendedExpenseDescriptionType) => {
        const appFormExpenseRecord = expenses?.expenseDetails?.find(
          (expense) => expense?.expenseDescriptionId === description?.id
        );
        return {
          ...getExpenseFormItem(
            appFormExpenseRecord || {
              expenseDescriptionId: description?.id,
              expenseTypeId: description?.expenseTypeId,
            }
          ),
          expenseDescription: description,
        };
      }),
      documents: undefined,
    }),
    [expenseDescriptions, expenses?.expenseDetails, expenses?.isMarried]
  );

  return (
    <EditModal title={t.MONTHLY_EXPENSE} onCancel={onCancel}>
      <EditExpenseComponent
        initialValues={initialValues}
        isEditable={!isLocked}
        documentsUploadProps={{
          documentIds: expenses?.documentIds,
          onlyRetrieveByDocumentIds: true,
        }}
        onCancel={onCancel}
        onSubmit={(updatedValues?: IExpenseSubmitValues) => {
          updateExpense(
            {
              ...expenses,
              documentIds: updatedValues?.documentIds as string[],
              expenseDetails: updatedValues?.expenseDetails
                ?.map((item) => convertExpenseFormItemToDto(item))
                ?.filter(
                  (converted) =>
                    converted?.applicantAmount !== undefined ||
                    converted?.spouseAmount !== undefined ||
                    converted?.otherHouseholdMemberAmount !== undefined
                ),
            },
            {
              onSuccess: () => {
                toast.success(t.SUCCESSFULLY_SAVED);
                onOk?.();
              },
              onError: () => {
                toast.error(t.SOMETHING_WENT_WRONG);
              },
            }
          );
        }}
      />
    </EditModal>
  );
};

export default EditExpenseModal;
