import { useCallback, useEffect, useState, useRef } from 'react';
import { Grid, Container, useMediaQuery, useTheme, Box, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from './Header';
import LocationSearchForm from './LocationSearchForm';
import Wrapper from './Wrapper';
import OfficesMap from './OfficesMap';
import LocationsList from './LocationsList';

import useLocale from '../../../app/hooks/useLocale';
import useBookAppointment from '../hooks/useBookAppointment';

import { IAppointmentDetailsUpdate, LocationsPagedResult, IUserLocation, IOfficeLocationGoogleMap } from '../types';
import { sortOfficesByProximityToTargetLatLng, updateOfficesWithLatLng } from '../utils';
import { useLocationsMutation } from '../queries';
import { ROUTES } from '../../../app/routes/constants';

const SelectOffice = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const listRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  const { userLocation, setUserLocation, appointmentDetails, resetAppointmentDetails } = useBookAppointment();

  const [locations, setLocations] = useState<IOfficeLocationGoogleMap[]>();
  const [height, setHeight] = useState<number>(0);

  const { mutate, isLoading } = useLocationsMutation();

  const onSearchSuccess = useCallback(
    (response?: LocationsPagedResult, userLocation?: IUserLocation) => {
      if (response?.items && response?.items?.length) {
        const sliced = response?.items?.slice(0, 5);

        const sorted = sortOfficesByProximityToTargetLatLng(
          new google.maps.LatLng(userLocation?.coordinates?.lat as number, userLocation?.coordinates?.lng as number),
          updateOfficesWithLatLng(sliced)
        );

        setLocations(sorted);

        if (listRef?.current?.offsetHeight) {
          setHeight(listRef?.current?.offsetHeight);
        }
      }

      if (!response?.items || !response?.items?.length) {
        toast.info(t.NO_OFFICES_FOUND);
        setLocations(undefined);
      }
    },
    [t.NO_OFFICES_FOUND]
  );

  const handleSubmit = useCallback(
    (userLocation?: IUserLocation) => {
      setUserLocation?.(userLocation);
      mutate(userLocation?.coordinates, {
        onSuccess: (data) => onSearchSuccess(data, userLocation),
      });
    },
    [mutate, onSearchSuccess, setUserLocation]
  );

  useEffect(() => {
    if (userLocation && userLocation?.coordinates && !locations) {
      handleSubmit(userLocation);
    }
  }, [handleSubmit, locations, userLocation]);

  return (
    <Wrapper loading={isLoading} data-testid="select-office">
      <Container maxWidth="desktop">
        <Grid container display="flex" flexGrow={1} alignItems="center" justifyContent="center">
          <Grid item mobile={12} tablet={6} desktop={6}>
            <Box sx={{ pb: 0 }}>
              <Stack sx={{ pl: { mobile: 0, tablet: 4 }, pr: { mobile: 0, tablet: 4 } }}>
                <Typography variant="body2" textAlign="center" sx={{ pb: 2.5 }}>
                  {t.BOOK_YOUR_APPOINTMENT_NOW}
                </Typography>
              </Stack>
              <Header
                title={t.SELECT_AN_OFFICE_LOCATION}
                data-testid="select-office-page-title"
                containerProps={{ sx: { pb: 2 } }}
                titleProps={{ sx: { pb: 0 } }}
              />
            </Box>
            <LocationSearchForm userLocation={userLocation} onSubmit={handleSubmit} key="select-office" />
          </Grid>
        </Grid>

        <Grid
          container
          display="flex"
          flexGrow={1}
          spacing={4}
          sx={{ flexDirection: { mobile: 'row', tablet: 'row-reverse' }, paddingTop: { mobile: 2, tablet: 3 } }}
        >
          <Grid
            item
            mobile={12}
            desktop={6}
            sx={{
              overflow: { tablet: 'hidden' },
              paddingTop: 3,
            }}
          >
            {locations && (
              <OfficesMap
                officesNearby={locations}
                userCoordinates={userLocation?.coordinates}
                center={userLocation?.coordinates}
                height={isDesktop ? height : 400}
              />
            )}
          </Grid>
          <Grid item mobile={12} desktop={6}>
            <LocationsList
              appointmentDetails={appointmentDetails}
              locations={locations}
              onItemClick={(appointmentDetails) => {
                resetAppointmentDetails?.(appointmentDetails as IAppointmentDetailsUpdate);

                if (appointmentId) {
                  navigate(`${ROUTES.RESCHEDULE_APPOINTMENT}/${appointmentId}/${ROUTES.SELECT_TIME}`);
                } else {
                  navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_TIME}`);
                }
              }}
              ref={listRef}
            />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default SelectOffice;
