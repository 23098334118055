import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { inputDateToServerFormat, serverDateToInputFormat } from '../../../../app/utils/dateTimeHelpers';

import type {
  IFormValuesEmploymentDetail,
  IFormValuesPreviousAddress,
  IFormValuesInsurancePeriod,
  IFormValuesDependant,
  IFormValuesInsolvency,
  IFormValuesPersonalInfo,
} from './types';

const getEmploymentDetailsFormItem = (item?: ClientPublicService.AppFormPersonalInfoEmploymentDetailDto) =>
  ({
    id: item?.id || '',
    employerName: item?.employerName || '',
    employerAddress: item?.employerAddress || '',
    employerStreetNumber: item?.employerStreetNumber || '',
    employerStreetName: item?.employerStreetName || '',
    employerUnit: item?.employerUnit || '',
    employerCity: item?.employerCity || '',
    employerPostalCode: item?.employerPostalCode || '',
    employerProvinceId: item?.employerProvinceId || '',
    employerCountryId: item?.employerCountryId || '',
    occupationName: item?.occupationName || '',
    industryId: item?.industryId || '',
    employmentStatusId: item?.employmentStatusId || '',
    startDate: serverDateToInputFormat(item?.startDate) || null,
    endDate: serverDateToInputFormat(item?.endDate) || null,
    isCurrentEmployer: item?.isCurrentEmployer || false,
  } as IFormValuesEmploymentDetail);

const convertEmploymentDetailFormItemToDto = (item?: IFormValuesEmploymentDetail) =>
  ({
    id: item?.id || undefined,
    employerName: item?.employerName || undefined,
    employerAddress: item?.employerAddress || undefined,
    employerStreetNumber: item?.employerStreetNumber || undefined,
    employerStreetName: item?.employerStreetName || undefined,
    employerUnit: item?.employerUnit || undefined,
    employerCity: item?.employerCity || undefined,
    employerPostalCode: item?.employerPostalCode || undefined,
    employerProvinceId: item?.employerProvinceId || undefined,
    employerCountryId: item?.employerCountryId || undefined,
    occupationName: item?.occupationName || undefined,
    industryId: item?.industryId || undefined,
    employmentStatusId: item?.employmentStatusId || undefined,
    startDate: inputDateToServerFormat(item?.startDate) || undefined,
    endDate: inputDateToServerFormat(item?.endDate) || undefined,
    isCurrentEmployer: item?.isCurrentEmployer || false,
  } as ClientPublicService.AppFormPersonalInfoEmploymentDetailDto);

const getPreviousAddressFormItem = (item?: ClientPublicService.AppFormPersonalInfoPreviousAddressDto) =>
  ({
    id: item?.id || '',
    address: item?.address || '',
    streetNumber: item?.streetNumber || '',
    streetName: item?.streetName || '',
    unit: item?.unit || '',
    city: item?.city || '',
    postalCode: item?.postalCode || '',
    provinceId: item?.provinceId || '',
    countryId: item?.countryId || '',
  } as IFormValuesPreviousAddress);

const convertPreviousAddressFormItemToDto = (item?: IFormValuesPreviousAddress) =>
  ({
    id: item?.id || undefined,
    address: item?.address || undefined,
    streetNumber: item?.streetNumber || undefined,
    streetName: item?.streetName || undefined,
    unit: item?.unit || undefined,
    city: item?.city || undefined,
    postalCode: item?.postalCode || undefined,
    provinceId: item?.provinceId || undefined,
    countryId: item?.countryId || undefined,
  } as ClientPublicService.AppFormPersonalInfoPreviousAddressDto);

const getInsurancePeriodFormItem = (item?: ClientPublicService.AppFormPersonalInfoEmploymentInsurancePeriodDto) =>
  ({
    id: item?.id || '',
    startDate: serverDateToInputFormat(item?.startDate) || null,
    endDate: serverDateToInputFormat(item?.endDate) || null,
    isCurrentPeriod: item?.isCurrentPeriod || false,
  } as IFormValuesInsurancePeriod);

const convertInsurancePeriodFormItemToDto = (item?: IFormValuesInsurancePeriod) =>
  ({
    id: item?.id || undefined,
    startDate: inputDateToServerFormat(item?.startDate) || undefined,
    endDate: inputDateToServerFormat(item?.endDate) || undefined,
    isCurrentPeriod: item?.isCurrentPeriod,
  } as ClientPublicService.AppFormPersonalInfoEmploymentInsurancePeriodDto);

const getDependantFormItem = (item?: ClientPublicService.AppFormPersonalInfoDependantDto) =>
  ({
    id: item?.id || '',
    firstName: item?.firstName || '',
    lastName: item?.lastName || '',
    relationshipTypeId: item?.relationshipTypeId || '',
    birthDate: serverDateToInputFormat(item?.birthDate) || null,
  } as IFormValuesDependant);

const convertDependantFormItemToDto = (item?: IFormValuesDependant) =>
  ({
    id: item?.id || undefined,
    firstName: item?.firstName || undefined,
    lastName: item?.lastName || undefined,
    relationshipTypeId: item?.relationshipTypeId || undefined,
    birthDate: inputDateToServerFormat(item?.birthDate) || undefined,
  } as ClientPublicService.AppFormPersonalInfoDependantDto);

const getInsolvencyFormItem = (item?: ClientPublicService.AppFormPersonalInfoPreviousInsolvencyDto) =>
  ({
    id: item?.id || '',
    insolvencyTypeId: item?.insolvencyTypeId || '',
    applicantName: item?.applicantName || '',
    trusteeName: item?.trusteeName || '',
    estateNumber: item?.estateNumber || '',
    bankruptcyDate: serverDateToInputFormat(item?.bankruptcyDate) || null,
    dischargeDate: serverDateToInputFormat(item?.dischargeDate) || null,
    proposalDate: serverDateToInputFormat(item?.proposalDate) || null,
    proposalResultId: item?.proposalResultId || '',
    filingLocation: item?.filingLocation || '',
  } as IFormValuesInsolvency);

const convertInsolvencyFormItemToDto = (item?: IFormValuesInsolvency) =>
  ({
    id: item?.id || undefined,
    insolvencyTypeId: item?.insolvencyTypeId || undefined,
    applicantName: item?.applicantName || undefined,
    trusteeName: item?.trusteeName || undefined,
    estateNumber: item?.estateNumber || undefined,
    bankruptcyDate: inputDateToServerFormat(item?.bankruptcyDate) || undefined,
    dischargeDate: inputDateToServerFormat(item?.dischargeDate) || undefined,
    proposalDate: inputDateToServerFormat(item?.proposalDate) || undefined,
    proposalResultId: item?.proposalResultId || undefined,
    filingLocation: item?.filingLocation || undefined,
  } as ClientPublicService.AppFormPersonalInfoPreviousInsolvencyDto);

const getInitialPersonalInfoValues = (
  data?: ClientPublicService.IAppFormPersonalInfoDto,
  documents?: ClientPublicService.DocumentDto[]
) =>
  ({
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    middleName: data?.middleName || '',
    akaName: data?.akaName || '',
    birthDate: serverDateToInputFormat(data?.birthDate) || null,
    sin: data?.sin || '',
    maritalStatusId: data?.maritalStatusId || '',
    marriageDate: serverDateToInputFormat(data?.marriageDate) || null,
    educationLevelId: data?.educationLevelId || '',
    spouseFirstName: data?.spouseFirstName || '',
    spouseMiddleName: data?.spouseMiddleName || '',
    spouseLastName: data?.spouseLastName || '',
    spouseAkaName: data?.spouseAkaName || '',
    spouseBirthDate: serverDateToInputFormat(data?.spouseBirthDate) || null,
    spouseSIN: data?.spouseSIN || '',
    spouseEducationLevelId: data?.spouseEducationLevelId || '',
    isJointFile: data?.isJointFile || false,
    additionalNotes: data?.additionalNotes || '',
    address: data?.address || '',
    streetNumber: data?.streetNumber || '',
    streetName: data?.streetName || '',
    unit: data?.unit || '',
    city: data?.city || '',
    provinceId: data?.provinceId || '',
    postalCode: data?.postalCode || '',
    countryId: data?.countryId || '',
    residenceDate: serverDateToInputFormat(data?.residenceDate) || null,
    previousAddresses: data?.previousAddresses?.map((item) => getPreviousAddressFormItem(item)) || [],
    mainPhoneTypeId: data?.mainPhoneTypeId || '',
    mainPhoneNumber: data?.mainPhoneNumber || '',
    emailAddress: data?.emailAddress || '',
    spouseAddress: data?.spouseAddress || '',
    spouseUnit: data?.spouseUnit || '',
    spouseCity: data?.spouseCity || '',
    spouseStreetNumber: data?.spouseStreetNumber || '',
    spouseStreetName: data?.spouseStreetName || '',
    spouseProvinceId: data?.spouseProvinceId || '',
    spousePostalCode: data?.spousePostalCode || '',
    spouseCountryId: data?.spouseCountryId || '',
    spouseResidenceDate: serverDateToInputFormat(data?.spouseResidenceDate),
    spouseMainPhoneTypeId: data?.spouseMainPhoneTypeId || '',
    spouseMainPhoneNumber: data?.spouseMainPhoneNumber || '',
    spouseEmailAddress: data?.spouseEmailAddress || '',
    isSpouseAddressSame: data?.isSpouseAddressSame || false,
    isEmployedPastTwoYears: data?.isEmployedPastTwoYears || false,
    employmentDetails: data?.employmentDetails?.map((item) => getEmploymentDetailsFormItem(item)) || [],
    isReceivedEmploymentInsurancePastTwoYears: data?.isReceivedEmploymentInsurancePastTwoYears || false,
    employmentInsurancePeriods: data?.employmentInsurancePeriods?.map((item) => getInsurancePeriodFormItem(item)) || [],
    isSpouseEmployedPastTwoYears: data?.isSpouseEmployedPastTwoYears || false,
    spouseEmploymentDetails: data?.spouseEmploymentDetails?.map((item) => getEmploymentDetailsFormItem(item)) || [],
    isSpouseReceivedEmploymentInsurancePastTwoYears: data?.isSpouseReceivedEmploymentInsurancePastTwoYears || false,
    spouseEmploymentInsurancePeriods:
      data?.spouseEmploymentInsurancePeriods?.map((item) => getInsurancePeriodFormItem(item)) || [],
    hasFDReasonLossOfIncome: data?.hasFDReasonLossOfIncome || false,
    hasFDReasonMedicalReasons: data?.hasFDReasonMedicalReasons || false,
    hasFDReasonBusinessFailure: data?.hasFDReasonBusinessFailure || false,
    hasFDReasonGambling: data?.hasFDReasonGambling || false,
    hasFDReasonDrugAlcoholAddicition: data?.hasFDReasonDrugAlcoholAddicition || false,
    hasFDReasonMaritalSeparationOrRelationshipBreakdown:
      data?.hasFDReasonMaritalSeparationOrRelationshipBreakdown || false,
    hasFDReasonTaxLiabilities: data?.hasFDReasonTaxLiabilities || false,
    hasFDReasonFinancialSupportOfOthers: data?.hasFDReasonFinancialSupportOfOthers || false,
    hasFDReasonStudentDebt: data?.hasFDReasonStudentDebt || false,
    hasFDReasonLitigationIssues: data?.hasFDReasonLitigationIssues || false,
    hasFDReasonMovingOrRelocationExpenses: data?.hasFDReasonMovingOrRelocationExpenses || false,
    hasFDReasonFailedProposal: data?.hasFDReasonFailedProposal || false,
    hasFDReasonFinancialMismanagement: data?.hasFDReasonFinancialMismanagement || false,
    hasFDReasonOther: data?.hasFDReasonOther || false,
    specifiedFDReason: data?.specifiedFDReason || '',
    specifyFDReasonLegalMatters: data?.specifyFDReasonLegalMatters || '',
    reasonForNeedingFinancialHelp: data?.reasonForNeedingFinancialHelp || '',

    spouseHasFDReasonLossOfIncome: data?.spouseHasFDReasonLossOfIncome || false,
    spouseHasFDReasonMedicalReasons: data?.spouseHasFDReasonMedicalReasons || false,
    spouseHasFDReasonBusinessFailure: data?.spouseHasFDReasonBusinessFailure || false,
    spouseHasFDReasonGambling: data?.spouseHasFDReasonGambling || false,
    spouseHasFDReasonDrugAlcoholAddicition: data?.spouseHasFDReasonDrugAlcoholAddicition || false,
    spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown:
      data?.spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown || false,
    spouseHasFDReasonTaxLiabilities: data?.spouseHasFDReasonTaxLiabilities || false,
    spouseHasFDReasonFinancialSupportOfOthers: data?.spouseHasFDReasonFinancialSupportOfOthers || false,
    spouseHasFDReasonStudentDebt: data?.spouseHasFDReasonStudentDebt || false,
    spouseHasFDReasonLitigationIssues: data?.spouseHasFDReasonLitigationIssues || false,
    spouseHasFDReasonMovingOrRelocationExpenses: data?.spouseHasFDReasonMovingOrRelocationExpenses || false,
    spouseHasFDReasonFailedProposal: data?.spouseHasFDReasonFailedProposal || false,
    spouseHasFDReasonFinancialMismanagement: data?.spouseHasFDReasonFinancialMismanagement || false,
    spouseHasFDReasonOther: data?.spouseHasFDReasonOther || false,
    spouseSpecifiedFDReason: data?.spouseSpecifiedFDReason || '',
    spouseSpecifyFDReasonLegalMatters: data?.spouseSpecifyFDReasonLegalMatters || '',
    spouseReasonForNeedingFinancialHelp: data?.spouseReasonForNeedingFinancialHelp || '',

    numberOfPersonsInHousehold: data?.numberOfPersonsInHousehold || 1,
    hasDependants: data?.hasDependants || false,
    dependants: data?.dependants?.map((item) => getDependantFormItem(item)) || [],
    hasPreviousInsolvency: data?.hasPreviousInsolvency || false,
    previousInsolvencies: data?.previousInsolvencies?.map((item) => getInsolvencyFormItem(item)) || [],
    hasSpousePreviousInsolvency: data?.hasSpousePreviousInsolvency || false,
    spousePreviousInsolvencies: data?.spousePreviousInsolvencies?.map((item) => getInsolvencyFormItem(item)) || [],
    documentIds: data?.documentIds || [],
    documents,
  } as IFormValuesPersonalInfo);

const convertFormValuesToAppFormPersonalInfoDto = (formValues?: IFormValuesPersonalInfo) => ({
  ...formValues,
  firstName: formValues?.firstName || undefined,
  lastName: formValues?.lastName || undefined,
  middleName: formValues?.middleName || undefined,
  akaName: formValues?.akaName || undefined,
  birthDate: inputDateToServerFormat(formValues?.birthDate) || undefined,
  sin: formValues?.sin || undefined,
  maritalStatusId: formValues?.maritalStatusId || undefined,
  marriageDate: inputDateToServerFormat(formValues?.marriageDate) || undefined,
  educationLevelId: formValues?.educationLevelId || undefined,
  spouseFirstName: formValues?.spouseFirstName || undefined,
  spouseMiddleName: formValues?.spouseMiddleName || undefined,
  spouseLastName: formValues?.spouseLastName || undefined,
  spouseAkaName: formValues?.spouseAkaName || undefined,
  spouseBirthDate: inputDateToServerFormat(formValues?.spouseBirthDate) || undefined,
  spouseSIN: formValues?.spouseSIN || undefined,
  spouseEducationLevelId: formValues?.spouseEducationLevelId || undefined,
  isJointFile: formValues?.isJointFile || false,
  additionalNotes: formValues?.additionalNotes || undefined,
  address: formValues?.address || undefined,
  streetNumber: formValues?.streetNumber || undefined,
  streetName: formValues?.streetName || undefined,
  unit: formValues?.unit || undefined,
  city: formValues?.city || undefined,
  provinceId: formValues?.provinceId || undefined,
  postalCode: formValues?.postalCode || undefined,
  countryId: formValues?.countryId || undefined,
  residenceDate: inputDateToServerFormat(formValues?.residenceDate) || undefined,
  previousAddresses: formValues?.previousAddresses?.map((item) => convertPreviousAddressFormItemToDto(item)) || [],
  mainPhoneTypeId: formValues?.mainPhoneTypeId || undefined,
  mainPhoneNumber: formValues?.mainPhoneNumber || undefined,
  emailAddress: formValues?.emailAddress || undefined,
  spouseAddress: formValues?.spouseAddress || undefined,
  spouseUnit: formValues?.spouseUnit || undefined,
  spouseCity: formValues?.spouseCity || undefined,
  spouseStreetNumber: formValues?.spouseStreetNumber || undefined,
  spouseStreetName: formValues?.spouseStreetName || undefined,
  spouseProvinceId: formValues?.spouseProvinceId || undefined,
  spousePostalCode: formValues?.spousePostalCode || undefined,
  spouseCountryId: formValues?.spouseCountryId || undefined,
  spouseResidenceDate: inputDateToServerFormat(formValues?.spouseResidenceDate) || undefined,
  spouseMainPhoneTypeId: formValues?.spouseMainPhoneTypeId || undefined,
  spouseMainPhoneNumber: formValues?.spouseMainPhoneNumber || undefined,
  spouseEmailAddress: formValues?.spouseEmailAddress || undefined,
  employmentDetails: formValues?.employmentDetails?.map((item) => convertEmploymentDetailFormItemToDto(item)) || [],
  employmentInsurancePeriods:
    formValues?.employmentInsurancePeriods?.map((item) => convertInsurancePeriodFormItemToDto(item)) || [],
  spouseEmploymentDetails:
    formValues?.spouseEmploymentDetails?.map((item) => convertEmploymentDetailFormItemToDto(item)) || [],
  spouseEmploymentInsurancePeriods:
    formValues?.spouseEmploymentInsurancePeriods?.map((item) => convertInsurancePeriodFormItemToDto(item)) || [],
  specifiedFDReason: formValues?.specifiedFDReason || undefined,
  specifyFDReasonLegalMatters: formValues?.specifyFDReasonLegalMatters || undefined,
  reasonForNeedingFinancialHelp: formValues?.reasonForNeedingFinancialHelp || undefined,

  spouseSpecifiedFDReason: formValues?.spouseSpecifiedFDReason || undefined,
  spouseSpecifyFDReasonLegalMatters: formValues?.spouseSpecifyFDReasonLegalMatters || undefined,
  spouseReasonForNeedingFinancialHelp: formValues?.spouseReasonForNeedingFinancialHelp || undefined,

  dependants: formValues?.dependants?.map((item) => convertDependantFormItemToDto(item)) || [],
  previousInsolvencies: formValues?.previousInsolvencies?.map((item) => convertInsolvencyFormItemToDto(item)) || [],
  spousePreviousInsolvencies:
    formValues?.spousePreviousInsolvencies?.map((item) => convertInsolvencyFormItemToDto(item)) || [],
  documentIds: formValues?.documentIds || [],
});

export {
  getInitialPersonalInfoValues,
  convertFormValuesToAppFormPersonalInfoDto,
  getPreviousAddressFormItem,
  getEmploymentDetailsFormItem,
  convertEmploymentDetailFormItemToDto,
  getInsurancePeriodFormItem,
  getDependantFormItem,
  getInsolvencyFormItem,
};
