import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { ToggleWithInputFields } from '../../../../../components/FormFieldsPresets';
import { YesNoToggleField } from '../../../../../components/FormFields';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormQuestionnaireValues } from '../types';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const TransactionsApplicant = () => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  return (
    <>
      <ToggleWithInputFields
        toggleFieldName="hasMadeExessPaymentsPast12Months"
        toggleFieldLabel={t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS}
        toggleFieldProps={{ required: true }}
        inputFieldName="hasMadeExessPaymentsPast12MonthsDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasAssetsSiezedOrGarnishedPast12Months"
        toggleFieldLabel={t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS}
        toggleFieldProps={{ required: true }}
        inputFieldName="hasAssetsSiezedOrGarnishedPast12MonthsDetail"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years"
        toggleFieldLabel={t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS}
        toggleFieldProps={{ required: true }}
        inputFieldName="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails"
        disabled={isLocked}
      />

      {values?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years && (
        <YesNoToggleField
          name="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime"
          label={t.INSOLVENT_AT_THE_TIME}
          disabled={isLocked}
        />
      )}

      <ToggleWithInputFields
        toggleFieldName="hasGivenGiftsOver500WhileInsolventPast5Years"
        toggleFieldLabel={
          t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
        }
        toggleFieldProps={{ required: true }}
        inputFieldName="hasGivenGiftsOver500WhileInsolventPast5YearsDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isExpectedToReceiveNonIncomeFundsNext12Months"
        toggleFieldLabel={
          t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
        }
        toggleFieldProps={{ required: true }}
        inputFieldName="isExpectedToReceiveNonIncomeFundsNext12MonthsDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty"
        toggleFieldLabel={
          t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
        }
        toggleFieldProps={{ required: true }}
        inputFieldName="hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasMadeArragementsToContinuePayingCreditors"
        toggleFieldLabel={t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING}
        toggleFieldProps={{ required: true }}
        inputFieldName="hasMadeArragementsToContinuePayingCreditorsDetails"
        disabled={isLocked}
      />
    </>
  );
};

export default TransactionsApplicant;
