import NumberField from './NumberField';

const CurrencyField = (props: any) => (
  <NumberField
    {...props}
    inputProps={{
      allowNegative: false,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '$',
      maxLength: 15,
      placeholder: '$0.00',
      ...props?.inputProps,
    }}
  />
);

export default CurrencyField;
