import { useMemo, useContext, useRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import yup from '../../../../app/utils/customYup';
import { toast } from 'react-toastify';

import Business from './panels/Business';
import CoSignsGuaranteedLoans from './panels/CoSignsGuaranteedLoans';
import CreditBureauConsent from './panels/CreditBureauConsent';
import DebtAreaApplicant from './panels/DebtAreasApplicant';
import DebtAreaSpouse from './panels/DebtAreasSpouse';
import TransactionsApplicant from './panels/TransactionsApplicant';
import TransactionsSpouse from './panels/TransactionsSpouse';
import UnsavedForm from '../../components/UnsavedForm/UnsavedForm';

import AppFormAccordionPanel from '../../components/AppFormAccordion/AppFormAccordion';
import AccordionProvider, { AccordionContext } from '../../components/AppFormAccordion/AccordionProvider';
import ErrorsList from '../../components/AppFormAccordion/ErrorsList';
import ConfirmationModal from '../../../../components/ConfirmationModal';

import useLocale from '../../../../app/hooks/useLocale';
import useAppForm from '../../hooks/useAppForm';
import useModal from '../../../../app/hooks/useModal';

import { useQuestionnaireQuery, useBusinessOwnershipTypesQuery, useUpdateQuestionnaireMutation } from './queries';
import { useDocumentsUploadMutation, IFormValuesDocumentItem } from '../../../../components/DocumentsUpload';
import {
  useAppFormSupportingDocumentsQuery,
  useFoldersQuery,
  usePrefixesQuery,
} from '../../../../components/DocumentsUpload/queries';
import { getAppFormQuestionnaire, convertQuestionnaireFormValuesToDto } from './utils';
import { ROUTES } from '../../../../app/routes';
import { IFormQuestionnaireValues } from './types';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import SectionLockMessage from '../../components/SectionLockMessage';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';
import NavigationButtons from '../../components/NavigationButtons';
import FinancialAdviceProvider from './panels/FinancialAdviceProvider';
import { useAppFormPersonalInfoQuery } from '../PersonalInformation/queries';

export const DEBT_AREA_APPLICANT_PANEL = 'debt-area-applicant-panel';
export const DEBT_AREA_SPOUSE_PANEL = 'debt-area-spouse-panel';
export const TRANSACTIONS_APPLICANT_PANEL = 'transactions-applicant-panel';
export const TRANSACTIONS_SPOUSE_PANEL = 'transactions-spouse-panel';
export const BUSINESS_PANEL = 'business-panel';
export const CO_SIGNS_GUARANTEED_LOANS_PANEL = 'co-signs-guaranteed-loans-panel';
export const CREDIT_BUREAU_CONSENT_PANEL = 'credit-bureau-consent-panel';
export const FINANCIAL_ADVICE_PANEL = 'financial-advice-panel';

const QUESTIONNAIRE_DOCUMENTS_FOLDER_CODE = 'surplus-ie';

const QUESTIONNAIRE_DOCUMENTS_PREFIX_CODES = ['Business'];

const QuestionnaireForm = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const { appFormStatus, updateAppFormStatus, appFileData } = useAppForm() || {};
  const { setShowErrorsList } = useContext(AccordionContext);
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();

  const debtAreaApplicantRef = useRef<HTMLDivElement>(null);
  const debtAreaSpouseRef = useRef<HTMLDivElement>(null);
  const transactionsApplicantRef = useRef<HTMLDivElement>(null);
  const transactionsSpouseRef = useRef<HTMLDivElement>(null);
  const businessRef = useRef<HTMLDivElement>(null);
  const coSignsGuaranteedLoansRef = useRef<HTMLDivElement>(null);
  const creditBureauConsentRef = useRef<HTMLDivElement>(null);
  const refFinancialAdvices = useRef<HTMLDivElement>(null);

  const { data: questionnaire, isSuccess: isQuestionnaireSuccess } = useQuestionnaireQuery(appFileData?.id) || {};
  const { data: personalInfo } = useAppFormPersonalInfoQuery(appFileData?.id) || {};

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const { mutate: updateQuestionnaire } = useUpdateQuestionnaireMutation() || {};

  const { data: businessOwnershipTypes } = useBusinessOwnershipTypesQuery() || {};

  const { data: folders } = useFoldersQuery() || {};

  const documentFolderId = useMemo(
    () => folders?.find((folder) => folder.code === QUESTIONNAIRE_DOCUMENTS_FOLDER_CODE)?.id,
    [folders]
  );

  const { data: prefixes } =
    usePrefixesQuery({ documentFolderId, prefixCodes: QUESTIONNAIRE_DOCUMENTS_PREFIX_CODES }) || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  const { data: documents, isSuccess: isDocumentsSuccess } =
    useAppFormSupportingDocumentsQuery(
      {
        documentFolderId,
        documentPrefixCodes: QUESTIONNAIRE_DOCUMENTS_PREFIX_CODES,
        documentIds: questionnaire?.documentIds,
      },
      true
    ) || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED?.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const debtAreaApplicantValidationSchema = useMemo(
    () => ({
      isDebtFromFineOrPenaltyImposedByCourt: yup
        .boolean()
        .required(getRequiredByLabel(t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT)),
      isDebtFromRecognizanceOfBailBond: yup.boolean().required(getRequiredByLabel(t.RECOGNIZANCE_OF_BAIL_BOND)),
      isDebtFromAlimony: yup.boolean().required(getRequiredByLabel(t.ALIMONY)),
      isDebtFromMaintenanceOfAffiliationOrder: yup
        .boolean()
        .required(getRequiredByLabel(t.MAINTENANCE_OF_AFFILIATION_ORDER)),
      isDebtFromMaintenanceOfSupportOfSeparatedFamily: yup
        .boolean()
        .required(getRequiredByLabel(t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY)),
      isDebtFromFraud: yup.boolean().required(getRequiredByLabel(t.FRAUD)),
      isDebtFromEmbezzlement: yup.boolean().required(getRequiredByLabel(t.EMBEZZLEMENT)),
      isDebtFromMisappropriation: yup.boolean().required(getRequiredByLabel(t.MISAPPROPRIATION)),
      isDebtFromDefalcationWhileActingInAFiduciaryCapacity: yup
        .boolean()
        .required(getRequiredByLabel(t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY)),
      isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud: yup
        .boolean()
        .required(getRequiredByLabel(t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD)),
      isDebtFromStudentLoansOutstanding: yup.boolean().required(getRequiredByLabel(t.STUDENT_LOANS_OUTSTANDING)),
      debtFromStudentLoansOutstandingDateCeasedBeingStudent: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .nullable(),
    }),
    [
      getRequiredByLabel,
      t.ALIMONY,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY,
      t.EMBEZZLEMENT,
      t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT,
      t.FRAUD,
      t.INVALID_DATE,
      t.MAINTENANCE_OF_AFFILIATION_ORDER,
      t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY,
      t.MISAPPROPRIATION,
      t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD,
      t.RECOGNIZANCE_OF_BAIL_BOND,
      t.STUDENT_LOANS_OUTSTANDING,
    ]
  );

  const debtAreaSpouseValidationSchema = useMemo(
    () => ({
      spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .nullable(),
    }),
    [t.DATE_CANNOT_BE_IN_THE_FUTURE, t.INVALID_DATE]
  );

  const transactionsSpouseValidationSchema = useMemo(
    () => ({
      hasSpouseMadeExessPaymentsPast12Months: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(
                t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS
              )
            )
        : yup.boolean().nullable(),
      hasSpouseAssetsSiezedOrGarnishedPast12Months: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS)
            )
        : yup.boolean().nullable(),
      hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(
                t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS
              )
            )
        : yup.boolean().nullable(),
      hasSpouseGivenGiftsOver500WhileInsolventPast5Years: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(
                t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
              )
            )
        : yup.boolean().nullable(),
      isSpouseExpectedToReceiveNonIncomeFundsNext12Months: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(
                t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
              )
            )
        : yup.boolean().nullable(),
      hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(
              getRequiredByLabel(
                t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
              )
            )
        : yup.boolean().nullable(),
      hasSpouseMadeArragementsToContinuePayingCreditors: personalInfo?.isJointFile
        ? yup
            .boolean()
            .required(getRequiredByLabel(t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING))
        : yup.boolean().nullable(),
    }),
    [
      getRequiredByLabel,
      t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE,
      t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY,
      t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS,
      t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS,
      t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING,
      t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS,
      t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS,
      personalInfo?.isJointFile,
    ]
  );

  const transactionsApplicantValidationSchema = useMemo(
    () => ({
      hasMadeExessPaymentsPast12Months: yup
        .boolean()
        .required(
          getRequiredByLabel(t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS)
        ),
      hasAssetsSiezedOrGarnishedPast12Months: yup
        .boolean()
        .required(getRequiredByLabel(t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS)),
      hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years: yup
        .boolean()
        .required(
          getRequiredByLabel(
            t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS
          )
        ),
      hasGivenGiftsOver500WhileInsolventPast5Years: yup
        .boolean()
        .required(
          getRequiredByLabel(
            t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
          )
        ),
      isExpectedToReceiveNonIncomeFundsNext12Months: yup
        .boolean()
        .required(
          getRequiredByLabel(
            t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
          )
        ),
      hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty: yup
        .boolean()
        .required(
          getRequiredByLabel(
            t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
          )
        ),
      hasMadeArragementsToContinuePayingCreditors: yup
        .boolean()
        .required(getRequiredByLabel(t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING)),
    }),
    [
      getRequiredByLabel,
      t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE,
      t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY,
      t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS,
      t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS,
      t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING,
      t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS,
      t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS,
    ]
  );

  const documentsYupObject = useMemo(
    () =>
      yup.array().of(
        yup.object({
          documentPrefixId: yup
            .string()
            .when(['id'], ([id], schema) => (id ? schema : schema.required(getRequiredByLabel(t.DOCUMENT)))),
          businessName: yup
            .string()
            .when(['id'], ([id], schema) => (id ? schema : schema.required(getRequiredByLabel(t.BUSINESS_NAME)))),
        })
      ),

    [getRequiredByLabel, t.BUSINESS_NAME, t.DOCUMENT]
  );

  const documentsValidationSchema = useMemo(
    () => ({
      documents: documentsYupObject.when(['hasOwnedBusinessPast5Years'], ([hasOwnedBusinessPast5Years], schema) =>
        !appFormStatus ||
        appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete ||
        !hasOwnedBusinessPast5Years
          ? schema
          : schema
              .of(
                yup.object({
                  businessName: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD),
                })
              )
              .test('documents-for-all-businesses', t.SUPPORTING_DOCUMENTS_ARE_REQUIRED, function (documents) {
                const fieldValues = this.parent as IFormQuestionnaireValues;
                return (
                  fieldValues.businesses.length + fieldValues.spouseBusinesses.length <= (documents?.length ?? 0) &&
                  fieldValues.businesses.every((applicantBusiness) =>
                    documents?.some((doc) => applicantBusiness.businessName === doc.businessName)
                  ) &&
                  fieldValues.spouseBusinesses.every((spouseBusiness) =>
                    documents?.some((doc) => spouseBusiness.businessName === doc.businessName)
                  )
                );
              })
      ),
    }),
    [appFormStatus, documentsYupObject, t.SUPPORTING_DOCUMENTS_ARE_REQUIRED, t.THIS_IS_A_REQUIRED_FIELD]
  );

  const initialValues = useMemo(
    () =>
      getAppFormQuestionnaire(
        questionnaire,
        documents?.items?.map(
          (item) =>
            ({
              ...item,
              documentPrefixName: getLocalizedDtoName(prefixes?.find((prefix) => prefix.id === item?.documentPrefixId)),
            } as ClientPublicService.DocumentDto)
        )
      ),
    [questionnaire, documents?.items, getLocalizedDtoName, prefixes]
  );

  const businessValidationSchema = useMemo(
    () => ({
      businesses: yup.array().of(
        yup.object({
          businessName: yup.string().required(getRequiredByLabel(t.BUSINESS_NAME)),
          businessAddress: yup.string().required(getRequiredByLabel(t.BUSINESS_ADDRESS)),
          businessOwnershipTypeId: yup.string().required(getRequiredByLabel(t.TYPE_OF_OWNERSHIP)),
          businessTypeId: yup.string().required(getRequiredByLabel(t.TYPE_OF_BUSINESS)),
          maximumNumberOfEmployeesInThePast12Months: yup
            .number()
            .required(getRequiredByLabel(t.BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED)),
          isDirector: yup
            .boolean()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue ===
              ClientPublicService.BusinessOwnershipTypeEnum.Corporation
                ? schema.required(getRequiredByLabel(t.ARE_YOU_A_DIRECTOR))
                : schema.nullable()
            ),
          nameOfPartnersOrDirectors: yup
            .string()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue !==
              ClientPublicService.BusinessOwnershipTypeEnum.SoleProprietorship
                ? schema.recommended(getRecommendedByLabel(t.NAME_OF_PARTNERS__DIRECTORS))
                : schema
            ),
          startDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .required(getRequiredByLabel(t.START_DATE)),
          dateOperationsCeased: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .nullable(),
          isCorporationBankrupt: yup
            .boolean()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue ===
              ClientPublicService.BusinessOwnershipTypeEnum.Corporation
                ? schema.required(getRequiredByLabel(t.IS_THE_CORPORATION_BANKRUPT))
                : schema.nullable()
            ),
          hasEmployeesOrSubContractors: yup
            .boolean()
            .required(getRequiredByLabel(t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS)),
          isOwingWagesToEmployees: yup
            .boolean()
            .required(getRequiredByLabel(t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES)),
          isOwingSourceDeductionOnWages: yup
            .boolean()
            .required(getRequiredByLabel(t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES)),
        })
      ),
      spouseBusinesses: yup.array().of(
        yup.object({
          businessName: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.BUSINESS_NAME))
            : yup.string().nullable(),
          businessAddress: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.BUSINESS_ADDRESS))
            : yup.string().nullable(),
          businessOwnershipTypeId: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.TYPE_OF_OWNERSHIP))
            : yup.string().nullable(),
          businessTypeId: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.TYPE_OF_BUSINESS))
            : yup.string().nullable(),
          maximumNumberOfEmployeesInThePast12Months: personalInfo?.isJointFile
            ? yup.number().required(getRequiredByLabel(t.BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED))
            : yup.number().nullable(),
          isDirector: yup
            .boolean()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue ===
                ClientPublicService.BusinessOwnershipTypeEnum.Corporation && personalInfo?.isJointFile
                ? schema.required(getRequiredByLabel(t.ARE_YOU_A_DIRECTOR))
                : schema.nullable()
            ),
          nameOfPartnersOrDirectors: yup
            .string()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue !==
                ClientPublicService.BusinessOwnershipTypeEnum.SoleProprietorship && personalInfo?.isJointFile
                ? schema.recommended(getRecommendedByLabel(t.NAME_OF_PARTNERS__DIRECTORS))
                : schema
            ),
          startDate: personalInfo?.isJointFile
            ? yup
                .date()
                .typeError(t.INVALID_DATE)
                .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
                .required(getRequiredByLabel(t.START_DATE))
            : yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
          dateOperationsCeased: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .nullable(),
          isCorporationBankrupt: yup
            .boolean()
            .when(['businessOwnershipTypeId'], ([businessOwnershipTypeId], schema) =>
              businessOwnershipTypes?.find((item) => item?.id === businessOwnershipTypeId)?.enumValue ===
                ClientPublicService.BusinessOwnershipTypeEnum.Corporation && personalInfo?.isJointFile
                ? schema.required(getRequiredByLabel(t.IS_THE_CORPORATION_BANKRUPT))
                : schema.nullable()
            ),
          hasEmployeesOrSubContractors: personalInfo?.isJointFile
            ? yup.boolean().required(getRequiredByLabel(t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS))
            : yup.boolean().nullable(),
          isOwingWagesToEmployees: personalInfo?.isJointFile
            ? yup.boolean().required(getRequiredByLabel(t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES))
            : yup.boolean().nullable(),
          isOwingSourceDeductionOnWages: personalInfo?.isJointFile
            ? yup.boolean().required(getRequiredByLabel(t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES))
            : yup.boolean().nullable(),
        })
      ),
    }),
    [
      businessOwnershipTypes,
      getRecommendedByLabel,
      getRequiredByLabel,
      t.ARE_YOU_A_DIRECTOR,
      t.BUSINESS_ADDRESS,
      t.BUSINESS_NAME,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS,
      t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES,
      t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES,
      t.INVALID_DATE,
      t.IS_THE_CORPORATION_BANKRUPT,
      t.NAME_OF_PARTNERS__DIRECTORS,
      t.START_DATE,
      t.TYPE_OF_BUSINESS,
      t.TYPE_OF_OWNERSHIP,
      t.BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED,
      personalInfo?.isJointFile,
    ]
  );

  const coSignsGuaranteedLoansValidationSchema = useMemo(
    () => ({
      coSignedOrGuaranteedLoans: yup.array().of(
        yup.object({
          lendersName: yup.string().required(getRequiredByLabel(t.LENDERS_NAME)),
          lendersAddress: yup.string().required(getRequiredByLabel(t.LENDERS_ADDRESS)),
          borrowersName: yup.string().required(getRequiredByLabel(t.BORROWERS_NAME)),
          borrowersAddress: yup.string().required(getRequiredByLabel(t.BORROWERS_ADDRESS)),
        })
      ),
    }),
    [getRequiredByLabel, t.BORROWERS_ADDRESS, t.BORROWERS_NAME, t.LENDERS_ADDRESS, t.LENDERS_NAME]
  );
  const creditBureauConsentValidationSchema = useMemo(() => ({}), []);

  const financialAdviceProviderValidationSchema = useMemo(
    () => ({
      debtorHasAFinancialAdviceProvider: yup
        .boolean()
        .required(getRequiredByLabel(t.HAS_FINANCIAL_ADVICE_REQUIRED_MESSAGE)),
      financialAdvices: yup.array().of(
        yup.object({
          surname: yup.string().required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME)),
          givenName: yup
            .string()
            .required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME)),
          firmName: yup
            .string()
            .required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME)),
          firmAddress: yup
            .string()
            .required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS)),
          totalAmountPaidToDate: yup
            .number()
            .required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE)),
          totalRemainingAmountToBePaid: yup
            .number()
            .required(
              getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID)
            ),
        })
      ),
      debtorSpouseHasAFinancialAdviceProvider: personalInfo?.isJointFile
        ? yup.boolean().required(getRequiredByLabel(t.HAS_FINANCIAL_ADVICE_REQUIRED_MESSAGE))
        : yup.boolean().nullable(),
      spouseFinancialAdvices: yup.array().of(
        yup.object({
          surname: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME))
            : yup.string().nullable(),
          givenName: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME))
            : yup.string().nullable(),
          firmName: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME))
            : yup.string().nullable(),
          firmAddress: personalInfo?.isJointFile
            ? yup.string().required(getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS))
            : yup.string().nullable(),
          totalAmountPaidToDate: personalInfo?.isJointFile
            ? yup
                .number()
                .required(
                  getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE)
                )
            : yup.number().nullable(),
          totalRemainingAmountToBePaid: personalInfo?.isJointFile
            ? yup
                .number()
                .required(
                  getRequiredByLabel(t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID)
                )
            : yup.number().nullable(),
        })
      ),
    }),
    [
      getRequiredByLabel,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID,
      t.HAS_FINANCIAL_ADVICE_REQUIRED_MESSAGE,
      personalInfo?.isJointFile,
    ]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ...debtAreaApplicantValidationSchema,
        ...debtAreaSpouseValidationSchema,
        ...transactionsApplicantValidationSchema,
        ...transactionsSpouseValidationSchema,
        ...businessValidationSchema,
        ...coSignsGuaranteedLoansValidationSchema,
        ...creditBureauConsentValidationSchema,
        ...documentsValidationSchema,
        ...financialAdviceProviderValidationSchema,
      }),
    [
      businessValidationSchema,
      coSignsGuaranteedLoansValidationSchema,
      creditBureauConsentValidationSchema,
      debtAreaApplicantValidationSchema,
      debtAreaSpouseValidationSchema,
      transactionsApplicantValidationSchema,
      documentsValidationSchema,
      financialAdviceProviderValidationSchema,
      transactionsSpouseValidationSchema,
    ]
  );

  const isFormStarted = useMemo(
    () => appFormStatus?.questionnaireStatus !== ClientPublicService.AppFormStatusEnum.NotStarted,
    [appFormStatus?.questionnaireStatus]
  );

  const panels = useMemo(
    () =>
      [
        {
          title: `${t.SUPPLEMENTAL_INFORMATION_ON_DEBTS} - ${t.APPLICANT}`,
          id: DEBT_AREA_APPLICANT_PANEL,
          validatedProps: Object.keys(debtAreaApplicantValidationSchema),
          ref: debtAreaApplicantRef,
          children: <DebtAreaApplicant />,
          conditionalRender: true,
        },
        {
          title: `${t.SUPPLEMENTAL_INFORMATION_ON_DEBTS} - ${t.SPOUSE}`,
          id: DEBT_AREA_SPOUSE_PANEL,
          ref: debtAreaSpouseRef,
          children: <DebtAreaSpouse />,
          conditionalRender: initialValues?.isMarried,
        },
        {
          title: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE,
          id: FINANCIAL_ADVICE_PANEL,
          validatedProps: Object.keys(financialAdviceProviderValidationSchema),
          ref: refFinancialAdvices,
          children: <FinancialAdviceProvider isJointFile={personalInfo?.isJointFile} />,
          conditionalRender: true,
        },
        {
          title: t.COSIGNS_OR_GUARANTEED_LOANS,
          id: CO_SIGNS_GUARANTEED_LOANS_PANEL,
          validatedProps: Object.keys(coSignsGuaranteedLoansValidationSchema),
          ref: coSignsGuaranteedLoansRef,
          children: <CoSignsGuaranteedLoans />,
          conditionalRender: true,
        },
        {
          title: t.TRANSACTIONS_APPLICANT,
          id: TRANSACTIONS_APPLICANT_PANEL,
          validatedProps: Object.keys(transactionsApplicantValidationSchema),
          ref: transactionsApplicantRef,
          children: <TransactionsApplicant />,
          conditionalRender: true,
        },
        {
          title: t.TRANSACTIONS_SPOUSEPARTNER,
          id: TRANSACTIONS_SPOUSE_PANEL,
          validatedProps: Object.keys(transactionsSpouseValidationSchema),
          ref: transactionsSpouseRef,
          children: <TransactionsSpouse />,
          conditionalRender: personalInfo?.isJointFile,
        },
        {
          title: t.BUSINESS,
          id: BUSINESS_PANEL,
          validatedProps: Object.keys({ ...businessValidationSchema, ...documentsValidationSchema }),
          ref: businessRef,
          children: <Business isJointFile={personalInfo?.isJointFile} />,
          conditionalRender: true,
        },
        {
          title: t.CREDIT_BUREAU_CONSENT,
          id: CREDIT_BUREAU_CONSENT_PANEL,
          validatedProps: Object.keys(creditBureauConsentValidationSchema),
          ref: creditBureauConsentRef,
          children: <CreditBureauConsent />,
          conditionalRender: true,
        },
      ]?.filter((panel) => panel.conditionalRender),
    [
      businessValidationSchema,
      coSignsGuaranteedLoansValidationSchema,
      creditBureauConsentValidationSchema,
      debtAreaApplicantValidationSchema,
      documentsValidationSchema,
      initialValues?.isMarried,
      personalInfo?.isJointFile,
      t.APPLICANT,
      t.BUSINESS,
      t.COSIGNS_OR_GUARANTEED_LOANS,
      t.CREDIT_BUREAU_CONSENT,
      t.SPOUSE,
      t.SUPPLEMENTAL_INFORMATION_ON_DEBTS,
      t.TRANSACTIONS_APPLICANT,
      t.TRANSACTIONS_SPOUSEPARTNER,
      transactionsApplicantValidationSchema,
      transactionsSpouseValidationSchema,
      financialAdviceProviderValidationSchema,
      t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE,
    ]
  );

  const saveQuestionnaire = useCallback(
    (values: IFormQuestionnaireValues, onSuccess?: () => void) => {
      updateQuestionnaire(
        {
          ...convertQuestionnaireFormValuesToDto(values),
        },
        {
          onSuccess: (response) => {
            if (response?.result === ClientPublicService.Result.Successful) {
              updateAppFormStatus?.();
              toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.QUESTIONNAIRE));

              if (onSuccess) {
                onSuccess();
              } else {
                navigate(`${ROUTES.DASHBOARD}`);
              }
            } else {
              toast.error(response?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
            }
          },
        }
      );
    },
    [
      navigate,
      t.MODULE_SAVED_SUCCESSFULLY,
      t.QUESTIONNAIRE,
      t.SOMETHING_WENT_WRONG,
      updateAppFormStatus,
      updateQuestionnaire,
    ]
  );

  const handleSubmit = useCallback(
    async (values: IFormQuestionnaireValues, onSuccess?: () => void) => {
      const isDocumentsSchemaValid = await yup.object().shape({ documents: documentsYupObject }).isValid(values);

      if (!isDocumentsSchemaValid) {
        showModal(
          <ConfirmationModal
            title={t.CONFIRMATION}
            message={t.QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM}
            onCancel={closeModal}
            cancelButtonText={t.STAY}
            okButtonText={t.LEAVE}
            onOk={() => {
              saveQuestionnaire(values, onSuccess);
              closeModal();
            }}
          />
        );
      } else {
        const newDocumentsIds = await uploadDocuments({
          fileId: appFileData?.id as string,
          files: values?.documents as IFormValuesDocumentItem[],
        });

        saveQuestionnaire(
          {
            ...(values as IFormQuestionnaireValues),
            documentIds: [...(values?.documentIds || []), ...newDocumentsIds] as string[],
          },
          onSuccess
        );
      }
    },
    [
      appFileData?.id,
      closeModal,
      documentsYupObject,
      saveQuestionnaire,
      showModal,
      t.CONFIRMATION,
      t.LEAVE,
      t.QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM,
      t.STAY,
      uploadDocuments,
    ]
  );

  useEffect(() => {
    setShowErrorsList(isFormStarted && isDocumentsSuccess && isQuestionnaireSuccess && !isLocked);
  }, [isLocked, isFormStarted, isQuestionnaireSuccess, isDocumentsSuccess, setShowErrorsList]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_: IFormQuestionnaireValues, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={false}
        validateOnChange
        validateOnBlur={isFormStarted}
        validationSchema={validationSchema}
        data-testid="personal-information-form"
      >
        {({ values, submitForm }) => {
          return (
            <UnsavedForm onSubmit={handleSubmit}>
              <>{isLocked && <SectionLockMessage />}</>

              {initialValues && <ErrorsList panels={panels} />}

              <>
                {panels?.map((props) => (
                  <AppFormAccordionPanel key={props?.id} {...props} />
                ))}
              </>

              <NavigationButtons
                onNext={async () => {
                  if (isLocked) {
                    navigate(`${ROUTES.DASHBOARD}`);
                  } else {
                    await submitForm();
                    handleSubmit(values);
                  }
                }}
                onPrev={async () => {
                  if (isLocked) {
                    navigate(`${ROUTES.APP_FORM}/${ROUTES.BANKING}`);
                  } else {
                    await submitForm();
                    handleSubmit(values, () => {
                      navigate(`${ROUTES.APP_FORM}/${ROUTES.BANKING}`);
                    });
                  }
                }}
                currentSection={AppformSection.Questionnaire}
                disabled={isLocked}
              />
            </UnsavedForm>
          );
        }}
      </Formik>
    </>
  );
};

const Questionnaire = () => (
  <AccordionProvider>
    <QuestionnaireForm />
  </AccordionProvider>
);

export default Questionnaire;
