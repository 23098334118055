import { ClientPublicService } from '../../app/api/ClientPublicService';

import { serverDateToInputFormat, inputDateToServerFormat, DATE_FORMAT3 } from '../../app/utils/dateTimeHelpers';
import { IDebtorProfileFormValues } from './types';

const getNameLine = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  return firstName || lastName || '-';
};

const getAddressLine = (streetName?: string, streetNumber?: string) => {
  if (streetName && streetNumber) return `${streetNumber} ${streetName}`;
  return streetName || streetNumber || '';
};

const getCityLine = (city?: string, province?: string, country?: string) => {
  let cityLine = city || '';
  cityLine += province ? `${cityLine ? ', ' : ''}${province}` : '';
  cityLine += country ? `${cityLine ? ', ' : ''}${country}` : '';
  return cityLine;
};

const getDateOfBirth = (dto?: ClientPublicService.DebtorProfileDto) =>
  serverDateToInputFormat(dto?.dateOfBirth)?.format(DATE_FORMAT3) || '-';

const getPhoneLine = (phoneNumber?: string, phoneType?: string) => {
  if (!phoneNumber && !phoneType) return '-';

  let phoneLine = phoneNumber;
  phoneLine += phoneType ? ` (${phoneType})` : '';
  return phoneLine;
};

const getDebtorProfileFormValues = (data?: ClientPublicService.DebtorProfileDto) =>
  ({
    firstName: data?.firstName || '',
    middleName: data?.middleName || '',
    lastName: data?.lastName || '',
    dateOfBirth: serverDateToInputFormat(data?.dateOfBirth) || null,
    address: data?.address || '',
    streetName: data?.streetName || '',
    streetNumber: data?.streetNumber || '',
    unit: data?.unit || '',
    city: data?.city || '',
    provinceId: data?.provinceId || '',
    countryId: data?.countryId || '',
    postalCode: data?.postalCode || '',
    mainPhoneTypeId: data?.mainPhoneTypeId || '',
    mainPhoneNumber: data?.mainPhoneNumber || '',
  } as IDebtorProfileFormValues);

const convertDebtorProfileFormValuesToDto = (data?: IDebtorProfileFormValues) => ({
  firstName: data?.firstName || undefined,
  middleName: data?.middleName || undefined,
  lastName: data?.lastName || undefined,
  dateOfBirth: inputDateToServerFormat(data?.dateOfBirth),
  address: data?.address || undefined,
  streetName: data?.streetName || undefined,
  streetNumber: data?.streetNumber || undefined,
  unit: data?.unit || undefined,
  city: data?.city || undefined,
  provinceId: data?.provinceId || undefined,
  countryId: data?.countryId || undefined,
  postalCode: data?.postalCode || undefined,
  mainPhoneTypeId: data?.mainPhoneTypeId || undefined,
  mainPhoneNumber: data?.mainPhoneNumber || undefined,
});

const testOneUpperCase = (value: string) => /[A-Z]/.test(value);

const testOneLowerCase = (value: string) => /[a-z]/.test(value);

const testOneNumber = (value: string) => /[0-9]/.test(value);

const testOneSpecialCharacter = (value: string) => /[!@#$%^&*()_+{}\\[\]:;<>,.?~\\/-]/.test(value);

export {
  getAddressLine,
  getCityLine,
  getDateOfBirth,
  getPhoneLine,
  getNameLine,
  getDebtorProfileFormValues,
  convertDebtorProfileFormValuesToDto,
  testOneLowerCase,
  testOneNumber,
  testOneSpecialCharacter,
  testOneUpperCase,
};
