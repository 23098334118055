import { useMemo } from 'react';
import {
  IconButton,
  IconButtonProps,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  useTheme,
  TypographyProps,
  Card,
  CardProps,
  CardContent,
  CardActionArea,
  CardActions,
  Box,
} from '@mui/material';
import { IElevatedContainerProps } from '../ElevatedContainer';

const ActionContainer = (props: any) => <CardActionArea {...props} />;

export interface IListItemProps {
  id: string;
  title?: string | React.ReactElement;
  subtitle?: React.ReactElement | string;
  icon?: React.ReactElement;
  iconProps?: ListItemIconProps;
  actionButtonIcon?: React.ReactElement;
  actionButtonProps?: IconButtonProps;
  containerProps?: CardProps | IElevatedContainerProps;
  primaryTextProps?: TypographyProps;
  secondaryTextProps?: TypographyProps;
  actionArea?: 'container' | 'arrow';
}

const ListLayoutItem = ({
  title,
  subtitle,
  icon,
  iconProps,
  actionButtonIcon,
  actionButtonProps,
  containerProps,
  primaryTextProps,
  secondaryTextProps,
  actionArea = 'arrow',
}: IListItemProps) => {
  const theme = useTheme();

  const isAllContainerClickable = useMemo(() => actionArea === 'container', [actionArea]);

  const CardContentInnerContainer = isAllContainerClickable ? ActionContainer : Box;

  return (
    <Card
      {...containerProps}
      sx={{
        borderRadius: 1.5,
        '& .MuiCardContent-root': {
          p: 0,
        },
        '& .MuiCardContent-root:last-child': {
          pb: 0,
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.cobalt,
        },
        ':hover': {
          backgroundColor: isAllContainerClickable ? theme.palette.cobalt : theme.palette.white,
          '& .MuiTypography-root': {
            color: isAllContainerClickable ? theme.palette.white : theme.palette.primary.main,
          },
          '& .MuiSvgIcon-root': {
            color: isAllContainerClickable ? theme.palette.white : theme.palette.cobalt,
          },
        },
        ...containerProps?.sx,
      }}
    >
      <CardContent>
        <CardContentInnerContainer
          onClick={(e) => {
            if (isAllContainerClickable) {
              actionButtonProps?.onClick?.(e as any);
            }
          }}
          sx={{ pt: 1, pb: 1, pl: 1.5, pr: 1.5 }}
        >
          <ListItem
            disableGutters
            secondaryAction={
              actionButtonIcon && (
                <CardActions>
                  {isAllContainerClickable ? (
                    actionButtonIcon
                  ) : (
                    <IconButton
                      aria-label="action"
                      {...actionButtonProps}
                      sx={{ color: theme.palette.cobalt, ...actionButtonProps?.sx }}
                      edge="end"
                    >
                      {actionButtonIcon}
                    </IconButton>
                  )}
                </CardActions>
              )
            }
            sx={{ pt: 0, pb: 0 }}
          >
            <>
              {icon && (
                <ListItemIcon sx={{ color: theme.palette.slate }} {...iconProps}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText
                primary={title}
                secondary={subtitle}
                primaryTypographyProps={primaryTextProps}
                secondaryTypographyProps={{
                  component: 'span',
                  ...secondaryTextProps,
                }}
              />
            </>
          </ListItem>
        </CardContentInnerContainer>
      </CardContent>
    </Card>
  );
};

export default ListLayoutItem;
