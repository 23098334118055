import { useCallback } from 'react';
import { Stack, Typography, Button } from '@mui/material';

import DataItem from '../../../components/DataItem';
import RequestChangeModal from './RequestChangeModal/RequestChangeModal';
import ConfirmationModal from '../../../components/ConfirmationModal';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';

import { useDebitInformationQuery, useApplicationFileQuery } from '../queries';

const DebitInfoCard = () => {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();

  const { data: applicationFile } = useApplicationFileQuery() || {};

  const { data: debitInformation } = useDebitInformationQuery(applicationFile?.id) || {};

  const handleChangeSuccessConfirmation = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.CHANGE_REQUESTED}
        message={t.WE_HAVE_MADE_A_REQUEST_TO_UPDATE_YOUR_PREAUTHORIZED_DEBIT_INFORMATION}
        onOk={closeModal}
        okButtonText={t.CLOSE}
        hasCancelButton={false}
      />
    );
  }, [
    closeModal,
    showModal,
    t.CHANGE_REQUESTED,
    t.CLOSE,
    t.WE_HAVE_MADE_A_REQUEST_TO_UPDATE_YOUR_PREAUTHORIZED_DEBIT_INFORMATION,
  ]);

  const handleRequestChange = useCallback(() => {
    showModal(
      <RequestChangeModal
        fileId={applicationFile?.id as string}
        onCancel={closeModal}
        onOk={() => {
          closeModal();
          handleChangeSuccessConfirmation();
        }}
      />
    );
  }, [applicationFile?.id, closeModal, handleChangeSuccessConfirmation, showModal]);

  return (
    <>
      <Stack>
        <Typography variant="h6" fontWeight={600} aria-label={t.PREAUTHORIZED_DEBIT_INFORMATION}>
          {t.PREAUTHORIZED_DEBIT_INFORMATION}
        </Typography>
        <DataItem title={t.ACCOUNT_NAME} content={debitInformation && (debitInformation?.accountOwner || '-')} />

        <DataItem title={t.ACCOUNT_NUMBER} content={debitInformation && (debitInformation?.bankAccountNumber || '-')} />

        <DataItem title={t.BRANCH_NUMBER} content={debitInformation && (debitInformation?.bankBranchNumber || '-')} />

        <DataItem title={t.TRANSIT_NUMBER} content={debitInformation && (debitInformation?.bankTransitNumber || '-')} />

        <Button
          variant="contained"
          fullWidth
          onClick={handleRequestChange}
          disabled={!debitInformation || !debitInformation?.userCanSendChangeRequest}
          sx={{ mt: 3 }}
        >
          {debitInformation?.userCanSendChangeRequest ? t.REQUEST_CHANGE : t.CHANGE_REQUESTED}
        </Button>
      </Stack>
    </>
  );
};

export default DebitInfoCard;
