import { useCallback, useEffect } from 'react';
import { Outlet, matchPath, useNavigate, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';

import PageContentContainer from '../../components/PageContentContainer';

import useLocale from '../../app/hooks/useLocale';

import { ROUTES } from '../../app/routes/constants';
import { IAppFormContextProps } from './hooks/useAppForm';
import { useAppFormStatusQuery, useApplicationFileQuery } from './queries';
import AppFormNavigatorBar from './components/AppFormNavigatorBar';

const AppForm = () => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: appFileData } = useApplicationFileQuery() || {};

  const { data: appFormStatus, refetch } = useAppFormStatusQuery(appFileData?.id) || {};

  const updateAppFormStatus = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const match = matchPath({ path: ROUTES.APP_FORM }, location.pathname);
    if (match) navigate(`${ROUTES.APP_FORM}/${ROUTES.PERSONAL_INFORMATION}`, { replace: true });
  }, [location.pathname, navigate]);

  return (
    <PageContentContainer headerProps={{ title: t.APPLICATION_FORM, sx: { pb: 2.5 } }}>
      <Stack>
        <AppFormNavigatorBar />
        <Outlet context={{ updateAppFormStatus, appFormStatus, appFileData } as IAppFormContextProps} />
      </Stack>
    </PageContentContainer>
  );
};

export default AppForm;
