import { useMemo, useCallback } from 'react';
import { Grid, Button, Divider } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import yup from '../../../app/utils/customYup';

import ChangePasswordForm from './ChangePasswordForm';
import EditModal, { IEditModalProps } from '../../../components/EditModal';

import useLocale from '../../../app/hooks/useLocale';

import {
  ONE_LOWERCASE_TEST,
  ONE_UPPERCASE_TEST,
  ONE_NUMBER_TEST,
  ONE_SPECIAL_CHAR_TEST,
  PASSWORDS_MATCH_TEST,
} from '../constants';
import { useDebtorPasswordMutation } from '../queries';
import { testOneLowerCase, testOneNumber, testOneSpecialCharacter, testOneUpperCase } from '../utils';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

interface IEditDebtModalProps extends Pick<IEditModalProps, 'onCancel'> {
  onOk?: () => void;
}

const ChangePasswordModal = ({ onCancel, onOk }: IEditDebtModalProps) => {
  const { t } = useLocale();

  const { mutate: updatePassword } = useDebtorPasswordMutation() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup.string().required(getRequiredByLabel(t.OLD_PASSWORD)),
        newPassword: yup
          .string()
          .required(getRequiredByLabel(t.NEW_PASSWORD))
          .test(ONE_LOWERCASE_TEST, t.ONE_LOWERCASE_CHARACTER, testOneLowerCase)
          .test(ONE_UPPERCASE_TEST, t.ONE_UPPERCASE_CHARACTER, testOneUpperCase)
          .test(ONE_NUMBER_TEST, t.ONE_NUMBER, testOneNumber)
          .test(ONE_SPECIAL_CHAR_TEST, t.ONE_SPECIAL_CHARACTER, testOneSpecialCharacter)
          .min(8, t.EIGHT_CHARACTERS_MINIMUM),
        confirmNewPassword: yup
          .string()
          .required(getRequiredByLabel(t.CONFIRM_NEW_PASSWORD))
          .test(
            PASSWORDS_MATCH_TEST,
            t.PASSWORDS_MATCH,
            (value, context) => Boolean(value) && value === context.parent.newPassword
          ),
      }),
    [
      getRequiredByLabel,
      t.CONFIRM_NEW_PASSWORD,
      t.EIGHT_CHARACTERS_MINIMUM,
      t.NEW_PASSWORD,
      t.OLD_PASSWORD,
      t.ONE_LOWERCASE_CHARACTER,
      t.ONE_NUMBER,
      t.ONE_SPECIAL_CHARACTER,
      t.ONE_UPPERCASE_CHARACTER,
      t.PASSWORDS_MATCH,
    ]
  );

  return (
    <EditModal title={t.CHANGE_PASSWORD} onCancel={onCancel}>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        onSubmit={(_: ClientPublicService.IDebtorPasswordUpdatePublicDto, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validate={(values) => {
          return validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {})
            .catch((err) => {
              return err.inner.reduce((obj: any, e: any) => {
                if (!(e.path in obj)) obj[e.path] = [];
                obj[e.path] = obj[e.path].concat(e.errors);
                return obj;
              }, {});
            });
        }}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ isValid, values, setFieldError }) => {
          return (
            <>
              <ChangePasswordForm />
              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container flexDirection="row" justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={!isValid}
                    onClick={() => {
                      updatePassword(values, {
                        onSuccess: (response) => {
                          if (response?.hasErrors) {
                            if (response?.messages?.[0]?.body === 'INVALID_OLD_PASSWORD') {
                              setFieldError('oldPassword', t.INVALID_OLD_PASSWORD);
                            } else if (response?.messages?.[0]?.body === 'PASSWORD_HISTORY_ERROR') {
                              toast.error(t.NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_PREVIOUS_PASSWORDS);
                            } else if (response?.messages?.[0]?.body === 'PASSWORD_DICTIONARY_ERROR') {
                              toast.error(t.THE_PASSWORD_IS_EASY_TO_GUESS);
                            } else {
                              toast.error(t.OPERATION_FAILED);
                            }
                          } else {
                            toast.success(t.PASSWORD_CHANGED_SUCCESSFULLY);
                            onOk?.();
                          }
                        },
                        onError: (response) => {
                          if (
                            response?.error?.validationErrors?.[0]?.message &&
                            t[response?.error?.validationErrors?.[0]?.message]
                          ) {
                            toast.error(t[response?.error?.validationErrors?.[0]?.message]);
                          } else {
                            toast.error(t.OPERATION_FAILED);
                          }
                        },
                      });
                    }}
                  >
                    {t.CHANGE_PASSWORD}
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </EditModal>
  );
};

export default ChangePasswordModal;
