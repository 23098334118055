import { useContext, useMemo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Divider, Box } from '@mui/material';

import {
  SelectField,
  TextField,
  CheckboxField,
  DatePickerField,
  MaskedTextField,
} from '../../../../../components/FormFields';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { PersonalInfoContext } from '../PersonalInformationProvider';
import { IFormValuesPersonalInfo } from '../types';

import { useMaritalStatusesQuery, useEducationLevelsQuery } from '../queries';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';
import { SupportDocsUpload } from '../../../../../components/DocumentsUpload';
import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';

const PersonalDetails = () => {
  const { t } = useLocale();
  const { isMarried, setIsMarried } = useContext(PersonalInfoContext);
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const { data: maritalStatuses } = useMaritalStatusesQuery() || {};
  const { data: educationLevels } = useEducationLevelsQuery() || {};

  const maritalStatusEnum = useMemo(
    () => maritalStatuses?.find((status) => status.id === values?.maritalStatusId)?.enumValue,
    [maritalStatuses, values?.maritalStatusId]
  );

  const isSingle = useMemo(
    () => maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Single || !values?.maritalStatusId,
    [maritalStatusEnum, values?.maritalStatusId]
  );

  useEffect(() => {
    const isMarried =
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Married ||
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.CommonLaw;
    setIsMarried(isMarried);

    if (!isMarried) {
      setFieldValue('isJointFile', false);
    }
  }, [maritalStatusEnum, setFieldValue, setIsMarried]);

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>

      <TextField
        name="firstName"
        label={t.FIRST_NAME}
        required
        data-testid="firstName"
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      <TextField
        name="middleName"
        label={t.MIDDLE_NAME}
        data-testid="middleName"
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      <TextField
        name="lastName"
        label={t.LAST_NAME}
        required
        data-testid="lastName"
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      <TextField
        name="akaName"
        label={t.ALSO_KNOWN_AS}
        data-testid="akaName"
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      <DatePickerField
        name="birthDate"
        label={t.DATE_OF_BIRTH}
        required
        data-testid="birthDate"
        disableFuture
        format={DATE_FORMAT}
        disabled={isLocked}
      />

      <MaskedTextField mask="000 000 000" name="sin" label={t.SIN} required data-testid="sin" disabled={isLocked} />

      <SelectField
        label={t.MARITAL_STATUS}
        name="maritalStatusId"
        options={maritalStatuses}
        required
        data-testid="maritalStatusId"
        disabled={isLocked}
      />

      <DatePickerField
        name="marriageDate"
        label={t.DATE_OF_MARRIAGE}
        data-testid="marriageDate"
        disabled={isSingle || isLocked}
        format={DATE_FORMAT}
        disableFuture
      />

      <SelectField
        label={t.HIGHEST_EDUCATION_LEVEL}
        name="educationLevelId"
        options={educationLevels}
        data-testid="educationLevelId"
        disabled={isLocked}
      />

      {isMarried && (
        <Box data-testid="spouse-personal-details">
          <Divider sx={{ mt: 2, mb: 2 }} />

          <AppFormSectionSubtitle>{t.SPOUSE_PARTNER}</AppFormSectionSubtitle>

          <TextField
            name="spouseFirstName"
            label={t.FIRST_NAME}
            required={Boolean(values?.isJointFile)}
            recommended={!Boolean(values?.isJointFile)}
            data-testid="spouseFirstName"
            inputProps={{ maxLength: 100 }}
            disabled={isLocked}
          />

          <TextField
            name="spouseMiddleName"
            label={t.MIDDLE_NAME}
            data-testid="spouseMiddleName"
            inputProps={{ maxLength: 100 }}
            disabled={isLocked}
          />

          <TextField
            name="spouseLastName"
            label={t.LAST_NAME}
            required={Boolean(values?.isJointFile)}
            recommended={!Boolean(values?.isJointFile)}
            data-testid="spouseLastName"
            inputProps={{ maxLength: 100 }}
            disabled={isLocked}
          />

          <TextField
            name="spouseAkaName"
            label={t.ALSO_KNOWN_AS}
            data-testid="spouseAkaName"
            inputProps={{ maxLength: 100 }}
            disabled={isLocked}
          />

          <DatePickerField
            name="spouseBirthDate"
            label={t.DATE_OF_BIRTH}
            required={Boolean(values?.isJointFile)}
            data-testid="spouseBirthDate"
            format={DATE_FORMAT}
            disableFuture
            disabled={isLocked}
          />

          <MaskedTextField
            mask="000 000 000"
            name="spouseSIN"
            label={t.SIN}
            required={Boolean(values?.isJointFile)}
            data-testid="spouseSIN"
            disabled={isLocked}
          />

          <SelectField
            label={t.HIGHEST_EDUCATION_LEVEL}
            name="spouseEducationLevelId"
            options={educationLevels}
            data-testid="spouseEducationLevelId"
            disabled={isLocked}
          />

          <CheckboxField name="isJointFile" label={t.JOINT_FILE} data-testid="isJointFile" disabled={isLocked} />
        </Box>
      )}

      <Divider sx={{ mt: 2 }} />

      <SupportDocsUpload
        tooltip={t.ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED}
        documentFolderCode="lead"
        documentPrefixCodes={[
          'Personal.Drivers-License',
          'Personal.Health-Card',
          'Personal.Other-Id',
          'Personal.Passport',
        ]}
        isForSpouse={values?.isJointFile}
        disabled={isLocked}
        onlyRetrieveByDocumentIds={true}
        documentIds={values?.documentIds}
      />

      <Divider sx={{ mt: 2, mb: 1 }} />

      <TextField
        name="additionalNotes"
        label={t.ADDITIONAL_NOTES}
        data-testid="additionalNotes"
        multiline
        minRows={4}
        maxRows={4}
        inputProps={{ maxLength: 1000 }}
        disabled={isLocked}
      />
    </>
  );
};

export default PersonalDetails;
