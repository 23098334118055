import { Backdrop, CircularProgress, useTheme } from '@mui/material';

interface IProps {
  loading?: boolean;
  left?: number;
}

const Loader = ({ loading = false, left = 0 }: IProps) => {
  const theme = useTheme();

  return (
    <Backdrop
      open={loading}
      sx={{
        '.MuiCircularProgress-root': { color: theme.palette.white },
        zIndex: (theme) => theme.zIndex.drawer + 1,
        left,
      }}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default Loader;
