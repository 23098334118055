import { ReactElement, useEffect, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';

import UnsavedFormProvider, { UnsavedFormContext } from './UnsavedFormProvider';
import { checkIfUnsavedFormChanges } from '../../../../app/utils/helpers';
import { APPFORM_SAVE_AND_LEAVE_EVENT, CHECK_IF_UNSAVED_CHANGES_EVENT } from '../../../../app/constants/eventBusKeys';
import eventBus from '../../../../app/utils/eventBus';

interface IUnsavedFormProps {
  children: ReactElement | ReactElement[];
  onSubmit?: (values?: any, callback?: () => void) => void;
}

const UnsavedFormComponent = ({ children, onSubmit }: IUnsavedFormProps) => {
  const { values, initialValues } = useFormikContext();
  const { fieldsToExclude } = useContext(UnsavedFormContext);

  const handleSaveAndLeave = useCallback(
    (e: any) => {
      const onSaveSuccess = e?.detail?.onSaveSuccess;
      onSubmit?.(values, onSaveSuccess);
    },
    [onSubmit, values]
  );

  const handleCheckIfUnsavedChanges = useCallback(
    (e: any) => {
      const onCheckSuccess = e?.detail?.onCheckSuccess;
      onCheckSuccess?.(checkIfUnsavedFormChanges(values, initialValues, fieldsToExclude));
    },
    [fieldsToExclude, initialValues, values]
  );

  useEffect(() => {
    eventBus.on(APPFORM_SAVE_AND_LEAVE_EVENT, handleSaveAndLeave);

    return () => {
      eventBus.remove(APPFORM_SAVE_AND_LEAVE_EVENT, handleSaveAndLeave);
    };
  }, [handleSaveAndLeave]);

  useEffect(() => {
    eventBus.on(CHECK_IF_UNSAVED_CHANGES_EVENT, handleCheckIfUnsavedChanges);

    return () => {
      eventBus.remove(CHECK_IF_UNSAVED_CHANGES_EVENT, handleCheckIfUnsavedChanges);
    };
  }, [handleCheckIfUnsavedChanges]);

  return <>{children}</>;
};

const UnsavedForm = ({ children, onSubmit }: IUnsavedFormProps) => {
  return (
    <UnsavedFormProvider>
      <UnsavedFormComponent onSubmit={onSubmit}>{children}</UnsavedFormComponent>
    </UnsavedFormProvider>
  );
};

export default UnsavedForm;
