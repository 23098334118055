import { useMemo, useCallback } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery, Link, styled, useTheme, Box } from '@mui/material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import yup from '../../app/utils/customYup';

import { TextField, PhoneField, SelectField } from '../../components/FormFields';
import PageContentContainer from '../../components/PageContentContainer';
import ElevatedContainer from '../../components/ElevatedContainer';
import ConfirmationModal from '../../components/ConfirmationModal';

import useLocale from '../../app/hooks/useLocale';
import useModal from '../../app/hooks/useModal';

import { ROUTES } from '../../app/routes';
import { regexPhone } from '../../app/utils/helpers';
import { useContactTechnicalSupportMutation } from './queries';
import { ContactSupport } from '../../app/constants/ReCaptchaActions';
import { ClientPublicService } from '../../app/api/ClientPublicService';

type ITechnicalSupportForm = ClientPublicService.IContactSupportFormDto;

const TechnicalSupport = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const { showModal, closeModal } = useModal();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { mutateAsync: contactSupport } = useContactTechnicalSupportMutation() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const inquiryTypes = useMemo(
    () => [
      t.LOGIN_OR_ACCOUNT_ISSUES,
      t.PERFORMANCE_AND_LOADING_PROBLEMS,
      t.BROWSER_COMPATIBILITY,
      t.ERROR_MESSAGES,
      t.FUNCTIONALITY_AND_FEATURES,
      t.PASSWORD_RESET_ASSISTANCE,
      t.REPORT_A_BUG_OR_GLITCH,
    ],
    [
      t.BROWSER_COMPATIBILITY,
      t.ERROR_MESSAGES,
      t.FUNCTIONALITY_AND_FEATURES,
      t.LOGIN_OR_ACCOUNT_ISSUES,
      t.PERFORMANCE_AND_LOADING_PROBLEMS,
      t.REPORT_A_BUG_OR_GLITCH,
      t.PASSWORD_RESET_ASSISTANCE,
    ]
  );

  const showOnSuccessModal = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.MESSAGE_SENT_SUCCESSFULLY}
        message={t.YOUR_INQUIRY_HAS_BEEN_SUCCESSFULLY_SUBMITTED}
        onOk={() => {
          closeModal();
          navigate(ROUTES.ROOT);
        }}
        onCancel={closeModal}
        hasCancelButton={false}
        okButtonText={t.CLOSE}
      />
    );
  }, [
    closeModal,
    navigate,
    showModal,
    t.CLOSE,
    t.MESSAGE_SENT_SUCCESSFULLY,
    t.YOUR_INQUIRY_HAS_BEEN_SUCCESSFULLY_SUBMITTED,
  ]);

  const showOnErrorsModal = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.SUBMISSION_ERROR}
        message={
          <>
            {t.THERE_WAS_AN_ISSUE_PROCESSING_YOUR_REQUEST_PLEASE_TRY_AGAIN_LATER}
            <PhoneLink target="_blank" rel="noopener" href="tel:1-855-236-3328">
              1-855-BDO DEBT
            </PhoneLink>
            .
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
        hasCancelButton={false}
        okButtonText={t.CLOSE}
      />
    );
  }, [
    closeModal,
    showModal,
    t.CLOSE,
    t.SUBMISSION_ERROR,
    t.THERE_WAS_AN_ISSUE_PROCESSING_YOUR_REQUEST_PLEASE_TRY_AGAIN_LATER,
  ]);

  const handleSubmit = useCallback(
    async (values?: ITechnicalSupportForm) => {
      if (!executeRecaptcha) return;

      const token = await executeRecaptcha(ContactSupport);

      if (token) {
        contactSupport({ ...values, token } as ClientPublicService.ContactSupportFormDto, {
          onSuccess: showOnSuccessModal,
          onError: showOnErrorsModal,
        });
      }
    },
    [executeRecaptcha, contactSupport, showOnSuccessModal, showOnErrorsModal]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(getRequiredByLabel(t.NAME)),
        email: yup.string().email(t.EMAIL_ADDRESS_IS_INVALID).required(getRequiredByLabel(t.EMAIL)),
        phoneNumber: yup.string().required(getRequiredByLabel(t.PHONE_NUMBER)).matches(regexPhone, t.PHONE_NOT_VALID),
        inquiryType: yup.string().required(getRequiredByLabel(t.WHICH_BEST_DESCRIBES_YOUR_INQUIRY)),
        comments: yup.string().required(getRequiredByLabel(t.COMMENTS)),
      }),
    [
      getRequiredByLabel,
      t.NAME,
      t.EMAIL_ADDRESS_IS_INVALID,
      t.EMAIL,
      t.PHONE_NUMBER,
      t.PHONE_NOT_VALID,
      t.WHICH_BEST_DESCRIBES_YOUR_INQUIRY,
      t.COMMENTS,
    ]
  );

  return (
    <PageContentContainer
      headerProps={{
        title: t.NEED_TECHNICAL_SUPPORT,
        hasNotificationsIcon: false,
        hasBackButton: false,
      }}
    >
      <Typography variant="body2">{t.IF_YOU_NEED_TECHNICAL_SUPPORT_PLEASE_COMPLETE_THE_FORM_BELOW}</Typography>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phoneNumber: '',
          inquiryType: '',
          comments: '',
        }}
        enableReinitialize
        onSubmit={(_: ITechnicalSupportForm, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnBlur={true}
      >
        {({ values, submitForm, isValid }) => (
          <>
            <ElevatedContainer sx={{ borderRadius: 1.5, mt: 3, pt: 2, pb: 2, pl: 1.5, pr: 1.5 }}>
              <Stack>
                <TextField name="name" label={t.NAME} required />

                <TextField name="email" label={t.EMAIL} required />

                <PhoneField name="phoneNumber" label={t.PHONE_NUMBER} required />

                <SelectField
                  name="inquiryType"
                  label={t.WHICH_BEST_DESCRIBES_YOUR_INQUIRY}
                  required
                  options={inquiryTypes?.map((item) => ({ id: item, name: item }))}
                />

                <TextField name="comments" label={t.COMMENTS} data-testid="message" multiline minRows={3} required />
              </Stack>
            </ElevatedContainer>
            <Grid container justifyContent={isDesktop ? 'left' : 'center'} data-testid="button-container">
              <Grid item mobile={12} tablet={6} desktop={3}>
                <Button
                  variant="contained"
                  data-testid="ok-button"
                  fullWidth
                  onClick={async () => {
                    await submitForm();
                    if (isValid) handleSubmit(values);
                  }}
                  disabled={!isValid}
                  sx={{ mt: 2 }}
                >
                  {t.SUBMIT}
                </Button>
              </Grid>
            </Grid>
            <Box my={2}>
              <Typography variant="body2" mb={3} mt={5}>
                {t.CONTACT_SUPPORT_PAGE_PHONE_TITLE}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom={true}
                component={Link}
                href={'tel:1-844-254-1058'}
                underline={'always'}
              >
                <u>1-844-254-1058</u>
              </Typography>
              <Typography variant="body2" gutterBottom={true} sx={{ fontWeight: 'bold' }}>
                {t.CONTACT_SUPPORT_PAGE_PHONE_HOURS_TITLE}
              </Typography>
              <Typography variant="body2" gutterBottom={true}>
                {t.CONTACT_SUPPORT_PAGE_PHONE_HOURS}
              </Typography>
              <Typography variant="body2" gutterBottom={true}>
                {t.CONTACT_SUPPORT_PAGE_PHONE_DAYS}
              </Typography>
            </Box>
          </>
        )}
      </Formik>
    </PageContentContainer>
  );
};

export default TechnicalSupport;

const PhoneLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
