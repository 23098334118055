import { useCallback, useMemo } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import { Button, Divider, Stack, IconButton, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  SelectField,
  YesNoToggleField,
  TextField,
  DatePickerField,
  NumberField,
} from '../../../../../components/FormFields';
import { LocationFields } from '../../../../../components/FormFieldsPresets';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';
import { SupportDocsUpload } from '../../../../../components/DocumentsUpload';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormQuestionnaireValues } from '../types';
import { getBusinessQuestionnaire } from '../utils';
import { useBusinessTypesQuery, useBusinessOwnershipTypesQuery } from '../queries';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';
import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';

const Business = (props: { isJointFile: boolean | undefined }) => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  const { data: businessTypes, isLoading: isLoadingBusinessTypes } = useBusinessTypesQuery() || {};
  const { data: businessOwnershipTypes, isLoading: isLoadingBuisnessOwnershipTypes } =
    useBusinessOwnershipTypesQuery() || {};

  const newBusinessItem = useMemo(() => getBusinessQuestionnaire(), []);

  const handleHasBusinessToggle = useCallback(
    (hasOwnedBusinessPast5Years?: boolean) => {
      const noBusinessRecords = !values?.businesses || values?.businesses?.length === 0;
      if (hasOwnedBusinessPast5Years && noBusinessRecords) {
        setFieldValue('businesses', [newBusinessItem]);
      }
      if (!hasOwnedBusinessPast5Years) {
        setFieldValue('businesses', []);
      }
    },
    [newBusinessItem, setFieldValue, values?.businesses]
  );

  const handleHasSpouseBusinessToggle = useCallback(
    (hasSpouseOwnedBusinessPast5Years?: boolean) => {
      const noBusinessRecords = !values?.spouseBusinesses || values?.spouseBusinesses?.length === 0;
      if (hasSpouseOwnedBusinessPast5Years && noBusinessRecords) {
        setFieldValue('spouseBusinesses', [newBusinessItem]);
      }
      if (!hasSpouseOwnedBusinessPast5Years) {
        setFieldValue('spouseBusinesses', []);
      }
    },
    [newBusinessItem, setFieldValue, values?.spouseBusinesses]
  );

  const businessNames = useMemo(() => values?.businesses?.map((item) => item?.businessName), [values?.businesses]);

  const spouseBusinessNames = useMemo(
    () => values?.spouseBusinesses?.map((item) => item?.businessName),
    [values?.spouseBusinesses]
  );

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>

      <YesNoToggleField
        label={t.HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS}
        name="hasOwnedBusinessPast5Years"
        onChange={handleHasBusinessToggle}
        disabled={isLocked}
      />

      {values?.hasOwnedBusinessPast5Years && values?.businesses && values?.businesses?.length > 0 && (
        <>
          <FieldArray name="businesses">
            {({ remove, insert }: ArrayHelpers) => (
              <>
                <Divider sx={{ mt: 2, mb: 1 }} />
                {values?.businesses?.map((_, index: number) => {
                  const businessOwnershipEnum = businessOwnershipTypes?.find(
                    (item) => item?.id === values?.businesses?.[index]?.businessOwnershipTypeId
                  )?.enumValue;

                  return (
                    <Stack key={index}>
                      <TextField
                        name={`businesses.${index}.businessName`}
                        label={t.BUSINESS_NAME}
                        required
                        inputProps={{ maxLength: 500 }}
                        disabled={isLocked}
                      />

                      <SelectField
                        name={`businesses.${index}.businessOwnershipTypeId`}
                        label={t.TYPE_OF_OWNERSHIP}
                        options={businessOwnershipTypes}
                        required
                        loading={isLoadingBuisnessOwnershipTypes}
                        disabled={isLocked}
                      />

                      <SelectField
                        name={`businesses.${index}.businessTypeId`}
                        label={t.TYPE_OF_BUSINESS}
                        options={businessTypes}
                        required
                        loading={isLoadingBusinessTypes}
                        disabled={isLocked}
                      />

                      <LocationFields
                        presetType="short"
                        addressFieldName={`businesses.${index}.businessAddress`}
                        addressFieldLabel={t.BUSINESS_ADDRESS}
                        streetNameFieldName={`businesses.${index}.businessStreetNumber`}
                        streetNumberFieldName={`businesses.${index}.businessStreetNumber`}
                        unitFieldName={`businesses.${index}.businessUnit`}
                        unitFieldLabel={t.BUSINESS_ADDRESS_UNIT}
                        cityFieldName={`businesses.${index}.businessCity`}
                        provinceIdFieldName={`businesses.${index}.businessProvinceId`}
                        countryIdFieldName={`businesses.${index}.businessCountryId`}
                        postalCodeFieldName={`businesses.${index}.businessPostalCode`}
                        requiredFields={[`businesses.${index}.businessAddress`]}
                        disabledFields={isLocked ? 'all' : undefined}
                      />

                      <DatePickerField
                        name={`businesses.${index}.startDate`}
                        label={t.START_DATE}
                        required
                        disableFuture
                        format={DATE_FORMAT}
                        disabled={isLocked}
                      />

                      <DatePickerField
                        name={`businesses.${index}.dateOperationsCeased`}
                        label={t.DATE_OPERATIONS_CEASED}
                        disableFuture
                        format={DATE_FORMAT}
                        disabled={isLocked}
                      />

                      <NumberField
                        name={`businesses.${index}.maximumNumberOfEmployeesInThePast12Months`}
                        label={t.BUSINESS_NUMBER_OF_EMPLOYEES}
                        required
                        inputProps={{
                          allowNegative: false,
                          decimalScale: 0,
                        }}
                        disabled={isLocked}
                      />

                      {businessOwnershipEnum === ClientPublicService.BusinessOwnershipTypeEnum.Corporation && (
                        <YesNoToggleField
                          label={t.ARE_YOU_A_DIRECTOR}
                          name={`businesses.${index}.isDirector`}
                          required
                          disabled={isLocked}
                        />
                      )}

                      {businessOwnershipEnum === ClientPublicService.BusinessOwnershipTypeEnum.Corporation && (
                        <YesNoToggleField
                          label={t.IS_THE_CORPORATION_BANKRUPT}
                          name={`businesses.${index}.isCorporationBankrupt`}
                          required
                          disabled={isLocked}
                        />
                      )}

                      <YesNoToggleField
                        label={t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS}
                        name={`businesses.${index}.hasEmployeesOrSubContractors`}
                        required
                        disabled={isLocked}
                      />

                      <YesNoToggleField
                        label={t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES}
                        name={`businesses.${index}.isOwingWagesToEmployees`}
                        required
                        disabled={isLocked}
                      />

                      <YesNoToggleField
                        label={t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES}
                        name={`businesses.${index}.isOwingSourceDeductionOnWages`}
                        required
                        disabled={isLocked}
                      />

                      {businessOwnershipEnum !== ClientPublicService.BusinessOwnershipTypeEnum.SoleProprietorship && (
                        <TextField
                          label={t.NAME_OF_PARTNERS__DIRECTORS}
                          name={`businesses.${index}.nameOfPartnersOrDirectors`}
                          inputProps={{ maxLength: 500 }}
                          recommended
                          multiline
                          minRows={3}
                          maxRows={3}
                          disabled={isLocked}
                        />
                      )}

                      <TextField
                        label={t.OTHER_DETAILS}
                        name={`businesses.${index}.otherDetails`}
                        inputProps={{ maxLength: 1024 }}
                        multiline
                        minRows={3}
                        maxRows={3}
                        disabled={isLocked}
                      />

                      <IconButton
                        onClick={() => remove(index)}
                        disabled={values?.businesses?.length === 1 || isLocked}
                        sx={{ mt: 2, alignSelf: 'start' }}
                        color="error"
                        aria-label="Delete"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                    </Stack>
                  );
                })}

                <Button
                  variant="text"
                  onClick={() => insert(values?.businesses?.length || 0, newBusinessItem)}
                  disabled={isLocked}
                >
                  {t.ADD_BUSINESS}
                </Button>
              </>
            )}
          </FieldArray>
        </>
      )}

      {props.isJointFile && (
        <Box data-testid="spouse-business-fields">
          <Divider sx={{ mt: 2, mb: 2 }} />

          <AppFormSectionSubtitle>{t.SPOUSE_PARTNER}</AppFormSectionSubtitle>

          <YesNoToggleField
            label={t.HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS}
            name="hasSpouseOwnedBusinessPast5Years"
            onChange={handleHasSpouseBusinessToggle}
            disabled={isLocked}
          />

          {values?.hasSpouseOwnedBusinessPast5Years &&
            values?.spouseBusinesses &&
            values?.spouseBusinesses?.length > 0 && (
              <>
                <FieldArray name="spouseBusinesses">
                  {({ remove, insert }: ArrayHelpers) => (
                    <>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                      {values?.spouseBusinesses?.map((_, index: number) => {
                        const businessOwnershipEnum = businessOwnershipTypes?.find(
                          (item) => item?.id === values?.spouseBusinesses?.[index]?.businessOwnershipTypeId
                        )?.enumValue;

                        return (
                          <Stack key={index}>
                            <TextField
                              name={`spouseBusinesses.${index}.businessName`}
                              label={t.BUSINESS_NAME}
                              required={props.isJointFile}
                              inputProps={{ maxLength: 500 }}
                              disabled={isLocked}
                            />

                            <SelectField
                              name={`spouseBusinesses.${index}.businessOwnershipTypeId`}
                              label={t.TYPE_OF_OWNERSHIP}
                              required={props.isJointFile}
                              options={businessOwnershipTypes}
                              loading={isLoadingBuisnessOwnershipTypes}
                              disabled={isLocked}
                            />

                            <SelectField
                              name={`spouseBusinesses.${index}.businessTypeId`}
                              label={t.TYPE_OF_BUSINESS}
                              required={props.isJointFile}
                              options={businessTypes}
                              loading={isLoadingBusinessTypes}
                              disabled={isLocked}
                            />

                            <LocationFields
                              presetType="short"
                              addressFieldName={`spouseBusinesses.${index}.businessAddress`}
                              addressFieldLabel={t.BUSINESS_ADDRESS}
                              streetNameFieldName={`spouseBusinesses.${index}.businessStreetNumber`}
                              streetNumberFieldName={`spouseBusinesses.${index}.businessStreetNumber`}
                              unitFieldName={`spouseBusinesses.${index}.businessUnit`}
                              unitFieldLabel={t.BUSINESS_ADDRESS_UNIT}
                              cityFieldName={`spouseBusinesses.${index}.businessCity`}
                              provinceIdFieldName={`spouseBusinesses.${index}.businessProvinceId`}
                              countryIdFieldName={`spouseBusinesses.${index}.businessCountryId`}
                              postalCodeFieldName={`spouseBusinesses.${index}.businessPostalCode`}
                              requiredFields={props.isJointFile ? [`spouseBusinesses.${index}.businessAddress`] : []}
                              disabledFields={isLocked ? 'all' : undefined}
                            />

                            <DatePickerField
                              name={`spouseBusinesses.${index}.startDate`}
                              label={t.START_DATE}
                              disableFuture
                              format={DATE_FORMAT}
                              disabled={isLocked}
                              required={props.isJointFile}
                            />

                            <DatePickerField
                              name={`spouseBusinesses.${index}.dateOperationsCeased`}
                              label={t.DATE_OPERATIONS_CEASED}
                              disableFuture
                              format={DATE_FORMAT}
                              disabled={isLocked}
                            />

                            <NumberField
                              name={`spouseBusinesses.${index}.maximumNumberOfEmployeesInThePast12Months`}
                              label={t.BUSINESS_NUMBER_OF_EMPLOYEES}
                              required={props.isJointFile}
                              inputProps={{
                                allowNegative: false,
                                decimalScale: 0,
                              }}
                              disabled={isLocked}
                            />

                            {businessOwnershipEnum === ClientPublicService.BusinessOwnershipTypeEnum.Corporation && (
                              <YesNoToggleField
                                label={t.ARE_YOU_A_DIRECTOR}
                                name={`spouseBusinesses.${index}.isDirector`}
                                disabled={isLocked}
                                required={props.isJointFile}
                              />
                            )}

                            {businessOwnershipEnum === ClientPublicService.BusinessOwnershipTypeEnum.Corporation && (
                              <YesNoToggleField
                                label={t.IS_THE_CORPORATION_BANKRUPT}
                                name={`spouseBusinesses.${index}.isCorporationBankrupt`}
                                disabled={isLocked}
                                required={props.isJointFile}
                              />
                            )}

                            <YesNoToggleField
                              label={t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS}
                              name={`spouseBusinesses.${index}.hasEmployeesOrSubContractors`}
                              disabled={isLocked}
                              required={props.isJointFile}
                            />

                            <YesNoToggleField
                              label={t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES}
                              name={`spouseBusinesses.${index}.isOwingWagesToEmployees`}
                              disabled={isLocked}
                              required={props.isJointFile}
                            />

                            <YesNoToggleField
                              label={t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES}
                              name={`spouseBusinesses.${index}.isOwingSourceDeductionOnWages`}
                              disabled={isLocked}
                              required={props.isJointFile}
                            />

                            {businessOwnershipEnum !==
                              ClientPublicService.BusinessOwnershipTypeEnum.SoleProprietorship && (
                              <TextField
                                label={t.NAME_OF_PARTNERS__DIRECTORS}
                                name={`spouseBusinesses.${index}.nameOfPartnersOrDirectors`}
                                inputProps={{ maxLength: 500 }}
                                multiline
                                minRows={3}
                                maxRows={3}
                                disabled={isLocked}
                                recommended
                              />
                            )}

                            <TextField
                              label={t.OTHER_DETAILS}
                              name={`spouseBusinesses.${index}.otherDetails`}
                              inputProps={{ maxLength: 1024 }}
                              multiline
                              minRows={3}
                              maxRows={3}
                              disabled={isLocked}
                            />

                            <IconButton
                              onClick={() => remove(index)}
                              disabled={values?.spouseBusinesses?.length === 1 || isLocked}
                              sx={{ mt: 2, alignSelf: 'start' }}
                              color="error"
                              aria-label="Delete"
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                            <Divider sx={{ mt: 2, mb: 1 }} />
                          </Stack>
                        );
                      })}

                      <Button
                        variant="text"
                        onClick={() => insert(values?.spouseBusinesses?.length || 0, newBusinessItem)}
                        disabled={isLocked}
                      >
                        {t.ADD_BUSINESS}
                      </Button>
                    </>
                  )}
                </FieldArray>
              </>
            )}
        </Box>
      )}
      {((values?.businesses?.length ?? 0) > 0 || (values?.spouseBusinesses?.length ?? 0) > 0) && (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <SupportDocsUpload
            documentFolderCode="surplus-ie"
            documentPrefixCodes={['Business']}
            businessNames={[...(businessNames || []), ...(spouseBusinessNames || [])]}
            disabled={isLocked}
            onlyRetrieveByDocumentIds={true}
            documentIds={values?.documentIds}
          />
        </>
      )}
    </>
  );
};

export default Business;
