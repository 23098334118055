import { ToggleButton, styled, ToggleButtonProps } from '@mui/material';

const CustomToggleButton = (props: ToggleButtonProps) => (
  <StyledToggleButton {...props} size="small" classes={{ disabled: 'disabled-btn' }} />
);

export default CustomToggleButton;

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'initial',
  fontSize: 18,
  fontWeight: 600,
  borderRadius: 0,
  borderColor: theme.palette.red,
  color: theme.palette.red,
  backgroundColor: theme.palette.white,

  '&.Mui-selected:not(.Mui-disabled)': {
    backgroundColor: theme.palette.red,
    color: theme.palette.white,
  },
  '&.disabled-btn': {
    backgroundColor: theme.palette.grey,
    color: theme.palette.primary.main,
  },
}));
