export const USE_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

//collections
export const MEETING_TYPES_QUERY_KEY = 'meeting-types';
export const PHONE_TYPES_QUERY_KEY = 'phone-types';
export const APPOINTEMENT_TYPES_QUERY_KEY = 'appointment-types';
export const REFERRAL_SOURCES_QUERY_KEY = 'referral-sources';
export const APPOINTMENT_STATUSES_QUERY_KEY = 'appointment-statuses';
export const PROVINCES_QUERY_KEY = 'provinces';
export const COUNTRIES_QUERY_KEY = 'countries';
export const AGENTS_QUERY_KEY = 'agents';
export const CANADA_PROVINCES_QUERY_KEY = 'canada-provinces';
export const USA_PROVINCES_QUERY_KEY = 'usa-provinces';

//google maps
export const GOOGLE_API_QUERY_KEY = 'google-api';

//debtor
export const DEBTOR_INFORMATION_QUERY_KEY = 'debtor-information';
export const DEBTOR_SETTINGS_QUERY_KEY = 'debtor-settings';
export const DEBTOR_PROFILE_QUERY_KEY = 'debtor-profile';

//offices
export const OFFICE_LOCATIONS_QUERY_KEY = 'office-locations';

//meetings
export const TIMESLOTS_QUERY_KEY = 'timeslots';
export const INITIAL_TIMESLOTS_QUERY_KEY = 'initial-timeslots';

//appointments
export const APPOINTEMENT_CREATE_MUTATION_KEY = 'appointment-create';
export const APPOINTMENT_GET_QUERY_KEY = 'appointment-get';
export const APPOINTMENTS_GET_QUERY_KEY = 'appointments-get';
export const COMPLETED_INITIAL_CONSULTATIONS_COUNT_QUERY_KEY = 'completed-intial-consultations-count';
export const UPCOMING_APPOINTMENTS_GET_QUERY_KEY = 'upcoming-appointments-get';
export const PREVIOUS_APPOINTMENTS_GET_QUERY_KEY = 'previous-appointments-get';
export const COUNSELLING_SESSIONS_COUNT = 'counselling-sessions-count';

//documents
export const DOCUMENTS_QUERY_KEY = 'documents-get';
export const DOCUMENT_GET_QUERY_KEY = 'document-get';
export const SUPPORTING_DOCUMENTS_QUERY_KEY = 'supporting-documents';
export const SUPPORTING_DOCUMENTS_COUNT_QUERY_KEY = 'supporting-documents-count';
export const DOCUMENTS_TYPES_QUERY_KEY = 'document-types';
export const DOCUMENT_DOWNLOAD_QUERY_KEY = 'document-download';
export const FOLDERS_GET_QUERY_KEY = 'folders-get';
export const PREFIXES_GET_QUERY_KEY = 'prefixes-get';
export const APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY = 'app-form-supporting-documents';

//agents
export const FILTER_USERS_QUERY_KEY = 'filter-users';

//app-form
export const APP_FORM_STATUS_QUERY_KEY = 'app-form-status';
export const APP_FORM_BANKING_INFO_QUERY_KEY = 'app-form-banking-info';

//assets-debts
export const ASSETS_GET_QUERY_KEY = 'assets';
export const ASSET_BY_ID_GET_QUERY_KEY = 'asset-by-id';
export const DEBTS_GET_QUERY_KEY = 'debts';
export const DEBT_BY_ID_GET_QUERY_KEY = 'debt-by-id';
export const ASSETS_CONFIG_QUERY_KEY = 'assets-config';
export const ASSETS_TYPES_QUERY_KEY = 'assets-types';
export const ASSETS_DEBTS_OWNERSHIP_TYPES_QUERY_KEY = 'assets-debts-ownership-types';
export const ASSETS_DESCRIPTIONS_QUERY_KEY = 'assets-descriptions';
export const DEBTS_DESCRIPTIONS_QUERY_KEY = 'debts-descriptions';
export const LIST_PREFIXES_FOR_ASSETS = 'list-prefixes-for-assets';

// personal info
export const PERSONAL_INFO_QUERY_KEY = 'personal-info';
export const MARITAL_STATUSES_QUERY_KEY = 'marital-statuses';
export const EDUCATION_LEVELS_QUERY_KEY = 'education-levels';
export const EMPLOYMENT_STATUSES_QUERY_KEY = 'employment-statuses';
export const INDUSTRIES_QUERY_KEY = 'industries';
export const RELATIONSHIPS_QUERY_KEY = 'relationships';
export const INSOLVENCY_TYPES_QUERY_KEY = 'insolvency-types';
export const PROPOSAL_RESULTS_QUERY_KEY = 'proposal-results';

// income tax
export const INCOME_TAX_QUERY_KEY = 'income-tax';

// questionnaire
export const QUESTIONNAIRE_QUERY_KEY = 'questionnaire';
export const BUSINESS_TYPES_QUERY_KEY = 'business-types';
export const BUSINESS_OWNERSHIP_TYPES_QUERY_KEY = 'business-ownership-types';

// app file
export const APP_FILE_QUERY_KEY = 'app-file';
export const RECAPTCHA_TOKEN_VALIDATION_MUTATION = 'validate-recaptcha-token';

// incomes-expense
export const LIST_INCOMES_QUERY_KEY = 'list-incomes';
export const LIST_EXPENSES_QUERY_KEY = 'list-expenses';
export const APP_FORM_INCOMES_QUERY_KEY = 'app-form-incomes';
export const APP_FORM_EXPENSES_QUERY_KEY = 'app-form-expenses';
export const LIST_INCOME_TYPES_QUERY_KEY = 'list-income-types';
export const LIST_EXPENSE_TYPES_QUERY_KEY = 'list-expense-types';
export const LIST_EXPENSE_DESCRIPTIONS_QUERY_KEY = 'list-expense-descriptions';
export const LIST_ALL_EXPENSE_DESCRIPTIONS_QUERY_KEY = 'list-all-expense-descriptions';

// app file
export const APPLICATION_FILE_QUERY_KEY = 'application-file';

export const PENDING_FOLLOWUP_QUERY_KEY = 'pending-follow-up';

// admin info
export const SIGNUP_STATUS_QUERY_KEY = 'admininfo-signup-status';

// payments and banking
export const DISCHARGE_QUERY_KEY = 'discharge';
export const DEBIT_INFORMATION_QUERY_KEY = 'debit-information';
export const PAYMENTS_INFORMATION_QUERY_KEY = 'payments-information';
export const BALANCE_QUERY_KEY = 'balance';
export const PAST_PAYMENTS_COUNT_QUERY_KEY = 'past-payments-count';
export const OUTSTANDING_PAYMENTS_COUNT_QUERY_KEY = 'outstanding-payments-count';
export const UPCOMING_PAYMENTS_COUNT_QUERY_KEY = 'upcoming-payments-count';
export const PAYMENT_DETAILS_QUERY_KEY = 'payment-details';
export const RECEIPTS_BY_PAYMENT_ID_QUERY_KEY = 'receipts-by-payment-id';
export const NEXT_PAYMENT_QUERY = 'next-payment';

// contact staff
export const CPC_TEAM_QUERY_KEY = 'getall_cpc_teams';

// income expense reports
export const IE_REPORTS_QUERY_KEY = 'ie-reports';
export const PENDING_IE_REPORTS_QUERY_KEY = 'pending-ie-reports';
export const COMPLETED_IE_REPORTS_QUERY_KEY = 'completed-ie-reports';
export const PENDING_COUNT_IE_REPORTS_QUERY_KEY = 'pending-count-ie-reports';
export const COMPLETED_COUNT_IE_REPORTS_QUERY_KEY = 'completed-count-ie-reports';
export const ACTIVE_SUBMISSION_QUERY_KEY = 'active-submission';
export const PREVIOUS_ACTIVE_SUBMISSION_QUERY_KEY = 'previous-active-submission';
export const PERIOD_STATUS_QUERY_KEY = 'period-status';
export const IE_REPORT_BY_ID_QUERY_KEY = 'ie-report-by-id';
export const EARLIEST_OUTSTANDING_REPORT_QUERY_KEY = 'earliest-outstanding-report';

// notifications
export const NEW_NOTIFICATIONS_QUERY_KEY = 'new-notifications';
export const NEW_NOTIFICATIONS_EXIST_QUERY_KEY = 'new-notifications-exist';
