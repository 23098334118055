import { Stack, IconButton, useTheme } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { currencyFormatter } from '../app/utils/helpers';

interface IAmountCellWithDeleteActionProps {
  value?: number;
  onDelete?: () => void;
  disabled?: boolean;
}

const AmountCellWithDeleteAction = ({ value, onDelete, disabled }: IAmountCellWithDeleteActionProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" flex={0}>
      <>{currencyFormatter(value, { maximumFractionDigits: 2 })}</>
      <IconButton
        onClick={(e) => {
          e?.stopPropagation();
          onDelete?.();
        }}
        sx={{ p: 0, color: theme.palette.urgentRed }}
        disabled={disabled}
        aria-label="Delete"
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Stack>
  );
};

export default AmountCellWithDeleteAction;
