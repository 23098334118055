import { useMemo, useCallback } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import {
  Box,
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  Link,
  styled,
  useTheme,
  DrawerProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LanguageMenu from './LanguageMenu';
import { IAppDrawerProps } from '../AppDrawer';
import UnsavedAppFormChangesModal from '../../../features/AppForm/components/UnsavedAppFormChangesModal';
import SignOutButton from './SignOutButton';

import FeatureFlag, { FEATUREFLAG_LOGIN_BUTTON } from '../../FeatureFlag/FeatureFlag';

import useLocale from '../../../app/hooks/useLocale';
import useAuthService from '../../../app/hooks/useAuthService';
import useModal from '../../../app/hooks/useModal';
import { ILocale } from '../../../app/providers/LocaleProvider/types';

import { drawerWidth } from '../constants';
import { ROUTES } from '../../../app/routes';
import { APPFORM_SAVE_AND_LEAVE_EVENT, CHECK_IF_UNSAVED_CHANGES_EVENT } from '../../../app/constants/eventBusKeys';
import eventBus from '../../../app/utils/eventBus';

const websiteUrl = process.env.REACT_APP_BDO_WEBSITE_URL;

interface IAppMenuProps extends DrawerProps, IAppDrawerProps {
  isPreSignupStage?: boolean;
}

interface IMenuItemDataProps {
  title?: string;
  url?: string;
  href?: string;
  hidden?: boolean;
}

export default function AppMenu({
  window,
  menuOpen,
  handleMenuToggle,
  isAuthenticated,
  isPreSignupStage,
  ...props
}: IAppMenuProps) {
  const { t, locale } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signin } = useAuthService();
  const { showModal, closeModal } = useModal();

  const handleNavigate = useCallback(
    (item?: IMenuItemDataProps) => {
      if (item?.url) {
        navigate(item?.url);
      }
    },
    [navigate]
  );

  const isTrackChangesAppFormRoute = useMemo(
    () =>
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.PERSONAL_INFORMATION}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.INCOME_TAX}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.BANKING}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.QUESTIONNAIRE}`, location?.pathname)),

    [location?.pathname]
  );

  const handleMenuItemClick = useCallback(
    (item?: IMenuItemDataProps) => {
      if (isTrackChangesAppFormRoute) {
        eventBus.dispatch(CHECK_IF_UNSAVED_CHANGES_EVENT, {
          onCheckSuccess: (isChangesUnsaved?: boolean) => {
            if (isChangesUnsaved) {
              showModal(
                <UnsavedAppFormChangesModal
                  onLeave={() => {
                    handleNavigate(item);
                    closeModal();
                  }}
                  onSaveAndLeave={() => {
                    eventBus.dispatch(APPFORM_SAVE_AND_LEAVE_EVENT, {
                      onSaveSuccess: () => {
                        handleNavigate(item);
                        closeModal();
                      },
                    });
                  }}
                />
              );
            } else {
              handleNavigate(item);
            }
          },
        });
      } else {
        handleNavigate(item);
      }
    },
    [closeModal, handleNavigate, isTrackChangesAppFormRoute, showModal]
  );

  const authenticatedUserMenu: IMenuItemDataProps[] = useMemo(
    () =>
      (
        [
          {
            title: t.DASHBOARD,
            url: ROUTES.DASHBOARD,
          },
          {
            title: t.APPLICATION_FORM,
            url: ROUTES.APP_FORM,
            hidden: isPreSignupStage === false || isPreSignupStage === undefined,
          },
          {
            title: t.PAYMENTS_AND_BANKING,
            url: ROUTES.PAYMENTS_AND_BANKING,
            hidden: isPreSignupStage === true || isPreSignupStage === undefined,
          },
          {
            title: t.APPOINTMENTS,
            url: ROUTES.APPOINTMENTS,
          },
          {
            title: t.DOCUMENTS,
            url: ROUTES.DOCUMENTS,
          },
          {
            title: t.CONTACT_STAFF,
            url: ROUTES.CONTACT_STAFF,
          },
          {
            title: t.MY_PROFILE,
            url: ROUTES.PROFILE,
          },
          {
            title: t.TECHNICAL_SUPPORT,
            url: ROUTES.CONTACT_SUPPORT,
          },
        ] as IMenuItemDataProps[]
      )?.filter((item) => !item?.hidden),
    [
      isPreSignupStage,
      t.APPLICATION_FORM,
      t.APPOINTMENTS,
      t.CONTACT_STAFF,
      t.DASHBOARD,
      t.DOCUMENTS,
      t.MY_PROFILE,
      t.TECHNICAL_SUPPORT,
      t.PAYMENTS_AND_BANKING,
    ]
  );

  const isFrench = useMemo(() => locale === ILocale.fr, [locale]);

  const unauthenticatedUserMenu: IMenuItemDataProps[] = useMemo(() => {
    const unauthenticatedMenuItemsArray: IMenuItemDataProps[] = [
      {
        title: t.HOW_WE_HELP,
        href: isFrench ? `${websiteUrl}/fr-ca/comment-nous-aidons/` : `${websiteUrl}/how-we-help/`,
      },
      {
        title: t.SOLUTIONS,
        href: isFrench ? `${websiteUrl}/fr-ca/solutions/` : `${websiteUrl}/solutions/`,
      },
      {
        title: t.TOOLS,
        href: isFrench ? `${websiteUrl}/fr-ca/outils/` : `${websiteUrl}/tools/`,
      },
      {
        title: t.ADVICE,
        href: isFrench ? `${websiteUrl}/fr-ca/conseils/` : `${websiteUrl}/advice/`,
      },
      {
        title: t.LOCATIONS,
        href: isFrench ? `${websiteUrl}/fr-ca/emplacements/` : `${websiteUrl}/locations`,
      },
    ];

    unauthenticatedMenuItemsArray?.push({
      title: t.TECHNICAL_SUPPORT,
      url: ROUTES.CONTACT_SUPPORT,
    });

    return unauthenticatedMenuItemsArray;
  }, [isFrench, t.ADVICE, t.HOW_WE_HELP, t.LOCATIONS, t.SOLUTIONS, t.TECHNICAL_SUPPORT, t.TOOLS]);

  const drawer = useMemo(
    () => (
      <DrawerContainer>
        <Box>
          <MobileToolbar>
            <IconButton onClick={handleMenuToggle} edge="end" data-testid="close-button" aria-label="close menu button">
              <CloseIcon style={{ color: theme.palette.white }} />
            </IconButton>
          </MobileToolbar>

          <DrawerMenuList data-testid="menu-items-list">
            {/* {isAuthenticated
              ? authenticatedUserMenu?.map((item, index) => (
                  <ListItem key={`menuitem-${index}`} sx={{ pl: 0, pr: 0 }}>
                    <ListItemButton
                      onClick={() => {
                        handleMenuItemClick(item);
                        handleMenuToggle?.();
                      }}
                    >
                      <AppMenuItemText>{item?.title}</AppMenuItemText>
                    </ListItemButton>
                  </ListItem>
                )) */}

            {(isAuthenticated ? authenticatedUserMenu : unauthenticatedUserMenu)?.map((item, index) => (
              <ListItem key={`menuitem-${index}`} sx={{ justifyContent: 'flex-end', p: isAuthenticated ? 1 : 2 }}>
                {item?.href ? (
                  <Link href={item.href} target="_blank" rel="noopener">
                    <AppMenuItemText>{item?.title}</AppMenuItemText>
                  </Link>
                ) : (
                  <ListItemButton
                    onClick={() => {
                      handleMenuItemClick(item);
                      handleMenuToggle?.();
                    }}
                  >
                    <AppMenuItemText>{item?.title}</AppMenuItemText>
                  </ListItemButton>
                )}
              </ListItem>
            ))}

            <LanguageMenu handleMenuToggle={handleMenuToggle} />
          </DrawerMenuList>
        </Box>

        {/* TODO: Delete this code block after the login redirectis implemented. */}
        <FeatureFlag flag={FEATUREFLAG_LOGIN_BUTTON}>
          {!isAuthenticated && (
            <Box color={theme.palette.error.main} padding="24px" textAlign="end">
              <Link
                component="button"
                variant="h6"
                underline="none"
                style={{ color: theme.palette.error.main }}
                onClick={() => {
                  signin();
                  handleMenuToggle?.();
                }}
              >
                {t.SIGN_IN}
              </Link>
            </Box>
          )}
        </FeatureFlag>

        {isAuthenticated && (
          <Box sx={{ pl: 1, pr: 1, pb: 2 }}>
            <SignOutButton />
          </Box>
        )}
      </DrawerContainer>
    ),
    [
      authenticatedUserMenu,
      handleMenuItemClick,
      handleMenuToggle,
      isAuthenticated,
      signin,
      t.SIGN_IN,
      theme.palette.error.main,
      theme.palette.white,
      unauthenticatedUserMenu,
    ]
  );

  const container = window !== undefined ? () => window()?.document?.body : undefined;

  return (
    <Drawer
      {...props}
      container={container}
      variant="temporary"
      data-testid="app-menu"
      open={menuOpen}
      onClose={handleMenuToggle}
      anchor="right"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { mobile: 'block', desktop: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
  );
}

const DrawerContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  justifyContent: 'end',
}));

const DrawerMenuList = styled(List)`
  padding-top: 24px;
`;

const AppMenuItemText = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: 20,
    textAlign: 'end',
    fontWeight: 600,
    lineHeight: 1,
  },
}));
