import { useCallback, useMemo, useEffect, useContext } from 'react';
import { Typography, Grid, List, ListItem, Link, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';

import { IAppFormAccordionProps } from './AppFormAccordion';
import { AccordionContext } from './AccordionProvider';
import useLocale from '../../../../app/hooks/useLocale';

import { getAllObjectProps } from '../../../../app/utils/helpers';

interface IErrorListPanelProps extends IAppFormAccordionProps {
  ref: React.RefObject<HTMLDivElement>;
}

interface IErrorListProps {
  panels: IErrorListPanelProps[];
}

const ErrorsList = ({ panels }: IErrorListProps) => {
  const theme = useTheme();
  const { t, locale } = useLocale();
  const { errors, touched, getFieldMeta, submitCount, submitForm } = useFormikContext<any>();
  const { setExpanded, showErrorsList } = useContext(AccordionContext);

  const handleGoToError = useCallback(
    (panel?: IErrorListPanelProps) => {
      setExpanded(panel?.id as string);

      setTimeout(() => {
        panel?.ref?.current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start',
        });
      }, 500);
    },
    [setExpanded]
  );

  const propsTouched = useMemo(() => getAllObjectProps(touched), [touched]);

  const propsWithErrors = useMemo(
    () => getAllObjectProps(errors)?.filter((item) => propsTouched.indexOf(item) > 0),
    [errors, propsTouched]
  );

  useEffect(() => {
    if (submitCount === 0 && showErrorsList) {
      submitForm();
    }
  }, [showErrorsList, submitCount, submitForm]);

  if (!propsWithErrors || !propsWithErrors?.length) return <></>;

  return (
    <List
      sx={{
        backgroundColor: `${theme.palette.error.main}10`,
        mb: 2.5,
      }}
    >
      {propsWithErrors?.map((item, index) => {
        const { error } = getFieldMeta(item);

        if (typeof error !== 'string') return null;

        const validatedProp = item?.split('.')[0];
        const panel = panels?.find((p) => p.validatedProps?.includes(validatedProp));

        const isRecommended = error?.includes('[recommended]');
        const ErrorItemIcon = isRecommended ? ErrorIcon : CancelIcon;
        const color = isRecommended ? theme.palette.warningOrange : theme.palette.urgentRed;

        return (
          <ListItem key={String(index) + String(locale)} alignItems="flex-start" dense>
            <Grid container direction="row" columnSpacing={1} wrap="nowrap">
              <Grid item mobile="auto">
                <ErrorItemIcon style={{ fontSize: 16, color, marginTop: 6 }} />
              </Grid>
              <Grid item mobile="auto">
                <Link
                  component="button"
                  onClick={() => handleGoToError(panel)}
                  sx={{
                    '&.MuiLink-root': {
                      color: theme.palette.urgentRed,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: 12,
                    },
                  }}
                >
                  {isRecommended ? t.GO_TO_WARNING : t.GO_TO_ERROR}
                </Link>
              </Grid>
              <Grid item mobile>
                <Typography variant="body4">{error?.replace('[recommended]', '')}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ErrorsList;
