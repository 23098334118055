import { useQuery, useMutation } from '@tanstack/react-query';

import {
  APPLICATION_FILE_QUERY_KEY,
  DEBIT_INFORMATION_QUERY_KEY,
  PAYMENTS_INFORMATION_QUERY_KEY,
  OUTSTANDING_PAYMENTS_COUNT_QUERY_KEY,
  PAYMENT_DETAILS_QUERY_KEY,
  RECEIPTS_BY_PAYMENT_ID_QUERY_KEY,
  UPCOMING_PAYMENTS_COUNT_QUERY_KEY,
  BALANCE_QUERY_KEY,
  PAST_PAYMENTS_COUNT_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';

import { argumentifyPaymentsSearchParams, argumentifyCountPaymentsSearchParams } from './utils';
import { IPaymentsSearchParams, IPaymentUpdateParams } from './types';
import moment, { inputDateToServerFormat } from '../../app/utils/dateTimeHelpers';

import { queryClient } from '../../app/config';
import { BaseService } from '../../app/api/BaseService';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';
import { ILocale } from '../../app/providers/LocaleProvider/types';

const PAD_TYPE = 'PAD';
const PAP_TYPE = 'PAP';

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });

export const useDebitInformationQuery = (fileId?: string) =>
  useQuery({
    queryKey: [DEBIT_INFORMATION_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.padInfo(fileId as string);
      return data;
    },
    enabled: !!fileId,
  });

export const usePastPaymentsCountQuery = (fileId?: string) =>
  useQuery({
    queryKey: [PAST_PAYMENTS_COUNT_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.count(
        ...argumentifyCountPaymentsSearchParams({
          fileId,
          receiptDateTo: inputDateToServerFormat(moment()),
          maxResultCount: 1000,
        } as IPaymentsSearchParams)
      );
      return data;
    },
    enabled: !!fileId,
  });

export const useOutstadingPaymentsCountQuery = (fileId?: string) =>
  useQuery({
    queryKey: [OUTSTANDING_PAYMENTS_COUNT_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.count(
        ...argumentifyCountPaymentsSearchParams({
          fileId,
          isDeposited: false,
          receiptDateTo: inputDateToServerFormat(moment()),
          maxResultCount: 1000,
        } as IPaymentsSearchParams)
      );
      return data;
    },
    enabled: !!fileId,
  });

export const useUpcomingPaymentsCountQuery = (fileId?: string) =>
  useQuery({
    queryKey: [UPCOMING_PAYMENTS_COUNT_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.count(
        ...argumentifyCountPaymentsSearchParams({
          fileId,
          receiptDateFrom: inputDateToServerFormat(moment()),
          maxResultCount: 1000,
        } as IPaymentsSearchParams)
      );
      return data;
    },
    enabled: !!fileId,
  });

export const usePaymentsInformationQuery = (requestParams?: IPaymentsSearchParams) =>
  useQuery({
    queryKey: [
      PAYMENTS_INFORMATION_QUERY_KEY,
      ...argumentifyPaymentsSearchParams(requestParams as IPaymentsSearchParams),
    ],
    queryFn: async () => {
      const data = await API.padScheduleAll(
        ...argumentifyPaymentsSearchParams({
          ...requestParams,
          maxResultCount: 1000,
          sorting: requestParams?.receiptDateFrom ? 'dueDate asc' : 'dueDate desc',
        } as IPaymentsSearchParams)
      );
      return data?.filter((item) => item?.type === PAP_TYPE || item?.type === PAD_TYPE);
    },
    enabled: !!requestParams?.fileId && (!!requestParams?.receiptDateFrom || !!requestParams?.receiptDateTo),
  });

export const useBalanceQuery = () =>
  useQuery({
    queryKey: [BALANCE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.balanceInfo();

      return data;
    },
  });

export const usePaymentDetailsQuery = (paymentId?: string, fileId?: string) =>
  useQuery({
    queryKey: [PAYMENT_DETAILS_QUERY_KEY, paymentId, fileId],
    queryFn: async () => {
      const data = await API.padScheduleGET(paymentId as string, fileId as string);
      return data;
    },
    enabled: !!paymentId && !!fileId,
  });

export const useUpdatePaymentDetailsMutation = () =>
  useMutation({
    mutationFn: ({ fileId, ...body }: IPaymentUpdateParams) =>
      API.padSchedulePUT(fileId, body as ClientPublicService.PADScheduleUpdatePublicDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PAYMENTS_INFORMATION_QUERY_KEY] }),
  });

export const useUpdateDebitInformationMutation = () =>
  useMutation({
    mutationFn: ({ fileId, ...body }: IPaymentUpdateParams) =>
      API.padInfoChangeRequest(fileId, body as ClientPublicService.PADInfoChangeRequestCreatePublicDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DEBIT_INFORMATION_QUERY_KEY] }),
  });

export const usePaymentReceiptsQuery = (paymentId?: string) =>
  useQuery({
    queryKey: [RECEIPTS_BY_PAYMENT_ID_QUERY_KEY, paymentId],
    queryFn: async () => {
      const data = await API.getAll(paymentId as string);
      return data;
    },
    enabled: !!paymentId,
  });

export const useReceiptDownloadBlobMutation = () =>
  useMutation({
    mutationFn: async ({ receiptId, locale }: { receiptId: string; locale: ILocale }) => {
      const token = new BaseService().getAccessTokenHeader();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/client-service-public/receipts/${receiptId}/download`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
            'accept-language': locale === ILocale.fr ? ILocale.fr : ILocale.en,
          }),
        }
      );
      return response.blob();
    },
  });
