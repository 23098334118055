import { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Toolbar, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AppDrawer, { drawerWidth } from './AppDrawer';
import Footer from './Footer';

import useLocale from '../app/hooks/useLocale';
import { ILocale } from '../app/providers/LocaleProvider/types';

import SiteBar from './SiteBar/SiteBar';
import useAuthService from '../app/hooks/useAuthService';
import { UTM_PARAMS_SESSION_STORAGE_KEY } from '../app/constants/common';

const Layout = ({
  children,
}: {
  children?: React.ReactElement | Array<React.ReactElement> | string | boolean | any;
}) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated } = useAuthService();
  const { locale } = useLocale();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (location.search && !isAuthenticated() && !sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_KEY)) {
      sessionStorage.setItem(UTM_PARAMS_SESSION_STORAGE_KEY, document?.referrer + (location.search || ''));
    }
  }, [isAuthenticated, location.search]);

  useEffect(() => {
    if (isAuthenticated() && sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_KEY)) {
      sessionStorage.removeItem(UTM_PARAMS_SESSION_STORAGE_KEY);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.key]);

  return (
    <LocalizationProvider adapterLocale={locale === ILocale.en ? 'en-ca' : 'fr-ca'} dateAdapter={AdapterMoment}>
      <Wrapper>
        <CssBaseline />
        <SiteBar handleMenuToggle={handleMenuToggle} />
        <AppDrawer menuOpen={menuOpen} handleMenuToggle={handleMenuToggle} isAuthenticated={isAuthenticated()} />
        <Container
          component="main"
          sx={{
            width: { mobile: '100%', desktop: isAuthenticated() ? `calc(100% - ${drawerWidth}px)` : '100%' },
            p: 0,
          }}
        >
          <Container
            sx={{
              minHeight: '100vh',
              flexDirection: 'column',
              display: 'flex',
              p: 0,
            }}
          >
            <Toolbar sx={{ display: { desktop: isAuthenticated() ? 'none' : 'block' } }} />
            {children}
          </Container>
          {!isAuthenticated() && <Footer />}
        </Container>
      </Wrapper>
    </LocalizationProvider>
  );
};

export default Layout;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('mobile')]: {
    display: 'none',
  },
}));
