import { useMemo, useState, useContext } from 'react';
import { Stack, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import DataItem from '../../../components/DataItem';

import useLocale from '../../../app/hooks/useLocale';

import { DashboardContext } from '../DashboardProvider';
import { DATE_FORMAT6, serverDateToInputFormat } from '../../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const MyDocumentsCard = () => {
  const { t, getLocalizedDate } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { applicationFile } = useContext(DashboardContext);

  const [isExpanded, setIsExpanded] = useState(false);

  const isConsumerProposalInsolvencyType = useMemo(
    () =>
      applicationFile?.filingTypeEnum === ClientPublicService.FilingTypeEnum.DivisionI ||
      applicationFile?.filingTypeEnum === ClientPublicService.FilingTypeEnum.DivisionII,
    [applicationFile?.filingTypeEnum]
  );

  const filingType = useMemo(() => {
    const insolvencyTypeName = isConsumerProposalInsolvencyType ? t.CONSUMER_PROPOSAL : t.BANKRUPTCY;

    return `${insolvencyTypeName} - ${applicationFile?.filingType}`;
  }, [applicationFile?.filingType, isConsumerProposalInsolvencyType, t.BANKRUPTCY, t.CONSUMER_PROPOSAL]);

  return (
    <>
      <Stack>
        <Typography variant="h6" fontWeight={600} aria-label={t.FILE_DETAILS}>
          {t.FILE_DETAILS}
        </Typography>

        <DataItem title={t.FILING_TYPE} content={filingType} />

        <DataItem title={t.ESTATE_NUMBER} content={applicationFile?.estateNumber || '-'} />

        {(isExpanded || isDesktop) && (
          <>
            <DataItem title={t.ESTATE_ADMIN} content={applicationFile?.estateAdministrator || '-'} />

            <DataItem title={t.TRUSTEE} content={applicationFile?.trustee || '-'} />

            <DataItem title={t.SERVICE_LOCATION} content={applicationFile?.serviceLocation || '-'} />

            <DataItem
              title={isConsumerProposalInsolvencyType ? t.DATE_OF_FILING : t.DATE_OF_BANKRUPTCY}
              content={
                applicationFile?.insolvencyDate
                  ? getLocalizedDate(serverDateToInputFormat(applicationFile?.insolvencyDate), DATE_FORMAT6)
                  : '-'
              }
            />

            {!isConsumerProposalInsolvencyType && (
              <DataItem title={t.DURATION_OF_BANKRUPTCY} content={applicationFile?.bankruptcyDuration || '-'} />
            )}
          </>
        )}

        {!isDesktop && (
          <Button
            variant="text"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              p: 0,
              color: theme.palette.secondary.main,
              '&.MuiButton-root:hover:not(.Mui-disabled)': { color: theme.palette.secondary.main },
            }}
          >
            <Stack alignItems="center" flexDirection="row" spacing={1}>
              {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              {isExpanded ? t.SHOW_LESS : t.SHOW_MORE}
            </Stack>
          </Button>
        )}
      </Stack>
    </>
  );
};

export default MyDocumentsCard;
