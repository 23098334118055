import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  ListItemProps,
  useTheme,
  Box,
} from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';

export type IPermanentDrawerItemType = 'menu-item' | 'divider';

export interface IPermanentDrawerItemProps extends ListItemProps {
  title?: string;
  icon?: React.ReactElement;
  url?: string;
  submenu?: React.ReactElement | null;
  drawerItemHidden?: boolean;
  type?: IPermanentDrawerItemType;
}

const PermanentDrawerItem = ({
  title,
  icon,
  url,
  submenu,
  drawerItemHidden,
  type = 'menu-item',
  ...props
}: IPermanentDrawerItemProps) => {
  const theme = useTheme();
  const location = useLocation();

  if (drawerItemHidden) return <></>;

  return (
    <>
      <StyledListItem
        {...props}
        sx={{
          mt: 0.5,
          backgroundColor: Boolean(matchPath(url as string, location?.pathname))
            ? theme.palette.paleCharcoal
            : 'transparent',
          borderRadius: 1.5,
          ...props?.sx,
        }}
      >
        <StyledListItemButton>
          <StyledListItemIcon role="img" aria-label={title}>
            {icon}
          </StyledListItemIcon>
          <StyledListItemText>{title}</StyledListItemText>
        </StyledListItemButton>
      </StyledListItem>
      {submenu ? <Box sx={{ pt: 0.75, pb: 0.75 }}>{submenu}</Box> : <> </>}
    </>
  );
};

export default PermanentDrawerItem;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  '& .MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.paleCharcoal,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  borderRadius: 4,
  padding: '8px 12px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.slate,
}));

const StyledListItemText = styled(ListItemText)(() => ({
  '.MuiTypography-root': {
    fontWeight: 500,
  },
}));
