import useAuthService from '../app/hooks/useAuthService';
import useLocale from '../app/hooks/useLocale';
import { Button, Container } from '@mui/material';

/**
 *  Corporate with Auth0, it is Sign in / Sign out buttons with user profile information such as User Logo and User Name
 *  Displayed in SiteBar.
 */
const LoginButton = () => {
  const { t } = useLocale();
  const { getUser, isAuthenticated, signin, signout } = useAuthService();

  return (
    <Container sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      {isAuthenticated() && <span>{getUser()?.userName}</span>}
      {!isAuthenticated() && (
        <Button
          onClick={() => {
            signin();
          }}
          data-testid="login-button"
        >
          {t.SIGN_IN}
        </Button>
      )}
      {isAuthenticated() && (
        <Button
          onClick={() => {
            signout();
          }}
          data-testid="logout-button"
        >
          {t.SIGN_OUT}
        </Button>
      )}
    </Container>
  );
};

export default LoginButton;
