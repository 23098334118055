import { useQuery, useMutation } from '@tanstack/react-query';

import {
  ACTIVE_SUBMISSION_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  APP_FORM_EXPENSES_QUERY_KEY,
  APP_FORM_INCOMES_QUERY_KEY,
  PERIOD_STATUS_QUERY_KEY,
  IE_REPORT_BY_ID_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';

import { queryClient } from '../../app/config';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });

export const useAppFormExpensesQuery = (fileId?: string) =>
  useQuery<ClientPublicService.AppFormIncomeExpenseExpenseDto>({
    queryKey: [APP_FORM_EXPENSES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormExpense(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useAppFormIncomesQuery = (fileId?: string) =>
  useQuery({
    queryKey: [APP_FORM_INCOMES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormIncome(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useActiveSubmissionQuery = (submissionPeriodId?: string) => {
  return useQuery({
    queryKey: [ACTIVE_SUBMISSION_QUERY_KEY, submissionPeriodId],
    queryFn: async () => {
      const data = await API.activeSubmission(submissionPeriodId as string);
      return data;
    },
  });
};

export const usePreviousSubmissionMutation = () =>
  useMutation({
    mutationFn: (submissionPeriodId: string) => API.previousActiveSubmission(submissionPeriodId),
  });

export const usePeriodStatusQuery = () => {
  return useQuery({
    queryKey: [PERIOD_STATUS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.periodStatus();
      return data;
    },
  });
};

export const useReportByIdQuery = (submissionPeriodId?: string) => {
  return useQuery({
    queryKey: [IE_REPORT_BY_ID_QUERY_KEY, submissionPeriodId],
    queryFn: async () => {
      const data = await API.schedules(submissionPeriodId as string);
      return data;
    },
  });
};

export const useSaveReportMutation = () =>
  useMutation({
    mutationFn: (body?: ClientPublicService.IncomeExpenseSubmissionPublicDto) => API.save(body),
    onSuccess: () => {
      queryClient.invalidateQueries([ACTIVE_SUBMISSION_QUERY_KEY]);
    },
  });

export const useSubmitReportMutation = () =>
  useMutation({
    mutationFn: (body?: ClientPublicService.IncomeExpenseSubmissionCreatePublicDto) => API.submit(body),
  });
