import { useCallback } from 'react';
import { toast } from 'react-toastify';

import PageContentContainer from '../../components/PageContentContainer';
import Outstanding from './components/Outstanding';
import Uploaded from './components/Uploaded';
import UploadButton from './components/UploadButton';

import useLocale from '../../app/hooks/useLocale';

import { useGeneralUploadMutation } from './queries';
import { IUploadedFileProps } from './types';
import { EMPTY_GUID } from '../../app/constants/common';
import moment from '../../app/utils/dateTimeHelpers';

const Documents = () => {
  const { t } = useLocale();

  const { mutate: uploadDocument } = useGeneralUploadMutation() || {};

  const handleGeneralUpload = useCallback(
    (uploadedFile: IUploadedFileProps) => {
      uploadDocument(
        {
          supportingDocumentId: EMPTY_GUID,
          documentFolderId: EMPTY_GUID,
          documentPrefixId: EMPTY_GUID,
          additionalInformation: '',
          content: { data: uploadedFile?.blob, fileName: uploadedFile?.name as string },
          isVisible: true,
          fileModifiedDate: moment(),
          isForSpouse: false,
          businessName: '',
        },
        {
          onSuccess: () => {
            toast.success(t.DOCUMENT_UPLOADED_SUCCESSFULLY);
          },
          onError: () => {
            toast.error(t.DOCUMENT_UPLOAD_FAILED);
          },
        }
      );
    },
    [t.DOCUMENT_UPLOADED_SUCCESSFULLY, t.DOCUMENT_UPLOAD_FAILED, uploadDocument]
  );

  return (
    <PageContentContainer
      headerProps={{
        title: t.MY_DOCUMENTS,
        actionButton: <UploadButton id="general-upload" onUploadSuccess={handleGeneralUpload} />,
      }}
    >
      <Outstanding />
      <Uploaded />
    </PageContentContainer>
  );
};

export default Documents;
