import { useTheme } from '@mui/material';

interface ICustomLabelProps {
  label?: string;
  required?: boolean;
  recommended?: boolean;
}

const CustomLabel = ({ label, required = false, recommended = false }: ICustomLabelProps) => {
  const theme = useTheme();

  if (!label) return <></>;

  if (label && !required && !recommended) return <>{label}</>;

  if (label && recommended)
    return (
      <>
        {label}
        <span style={{ color: theme.palette.warningOrange, marginLeft: 4 }}>*</span>
      </>
    );

  return (
    <>
      {label}
      <span style={{ color: theme.palette.error.main, marginLeft: 4 }}>*</span>
    </>
  );
};

export default CustomLabel;
