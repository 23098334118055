export enum ROUTES {
  ROOT = '/',
  LOGIN = '/login',
  ERROR = '/error',
  CONTACT_SUPPORT = '/contact-support',

  BOOK_APPOINTMENT = '/book-appointment',
  RESCHEDULE_APPOINTMENT = '/reschedule-appointment',
  SELECT_OFFICE = 'select-office',
  SELECT_TIME = 'select-time',
  CONTACT_DETAILS = 'contact-details',
  CONFIRMATION = 'confirmation',

  PAYMENTS_AND_BANKING = '/payments-and-banking',
  APPOINTMENTS = '/appointments',
  DASHBOARD = '/dashboard',
  DOCUMENTS = '/documents',
  PROFILE = '/profile',
  APP_FORM = '/app-form',
  PERSONAL_INFORMATION = 'personal-information',
  ASSETS_DEBTS = 'assets-debts',
  INCOME_EXPENSE = 'income-expense',
  INCOME_TAX = 'income-tax',
  BANKING = 'banking',
  QUESTIONNAIRE = 'questionnaire',

  INCOME_EXPENSE_REPORTS = '/income-expense-reports',

  IE_REPORT = '/report',
  IE_REPORT_INCOME = 'income',
  IE_REPORT_EXPENSE = 'expense',

  NOTIFICATIONS = '/notifications',
  CONTACT_STAFF = '/contact-staff',

  /* Note: Don't modify below 4 single sign-on associated routes. */
  AUTH0_CALLBACK = '/auth-callback',
  AUTH0_SILENT_CALLBACK = '/silent-auth-callback',
  AUTH0_LOGOUT = '/logout',
  AUTH0_LOGOUNT_CALLBACK = '/logout/callback',
}
