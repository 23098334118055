import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { worker } from './app/config/browser';

import './index.css';
import './app/assets/fonts/ProximaNovaRegular.otf';
import './app/assets/fonts/ProximaNovaBold.ttf';
import './app/assets/fonts/ProximaNovaXBold.otf';
import './app/assets/fonts/ProximaNovaSemiBold.otf';
import 'react-toastify/dist/ReactToastify.css';
import appInsights from './app/config/appinsights';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import RecaptchaBadgeStyle from './app/layout/RecaptchaBadgeStyle';

if (process.env.NODE_ENV === 'development') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const options = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
} as ToastContainerProps;

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <style>{RecaptchaBadgeStyle}</style>
    <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`}>
      <App />
    </GoogleReCaptchaProvider>
    <ToastContainer {...options} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
appInsights.loadAppInsights();
