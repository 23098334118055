import { Box } from '@mui/material';
import ProgressBar, { AppformSection } from './components/ProgressBar';

export interface IAppFormProgressProp {
  activeSection: AppformSection;
}

const AppFormProgressComponent = ({ activeSection }: IAppFormProgressProp) => {
  return (
    <Box height={4} width="100%" my={4} display="flex" alignItems="center">
      {Object.keys(AppformSection).map((key, i) => {
        return (
          isNaN(Number(key)) && (
            <ProgressBar section={AppformSection[key as keyof typeof AppformSection]} activeSection={activeSection} />
          )
        );
      })}
    </Box>
  );
};

export default AppFormProgressComponent;
