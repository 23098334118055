import { useQuery, useMutation } from '@tanstack/react-query';

import {
  APPOINTMENTS_GET_QUERY_KEY,
  APPOINTEMENT_TYPES_QUERY_KEY,
  APPOINTMENT_STATUSES_QUERY_KEY,
  MEETING_TYPES_QUERY_KEY,
  TIMESLOTS_QUERY_KEY,
  UPCOMING_APPOINTMENTS_GET_QUERY_KEY,
  PREVIOUS_APPOINTMENTS_GET_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import { argumentifyAppointmentsSearchParams } from './utils';
import { IAppointmentsSearchParams } from './types';

import API from '../../app/api/api';
import { queryClient } from '../../app/config';
import moment from '../../app/utils/dateTimeHelpers';

export const useAppointmentsQuery = (requestParams: IAppointmentsSearchParams) =>
  useQuery({
    queryKey: [APPOINTMENTS_GET_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAppointmentInfoList(
        ...argumentifyAppointmentsSearchParams({
          ...requestParams,
        })
      );
      return data;
    },
  });

export const useUpcomingAppointmentsQuery = (requestParams: IAppointmentsSearchParams) =>
  useQuery({
    queryKey: [UPCOMING_APPOINTMENTS_GET_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAppointmentInfoList(
        ...argumentifyAppointmentsSearchParams({
          startDateTimeAfter: moment(),
          ...requestParams,
        })
      );
      return data;
    },
  });

export const usePreviousAppointmentsQuery = (requestParams: IAppointmentsSearchParams) =>
  useQuery({
    queryKey: [PREVIOUS_APPOINTMENTS_GET_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAppointmentInfoList(
        ...argumentifyAppointmentsSearchParams({
          startDateTimeBefore: moment(),
          ...requestParams,
        })
      );
      return data;
    },
  });

export const useAppointmentTypesQuery = () =>
  useQuery({
    queryKey: [APPOINTEMENT_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentTypes(undefined);
      return data;
    },
  });

export const useAppointmentStatusesQuery = () =>
  useQuery({
    queryKey: [APPOINTMENT_STATUSES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentStatuses();
      return data;
    },
  });

export const useMeetingTypesQuery = () =>
  useQuery({
    queryKey: [MEETING_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listMeetingTypes();
    },
  });

export const useAppointmentByIdMutation = () =>
  useMutation({
    mutationFn: (appointmentId?: string) => API.getAppointmentInfo(appointmentId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [TIMESLOTS_QUERY_KEY] }),
  });
