import { CheckboxWithLabel } from 'formik-mui';
import { FormLabel, Typography } from '@mui/material';

import CustomLabel from '../CustomLabel';
import RecommendableField from './RecommendableField';

const CheckboxField = (props: any) => (
  <RecommendableField
    {...props}
    component={CheckboxWithLabel}
    type="checkbox"
    name={props?.name}
    inputProps={{ ...props?.inputProps }}
    Label={{
      label:
        (
          <Typography variant="body3" sx={{ width: '100%', m: 1, marginLeft: 0, marginRight: 0, ...props?.sx }}>
            <FormLabel>
              <CustomLabel label={props?.label} required={props?.required} recommended={props?.recommended} />
            </FormLabel>
          </Typography>
        ) || props?.label,
    }}
  />
);

export default CheckboxField;
