import { useMemo } from 'react';
import { Grid, Stack, Typography, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Circle } from '@mui/icons-material';

import DonutDiagram from '../../../components/DonutDiagram';
import BalanceCardSkeleton from './BalanceCardSkeleton';

import useLocale from '../../../app/hooks/useLocale';

import { useApplicationFileQuery, useBalanceQuery } from '../queries';
import { currencyFormatter } from '../../../app/utils/helpers';
import { ROUTES } from '../../../app/routes';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const BalanceTypography = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body3" fontWeight={600} noWrap>
    {children}
  </Typography>
);

const BalanceCard = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: applicationFile } = useApplicationFileQuery() || {};

  const { data: balance } = useBalanceQuery() || {};

  const isClosedStage = useMemo(
    () => applicationFile?.fileStage?.enumValue === ClientPublicService.FileStageEnum.Closed,
    [applicationFile?.fileStage?.enumValue]
  );

  const bankruptcyType = useMemo(() => {
    switch (applicationFile?.filingTypeEnum) {
      case ClientPublicService.FilingTypeEnum.Ordinary:
        return t.BANKRUPTCY;
      case ClientPublicService.FilingTypeEnum.Summary:
        return t.BANKRUPTCY;
      case ClientPublicService.FilingTypeEnum.DivisionI:
        return t.CONSUMER_PROPOSAL;
      case ClientPublicService.FilingTypeEnum.DivisionII:
        return t.CONSUMER_PROPOSAL;
      default:
        return '';
    }
  }, [applicationFile?.filingTypeEnum, t.BANKRUPTCY, t.CONSUMER_PROPOSAL]);

  if (balance === undefined) {
    return (
      <>
        <BalanceCardSkeleton />
      </>
    );
  }

  if (!Boolean(balance?.startingBalance) && !isClosedStage) {
    return (
      <Stack>
        <Typography variant="h6" fontWeight={600} aria-label="Bankruptcy Type" style={{ marginBottom: 10 }}>
          {bankruptcyType}
        </Typography>

        <Typography variant="body2" fontWeight={600} color="secondary">
          <span>{t.WERE_IN_THE_PROCESS_OF_SETTING_UP_YOUR_ACCOUNT} </span>
          <Link
            component="span"
            sx={{ cursor: 'pointer', color: theme.palette.cobalt }}
            onClick={() => navigate(ROUTES.CONTACT_STAFF)}
          >
            {t.CONTACT_US}
          </Link>
          <span> {t.IF_YOU_NEED_ASSISTANCE}</span>
          <p>{t.THANK_YOU_FOR_CHOOSING_BDO}</p>
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack justifyContent="start" flex={1}>
        <Stack alignItems="center">
          <DonutDiagram
            size={140}
            innerComponent={
              <Stack alignItems="center">
                <Typography variant="body4" fontWeight={600} color="secondary">
                  {t.STARTING_BALANCE}
                </Typography>
                <Typography variant="body4" fontWeight={600}>
                  {currencyFormatter(balance?.startingBalance)}
                </Typography>
              </Stack>
            }
            backgroundColor={theme.palette.chartGrey}
            items={[
              {
                color: theme.palette.successGreen,
                value: balance?.startingBalance ? ((balance?.paidBalance || 0) / balance?.startingBalance) * 100 : 0,
              },
            ]}
            strokeLinecap="butt"
          />
        </Stack>

        <Grid container columnSpacing={1} justifyContent="center" pt={2}>
          <Grid item mobile="auto">
            <Stack alignItems="flex-end" rowGap={0.75}>
              <Circle sx={{ color: theme.palette.chartGrey, fontSize: 20 }} />

              <Circle sx={{ color: theme.palette.successGreen, fontSize: 20 }} />
            </Stack>
          </Grid>
          <Grid item mobile="auto">
            <Stack rowGap={0.75}>
              <BalanceTypography>{t.REMAINING_BALANCE}</BalanceTypography>

              <BalanceTypography>{t.BALANCE_PAID}</BalanceTypography>
            </Stack>
          </Grid>
          <Grid item mobile="auto">
            <Stack rowGap={0.75}>
              <BalanceTypography>
                {currencyFormatter((balance?.startingBalance || 0) - (balance?.paidBalance || 0))}
              </BalanceTypography>

              <BalanceTypography>{currencyFormatter(balance?.paidBalance || 0)}</BalanceTypography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default BalanceCard;
