import { useOutletContext } from 'react-router-dom';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

export interface IAppFormContextProps {
  appFormStatus?: ClientPublicService.AppFormStatusDto;
  updateAppFormStatus?: () => void;
  appFileData?: ClientPublicService.ApplicationFileDto;
}

const useAppForm = () => useOutletContext<IAppFormContextProps>();

export default useAppForm;
