import { createContext } from 'react';

import { useDebtorProfileQuery, useAdminInfoSignupStatusQuery, useApplicationFileQuery } from './queries';
import { ClientPublicService } from '../../app/api/ClientPublicService';

interface IDashboardContext {
  debtorProfile?: ClientPublicService.DebtorProfilePageDto;
  applicationFile?: ClientPublicService.ApplicationFileDto;
  signupStatus?: ClientPublicService.AdminInfoSignupPublicDto;
}

export const initialDashboardContextValues: IDashboardContext = {
  debtorProfile: undefined,
  applicationFile: undefined,
  signupStatus: undefined,
};

export const DashboardContext = createContext<IDashboardContext>(initialDashboardContextValues);

interface IProps {
  children: React.ReactElement;
}

const DashboardProvider = ({ children }: IProps): React.ReactElement => {
  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { data: signupStatus } = useAdminInfoSignupStatusQuery() || {};

  const { data: applicationFile } = useApplicationFileQuery() || {};
  return (
    <DashboardContext.Provider
      value={{
        applicationFile,
        debtorProfile,
        signupStatus,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
