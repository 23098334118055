import { Typography, Stack, styled, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LocationSearchForm from './LocationSearchForm';
import Wrapper from './Wrapper';

import useBookAppointment from '../hooks/useBookAppointment';
import useLocale from '../../../app/hooks/useLocale';

import { getBackgroundLinearGradient } from '../../../app/utils/helpers';
import { useGoogleApiQuery } from '../queries';
import { ROUTES } from '../../../app/routes/constants';

const WhereAreYouLocated = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const { setUserLocation } = useBookAppointment();

  const { isLoading } = useGoogleApiQuery() || {};

  return (
    <StyledWrapper loading={isLoading}>
      <Stack data-testid="where-are-you-located-page" spacing={1} sx={{ maxWidth: 500 }}>
        <Box>
          <Stack sx={{ pl: { mobile: 0, tablet: 4 }, pr: { mobile: 0, tablet: 4 } }}>
            <Typography variant="body2" textAlign="center">
              {t.BOOK_YOUR_APPOINTMENT_NOW}
            </Typography>
          </Stack>
          <Typography variant="h1" data-testid="where-are-you-located-page-title" textAlign="center" sx={{ mt: 3 }}>
            {t.ENTER_YOUR_LOCATION}
          </Typography>
        </Box>
        <LocationSearchForm
          onSubmit={(value) => {
            setUserLocation?.(value);
            navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_OFFICE}`);
          }}
          key="where-are-you-located"
        />
      </Stack>
    </StyledWrapper>
  );
};

export default WhereAreYouLocated;

const opacityBreakpoints = ['60', '40', '20', '10', '07', '05', '03'];

const StyledWrapper = styled(Wrapper)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 37)}`,
  },
  [theme.breakpoints.up('tablet')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 26)}`,
  },
  [theme.breakpoints.up('desktop')]: {
    backgroundImage: `${getBackgroundLinearGradient(opacityBreakpoints, 21)}`,
  },
  backgroundPosition: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: 0,
}));
