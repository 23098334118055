import { useQuery, useMutation } from '@tanstack/react-query';

import {
  APPOINTMENT_STATUSES_QUERY_KEY,
  APPOINTEMENT_TYPES_QUERY_KEY,
  MEETING_TYPES_QUERY_KEY,
  TIMESLOTS_QUERY_KEY,
  UPCOMING_APPOINTMENTS_GET_QUERY_KEY,
  PREVIOUS_APPOINTMENTS_GET_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  APPOINTMENTS_GET_QUERY_KEY,
  COUNSELLING_SESSIONS_COUNT,
  DEBTOR_INFORMATION_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';

import API from '../../app/api/api';
import { queryClient } from '../../app/config';
import { ClientPublicService } from '../../app/api/ClientPublicService';

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
  });

export const useAppointmentStatusesQuery = () =>
  useQuery({
    queryKey: [APPOINTMENT_STATUSES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentStatuses();
      return data;
    },
  });

export const useAppointmentTypesQuery = () =>
  useQuery({
    queryKey: [APPOINTEMENT_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAppointmentTypes(undefined);
      return data;
    },
  });

export const useMeetingTypesQuery = () =>
  useQuery({
    queryKey: [MEETING_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listMeetingTypes();
    },
  });

export const useAppointmentByIdMutation = () =>
  useMutation({
    mutationFn: (appointmentId?: string) => API.getAppointmentInfo(appointmentId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [TIMESLOTS_QUERY_KEY] }),
  });

export const useCancelAppointmentMutation = () =>
  useMutation({
    mutationFn: (appointmentId?: string) => API.cancelAppointment(appointmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UPCOMING_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PREVIOUS_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APPOINTMENTS_GET_QUERY_KEY] });
    },
  });

export const useRequestChangeFollowUpCounsellingCountQuery = () =>
  useQuery({
    queryKey: [COUNSELLING_SESSIONS_COUNT],
    queryFn: async () => {
      return await API.countTasks(
        [ClientPublicService.AppTaskNameEnum.FollowUpCounselling],
        [ClientPublicService.AppTaskStatusEnum.Pending],
        undefined,
        undefined,
        1000
      );
    },
  });

export const useRequestChangeFollowUpCounsellingMutation = () =>
  useMutation({
    mutationFn: () => API.counsellingFollowUp(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UPCOMING_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PREVIOUS_APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APPOINTMENTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [COUNSELLING_SESSIONS_COUNT] });
    },
  });
