import { AuthProvider } from '../auth/components/AuthProvider';
import LocaleProvider from './LocaleProvider/LocaleProvider';
import ModalProvider from './ModalProvider';

interface IProps {
  children: React.ReactElement;
}

const ContextProviders = ({ children }: IProps): React.ReactElement => {
  return (
    <AuthProvider>
      {/*  When authentication process updated, nexsted route and route's refered component will got re-render automatically.  
           Note: Any nested components inside "AuthProvider", it can call useContext(AuthContext) to get reference of authService.
      */}

      <LocaleProvider>
        <ModalProvider>{children}</ModalProvider>
      </LocaleProvider>
    </AuthProvider>
  );
};

export default ContextProviders;
