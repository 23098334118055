import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { ToggleWithInputFields } from '../../../../../components/FormFieldsPresets';
import { YesNoToggleField } from '../../../../../components/FormFields';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormQuestionnaireValues } from '../types';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const TransactionsSpouse = () => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  return (
    <>
      <ToggleWithInputFields
        toggleFieldName="hasSpouseMadeExessPaymentsPast12Months"
        toggleFieldLabel={t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS}
        inputFieldName="hasSpouseMadeExessPaymentsPast12MonthsDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasSpouseAssetsSiezedOrGarnishedPast12Months"
        toggleFieldLabel={t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS}
        inputFieldName="hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years"
        toggleFieldLabel={t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS}
        inputFieldName="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      {values?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years && (
        <YesNoToggleField
          name="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime"
          toggleFieldProps={{ required: true }}
          label={t.INSOLVENT_AT_THE_TIME}
          disabled={isLocked}
        />
      )}

      <ToggleWithInputFields
        toggleFieldName="hasSpouseGivenGiftsOver500WhileInsolventPast5Years"
        toggleFieldLabel={
          t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
        }
        inputFieldName="hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isSpouseExpectedToReceiveNonIncomeFundsNext12Months"
        toggleFieldLabel={
          t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
        }
        inputFieldName="isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty"
        toggleFieldLabel={
          t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
        }
        inputFieldName="hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="hasSpouseMadeArragementsToContinuePayingCreditors"
        toggleFieldLabel={t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING}
        inputFieldName="hasSpouseMadeArragementsToContinuePayingCreditorsDetails"
        toggleFieldProps={{ required: true }}
        disabled={isLocked}
      />
    </>
  );
};

export default TransactionsSpouse;
