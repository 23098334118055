import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ReactQueryProvider, ContextProviders } from './app/providers';
import Modal from './components/Modal';
import { AppRoutes } from './app/routes';
import theme from './app/layout/theme';
import { ReactElement } from 'react';

function App(): ReactElement {
  return (
    <ReactQueryProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ContextProviders>
            <>
              <AppRoutes />
              <Modal />
            </>
          </ContextProviders>
        </BrowserRouter>
      </ThemeProvider>
    </ReactQueryProvider>
  );
}

export default App;
