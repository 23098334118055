import { ClientPublicService } from './ClientPublicService';

const { APIClient } = ClientPublicService;

/**
 * Shared singleton API client instance.
 * Call Bdo.Pds.PublicWebGateway end point: https://localhost:44353 (for local debug).
 * Work for calling server side methods.
 * Note: This is not auto generated code.
 */
const apiClient = new APIClient(process.env.REACT_APP_BASE_URL);
export default apiClient;
