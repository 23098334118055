import { useMemo, useContext } from 'react';
import { Divider, Box, styled, BoxProps } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import EditExpenseForm from './EditExpenseForm';
import EditExpenseHeader from './EditExpenseHeader';
import EditExpenseFooter from './EditExpenseFooter';

import useLocale from '../../app/hooks/useLocale';

import {
  useDocumentsUploadMutation,
  IFormValuesDocumentItem,
  getDocumentsValidationSchema,
  ISupportDocsUploadProps,
} from '../DocumentsUpload';
import { IFormValues, IExpenseSubmitValues } from './types';
import { isOther } from './utils';
import EditExpenseProvider, { EditExpenseContext } from './EditExpenseProvider';

interface IEditExpenseProps {
  fileId?: string;
  initialValues?: IFormValues;
  onSubmit?: (values: IExpenseSubmitValues) => void;
  onCancel?: () => void;
  footerProps?: BoxProps;
  headerProps?: BoxProps;
  isEditable?: boolean;
}

interface IEditExpenseComponentProps extends IEditExpenseProps {
  isEditable?: boolean;
  documentsUploadProps?: ISupportDocsUploadProps;
}

const EditExpense = ({ fileId, initialValues, onSubmit, onCancel, isEditable, ...props }: IEditExpenseProps) => {
  const { t } = useLocale();
  const { tabsCount, goToNextTab, currentTabNumber } = useContext(EditExpenseContext);

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        expenseDetails: yup.array().of(
          yup.object({
            specifiedDescription: yup
              .string()
              .when(
                ['expenseDescription', 'applicantAmount', 'spouseAmount', 'otherHouseholdMemberAmount'],
                ([expenseDescription, applicantAmount, spouseAmount, otherHouseholdMemberAmount], schema) =>
                  isOther(expenseDescription) &&
                  (applicantAmount > 0 || spouseAmount > 0 || otherHouseholdMemberAmount > 0)
                    ? schema.required(t.THIS_IS_A_REQUIRED_FIELD)
                    : schema
              ),
          })
        ),
        ...getDocumentsValidationSchema(t.THIS_IS_A_REQUIRED_FIELD).fields,
      }),
    [t.THIS_IS_A_REQUIRED_FIELD]
  );

  return (
    <>
      <HeaderContainer {...props?.headerProps}>
        <EditExpenseHeader />
        <ModalDivider />
      </HeaderContainer>

      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          if (currentTabNumber < tabsCount - 1) {
            goToNextTab?.();
          } else {
            const newDocumentsIds = await uploadDocuments({
              fileId: fileId as string,
              files: values?.documents as IFormValuesDocumentItem[],
            });

            const previouslyUploadedDocumentsIds = (values?.documents || [])
              ?.filter((item) => item.id)
              ?.map((item) => item?.id);

            onSubmit?.({
              documentIds: [...previouslyUploadedDocumentsIds, ...newDocumentsIds] as string[],
              expenseDetails: values?.expenseDetails,
            });
          }
          setSubmitting(false);
        }}
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <EditExpenseForm />

            <FooterContainer {...props?.footerProps}>
              <ModalDivider />
              <EditExpenseFooter onCancel={onCancel} />
            </FooterContainer>
          </>
        )}
      </Formik>
    </>
  );
};

const EditExpenseComponent = ({ isEditable, documentsUploadProps, ...props }: IEditExpenseComponentProps) => (
  <EditExpenseProvider isEditable={isEditable} documentsUploadProps={documentsUploadProps}>
    <EditExpense {...props} />
  </EditExpenseProvider>
);

export default EditExpenseComponent;

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.white,
  zIndex: 1,
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.white,
  zIndex: 1,
}));

const ModalDivider = styled(Divider)(({ theme }) => ({
  marginTop: 12,
  marginBottom: 12,
}));
