import { Route, Routes, Outlet } from 'react-router-dom';

import { ROUTES } from './constants';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from '../../components/Layout';

import { Callback } from '../auth/components/Callback';
import { SilentRenew } from '../auth/components/SilentRenew';
import { Logout } from '../auth/components/Logout';
import { LogoutCallback } from '../auth/components/LogoutCallback';

import BookAppointment from '../../features/BookAppointment/BookAppointment';
import WhereAreYouLocated from '../../features/BookAppointment/components/WhereAreYouLocated';
import SelectOffice from '../../features/BookAppointment/components/SelectOffice';
import SelectTime from '../../features/BookAppointment/components/SelectTime';
import ContactDetails from '../../features/BookAppointment/components/ContactDetails';
import Confirmation from '../../features/BookAppointment/components/Confirmation';

import Appointments from '../../features/Appointments/Appointments';
import Documents from '../../features/Documents/Documents';
import Dashboard from '../../features/Dashboard/Dashboard';
import MyProfile from '../../features/MyProfile/MyProfile';
import WelcomePage from '../../features/WelcomePage/WelcomePage';
import PageNotFound from '../../features/PageNotFound/PageNotFound';
import AppForm from '../../features/AppForm/AppForm';
import AssetsDebts from '../../features/AppForm/modules/AssetsDebts/AssetsDebts';
import IncomeExpense from '../../features/AppForm/modules/IncomeExpense/IncomeExpense';
import PersonalInformation from '../../features/AppForm/modules/PersonalInformation/PersonalInformation';
import IncomeTax from '../../features/AppForm/modules/IncomeTax/IncomeTax';
import Questionnaire from '../../features/AppForm/modules/Questionnaire/Questionnaire';
import Banking from '../../features/AppForm/modules/Banking/Banking';
import PaymentsBanking from '../../features/PaymentsBanking/PaymentsBanking';
import ContactStaff from '../../features/ContactStaff/ContactStaff';
import LoginRedirect from '../auth/components/LoginRedirect';

import IEReports from '../../features/IEReports/IEReports';
import EditIEReport from '../../features/EditIEReport/EditIEReport';
import EditIEReportSummary from '../../features/EditIEReport/EditEIReportSummary';
import EditIEReportEditExpense from '../../features/EditIEReport/EditIEReportEditExpense';
import EditIEReportEditIncome from '../../features/EditIEReport/EditEIReportEditIncome';

import Notifications from '../../features/Notifications/Notifications';
import RegistrationLinkExpired from '../../features/RegistrationLinkExpired/RegistrationLinkExpired';
import TechnicalSupport from '../../features/TechnicalSupport/TechnicalSupport';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Unauthorized routes */}
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route path={ROUTES.ROOT} element={<WelcomePage />} />
        <Route path={ROUTES.LOGIN} element={<LoginRedirect />} />

        <Route path={ROUTES.BOOK_APPOINTMENT} element={<BookAppointment />}>
          <Route path="" element={<WhereAreYouLocated />} />
          <Route path={ROUTES.SELECT_OFFICE} element={<SelectOffice />} />
          <Route path={ROUTES.SELECT_TIME} element={<SelectTime />} />
          <Route path={ROUTES.CONTACT_DETAILS} element={<ContactDetails />} />
          <Route path={`${ROUTES.CONFIRMATION}/:appointmentId`} element={<Confirmation />} />
        </Route>

        <Route path={ROUTES.ERROR} element={<RegistrationLinkExpired />} />
        <Route path={ROUTES.CONTACT_SUPPORT} element={<TechnicalSupport />} />

        <Route path="*" element={<PageNotFound />} />

        {/* Note: Don't modify below 4 single sign-on associated routes. */}
        <Route path={ROUTES.AUTH0_CALLBACK} element={<Callback />} />
        <Route path={ROUTES.AUTH0_SILENT_CALLBACK} element={<SilentRenew />} />
        <Route path={ROUTES.AUTH0_LOGOUT} element={<Logout />} />
        <Route path={ROUTES.AUTH0_LOGOUNT_CALLBACK} element={<LogoutCallback />} />
      </Route>

      {/* Authorized routes */}
      <Route
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route path="*" element={<PageNotFound />} />

        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.APPOINTMENTS} element={<Appointments />} />
        <Route path={ROUTES.DOCUMENTS} element={<Documents />} />

        <Route path={ROUTES.APP_FORM} element={<AppForm />}>
          <Route path="" element={<PersonalInformation />} />
          <Route path={ROUTES.PERSONAL_INFORMATION} element={<PersonalInformation />} />
          <Route path={ROUTES.ASSETS_DEBTS} element={<AssetsDebts />} />
          <Route path={ROUTES.INCOME_EXPENSE} element={<IncomeExpense />} />
          <Route path={ROUTES.INCOME_TAX} element={<IncomeTax />} />
          <Route path={ROUTES.QUESTIONNAIRE} element={<Questionnaire />} />
          <Route path={ROUTES.BANKING} element={<Banking />} />
        </Route>

        <Route path={ROUTES.PAYMENTS_AND_BANKING} element={<PaymentsBanking />} />

        <Route path={ROUTES.INCOME_EXPENSE_REPORTS} element={<IEReports />} />

        <Route path={`${ROUTES.IE_REPORT}/:submissionPeriodId`} element={<EditIEReport />}>
          <Route path="" element={<EditIEReportSummary />} />
          <Route path={ROUTES.IE_REPORT_EXPENSE} element={<EditIEReportEditExpense />} />
          <Route path={ROUTES.IE_REPORT_INCOME} element={<EditIEReportEditIncome />} />
        </Route>

        <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />

        <Route path={`${ROUTES.RESCHEDULE_APPOINTMENT}/:appointmentId`} element={<BookAppointment />}>
          <Route path={ROUTES.SELECT_OFFICE} element={<SelectOffice />} />
          <Route path={ROUTES.SELECT_TIME} element={<SelectTime />} />
          <Route path={ROUTES.CONFIRMATION} element={<Confirmation />} />
        </Route>

        <Route path={ROUTES.PROFILE} element={<MyProfile />} />
        <Route path={ROUTES.CONTACT_STAFF} element={<ContactStaff />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
