import { useCallback, useMemo } from 'react';
import { Typography, Stack, List, ListItem, Link, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';

import useLocale from '../../../../app/hooks/useLocale';

import { getAllObjectProps } from '../../../../app/utils/helpers';
import { IAppFormIncomeTax } from './types';

const ErrorsList = () => {
  const theme = useTheme();
  const { t } = useLocale();
  const { errors, getFieldMeta } = useFormikContext<IAppFormIncomeTax>();

  const handleGoToError = useCallback((errorField?: string) => {
    const element = document.querySelector(`[name="${errorField}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const propsWithErrors = useMemo(() => getAllObjectProps(errors), [errors]);

  if (!propsWithErrors || !propsWithErrors?.length) return <></>;

  return (
    <List
      sx={{
        backgroundColor: `${theme.palette.error.main}10`,
        mb: 2.5,
      }}
    >
      {propsWithErrors?.map((item, index) => {
        const { error } = getFieldMeta(item);

        if (typeof error !== 'string') return null;

        const isRecommended = error?.includes('[recommended]');

        return (
          <ListItem key={index} alignItems="flex-start" dense>
            <Stack direction="row" spacing={1} alignItems="center">
              {isRecommended ? (
                <ErrorIcon style={{ fontSize: 16, color: theme.palette.warningOrange, marginTop: 6 }} />
              ) : (
                <CancelIcon style={{ fontSize: 16, color: theme.palette.urgentRed, marginTop: 6 }} />
              )}
              <Link
                component="button"
                onClick={() => handleGoToError(item)}
                sx={{
                  '&.MuiLink-root': {
                    color: theme.palette.urgentRed,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontSize: 12,
                  },
                }}
              >
                {isRecommended ? t.GO_TO_WARNING : t.GO_TO_ERROR}
              </Link>
              <Typography variant="body4">{error?.replace('[recommended]', '')}</Typography>
            </Stack>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ErrorsList;
