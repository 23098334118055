import useLocale from '../../../../../app/hooks/useLocale';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { IFormQuestionnaireValues } from '../types';
import useAppForm from '../../../hooks/useAppForm';
import { useCallback, useMemo } from 'react';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';
import { getFinancialAdviceProviderQuestionnaire } from '../utils';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';
import { NumberField, TextField, YesNoToggleField } from '../../../../../components/FormFields';
import { Box, Button, Divider, FormLabel, IconButton, Stack, useTheme } from '@mui/material';
import { LocationFields } from '../../../../../components/FormFieldsPresets';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FinancialAdviceProvider = (props: { isJointFile: boolean | undefined }) => {
  const { t } = useLocale();
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  const newFinancialAdviceProviderItem = useMemo(() => getFinancialAdviceProviderQuestionnaire(), []);

  const handleDebtorHasAFinancialAdviceProvider = useCallback(
    (debtorHasAFinancialAdviceProvider?: boolean) => {
      const noFinancialAdviceProviderRecords = !values?.financialAdvices || values?.financialAdvices.length === 0;
      if (debtorHasAFinancialAdviceProvider && noFinancialAdviceProviderRecords) {
        setFieldValue('financialAdvices', [newFinancialAdviceProviderItem]);
      }
      if (!debtorHasAFinancialAdviceProvider) {
        setFieldValue('financialAdvices', []);
      }
    },
    [newFinancialAdviceProviderItem, setFieldValue, values?.financialAdvices]
  );

  const handleDebtorSpouseHasAFinancialAdviceProvider = useCallback(
    (debtorSpouseHasAFinancialAdviceProvider?: boolean) => {
      const noFinancialAdviceProviderRecords =
        !values?.spouseFinancialAdvices || values?.spouseFinancialAdvices.length === 0;
      if (debtorSpouseHasAFinancialAdviceProvider && noFinancialAdviceProviderRecords) {
        setFieldValue('spouseFinancialAdvices', [newFinancialAdviceProviderItem]);
      }
      if (!debtorSpouseHasAFinancialAdviceProvider) {
        setFieldValue('spouseFinancialAdvices', []);
      }
    },
    [newFinancialAdviceProviderItem, setFieldValue, values?.spouseFinancialAdvices]
  );

  return (
    <>
      <AppFormSectionSubtitle>
        {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT}
      </AppFormSectionSubtitle>

      <YesNoToggleField
        label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE}
        name="debtorHasAFinancialAdviceProvider"
        onChange={handleDebtorHasAFinancialAdviceProvider}
        disabled={isLocked}
        required
      />

      {values?.debtorHasAFinancialAdviceProvider &&
        values?.financialAdvices &&
        values?.financialAdvices?.length > 0 && (
          <>
            <FieldArray name="financialAdvices">
              {({ remove, insert }: ArrayHelpers) => (
                <>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  {values?.financialAdvices?.map((_, index: number) => {
                    return (
                      <>
                        <FormLabel
                          sx={{
                            fontWeight: 600,
                            color: theme.palette.primary.main,
                            fontSize: 14,
                          }}
                        >
                          {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE}
                        </FormLabel>
                        <Stack key={index}>
                          <TextField
                            name={`financialAdvices.${index}.surname`}
                            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME}
                            required
                            inputProps={{ maxLength: 512 }}
                            disabled={isLocked}
                          />

                          <TextField
                            name={`financialAdvices.${index}.givenName`}
                            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME}
                            required
                            inputProps={{ maxLength: 512 }}
                            disabled={isLocked}
                          />

                          <TextField
                            name={`financialAdvices.${index}.firmName`}
                            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME}
                            required
                            inputProps={{ maxLength: 512 }}
                            disabled={isLocked}
                          />

                          <LocationFields
                            presetType="short"
                            addressFieldName={`financialAdvices.${index}.firmAddress`}
                            addressFieldLabel={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS}
                            streetNameFieldName={`financialAdvices.${index}.firmStreetNumber`}
                            streetNumberFieldName={`financialAdvices.${index}.firmStreetNumber`}
                            unitFieldName={`financialAdvices.${index}.addressUnit`}
                            unitFieldLabel={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT}
                            cityFieldName={`financialAdvices.${index}.firmCity`}
                            provinceIdFieldName={`financialAdvices.${index}.firmProvinceId`}
                            countryIdFieldName={`financialAdvices.${index}.firmCountryId`}
                            postalCodeFieldName={`financialAdvices.${index}.firmPostalCode`}
                            requiredFields={[`financialAdvices.${index}.firmAddress`]}
                            disabledFields={isLocked ? 'all' : undefined}
                          />

                          <NumberField
                            name={`financialAdvices.${index}.totalAmountPaidToDate`}
                            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE}
                            required
                            inputProps={{
                              allowNegative: false,
                              decimalScale: 2,
                            }}
                            disabled={isLocked}
                          />

                          <NumberField
                            name={`financialAdvices.${index}.totalRemainingAmountToBePaid`}
                            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID}
                            required
                            inputProps={{
                              allowNegative: false,
                              decimalScale: 2,
                            }}
                            disabled={isLocked}
                          />

                          <IconButton
                            onClick={() => remove(index)}
                            disabled={values?.financialAdvices?.length === 1 || isLocked}
                            sx={{ mt: 2, alignSelf: 'start' }}
                            color="error"
                            aria-label="Delete"
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                          <Divider sx={{ mt: 2, mb: 1 }} />
                        </Stack>
                      </>
                    );
                  })}

                  <Button
                    variant="text"
                    onClick={() => insert(values?.financialAdvices?.length || 0, newFinancialAdviceProviderItem)}
                    disabled={isLocked}
                  >
                    {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT}
                  </Button>
                </>
              )}
            </FieldArray>
          </>
        )}

      {props.isJointFile && (
        <Box data-testid={'spouse-financial-advice-providers'}>
          <Divider sx={{ mt: 2, mb: 2 }} />

          <AppFormSectionSubtitle>
            {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE}
          </AppFormSectionSubtitle>

          <YesNoToggleField
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE}
            name="debtorSpouseHasAFinancialAdviceProvider"
            onChange={handleDebtorSpouseHasAFinancialAdviceProvider}
            disabled={isLocked}
            required={props.isJointFile}
          />

          {values?.debtorSpouseHasAFinancialAdviceProvider &&
            values?.spouseFinancialAdvices &&
            values?.spouseFinancialAdvices?.length > 0 && (
              <>
                <FieldArray name="spouseFinancialAdvices">
                  {({ remove, insert }: ArrayHelpers) => (
                    <>
                      <Divider sx={{ mt: 2, mb: 1 }} />
                      {values?.spouseFinancialAdvices?.map((_, index: number) => {
                        return (
                          <>
                            <FormLabel
                              sx={{
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                                fontSize: 14,
                              }}
                            >
                              {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE}
                            </FormLabel>
                            <Stack key={index}>
                              <TextField
                                name={`spouseFinancialAdvices.${index}.surname`}
                                label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME}
                                required
                                inputProps={{ maxLength: 512 }}
                                disabled={isLocked}
                              />

                              <TextField
                                name={`spouseFinancialAdvices.${index}.givenName`}
                                label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME}
                                required
                                inputProps={{ maxLength: 512 }}
                                disabled={isLocked}
                              />

                              <TextField
                                name={`spouseFinancialAdvices.${index}.firmName`}
                                label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME}
                                required
                                inputProps={{ maxLength: 512 }}
                                disabled={isLocked}
                              />

                              <LocationFields
                                presetType="short"
                                addressFieldName={`spouseFinancialAdvices.${index}.firmAddress`}
                                addressFieldLabel={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS}
                                streetNameFieldName={`spouseFinancialAdvices.${index}.firmStreetNumber`}
                                streetNumberFieldName={`spouseFinancialAdvices.${index}.firmStreetNumber`}
                                unitFieldName={`spouseFinancialAdvices.${index}.addressUnit`}
                                unitFieldLabel={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT}
                                cityFieldName={`spouseFinancialAdvices.${index}.firmCity`}
                                provinceIdFieldName={`spouseFinancialAdvices.${index}.firmProvinceId`}
                                countryIdFieldName={`spouseFinancialAdvices.${index}.firmCountryId`}
                                postalCodeFieldName={`spouseFinancialAdvices.${index}.firmPostalCode`}
                                requiredFields={[`spouseFinancialAdvices.${index}.firmAddress`]}
                                disabledFields={isLocked ? 'all' : undefined}
                              />

                              <NumberField
                                name={`spouseFinancialAdvices.${index}.totalAmountPaidToDate`}
                                label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE}
                                required
                                inputProps={{
                                  allowNegative: false,
                                  decimalScale: 2,
                                }}
                                disabled={isLocked}
                              />

                              <NumberField
                                name={`spouseFinancialAdvices.${index}.totalRemainingAmountToBePaid`}
                                label={
                                  t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID
                                }
                                required
                                inputProps={{
                                  allowNegative: false,
                                  decimalScale: 2,
                                }}
                                disabled={isLocked}
                              />

                              <IconButton
                                onClick={() => remove(index)}
                                disabled={values?.spouseFinancialAdvices?.length === 1 || isLocked}
                                sx={{ mt: 2, alignSelf: 'start' }}
                                color="error"
                                aria-label="Delete"
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                              <Divider sx={{ mt: 2, mb: 1 }} />
                            </Stack>
                          </>
                        );
                      })}

                      <Button
                        variant="text"
                        onClick={() =>
                          insert(values?.spouseFinancialAdvices?.length || 0, newFinancialAdviceProviderItem)
                        }
                        disabled={isLocked}
                      >
                        {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT}
                      </Button>
                    </>
                  )}
                </FieldArray>
              </>
            )}
        </Box>
      )}
    </>
  );
};

export default FinancialAdviceProvider;
