import { useQuery, useMutation, useQueries } from '@tanstack/react-query';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import {
  LIST_INCOMES_QUERY_KEY,
  LIST_EXPENSES_QUERY_KEY,
  APP_FORM_INCOMES_QUERY_KEY,
  APP_FORM_EXPENSES_QUERY_KEY,
  LIST_INCOME_TYPES_QUERY_KEY,
  LIST_EXPENSE_TYPES_QUERY_KEY,
  LIST_EXPENSE_DESCRIPTIONS_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
} from '../../../../app/constants/reactQueryKeys';
import { IIncomeType, IExpenseType, IExpenseDescriptionType } from './types';
import { IExtendedExpenseDescriptionType } from '../../../../components/EditExpenseComponent/types';
import { queryClient } from '../../../../app/config';

import API from '../../../../app/api/api';

export const useListIncomesQuery = (fileId?: string) =>
  useQuery<ClientPublicService.AppFormIncomeExpenseIncomeDetailListItem[]>({
    queryKey: [LIST_INCOMES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.listAppFormIncomeDetails(fileId);
      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useListExpensesQuery = (fileId?: string) =>
  useQuery<ClientPublicService.AppFormIncomeExpenseExpenseDetailListItem[]>({
    queryKey: [LIST_EXPENSES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.listAppFormExpenseDetails(fileId);
      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useAppFormIncomesQuery = (fileId?: string) =>
  useQuery<ClientPublicService.AppFormIncomeExpenseIncomeDto>({
    queryKey: [APP_FORM_INCOMES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormIncome(fileId);
      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useAppFormExpensesQuery = (fileId?: string) =>
  useQuery<ClientPublicService.AppFormIncomeExpenseExpenseDto>({
    queryKey: [APP_FORM_EXPENSES_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormExpense(fileId);
      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useListIncomeTypesQuery = () =>
  useQuery<IIncomeType[]>({
    queryKey: [LIST_INCOME_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listIncomeTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useListExpenseTypesQuery = () =>
  useQuery<IExpenseType[]>({
    queryKey: [LIST_EXPENSE_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listExpenseTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useListExpenseDescriptionsQuery = (expenseTypeId?: string) =>
  useQuery<IExpenseDescriptionType[]>({
    queryKey: [LIST_EXPENSE_DESCRIPTIONS_QUERY_KEY, expenseTypeId],
    queryFn: async () => {
      const data = await API.listExpenseDescriptions(expenseTypeId);
      return data;
    },
    enabled: !!expenseTypeId,
    refetchOnWindowFocus: false,
  });

export const useAllListExpenseDescriptionsQuery = (expenseTypes?: IExpenseType[]) =>
  useQueries({
    queries: (expenseTypes || []).map((expenseType) => {
      return {
        queryKey: [LIST_EXPENSE_DESCRIPTIONS_QUERY_KEY, expenseType.id],
        queryFn: async () => {
          const data = await API.listExpenseDescriptions(expenseType.id);
          return data?.map((item) => ({ ...item, expenseTypeId: expenseType.id } as IExtendedExpenseDescriptionType));
        },
        enabled: !!expenseTypes || !!expenseType.id,
        refetchOnWindowFocus: false,
      };
    }),
  });

export const useDeleteIncomeMutation = () =>
  useMutation({
    mutationFn: (incomeId?: string) => API.deleteAppFormIncome(incomeId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LIST_INCOMES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APP_FORM_INCOMES_QUERY_KEY] });
    },
  });

export const useUpdateIncomeMutation = () =>
  useMutation({
    mutationFn: (body?: ClientPublicService.IAppFormIncomeExpenseIncomeDto) =>
      API.updateAppFormIncome(body as ClientPublicService.AppFormIncomeExpenseIncomeDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LIST_INCOMES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APP_FORM_INCOMES_QUERY_KEY] });
    },
  });

export const useDeleteExpenseMutation = () =>
  useMutation({
    mutationFn: (expenseId?: string) => API.deleteAppFormExpense(expenseId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LIST_EXPENSES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APP_FORM_EXPENSES_QUERY_KEY] });
    },
  });

export const useUpdateExpenseMutation = () =>
  useMutation({
    mutationFn: (body?: ClientPublicService.IAppFormIncomeExpenseExpenseDto) =>
      API.updateAppFormExpense(body as ClientPublicService.AppFormIncomeExpenseExpenseDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LIST_EXPENSES_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [APP_FORM_EXPENSES_QUERY_KEY] });
    },
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });
