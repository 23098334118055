import { AuthConsumer } from './AuthProvider';

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <></>;
    }}
  </AuthConsumer>
);
