import PDFIcon from '../../app/assets/icons/pdf-file-icon.svg';
import DocIcon from '../../app/assets/icons/word-file-icon.svg';
import ExcelIcon from '../../app/assets/icons/excel-file-icon.svg';
import ImageIcon from '../../app/assets/icons/image-file-icon.svg';

import { ISupportingDocumentUploadParams, IDocumentsSearchParams, ISupportingDocumentsSearchParams } from './types';

const getImgDataByType = (fileTypeName?: string) => {
  const wordTypes = ['DOC', 'DOCX', 'MSG'];
  const excelTypes = ['XLS', 'XLSX', 'CSV'];
  const imageTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'BMP', 'TIFF', 'SVG', 'HEIC', 'AVIF', 'WEBP'];
  const pdfTypes = ['PDF', 'PPT'];

  if (!fileTypeName) return { src: DocIcon, alt: 'default-icon' };

  if (wordTypes?.includes(fileTypeName)) return { src: DocIcon, alt: 'doc-icon' };
  if (excelTypes?.includes(fileTypeName)) return { src: ExcelIcon, alt: 'excel-icon' };
  if (imageTypes?.includes(fileTypeName)) return { src: ImageIcon, alt: 'img-icon' };
  if (pdfTypes?.includes(fileTypeName)) return { src: PDFIcon, alt: 'pdf-icon' };

  return { src: DocIcon, alt: 'default-icon' };
};

const downloadFile = (blob: Blob, documentName: string) => {
  const url = URL.createObjectURL(blob);
  const link = window.document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName);
  link.click();
  URL.revokeObjectURL(url);
};

const argumentifyDocumentsGetParams = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentsSearchParams) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

const argumentifySupportingDocumentUploadParams = ({
  supportingDocumentId,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  isVisible,
  fileModifiedDate,
  content,
  isForSpouse,
  businessName,
}: ISupportingDocumentUploadParams) =>
  [
    supportingDocumentId,
    documentFolderId,
    documentPrefixId,
    additionalInformation,
    isVisible,
    fileModifiedDate,
    content,
    isForSpouse,
    businessName,
  ] as const;

const argumentifySupportingDocumentsSearchParams = ({
  supportingDocumentStatus,
  sorting,
  skipCount,
  maxResultCount,
}: ISupportingDocumentsSearchParams) => [supportingDocumentStatus, sorting, skipCount, maxResultCount] as const;

export {
  getImgDataByType,
  downloadFile,
  argumentifySupportingDocumentUploadParams,
  argumentifyDocumentsGetParams,
  argumentifySupportingDocumentsSearchParams,
};
