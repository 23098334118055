import { useCallback, useMemo, useEffect } from 'react';
import { Button, Grid, Typography, Link, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { TextField, SelectField, PhoneField, CheckboxField } from '../../../components/FormFields';
import FormControl from '../../../components/ControlledInputs/FormControl';
import Header from './Header';
import Wrapper from './Wrapper';
import Countdown from './Countdown';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ElevatedContainer from '../../../components/ElevatedContainer';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';
import useBookAppointment from '../hooks/useBookAppointment';
import { ILocale } from '../../../app/providers/LocaleProvider/types';

import { IAppointmentDetailsUpdate } from '../types';
import { MEETING_DATE_FORMAT, MEETING_TIME_FORMAT, convertToUtcMoment } from '../../../app/utils/dateTimeHelpers';
import { regexPhone } from '../../../app/utils/helpers';
import { UTM_PARAMS_SESSION_STORAGE_KEY } from '../../../app/constants/common';

import {
  usePhoneTypesQuery,
  useReferralSourcesQuery,
  useCreateAppointmentMutation,
  useMeetingTypesQuery,
} from '../queries';
import { ClientPublicService } from '../../../app/api/ClientPublicService';
import { ROUTES } from '../../../app/routes';

type IForm = Pick<
  IAppointmentDetailsUpdate,
  'firstName' | 'lastName' | 'mainPhoneTypeId' | 'mainPhoneNumber' | 'emailAddress' | 'isEmailPhoneEnabled'
>;

const ContactDetails = () => {
  const { t, getLocalizedDtoName, getLocalizedDate, locale } = useLocale();
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { appointmentDetails, isConfirmed, setIsConfirmed, updateAppointmentDetails } = useBookAppointment();

  const validationSchema = useMemo(
    () =>
      yup.object({
        firstName: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD),
        lastName: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD),
        mainPhoneTypeId: yup.string().required(t.REQUIRED),
        mainPhoneNumber: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD).matches(regexPhone, t.PHONE_NOT_VALID),
        emailAddress: yup.string().email(t.EMAIL_ADDRESS_IS_INVALID).required(t.THIS_IS_A_REQUIRED_FIELD),
        referralSourceTypeId: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD),
        isEmailPhoneEnabled: yup.boolean().oneOf([true], t.YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS),
      }),
    [
      t.EMAIL_ADDRESS_IS_INVALID,
      t.PHONE_NOT_VALID,
      t.REQUIRED,
      t.THIS_IS_A_REQUIRED_FIELD,
      t.YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS,
    ]
  );

  const { data: phoneTypes } = usePhoneTypesQuery() || {};

  const { data: referralSources } = useReferralSourcesQuery() || {};

  const { data: meetingTypes } = useMeetingTypesQuery() || {};

  const { mutate: mutateCreateAppointment, isLoading: isCreatingAppointment } = useCreateAppointmentMutation() || {};

  const showTimeRunOutDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.TIMES_UP}
        message={t.TIME_HAS_RUN_OUT}
        hasCancelButton={false}
        okButtonText={t.SELECT_ANOTHER_TIME_SLOT}
        onOk={() => {
          closeModal();
          updateAppointmentDetails?.({ agentId: undefined });
          navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_TIME}`);
        }}
      />
    );
  }, [
    showModal,
    t.TIMES_UP,
    t.TIME_HAS_RUN_OUT,
    t.SELECT_ANOTHER_TIME_SLOT,
    closeModal,
    updateAppointmentDetails,
    navigate,
  ]);

  const showUnableToBookDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.UNABLE_TO_BOOK_APPOINTMENT}
        message={
          <>
            <span>{t.BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART1}</span>
            <PhoneLink target="_blank" rel="noopener" href="tel:1-855-236-3328">
              1-855-BDO-Debt
            </PhoneLink>
            <span>{t.BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART2}: </span>
            <SiteLink target="_blank" rel="noopener" href={ROUTES.LOGIN}>
              {process.env.REACT_APP_PUBLIC_URL?.replace('https://', '')}
              {ROUTES.LOGIN}
            </SiteLink>
          </>
        }
        hasCancelButton={false}
        okButtonText={t.BACK_TO_HOMEPAGE}
        onOk={() => {
          navigate(ROUTES.ROOT);
          closeModal();
        }}
      />
    );
  }, [
    showModal,
    t.UNABLE_TO_BOOK_APPOINTMENT,
    t.BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART1,
    t.BASED_ON_THE_INFORMATION_PROVIDED_YOU_ALREADY_EXIST_IN_OUR_DATABASE_PART2,
    t.BACK_TO_HOMEPAGE,
    navigate,
    closeModal,
  ]);

  const showTimeslotTakenDialog = useCallback(() => {
    showModal(
      <ConfirmationModal
        title={t.THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE}
        message={t.PLEASE_SELECT_ANOTHER_TIME_SLOT}
        hasCancelButton={false}
        okButtonText={t.SELECT_ANOTHER_TIME_SLOT}
        onOk={() => {
          closeModal();
          navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_TIME}`);
        }}
      />
    );
  }, [
    showModal,
    t.THE_SELECTED_TIME_SLOT_IS_NO_LONGER_AVAILABLE,
    t.PLEASE_SELECT_ANOTHER_TIME_SLOT,
    t.SELECT_ANOTHER_TIME_SLOT,
    closeModal,
    navigate,
  ]);

  const handleSubmit = useCallback(
    (values: IForm) => {
      const url = sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_KEY) || undefined;
      mutateCreateAppointment(
        {
          dateTime: convertToUtcMoment(appointmentDetails?.dateTime),
          meetingTypeEnum: appointmentDetails?.meetingTypeEnum,
          officeLocationId: appointmentDetails?.officeLocationId,
          isPreferedLanguageEnglish: appointmentDetails?.isPreferedLanguageEnglish,
          agentId: appointmentDetails?.agentId,
          url: url,
          ...values,
        } as ClientPublicService.IBookInitialAppointmenInputPublicDto,
        {
          onSuccess: (response) => {
            if (response?.result === ClientPublicService.Result.Successful) {
              sessionStorage.removeItem(UTM_PARAMS_SESSION_STORAGE_KEY);

              if (response?.returnId) {
                navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.CONFIRMATION}/${response?.returnId}`);
              }
            }

            if (response?.hasErrors) {
              if (
                response?.messages?.[0]?.body === 'Public_AppointmentScheduling_Reserved_Timeslot_NoLongerAvailable'
              ) {
                showTimeslotTakenDialog();
              } else {
                toast.error(response?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
              }
            }
          },
          onError: (response) => {
            if (response?.error?.validationErrors?.[0]?.message === 'Public_AppointmentScheduling_Client_Exists') {
              showUnableToBookDialog();
            } else if (
              response?.error?.validationErrors?.[0]?.message &&
              t[response?.error?.validationErrors?.[0]?.message]
            ) {
              toast.error(t[response?.error?.validationErrors?.[0]?.message]);
            } else {
              toast.error(t.SOMETHING_WENT_WRONG);
            }
          },
        }
      );
    },
    [
      appointmentDetails?.agentId,
      appointmentDetails?.dateTime,
      appointmentDetails?.isPreferedLanguageEnglish,
      appointmentDetails?.meetingTypeEnum,
      appointmentDetails?.officeLocationId,
      mutateCreateAppointment,
      navigate,
      showTimeslotTakenDialog,
      showUnableToBookDialog,
      t,
    ]
  );

  const subtitle = useMemo(() => {
    const meetingType = meetingTypes?.find((item) => item?.enumValue === appointmentDetails?.meetingTypeEnum);

    return `${getLocalizedDtoName(meetingType)} - ${getLocalizedDate(
      appointmentDetails?.dateTime,
      MEETING_DATE_FORMAT
    )} - ${getLocalizedDate(appointmentDetails?.dateTime, MEETING_TIME_FORMAT)}`;
  }, [
    appointmentDetails?.dateTime,
    appointmentDetails?.meetingTypeEnum,
    getLocalizedDate,
    getLocalizedDtoName,
    meetingTypes,
  ]);

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed?.(false);
      navigate(ROUTES.ROOT, { replace: true });
    }
  }, [isConfirmed, navigate, setIsConfirmed]);

  useEffect(() => {
    if (!isConfirmed && !appointmentDetails?.dateTime) {
      navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_TIME}`, { replace: true });
    }
  }, [appointmentDetails?.dateTime, isConfirmed, navigate]);

  return (
    <Wrapper loading={isCreatingAppointment}>
      <Grid
        display="flex"
        flexGrow={1}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        data-testid="contact-details-page"
        container
      >
        <Header
          title={t.BOOK_YOUR_APPOINTMENT}
          subtitle={subtitle}
          hasBackButton
          backButtonAction={() => navigate(`${ROUTES.BOOK_APPOINTMENT}/${ROUTES.SELECT_TIME}`)}
          backButtonText={t.CHANGE_TIME_SLOT}
        />
        <ElevatedContainer>
          <Typography variant="h6" textAlign="center">
            {t.HOW_CAN_WE_REACH_YOU}
          </Typography>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              mainPhoneTypeId:
                phoneTypes?.find((item) => item?.enumValue === ClientPublicService.PhoneTypeEnum.Mobile)?.id || '',
              emailAddress: '',
              mainPhoneNumber: '',
              referralSourceTypeId: '',
              isEmailPhoneEnabled: false,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {(form) => (
              <StyledForm>
                <TextField name="firstName" label={t.FIRST_NAME} data-testid="first-name" required />
                <TextField name="lastName" label={t.LAST_NAME} data-testid="last-name" required />
                <Grid container direction="row" spacing={1} alignItems="bottom">
                  <Grid item mobile={4}>
                    <SelectField
                      name="mainPhoneTypeId"
                      label={t.TYPE}
                      data-testid="mainPhoneTypeId"
                      required
                      options={phoneTypes?.filter((item) => item?.code !== 'Fax')}
                      flex={1}
                    />
                  </Grid>
                  <Grid item mobile={8}>
                    <PhoneField
                      name="mainPhoneNumber"
                      label={t.PHONE_NUMBER}
                      data-testid="mainPhoneNumber"
                      required
                      flex={2}
                    />
                  </Grid>
                </Grid>
                <TextField name="emailAddress" label={t.EMAIL} data-testid="emailAddress" required />
                <SelectField
                  name="referralSourceTypeId"
                  label={t.HOW_DID_YOU_HEAR_ABOUT_US}
                  data-testid="referralSourceTypeId"
                  required
                  options={referralSources}
                />
                <CheckboxField
                  name="isEmailPhoneEnabled"
                  label={
                    <span>
                      {t.YES_ALLOW_BDO_TO_CONTACT_YOU}
                      <Link
                        href={
                          locale === ILocale.fr
                            ? `https://www.bdo.ca/fr-ca/legal-and-privacy/privacy-statement`
                            : `https://www.bdo.ca/legal-and-privacy/privacy-statement`
                        }
                        target="_blank"
                        rel="noopener"
                      >
                        {t.PRIVACY_POLICY}
                      </Link>
                    </span>
                  }
                  data-testid="isEmailPhoneEnabled"
                  required
                />
                <FormControl>
                  <Button
                    data-testid="book-appointment-button"
                    disabled={Boolean(!form?.isValid || !form.dirty)}
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    {t.BOOK_APPOINTMENT}
                  </Button>
                </FormControl>
              </StyledForm>
            )}
          </Formik>
        </ElevatedContainer>
        {appointmentDetails && (
          <Countdown
            startCountdownAt={5}
            onCountdownEnd={showTimeRunOutDialog}
            countdownStartedAt={appointmentDetails?.countdownStartedAt}
          />
        )}
      </Grid>
    </Wrapper>
  );
};

export default ContactDetails;

const PhoneLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const SiteLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
