import { useMemo } from 'react';
import { toast } from 'react-toastify';

import EditModal, { IEditModalProps } from '../../../../../../components/EditModal';
import EditIncomeComponent from '../../../../../../components/EditIncomeComponent/EditIncomeComponents';

import useLocale from '../../../../../../app/hooks/useLocale';

import {
  useListIncomeTypesQuery,
  useApplicationFileQuery,
  useAppFormIncomesQuery,
  useUpdateIncomeMutation,
} from '../../queries';

import { IIncomeType } from '../../types';
import { getIncomeFormItem, convertFormItemToIncomeDto } from './utils';
import { IIncomeSubmitValues } from '../../../../../../components/EditIncomeComponent/types';

import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

type IEditIncomeModalProps = Pick<IEditModalProps, 'onCancel'> & {
  onOk?: () => void;
  isMissingDocuments?: boolean;
};

const EditIncomeModal = ({ onOk, onCancel, isMissingDocuments }: IEditIncomeModalProps) => {
  const { t } = useLocale();

  const { data: appFile } = useApplicationFileQuery() || {};
  const { data: incomes } = useAppFormIncomesQuery(appFile?.id) || {};
  const { data: incomeTypes } = useListIncomeTypesQuery() || {};

  const isLocked = useMemo(
    () => appFile?.isLocked || appFile?.isAppFormLocked,
    [appFile?.isLocked, appFile?.isAppFormLocked]
  );

  const { mutate: updateIncomes } = useUpdateIncomeMutation() || {};

  const initialValues = useMemo(() => {
    const incomeDetails = incomeTypes?.map((incomeType?: IIncomeType) => {
      const appFormIncomeRecord = incomes?.incomeDetails?.find((detail) => detail?.incomeTypeId === incomeType?.id);
      return {
        ...getIncomeFormItem(appFormIncomeRecord || { incomeTypeId: incomeType?.id }),
        incomeType,
      };
    });

    return {
      isMarried: incomes?.isMarried,
      incomeDetails: incomeDetails?.sort(
        (a, b) =>
          +(a?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other) -
          +(b?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other)
      ),
      documents: undefined,
    };
  }, [incomeTypes, incomes?.incomeDetails, incomes?.isMarried]);

  return (
    <EditModal title={t.MONTHLY_INCOME} onCancel={onCancel}>
      <EditIncomeComponent
        initialValues={initialValues}
        isMissingDocuments={isMissingDocuments}
        documentsUploadProps={{
          documentIds: incomes?.documentIds,
          onlyRetrieveByDocumentIds: true,
        }}
        isEditable={!isLocked}
        onCancel={onCancel}
        onSubmit={(updatedValues?: IIncomeSubmitValues) => {
          updateIncomes(
            {
              ...incomes,
              documentIds: updatedValues?.documentIds as string[],
              incomeDetails:
                updatedValues?.incomeDetails
                  ?.map((item) => convertFormItemToIncomeDto(item))
                  ?.filter(
                    (converted) =>
                      converted?.applicantAmount !== undefined ||
                      converted?.spouseAmount !== undefined ||
                      converted?.otherHouseholdMemberAmount !== undefined
                  ) || [],
            },
            {
              onSuccess: () => {
                toast.success(t.SUCCESSFULLY_SAVED);
                onOk?.();
              },
              onError: () => {
                toast.error(t.SOMETHING_WENT_WRONG);
              },
            }
          );
        }}
      />
    </EditModal>
  );
};

export default EditIncomeModal;
