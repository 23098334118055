import { useEffect, useMemo, useState, useCallback } from 'react';
import { Stack } from '@mui/material';
import { toast } from 'react-toastify';

import useLocale from '../../../../app/hooks/useLocale';
import { Formik } from 'formik';
import yup from '../../../../app/utils/customYup';
import ElevatedContainer from '../../../../components/ElevatedContainer';
import { convertFormValuesToDto, getInitialIncomeTaxValues } from './utils';
import { useIncomeTaxQuery, useUpdateIncomeTaxMutation } from './queries';
import { useApplicationFileQuery } from '../../queries';
import { IAppFormIncomeTax } from './types';
import ErrorList from './ErrorList';
import IncomeTaxForm from './IncomeTaxForm';
import useAppForm from '../../hooks/useAppForm';
import { ROUTES } from '../../../../app/routes';
import { useNavigate } from 'react-router-dom';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import UnsavedForm from '../../components/UnsavedForm/UnsavedForm';
import SectionLockMessage from '../../components/SectionLockMessage';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';
import NavigationButtons from '../../components/NavigationButtons';

const IncomeTax = () => {
  const { data: appFile } = useApplicationFileQuery() || {};
  const { t } = useLocale();
  const { updateAppFormStatus, appFormStatus, appFileData } = useAppForm() || {};
  const navigate = useNavigate();
  const [isMarried, setIsMarried] = useState(true);
  const [showErrorList, setShowErrorsList] = useState(false);

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.incomeTaxStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.incomeTaxStatus]
  );

  const { data: incomeTaxInfo, isSuccess } = useIncomeTaxQuery(appFile?.id) || {};

  const { mutate: updateIncomeTaxMutation } = useUpdateIncomeTaxMutation() || {};

  const initialValues = useMemo(() => getInitialIncomeTaxValues(incomeTaxInfo), [incomeTaxInfo]);

  useEffect(() => {
    setIsMarried(initialValues?.isMarried ?? false);
  }, [initialValues]);

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const handleSubmit = useCallback(
    async (values: IAppFormIncomeTax, onSuccess?: () => void) => {
      updateIncomeTaxMutation(
        {
          ...convertFormValuesToDto(values),
          documentIds: [] as string[],
        },
        {
          onSuccess: () => {
            updateAppFormStatus?.();
            toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.INCOME_TAX));

            if (onSuccess) {
              onSuccess();
            } else {
              navigate(`${ROUTES.APP_FORM}/${ROUTES.BANKING}`);
            }
          },
        }
      );
    },
    [navigate, t.INCOME_TAX, t.MODULE_SAVED_SUCCESSFULLY, updateAppFormStatus, updateIncomeTaxMutation]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        yearLastReturnFiled: yup
          .date()
          .typeError(t.INVALID_DATE)
          .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
          .recommended(getRecommendedByLabel(t.YEAR_LAST_RETURN_FILED)),
        refundAmount: yup.number().nullable(),
        amountOwing: yup.number().nullable(),
        spouseYearLastReturnFiled: yup
          .date()
          .typeError(t.INVALID_DATE)
          .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
          .nullable(),
        spouseRefundAmount: yup.number().nullable(),
        spouseAmountOwing: yup.number().nullable(),
      }),
    [getRecommendedByLabel, t.DATE_CANNOT_BE_IN_THE_FUTURE, t.INVALID_DATE, t.YEAR_LAST_RETURN_FILED]
  );

  const isFormStarted = useMemo(
    () => Boolean(appFormStatus?.incomeTaxStatus !== ClientPublicService.AppFormStatusEnum.NotStarted),
    [appFormStatus?.incomeTaxStatus]
  );

  useEffect(() => {
    setShowErrorsList(isFormStarted && isSuccess && !isLocked);
  }, [isFormStarted, isLocked, isSuccess]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_: IAppFormIncomeTax, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={showErrorList}
        validationSchema={validationSchema}
        validateOnBlur={showErrorList}
      >
        {({ values, submitForm }) => (
          <UnsavedForm onSubmit={handleSubmit}>
            {showErrorList ? <ErrorList /> : <></>}
            <ElevatedContainer sx={{ borderRadius: 1.5, pt: 2, pb: 2, pl: 1.5, pr: 1.5 }}>
              <Stack>
                {isLocked && <SectionLockMessage />}

                <IncomeTaxForm isMarried={isMarried} isLocked={isLocked} />
              </Stack>
            </ElevatedContainer>
            <NavigationButtons
              onNext={async () => {
                if (isLocked) {
                  navigate(`${ROUTES.APP_FORM}/${ROUTES.BANKING}`);
                } else {
                  await submitForm();
                  handleSubmit(values, () => {
                    navigate(`${ROUTES.APP_FORM}/${ROUTES.BANKING}`);
                  });
                }
              }}
              onPrev={async () => {
                if (isLocked) {
                  navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_EXPENSE}`);
                } else {
                  await submitForm();
                  handleSubmit(values, () => {
                    navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_EXPENSE}`);
                  });
                }
              }}
              currentSection={AppformSection.IncomeTax}
              disabled={isLocked}
            />
          </UnsavedForm>
        )}
      </Formik>
    </>
  );
};

export default IncomeTax;
