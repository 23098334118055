import { useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import useLocale from '../../../../../../app/hooks/useLocale';

import { IDebtFormValues } from './EditDebtModal';

const recommendedFields = ['specifiedDescription', 'accountNumber'];

const EditDebtFooter = ({
  onCancel,
  onOk,
  isLocked,
}: {
  onCancel?: () => void;
  onOk?: () => void;
  isLocked?: boolean;
}) => {
  const { t } = useLocale();

  const { errors, touched, dirty, values } = useFormikContext<IDebtFormValues>();

  const hasErrors = useMemo(
    () => Object.keys(errors)?.filter((item) => !recommendedFields?.includes(item))?.length > 0,
    [errors]
  );

  return (
    <>
      <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap">
        <Grid item mobile={6} tablet={4}>
          <Button onClick={onCancel} variant="outlined" data-testid="cancel-button" fullWidth>
            {t.CANCEL}
          </Button>
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Button
            variant="contained"
            data-testid="ok-button"
            fullWidth
            onClick={onOk}
            disabled={values?.id ? hasErrors : !touched || !dirty || hasErrors || isLocked}
          >
            {t.SAVE}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditDebtFooter;
