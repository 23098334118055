import { Container, useTheme, Box, Typography, Stack } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';
import useLocale from '../../app/hooks/useLocale';

const RegistrationLinkExpired = () => {
  const theme = useTheme();
  const { t } = useLocale();

  return (
    <Container
      sx={{
        backgroundColor: theme.palette.slate,
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          margin: { mobile: 3 },
          maxWidth: { desktop: 700 },
          borderRadius: 5,
          overflow: 'hidden',
          backgroundColor: theme.palette.white,
          pl: 4,
          pr: 4,
          pt: 1,
          pb: 2,
          alignItems: 'center',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <EmailIcon sx={{ fontSize: 64, color: theme.palette.primary.main }} />
          <CancelIcon
            sx={{ fontSize: 32, color: theme.palette.urgentRed, position: 'absolute', right: -2, bottom: 10 }}
          />
        </Box>

        <Typography sx={{ fontSize: 35, fontWeight: 700, pt: 1.5, pb: 2 }}>{t.EXPIRED_LINK}</Typography>
        <Typography variant="body2">{t.THIS_REGISTRATION_LINK_HAS_EXPIRED}</Typography>
      </Stack>
    </Container>
  );
};

export default RegistrationLinkExpired;
