import { rest } from 'msw';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';

import { IAssetDescription, IAssetType, IDebtDescription } from './types';

export const listAssets: ClientPublicService.IAppFormAssetsDebtsAssetListItem[] = [
  {
    id: '6ddaae3e-d2e8-b92f-0a20-3a0b61d7917b',
    assetType: {
      id: '82b73749-f660-26bf-614a-3a0af9950d3c',
      orderNumber: 5,
      code: undefined,
      name: 'Real Estate',
      enumValue: 4,
      displayName: undefined,
      description: undefined,
    } as IAssetType,
    assetDescription: {
      id: 'fe8b2936-d8e6-6b58-83a1-3a0af9950d3c',
      orderNumber: 1,
      code: undefined,
      name: 'House',
      enumValue: 23,
      displayName: undefined,
      description: undefined,
    } as IAssetDescription,
    specifiedDescription: undefined,
    value: 1000000,
    displayName: 'Real Estate - House - $1,000,000',
    isMissingDetails: false,
    isMissingSupportingDocuments: false,
  },
];

export const listDebts: ClientPublicService.IAppFormAssetsDebtsDebtListItem[] = [
  {
    id: '51e16b1e-496f-1da1-5339-3a0b61d836d5',
    debtDescription: {
      id: '00000000-0000-0000-0000-000000000000',
      orderNumber: 2,
      code: undefined,
      name: 'Credit Card',
      enumValue: 1,
      displayName: undefined,
      description: undefined,
    } as IDebtDescription,
    creditorName: 'TD',
    amount: 1000,
    isSecured: false,
    displayName: 'Credit Card - TD - $1,000',
    isMissingDetails: false,
  },
];

const listAppFormAssetsGET = rest.get('/listAssets', (req, res, ctx) => {
  return res(ctx.json(listAssets));
});

const listAppFormDebtsGET = rest.get('/listDebts', (req, res, ctx) => {
  return res(ctx.json(listDebts));
});

export const handlers = [listAppFormAssetsGET, listAppFormDebtsGET];
