import { Grid } from '@mui/material';

import { SelectField, PhoneField } from '../../FormFields';

import useLocale from '../../../app/hooks/useLocale';

import { usePhoneTypesQuery } from './queries';

interface IPhoneTypeNumberFieldsProps {
  phoneTypeFieldName: string;
  phoneTypeFieldLabel?: string;
  phoneTypeFieldProps?: any;
  phoneNumberFieldName: string;
  phoneNumberFieldLabel?: string;
  phoneNumberFieldProps?: any;
  isFaxExcluded?: boolean;
}

const PhoneTypeNumberFields = ({
  phoneTypeFieldName,
  phoneTypeFieldLabel,
  phoneTypeFieldProps,
  phoneNumberFieldName,
  phoneNumberFieldLabel,
  phoneNumberFieldProps,
  isFaxExcluded = true,
}: IPhoneTypeNumberFieldsProps) => {
  const { t } = useLocale();
  const { data: phoneTypes } = usePhoneTypesQuery() || {};

  return (
    <Grid container direction="row" spacing={1} alignItems="bottom">
      <Grid item mobile={4}>
        <SelectField
          {...phoneTypeFieldProps}
          name={phoneTypeFieldName}
          label={phoneTypeFieldLabel || t.TYPE}
          data-testid="phoneTypeId"
          options={isFaxExcluded ? phoneTypes?.filter((item) => item?.name !== 'Fax') : phoneTypes}
          flex={1}
        />
      </Grid>
      <Grid item mobile={8}>
        <PhoneField
          {...phoneNumberFieldProps}
          name={phoneNumberFieldName}
          label={phoneNumberFieldLabel || t.PHONE_NUMBER}
          data-testid="phoneNumber"
          flex={2}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneTypeNumberFields;
