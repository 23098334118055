import { createContext, useState, ReactElement, useCallback } from 'react';

interface IAttachmentContext {
  fieldsToExclude?: string[];
  updateExclusionArray: (fieldsToExclude: string[]) => void;
  resetExclusionArray: () => void;
}

interface IProps {
  children: ReactElement;
}

export const initialUnsavedFormContextValues = {
  fieldsToExclude: [],
  updateExclusionArray: () => {},
  resetExclusionArray: () => {},
};

export const UnsavedFormContext = createContext<IAttachmentContext>(initialUnsavedFormContextValues);

const UnsavedFormProvider = ({ children }: IProps) => {
  const [fieldsToExclude, setFieldsToExclude] = useState<string[]>([]);

  const updateExclusionArray = useCallback(
    (newFieldsToExclude: string[]) => {
      setFieldsToExclude([...fieldsToExclude, ...newFieldsToExclude]);
    },
    [fieldsToExclude]
  );

  const resetExclusionArray = useCallback(() => {
    setFieldsToExclude([]);
  }, []);

  return (
    <UnsavedFormContext.Provider value={{ fieldsToExclude, updateExclusionArray, resetExclusionArray }}>
      {children}
    </UnsavedFormContext.Provider>
  );
};

export default UnsavedFormProvider;
