import { useCallback, useEffect, useState } from 'react';
import { Stack, Typography, useTheme, Button, Menu, MenuItem, Box } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import PaymentDataItem from './PaymentDataItem';
import PaymentDetailsModal from './PaymentDetailsModal/PaymentDetailsModal';
import PaymentDataItemSkeleton from './PaymentDataItemSkeleton';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';

import {
  usePaymentsInformationQuery,
  useApplicationFileQuery,
  useOutstadingPaymentsCountQuery,
  useUpcomingPaymentsCountQuery,
  usePastPaymentsCountQuery,
} from '../queries';
import moment, { inputDateToServerFormat } from '../../../app/utils/dateTimeHelpers';

const MAX_PER_PAGE = 10;

const PaymentsCard = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const { showModal, closeModal } = useModal();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isUpcoming, setIsUpcoming] = useState<boolean | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: applicationFile } = useApplicationFileQuery() || {};

  const { data: pastPaymentsCount } = usePastPaymentsCountQuery(applicationFile?.id) || {};

  const { data: outstandingPaymentsCount } = useOutstadingPaymentsCountQuery(applicationFile?.id) || {};

  const { data: upcomingPaymentsCount } = useUpcomingPaymentsCountQuery(applicationFile?.id) || {};

  const {
    data: debitInformation,
    isLoading: isLoadingPayments,
    refetch,
  } = usePaymentsInformationQuery({
    fileId: applicationFile?.id as string,
    receiptDateFrom: isUpcoming === true ? inputDateToServerFormat(moment()) : undefined,
    receiptDateTo: isUpcoming === false ? inputDateToServerFormat(moment()) : undefined,
  }) || {};

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePaymentItemClick = useCallback(
    (paymentId?: string) => {
      showModal(
        <PaymentDetailsModal
          paymentId={paymentId as string}
          fileId={applicationFile?.id as string}
          onCancel={closeModal}
          onOk={() => {
            closeModal();
            refetch();
          }}
        />
      );
    },
    [applicationFile?.id, closeModal, refetch, showModal]
  );

  useEffect(() => {
    if (isUpcoming === undefined && outstandingPaymentsCount !== undefined) {
      setIsUpcoming(!Boolean(outstandingPaymentsCount));
    }
  }, [isUpcoming, outstandingPaymentsCount]);

  return (
    <Stack>
      <Typography variant="h6" fontWeight={600} aria-label={t.PAYMENTS}>
        {t.PREAUTHORIZED_PAYMENTS}
      </Typography>
      {isUpcoming !== undefined && (
        <Box pb={1.5}>
          <Button
            id="menu-button"
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              p: 0,
              minWidth: 0,
              fontSize: 14,
              color: theme.palette.secondary.main,
              '&.MuiButton-root:hover:not(.Mui-disabled), &.MuiButton-root:focus:not(.Mui-disabled)': {
                color: theme.palette.secondary.main,
              },
            }}
          >
            {isUpcoming ? t.UPCOMING : t.PAST}
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
          <Menu
            id="menu"
            MenuListProps={{
              'aria-labelledby': 'menu-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsUpcoming(true);
                handleClose();
              }}
              disabled={!Boolean(upcomingPaymentsCount)}
            >
              {t.UPCOMING}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsUpcoming(false);
                handleClose();
              }}
              disabled={!Boolean(pastPaymentsCount)}
            >
              {t.PAST}
            </MenuItem>
          </Menu>
        </Box>
      )}
      {isLoadingPayments && !debitInformation && <PaymentDataItemSkeleton />}
      {(isExpanded ? debitInformation : debitInformation?.slice(0, MAX_PER_PAGE))?.map((item, index) => (
        <PaymentDataItem key={index} data={item} clickable onClick={() => handlePaymentItemClick(item?.id)} />
      ))}

      {debitInformation && debitInformation?.length > MAX_PER_PAGE && (
        <Button
          variant="text"
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            p: 0,
            color: theme.palette.secondary.main,
            '&.MuiButton-root:hover:not(.Mui-disabled)': { color: theme.palette.secondary.main },
          }}
        >
          <Stack alignItems="center" flexDirection="row" spacing={1}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            {isExpanded ? t.SHOW_LESS : t.SHOW_MORE}
          </Stack>
        </Button>
      )}
    </Stack>
  );
};

export default PaymentsCard;
