import { useMemo, useContext } from 'react';
import { Stack, Typography, Grid, Skeleton, useTheme } from '@mui/material';

import DonutDiagram from '../../../components/DonutDiagram';

import useLocale from '../../../app/hooks/useLocale';

import { DashboardContext } from '../DashboardProvider';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const ProgressAndStatusInfo = () => {
  const { t } = useLocale();
  const theme = useTheme();

  const { signupStatus } = useContext(DashboardContext);

  const progressStatus = useMemo(() => signupStatus?.preSignupProgress, [signupStatus?.preSignupProgress]);

  const progressNumber = (): string => {
    switch (progressStatus) {
      case ClientPublicService.PreSignupProgressEnum.InitialConsultation:
        return '1';
      case ClientPublicService.PreSignupProgressEnum.GatherDocuments:
        return '2';
      case ClientPublicService.PreSignupProgressEnum.SignUp:
        return '3';
      case ClientPublicService.PreSignupProgressEnum.FileInProgress:
        return '3';
      default:
        return '0';
    }
  };

  const chartValue = (): number =>
    progressStatus === ClientPublicService.PreSignupProgressEnum.FileInProgress ? 0 : 33;

  const chartCompletedValue = (): number => {
    switch (progressStatus) {
      case ClientPublicService.PreSignupProgressEnum.InitialConsultation:
        return 0;
      case ClientPublicService.PreSignupProgressEnum.GatherDocuments:
        return 33;
      case ClientPublicService.PreSignupProgressEnum.SignUp:
        return 67;
      case ClientPublicService.PreSignupProgressEnum.FileInProgress:
        return 100;
      default:
        return 0;
    }
  };

  const progressNextStep = (): string => {
    switch (progressStatus) {
      case ClientPublicService.PreSignupProgressEnum.InitialConsultation:
        return t.GATHER_DOCUMENTS;
      case ClientPublicService.PreSignupProgressEnum.GatherDocuments:
        return t.SIGN_UP;
      case ClientPublicService.PreSignupProgressEnum.SignUp:
        return t.FILE_IN_PROGRESS;
      case ClientPublicService.PreSignupProgressEnum.FileInProgress:
        return t.YOUR_DEBT_RELIEF_SPECIALIST_WILL_CONTACT_YOU;
      default:
        return t.InitialConsultation;
    }
  };

  const progressCurrentStep = (): string => {
    switch (progressStatus) {
      case ClientPublicService.PreSignupProgressEnum.InitialConsultation:
        return t.InitialConsultation;
      case ClientPublicService.PreSignupProgressEnum.GatherDocuments:
        return t.GATHER_DOCUMENTS;
      case ClientPublicService.PreSignupProgressEnum.SignUp:
        return t.SIGN_UP;
      case ClientPublicService.PreSignupProgressEnum.FileInProgress:
        return t.YOU_HAVE_SIGNED_UP;
      default:
        return '';
    }
  };

  const progressCurrentText = (): string => {
    switch (progressStatus) {
      case ClientPublicService.PreSignupProgressEnum.InitialConsultation:
        return t.COMPLETE_YOUR_APPLICATION_FORM_AND_ATTENT_YOUR_INITIAL_CONSULTATION;
      case ClientPublicService.PreSignupProgressEnum.GatherDocuments:
        return t.COMPLETE_YOUR_APPLICATION_FORM_AND_UPLOAD_ALL_SUPPORTING_DOCUMENTS;
      case ClientPublicService.PreSignupProgressEnum.SignUp:
        return t.COMPLETE_YOUR_SIGNUP_PROCESS;
      default:
        return '';
    }
  };

  return (
    <>
      <Stack>
        <Grid container rowSpacing={1}>
          <Grid item mobile={8}>
            {Boolean(progressStatus) ? (
              <Typography variant="h6" fontWeight={600} aria-label={`${progressCurrentStep()}`}>
                {`${progressCurrentStep()}`}
              </Typography>
            ) : (
              <Skeleton width="60%" />
            )}
            {Boolean(progressStatus) ? (
              <Typography variant="body2" fontWeight={600} color="secondary">
                {`${t.NEXT}: ${progressNextStep()}`}
              </Typography>
            ) : (
              <Skeleton />
            )}
          </Grid>
          <Grid item mobile={4} display={'flex'} justifyContent={'flex-end'}>
            {Boolean(progressStatus) && (
              <DonutDiagram
                innerText={`${progressNumber()} ${t.OF} 3`}
                items={[
                  { value: chartCompletedValue(), color: theme.palette.successGreen },
                  { value: chartValue(), color: theme.palette.cobalt },
                ]}
                thickness={5}
              />
            )}
          </Grid>
        </Grid>
        {Boolean(progressStatus) ? (
          <Typography variant="body2" sx={{ pb: 1, pt: 3, fontSize: 13 }} textAlign="center">
            {`${progressCurrentText()}`}
          </Typography>
        ) : (
          <Skeleton />
        )}
      </Stack>
    </>
  );
};

export default ProgressAndStatusInfo;
