import { Stack, Skeleton } from '@mui/material';

const PaymentDataItemSkeleton = () => (
  <Stack pt={1.5}>
    <Skeleton width={200} />
    <Skeleton />
  </Stack>
);

export default PaymentDataItemSkeleton;
