import { useCallback, useMemo } from 'react';
import { Stack, useTheme, Grid, useMediaQuery, Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableCard from '../../../../components/TableCard';
import SummaryCard from '../../../../components/SummaryCard';
import ElevatedContainer from '../../../../components/ElevatedContainer';
import AmountCellWithDeleteAction from '../../../../components/AmountCellWithDeleteAction';
import EditExpenseModal from './components/EditExpenseModal/EditExpenseModal';
import EditIncomeModal from './components/EditIncomesModal/EditIncomeModal';
import LabelWithError from '../../../../components/LabelWithError';

import useLocale from '../../../../app/hooks/useLocale';
import useModal from '../../../../app/hooks/useModal';
import useAppForm from '../../hooks/useAppForm';

import { ROUTES } from '../../../../app/routes/constants';
import {
  useListIncomesQuery,
  useListExpensesQuery,
  useDeleteExpenseMutation,
  useDeleteIncomeMutation,
} from './queries';
import { useApplicationFileQuery } from '../../queries';
import { IIncomeType } from './types';

import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import SectionLockMessage from '../../components/SectionLockMessage';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';
import NavigationButtons from '../../components/NavigationButtons';

const AssetsDebts = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { updateAppFormStatus, appFormStatus, appFileData } = useAppForm() || {};
  const navigate = useNavigate();

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.incomeExpenseStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.incomeExpenseStatus]
  );

  const { showModal, closeModal } = useModal();

  const { data: appFile } = useApplicationFileQuery() || {};

  const { data: incomes } = useListIncomesQuery(appFile?.id) || {};

  const { data: expenses } = useListExpensesQuery(appFile?.id) || {};

  const { mutate: deleteIncome } = useDeleteIncomeMutation() || {};

  const { mutate: deleteExpense } = useDeleteExpenseMutation() || {};

  const totalIncome = incomes?.map((item) => item?.amount || 0).reduce((sum, cur) => sum + cur, 0);

  const totalExpense = expenses?.map((item) => item?.amount || 0).reduce((sum, cur) => sum + cur, 0);

  const handleEditIncomeButtonClick = useCallback(() => {
    showModal(
      <EditIncomeModal
        onOk={() => {
          closeModal();
          updateAppFormStatus?.();
        }}
        onCancel={closeModal}
        isMissingDocuments={incomes?.some((item) => item?.isSupportingDocumentsIncomplete)}
      />
    );
  }, [closeModal, incomes, showModal, updateAppFormStatus]);

  const handleEditExpensesButtonClick = useCallback(() => {
    showModal(
      <EditExpenseModal
        onOk={() => {
          closeModal();
          updateAppFormStatus?.();
        }}
        onCancel={closeModal}
      />
    );
  }, [closeModal, showModal, updateAppFormStatus]);

  const incomesColumns = [
    {
      title: t.TYPE,
      key: 'incomeType',
      dataIndex: 'incomeType',
      render: (item: IIncomeType, record: ClientPublicService.AppFormIncomeExpenseIncomeDetailListItem) => (
        <LabelWithError label={getLocalizedDtoName(item)} hasError={record?.isSupportingDocumentsIncomplete} />
      ),
    },
    {
      title: t.AMOUNT,
      key: 'amount',
      dataIndex: 'amount',
      render: (value: number, record: ClientPublicService.AppFormIncomeExpenseIncomeDetailListItem) => (
        <AmountCellWithDeleteAction
          value={value}
          onDelete={() => deleteIncome(record?.id as string)}
          disabled={isLocked}
        />
      ),
      width: '40%',
    },
  ];

  const expensesColumns = [
    {
      title: t.DESCRIPTION,
      key: 'expenseDescription',
      dataIndex: 'expenseDescription',
      render: (_: string, record: ClientPublicService.AppFormIncomeExpenseExpenseDetailListItem) => (
        <LabelWithError
          label={getLocalizedDtoName(record?.expenseDescription)}
          hasError={record?.isSupportingDocumentsIncomplete}
        />
      ),
    },
    {
      title: t.AMOUNT,
      key: 'amount',
      dataIndex: 'amount',
      render: (value: number, record: ClientPublicService.AppFormIncomeExpenseExpenseDetailListItem) => (
        <AmountCellWithDeleteAction
          value={value}
          onDelete={() => deleteExpense(record?.id as string)}
          disabled={isLocked}
        />
      ),
      width: '40%',
    },
  ];

  const CardContainer = isDesktop ? RoundedCornersContainer : Box;

  const balance = useMemo(() => (totalIncome || 0) - (totalExpense || 0), [totalIncome, totalExpense]);

  return (
    <>
      {isLocked && <SectionLockMessage />}

      <Stack spacing={1.5}>
        <CardContainer>
          <SummaryCard
            items={[
              {
                label: t.MONTHLY_INCOME,
                value: totalIncome,
                labelColor: 'green',
              },
              {
                label: t.MONTHLY_EXPENSE,
                value: totalExpense,
                labelColor: 'red',
              },
              {
                label: balance >= 0 ? t.BUDGET_SURPLUS : t.BUDGET_DEFICIT,
                value: Math.abs(balance),
                labelColor: balance >= 0 ? 'green' : 'red',
              },
            ]}
          />
        </CardContainer>
        <CardContainer>
          <Grid container rowSpacing={{ mobile: 1.5, desktop: 2 }} columnSpacing={{ mobile: 0, desktop: 4 }}>
            <Grid item mobile={12} desktop>
              <TableCard
                title={t.INCOME}
                hasEditButton
                onEditButtonClick={handleEditIncomeButtonClick}
                isEditButtonDisabled={isLocked}
                tableProps={{
                  data: incomes,
                  columns: incomesColumns,
                  emptyValue: t.CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_INCOME,
                  onRowClick: handleEditIncomeButtonClick,
                }}
              />
            </Grid>
            <Grid item mobile={12} desktop>
              <TableCard
                title={t.EXPENSES}
                hasEditButton
                onEditButtonClick={handleEditExpensesButtonClick}
                isEditButtonDisabled={isLocked}
                tableProps={{
                  data: expenses,
                  columns: expensesColumns,
                  emptyValue: t.CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_EXPENSES,
                  onRowClick: handleEditExpensesButtonClick,
                }}
              />
            </Grid>
          </Grid>
        </CardContainer>
      </Stack>

      <NavigationButtons
        onNext={() => {
          if (!isLocked) toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.INCOME_EXPENSE));
          navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_TAX}`);
        }}
        onPrev={() => {
          navigate(`${ROUTES.APP_FORM}/${ROUTES.ASSETS_DEBTS}`);
        }}
        currentSection={AppformSection.IncomeExpense}
        disabled={isLocked}
      />
    </>
  );
};

export default AssetsDebts;

const RoundedCornersContainer = styled(ElevatedContainer)(() => ({
  borderRadius: 12,
}));
