import { useMemo } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import CustomLabel from '../CustomLabel';
import { useFormikContext, useField } from 'formik';
import { FormHelperText, useTheme } from '@mui/material';

import moment, { disableDateBefore, disableDateAfter } from '../../app/utils/dateTimeHelpers';

interface IFormValue {
  [key: string]: any;
}

type IDatePickerFieldProps = any;
interface IDatePickerFieldPropsExtended extends IDatePickerFieldProps {
  recommended?: boolean;
  recommendedWarningText?: string;
  format?: string;
}

const DatePickerField = ({ recommended, recommendedWarningText, ...props }: IDatePickerFieldPropsExtended) => {
  const { setFieldValue } = useFormikContext<IFormValue>();

  const [, meta] = useField(props?.name);
  const theme = useTheme();

  const isRecommendedError = useMemo(
    () => meta?.error && meta?.touched && meta.error?.includes('[recommended]'),
    [meta?.error, meta?.touched]
  );

  return (
    <>
      <DatePicker
        {...props}
        label={<CustomLabel label={props?.label} required={props?.required} recommended={recommended} />}
        sx={{
          width: '100%',
          m: 1,
          marginLeft: 0,
          marginRight: 0,
          ...(isRecommendedError
            ? {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${
                    props?.disabled ? theme.palette.disabledGray : theme.palette.warningOrange
                  } !important`,
                },
                '.MuiFormHelperText-root.Mui-error': {
                  display: 'none !important',
                },
                '.Mui-error': {
                  color: `${theme.palette.warningOrange} !important`,
                },
              }
            : {}),
        }}
        value={meta?.value || null}
        onChange={(date?: moment.Moment | null) => {
          setFieldValue(props.name, date, true);
        }}
        localeText={{
          fieldMonthPlaceholder: () => 'MMM',
        }}
        reduceAnimations
        slotProps={{
          textField: {
            name: props?.name,
            error: meta?.touched && !!meta?.error,
            helperText: meta?.touched && !!meta.error ? meta?.error : props?.helperText,

            onBlur: (e: any) => {
              if (
                !meta?.value?.isValid() ||
                (props?.disableFuture && disableDateAfter(meta?.value)) ||
                (props?.disablePast && disableDateBefore(meta?.value))
              ) {
                setFieldValue(props.name, null, true);
              }
            },
          },
          ...props?.slotProps,
        }}
        data-testid={props['data-testid']}
      />
      {isRecommendedError && (
        <FormHelperText
          style={{
            marginTop: -3,
            marginLeft: 14,
            color: theme.palette.warningOrange,
          }}
        >
          {meta?.error?.replace('[recommended]', '')}
        </FormHelperText>
      )}
    </>
  );
};

export default DatePickerField;
