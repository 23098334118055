import { ClientPublicService } from '../../app/api/ClientPublicService';

import { IExpenseDescriptionType } from '../../features/AppForm/modules/IncomeExpense/types';
import { IFormValues } from './types';

const isOther = (expenseDescription?: IExpenseDescriptionType) =>
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_NonDiscretionary ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Housing ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Living ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Transportation ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Personal ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Insurance ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Payments ||
  expenseDescription?.enumValue === ClientPublicService.ExpenseDescriptionEnum.Other_Medical;

const isExpenseFilled = (enumValue?: ClientPublicService.ExpenseDescriptionEnum, values?: IFormValues) =>
  Boolean(
    values?.expenseDetails?.find(
      (item: any) =>
        item?.expenseDescription?.enumValue === enumValue &&
        ((item?.applicantAmount || 0) > 0 ||
          (item?.spouseAmount || 0) > 0 ||
          (item?.otherHouseholdMemberAmount || 0) > 0)
    )
  );

export { isOther, isExpenseFilled };
