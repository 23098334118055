import { useFormikContext, getIn } from 'formik';
import ToggleGroupField from '../../FormFields/ToggleGroupField';
import TextField from '../../FormFields/TextField';

import useLocale from '../../../app/hooks/useLocale';

interface IToggleWithInputFieldsProps {
  toggleFieldName: string;
  toggleFieldLabel?: string;
  toggleFieldProps?: any;
  inputFieldName: string;
  inputFieldLabel?: string;
  inputFieldProps?: any;
  disabled?: boolean;
}

const ToggleWithInputFields = ({
  toggleFieldLabel,
  toggleFieldName,
  toggleFieldProps,
  inputFieldLabel,
  inputFieldName,
  inputFieldProps,
  disabled,
}: IToggleWithInputFieldsProps) => {
  const { t } = useLocale();
  const { values } = useFormikContext();

  return (
    <>
      <ToggleGroupField
        label={toggleFieldLabel}
        name={toggleFieldName}
        disabled={disabled}
        {...toggleFieldProps}
        exclusive
        data={[
          {
            value: true,
            label: t.YES,
          },
          {
            value: false,
            label: t.NO,
          },
        ]}
      />
      {Boolean(getIn(values, toggleFieldName)) && (
        <TextField
          label={inputFieldLabel || t.DETAILS}
          name={inputFieldName}
          multiline
          maxRows={3}
          minRows={3}
          inputProps={{ maxLength: 500 }}
          disabled={disabled}
          {...inputFieldProps}
        />
      )}
    </>
  );
};

export default ToggleWithInputFields;
