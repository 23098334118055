import { Container, ContainerProps, useMediaQuery, useTheme } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import PageHeader, { IPageHeaderProps } from './PageHeader/PageHeader';
import Loader from './Loader';

import useAuthService from '../app/hooks/useAuthService';
import { drawerWidth } from './AppDrawer';

interface IBodyContainerProps extends ContainerProps {
  headerProps?: IPageHeaderProps;
  loading?: boolean;
  children?: React.ReactElement | React.ReactElement[];
}

const PageContentContainer = ({ headerProps, loading = false, children, ...props }: IBodyContainerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { isAuthenticated } = useAuthService();

  const fetchingCount = useIsFetching();
  const mutatingCount = useIsMutating();

  return (
    <Container {...props} sx={{ p: 2, paddingLeft: { desktop: 4 }, paddingRight: { desktop: 4 }, ...props?.sx }}>
      <PageHeader {...headerProps} />
      {children}
      <Loader
        loading={Boolean(fetchingCount) || Boolean(mutatingCount) || loading}
        left={isDesktop && isAuthenticated() ? drawerWidth : 0}
      />
    </Container>
  );
};

export default PageContentContainer;
