import { IMaskInput } from 'react-imask';
import { forwardRef, memo } from 'react';

import { TextField as FormikTextField } from 'formik-mui';
import RecommendableField from './RecommendableField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}

const MaskedComponent = memo(
  forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }),
  (prevProps, nextProps) =>
    prevProps.mask === nextProps.mask && prevProps.name === nextProps.name && prevProps.onChange === nextProps.onChange
);

const MaskedTextField = (props: any) => (
  <RecommendableField
    component={FormikTextField}
    isFastField={false}
    InputProps={{ inputComponent: MaskedComponent as any, inputProps: { mask: props.mask } }}
    sx={{ width: '100%', m: 1, marginLeft: 0, marginRight: 0 }}
    {...props}
  />
);

export default MaskedTextField;
