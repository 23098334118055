import { useCallback, useMemo } from 'react';
import { Grid, Button, Stack, Typography, useTheme, Divider } from '@mui/material';
import { Formik } from 'formik';
import yup from '../../../app/utils/customYup';
import { toast } from 'react-toastify';

import EditModal, { IEditModalProps } from '../../../components/EditModal';
import { SupportDocsUpload, IFormValuesDocumentItem } from '../../../components/DocumentsUpload';

import useLocale from '../../../app/hooks/useLocale';

import { useSupportingDocumentUploadMutation, useDocumentsUploadMutation } from '../queries';
import { ClientPublicService } from '../../../app/api/ClientPublicService';
import { EMPTY_GUID } from '../../../app/constants/common';

interface IEditDebtModalProps extends Pick<IEditModalProps, 'onCancel'> {
  fileId: string;
  supportingDocumentDetails?: ClientPublicService.SupportingDocumentDto;
  onOk?: () => void;
}

interface IForm {
  documents: IFormValuesDocumentItem[];
}

const UploadDetailsModal = ({ fileId, supportingDocumentDetails, onCancel, onOk }: IEditDebtModalProps) => {
  const { t, getLocalizedSupporingDocumentName } = useLocale();
  const theme = useTheme();

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const { mutate: attachUploadedToSupportingDocument } = useSupportingDocumentUploadMutation() || {};

  const validationSchema = useMemo(
    () =>
      yup.object({
        documents: yup
          .array()
          .of(
            yup.object({
              documentPrefixId: yup.string().required(t.THIS_IS_A_REQUIRED_FIELD),
            })
          )
          .min(1),
      }),
    [t.THIS_IS_A_REQUIRED_FIELD]
  );

  const handleSubmit = useCallback(
    async (values?: IForm) => {
      const files = values?.documents?.map((item) => ({
        ...item,
        documentFolderId: EMPTY_GUID,
        isVisible: false,
        isForSpouse:
          supportingDocumentDetails?.supportingDocumentType?.enumValue ===
            ClientPublicService.SupportingDocumentTypeEnum.ID_Spouse ||
          supportingDocumentDetails?.supportingDocumentType?.enumValue ===
            ClientPublicService.SupportingDocumentTypeEnum.IncomeTax_Spouse,
      })) as IFormValuesDocumentItem[];

      const newDocumentsIds = await uploadDocuments({
        fileId: fileId as string,
        files,
      });

      if (newDocumentsIds) {
        attachUploadedToSupportingDocument(
          {
            supportingDocumentId: supportingDocumentDetails?.id,
            documentIds: newDocumentsIds,
          } as ClientPublicService.SupportingDocumentDocumentUpdateDto,
          {
            onSuccess: () => {
              toast.success(t.DOCUMENT_UPLOADED_SUCCESSFULLY);
              onOk?.();
            },
            onError: () => {
              toast.error(t.DOCUMENT_UPLOAD_FAILED);
            },
          }
        );
      }
    },
    [
      attachUploadedToSupportingDocument,
      fileId,
      onOk,
      supportingDocumentDetails?.id,
      supportingDocumentDetails?.supportingDocumentType?.enumValue,
      t.DOCUMENT_UPLOADED_SUCCESSFULLY,
      t.DOCUMENT_UPLOAD_FAILED,
      uploadDocuments,
    ]
  );

  return (
    <EditModal
      title={
        <Stack alignItems="flex-start" pb={1.5}>
          <Typography variant="body1" fontWeight={600} sx={{ textAlign: 'left' }}>
            {supportingDocumentDetails?.detail}
          </Typography>
          <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.secondary.main }}>
            {getLocalizedSupporingDocumentName(supportingDocumentDetails)}
          </Typography>
        </Stack>
      }
      onCancel={onCancel}
    >
      <Formik
        initialValues={{ documents: [] }}
        onSubmit={(_: IForm, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        validateOnMount
      >
        {({ isValid, values }) => {
          return (
            <Stack pt={1}>
              <SupportDocsUpload
                showPrefixesList={false}
                presetPrefixes={supportingDocumentDetails?.documentPrefixes}
              />
              <Divider sx={{ mt: 2, mb: 1 }} />
              <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap" pt={1.5}>
                <Grid item mobile={6} tablet={4}>
                  <Button onClick={onCancel} variant="outlined" fullWidth>
                    {t.CANCEL}
                  </Button>
                </Grid>
                <Grid item mobile={6} tablet={4}>
                  <Button variant="contained" fullWidth onClick={() => handleSubmit(values)} disabled={!isValid}>
                    {t.SAVE}
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          );
        }}
      </Formik>
    </EditModal>
  );
};

export default UploadDetailsModal;
