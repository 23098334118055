import { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, useTheme, Divider, Stack, IconButton } from '@mui/material';
import { Error, ChevronRight } from '@mui/icons-material';

import BalanceCard from '../../PaymentsBanking/components/BalanceCard';
import DataItem from '../../../components/DataItem';

import useLocale from '../../../app/hooks/useLocale';

import { useOutstadingPaymentsCountQuery, useNextPaymentQuery, useBalanceQuery } from '../queries';
import { currencyFormatter } from '../../../app/utils/helpers';
import moment, { DATE_FORMAT4 } from '../../../app/utils/dateTimeHelpers';
import { ROUTES } from '../../../app/routes';
import { DashboardContext } from '../DashboardProvider';

import { ClientPublicService } from '../../../app/api/ClientPublicService';

const PaymentsBankingCard = () => {
  const { t, getLocalizedDate } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const { applicationFile } = useContext(DashboardContext);

  const { data: balance } = useBalanceQuery() || {};

  const { data: outstandingPaymentsCount, isLoading: isLoadingOutstandingPaymentsCount } =
    useOutstadingPaymentsCountQuery(applicationFile?.id) || {};

  const { data: nextPayment } = useNextPaymentQuery(applicationFile?.id) || {};

  const isClosedStage = useMemo(
    () => applicationFile?.fileStage?.enumValue === ClientPublicService.FileStageEnum.Closed,
    [applicationFile?.fileStage?.enumValue]
  );

  const bankruptcyType = useMemo(() => {
    switch (applicationFile?.filingTypeEnum) {
      case ClientPublicService.FilingTypeEnum.Ordinary:
        return t.BANKRUPTCY;
      case ClientPublicService.FilingTypeEnum.Summary:
        return t.BANKRUPTCY;
      case ClientPublicService.FilingTypeEnum.DivisionI:
        return t.CONSUMER_PROPOSAL;
      case ClientPublicService.FilingTypeEnum.DivisionII:
        return t.CONSUMER_PROPOSAL;
      default:
        return '';
    }
  }, [applicationFile?.filingTypeEnum, t.BANKRUPTCY, t.CONSUMER_PROPOSAL]);

  const fileStage = useMemo(() => {
    switch (applicationFile?.fileStage?.enumValue) {
      case ClientPublicService.FileStageEnum.Signed:
        return t.IN_PROGRESS;
      case ClientPublicService.FileStageEnum.FileInProcess:
        return t.IN_PROGRESS;
      case ClientPublicService.FileStageEnum.Closed:
        return t.CLOSED;
      case ClientPublicService.FileStageEnum.Annulled:
        return t.ANNULLED;
      case ClientPublicService.FileStageEnum.Transferred:
        return t.TRANSFERRED;
      case ClientPublicService.FileStageEnum.StatusChanged:
        return t.STATUS_CHANGED;
      default:
        return '';
    }
  }, [applicationFile?.fileStage?.enumValue, t.ANNULLED, t.CLOSED, t.IN_PROGRESS, t.STATUS_CHANGED, t.TRANSFERRED]);

  if (!Boolean(balance?.startingBalance) && !isClosedStage) {
    return (
      <>
        <BalanceCard />
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" fontWeight={600} aria-label="Bankruptcy Type">
        {bankruptcyType}
      </Typography>

      <Typography variant="body2" fontWeight={600} color="secondary">
        {fileStage}
      </Typography>

      <BalanceCard />

      {nextPayment && (
        <>
          <Divider sx={{ mt: 2, mb: 0.5 }} />
          <DataItem
            title={t.NEXT_PAYMENT}
            content={
              !isClosedStage && nextPayment?.dueDate
                ? getLocalizedDate(moment(nextPayment?.dueDate), DATE_FORMAT4)
                : '-'
            }
            actionComponent={
              <Stack>
                <Typography variant="body3" color="secondary" fontWeight={600} textAlign="right">
                  {t.AMOUNT}
                </Typography>

                <Typography variant="body2" fontWeight={600} textAlign="right" mt={0.25}>
                  {isClosedStage ? '-' : currencyFormatter(nextPayment?.amount)}
                </Typography>
              </Stack>
            }
          />
        </>
      )}

      <>
        <Divider sx={{ mt: nextPayment ? 0.5 : 2, mb: 0.5 }} />
        <DataItem
          title={
            <Typography sx={{ color: theme.palette.cobalt }} fontWeight={600}>
              {t.PAYMENTS_AND_BANKING}
            </Typography>
          }
          content={
            !isClosedStage &&
            !isLoadingOutstandingPaymentsCount &&
            outstandingPaymentsCount &&
            outstandingPaymentsCount > 0 ? (
              <Stack flexDirection="row" alignItems="center" columnGap={1}>
                <Error sx={{ color: theme.palette.urgentRed }} />
                <Typography variant="body2" color="secondary" fontWeight={600}>
                  {t.N_PAYMENTS_OUTSTANDING?.replace('{0}', String(outstandingPaymentsCount))}
                </Typography>
              </Stack>
            ) : (
              <></>
            )
          }
          actionComponent={
            <IconButton
              onClick={() => navigate(ROUTES.PAYMENTS_AND_BANKING)}
              edge="end"
              aria-label="Payments and Banking"
            >
              <ChevronRight fontSize="large" sx={{ color: theme.palette.cobalt }} />
            </IconButton>
          }
        />
      </>
    </>
  );
};

export default PaymentsBankingCard;
