import { useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';

import useLocale from '../../../app/hooks/useLocale';

import { IFormValues } from '../SupportDocsUpload';
import { nameRemovedExtraDots, isDeviceiOS } from '../../../app/utils/helpers';
import { BLACK_SCREEN_SESSION_STORAGE_KEY } from '../../../app/constants/common';

interface IUploadedFileProps {
  name?: string;
  fileSizeInBytes?: number;
  blob?: Blob;
  type?: string;
}

interface IUploadButtonProps {
  onUploadSuccess?: (uploadedFiles: IUploadedFileProps[]) => void;
  disabled?: boolean;
}

const UploadButton = ({ onUploadSuccess, disabled }: IUploadButtonProps) => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormValues>();

  const getFileFromInput = (file: File): Promise<any> => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const validateTotalFilesSize = useCallback(
    (files: File[]) => {
      const totalFilesSize =
        files?.reduce((prev, curr) => prev + curr.size, 0) +
        (values?.documents || [])
          ?.filter((item) => !item?.id)
          ?.reduce((prev, curr) => prev + (curr?.fileSizeInBytes || 0), 0);

      if (totalFilesSize / 1024 / 1024 > 100) {
        toast.error(t.THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB);
        return false;
      }

      return true;
    },
    [t.THE_SIZE_OF_ATTACHMENTS_SHOULD_NOT_EXCEED_100MB, values?.documents]
  );

  const handleWarningiOS = useCallback(() => {
    if (isDeviceiOS() && !sessionStorage.getItem(BLACK_SCREEN_SESSION_STORAGE_KEY)) {
      toast.warning(t.BLACK_SCREEN_WARNING);
      sessionStorage.setItem(BLACK_SCREEN_SESSION_STORAGE_KEY, 'true');
    }
  }, [t.BLACK_SCREEN_WARNING]);

  const handleFileChange = useCallback(
    async (event: any) => {
      event?.persist();

      if (event?.target?.files && event?.target?.files?.length > 0) {
        const files = Array.from(event?.target?.files as File[]);

        if (!validateTotalFilesSize(files)) return;

        const uploadedFiles: IUploadedFileProps[] = [];

        for (let file of files) {
          const binary = await getFileFromInput(file);

          const name = nameRemovedExtraDots(file?.name);

          if (name) {
            uploadedFiles.push({
              name,
              fileSizeInBytes: file.size,
              blob: new Blob([binary], { type: file.type }),
              type: file.type,
            });
          }
        }

        if (uploadedFiles.length > 0) {
          event.target.value = null;
          onUploadSuccess?.(uploadedFiles);
        }
      }
    },
    [onUploadSuccess, validateTotalFilesSize]
  );

  return (
    <>
      <Grid container>
        <Grid item mobile={12}>
          <input
            accept="image/*,.pdf,.doc,.docx"
            id="file"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            disabled={disabled}
          />
          <label htmlFor="file">
            <Button
              component="span"
              variant="outlined"
              startIcon={<Upload />}
              fullWidth
              size="large"
              sx={{
                fontSize: 20,
                borderRadius: 2,
                p: 1.5,
                '.MuiButton-startIcon>*:nth-of-type(1)': {
                  fontSize: 32,
                },
              }}
              onClick={(e) => {
                handleWarningiOS();
                e.stopPropagation();
              }}
              disabled={disabled}
            >
              {t.UPLOAD}
            </Button>
          </label>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadButton;
