export const CANADA_COUNTRY_CODE = 'CA';
export const USA_COUNTRY_CODE = 'US';

export const STREET_NUMBER = 'street_number';
export const ROUTE = 'route';
export const LOCALITY = 'locality';
export const POSTAL_TOWN = 'postal_town';
export const POSTAL_CODE = 'postal_code';
export const ADMINISRATIVE_LEVEL_1 = 'administrative_area_level_1';
export const COUNTRY = 'country';
