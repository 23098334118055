import { forwardRef, RefObject, useContext, useMemo, useCallback } from 'react';
import { Typography, Accordion, AccordionSummary, Stack, AccordionDetails, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionContext } from './AccordionProvider';
import ErrorIcon from '@mui/icons-material/Error';

export interface IAppFormAccordionProps {
  id: string;
  title?: string;
  children?: React.ReactElement | Array<React.ReactElement> | string | boolean | any;
  validatedProps?: string[];
}

const AppFormAccordion = forwardRef(({ id, title, children, validatedProps }: IAppFormAccordionProps, ref) => {
  const theme = useTheme();
  const { expanded, setExpanded } = useContext(AccordionContext);
  const { errors, touched } = useFormikContext<any>();

  const handlePanelChange = useCallback(
    (_: any, newExpanded: boolean) => {
      setExpanded?.(newExpanded ? id : false);

      if (newExpanded) {
        setTimeout(() => {
          (ref as RefObject<HTMLDivElement>)?.current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start',
          });
        }, 500);
      }
    },
    [id, ref, setExpanded]
  );

  const touchedFieldsErrors = useMemo(() => Object.keys(errors).filter((key) => touched[key]), [errors, touched]);

  const hasErrors = useMemo(
    () => touchedFieldsErrors.some((key) => validatedProps?.includes(key)),
    [touchedFieldsErrors, validatedProps]
  );

  return (
    <Accordion
      expanded={id === expanded}
      onChange={handlePanelChange}
      ref={ref as RefObject<HTMLDivElement>}
      sx={{
        scrollMarginTop: { mobile: 66, desktop: 10 },
      }}
      TransitionProps={{
        timeout: 100,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
        <Stack flexDirection="row">
          <Typography variant="body1" fontWeight={600} data-testid="panel-title">
            {title}
          </Typography>
          {hasErrors && <ErrorIcon sx={{ fontSize: 22, color: theme.palette.urgentRed, ml: 1 }} />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: `1px solid ${theme.palette.bgGrey}` }}>
        {id === expanded ? children : null}
      </AccordionDetails>
    </Accordion>
  );
});

export default AppFormAccordion;
