import { useMemo } from 'react';
import { TextField, SelectField, CheckboxField, CurrencyField } from '../../../../../../components/FormFields';
import { LocationFields } from '../../../../../../components/FormFieldsPresets';
import { useFormikContext } from 'formik';

import useLocale from '../../../../../../app/hooks/useLocale';

import { useOwnershipTypesQuery, useDebtsDescriptionsQuery } from '../../queries';
import { IDebtFormValues } from './EditDebtModal';
import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

const EditDebtForm = ({ isLocked }: { isLocked?: boolean }) => {
  const { t } = useLocale();
  const { values } = useFormikContext<IDebtFormValues>();

  const { data: ownershipTypes, isInitialLoading: isLoadingOwnershipTypes } = useOwnershipTypesQuery() || {};
  const { data: debtDesctiptions, isInitialLoading: isLoadingDebtDescriptions } = useDebtsDescriptionsQuery() || {};

  const isOther = useMemo(
    () =>
      debtDesctiptions?.find((item) => item.id === values?.debtDescriptionId)?.enumValue ===
      ClientPublicService.DebtDescriptionEnum.Other,
    [debtDesctiptions, values?.debtDescriptionId]
  );

  const isPersonalLoan = useMemo(
    () =>
      debtDesctiptions?.find((item) => item.id === values?.debtDescriptionId)?.enumValue ===
      ClientPublicService.DebtDescriptionEnum.PersonalLoan,
    [debtDesctiptions, values?.debtDescriptionId]
  );

  return (
    <>
      <SelectField
        name="debtDescriptionId"
        label={t.DESCRIPTION}
        data-testid="debtDescriptionId"
        required
        options={debtDesctiptions}
        loading={isLoadingDebtDescriptions}
        disabled={isLocked}
      />

      {isOther && (
        <TextField
          name="specifiedDescription"
          label={t.OTHER_SPECIFY}
          data-testid="specifiedDescription"
          inputProps={{ maxLength: 500 }}
          recommended
          disabled={isLocked}
        />
      )}

      <TextField
        name="creditorName"
        label={t.CREDITOR_NAME}
        data-testid="creditorName"
        required
        inputProps={{ maxLength: 200 }}
        disabled={isLocked}
      />

      <CurrencyField name="amount" label={t.AMOUNT} data-testid="amount" required disabled={isLocked} />

      <SelectField
        name="ownershipTypeId"
        label={t.OWNERSHIP}
        data-testid="ownershipTypeId"
        options={ownershipTypes}
        loading={isLoadingOwnershipTypes}
        disabled={isLocked}
      />

      <TextField
        name="accountNumber"
        label={t.ACCOUNT_NUMBER}
        data-testid="accountNumber"
        recommended
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      <LocationFields
        presetType="short"
        addressFieldName="creditorAddress"
        addressFieldLabel={t.CREDITOR_ADDRESS}
        unitFieldName="creditorUnit"
        unitFieldLabel={t.CREDITOR_UNIT}
        streetNameFieldName="creditorStreetName"
        streetNumberFieldName="creditorStreetNumber"
        cityFieldName="creditorCity"
        provinceIdFieldName="creditorProvinceCode"
        countryIdFieldName="creditorCountryCode"
        postalCodeFieldName="creditorPostalCode"
        disabledFields={isLocked ? 'all' : undefined}
      />

      {isPersonalLoan && (
        <TextField
          name="creditorEmailAddress"
          label={t.CREDITOR_EMAIL_ADDRESS}
          data-testid="creditorEmailAddress"
          inputProps={{ maxLength: 200 }}
          disabled={isLocked}
        />
      )}

      <TextField
        name="comments"
        label={t.COMMENTS}
        data-testid="comments"
        multiline
        minRows={3}
        inputProps={{ maxLength: 500 }}
        disabled={isLocked}
      />

      <CheckboxField
        name="isBusinessDebt"
        label={t.BUSINESS_DEBT}
        data-testid="isBusinessDebt"
        sx={{ fontWeight: 600 }}
        disabled={isLocked}
      />
    </>
  );
};

export default EditDebtForm;
