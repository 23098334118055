import { useMemo } from 'react';
import { Stack, styled } from '@mui/material';

import CustomToggleButton from './CustomToggleButton';

import useLocale from '../app/hooks/useLocale';

export type IExpenderType = 'applicantAmount' | 'spouseAmount' | 'otherHouseholdMemberAmount';

interface IExpenderTabsProps {
  expenderTab: IExpenderType;
  setExpenderTab: (value: IExpenderType) => void;
  isMarried?: boolean;
  disabled?: boolean;
  isExpense?: boolean;
}

const ExpenderTabs = ({ expenderTab, setExpenderTab, isMarried, disabled, isExpense }: IExpenderTabsProps) => {
  const { t } = useLocale();

  const expendersTabs = useMemo(
    () =>
      [
        {
          label: isExpense ? t.HOUSEHOLD : t.APPLICANT,
          value: 'applicantAmount' as IExpenderType,
          conditionalRender: true,
        },
        {
          label: t.SPOUSE,
          value: 'spouseAmount' as IExpenderType,
          conditionalRender: isMarried,
        },
        {
          label: t.OTHER,
          value: 'otherHouseholdMemberAmount' as IExpenderType,
          conditionalRender: !isExpense,
        },
      ]?.filter((item) => item?.conditionalRender),
    [isMarried, t.APPLICANT, t.OTHER, t.SPOUSE, isExpense, t.HOUSEHOLD]
  );

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mt: 2 }}>
      {expendersTabs?.map((item, index) => (
        <ToggleButton
          key={index}
          value={item?.value}
          onClick={() => setExpenderTab(item?.value)}
          selected={expenderTab === item?.value}
          fullWidth
          disabled={disabled}
        >
          {item?.label}
        </ToggleButton>
      ))}
    </Stack>
  );
};

export default ExpenderTabs;

const ToggleButton = styled(CustomToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    borderColor: theme.palette.error.main,
  },
}));
