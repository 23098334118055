import { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import CustomPagination, { ICustomPaginationProps } from '../CustomPagination';

import ListLayoutItem, { IListItemProps } from './ListLayoutItem';

interface IListLayoutProps {
  items?: IListItemProps[];
  emptyValue?: string;
  paginationProps?: Partial<ICustomPaginationProps>;
}

const DEFAULT_PAGE_SIZE = 4;

const ListLayout = ({ items, emptyValue, paginationProps }: IListLayoutProps) => {
  const [page, setPage] = useState(0);

  const handleChangePage = useCallback(
    (_: any, newPage: number) => {
      if (paginationProps?.onPageChange) {
        paginationProps.onPageChange(_, newPage);
      } else {
        setPage(newPage);
      }
    },
    [paginationProps]
  );

  const displayedData = useMemo(() => {
    const rowsPerPage = paginationProps?.rowsPerPage || DEFAULT_PAGE_SIZE;
    return paginationProps?.page !== undefined
      ? items
      : items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [items, page, paginationProps?.page, paginationProps?.rowsPerPage]);

  const totalCount = useMemo(() => paginationProps?.count || items?.length || 0, [items, paginationProps?.count]);
  const rowsPerPage = useMemo(() => paginationProps?.rowsPerPage || DEFAULT_PAGE_SIZE, [paginationProps?.rowsPerPage]);

  useEffect(() => {
    if (paginationProps?.page !== undefined) {
      setPage(paginationProps?.page);
    }
  }, [paginationProps?.page]);

  if (!totalCount && emptyValue) {
    return (
      <Typography variant="body2" fontWeight={600} color="secondary" textAlign="center">
        {emptyValue}
      </Typography>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          '& .MuiPaper-root': {
            mt: 2.5,

            '&:first-child': {
              mt: 0,
            },
          },
        }}
      >
        {displayedData?.map((item) => (
          <ListLayoutItem {...item} key={item?.id} />
        ))}
      </Box>
      {totalCount > rowsPerPage && (
        <CustomPagination count={totalCount} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} />
      )}
    </Box>
  );
};

export default ListLayout;
