import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import yup from '../../../../app/utils/customYup';
import { toast } from 'react-toastify';

import PersonalDetails from './panels/PersonalDetails';
import ContactInformation from './panels/ContactInformation';
import Employment from './panels/Employment';
import FinancialDifficulty from './panels/FinancialDifficulty';
import Dependants from './panels/Dependants';
import PreviousInsolvencies from './panels/PreviousInsolvencies';
import UnsavedForm from '../../components/UnsavedForm/UnsavedForm';

import AccordionProvider, { AccordionContext, AppFormAccordion, ErrorsList } from '../../components/AppFormAccordion';
import ConfirmationModal from '../../../../components/ConfirmationModal';

import useLocale from '../../../../app/hooks/useLocale';
import useAppForm from '../../hooks/useAppForm';
import useModal from '../../../../app/hooks/useModal';

import { ROUTES } from '../../../../app/routes';

import PersonalInformationProvider from './PersonalInformationProvider';
import { IFormValuesPersonalInfo } from './types';
import { IFormValuesDocumentItem, useDocumentsUploadMutation } from '../../../../components/DocumentsUpload';
import {
  useAppFormSupportingDocumentsQuery,
  useFoldersQuery,
  usePrefixesQuery,
} from '../../../../components/DocumentsUpload/queries';
import {
  useAppFormPersonalInfoQuery,
  useCountriesQuery,
  useInsolvencyTypesQuery,
  useMaritalStatusesQuery,
  useUpdatePersonalInfoMutation,
} from './queries';

import { convertFormValuesToAppFormPersonalInfoDto, getInitialPersonalInfoValues } from './utils';
import { regexPhone, regexSIN, validateGreaterThan } from '../../../../app/utils/helpers';
import {
  CANADA_COUNTRY_CODE,
  USA_COUNTRY_CODE,
} from '../../../../components/FormFieldsPresets/LocationFields/constants';

import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import SectionLockMessage from '../../components/SectionLockMessage';
import NavigationButtons from '../../components/NavigationButtons';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';

export const PERSONAL_DETAILS_PANEL = 'personal-details';
export const CONTACT_INFORMATION_PANEL = 'contact-information';
export const EMPLOYMENT_PANEL = 'employment';
export const FINANCIAL_DIFFICULTY_PANEL = 'financial-difficulty';
export const DEPENDANTS_PANEL = 'dependants';
export const PREVIOUS_INSOLVENCY_PANEL = 'previous-insolvency';

const GREATER_THAN_VALIDATION = 'greater-than-validation';

const PERSONAL_INFO_DOCUMENTS_FOLDER_CODE = 'lead';

const PERSONAL_INFO_DOCUMENTS_PREFIX_CODES = [
  'Personal.Drivers-License',
  'Personal.Health-Card',
  'Personal.Other-Id',
  'Personal.Passport',
];

const PersonalInformationComponent = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const navigate = useNavigate();
  const { appFormStatus, updateAppFormStatus, appFileData: appFile } = useAppForm() || {};
  const { setShowErrorsList } = useContext(AccordionContext);
  const { showModal, closeModal } = useModal();

  const personalDetailsRef = useRef<HTMLDivElement>(null);
  const contactDetailsRef = useRef<HTMLDivElement>(null);
  const employmentRef = useRef<HTMLDivElement>(null);
  const financialDifficultyRef = useRef<HTMLDivElement>(null);
  const dependantsRef = useRef<HTMLDivElement>(null);
  const previousInsolvencyRef = useRef<HTMLDivElement>(null);

  const { data: personalInfo, isSuccess: isPersonalInfoSuccess } = useAppFormPersonalInfoQuery(appFile?.id) || {};

  const { mutate: updatePersonalInfo } = useUpdatePersonalInfoMutation() || {};

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const { data: countries } = useCountriesQuery() || {};

  const { data: insolvencyTypes, isSuccess: isInsolvencyTypesSuccess } = useInsolvencyTypesQuery() || {};

  const { data: maritalStatuses } = useMaritalStatusesQuery() || {};

  const { data: folders } = useFoldersQuery() || {};

  const documentFolderId = useMemo(
    () => folders?.find((folder) => folder.code === PERSONAL_INFO_DOCUMENTS_FOLDER_CODE)?.id,
    [folders]
  );

  const { data: prefixes } =
    usePrefixesQuery({ documentFolderId, prefixCodes: PERSONAL_INFO_DOCUMENTS_PREFIX_CODES }) || {};

  const { data: documents, isSuccess: isDocumentsSuccess } =
    useAppFormSupportingDocumentsQuery(
      {
        documentFolderId,
        documentPrefixCodes: PERSONAL_INFO_DOCUMENTS_PREFIX_CODES,
        documentIds: personalInfo?.documentIds,
      },
      true
    ) || {};

  const isMarried = useMemo(() => {
    const maritalStatusEnum = maritalStatuses?.find((status) => status.id === personalInfo?.maritalStatusId)?.enumValue;
    return (
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Married ||
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.CommonLaw
    );
  }, [maritalStatuses, personalInfo?.maritalStatusId]);

  const isLocked = useMemo(
    () =>
      appFile?.isLocked ||
      appFile?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFile?.isLocked, appFile?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED?.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const getGreaterThanZeroByLabel = useCallback(
    (fieldName: string) => t.SHOULD_BE_GREATER_THAN?.replace('{0}', fieldName).replace('{1}', '0'),
    [t.SHOULD_BE_GREATER_THAN]
  );

  const validateNotZero = useCallback((value?: number) => validateGreaterThan(value, 0), []);

  const validateCountryCodeUsaOrCanada = useCallback(
    (countryId?: string) => {
      const countryCode = countries?.find((country) => country.id === countryId)?.code;
      return countryCode === CANADA_COUNTRY_CODE || countryCode === USA_COUNTRY_CODE;
    },
    [countries]
  );

  const documentsYupObject = useMemo(
    () =>
      yup.array().of(
        yup.object({
          documentPrefixId: yup
            .string()
            .when(['id'], ([id], schema) => (id ? schema : schema.required(getRequiredByLabel(t.DOCUMENT)))),
          isForSpouse: yup.boolean().notRequired(),
        })
      ),
    [getRequiredByLabel, t.DOCUMENT]
  );

  const documentsValidationSchema = useMemo(
    () => ({
      documents: documentsYupObject.when(['isJointFile'], ([isJointFile], schema) =>
        !appFormStatus || appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete
          ? schema
          : schema.test({
              name: 'documents-completeness-validation',
              test(value, ctx) {
                const oneExistingForSpouse = Boolean(value?.find((item) => item?.isForSpouse));
                const oneExistingForApplicant = Boolean(value?.find((item) => !item?.isForSpouse));

                if (isJointFile && !oneExistingForSpouse && !oneExistingForApplicant) {
                  return ctx.createError({
                    message: t.FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE,
                  });
                }

                if (isJointFile && oneExistingForSpouse && !oneExistingForApplicant) {
                  return ctx.createError({ message: t.ONE_ID_REQUIRED_FOR_APPLICANT });
                }

                if (isJointFile && !oneExistingForSpouse && oneExistingForApplicant) {
                  return ctx.createError({ message: t.ONE_ID_REQUIRED_FOR_SPOUSE });
                }

                if (!isJointFile && !oneExistingForApplicant) {
                  return ctx.createError({ message: t.ONE_ID_REQUIRED_FOR_APPLICANT });
                }

                return true;
              },
            })
      ),
    }),
    [
      appFormStatus,
      documentsYupObject,
      t.FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE,
      t.ONE_ID_REQUIRED_FOR_APPLICANT,
      t.ONE_ID_REQUIRED_FOR_SPOUSE,
    ]
  );

  const personalDetailsValidationSchema = useMemo(
    () => ({
      firstName: yup.string().required(getRequiredByLabel(t.FIRST_NAME)),
      lastName: yup.string().required(getRequiredByLabel(t.LAST_NAME)),
      birthDate: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .required(getRequiredByLabel(t.DATE_OF_BIRTH)),
      sin: yup.string().required(getRequiredByLabel(t.SIN)).matches(regexSIN, t.SIN_NOT_VALID),
      maritalStatusId: yup.string().required(getRequiredByLabel(t.MARITAL_STATUS)),
      marriageDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
      spouseFirstName: yup
        .string()
        .when(['isJointFile', 'maritalStatusId'], ([isJointFile, maritalStatusId], schema) => {
          const maritalStatusEnum = maritalStatuses?.find((status) => status.id === maritalStatusId)?.enumValue;
          const isMarried =
            maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Married ||
            maritalStatusEnum === ClientPublicService.MaritalStatusEnum.CommonLaw;
          return isJointFile
            ? schema.required(getRequiredByLabel(t.SPOUSE_FIRST_NAME))
            : isMarried
            ? schema.recommended(getRecommendedByLabel(t.SPOUSE_FIRST_NAME))
            : schema;
        }),
      spouseLastName: yup
        .string()
        .when(['isJointFile', 'maritalStatusId'], ([isJointFile, maritalStatusId], schema) => {
          const maritalStatusEnum = maritalStatuses?.find((status) => status.id === maritalStatusId)?.enumValue;
          const isMarried =
            maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Married ||
            maritalStatusEnum === ClientPublicService.MaritalStatusEnum.CommonLaw;
          return isJointFile
            ? schema.required(getRequiredByLabel(t.SPOUSE_LAST_NAME))
            : isMarried
            ? schema.recommended(getRecommendedByLabel(t.SPOUSE_LAST_NAME))
            : schema;
        }),
      spouseBirthDate: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .when('isJointFile', {
          is: true,
          then: (schema) => schema.required(getRequiredByLabel(t.SPOUSE_DATE_OF_BIRTH)),
          otherwise: (schema) => schema.nullable(),
        }),
      spouseSIN: yup
        .string()
        .matches(regexSIN, t.SIN_NOT_VALID)
        .when('isJointFile', {
          is: true,
          then: (schema) => schema.required(getRequiredByLabel(t.SPOUSE_SIN)),
          otherwise: (schema) => schema,
        }),
    }),
    [
      getRequiredByLabel,
      t.FIRST_NAME,
      t.LAST_NAME,
      t.INVALID_DATE,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.DATE_OF_BIRTH,
      t.SIN,
      t.SIN_NOT_VALID,
      t.MARITAL_STATUS,
      t.SPOUSE_FIRST_NAME,
      t.SPOUSE_LAST_NAME,
      t.SPOUSE_DATE_OF_BIRTH,
      t.SPOUSE_SIN,
      maritalStatuses,
      getRecommendedByLabel,
    ]
  );

  const contactDetailsValidationSchema = useMemo(
    () => ({
      address: yup.string().required(getRequiredByLabel(t.ADDRESS)),
      city: yup.string().required(getRequiredByLabel(t.CITY)),
      postalCode: yup
        .string()
        .when(['countryId'], ([countryId], schema) =>
          validateCountryCodeUsaOrCanada(countryId) ? schema.required(getRequiredByLabel(t.POSTAL_CODE)) : schema
        ),
      countryId: yup.string().required(getRequiredByLabel(t.COUNTRY)),
      provinceId: yup
        .string()
        .when(['countryId'], ([countryId], schema) =>
          validateCountryCodeUsaOrCanada(countryId) ? schema.required(getRequiredByLabel(t.PROVINCE)) : schema
        ),
      residenceDate: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .required(getRequiredByLabel(t.AT_THIS_ADDRESS_SINCE)),
      mainPhoneTypeId: yup.string().required(getRequiredByLabel(t.TYPE)),
      mainPhoneNumber: yup.string().required(getRequiredByLabel(t.PHONE_NUMBER)).matches(regexPhone, t.PHONE_NOT_VALID),
      emailAddress: yup.string().email(t.EMAIL_NOT_VALID).recommended(getRecommendedByLabel(t.EMAIL)),
      spouseResidenceDate: yup
        .date()
        .typeError(t.INVALID_DATE)
        .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
        .nullable(),
    }),
    [
      getRecommendedByLabel,
      getRequiredByLabel,
      t.ADDRESS,
      t.AT_THIS_ADDRESS_SINCE,
      t.CITY,
      t.COUNTRY,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.EMAIL,
      t.EMAIL_NOT_VALID,
      t.INVALID_DATE,
      t.PHONE_NOT_VALID,
      t.PHONE_NUMBER,
      t.POSTAL_CODE,
      t.PROVINCE,
      t.TYPE,
      validateCountryCodeUsaOrCanada,
    ]
  );

  const employmentValidationSchema = useMemo(
    () => ({
      isEmployedPastTwoYears: yup.boolean().required(getRequiredByLabel(t.HAVE_BEEN_EMPLOYED)),
      employmentDetails: yup.array().of(
        yup.object({
          employerName: yup.string().required(getRequiredByLabel(t.EMPLOYER)),
          occupationName: yup.string().required(getRequiredByLabel(t.OCCUPATION)),
          industryId: yup.string().required(getRequiredByLabel(t.INDUSTRY)),
          employmentStatusId: yup.string().required(getRequiredByLabel(t.EMPLOYMENT_STATUS)),
          startDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
          endDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
        })
      ),
      isReceivedEmploymentInsurancePastTwoYears: yup.boolean().required(getRequiredByLabel(t.HAVE_RECIEVED_EI)),
      employmentInsurancePeriods: yup.array().of(
        yup.object({
          startDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .required(getRequiredByLabel(t.START_DATE)),
          endDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .when('isCurrentPeriod', {
              is: false,
              then: (schema) => schema.required(getRequiredByLabel(t.CURRENTLY_RECIEVING_EI)),
              otherwise: (schema) => schema.nullable(),
            }),
        })
      ),
      isSpouseEmployedPastTwoYears: yup.boolean().when('isJointFile', {
        is: true,
        then: (schema) => schema.required(getRequiredByLabel(t.HAVE_BEEN_EMPLOYED)),
        otherwise: (schema) => schema,
      }),
      spouseEmploymentDetails: yup.array().of(
        yup.object({
          employerName: yup.string().when(() => {
            return isMarried ? yup.string().required(getRequiredByLabel(t.EMPLOYER)) : yup.string();
          }),
          occupationName: yup.string().when(() => {
            return isMarried ? yup.string().required(getRequiredByLabel(t.OCCUPATION)) : yup.string();
          }),
          industryId: yup.string().when(() => {
            return isMarried ? yup.string().required(getRequiredByLabel(t.INDUSTRY)) : yup.string();
          }),
          startDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
          endDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
          employmentStatusId: yup.string().when(() => {
            return isMarried ? yup.string().required(getRequiredByLabel(t.EMPLOYMENT_STATUS)) : yup.string();
          }),
        })
      ),
      spouseEmploymentInsurancePeriods: yup.array().of(
        yup.object({
          startDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
          endDate: yup.date().typeError(t.INVALID_DATE).max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE).nullable(),
        })
      ),
    }),
    [
      getRequiredByLabel,
      t.CURRENTLY_RECIEVING_EI,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.EMPLOYER,
      t.EMPLOYMENT_STATUS,
      t.HAVE_BEEN_EMPLOYED,
      t.HAVE_RECIEVED_EI,
      t.INVALID_DATE,
      t.OCCUPATION,
      t.START_DATE,
      t.INDUSTRY,
      isMarried,
    ]
  );

  const financialDifficultyValidationSchema = useMemo(
    () => ({
      hasFDReasonOther: yup
        .boolean()
        .when(
          [
            'hasFDReasonLossOfIncome',
            'hasFDReasonMedicalReasons',
            'hasFDReasonBusinessFailure',
            'hasFDReasonGambling',
            'hasFDReasonDrugAlcoholAddicition',
            'hasFDReasonMaritalSeparationOrRelationshipBreakdown',
            'hasFDReasonTaxLiabilities',
            'hasFDReasonFinancialSupportOfOthers',
            'hasFDReasonStudentDebt',
            'hasFDReasonLitigationIssues',
            'hasFDReasonMovingOrRelocationExpenses',
            'hasFDReasonFailedProposal',
            'hasFDReasonFinancialMismanagement',
          ],
          {
            is: false,
            then: (schema) => schema.oneOf([true], t.SELECT_AT_LEAST_ONE_REASON),
            otherwise: (schema) => schema,
          }
        ),
      specifiedFDReason: yup.string().when('hasFDReasonOther', {
        is: true,
        then: (schema) => schema.required(getRequiredByLabel(t.OTHER_PLEASE_SPECIFY)),
        otherwise: (schema) => schema,
      }),
      specifyFDReasonLegalMatters: yup.string().when('hasFDReasonLitigationIssues', {
        is: true,
        then: (schema) => schema.required(getRequiredByLabel(t.LEGAL_MATTERS_PLEASE_SPECIFY)),
        otherwise: (schema) => schema,
      }),
      spouseHasFDReasonOther: personalInfo?.isJointFile
        ? yup
            .boolean()
            .when(
              [
                'spouseHasFDReasonLossOfIncome',
                'spouseHasFDReasonMedicalReasons',
                'spouseHasFDReasonBusinessFailure',
                'spouseHasFDReasonGambling',
                'spouseHasFDReasonDrugAlcoholAddicition',
                'spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown',
                'spouseHasFDReasonTaxLiabilities',
                'spouseHasFDReasonFinancialSupportOfOthers',
                'spouseHasFDReasonStudentDebt',
                'spouseHasFDReasonLitigationIssues',
                'spouseHasFDReasonMovingOrRelocationExpenses',
                'spouseHasFDReasonFailedProposal',
                'spouseHasFDReasonFinancialMismanagement',
              ],
              {
                is: false,
                then: (schema) => schema.oneOf([true], t.SELECT_AT_LEAST_ONE_REASON),
                otherwise: (schema) => schema,
              }
            )
        : yup.boolean().nullable(),
      spouseSpecifiedFDReason: personalInfo?.isJointFile
        ? yup.string().when('spouseHasFDReasonOther', {
            is: true,
            then: (schema) => schema.required(getRequiredByLabel(t.OTHER_PLEASE_SPECIFY)),
            otherwise: (schema) => schema,
          })
        : yup.string().nullable(),
      spouseSpecifyFDReasonLegalMatters: personalInfo?.isJointFile
        ? yup.string().when('spouseHasFDReasonLitigationIssues', {
            is: true,
            then: (schema) => schema.required(getRequiredByLabel(t.LEGAL_MATTERS_PLEASE_SPECIFY)),
            otherwise: (schema) => schema,
          })
        : yup.string().nullable(),
    }),
    [
      getRequiredByLabel,
      t.OTHER_PLEASE_SPECIFY,
      t.LEGAL_MATTERS_PLEASE_SPECIFY,
      t.SELECT_AT_LEAST_ONE_REASON,
      personalInfo,
    ]
  );

  const dependantsValidationSchema = useMemo(
    () => ({
      numberOfPersonsInHousehold: yup
        .number()
        .test(GREATER_THAN_VALIDATION, getGreaterThanZeroByLabel(t.VALUE), validateNotZero),
      dependants: yup.array().of(
        yup.object({
          firstName: yup.string().recommended(getRecommendedByLabel(t.DEPENDANT_FIRST_NAME)),
          lastName: yup.string().recommended(getRecommendedByLabel(t.DEPENDANT_LAST_NAME)),
          relationshipTypeId: yup.string().recommended(getRecommendedByLabel(t.RELATIONSHIP)),
          birthDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .recommended(getRecommendedByLabel(t.DATE_OF_BIRTH)),
        })
      ),
    }),
    [
      getGreaterThanZeroByLabel,
      getRecommendedByLabel,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.DATE_OF_BIRTH,
      t.DEPENDANT_FIRST_NAME,
      t.DEPENDANT_LAST_NAME,
      t.INVALID_DATE,
      t.RELATIONSHIP,
      t.VALUE,
      validateNotZero,
    ]
  );

  const previousInsolvenciesValidationSchema = useMemo(
    () => ({
      hasPreviousInsolvency: yup.boolean().required(getRequiredByLabel(t.HAVE_FILED_INSOLVENCY)),
      previousInsolvencies: yup.array().of(
        yup.object({
          insolvencyTypeId: yup.string().required(getRequiredByLabel(t.TYPE)),
          bankruptcyDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .when(['insolvencyTypeId'], ([insolvencyTypeId], schema) =>
              insolvencyTypes?.find((item) => item.id === insolvencyTypeId)?.enumValue ===
              ClientPublicService.InsolvencyTypeEnum.Bankruptcy
                ? schema.recommended(getRecommendedByLabel(t.BANKRUPTCY_DATE))
                : schema.nullable()
            ),
          dischargeDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .nullable(),
          proposalDate: yup
            .date()
            .typeError(t.INVALID_DATE)
            .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
            .when(['insolvencyTypeId'], ([insolvencyTypeId], schema) =>
              insolvencyTypes?.find((item) => item.id === insolvencyTypeId)?.enumValue ===
              ClientPublicService.InsolvencyTypeEnum.Proposal
                ? schema.recommended(getRecommendedByLabel(t.PROPOSAL_DATE))
                : schema.nullable()
            ),
          spousePreviousInsolvencies: yup.array().of(
            yup.object({
              bankruptcyDate: yup
                .date()
                .typeError(t.INVALID_DATE)
                .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
                .nullable(),
              dischargeDate: yup
                .date()
                .typeError(t.INVALID_DATE)
                .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
                .nullable(),
              proposalDate: yup
                .date()
                .typeError(t.INVALID_DATE)
                .max(new Date(), t.DATE_CANNOT_BE_IN_THE_FUTURE)
                .nullable(),
            })
          ),
        })
      ),
    }),
    [
      getRecommendedByLabel,
      getRequiredByLabel,
      insolvencyTypes,
      t.BANKRUPTCY_DATE,
      t.DATE_CANNOT_BE_IN_THE_FUTURE,
      t.HAVE_FILED_INSOLVENCY,
      t.INVALID_DATE,
      t.PROPOSAL_DATE,
      t.TYPE,
    ]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ...personalDetailsValidationSchema,
        ...documentsValidationSchema,
        ...contactDetailsValidationSchema,
        ...employmentValidationSchema,
        ...financialDifficultyValidationSchema,
        ...dependantsValidationSchema,
        ...previousInsolvenciesValidationSchema,
      }),
    [
      personalDetailsValidationSchema,
      documentsValidationSchema,
      contactDetailsValidationSchema,
      employmentValidationSchema,
      financialDifficultyValidationSchema,
      dependantsValidationSchema,
      previousInsolvenciesValidationSchema,
    ]
  );

  const initialValues = useMemo(
    () =>
      getInitialPersonalInfoValues(
        personalInfo,
        documents?.items?.map(
          (item) =>
            ({
              ...item,
              documentPrefixName: getLocalizedDtoName(prefixes?.find((prefix) => prefix.id === item?.documentPrefixId)),
            } as ClientPublicService.DocumentDto)
        )
      ),
    [personalInfo, documents?.items, getLocalizedDtoName, prefixes]
  );

  const isFormStarted = useMemo(
    () => appFormStatus?.personalInfoStatus !== ClientPublicService.AppFormStatusEnum.NotStarted,
    [appFormStatus?.personalInfoStatus]
  );

  const panels = useMemo(
    () => [
      {
        title: t.PERSONAL_DETAILS,
        id: PERSONAL_DETAILS_PANEL,
        validatedProps: Object.keys({ ...personalDetailsValidationSchema, ...documentsValidationSchema }),
        ref: personalDetailsRef,
        children: <PersonalDetails />,
      },
      {
        title: t.CONTACT_INFORMATION,
        id: CONTACT_INFORMATION_PANEL,
        validatedProps: Object.keys(contactDetailsValidationSchema),
        ref: contactDetailsRef,
        children: <ContactInformation />,
      },
      {
        title: t.EMPLOYMENT,
        id: EMPLOYMENT_PANEL,
        validatedProps: Object.keys(employmentValidationSchema),
        ref: employmentRef,
        children: <Employment />,
      },
      {
        title: t.REASONS_FOR_FINANCIAL_DIFFICULTY,
        id: FINANCIAL_DIFFICULTY_PANEL,
        validatedProps: Object.keys(financialDifficultyValidationSchema),
        ref: financialDifficultyRef,
        children: <FinancialDifficulty />,
      },
      {
        title: t.DEPENDANTS,
        id: DEPENDANTS_PANEL,
        validatedProps: Object.keys(dependantsValidationSchema),
        ref: dependantsRef,
        children: <Dependants />,
      },
      {
        title: t.PREVIOUS_INSOLVENCIES,
        id: PREVIOUS_INSOLVENCY_PANEL,
        validatedProps: Object.keys(previousInsolvenciesValidationSchema),
        ref: previousInsolvencyRef,
        children: <PreviousInsolvencies />,
      },
    ],
    [
      contactDetailsValidationSchema,
      dependantsValidationSchema,
      documentsValidationSchema,
      employmentValidationSchema,
      financialDifficultyValidationSchema,
      personalDetailsValidationSchema,
      previousInsolvenciesValidationSchema,
      t.CONTACT_INFORMATION,
      t.DEPENDANTS,
      t.EMPLOYMENT,
      t.PERSONAL_DETAILS,
      t.PREVIOUS_INSOLVENCIES,
      t.REASONS_FOR_FINANCIAL_DIFFICULTY,
    ]
  );

  const savePersonalInfo = useCallback(
    async (values?: IFormValuesPersonalInfo, onSuccess?: () => void) => {
      updatePersonalInfo(
        {
          id: personalInfo?.id,
          fileId: personalInfo?.fileId,
          ...convertFormValuesToAppFormPersonalInfoDto(values),
        },
        {
          onSuccess: (response) => {
            if (response?.result === ClientPublicService.Result.Successful) {
              updateAppFormStatus?.();
              toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.PERSONAL_INFORMATION));

              if (onSuccess) {
                onSuccess();
              } else {
                navigate(`${ROUTES.APP_FORM}/${ROUTES.ASSETS_DEBTS}`);
              }
            } else {
              toast.error(response?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
            }
          },
        }
      );
    },
    [
      navigate,
      personalInfo?.fileId,
      personalInfo?.id,
      t.MODULE_SAVED_SUCCESSFULLY,
      t.PERSONAL_INFORMATION,
      t.SOMETHING_WENT_WRONG,
      updateAppFormStatus,
      updatePersonalInfo,
    ]
  );

  const handleSubmit = useCallback(
    async (values?: IFormValuesPersonalInfo, onSuccess?: () => void) => {
      const isDocumentsSchemaValid = await yup.object().shape({ documents: documentsYupObject }).isValid(values);

      if (!isDocumentsSchemaValid) {
        showModal(
          <ConfirmationModal
            title={t.CONFIRMATION}
            message={t.DOCUMENTS_UPLOAD_ERROR_CONFIRM}
            onCancel={closeModal}
            cancelButtonText={t.STAY}
            okButtonText={t.LEAVE}
            onOk={() => {
              savePersonalInfo(values, onSuccess);
              closeModal();
            }}
          />
        );
      } else {
        const newDocumentsIds = await uploadDocuments({
          fileId: appFile?.id as string,
          files: values?.documents as IFormValuesDocumentItem[],
        });

        savePersonalInfo(
          {
            ...(values as IFormValuesPersonalInfo),
            documentIds: [...(values?.documentIds || []), ...newDocumentsIds] as string[],
          },
          onSuccess
        );
      }
    },
    [
      appFile?.id,
      closeModal,
      documentsYupObject,
      savePersonalInfo,
      showModal,
      t.CONFIRMATION,
      t.DOCUMENTS_UPLOAD_ERROR_CONFIRM,
      t.LEAVE,
      t.STAY,
      uploadDocuments,
    ]
  );

  useEffect(() => {
    setShowErrorsList(
      isFormStarted && isPersonalInfoSuccess && isInsolvencyTypesSuccess && isDocumentsSuccess && !isLocked
    );
  }, [isFormStarted, isPersonalInfoSuccess, setShowErrorsList, isInsolvencyTypesSuccess, isDocumentsSuccess, isLocked]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_: IFormValuesPersonalInfo, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={false}
        validateOnChange
        validateOnBlur={isFormStarted}
        validationSchema={validationSchema}
        data-testid="personal-information-form"
      >
        {({ values, submitForm, initialValues }) => {
          return (
            <>
              {isLocked && <SectionLockMessage />}

              <UnsavedForm onSubmit={handleSubmit}>
                {initialValues && <ErrorsList panels={panels} />}

                <>
                  {panels?.map((props) => (
                    <AppFormAccordion key={props?.id} {...props} />
                  ))}
                </>
                <NavigationButtons
                  onNext={async () => {
                    if (isLocked) {
                      navigate(`${ROUTES.APP_FORM}/${ROUTES.ASSETS_DEBTS}`);
                    } else {
                      await submitForm();
                      handleSubmit(values, () => {
                        navigate(`${ROUTES.APP_FORM}/${ROUTES.ASSETS_DEBTS}`);
                      });
                    }
                  }}
                  currentSection={AppformSection.PersonalInfo}
                  disabled={isLocked}
                />
              </UnsavedForm>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const PersonalInformation = () => (
  <AccordionProvider>
    <PersonalInformationProvider>
      <PersonalInformationComponent />
    </PersonalInformationProvider>
  </AccordionProvider>
);

export default PersonalInformation;
