import { Fragment } from 'react';
import { Typography, useTheme, Skeleton, ListItem, ListItemText, ListItemButton } from '@mui/material';

export interface IDataItemProps {
  title?: string | React.ReactElement | undefined;
  content?: Array<string | React.ReactElement | undefined> | string | React.ReactElement | undefined;
  actionComponent?: React.ReactElement;
  clickable?: boolean;
  onClick?: () => void;
}

const ListItemContainer = ({
  clickable,
  onClick,
  children,
}: {
  clickable?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) =>
  clickable ? (
    <ListItemButton onClick={onClick} sx={{ p: 0 }}>
      {children}
    </ListItemButton>
  ) : (
    <Fragment>{children}</Fragment>
  );

const DataItem = ({ title, content, actionComponent, clickable, onClick }: IDataItemProps) => {
  const theme = useTheme();

  return (
    <ListItem dense sx={{ p: 0, gap: 1 }}>
      <ListItemContainer clickable={clickable} onClick={onClick}>
        <ListItemText
          primaryTypographyProps={{ component: 'div' }}
          secondaryTypographyProps={{ component: 'div' }}
          primary={
            typeof title === 'string' ? (
              <Typography variant="body3" color={theme.palette.secondary.main} fontWeight={600}>
                {title}
              </Typography>
            ) : (
              title
            )
          }
          secondary={
            Array.isArray(content) ? (
              content?.map((item, i) =>
                typeof item !== 'string' ? (
                  item || <Skeleton />
                ) : (
                  <Typography variant="body2" key={i} fontWeight={600}>
                    {item?.trim() || <Skeleton />}
                  </Typography>
                )
              )
            ) : (
              <>
                {typeof content !== 'string' ? (
                  content || <Skeleton />
                ) : (
                  <Typography variant="body2" fontWeight={600}>
                    {content?.trim() || <Skeleton />}
                  </Typography>
                )}
              </>
            )
          }
        />
        {actionComponent}
      </ListItemContainer>
    </ListItem>
  );
};

export default DataItem;
