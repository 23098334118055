import { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { Typography, Divider } from '@mui/material';

import ElevatedContainer from '../../components/ElevatedContainer';
import EditExpenseComponent from '../../components/EditExpenseComponent/EditExpenseComponent';

import useLocale from '../../app/hooks/useLocale';

import { IExpenseSubmitValues } from '../../components/EditExpenseComponent/types';
import { isOther } from '../../components/EditExpenseComponent/utils';

import { useApplicationFileQuery, useAppFormExpensesQuery, useSaveReportMutation } from './queries';
import { convertExpenseFormItemToSaveDto, getExpenseFormItem, checkIfEditable } from './utils';
import { DOCUMENTS_BY_IDS_RETRIEVED_EVENT } from '../../app/constants/eventBusKeys';
import eventBus from '../../app/utils/eventBus';

import { ROUTES } from '../../app/routes';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditIEReportEditExpense = () => {
  const { submissionPeriodId } = useParams();
  const { t } = useLocale();
  const navigate = useNavigate();

  const { report, setPreviousSubmission } = useOutletContext<{
    report: ClientPublicService.IncomeExpenseSubmissionPublicDto;
    setPreviousSubmission?: (previousSubmission?: ClientPublicService.IncomeExpenseSubmissionPublicDto) => void;
  }>();

  const [documentsIdsToKeep, setDocumentsIdsToKeep] = useState<string[]>();

  const { data: applicationFile } = useApplicationFileQuery() || {};

  const { data: appFormExpenses } = useAppFormExpensesQuery(applicationFile?.id) || {};

  const { mutate: saveReport } = useSaveReportMutation() || {};

  const isEditable = useMemo(() => checkIfEditable(report), [report]);

  const initialValues = useMemo(
    () => ({
      isMarried: appFormExpenses?.isMarried,
      expenseDetails: report?.expenseItems
        ?.map((expense) => getExpenseFormItem(expense))
        ?.sort((a, b) => (a?.expenseDescription?.orderNumber || 0) - (b?.expenseDescription?.orderNumber || 0))
        ?.sort((a, b) => +isOther(a?.expenseDescription) - +isOther(b?.expenseDescription)),
      documents: undefined,
    }),
    [appFormExpenses?.isMarried, report?.expenseItems]
  );

  const handleDocumentsRetrieved = useCallback((e: any) => {
    const data = e?.detail;
    setDocumentsIdsToKeep(
      data?.providedDocumentsIds?.filter((id: string) => data?.previouslyUploadedIds?.indexOf(id) === -1)
    );
  }, []);

  useEffect(() => {
    if (!documentsIdsToKeep && report) {
      setDocumentsIdsToKeep(report?.documentIds || []);
    }
  }, [documentsIdsToKeep, report?.documentIds, report]);

  useEffect(() => {
    eventBus.on(DOCUMENTS_BY_IDS_RETRIEVED_EVENT, handleDocumentsRetrieved);

    return () => {
      eventBus.remove(DOCUMENTS_BY_IDS_RETRIEVED_EVENT, handleDocumentsRetrieved);
    };
  }, [handleDocumentsRetrieved]);

  return (
    <ElevatedContainer sx={{ borderRadius: 1.5, pt: 2, pb: 2, pl: 1.5, pr: 1.5 }}>
      <Typography variant="body2" fontWeight={600}>
        {t.MONTHLY_EXPENSE}
      </Typography>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <EditExpenseComponent
        isEditable={isEditable}
        documentsUploadProps={{
          isSoftDeleteAllowed: isEditable,
          documentIds: report?.documentIds,
          onlyRetrieveByDocumentIds: true,
        }}
        initialValues={initialValues}
        headerProps={{ sx: { position: 'relative' } }}
        footerProps={{ sx: { position: 'relative' } }}
        onCancel={() => navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}`)}
        onSubmit={(updatedValues?: IExpenseSubmitValues) => {
          saveReport?.(
            {
              ...report,
              expenseItems: updatedValues?.expenseDetails?.map((income) => convertExpenseFormItemToSaveDto(income)),
              documentIds: [...(documentsIdsToKeep || []), ...(updatedValues?.documentIds as string[])],
            } as ClientPublicService.IncomeExpenseSubmissionPublicDto,
            {
              onSuccess: () => {
                setPreviousSubmission?.(undefined);
                navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}`);
              },
            }
          );
        }}
      />
    </ElevatedContainer>
  );
};

export default EditIEReportEditExpense;
