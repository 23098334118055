import { Grid, Box, useTheme, Link, styled, LinkProps, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useLocale from '../app/hooks/useLocale';
import { ILocale } from '../app/providers/LocaleProvider/types';

import { ROUTES } from '../app/routes';
import { useMemo } from 'react';

const websiteUrl = process.env.REACT_APP_BDO_WEBSITE_URL;

const FooterLink = (props: LinkProps) => <StyledLink target="_blank" rel="noopener" {...props} />;

const GoogleTermsOfServiceAndPrivacyPolicy = (termsOfServiceAndPrivacyPolicy: string) => {
  const englishLinks = [
    { placeholder: '{01}', text: 'Privacy Policy', url: 'https://policies.google.com/privacy' },
    { placeholder: '{02}', text: 'Terms of Service', url: 'https://policies.google.com/terms' },
  ];

  const frenchLinks = [
    { placeholder: '{01}', text: 'règles de confidentialité', url: 'https://policies.google.com/privacy' },
    { placeholder: '{02}', text: "conditions d'utilisation", url: 'https://policies.google.com/terms' },
  ];

  const isFrench = /[\u00C0-\u017F]/.test(termsOfServiceAndPrivacyPolicy);
  const links = isFrench ? frenchLinks : englishLinks;

  const splitText = termsOfServiceAndPrivacyPolicy.split(/(\{\d+})|(\{[A-Za-z\s'’]+})/);

  return splitText.map((part) => {
    const match = part?.match(/\{(\d+)|\{([A-Za-z\s'’]+)}/);
    if (match) {
      const index = Number(match[1]) - 1;
      const placeholder = match[2];
      const link = links.find((linkObj) => linkObj.placeholder === match[0]);
      if (index >= 0 && links[index]) {
        return (
          <StyledLink href={links[index].url} key={index}>
            {links[index].text}
          </StyledLink>
        );
      } else if (link) {
        return (
          <StyledLink href={link.url} key={placeholder}>
            {placeholder}
          </StyledLink>
        );
      } else {
        return match[0];
      }
    }
    return part;
  });
};

const Footer = () => {
  const { t, locale } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const isFrench = useMemo(() => locale === ILocale.fr, [locale]);

  return (
    <Box
      sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white, fontSize: 14 }}
      component="footer"
    >
      <Grid container style={{ padding: '20px 40px' }}>
        <Grid item mobile={12} tablet={4}>
          <Stack>
            <StyledLink href="tel:1-855-236-3328">1-855-BDO DEBT</StyledLink>
            <StyledLink onClick={() => navigate(ROUTES.CONTACT_SUPPORT)}>{t.TECHNICAL_SUPPORT}</StyledLink>
          </Stack>
        </Grid>
        <Grid item mobile={12} tablet={4}>
          <Stack>
            <FooterLink
              data-testid="locations"
              href={isFrench ? `${websiteUrl}/fr-ca/emplacements/` : `${websiteUrl}/locations`}
            >
              {t.LOCATIONS}
            </FooterLink>
            <FooterLink href={isFrench ? `${websiteUrl}/fr-ca/nos-gens/` : `${websiteUrl}/Our-people`}>
              {t.OUR_PEOPLE}
            </FooterLink>
            <FooterLink href={isFrench ? `${websiteUrl}/fr-ca/faq/` : `${websiteUrl}/faq`}>{t.FAQ}</FooterLink>
            <FooterLink href={isFrench ? `${websiteUrl}/fr-ca/temoignages/` : `${websiteUrl}/testimonials`}>
              {t.TESTIMONIALS}
            </FooterLink>
            <FooterLink href={isFrench ? `${websiteUrl}/fr-ca/presse-et-medias/` : `${websiteUrl}/Press-and-media`}>
              {t.PRESS_AND_MEDIA}
            </FooterLink>
          </Stack>
        </Grid>
        <Grid item mobile={12} tablet={4}>
          <Stack>
            <FooterLink
              href={
                isFrench
                  ? `${websiteUrl}/fr-ca/restructuration-dettes-dentreprise/`
                  : `${websiteUrl}/Business-debt-solutions`
              }
            >
              {t.BUSINESS_DEBT_SOLUTIONS}
            </FooterLink>
            <FooterLink
              href={
                isFrench
                  ? `${websiteUrl}/fr-ca/programme-de-bien-etre-financier/`
                  : `${websiteUrl}/Financial-wellness-program`
              }
            >
              {t.FINANCIAL_WELLNESS_PROGRAM}
            </FooterLink>
            <FooterLink
              href={
                isFrench
                  ? `https://www.bdo.ca/fr-ca/legal-and-privacy/privacy-statement`
                  : `https://www.bdo.ca/legal-and-privacy/privacy-statement`
              }
            >
              {t.LEGAL_AND_PRIVACY}
            </FooterLink>
            <FooterLink
              href={
                isFrench
                  ? `${websiteUrl}/fr-ca/comment-nous-aidons/quest-ce-quun-syndic-autorise-en-insolvabilite/`
                  : `${websiteUrl}/how-we-help/what-is-a-licensed-insolvency-trustee`
              }
            >
              {t.LICENSED_INSOLVENCY_TRUSTEES}
            </FooterLink>
            <FooterLink
              href={isFrench ? `${websiteUrl}/fr-ca/qui-nous-sommes/` : `${websiteUrl}/who-we-are`}
              style={{ paddingBottom: 0 }}
            >
              {t.WHO_WE_ARE}
            </FooterLink>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: theme.palette.grayFooter, textAlign: 'center' }} style={{ padding: '20px 40px' }}>
        {t.BDO_CANADA_LIMITED_IS_AN_AFFILIATE_OF_BDO_CANADA}
      </Box>
      <Box sx={{ backgroundColor: theme.palette.grayFooter, textAlign: 'center' }} style={{ padding: '20px 40px' }}>
        {GoogleTermsOfServiceAndPrivacyPolicy(t.GOOGLE_RECAPTCHA_TERMS_AND_PRIVACY)}
      </Box>
    </Box>
  );
};

export default Footer;

const StyledLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    paddingBottom: 12,
    color: theme.palette.white,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
