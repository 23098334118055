import { useQuery, useMutation } from '@tanstack/react-query';
import { APPLICATION_FILE_QUERY_KEY, APP_FORM_BANKING_INFO_QUERY_KEY } from '../../../../app/constants/reactQueryKeys';
import API from '../../../../app/api/api';
import { queryClient } from '../../../../app/config';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';

export const useAppFormBakingInfoQuery = (fileId?: string) =>
  useQuery({
    queryKey: [APP_FORM_BANKING_INFO_QUERY_KEY, fileId],
    queryFn: async () => {
      return await API.getAppFormBankingInfo(fileId);
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useUpdateBankingInfoMutation = () =>
  useMutation({
    mutationFn: (bankingInfo: ClientPublicService.IAppFormBankingInfoDto) =>
      API.updateAppFormBankingInfo(bankingInfo as ClientPublicService.AppFormBankingInfoDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [APP_FORM_BANKING_INFO_QUERY_KEY] }),
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });
