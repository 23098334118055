import { useMemo, useCallback } from 'react';
import { Divider, Drawer, DrawerProps, List, Typography, useTheme, Stack } from '@mui/material';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import AppFormMenu from '../../AppFormMenu/AppFormMenu';

import appFormIcon from '../../../app/assets/icons/app-form-icon.svg';
import appointmentsIcon from '../../../app/assets/icons/appointments-icon.svg';
import logo from '../../../app/assets/images/bdo-logo-en.svg';

import PermanentDrawerItem from './PermanentDrawerItem';
import CustomMenuIcon from './CustomMenuIcon';
import UnsavedAppFormChangesModal from '../../../features/AppForm/components/UnsavedAppFormChangesModal';
import LanguageMenu from './LanguageMenu';
import SignOutButton from './SignOutButton';

import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';

import { drawerWidth } from '../constants';
import { ROUTES } from '../../../app/routes';
import eventBus from '../../../app/utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT, CHECK_IF_UNSAVED_CHANGES_EVENT } from '../../../app/constants/eventBusKeys';
import { IPermanentDrawerItemProps, IPermanentDrawerItemType } from './PermanentDrawerItem';

interface IPermanentDrawerProps extends DrawerProps {
  isPreSignupStage?: boolean;
}

const DASHBOARD_KEY = 'dashboard';
const APPLICATION_FORM_KEY = 'applicationForm';
const PAYMENTS_AND_BANKING_KEY = 'paymentsAndBanking';
const DOCUMENTS_KEY = 'documents';
const APPOINTMENTS_KEY = 'appointments';
const CONTACT_STAFF_KEY = 'contactStaff';
const MY_PROFILE_KEY = 'myProfile';
const TECHNICAL_SUPPORT_KEY = 'technicalSupport';

export default function PermanentDrawer({ isPreSignupStage, ...props }: IPermanentDrawerProps) {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal, closeModal } = useModal();

  const isAppFormRoute = useMemo(
    () => Boolean(matchPath(`/${ROUTES.APP_FORM}/*`, location?.pathname)),
    [location?.pathname]
  );

  const isTrackChangesAppFormRoute = useMemo(
    () =>
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.PERSONAL_INFORMATION}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.INCOME_TAX}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.BANKING}`, location?.pathname)) ||
      Boolean(matchPath(`/${ROUTES.APP_FORM}/${ROUTES.QUESTIONNAIRE}`, location?.pathname)),

    [location?.pathname]
  );

  const handleMenuItemClick = useCallback(
    (item?: IPermanentDrawerItemProps) => {
      const url = item?.url as string;

      if (isTrackChangesAppFormRoute) {
        eventBus.dispatch(CHECK_IF_UNSAVED_CHANGES_EVENT, {
          onCheckSuccess: (isChangesUnsaved?: boolean) => {
            if (isChangesUnsaved) {
              showModal(
                <UnsavedAppFormChangesModal
                  onLeave={() => {
                    navigate(url);
                    closeModal();
                  }}
                  onSaveAndLeave={() => {
                    eventBus.dispatch(APPFORM_SAVE_AND_LEAVE_EVENT, {
                      onSaveSuccess: () => {
                        navigate(url);
                        closeModal();
                      },
                    });
                  }}
                />
              );
            } else {
              navigate(url);
            }
          },
        });
      } else {
        navigate(url);
      }
    },
    [closeModal, isTrackChangesAppFormRoute, navigate, showModal]
  );

  const menu: IPermanentDrawerItemProps[] = useMemo(
    () =>
      [
        {
          key: DASHBOARD_KEY,
          title: t.DASHBOARD,
          icon: <HomeIcon />,
          url: ROUTES.DASHBOARD,
        },
        {
          key: APPLICATION_FORM_KEY,
          title: t.APPLICATION_FORM,
          icon: <CustomMenuIcon src={appFormIcon} alt="app-form-icon" />,
          url: ROUTES.APP_FORM,
          drawerItemHidden: isPreSignupStage === false || isPreSignupStage === undefined,
          submenu: isAppFormRoute ? <AppFormMenu /> : null,
        },
        {
          key: PAYMENTS_AND_BANKING_KEY,
          title: t.PAYMENTS_AND_BANKING,
          icon: <CustomMenuIcon src={appFormIcon} alt="app-form-icon" />,
          url: ROUTES.PAYMENTS_AND_BANKING,
          drawerItemHidden: isPreSignupStage === true || isPreSignupStage === undefined,
        },
        {
          key: DOCUMENTS_KEY,
          title: t.DOCUMENTS,
          icon: <FolderIcon />,
          url: ROUTES.DOCUMENTS,
        },
        {
          key: APPOINTMENTS_KEY,
          title: t.APPOINTMENTS,
          icon: <CustomMenuIcon src={appointmentsIcon} alt="appointments-icon" />,
          url: ROUTES.APPOINTMENTS,
        },
        {
          type: 'divider' as IPermanentDrawerItemType,
        },
        {
          key: CONTACT_STAFF_KEY,
          title: t.CONTACT_STAFF,
          icon: <EmailIcon />,
          url: ROUTES.CONTACT_STAFF,
        },
        {
          key: MY_PROFILE_KEY,
          title: t.MY_PROFILE,
          icon: <SettingsIcon />,
          url: ROUTES.PROFILE,
        },
        {
          key: TECHNICAL_SUPPORT_KEY,
          title: t.TECHNICAL_SUPPORT,
          icon: <BuildIcon />,
          url: ROUTES.CONTACT_SUPPORT,
        },
        {
          type: 'divider' as IPermanentDrawerItemType,
        },
      ]?.filter((item) => !item?.drawerItemHidden),
    [
      isAppFormRoute,
      isPreSignupStage,
      t.APPLICATION_FORM,
      t.APPOINTMENTS,
      t.CONTACT_STAFF,
      t.DASHBOARD,
      t.DOCUMENTS,
      t.MY_PROFILE,
      t.TECHNICAL_SUPPORT,
      t.PAYMENTS_AND_BANKING,
    ]
  );

  const drawer = useMemo(
    () => (
      <Stack
        justifyContent="space-between"
        sx={{ paddingTop: 3, paddingBottom: 4, paddingLeft: 2, paddingRight: 2, flex: 1 }}
      >
        <Stack>
          <Stack alignItems="center" sx={{ paddingBottom: 3 }}>
            <img src={logo} alt="bdo-logo" width={110} height="auto" />
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, color: theme.palette.slate, paddingTop: 1 }}
              data-testid="debthub"
            >
              {t.DEBT_HUB}
            </Typography>
          </Stack>
          <List data-testid="menu">
            {menu?.map((item, index) =>
              item?.type === 'divider' ? (
                <Divider key={index} sx={{ mt: 1, mb: 1 }} />
              ) : (
                <PermanentDrawerItem
                  {...item}
                  key={item?.key}
                  onClick={() => handleMenuItemClick(item)}
                  sx={
                    item.key === APPLICATION_FORM_KEY && isAppFormRoute
                      ? {
                          backgroundColor: theme.palette.paleCharcoal,
                        }
                      : {}
                  }
                />
              )
            )}
            <LanguageMenu type="permanent-drawer" />
          </List>
        </Stack>

        <Stack>
          <Divider />
          <SignOutButton />
        </Stack>
      </Stack>
    ),
    [theme.palette.slate, theme.palette.paleCharcoal, t.DEBT_HUB, menu, isAppFormRoute, handleMenuItemClick]
  );

  return (
    <Drawer
      {...props}
      variant="permanent"
      open
      sx={{
        display: { mobile: 'none', desktop: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
      data-testid="permanent-drawer"
    >
      {drawer}
    </Drawer>
  );
}
