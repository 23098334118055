import { Navigate } from 'react-router-dom';

import Layout from '../../../components/Layout';

import useAuthService from '../../hooks/useAuthService';

const ProtectedRoute = ({
  children,
}: {
  children?: React.ReactElement | Array<React.ReactElement> | string | boolean | any;
}) => {
  const { isAuthenticated } = useAuthService();

  return !isAuthenticated() ? <Navigate to="/" replace /> : <Layout>{children}</Layout>;
};

export default ProtectedRoute;
