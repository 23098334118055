import { useField } from 'formik';
import { ToggleButton, FormHelperText, Box } from '@mui/material';
import RecommendableField from './RecommendableField';

const CustomToggleButton = ({ field, form, ...props }: any) => {
  const [, meta] = useField(field?.name);
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <ToggleButton
        {...field}
        {...props}
        name={field?.name}
        value={field?.value || false}
        selected={field?.value}
        onChange={() => form.setFieldValue(field.name, !field.value)}
        fullWidth
      >
        {props?.label}
      </ToggleButton>
      {meta?.touched && !!meta?.error && <FormHelperText error>{meta?.error}</FormHelperText>}
    </Box>
  );
};

const ToggleButtonField = (props: any) => {
  return <RecommendableField component={CustomToggleButton} type="checkbox" name={props?.name} {...props} />;
};

export default ToggleButtonField;
