import { useQuery, useMutation } from '@tanstack/react-query';

import {
  FOLDERS_GET_QUERY_KEY,
  PREFIXES_GET_QUERY_KEY,
  DOCUMENTS_QUERY_KEY,
  APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY,
  DOCUMENTS_TYPES_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import {
  argumentifyDocumentsGetParams,
  argumentifyDocumentCreateParams,
  convertFormValuesToDocumentCreateParams,
  argumentifyPrefixesQueryParams,
} from './utils';
import { IDocumentsSearchParams, IFormValuesDocumentItem, IPrefixesQueryParams } from './types';
import { BaseService } from '../../app/api/BaseService';

import API from '../../app/api/api';
import { queryClient } from '../../app/config';

export const useFoldersQuery = (enabled = true) =>
  useQuery({
    queryKey: [FOLDERS_GET_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listFolders();

      return data;
    },
    enabled: enabled && !queryClient.getQueryData([FOLDERS_GET_QUERY_KEY]),
  });

export const usePrefixesQuery = ({ documentFolderId, prefixCodes, assetId }: IPrefixesQueryParams, enabled = true) =>
  useQuery({
    queryKey: [PREFIXES_GET_QUERY_KEY, documentFolderId, ...(prefixCodes || []), assetId],
    queryFn: async () => {
      const data = await API.listPrefixes(
        ...argumentifyPrefixesQueryParams({ documentFolderId, assetId, prefixCodes })
      );
      return data;
    },
    enabled: !!documentFolderId && enabled,
  });

export const useDocumentsTypesQuery = () =>
  useQuery({
    queryKey: [DOCUMENTS_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listTypes();

      return data;
    },
    enabled: !queryClient.getQueryData([DOCUMENTS_TYPES_QUERY_KEY]),
  });

export const useDocumentsQuery = (params: IDocumentsSearchParams) => {
  const args = argumentifyDocumentsGetParams({ ...params, isVisible: true });

  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, ...args],
    queryFn: async () => {
      const data = await API.documentsGET(...args);

      return data;
    },
    enabled: !!params?.fileId,
  });
};

export const useAppFormSupportingDocumentsQuery = (
  { documentFolderId, documentPrefixCodes, documentIds }: IDocumentsSearchParams,
  onlyRetrieveByDocumentIds = false,
  enabled = true
) => {
  return useQuery({
    queryKey: [
      APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY,
      documentFolderId,
      ...(documentPrefixCodes || []),
      documentIds || [],
      onlyRetrieveByDocumentIds,
    ],
    queryFn: async () => {
      //if retrieveing only by document ids and there are no document ids, return empty results
      if (onlyRetrieveByDocumentIds && !documentIds?.length) {
        return {
          items: [],
          totalCount: 0,
        };
      }

      const data = await API.documentsGET(
        ...argumentifyDocumentsGetParams({
          documentFolderId,
          documentPrefixCodes,
          documentIds,
        })
      );

      return data;
    },
    enabled: !!documentFolderId && enabled,
  });
};

export const useDocumentUploadMutation = () =>
  useMutation({
    mutationFn: async ({ fileId, values }: { fileId: string; values?: IFormValuesDocumentItem }) => {
      return API.documentsPOST(
        ...argumentifyDocumentCreateParams(convertFormValuesToDocumentCreateParams({ fileId, values }))
      ).catch((error) => error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY]);
      queryClient.invalidateQueries([APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY]);
    },
  });

export const useDocumentsUploadMutation = () =>
  useMutation({
    mutationFn: async ({ fileId, files }: { fileId: string; files: IFormValuesDocumentItem[] }) => {
      const newFiles = (files || [])?.filter((item: IFormValuesDocumentItem) => !item.id);

      const documentIds: string[] = [];

      for (let file of newFiles) {
        const params = convertFormValuesToDocumentCreateParams({ fileId, values: file });
        const result = await API.documentsPOST(...argumentifyDocumentCreateParams(params)).catch((error) => error);
        if (result?.returnId) {
          documentIds.push(result.returnId);
        }
      }

      return documentIds;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY]);
      queryClient.invalidateQueries([APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY]);
    },
  });

export const useDownloadBlobMutation = () =>
  useMutation({
    mutationFn: async (documentId: string) => {
      const token = new BaseService().getAccessTokenHeader();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/client-service-public/documents/${documentId}/download`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
          }),
        }
      );
      return response.blob();
    },
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });

export const useDocumentDeleteMutation = () =>
  useMutation({
    mutationFn: async (documentId: string) => {
      return API.documentsDELETE([documentId]).catch((error) => error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY]);
      queryClient.invalidateQueries([APP_FORM_SUPPORTING_DOCUMENTS_QUERY_KEY]);
    },
  });
