import { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { List, Menu, MenuItem, useTheme, Box } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useLocale from '../../../app/hooks/useLocale';
import useModal from '../../../app/hooks/useModal';
import AppFormMenuItem, { IAppFormMenuItem } from '../../../components/AppFormMenu/AppFormMenuItem';
import { useAppFormStatusQuery, useApplicationFileQuery } from '../queries';
import { ROUTES } from '../../../app/routes';
import { APPFORM_SAVE_AND_LEAVE_EVENT, CHECK_IF_UNSAVED_CHANGES_EVENT } from '../../../app/constants/eventBusKeys';
import eventBus from '../../../app/utils/eventBus';
import UnsavedAppFormChangesModal from './UnsavedAppFormChangesModal';

const PERSONAL_INFORMATION_KEY = 'personalInformation';
const ASSETS_DEBTS_KEY = 'assetsDebts';
const INCOME_EXPENSE_KEY = 'incomeExpense';
const INCOME_TAX_KEY = 'incomeTax';
const BANKING_INFORMATION_KEY = 'bankingInformation';
const QUESTIONNAIRE_KEY = 'questionnaire';

const AppFormNavigatorBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useLocale();
  const theme = useTheme();

  const { showModal, closeModal } = useModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<IAppFormMenuItem>();

  const { data: appFileData } = useApplicationFileQuery() || {};

  const { data } = useAppFormStatusQuery(appFileData?.id) || {};

  const open = Boolean(anchorEl);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const setItemAndNavigate = useCallback(
    (item: IAppFormMenuItem) => {
      setSelected(item);

      if (item?.appModuleRoute) {
        navigate(`${ROUTES.APP_FORM}/${item?.appModuleRoute}`);
      }
    },
    [navigate]
  );

  const isTrackChangesAppFormRoute = useMemo(
    () =>
      selected?.id === PERSONAL_INFORMATION_KEY ||
      selected?.id === INCOME_TAX_KEY ||
      selected?.id === BANKING_INFORMATION_KEY ||
      selected?.id === QUESTIONNAIRE_KEY,
    [selected?.id]
  );

  const handleMenuItemClick = useCallback(
    (item: IAppFormMenuItem) => {
      setAnchorEl(null);
      if (item?.id === selected?.id) return;

      if (isTrackChangesAppFormRoute) {
        eventBus.dispatch(CHECK_IF_UNSAVED_CHANGES_EVENT, {
          onCheckSuccess: (isChangesUnsaved?: boolean) => {
            if (isChangesUnsaved) {
              showModal(
                <UnsavedAppFormChangesModal
                  onLeave={() => {
                    setItemAndNavigate(item);
                    closeModal();
                  }}
                  onSaveAndLeave={() => {
                    eventBus.dispatch(APPFORM_SAVE_AND_LEAVE_EVENT, {
                      onSaveSuccess: () => {
                        setItemAndNavigate(item);
                        closeModal();
                      },
                    });
                  }}
                />
              );
            } else {
              setItemAndNavigate(item);
            }
          },
        });
      } else {
        setItemAndNavigate(item);
      }
    },
    [closeModal, isTrackChangesAppFormRoute, selected, setItemAndNavigate, showModal]
  );

  const menu: IAppFormMenuItem[] = useMemo(
    () => [
      {
        id: PERSONAL_INFORMATION_KEY,
        appModuleName: t.PERSONAL_INFORMATION,
        appModuleStatus: data?.personalInfoStatus,
        appModuleRoute: ROUTES.PERSONAL_INFORMATION,
      },
      {
        id: ASSETS_DEBTS_KEY,
        appModuleName: t.ASSETS_DEBTS,
        appModuleStatus: data?.assetsDebtsStatus,
        appModuleRoute: ROUTES.ASSETS_DEBTS,
      },
      {
        id: INCOME_EXPENSE_KEY,
        appModuleName: t.INCOME_EXPENSE,
        appModuleStatus: data?.incomeExpenseStatus,
        appModuleRoute: ROUTES.INCOME_EXPENSE,
      },
      {
        id: INCOME_TAX_KEY,
        appModuleName: t.INCOME_TAX,
        appModuleStatus: data?.incomeTaxStatus,
        appModuleRoute: ROUTES.INCOME_TAX,
      },
      {
        id: BANKING_INFORMATION_KEY,
        appModuleName: t.BANKING_INFORMATION,
        appModuleStatus: data?.bankingInformationStatus,
        appModuleRoute: ROUTES.BANKING,
      },
      {
        id: QUESTIONNAIRE_KEY,
        appModuleName: t.QUESTIONNAIRE,
        appModuleStatus: data?.questionnaireStatus,
        appModuleRoute: ROUTES.QUESTIONNAIRE,
      },
    ],
    [
      data?.assetsDebtsStatus,
      data?.bankingInformationStatus,
      data?.incomeExpenseStatus,
      data?.incomeTaxStatus,
      data?.personalInfoStatus,
      data?.questionnaireStatus,
      t.ASSETS_DEBTS,
      t.BANKING_INFORMATION,
      t.INCOME_EXPENSE,
      t.INCOME_TAX,
      t.PERSONAL_INFORMATION,
      t.QUESTIONNAIRE,
    ]
  );

  useEffect(() => {
    const match = menu?.find((item) =>
      matchPath({ path: `${ROUTES.APP_FORM}/${item?.appModuleRoute}` }, location.pathname)
    );
    setSelected(match);
  }, [location.pathname, menu]);

  return (
    <Box
      sx={{
        pb: 2.5,
      }}
      data-testid="mobile-app-form-menu"
    >
      <List
        component="div"
        disablePadding
        sx={{
          '.MuiListItemButton-root:hover': {
            backgroundColor: theme.palette.white,
          },
          '.MuiMenu-paper': {
            right: 16,
          },
        }}
      >
        <AppFormMenuItem
          aria-expanded={open ? 'true' : undefined}
          appModuleName={selected?.appModuleName}
          appModuleStatus={selected?.appModuleStatus}
          onClick={handleOpen}
          sx={{
            pt: 2.5,
            borderRadius: 1.5,
            boxShadow: `0px 2px 4px -2px rgba(0, 0, 0, 0.12), 
                                    0px 4px 9px 3px rgba(0, 0, 0, 0.04),
                                    0px 1px 5px 0px rgba(0, 0, 0, 0.012);`,
          }}
          appModuleId={selected?.id}
          displayNavBar={true}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </AppFormMenuItem>
      </List>
      <Menu
        id="app-form-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            '.MuiMenuItem-root': {
              p: 0,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              right: 16,
              p: 0,
            },
          },
        }}
      >
        {menu?.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)} divider={index !== menu.length - 1}>
              <AppFormMenuItem
                appModuleName={item?.appModuleName}
                appModuleStatus={item?.appModuleStatus}
                displayNavBar={false}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default AppFormNavigatorBar;
