import { useQuery, useMutation } from '@tanstack/react-query';

import {
  DEBTOR_INFORMATION_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  CANADA_PROVINCES_QUERY_KEY,
  USA_PROVINCES_QUERY_KEY,
  COUNTRIES_QUERY_KEY,
  PHONE_TYPES_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import { IUpdateDebtorSettingsParams, IUpdateDebtorProfileParams } from './types';
import { queryClient } from '../../app/config';

import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const usePhoneTypesQuery = () =>
  useQuery({
    queryKey: [PHONE_TYPES_QUERY_KEY],
    queryFn: async () => {
      return await API.listPhoneTypes();
    },
  });

export const useCanadaProvincesQuery = (canadaCountryId?: string) =>
  useQuery({
    queryKey: [CANADA_PROVINCES_QUERY_KEY, canadaCountryId],
    queryFn: async () => {
      const data = await API.listProvinces(canadaCountryId);
      return data;
    },
    enabled: !!canadaCountryId,
    refetchOnWindowFocus: false,
  });

export const useUsaProvincesQuery = (usaCountryId?: string) =>
  useQuery({
    queryKey: [USA_PROVINCES_QUERY_KEY, usaCountryId],
    queryFn: async () => {
      const data = await API.listProvinces(usaCountryId);
      return data;
    },
    enabled: !!usaCountryId,
    refetchOnWindowFocus: false,
  });

export const useCountriesQuery = () =>
  useQuery({
    queryKey: [COUNTRIES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listCountries();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useSettingsMutation = () =>
  useMutation({
    mutationFn: ({ fileId, ...rest }: IUpdateDebtorSettingsParams) => {
      return API.settings(fileId as string, rest as ClientPublicService.DebtorSettingsPublicUpdateDto);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DEBTOR_INFORMATION_QUERY_KEY] }),
  });

export const useDebtorProfileMutation = () =>
  useMutation({
    mutationFn: ({ fileId, ...rest }: IUpdateDebtorProfileParams) => {
      return API.updateDebtorProfile(fileId as string, rest as ClientPublicService.DebtorProfileUpdatePublicDto);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DEBTOR_INFORMATION_QUERY_KEY] }),
  });

export const useDebtorPasswordMutation = () =>
  useMutation<
    ClientPublicService.SaveResult,
    ClientPublicService.RemoteServiceErrorResponse,
    ClientPublicService.IDebtorPasswordUpdatePublicDto,
    unknown
  >({
    mutationFn: (requestParams: ClientPublicService.IDebtorPasswordUpdatePublicDto) => {
      return API.updateDebtorPassword(requestParams as ClientPublicService.DebtorPasswordUpdatePublicDto);
    },
  });
