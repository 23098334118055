import { ClientPublicService } from '../../app/api/ClientPublicService';
import type {
  IDocumentsSearchParams,
  IDocumentCreateParams,
  IFormValuesDocumentItem,
  IPrefixesQueryParams,
} from './types';
import { getToday } from '../../app/utils/dateTimeHelpers';

const argumentifyDocumentsGetParams = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentsSearchParams) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

const argumentifyDocumentCreateParams = ({
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  isVisible,
  fileModifiedDate,
  content,
  isForSpouse,
  businessName,
}: IDocumentCreateParams) =>
  [
    documentFolderId,
    documentPrefixId,
    additionalInformation,
    isVisible,
    fileModifiedDate,
    content,
    isForSpouse,
    businessName,
  ] as const;

const getDocumentFormItem = (item?: ClientPublicService.DocumentDto) => ({
  documentFolderId: item?.documentFolderId || '',
  documentPrefixId: item?.documentPrefixId || '',
  additionalInformation: item?.additionalInformation || '',
  documentPrefixName: item?.documentPrefixName || '',
  id: item?.id,
  name: item?.name || '',
  fileSizeInBytes: item?.fileSizeInBytes || 0,
  businessName: item?.businessName || '',
  isForSpouse: item?.isForSpouse || false,
  isSupportingDocumentReviewed: item?.isSupportingDocumentReviewed || false,
  frenchName: item?.frenchName,
});

const convertFormValuesToDocumentCreateParams = ({
  fileId,
  values,
}: {
  fileId?: string;
  values?: IFormValuesDocumentItem;
}) => ({
  fileId,
  documentFolderId: values?.documentFolderId || undefined,
  documentPrefixId: values?.documentPrefixId,
  additionalInformation: values?.additionalInformation,
  content: { fileName: values?.name as string, data: values?.blob as Blob },
  fileModifiedDate: getToday(),
  isForSpouse: values?.isForSpouse,
  businessName: values?.businessName || '',
  isVisible: values?.isVisible === undefined ? true : values?.isVisible,
});

const argumentifyPrefixesQueryParams = ({ documentFolderId, assetId, prefixCodes }: IPrefixesQueryParams) =>
  [documentFolderId, assetId, prefixCodes] as const;

export {
  argumentifyDocumentsGetParams,
  getDocumentFormItem,
  argumentifyDocumentCreateParams,
  convertFormValuesToDocumentCreateParams,
  argumentifyPrefixesQueryParams,
};
