import { useQuery, useMutation } from '@tanstack/react-query';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import { CPC_TEAM_QUERY_KEY } from '../../app/constants/reactQueryKeys';

import API from '../../app/api/api';

export const useCPCTeamsQuery = () =>
  useQuery({
    queryKey: [CPC_TEAM_QUERY_KEY],
    queryFn: async () => {
      return await API.getAllCPCTeams();
    },
  });

export const useSendContactStaffMessageMutation = () =>
  useMutation({
    mutationFn: (contactStaffInfo: ClientPublicService.IContactStaffDto) =>
      API.sendMessage(contactStaffInfo as ClientPublicService.ContactStaffDto),
  });
