import { useCallback } from 'react';
import { MenuList, MenuItem, Stack, Box, styled, IconButton } from '@mui/material';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Clear } from '@mui/icons-material';
import { useFormikContext } from 'formik';

import { TextField } from '../../../../components/FormFields';
import GoogleMapsLoaderWrapper from '../../../../components/GoogleMapsLoaderWrapper/GoogleMapsLoaderWrapper';

import useLocale from '../../../../app/hooks/useLocale';

import { ClientPublicService } from '../../../../app/api/ClientPublicService';

const ChangeRequestFormComponent = () => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<ClientPublicService.PADInfoChangeRequestCreatePublicDto>();

  const handleSelect = useCallback(
    async (inputText: string) => {
      if (!inputText) return;

      const result = await geocodeByAddress(inputText);

      if (result && result[0] && result[0].formatted_address) {
        setFieldValue('bankAddress', result[0].formatted_address);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <TextField name="accountName" label={t.ACCOUNT_NAME} required />

      <TextField name="bankName" label={t.BANK_NAME} required />

      <PlacesAutocomplete
        value={values?.bankAddress}
        onChange={(value) => setFieldValue('bankAddress', value)}
        onSelect={(value) => handleSelect(value)}
        searchOptions={{
          types: ['geocode'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Stack>
            <TextField
              inputProps={{ ...getInputProps() }}
              name="bankAddress"
              label={t.BANK_ADDRESS}
              required
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: values?.bankAddress ? 'visible' : 'hidden', p: 0 }}
                    onClick={() => setFieldValue('address', '')}
                    aria-label="clear address field"
                  >
                    <Clear />
                  </IconButton>
                ),
              }}
            />
            {Boolean(suggestions?.length) && (
              <Box style={{ position: 'relative' }}>
                <SuggestionsList>
                  {suggestions?.map((suggestion, index) => (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion)}
                      key={`suggestion-${index}`}
                      style={{ whiteSpace: 'normal' }}
                      data-testid={suggestion.description}
                      onClick={(e) => {
                        getSuggestionItemProps(suggestion)?.onClick(e);
                        setFieldValue('bankAddress', suggestion.description);
                      }}
                    >
                      {suggestion.description}
                    </MenuItem>
                  ))}
                </SuggestionsList>
              </Box>
            )}
          </Stack>
        )}
      </PlacesAutocomplete>

      <TextField name="accountNumber" label={t.ACCOUNT_NUMBER} required />

      <TextField name="transitNumber" label={t.TRANSIT_NUMBER} required />

      <TextField name="branchNumber" label={t.BRANCH_NUMBER} required />
    </>
  );
};

const ChangeRequestForm = (props: any) => (
  <GoogleMapsLoaderWrapper component={<ChangeRequestFormComponent {...props} />} />
);

export default ChangeRequestForm;

const SuggestionsList = styled(MenuList)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: theme.palette.white,
  padding: 0,
}));
