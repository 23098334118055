import { IconButton, useTheme } from '@mui/material';

import { DeleteOutlined, Download } from '@mui/icons-material';

export type ActionButtonType = 'download' | 'delete';

interface IActionButtonProps {
  type: ActionButtonType;
  onClick?: () => void;
}

const ActionButton = ({ type, onClick }: IActionButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton edge="end" aria-label="item-action-button" onClick={onClick}>
      {type === 'download' ? (
        <Download sx={{ fontSize: { mobile: 28, tablet: 24 } }} />
      ) : (
        <DeleteOutlined sx={{ fontSize: { mobile: 28, tablet: 24 }, color: theme.palette.error.main }} />
      )}
    </IconButton>
  );
};

export default ActionButton;
