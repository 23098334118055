import { Box, DrawerProps } from '@mui/material';

import AppMenu from './components/AppMenu';
import PermanentDrawer from './components/PermanentDrawer';

import { drawerWidth } from './constants';
import { useDebtorProfileQuery } from './queries';

export interface IAppDrawerProps extends DrawerProps {
  window?: () => Window;
  menuOpen?: boolean;
  handleMenuToggle?: () => void;
  isAuthenticated?: boolean;
}

const AppDrawer = ({ isAuthenticated, ...props }: IAppDrawerProps) => {
  const { data: debtorProfile } = useDebtorProfileQuery(isAuthenticated) || {};

  return (
    <Box component="nav" aria-label="menu" sx={{ width: { desktop: isAuthenticated ? drawerWidth : 0 } }}>
      <AppMenu
        {...props}
        isAuthenticated={isAuthenticated}
        isPreSignupStage={!debtorProfile?.profile?.isFileStagePostSignup}
      />
      {isAuthenticated ? <PermanentDrawer isPreSignupStage={!debtorProfile?.profile?.isFileStagePostSignup} /> : <></>}
    </Box>
  );
};

export default AppDrawer;
