import { useQuery } from '@tanstack/react-query';

import {
  APP_FORM_STATUS_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  UPCOMING_APPOINTMENTS_GET_QUERY_KEY,
  SUPPORTING_DOCUMENTS_COUNT_QUERY_KEY,
  SIGNUP_STATUS_QUERY_KEY,
  DEBTOR_INFORMATION_QUERY_KEY,
  DOCUMENTS_QUERY_KEY,
  BALANCE_QUERY_KEY,
  OUTSTANDING_PAYMENTS_COUNT_QUERY_KEY,
  NEXT_PAYMENT_QUERY,
  PENDING_COUNT_IE_REPORTS_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';
import { IAppointmentsSearchParams, IPaymentsCountSearchParams } from './types';
import {
  argumentifyAppointmentsSearchParams,
  argumentifySupportingDocumentsSearchParams,
  argumentifyDocumentsGetParams,
  argumentifyCountPaymentsSearchParams,
  argumentifyPaymentsSearchParams,
  argumentifyIEReportsSearchParams,
} from './utils';
import moment, { inputDateToServerFormat } from '../../app/utils/dateTimeHelpers';

import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';

const PAD_TYPE = 'PAD';
const PAP_TYPE = 'PAP';

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
  });

export const useAppFormStatusQuery = (fileId?: string) =>
  useQuery({
    queryKey: [APP_FORM_STATUS_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormStatus(fileId);

      return data;
    },
    enabled: !!fileId,
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
  });

export const useSupportingDocumentsCountQuery = () =>
  useQuery({
    queryKey: [SUPPORTING_DOCUMENTS_COUNT_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAllSupportingdocuments(
        ...argumentifySupportingDocumentsSearchParams({
          supportingDocumentStatus: ClientPublicService.SupportingDocumentStatusEnum.Pending,
        })
      );

      return data?.totalCount;
    },
  });

export const useDocumentsCountQuery = (fileId?: string) => {
  const args = argumentifyDocumentsGetParams({ fileId, isVisible: true });

  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, ...args],
    queryFn: async () => {
      const data = await API.documentsGET(...args);

      return data?.totalCount;
    },
    enabled: !!fileId,
  });
};

export const useUpcomingAppointmentsQuery = (requestParams: IAppointmentsSearchParams) =>
  useQuery({
    queryKey: [UPCOMING_APPOINTMENTS_GET_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getAppointmentInfoList(
        ...argumentifyAppointmentsSearchParams({
          appointmentStatusEnums: [ClientPublicService.AppointmentStatusEnum.Pending],
          startDateTimeAfter: moment(),
          ...requestParams,
        })
      );
      return data;
    },
  });

export const useAdminInfoSignupStatusQuery = () =>
  useQuery({
    queryKey: [SIGNUP_STATUS_QUERY_KEY],
    queryFn: async () => {
      return await API.signUp();
    },
  });

export const useBalanceQuery = () =>
  useQuery({
    queryKey: [BALANCE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.balanceInfo();

      return data;
    },
  });

export const useOutstadingPaymentsCountQuery = (fileId?: string) =>
  useQuery({
    queryKey: [OUTSTANDING_PAYMENTS_COUNT_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.count(
        ...argumentifyCountPaymentsSearchParams({
          fileId,
          isDeposited: false,
          receiptDateTo: inputDateToServerFormat(moment()),
          maxResultCount: 1000,
        } as IPaymentsCountSearchParams)
      );
      return data;
    },
    enabled: !!fileId,
  });

export const useNextPaymentQuery = (fileId?: string) =>
  useQuery({
    queryKey: [NEXT_PAYMENT_QUERY],
    queryFn: async () => {
      const data = await API.padScheduleAll(
        ...argumentifyPaymentsSearchParams({
          fileId: fileId as string,
          receiptDateFrom: inputDateToServerFormat(moment()),
          maxResultCount: 1000,
        })
      );
      return data
        ?.filter((item) => item?.type === PAP_TYPE || item?.type === PAD_TYPE)
        ?.sort((a, b) => moment(a?.dueDate).diff(moment(b?.dueDate)))?.[0];
    },
    enabled: !!fileId,
  });

export const usePendingIEReportsQuery = () => {
  const params = argumentifyIEReportsSearchParams({
    statuses: [
      ClientPublicService.IncomeExpensePeriodStatusEnum.Pending,
      ClientPublicService.IncomeExpensePeriodStatusEnum.MissingInformation,
      ClientPublicService.IncomeExpensePeriodStatusEnum.DiscrepancyNotice,
      ClientPublicService.IncomeExpensePeriodStatusEnum.AmendmentRequired,
    ],
    maxResultCount: 1000,
  });
  return useQuery({
    queryKey: [PENDING_COUNT_IE_REPORTS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.schedulesAll(...params);
      return data;
    },
  });
};
