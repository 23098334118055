import { useNavigate } from 'react-router-dom';

import PageContentContainer from '../../components/PageContentContainer';
import PendingIEReports from './components/PendingIEReports';
import CompletedIEReports from './components/CompletedIEReports';

import useLocale from '../../app/hooks/useLocale';

import { ROUTES } from '../../app/routes';

const IEReports = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  return (
    <PageContentContainer
      headerProps={{
        title: t.INCOME_AND_EXPENSE_REPORTS,
        onBackButtonClick: () => navigate(ROUTES.DASHBOARD, { replace: true }),
      }}
    >
      <PendingIEReports />
      <CompletedIEReports />
    </PageContentContainer>
  );
};

export default IEReports;
