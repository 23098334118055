import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { TextField, ToggleButtonField } from '../../../../../components/FormFields';
import { FormLabel, useTheme } from '@mui/material';

import CustomLabel from '../../../../../components/CustomLabel';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormValuesPersonalInfo } from '../types';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const FinancialDifficulty = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const handleLegalMattersToggle = useCallback(() => {
    setFieldValue('specifyFDReasonLegalMatters', '');
  }, [setFieldValue]);

  const handleReasonOtherToggle = useCallback(() => {
    setFieldValue('specifiedFDReason', '');
  }, [setFieldValue]);

  const handleSpouseLegalMattersToggle = useCallback(() => {
    setFieldValue('spouseSpecifyFDReasonLegalMatters', '');
  }, [setFieldValue]);

  const handleSpouseReasonOtherToggle = useCallback(() => {
    setFieldValue('spouseSpecifiedFDReason', '');
  }, [setFieldValue]);

  return (
    <>
      <FormLabel sx={{ fontWeight: 600, color: theme.palette.primary.main, fontSize: 16 }}>
        <CustomLabel label={t.TITLE_APPLICANT} required />
      </FormLabel>
      <br />
      <FormLabel sx={{ fontWeight: 600, color: theme.palette.primary.main, fontSize: 14 }}>
        <CustomLabel label={t.WHAT_ARE_REASONS_FOR_DIFFICULTY} required />
      </FormLabel>
      <ToggleButtonField name="hasFDReasonLossOfIncome" label={t.LOSS_OF_INCOME} disabled={isLocked} />
      <ToggleButtonField name="hasFDReasonMedicalReasons" label={t.MEDICAL_REASONS} disabled={isLocked} />
      <ToggleButtonField name="hasFDReasonBusinessFailure" label={t.BUSINESS_FAILURE} disabled={isLocked} />
      <ToggleButtonField name="hasFDReasonGambling" label={t.GAMBLING} disabled={isLocked} />
      <ToggleButtonField
        name="hasFDReasonDrugAlcoholAddicition"
        label={t.ADDICTION_OTHER_THAN_GAMBLING}
        disabled={isLocked}
      />
      <ToggleButtonField
        name="hasFDReasonMaritalSeparationOrRelationshipBreakdown"
        label={t.RELATIONSHIP_BREAKDOWN}
        disabled={isLocked}
      />
      <ToggleButtonField name="hasFDReasonTaxLiabilities" label={t.TAX_LIABILITIES} disabled={isLocked} />
      <ToggleButtonField
        name="hasFDReasonFinancialSupportOfOthers"
        label={t.FINANCIAL_SUPPORT_OF_OTHERS}
        disabled={isLocked}
      />
      <ToggleButtonField name="hasFDReasonStudentDebt" label={t.STUDENT_DEBT} disabled={isLocked} />
      <ToggleButtonField
        name="hasFDReasonLitigationIssues"
        label={t.LEGAL_MATTERS}
        disabled={isLocked}
        onClick={handleLegalMattersToggle}
      />
      <ToggleButtonField
        name="hasFDReasonMovingOrRelocationExpenses"
        label={t.MOVING_RELOCATION_EXPENSES}
        disabled={isLocked}
      />
      <ToggleButtonField name="hasFDReasonFailedProposal" label={t.FAILED_PROPOSAL} disabled={isLocked} />
      <ToggleButtonField
        name="hasFDReasonFinancialMismanagement"
        label={t.FINANCIAL_MISMANAGEMENT}
        disabled={isLocked}
      />
      <ToggleButtonField
        name="hasFDReasonOther"
        label={t.OTHER}
        disabled={isLocked}
        onClick={handleReasonOtherToggle}
      />
      {values?.hasFDReasonLitigationIssues && (
        <TextField
          name="specifyFDReasonLegalMatters"
          label={t.LEGAL_MATTERS_PLEASE_SPECIFY}
          multiline
          rows={4}
          required
          maxLength={1000}
          disabled={isLocked}
        />
      )}
      {values?.hasFDReasonOther && (
        <TextField
          name="specifiedFDReason"
          label={t.OTHER_PLEASE_SPECIFY}
          multiline
          rows={4}
          required
          maxLength={1000}
          disabled={isLocked}
        />
      )}
      <TextField
        name="reasonForNeedingFinancialHelp"
        label={t.DESCRIBE_IN_YOUR_OWN_WORDS_WHY_YOU_NEED_FINANCIAL_HELP}
        multiline
        rows={4}
        maxLength={5000}
        disabled={isLocked}
      />
      {/* SPOUSE Financial Difficulty */}
      {values?.isJointFile ? (
        <>
          <FormLabel sx={{ fontWeight: 600, color: theme.palette.primary.main, fontSize: 16 }}>
            <CustomLabel label={t.TITLE_SPOUSE} required />
          </FormLabel>
          <br />
          <FormLabel sx={{ fontWeight: 600, color: theme.palette.primary.main, fontSize: 14 }}>
            <CustomLabel label={t.WHAT_ARE_REASONS_FOR_DIFFICULTY} required />
          </FormLabel>
          <ToggleButtonField name="spouseHasFDReasonLossOfIncome" label={t.LOSS_OF_INCOME} disabled={isLocked} />
          <ToggleButtonField name="spouseHasFDReasonMedicalReasons" label={t.MEDICAL_REASONS} disabled={isLocked} />
          <ToggleButtonField name="spouseHasFDReasonBusinessFailure" label={t.BUSINESS_FAILURE} disabled={isLocked} />
          <ToggleButtonField name="spouseHasFDReasonGambling" label={t.GAMBLING} disabled={isLocked} />
          <ToggleButtonField
            name="spouseHasFDReasonDrugAlcoholAddicition"
            label={t.ADDICTION_OTHER_THAN_GAMBLING}
            disabled={isLocked}
          />
          <ToggleButtonField
            name="spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown"
            label={t.RELATIONSHIP_BREAKDOWN}
            disabled={isLocked}
          />
          <ToggleButtonField name="spouseHasFDReasonTaxLiabilities" label={t.TAX_LIABILITIES} disabled={isLocked} />
          <ToggleButtonField
            name="spouseHasFDReasonFinancialSupportOfOthers"
            label={t.FINANCIAL_SUPPORT_OF_OTHERS}
            disabled={isLocked}
          />
          <ToggleButtonField name="spouseHasFDReasonStudentDebt" label={t.STUDENT_DEBT} disabled={isLocked} />
          <ToggleButtonField
            name="spouseHasFDReasonLitigationIssues"
            label={t.LEGAL_MATTERS}
            disabled={isLocked}
            onClick={handleSpouseLegalMattersToggle}
          />
          <ToggleButtonField
            name="spouseHasFDReasonMovingOrRelocationExpenses"
            label={t.MOVING_RELOCATION_EXPENSES}
            disabled={isLocked}
          />
          <ToggleButtonField name="spouseHasFDReasonFailedProposal" label={t.FAILED_PROPOSAL} disabled={isLocked} />
          <ToggleButtonField
            name="spouseHasFDReasonFinancialMismanagement"
            label={t.FINANCIAL_MISMANAGEMENT}
            disabled={isLocked}
          />
          <ToggleButtonField
            name="spouseHasFDReasonOther"
            label={t.OTHER}
            disabled={isLocked}
            onClick={handleSpouseReasonOtherToggle}
          />
          {values?.spouseHasFDReasonLitigationIssues && (
            <TextField
              name="spouseSpecifyFDReasonLegalMatters"
              label={t.LEGAL_MATTERS_PLEASE_SPECIFY}
              multiline
              rows={4}
              required
              maxLength={1000}
              disabled={isLocked}
            />
          )}
          {values?.spouseHasFDReasonOther && (
            <TextField
              name="spouseSpecifiedFDReason"
              label={t.OTHER_PLEASE_SPECIFY}
              multiline
              rows={4}
              required
              maxLength={1000}
              disabled={isLocked}
            />
          )}
          <TextField
            name="spouseReasonForNeedingFinancialHelp"
            label={t.DESCRIBE_IN_YOUR_OWN_WORDS_WHY_YOU_NEED_FINANCIAL_HELP}
            multiline
            rows={4}
            maxLength={5000}
            disabled={isLocked}
          />
        </>
      ) : null}
    </>
  );
};

export default FinancialDifficulty;
