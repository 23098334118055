import { useContext } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useFormikContext } from 'formik';

import CustomTabPanel, { a11yProps } from '../../../../../../components/CustomTabPanel';
import LabelWithError from '../../../../../../components/LabelWithError';

import { EditAssetModalContext, DETAILS_TAB, DOCUMENTS_TAB, BASIC_TAB } from './EditAssetModalProvider';
import { IAssetFormValues } from './types';

const EditAssetForm = ({
  isMissingDetails = false,
  isMissingDocuments = false,
}: {
  isMissingDetails?: boolean;
  isMissingDocuments?: boolean;
}) => {
  const { tabs, currentTab, goToNextTab, goToPreviousTab } = useContext(EditAssetModalContext);

  const { submitForm, isValid } = useFormikContext<IAssetFormValues>();

  return (
    <>
      <Tabs
        data-testid="edit-asset-tabs"
        value={currentTab}
        onChange={(_, newTab) => {
          if (newTab < currentTab) {
            goToPreviousTab?.();
            setTimeout(() => submitForm(), 100);
          } else {
            if (tabs?.[currentTab]?.key === BASIC_TAB) {
              submitForm().then(() => {
                if (isValid) {
                  goToNextTab?.();
                }
              });
            } else {
              goToNextTab?.();
            }
          }
        }}
        aria-label="edit asset tabs"
        TabScrollButtonProps={{ sx: { position: 'sticky' } }}
      >
        {tabs?.map((item, index) => (
          <Tab
            label={
              <LabelWithError
                label={item.label}
                hasError={
                  (item?.key === DETAILS_TAB && isMissingDetails) || (item?.key === DOCUMENTS_TAB && isMissingDocuments)
                }
              />
            }
            key={`tab-${index}`}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      {tabs?.map((item, index) => (
        <CustomTabPanel value={currentTab} index={index} key={`tab-panel-${index}`}>
          {item.children}
        </CustomTabPanel>
      ))}
    </>
  );
};

export default EditAssetForm;
