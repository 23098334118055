import { Button, Grid } from '@mui/material';

import useLocale from '../app/hooks/useLocale';

interface IFooterProps {
  onCancel?: () => void;
  onNext?: () => void;
  isNextDisabled?: boolean;
  nextButtonText?: string;
  isEditable?: boolean;
}

const Footer = ({ onCancel, onNext, isNextDisabled = false, nextButtonText, isEditable }: IFooterProps) => {
  const { t } = useLocale();

  return (
    <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap">
      <Grid item mobile={isEditable ? 6 : 12} tablet={4}>
        <Button onClick={onCancel} variant="outlined" data-testid="cancel-button" fullWidth>
          {isEditable ? t.CANCEL : t.BACK}
        </Button>
      </Grid>
      {isEditable && (
        <Grid item mobile={6} tablet={4}>
          <Button variant="contained" data-testid="ok-button" fullWidth onClick={onNext} disabled={isNextDisabled}>
            {nextButtonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Footer;
