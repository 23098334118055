import { ITaskActionCountRequestParams, IAppointmentsSearchParams } from './types';

export const argumentifyTaskActionCountRequestParams = ({
  filterText,
  filterSubjectText,
  fileId,
  description,
  followUpDateMin,
  followUpDateMax,
  openDateMin,
  openDateMax,
  closeDateMin,
  closeDateMax,
  assignedAgentId,
  assignedTeamId,
  appTaskId,
  appTaskActionIds,
  appTaskActionId,
  appTaskActionNameId,
  appTaskActionStatusId,
  appTaskActionStatus,
  teamTypes,
  appTaskActionNames,
  calledFromDashboard,
  sorting,
  skipCount,
  maxResultCount,
}: ITaskActionCountRequestParams) =>
  [
    filterText,
    filterSubjectText,
    fileId,
    description,
    followUpDateMin,
    followUpDateMax,
    openDateMin,
    openDateMax,
    closeDateMin,
    closeDateMax,
    assignedAgentId,
    assignedTeamId,
    appTaskId,
    appTaskActionIds,
    appTaskActionId,
    appTaskActionNameId,
    appTaskActionStatusId,
    appTaskActionStatus,
    teamTypes,
    appTaskActionNames,
    calledFromDashboard,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyAppointmentsSearchParams = ({
  isAllAppointments,
  appointmentStatusEnums,
  appointmentTypeEnums,
  startDateTimeBefore,
  startDateTimeAfter,
  sorting,
  skipCount,
  maxResultCount,
}: IAppointmentsSearchParams) =>
  [
    isAllAppointments,
    appointmentStatusEnums,
    appointmentTypeEnums,
    startDateTimeBefore,
    startDateTimeAfter,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;
