import { useField } from 'formik';
import { ToggleButton, Box, useTheme, FormHelperText, ToggleButtonGroup, FormLabel } from '@mui/material';

import CustomLabel from '../CustomLabel';
import RecommendableField from './RecommendableField';

type FieldProps = any;

interface IToggleDataItem {
  value: any;
  label: string;
}

export interface IToggleGroupFieldProps extends FieldProps {
  data?: IToggleDataItem[];
}

const CustomToggleButtonGroup = ({ data, field, form, ...props }: any) => {
  const theme = useTheme();
  const [, meta] = useField(field?.name);
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <FormLabel sx={{ fontWeight: 600, color: theme.palette.primary.main, fontSize: 14 }}>
        <CustomLabel label={props?.label} required={props?.required} recommended={props?.recommended} />
      </FormLabel>
      <ToggleButtonGroup
        {...field}
        {...props}
        name={field?.name}
        value={field?.value}
        selected={field?.value}
        onChange={(e: any, value: any) => {
          form.setFieldValue(field.name, value);
          props?.onChange?.(value);
        }}
        onBlur={() => form?.setFieldTouched(field?.name)}
        fullWidth
      >
        {data?.map((item: IToggleDataItem, index: number) => (
          <ToggleButton value={item?.value} key={index}>
            {item?.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {meta?.touched && !!meta?.error && <FormHelperText error>{meta?.error}</FormHelperText>}
    </Box>
  );
};

const ToggleGroupField = ({ data, ...props }: IToggleGroupFieldProps) => (
  <Box sx={{ mt: 1, mb: 1 }}>
    <RecommendableField
      component={CustomToggleButtonGroup}
      name={props?.name}
      type="checkbox"
      fullWidth
      {...props}
      data={data}
    />
  </Box>
);

export default ToggleGroupField;
