import { useQuery, useMutation } from '@tanstack/react-query';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import {
  ASSETS_GET_QUERY_KEY,
  DEBTS_GET_QUERY_KEY,
  ASSETS_CONFIG_QUERY_KEY,
  ASSET_BY_ID_GET_QUERY_KEY,
  DEBT_BY_ID_GET_QUERY_KEY,
  ASSETS_TYPES_QUERY_KEY,
  ASSETS_DEBTS_OWNERSHIP_TYPES_QUERY_KEY,
  DEBTS_DESCRIPTIONS_QUERY_KEY,
  ASSETS_DESCRIPTIONS_QUERY_KEY,
  APPLICATION_FILE_QUERY_KEY,
  LIST_PREFIXES_FOR_ASSETS,
} from '../../../../app/constants/reactQueryKeys';
import { queryClient } from '../../../../app/config';

import API from '../../../../app/api/api';

export const useListAssetsQuery = (fileId?: string) =>
  useQuery<ClientPublicService.IAppFormAssetsDebtsAssetListItem[]>({
    queryKey: [ASSETS_GET_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.listAppFormAssets(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useListDebtsQuery = (fileId?: string) =>
  useQuery<ClientPublicService.IAppFormAssetsDebtsDebtListItem[]>({
    queryKey: [DEBTS_GET_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.listAppFormDebts(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useAppFormAssetsConfigQuery = (fileId?: string) =>
  useQuery({
    queryKey: [ASSETS_CONFIG_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormAssetConfig(fileId);
      return data;
    },
    enabled: !!fileId,
  });

export const useAssetByIdQuery = (assetId?: string) =>
  useQuery({
    queryKey: [ASSET_BY_ID_GET_QUERY_KEY, assetId],
    queryFn: async () => {
      const data = await API.getAppFormAsset(assetId);
      return data;
    },
    enabled: !!assetId,
  });

export const useAssetByIdQueryMutation = () =>
  useMutation({
    mutationFn: (assetId?: string) => API.getAppFormAsset(assetId),
  });

export const useDebtByIdQuery = (debtId?: string) =>
  useQuery({
    queryKey: [DEBT_BY_ID_GET_QUERY_KEY, debtId],
    queryFn: async () => {
      const data = await API.getAppFormDebt(debtId);
      return data;
    },
    enabled: !!debtId,
  });

export const useAssetsTypesQuery = () =>
  useQuery({
    queryKey: [ASSETS_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAssetTypes();
      return data;
    },
  });

export const useOwnershipTypesQuery = () =>
  useQuery({
    queryKey: [ASSETS_DEBTS_OWNERSHIP_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listAssetDebtOwnershipTypes();
      return data;
    },
  });

export const useAssetsDescriptionsQuery = (assetTypeId?: string) =>
  useQuery({
    queryKey: [ASSETS_DESCRIPTIONS_QUERY_KEY, assetTypeId],
    queryFn: async () => {
      const data = await API.listAssetDescriptions(assetTypeId);
      return data;
    },
    enabled: !!assetTypeId,
  });

export const useDebtsDescriptionsQuery = () =>
  useQuery({
    queryKey: [DEBTS_DESCRIPTIONS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listDebtDescriptions();
      return data;
    },
  });

export const useUpdateAssetMutation = () =>
  useMutation({
    mutationFn: (asset: ClientPublicService.IAppFormAssetsDebtsAssetDto) =>
      API.updateAppFormAsset(asset as ClientPublicService.AppFormAssetsDebtsAssetDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ASSETS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [ASSET_BY_ID_GET_QUERY_KEY] });
    },
  });

export const useCreateAssetMutation = () =>
  useMutation({
    mutationFn: (asset: ClientPublicService.IAppFormAssetsDebtsAssetDto) =>
      API.createAppFormAsset(asset as ClientPublicService.AppFormAssetsDebtsAssetDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [ASSETS_GET_QUERY_KEY] }),
  });

export const useDeleteAssetMutation = () =>
  useMutation({
    mutationFn: (assetId?: string) => API.deleteAppFormAsset(assetId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [ASSETS_GET_QUERY_KEY] }),
  });

export const useUpdateDebtMutation = () =>
  useMutation({
    mutationFn: (debt: ClientPublicService.IAppFormAssetsDebtsDebtDto) =>
      API.updateAppFormDebt(debt as ClientPublicService.AppFormAssetsDebtsDebtDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEBTS_GET_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [DEBT_BY_ID_GET_QUERY_KEY] });
    },
  });

export const useCreateDebtMutation = () =>
  useMutation({
    mutationFn: (debt: ClientPublicService.IAppFormAssetsDebtsDebtDto) =>
      API.createAppFormDebt(debt as ClientPublicService.AppFormAssetsDebtsDebtDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DEBTS_GET_QUERY_KEY] }),
  });

export const useDeleteDebtMutation = () =>
  useMutation({
    mutationFn: (debtId?: string) => API.deleteAppFormDebt(debtId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [DEBTS_GET_QUERY_KEY] }),
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useListPrefixesForAssetsQuery = (assetId?: string) =>
  useQuery({
    queryKey: [LIST_PREFIXES_FOR_ASSETS, assetId],
    queryFn: async () => {
      const data = await API.listPrefixesForAsset(assetId);
      return data;
    },
    enabled: !!assetId,
  });
