import { useMemo, useEffect, useState, useCallback } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { Typography, Divider } from '@mui/material';

import ElevatedContainer from '../../components/ElevatedContainer';
import EditIncomeComponent from '../../components/EditIncomeComponent/EditIncomeComponents';

import useLocale from '../../app/hooks/useLocale';

import { IIncomeSubmitValues } from '../../components/EditIncomeComponent/types';
import { useApplicationFileQuery, useAppFormIncomesQuery, useSaveReportMutation } from './queries';
import { convertIncomeFormItemToSaveDto, getIncomeFormItem, checkIfEditable } from './utils';
import { DOCUMENTS_BY_IDS_RETRIEVED_EVENT } from '../../app/constants/eventBusKeys';
import eventBus from '../../app/utils/eventBus';

import { ROUTES } from '../../app/routes';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditIEReportEditIncome = () => {
  const { submissionPeriodId } = useParams();
  const { t } = useLocale();
  const navigate = useNavigate();

  const [documentsIdsToKeep, setDocumentsIdsToKeep] = useState<string[]>();

  const { report, setPreviousSubmission } = useOutletContext<{
    report: ClientPublicService.IncomeExpenseSubmissionPublicDto;
    setPreviousSubmission?: (previousSubmission?: ClientPublicService.IncomeExpenseSubmissionPublicDto) => void;
  }>();

  const { data: applicationFile } = useApplicationFileQuery() || {};

  const { data: appFormExpenses } = useAppFormIncomesQuery(applicationFile?.id) || {};

  const { mutate: saveReport } = useSaveReportMutation() || {};

  const isEditable = useMemo(() => checkIfEditable(report), [report]);

  const initialValues = useMemo(
    () => ({
      isMarried: appFormExpenses?.isMarried,
      incomeDetails: report?.incomeItems
        ?.map((income) => getIncomeFormItem(income))
        ?.sort((a, b) => (a?.incomeType?.orderNumber || 0) - (b?.incomeType?.orderNumber || 0))
        ?.sort(
          (a, b) =>
            +(a?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other) -
            +(b?.incomeType?.enumValue === ClientPublicService.IncomeTypeEnum.Other)
        ),
      documents: undefined,
    }),
    [appFormExpenses?.isMarried, report?.incomeItems]
  );

  const isMissingDocuments = useMemo(
    () => Boolean(report?.incomeItems?.some((item) => item.isMissingDocuments)),
    [report]
  );

  useEffect(() => {
    if (!documentsIdsToKeep && report) {
      setDocumentsIdsToKeep(report?.documentIds || []);
    }
  }, [documentsIdsToKeep, report, report?.documentIds]);

  const handleDocumentsRetrieved = useCallback((e: any) => {
    const data = e?.detail;

    setDocumentsIdsToKeep(
      data?.providedDocumentsIds?.filter((id: string) => data?.previouslyUploadedIds?.indexOf(id) === -1)
    );
  }, []);

  useEffect(() => {
    eventBus.on(DOCUMENTS_BY_IDS_RETRIEVED_EVENT, handleDocumentsRetrieved);

    return () => {
      eventBus.remove(DOCUMENTS_BY_IDS_RETRIEVED_EVENT, handleDocumentsRetrieved);
    };
  }, [handleDocumentsRetrieved]);

  return (
    <ElevatedContainer sx={{ borderRadius: 1.5, pt: 2, pb: 2, pl: 1.5, pr: 1.5 }}>
      <Typography variant="body2" fontWeight={600}>
        {t.MONTHLY_INCOME}
      </Typography>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <EditIncomeComponent
        isEditable={isEditable}
        documentsUploadProps={{
          isSoftDeleteAllowed: isEditable,
          documentIds: report?.documentIds,
          onlyRetrieveByDocumentIds: true,
        }}
        isMissingDocuments={isMissingDocuments}
        initialValues={initialValues}
        headerProps={{ sx: { position: 'relative' } }}
        footerProps={{ sx: { position: 'relative' } }}
        onCancel={() => navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}`)}
        onSubmit={(updatedValues?: IIncomeSubmitValues) => {
          saveReport?.(
            {
              ...report,
              incomeItems: updatedValues?.incomeDetails?.map((income) => convertIncomeFormItemToSaveDto(income)),
              documentIds: [...(documentsIdsToKeep || []), ...(updatedValues?.documentIds as string[])],
            } as ClientPublicService.IncomeExpenseSubmissionPublicDto,
            {
              onSuccess: () => {
                setPreviousSubmission?.(undefined);
                navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}`);
              },
            }
          );
        }}
      />
    </ElevatedContainer>
  );
};

export default EditIEReportEditIncome;
