import { GeocoderAddressComponent } from './types';

import { STREET_NUMBER, ROUTE, LOCALITY, POSTAL_TOWN, POSTAL_CODE, ADMINISRATIVE_LEVEL_1, COUNTRY } from './constants';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const getLongName = (addressComponents: GeocoderAddressComponent[], prop: string) =>
  addressComponents.find((item) => item.types?.[0] === prop)?.long_name;

const getShortName = (addressComponents: GeocoderAddressComponent[], prop: string) =>
  addressComponents.find((item) => item.types?.[0] === prop)?.short_name;

const getLocation = (
  geocodeResult: google.maps.GeocoderResult,
  provinces?: ClientPublicService.LookupDto[],
  countries?: ClientPublicService.LookupDto[]
) => {
  const addressComponents = geocodeResult?.address_components as GeocoderAddressComponent[];
  const streetNumber = getLongName(addressComponents, STREET_NUMBER);
  const streetName = getLongName(addressComponents, ROUTE);
  const city = getLongName(addressComponents, LOCALITY) || getLongName(addressComponents, POSTAL_TOWN);
  const postalCode = getLongName(addressComponents, POSTAL_CODE);

  const countryCode = getShortName(addressComponents, COUNTRY);
  const countryId = countries?.find((item) => item.code === countryCode)?.id;

  const provinceCode = getShortName(addressComponents, ADMINISRATIVE_LEVEL_1);
  const provinceName = getLongName(addressComponents, ADMINISRATIVE_LEVEL_1);
  const provinceId = provinces?.find((item) => item.code === provinceCode)?.id;

  return {
    streetNumber,
    streetName,
    city,
    postalCode,
    provinceId,
    provinceName,
    countryId,
    address: geocodeResult?.formatted_address,
  };
};

const getShortAddressLine = (streetName?: string, streetNumber?: string) => {
  if (streetName && streetNumber) return `${streetNumber} ${streetName}`;
  return streetName || streetNumber || '';
};

export { getLocation, getShortAddressLine };
