import { useMemo } from 'react';
import { useFormikContext, getIn } from 'formik';

import { ToggleWithInputFields } from '../../../../../components/FormFieldsPresets';
import { DatePickerField } from '../../../../../components/FormFields';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { IFormQuestionnaireValues } from '../types';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const DebtAreaApplicant = () => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormQuestionnaireValues>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  return (
    <>
      <AppFormSectionSubtitle>{t.DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM}</AppFormSectionSubtitle>

      <ToggleWithInputFields
        toggleFieldName="isDebtFromFineOrPenaltyImposedByCourt"
        toggleFieldLabel={t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromFineOrPenaltyImposedByCourtDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromRecognizanceOfBailBond"
        toggleFieldLabel={t.RECOGNIZANCE_OF_BAIL_BOND}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromRecognizanceOfBailBondDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromAlimony"
        toggleFieldLabel={t.ALIMONY}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromAlimonyDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromMaintenanceOfAffiliationOrder"
        toggleFieldLabel={t.MAINTENANCE_OF_AFFILIATION_ORDER}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromMaintenanceOfAffiliationOrderDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromMaintenanceOfSupportOfSeparatedFamily"
        toggleFieldLabel={t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromMaintenanceOfSupportOfSeparatedFamilyDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromFraud"
        toggleFieldLabel={t.FRAUD}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromFraudDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromEmbezzlement"
        toggleFieldLabel={t.EMBEZZLEMENT}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromEmbezzlementDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromMisappropriation"
        toggleFieldLabel={t.MISAPPROPRIATION}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromMisappropriationDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromDefalcationWhileActingInAFiduciaryCapacity"
        toggleFieldLabel={t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromDefalcationWhileActingInAFiduciaryCapacityDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud"
        toggleFieldLabel={t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails"
        disabled={isLocked}
      />

      <ToggleWithInputFields
        toggleFieldName="isDebtFromStudentLoansOutstanding"
        toggleFieldLabel={t.STUDENT_LOANS_OUTSTANDING}
        toggleFieldProps={{ required: true }}
        inputFieldName="debtFromStudentLoansOutstandingDetails"
        disabled={isLocked}
      />

      {Boolean(getIn(values, 'isDebtFromStudentLoansOutstanding')) && (
        <DatePickerField
          name="debtFromStudentLoansOutstandingDateCeasedBeingStudent"
          label={t.DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT}
          disableFuture
          format={DATE_FORMAT}
          disabled={isLocked}
        />
      )}
    </>
  );
};

export default DebtAreaApplicant;
