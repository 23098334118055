import { styled, MenuList, MenuItem, Box } from '@mui/material';
import { Suggestion } from 'react-places-autocomplete';

const AutofillSuggestions = ({
  suggestions,
  getSuggestionItemProps,
}: {
  suggestions?: readonly Suggestion[];
  getSuggestionItemProps: any;
}) => (
  <Box style={{ position: 'relative' }}>
    <StyledAutofillSuggestions>
      {suggestions?.length ? (
        suggestions?.map((suggestion, index) => (
          <MenuItem
            {...getSuggestionItemProps(suggestion)}
            key={`suggestion-${index}`}
            style={{ whiteSpace: 'normal' }}
            role="menuitem"
            aria-label="suggestion"
          >
            {suggestion.description}
          </MenuItem>
        ))
      ) : (
        <li role="menuitem" aria-label="suggestion" />
      )}
    </StyledAutofillSuggestions>
  </Box>
);

export default AutofillSuggestions;

const StyledAutofillSuggestions = styled(MenuList)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: 'white',
  padding: 0,
}));
