import { useContext } from 'react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { TextField, CurrencyField, PercentField } from '../../../../../../../components/FormFields';

import useLocale from '../../../../../../../app/hooks/useLocale';

import { EditAssetModalContext } from '../EditAssetModalProvider';
import { IAssetFormValues } from '../types';

const InvestmentsPoliciesDetails = () => {
  const { t } = useLocale();

  const { assetId, isRetirementFund, isRESP, isTaxableInvestment, isSharedInvestment, isOwnershipInterest, isLocked } =
    useContext(EditAssetModalContext);

  const { submitForm } = useFormikContext<IAssetFormValues>();

  useEffect(() => {
    if (assetId) submitForm();
  }, [assetId, submitForm]);

  return (
    <Box data-testid="investments-policies">
      <TextField
        name="nameOfInstitution"
        label={t.NAME_OF_INSTITUTION}
        data-testid="nameOfInstitution"
        required
        inputProps={{ maxLength: 200 }}
        disabled={isLocked}
      />
      <TextField
        name="policyNumber"
        label={t.POLICY_NUMBER}
        data-testid="policyNumber"
        inputProps={{ maxLength: 100 }}
        disabled={isLocked}
      />

      {isRetirementFund && (
        <CurrencyField
          name="contributionsInLast12Months"
          label={t.CONTRIBUTIONS_IN_LAST_12_MONTHS}
          data-testid="contributionsInLast12Months"
          required
          disabled={isLocked}
        />
      )}

      {isRESP && (
        <CurrencyField
          name="grantToBeRepaid"
          label={t.GRANT_TO_BE_REPAID}
          data-testid="grantToBeRepaid"
          recommended
          disabled={isLocked}
        />
      )}

      {(isTaxableInvestment || isSharedInvestment) && (
        <CurrencyField
          name="estimatedTaxOnDisposition"
          label={t.ESTIMATED_TAX_ON_DISPOSITION}
          data-testid="estimatedTaxOnDisposition"
          disabled={isLocked}
        />
      )}

      {isOwnershipInterest && (
        <PercentField
          name="debtorsOwnershipInterest"
          label={t.DEBTORS_OWNERSHIP_INTEREST}
          data-testid="debtorsOwnershipInterest"
          disabled={isLocked}
        />
      )}
    </Box>
  );
};

export default InvestmentsPoliciesDetails;
