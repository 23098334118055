import { useMemo, useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';

import { SupportDocsUpload } from '../DocumentsUpload';

import useLocale from '../../app/hooks/useLocale';

import { EditIncomeModalContext } from './EditIncomeModalProvider';
import { IIncomeFormValues } from './types';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditIncomeDocumentsTab = ({ ...props }: any) => {
  const { t } = useLocale();
  const { values } = useFormikContext<IIncomeFormValues>();
  const { isEditable } = useContext(EditIncomeModalContext);

  const isIncomeTypeFilled = useCallback(
    (enumValue?: ClientPublicService.IncomeTypeEnum) =>
      Boolean(
        values?.incomeDetails?.find(
          (item) =>
            item?.incomeType?.enumValue === enumValue &&
            ((item?.applicantAmount || 0) > 0 ||
              (item?.spouseAmount || 0) > 0 ||
              (item?.otherHouseholdMemberAmount || 0) > 0)
        )
      ),
    [values?.incomeDetails]
  );

  const documentPrefixCodes = useMemo(() => {
    const codes = [];

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.EmploymentIncome)) {
      codes.push('Income.EmploymentIncome');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.PensionOrAnnuities)) {
      codes.push('Income.PensionOrAnnuities');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.EmploymentInsuranceBenefits)) {
      codes.push('Income.EmploymentInsurance');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.SocialAssistance)) {
      codes.push('Income.SocialAssistance');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.SelfEmploymentIncome)) {
      codes.push('Income.SelfEmploymentIncome');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.ChildTaxBenefit)) {
      codes.push('Income.ChildBenefit.Bank-Statement');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.RentalIncome)) {
      codes.push('Income.RentalIncome');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.GovernmentBenefits)) {
      codes.push('Income.GovernmentBenefits.Bank-Statement');
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.OtherBenefits)) {
      codes.push('Income.OtherBenefits.Bank-Statement');
    }

    return codes;
  }, [isIncomeTypeFilled]);

  const tooltip = useMemo(() => {
    const tooltipArray = [];

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.SelfEmploymentIncome)) {
      tooltipArray.push(t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED);
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.PensionOrAnnuities)) {
      tooltipArray.push(t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED);
    }

    if (isIncomeTypeFilled(ClientPublicService.IncomeTypeEnum.RentalIncome)) {
      tooltipArray.push(t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_FOR_RENTAL_INCOME_IS_REQUIRED);
    }

    return tooltipArray?.length ? tooltipArray : undefined;
  }, [
    isIncomeTypeFilled,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED,
    t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_FOR_RENTAL_INCOME_IS_REQUIRED,
    t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED,
  ]);

  return (
    <SupportDocsUpload
      {...props}
      tooltip={tooltip}
      documentFolderCode="surplus-ie"
      documentPrefixCodes={documentPrefixCodes}
      disabled={!isEditable}
    />
  );
};

export default EditIncomeDocumentsTab;
