import { useMutation, useQuery } from '@tanstack/react-query';

import {
  COUNTRIES_QUERY_KEY,
  DEBTOR_PROFILE_QUERY_KEY,
  EDUCATION_LEVELS_QUERY_KEY,
  EMPLOYMENT_STATUSES_QUERY_KEY,
  INDUSTRIES_QUERY_KEY,
  INSOLVENCY_TYPES_QUERY_KEY,
  MARITAL_STATUSES_QUERY_KEY,
  PERSONAL_INFO_QUERY_KEY,
  PHONE_TYPES_QUERY_KEY,
  PROPOSAL_RESULTS_QUERY_KEY,
  PROVINCES_QUERY_KEY,
  RELATIONSHIPS_QUERY_KEY,
} from '../../../../app/constants/reactQueryKeys';
import { queryClient } from '../../../../app/config';

import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import API from '../../../../app/api/api';

export const useAppFormPersonalInfoQuery = (fileId?: string) =>
  useQuery({
    queryKey: [PERSONAL_INFO_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormPersonalInfo(fileId);

      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useUpdatePersonalInfoMutation = () =>
  useMutation({
    mutationFn: (personalInfo: ClientPublicService.IAppFormPersonalInfoDto) =>
      API.updateAppFormPersonalInfo(personalInfo as ClientPublicService.AppFormPersonalInfoDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [PERSONAL_INFO_QUERY_KEY] }),
  });

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_PROFILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useMaritalStatusesQuery = () =>
  useQuery({
    queryKey: [MARITAL_STATUSES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listMaritalStatuses();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useEducationLevelsQuery = () =>
  useQuery({
    queryKey: [EDUCATION_LEVELS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listEducationLevels();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useIndustriesQuery = () =>
  useQuery({
    queryKey: [INDUSTRIES_QUERY_KEY],
    queryFn: async () => {
      return await API.listIndustries('');
    },
    refetchOnWindowFocus: false,
  });

export const useEmploymentStatusesQuery = () =>
  useQuery({
    queryKey: [EMPLOYMENT_STATUSES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listEmploymentStatuses();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useCountriesQuery = () =>
  useQuery({
    queryKey: [COUNTRIES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listCountries();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useProvincesQuery = (countryId?: string) =>
  useQuery({
    queryKey: [PROVINCES_QUERY_KEY, countryId],
    queryFn: async () => {
      const data = await API.listProvinces(countryId);

      return data;
    },
    enabled: !!countryId,
    refetchOnWindowFocus: false,
  });

export const usePhoneTypesQuery = () =>
  useQuery({
    queryKey: [PHONE_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listPhoneTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useRelationshipsQuery = () =>
  useQuery({
    queryKey: [RELATIONSHIPS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listRelationshipTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useInsolvencyTypesQuery = () =>
  useQuery({
    queryKey: [INSOLVENCY_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listInsolvencyTypes();

      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useProposalResultsQuery = () =>
  useQuery({
    queryKey: [PROPOSAL_RESULTS_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listProposalResults();

      return data;
    },
  });
