const localStorageUtils = {
  get: <V>(path: string): V | null => {
    const value = localStorage.getItem(path);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  },
  set: <V>(path: string, value: V) => {
    localStorage.setItem(path, JSON.stringify(value));
  },
};

export default localStorageUtils;
