import {
  IAppointmentsSearchParams,
  ISupportingDocumentsSearchParams,
  IDocumentsSearchParams,
  IPaymentsCountSearchParams,
  IPaymentsSearchParams,
  IIEReportsSearchParams,
} from './types';

export const argumentifyAppointmentsSearchParams = ({
  isAllAppointments,
  appointmentStatusEnums,
  appointmentTypeEnums,
  startDateTimeBefore,
  startDateTimeAfter,
  sorting,
  skipCount,
  maxResultCount,
}: IAppointmentsSearchParams) =>
  [
    isAllAppointments,
    appointmentStatusEnums,
    appointmentTypeEnums,
    startDateTimeBefore,
    startDateTimeAfter,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifySupportingDocumentsSearchParams = ({
  supportingDocumentStatus,
  sorting,
  skipCount,
  maxResultCount,
}: ISupportingDocumentsSearchParams) => [supportingDocumentStatus, sorting, skipCount, maxResultCount] as const;

export const argumentifyDocumentsGetParams = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentsSearchParams) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyCountPaymentsSearchParams = ({
  receiptDateFrom,
  receiptDateTo,
  isDeposited,
  sorting,
  skipCount,
  maxResultCount,
}: IPaymentsCountSearchParams) =>
  [receiptDateFrom, receiptDateTo, isDeposited, sorting, skipCount, maxResultCount] as const;

export const argumentifyPaymentsSearchParams = ({
  fileId,
  receiptDateFrom,
  receiptDateTo,
  isDeposited,
  sorting,
  skipCount,
  maxResultCount,
}: IPaymentsSearchParams) =>
  [fileId, receiptDateFrom, receiptDateTo, isDeposited, sorting, skipCount, maxResultCount] as const;

const argumentifyIEReportsSearchParams = ({
  dueDate,
  dueDateStart,
  dueDateEnd,
  statuses,
  sorting,
  skipCount,
  maxResultCount,
}: IIEReportsSearchParams) =>
  [dueDate, dueDateStart, dueDateEnd, statuses, sorting, skipCount, maxResultCount] as const;

export { argumentifyIEReportsSearchParams };
