import { styled, Box } from '@mui/material';

interface IProps {
  src: string;
  alt: string;
}

const CustomMenuIcon = ({ alt, src }: IProps) => (
  <MenuItemContainer>
    <img alt={alt} src={src} width={16} />
  </MenuItemContainer>
);

export default CustomMenuIcon;

const MenuItemContainer = styled(Box)`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
