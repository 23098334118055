import { Stack, useTheme } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

interface ILabelWithErrorProps {
  label?: string | React.ReactElement;
  hasError?: boolean;
}

const LabelWithError = ({ label, hasError = true }: ILabelWithErrorProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={1} data-testid="label-with-error">
      {hasError && <ErrorIcon sx={{ color: theme.palette.urgentRed }} />}
      <div>{label}</div>
    </Stack>
  );
};

export default LabelWithError;
