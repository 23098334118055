import { useMemo } from 'react';
import { useField, FastField, Field as RegularField, getIn } from 'formik';
import { useTheme, FormHelperText } from '@mui/material';

type IFieldProps = any;

export interface IRecommendableFieldProp extends IFieldProps {
  recommended?: boolean;
  recommendedWarningText?: string;
  isFastField?: boolean;
}

const CustomFastField = (props: any) => {
  const shouldComponentUpdate = (nextProps: any, currentProps: any) => {
    return (
      nextProps.label !== currentProps.label ||
      nextProps.name !== currentProps.name ||
      nextProps.required !== currentProps.required ||
      nextProps.disabled !== currentProps.disabled ||
      nextProps.readOnly !== currentProps.readOnly ||
      nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
      Object.keys(nextProps).length !== Object.keys(currentProps).length ||
      getIn(nextProps.formik.values, currentProps.name) !== getIn(currentProps.formik.values, currentProps.name) ||
      getIn(nextProps.formik.errors, currentProps.name) !== getIn(currentProps.formik.errors, currentProps.name) ||
      getIn(nextProps.formik.touched, currentProps.name) !== getIn(currentProps.formik.touched, currentProps.name)
    );
  };
  return <FastField {...props} shouldUpdate={shouldComponentUpdate} />;
};

const RecommendableField = ({
  recommended,
  recommendedWarningText,
  sx: recommendableFieldsx,
  isFastField = true,
  ...props
}: IRecommendableFieldProp) => {
  const [, meta] = useField(props?.name);
  const theme = useTheme();

  const isRecommendedError = useMemo(
    () => meta?.error && meta?.touched && meta?.error?.includes('[recommended]'),
    [meta?.error, meta?.touched]
  );

  const Field = useMemo(() => (isFastField ? CustomFastField : RegularField), [isFastField]);

  return (
    <>
      <Field
        sx={{
          width: '100%',
          ...(isRecommendedError
            ? {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${
                    props?.disabled ? theme.palette.disabledGray : theme.palette.warningOrange
                  } !important`,
                },
                '.MuiFormHelperText-root.Mui-error': {
                  display: 'none !important',
                },
                '.Mui-error': {
                  color: `${theme.palette.warningOrange} !important`,
                },
              }
            : {}),
          ...recommendableFieldsx,
        }}
        {...props}
      />
      {isRecommendedError && (
        <FormHelperText
          style={{
            marginTop: -3,
            marginLeft: 14,
            color: theme.palette.warningOrange,
          }}
        >
          {meta?.error?.replace('[recommended]', '')}
        </FormHelperText>
      )}
    </>
  );
};

export default RecommendableField;
