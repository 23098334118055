import ElevatedContainer from '../../../components/ElevatedContainer';
import DataItem, { IDataItemProps } from '../../../components/DataItem';

export type IProfileDataItemProps = IDataItemProps;

const ProfileDataItem = (props: IProfileDataItemProps) => {
  return (
    <ElevatedContainer sx={{ borderRadius: 3, pt: 0, pb: 0 }}>
      <DataItem {...props} />
    </ElevatedContainer>
  );
};

export default ProfileDataItem;
