import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    slate: string;
    white: string;
    oceanBlue: string;
    successGreen: string;
    chipSuccess: string;
    chipError: string;
    subtitleGrey: string;
    tableHeader: string;
    siteBlue: string;
    red: string;
    grayFooter: string;
    paleCharcoal: string;
    urgentRed: string;
    disabledGray: string;
    bgGrey: string;
    lightBlue: string;
    warningOrange: string;
    inputOutline: string;
    secondLevelMenuBckg: string;
    siteBlueDarker: string;
    cobalt: string;
    chartGrey: string;
    blueBar: string;
  }

  interface PaletteOptions {
    slate: string;
    white: string;
    oceanBlue: string;
    successGreen: string;
    chipSuccess: string;
    chipError: string;
    subtitleGrey: string;
    tableHeader: string;
    siteBlue: string;
    red: string;
    grayFooter: string;
    paleCharcoal: string;
    urgentRed: string;
    disabledGray: string;
    bgGrey: string;
    lightBlue: string;
    warningOrange: string;
    inputOutline: string;
    secondLevelMenuBckg: string;
    siteBlueDarker: string;
    cobalt: string;
    chartGrey: string;
    blueBar: string;
  }
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const breakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 993,
};

const colors = {
  slate: '#5b6e7f',
  white: '#FFFFFF',
  oceanBlue: '#15A4DE',
  successGreen: '#009966',
  chipError: '#E81A3B20',
  chipSuccess: '#54A57550',
  subtitleGrey: '#6F6F6F',
  tableHeader: '#5C5C5C',
  siteBlue: '#1A7D99',
  red: '#E81A3B',
  main: '#333333',
  gray: '#6F6F6F',
  grayBackground: '#F4F4F4',
  grayFooter: '#505050',
  paleCharcoal: '#E7E7E7',
  urgentRed: '#AD1931',
  disabledGray: '#BFBFBF',
  bgGrey: '#F1F1F3',
  lightBlue: '#F3FAFD',
  warningOrange: '#FD7E14',
  inputOutline: '#0000003b',
  secondLevelMenuBckg: '#F7F6F7',
  siteBlueDarker: '#166880',
  cobalt: '#0062B8',
  chartGrey: '#9DA8B2',
  blueBar: 'rgba(0, 98, 184, 0.5)',
};

const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary: {
      main: colors.main,
    },
    secondary: {
      main: colors.gray,
    },
    error: {
      main: colors.red,
    },
    background: {
      default: colors.grayBackground,
      paper: colors.white,
    },
    ...colors,
  },
  typography: {
    fontFamily: ['ProximaNova', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 32,
      fontWeight: 800,
    },
    h2: {
      fontSize: 28,
      fontWeight: 800,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h6: {
      fontSize: 20,
      fontWeight: 600,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    body3: {
      fontSize: 14,
    },
    body4: {
      fontSize: 12,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: ({ theme }) => ({
          backgroundColor: theme.palette.red,
          color: theme.palette.white,
          textTransform: 'initial',
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: theme.palette.red,
          },
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 0,
        }),
        outlined: ({ theme }) => ({
          borderColor: theme.palette.red,
          color: theme.palette.red,
          backgroundColor: theme.palette.white,
          textTransform: 'initial',
          '&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)': {
            borderColor: theme.palette.red,
            backgroundColor: theme.palette.red,
            color: theme.palette.white,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.disabledGray,
          },
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 0,
        }),
        text: ({ theme }) => ({
          color: theme.palette.red,
          textTransform: 'initial',
          '&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)': {
            color: theme.palette.red,
          },
          fontSize: 18,
          fontWeight: 600,
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: ({ theme }) => ({
          fontSize: 16,
          [theme.breakpoints.down('desktop')]: {
            fontSize: 18,
          },
        }),
        secondary: ({ theme }) => ({
          fontSize: 14,
          [theme.breakpoints.down('desktop')]: {
            fontSize: 16,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&.Mui-checked:not(.Mui-disabled)': {
            color: theme.palette.error.main,
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&.MuiLink-root': {
            color: theme.palette.cobalt,
            textDecoration: 'none',
          },
          '&.MuiDayCalendar-weekDayLabel': {
            fontSize: 16,
            width: 40,
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiPickersDay-root': {
            fontSize: 16,
            width: 40,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.disabledGray,
              color: theme.palette.white,
            },
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              backgroundColor: theme.palette.red,
              color: theme.palette.white,
            },
          },
          '&.MuiPickersDay-root:not(.Mui-selected)': {
            borderColor: theme.palette.cobalt,
          },
          '&.MuiCheckbox-root': {
            width: 'auto',
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        asterisk: {
          color: colors.red,
          '&$error': {
            color: colors.red,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          paddingTop: 32,
          paddingBottom: 32,
          margin: 16,
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.h2,
          textAlign: 'center',
          paddingLeft: 8,
          paddingRight: 8,
          lineHeight: 1.1,
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.h6,
          textAlign: 'center',
          paddingLeft: 8,
          paddingRight: 8,
          lineHeight: 1.2,
          color: theme.palette.secondary.main,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 24,
          '& .MuiTab-root': {
            color: theme.palette.disabledGray,
            textTransform: 'none',
            fontSize: 16,
            padding: 8,
            minHeight: 24,
            minWidth: 0,
            '&.Mui-selected': {
              color: theme.palette.primary.main,
            },
          },

          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.error.main,
          },
        }),
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.error.main,
        }),
        flexContainer: ({ theme }) => ({
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.bgGrey,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: 16,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 10px 0px rgba(208, 213, 221, 0.20);',
          borderRadius: 12,
          marginBottom: 12,
          '&:last-of-type': {
            borderRadius: 12,
          },
          '&: first-of-type': {
            borderRadius: 12,
          },
          '&:before': {
            background: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
        root: ({ theme }) => ({
          minHeight: 0,
          padding: 16,
          '&.Mui-expanded': {
            minHeight: 0,
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          textTransform: 'initial',
          padding: '6px 10px',
          borderColor: theme.palette.slate,
          '&.Mui-selected, &.Mui-selected:hover:not(.Mui-disabled)': {
            backgroundColor: theme.palette.red,
            color: theme.palette.white,
          },
          '&.Mui-selected.Mui-disabled': {
            borderColor: theme.palette.disabledGray,
            backgroundColor: theme.palette.disabledGray,
            color: theme.palette.white,
          },

          '&.Mui-disabled:not(.Mui-selected)': {
            borderColor: theme.palette.disabledGray,
            color: theme.palette.secondary.main,
          },
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 4,
        }),
      },
    },
  },
});

export default theme;
