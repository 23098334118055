import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

import { IAssetFormValues } from './types';
import { IOwnershipType } from '../../types';

import { inputDateToServerFormat } from '../../../../../../app/utils/dateTimeHelpers';

const getInitialAssetValue = (
  data?: ClientPublicService.AppFormAssetsDebtsAssetDto,
  config?: ClientPublicService.AppFormAssetsDebtsAssetConfig,
  ownershipTypes?: IOwnershipType[]
) =>
  ({
    id: data?.id || undefined,
    assetType: data?.assetType || '',
    assetDescription: data?.assetDescription || '',
    assetDescriptionName: data?.assetDescriptionName || '',
    assetTypeId: data?.assetTypeId || '',
    assetDescriptionId: data?.assetDescriptionId || '',
    specifiedDescription: data?.specifiedDescription || '',
    ownershipTypeId:
      data?.ownershipTypeId ||
      ownershipTypes?.find((item) => item.enumValue === ClientPublicService.AssetDebtOwnershipTypeEnum.Applicant)?.id ||
      '',
    value: data?.value,
    isExempt: data?.isExempt || false,
    comments: data?.comments || '',
    address: data?.address || '',
    unit: data?.unit || '',
    streetNumber: data?.streetNumber || '',
    streetName: data?.streetName || '',
    city: data?.city || '',
    postalCode: data?.postalCode || '',
    provinceId: data?.provinceId || '',
    countryId: data?.countryId || '',
    applicantOwnershipPercentage: data?.id ? data?.applicantOwnershipPercentage : config?.isJointFile ? 50 : 100,
    spouseOwnershipPercentage: data?.id ? data?.spouseOwnershipPercentage : config?.isJointFile ? 50 : 0,
    otherOwnershipPercentage:
      data?.id && data?.otherOwnershipPercentage
        ? data?.otherOwnershipPercentage
        : data?.applicantOwnershipPercentage && data?.spouseOwnershipPercentage
        ? 100 - data?.applicantOwnershipPercentage - data?.spouseOwnershipPercentage
        : 0,
    totalNumberOfOwners: data?.totalNumberOfOwners || 1,
    isPrincipalResidence: data?.isPrincipalResidence || !Boolean(config?.hasPrincipalResidence),
    documentIds: data?.documentIds || [],
    year: data?.year,
    basisOfValue: data?.basisOfValue || '',
    isOwnershipHeldInJointTenancy: data?.isOwnershipHeldInJointTenancy || false,
    nameOfInstitution: data?.nameOfInstitution || '',
    policyNumber: data?.policyNumber || '',
    contributionsInLast12Months: data?.contributionsInLast12Months,
    grantToBeRepaid: data?.grantToBeRepaid,
    estimatedTaxOnDisposition: data?.estimatedTaxOnDisposition,
    debtorsOwnershipInterest: data?.debtorsOwnershipInterest || 100,
    make: data?.make || '',
    model: data?.model || '',
    trim: data?.trim || '',
    style: data?.style || '',
    kilometers: data?.kilometers,
    vin: data?.vin || '',
    isVehicleNeededForEmployment: data?.isVehicleNeededForEmployment || false,
    securedToIds: data?.securedToDebts?.map((item) => item?.id) || [],
    securedToDebts: data?.securedToDebts || [],
  } as IAssetFormValues);

const convertSecuredFormValueToDto = (values?: ClientPublicService.AppFormAssetsDebtsDebtListItem) => ({
  id: values?.id || undefined,
  debtDescription: values?.debtDescription || undefined,
  creditorName: values?.creditorName || undefined,
  amount: values?.amount || undefined,
  isSecured: values?.isSecured || false,
  displayName: values?.displayName || undefined,
  isMissingDetails: values?.isMissingDetails || false,
});

const convertFormValueToAssetDto = (values: IAssetFormValues) =>
  ({
    id: values.id || undefined,
    assetType: values.assetType || undefined,
    assetDescription: values.assetDescription || undefined,
    assetDescriptionName: values.assetDescriptionName || undefined,
    assetTypeId: values.assetTypeId || undefined,
    assetDescriptionId: values.assetDescriptionId || undefined,
    specifiedDescription: values.specifiedDescription || undefined,
    value: values.value || undefined,
    ownershipTypeId: values.ownershipTypeId || undefined,
    comments: values.comments || undefined,
    isExempt: values.isExempt || false,
    securedToDebts: values.securedToDebts?.map((item) => convertSecuredFormValueToDto(item)) || [],
    basisOfValue: values.basisOfValue || undefined,
    address: values.address || undefined,
    streetNumber: values.streetNumber || undefined,
    streetName: values.streetName || undefined,
    unit: values.unit || undefined,
    city: values.city || undefined,
    postalCode: values.postalCode || undefined,
    provinceId: values.provinceId || undefined,
    countryId: values.countryId || undefined,
    applicantOwnershipPercentage: values.applicantOwnershipPercentage || undefined,
    spouseOwnershipPercentage: values.spouseOwnershipPercentage || undefined,
    otherOwnershipPercentage: values.otherOwnershipPercentage || undefined,
    totalNumberOfOwners: values.totalNumberOfOwners || undefined,
    isOwnershipHeldInJointTenancy: values.isOwnershipHeldInJointTenancy || undefined,
    isPrincipalResidence: values.isPrincipalResidence || undefined,
    nameOfInstitution: values.nameOfInstitution || undefined,
    policyNumber: values.policyNumber || undefined,
    contributionsInLast12Months: values.contributionsInLast12Months || undefined,
    grantToBeRepaid: values.grantToBeRepaid || undefined,
    estimatedTaxOnDisposition: values.estimatedTaxOnDisposition || undefined,
    debtorsOwnershipInterest: values.debtorsOwnershipInterest || undefined,
    year: inputDateToServerFormat(values.year) || undefined,
    make: values.make || undefined,
    model: values.model || undefined,
    trim: values.trim || undefined,
    style: values.style || undefined,
    kilometers: values.kilometers || undefined,
    vin: values.vin || undefined,
    isVehicleNeededForEmployment: values.isVehicleNeededForEmployment || false,
    documentIds: values.documentIds || undefined,
  } as ClientPublicService.IAppFormAssetsDebtsAssetDto);

export { getInitialAssetValue, convertFormValueToAssetDto };
