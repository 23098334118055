import { IOwnershipType } from '../../types';

import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

const getInitialDebtValues = (
  debt?: ClientPublicService.AppFormAssetsDebtsDebtDto,
  appFile?: ClientPublicService.ApplicationFileDto,
  ownershipTypes?: IOwnershipType[]
) => ({
  id: debt?.id,
  fileId: debt?.fileId || appFile?.id,
  debtDescriptionId: debt?.debtDescriptionId || '',
  creditorName: debt?.creditorName || '',
  amount: debt?.amount,
  ownershipTypeId:
    debt?.ownershipTypeId ||
    ownershipTypes?.find((item) => item.enumValue === ClientPublicService.AssetDebtOwnershipTypeEnum.Applicant)?.id ||
    '',
  accountNumber: debt?.accountNumber || '',
  comments: debt?.comments || '',
  isBusinessDebt: debt?.isBusinessDebt || false,
  creditorAddress: debt?.creditorAddress || '',
  creditorUnit: debt?.creditorUnit || '',
  creditorCity: debt?.creditorCity || '',
  creditorPostalCode: debt?.creditorPostalCode || '',
  creditorProvinceCode: debt?.creditorProvinceCode || '',
  creditorCountryCode: debt?.creditorCountryCode || '',
  creditorEmailAddress: debt?.creditorEmailAddress || '',
  isUniqueCreditor: debt?.isUniqueCreditor || false,
  externalCreditorNameId: debt?.externalCreditorNameId || '',
  creditorTypeId: debt?.creditorTypeId || '',
  unsecuredAmount: debt?.unsecuredAmount,
  isIncludeInProposal: debt?.isIncludeInProposal || false,
  isCreditorNotParticipatingInProposal: debt?.isCreditorNotParticipatingInProposal || false,
  reasonForPreferredId: debt?.reasonForPreferredId || '',
  ltpCodeId: debt?.ltpCodeId || '',
  isPotentiallyStatuteBarred: debt?.isPotentiallyStatuteBarred || false,
  securedByAssetIds: debt?.securedByAssetIds || [],
});

export { getInitialDebtValues };
