import React, { useMemo, useState, Fragment, useCallback } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
  Box,
  Typography,
  FormControlLabel,
  Divider,
  useTheme,
  styled,
} from '@mui/material';
import { FilterList, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import CustomPagination, { ICustomPaginationProps } from './CustomPagination';

import useLocale from '../app/hooks/useLocale';

const DEFAULT_PAGE_SIZE = 10;

interface IDataItemProps {
  [key: string]: any;
}

interface IFilterItemProps {
  text: string;
  value: any;
}

export interface IColumnType {
  title?: string;
  dataIndex: string;
  key: string;
  render?: (value?: any, record?: any) => string | undefined | React.ReactElement;
  sorter?: (a: any, b: any) => number;
  sortable?: boolean;
  filters?: IFilterItemProps[] | true;
  onFilter?: (value: any, record?: any) => boolean;
  width?: string | number;
}

export interface ICustomTableProps {
  data?: IDataItemProps[];
  columns?: IColumnType[];
  striped?: boolean;
  emptyValue?: string;
  onRowClick?: (record: IDataItemProps) => void;
  onColumnSort?: (column: string, sortDirection: SortType) => void;
  paginationProps?: Partial<ICustomPaginationProps>;
}

type SortType = 'asc' | 'desc';

const CustomTable: React.FC<ICustomTableProps> = ({
  data = [],
  columns = [],
  striped,
  emptyValue,
  onRowClick,
  onColumnSort,
  paginationProps,
}) => {
  const { t } = useLocale();
  const theme = useTheme();
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortType>('asc');

  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: { [value: string]: boolean } }>({});

  const [page, setPage] = useState(paginationProps?.page || 0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSort = useCallback(
    (column: string, sortDirection: SortType) => {
      setSortColumn(column);
      setSortDirection(sortDirection);

      onColumnSort?.(column, sortDirection);
    },
    [onColumnSort]
  );

  const handleFilterClick = useCallback((event: React.MouseEvent<HTMLElement>, column: string) => {
    setFilterColumn(column);
    setAnchorEl(event?.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangePage = useCallback(
    (_: any, newPage: number) => {
      setPage(newPage);

      paginationProps?.onPageChange?.(_, newPage);
    },
    [paginationProps]
  );

  const handleFilterSelection = useCallback(
    (value: string) => {
      if (!filterColumn) return;

      const updatedFilters = { ...activeFilters };
      const filterValues = updatedFilters[filterColumn] || {};

      if (filterValues[value]) {
        delete filterValues[value];
      } else {
        filterValues[value] = true;
      }

      updatedFilters[filterColumn] = filterValues;
      setActiveFilters(updatedFilters);
    },
    [activeFilters, filterColumn]
  );

  const isFilterSelected = useCallback(
    (column: string, value: string) => {
      return activeFilters[column]?.[value];
    },
    [activeFilters]
  );

  const handleResetFilters = useCallback(() => {
    if (!filterColumn) return;

    const updatedFilters = { ...activeFilters };
    delete updatedFilters[filterColumn];

    setActiveFilters(updatedFilters);
    handleFilterClose();
  }, [activeFilters, filterColumn, handleFilterClose]);

  const sortedData = useMemo(
    () =>
      sortColumn
        ? [...data].sort((a, b) => {
            const sorter = columns?.find((item) => item?.dataIndex === sortColumn)?.sorter;

            if (sorter) {
              if (sorter(a, b) < 0) {
                return sortDirection === 'asc' ? -1 : 1;
              }
              if (sorter(a, b) > 0) {
                return sortDirection === 'asc' ? 1 : -1;
              }
              return 0;
            }

            if (a[sortColumn] < b[sortColumn]) {
              return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortColumn] > b[sortColumn]) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
          })
        : data,
    [columns, data, sortColumn, sortDirection]
  );

  const filteredData = useMemo(
    () =>
      sortedData?.filter((record) => {
        for (const column in activeFilters) {
          const onFilter = columns?.find((item) => item.dataIndex === column)?.onFilter;

          if (onFilter) {
            const selectedValues = Object.keys(activeFilters[column]);
            if (selectedValues?.find((value) => !onFilter(value, record))) {
              return false;
            }
          } else {
            if (activeFilters[column] && !activeFilters[column][record[column] as string]) {
              return false;
            }
          }
        }
        return true;
      }),
    [sortedData, activeFilters, columns]
  );

  const displayedData = useMemo(() => {
    const rowsPerPage = paginationProps?.rowsPerPage || DEFAULT_PAGE_SIZE;
    return paginationProps?.page
      ? filteredData
      : filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, page, paginationProps?.page, paginationProps?.rowsPerPage]);

  const columnFilters = useMemo(() => {
    if (!filterColumn) return [];

    const filters = columns?.find((item) => item?.dataIndex === filterColumn)?.filters;
    const isDefaultFilters = filters === true;

    const uniqueValues = isDefaultFilters
      ? [...new Set(data?.map((item) => item[filterColumn]))]
          ?.filter((i?: string) => i && Boolean(String(i)?.trim()))
          ?.map((unique: string) => ({
            text: unique,
            value: unique,
          }))
      : filters;

    return uniqueValues;
  }, [columns, data, filterColumn]);

  const noData = useMemo(() => !displayedData || displayedData?.length === 0, [displayedData]);

  const renderHeaderCell = ({ dataIndex, title, sortable = true, filters }: IColumnType) => {
    const isSorting = sortColumn === dataIndex;
    const isFiltered = Object.prototype.hasOwnProperty.call(activeFilters, dataIndex);

    return (
      <TableCell key={dataIndex} data-testid={`'header-row-${dataIndex}`} style={{ height: 48 }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body3" fontWeight={600} sx={{ color: theme.palette.tableHeader }}>
            {title}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            {sortable && (
              <Box display="flex" flexDirection="column" alignItems="start">
                <IconButton
                  disableRipple
                  edge={false}
                  style={{ padding: 0 }}
                  onClick={() => handleSort(dataIndex, 'asc')}
                  data-testid={`asc-sort-button-${dataIndex}`}
                  aria-label="Sort Ascending"
                >
                  <ArrowDropUp
                    fontSize="medium"
                    viewBox="0 -8 24 24"
                    style={{
                      color:
                        isSorting && sortDirection === 'asc' ? theme.palette.error.main : theme.palette.disabledGray,
                    }}
                  />
                </IconButton>
                <IconButton
                  disableRipple
                  edge={false}
                  style={{ padding: 0 }}
                  onClick={() => handleSort(dataIndex, 'desc')}
                  data-testid={`desc-sort-button-${dataIndex}`}
                  aria-label="Sort Descending"
                >
                  <ArrowDropDown
                    fontSize="medium"
                    viewBox="0 8 24 24"
                    style={{
                      color:
                        isSorting && sortDirection === 'desc' ? theme.palette.error.main : theme.palette.disabledGray,
                    }}
                  />
                </IconButton>
              </Box>
            )}
            {filters && (
              <IconButton
                disableRipple
                edge={false}
                style={{ padding: 0 }}
                onClick={(event: React.MouseEvent<HTMLElement>) => handleFilterClick(event, dataIndex)}
                data-testid={`filter-button-${dataIndex}`}
                aria-label="Filter"
              >
                <FilterList
                  fontSize="small"
                  style={{ color: isFiltered ? theme.palette.error.main : theme.palette.slate }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <Fragment key={`header-${index}`}>{renderHeaderCell(column)}</Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!noData ? (
            displayedData?.map((item, index) => (
              <StyledTableRow
                key={`data-row-${index}`}
                striped={striped ? 1 : 0}
                data-testid={`data-row-${index}`}
                onClick={() => onRowClick?.(item)}
                sx={{ cursor: onRowClick ? 'pointer' : 'default' }}
              >
                {columns?.map((column) => (
                  <TableCell
                    key={`cell-${column?.key}-${index}`}
                    data-testid={`cell-${column?.key}-${index}`}
                    style={{ width: column?.width }}
                  >
                    {column?.render ? column?.render(item?.[column?.dataIndex], item) : item?.[column?.dataIndex]}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <tr>
              {columns?.map((column, index) => (
                <td key={`cell-${column?.key}-${index}`}></td>
              ))}
            </tr>
          )}
        </TableBody>
      </Table>
      {!noData && (
        <CustomPagination
          count={paginationProps?.count || filteredData?.length}
          rowsPerPage={paginationProps?.rowsPerPage || DEFAULT_PAGE_SIZE}
          page={page}
          onPageChange={handleChangePage}
        />
      )}

      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFilterClose}>
        {filterColumn &&
          columnFilters?.map((filter: IFilterItemProps, index) => (
            <MenuItem key={index} onClick={() => handleFilterSelection(filter?.value)}>
              <FormControlLabel
                control={<Checkbox checked={isFilterSelected(filterColumn, filter?.value) || false} size="small" />}
                label={String(filter?.text)}
              />
            </MenuItem>
          ))}
        <Divider />
        <MenuItem onClick={handleResetFilters}>
          <ListItemText primary="Reset Filters" style={{ color: theme.palette.cobalt, textAlign: 'center' }} />
        </MenuItem>
      </StyledMenu>

      {noData && (
        <Typography variant="body2" fontWeight={600} color="secondary" textAlign="center" sx={{ pt: 2, pb: 2 }}>
          {emptyValue || t.NO_DATA}
        </Typography>
      )}
    </TableContainer>
  );
};

export default CustomTable;

const TableContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  marginTop: 16,

  '& .MuiTableCell-root': {
    borderBottomStyle: 'dashed',
  },
  '& th.MuiTableCell-root': {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const StyledMenu = styled(Menu)(() => ({
  '& .MuiCheckbox-root': {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const StyledTableRow = styled(TableRow)<{ striped?: number }>(({ theme, striped }) => ({
  '&:nth-of-type(2n -1)': {
    backgroundColor: striped ? theme.palette.background.default : 'white',
  },
}));
