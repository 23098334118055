import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_INGESTION_ENDPOINT}`,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    isBrowserLinkTrackingEnabled: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    disableFetchTracking: false,
  },
});

export default appInsights;
