import { useQuery } from '@tanstack/react-query';

import { PENDING_IE_REPORTS_QUERY_KEY, COMPLETED_IE_REPORTS_QUERY_KEY } from '../../app/constants/reactQueryKeys';

import { IIEReportsSearchParams } from './types';
import { argumentifyIEReportsSearchParams } from './utils';

import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';

export const usePendingIEReportsQuery = (requestParams?: IIEReportsSearchParams) => {
  const params = argumentifyIEReportsSearchParams({
    ...requestParams,
    statuses: [
      ClientPublicService.IncomeExpensePeriodStatusEnum.Pending,
      ClientPublicService.IncomeExpensePeriodStatusEnum.MissingInformation,
      ClientPublicService.IncomeExpensePeriodStatusEnum.DiscrepancyNotice,
      ClientPublicService.IncomeExpensePeriodStatusEnum.AmendmentRequired,
    ],
    maxResultCount: 1000,
    sorting: 'dueDate asc' || requestParams?.sorting,
  });
  return useQuery({
    queryKey: [PENDING_IE_REPORTS_QUERY_KEY, ...params],
    queryFn: async () => {
      const data = await API.schedulesAll(...params);
      return data;
    },
  });
};

export const useCompletedIEReportsQuery = (requestParams?: IIEReportsSearchParams) => {
  const params = argumentifyIEReportsSearchParams({
    ...requestParams,
    statuses: [
      ClientPublicService.IncomeExpensePeriodStatusEnum.Received,
      ClientPublicService.IncomeExpensePeriodStatusEnum.Completed,
    ],
    maxResultCount: 1000,
    sorting: 'dueDate desc' || requestParams?.sorting,
  });
  return useQuery({
    queryKey: [COMPLETED_IE_REPORTS_QUERY_KEY, ...params],
    queryFn: async () => {
      const data = await API.schedulesAll(...params);
      return data;
    },
  });
};
