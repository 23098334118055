import { useMemo, useCallback } from 'react';
import { Divider } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import yup from '../../../../../../app/utils/customYup';

import EditModal, { IEditModalProps } from '../../../../../../components/EditModal';
import EditDebtForm from './EditDebtForm';
import EditDebtFooter from './EditDebtFooter';

import useLocale from '../../../../../../app/hooks/useLocale';

import {
  useDebtByIdQuery,
  useUpdateDebtMutation,
  useCreateDebtMutation,
  useApplicationFileQuery,
  useOwnershipTypesQuery,
} from '../../queries';
import { getInitialDebtValues } from './utils';
import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';
import { validateGreaterThan } from '../../../../../../app/utils/helpers';

type IEditDebtModalProps = Pick<IEditModalProps, 'onCancel'> & {
  debtId?: string;
  onOk?: () => void;
  isLocked?: boolean;
};

export type IDebtFormValues = ClientPublicService.IAppFormAssetsDebtsDebtDto;

const GREATER_THAN_VALIDATION = 'greater-than-validation';

const EditDebtModal = ({ debtId, onOk, onCancel, isLocked }: IEditDebtModalProps) => {
  const { t } = useLocale();

  const { data: debt } = useDebtByIdQuery(debtId) || {};
  const { data: appFile } = useApplicationFileQuery() || {};

  const { mutate: updateDebt } = useUpdateDebtMutation() || {};
  const { mutate: createDebt } = useCreateDebtMutation() || {};
  const { data: ownershipTypes } = useOwnershipTypesQuery() || {};

  const getRequiredByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_REQUIRED?.replace('{0}', fieldName),
    [t.FIELD_IS_REQUIRED]
  );

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED?.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const getGreaterThanZeroByLabel = useCallback(
    (fieldName: string) => t.SHOULD_BE_GREATER_THAN.replace('{0}', fieldName).replace('{1}', '0'),
    [t.SHOULD_BE_GREATER_THAN]
  );

  const validateNotZero = useCallback((value?: number) => validateGreaterThan(value, 0), []);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        debtDescriptionId: yup.string().required(getRequiredByLabel(t.DESCRIPTION)),
        creditorName: yup.string().required(getRequiredByLabel(t.CREDITOR_NAME)),
        amount: yup.number().test(GREATER_THAN_VALIDATION, getGreaterThanZeroByLabel(t.AMOUNT), validateNotZero),
        accountNumber: yup.string().recommended(getRecommendedByLabel(t.ACCOUNT_NUMBER)),
        specifiedDescription: yup.string().recommended(getRecommendedByLabel(t.OTHER_SPECIFY)),
        creditorEmailAddress: yup.string().email(t.EMAIL_NOT_VALID).nullable(),
      }),
    [
      getRequiredByLabel,
      t.DESCRIPTION,
      t.CREDITOR_NAME,
      t.AMOUNT,
      t.ACCOUNT_NUMBER,
      t.OTHER_SPECIFY,
      t.EMAIL_NOT_VALID,
      getGreaterThanZeroByLabel,
      validateNotZero,
      getRecommendedByLabel,
    ]
  );

  const initialValues = useMemo(
    () => getInitialDebtValues(debt, appFile, ownershipTypes),
    [debt, appFile, ownershipTypes]
  );

  return (
    <EditModal title={debtId ? t.EDIT_DEBT : t.NEW_DEBT} onCancel={onCancel}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={Boolean(debt?.id)}
        validationSchema={validationSchema}
      >
        {({ values, submitForm, setSubmitting }) => (
          <>
            <EditDebtForm isLocked={isLocked} />

            <Divider light sx={{ mb: 2, mt: 1 }} />

            <EditDebtFooter
              isLocked={isLocked}
              onCancel={onCancel}
              onOk={async () => {
                await submitForm();

                const mutateDebt = debtId ? updateDebt : createDebt;
                mutateDebt(
                  {
                    ...debt,
                    ...values,
                  },
                  {
                    onSuccess: () => {
                      toast.success(t.SUCCESSFULLY_SAVED);
                      setSubmitting(false);
                      onOk?.();
                    },
                    onError: () => {
                      toast.error(t.SOMETHING_WENT_WRONG);
                    },
                  }
                );
              }}
            />
          </>
        )}
      </Formik>
    </EditModal>
  );
};

export default EditDebtModal;
