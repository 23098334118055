import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { inputDateToServerFormat, serverDateToInputFormat } from '../../../../app/utils/dateTimeHelpers';
import {
  IFormBusinessInfoValues,
  IFormCoSignOrGuarateedLoanValues,
  IFormFinancialAdviceProviderValues,
  IFormQuestionnaireValues,
} from './types';

const getFinancialAdviceProviderQuestionnaire = (
  data?: ClientPublicService.IAppFormQuestionnaireFinancialAdviceProviderDto
) =>
  ({
    id: data?.id,
    surname: data?.surname || '',
    givenName: data?.givenName || '',
    firmName: data?.firmName || '',
    firmAddress: data?.firmAddress || '',
    firmCity: data?.firmCity || '',
    firmPostalCode: data?.firmPostalCode || '',
    firmStreetName: data?.firmStreetName || '',
    firmStreetNumber: data?.firmStreetNumber || '',
    firmCountryId: data?.firmCountryId || '',
    firmProvinceId: data?.firmProvinceId || '',
    addressUnit: data?.addressUnit || '',
    totalAmountPaidToDate:
      data?.totalAmountPaidToDate !== undefined && data.totalAmountPaidToDate !== null
        ? data.totalAmountPaidToDate
        : null,
    totalRemainingAmountToBePaid:
      data?.totalRemainingAmountToBePaid !== undefined && data.totalRemainingAmountToBePaid !== null
        ? data.totalRemainingAmountToBePaid
        : null,
  } as IFormFinancialAdviceProviderValues);

const convertFinancialAdviceProviderFormValuesToDto = (data?: IFormFinancialAdviceProviderValues) =>
  ({
    id: data?.id,
    surname: data?.surname || undefined,
    givenName: data?.givenName || undefined,
    firmName: data?.firmName || undefined,
    firmAddress: data?.firmAddress || undefined,
    firmCity: data?.firmCity || undefined,
    firmPostalCode: data?.firmPostalCode || undefined,
    firmStreetName: data?.firmStreetName || undefined,
    firmStreetNumber: data?.firmStreetNumber || undefined,
    firmCountryId: data?.firmCountryId || undefined,
    firmProvinceId: data?.firmProvinceId || undefined,
    addressUnit: data?.addressUnit || undefined,
    totalAmountPaidToDate:
      data?.totalAmountPaidToDate !== undefined && data.totalAmountPaidToDate !== null
        ? data.totalAmountPaidToDate
        : undefined,
    totalRemainingAmountToBePaid:
      data?.totalRemainingAmountToBePaid !== undefined && data.totalRemainingAmountToBePaid !== null
        ? data.totalRemainingAmountToBePaid
        : undefined,
  } as ClientPublicService.IAppFormQuestionnaireFinancialAdviceProviderDto);

const getBusinessQuestionnaire = (data?: ClientPublicService.IAppFormQuestionnaireBusinessDto) =>
  ({
    id: data?.id,
    businessName: data?.businessName || '',
    businessAddress: data?.businessAddress || '',
    businessStreetNumber: data?.businessStreetNumber || '',
    businessStreetName: data?.businessStreetName || '',
    businessUnit: data?.businessUnit || '',
    maximumNumberOfEmployeesInThePast12Months: data?.maximumNumberOfEmployeesInThePast12Months || null,
    businessCity: data?.businessCity || '',
    businessPostalCode: data?.businessPostalCode || '',
    businessProvinceId: data?.businessProvinceId || '',
    businessCountryId: data?.businessCountryId || '',
    businessOwnershipTypeId: data?.businessOwnershipTypeId || '',
    businessTypeId: data?.businessTypeId || '',
    isDirector: data?.isDirector,
    nameOfPartnersOrDirectors: data?.nameOfPartnersOrDirectors || '',
    startDate: serverDateToInputFormat(data?.startDate) || null,
    dateOperationsCeased: serverDateToInputFormat(data?.dateOperationsCeased) || null,
    isCorporationBankrupt: data?.isCorporationBankrupt,
    hasEmployeesOrSubContractors: data?.hasEmployeesOrSubContractors,
    isOwingWagesToEmployees: data?.isOwingWagesToEmployees,
    isOwingSourceDeductionOnWages: data?.isOwingSourceDeductionOnWages,
    otherDetails: data?.otherDetails || '',
  } as IFormBusinessInfoValues);

const convertBusinessFormValuesToDto = (data?: IFormBusinessInfoValues) =>
  ({
    id: data?.id,
    businessName: data?.businessName || undefined,
    businessAddress: data?.businessAddress || undefined,
    businessStreetNumber: data?.businessStreetNumber || undefined,
    businessStreetName: data?.businessStreetName || undefined,
    businessUnit: data?.businessUnit || undefined,
    maximumNumberOfEmployeesInThePast12Months: data?.maximumNumberOfEmployeesInThePast12Months || undefined,
    businessCity: data?.businessCity || undefined,
    businessPostalCode: data?.businessPostalCode || undefined,
    businessProvinceId: data?.businessProvinceId || undefined,
    businessCountryId: data?.businessCountryId || undefined,
    businessOwnershipTypeId: data?.businessOwnershipTypeId || undefined,
    businessTypeId: data?.businessTypeId || undefined,
    isDirector: data?.isDirector,
    nameOfPartnersOrDirectors: data?.nameOfPartnersOrDirectors || undefined,
    startDate: inputDateToServerFormat(data?.startDate),
    dateOperationsCeased: inputDateToServerFormat(data?.dateOperationsCeased),
    isCorporationBankrupt: data?.isCorporationBankrupt,
    hasEmployeesOrSubContractors: data?.hasEmployeesOrSubContractors,
    isOwingWagesToEmployees: data?.isOwingWagesToEmployees,
    isOwingSourceDeductionOnWages: data?.isOwingSourceDeductionOnWages,
    otherDetails: data?.otherDetails || undefined,
  } as ClientPublicService.IAppFormQuestionnaireBusinessDto);

const getCoSignedOrGuaranteedLoan = (data?: ClientPublicService.IAppFormQuestionnaireCoSignOrGuaranteedLoanDto) =>
  ({
    id: data?.id,
    isBySpouse: data?.isBySpouse ? 'spouse' : 'applicant',
    lendersName: data?.lendersName || '',
    lendersAddress: data?.lendersAddress || '',
    lendersStreetNumber: data?.lendersStreetNumber || '',
    lendersStreetName: data?.lendersStreetName || '',
    lendersUnit: data?.lendersUnit || '',
    lendersCity: data?.lendersCity || '',
    lendersPostalCode: data?.lendersPostalCode || '',
    lendersProvinceId: data?.lendersProvinceId || '',
    lendersCountryId: data?.lendersCountryId || '',
    borrowersName: data?.borrowersName || '',
    borrowersAddress: data?.borrowersAddress || '',
    borrowersStreetNumber: data?.borrowersStreetNumber || '',
    borrowersStreetName: data?.borrowersStreetName || '',
    borrowersUnit: data?.borrowersUnit || '',
    borrowersCity: data?.borrowersCity || '',
    borrowersPostalCode: data?.borrowersPostalCode || '',
    borrowersProvinceId: data?.borrowersProvinceId || '',
    borrowersCountryId: data?.borrowersCountryId || '',
  } as IFormCoSignOrGuarateedLoanValues);

const convertCoSignedOrGuaranteedLoanFormValuesToDto = (data?: IFormCoSignOrGuarateedLoanValues) =>
  ({
    id: data?.id,
    isBySpouse: data?.isBySpouse === 'spouse',
    lendersName: data?.lendersName || undefined,
    lendersAddress: data?.lendersAddress || undefined,
    lendersStreetNumber: data?.lendersStreetNumber || undefined,
    lendersStreetName: data?.lendersStreetName || undefined,
    lendersUnit: data?.lendersUnit || undefined,
    lendersCity: data?.lendersCity || undefined,
    lendersPostalCode: data?.lendersPostalCode || undefined,
    lendersProvinceId: data?.lendersProvinceId || undefined,
    lendersCountryId: data?.lendersCountryId || undefined,
    borrowersName: data?.borrowersName || undefined,
    borrowersAddress: data?.borrowersAddress || undefined,
    borrowersStreetNumber: data?.borrowersStreetNumber || undefined,
    borrowersStreetName: data?.borrowersStreetName || undefined,
    borrowersUnit: data?.borrowersUnit || undefined,
    borrowersCity: data?.borrowersCity || undefined,
    borrowersPostalCode: data?.borrowersPostalCode || undefined,
    borrowersProvinceId: data?.borrowersProvinceId || undefined,
    borrowersCountryId: data?.borrowersCountryId || undefined,
  } as ClientPublicService.IAppFormQuestionnaireCoSignOrGuaranteedLoanDto);

const getAppFormQuestionnaire = (
  data?: ClientPublicService.IAppFormQuestionnaireDto,
  documents?: ClientPublicService.DocumentDto[]
) =>
  ({
    id: data?.id,
    fileId: data?.fileId,
    isMarried: data?.isMarried,
    isDebtFromFineOrPenaltyImposedByCourt: data?.isDebtFromFineOrPenaltyImposedByCourt,
    debtFromFineOrPenaltyImposedByCourtDetails: data?.debtFromFineOrPenaltyImposedByCourtDetails || '',
    isDebtFromRecognizanceOfBailBond: data?.isDebtFromRecognizanceOfBailBond,
    debtFromRecognizanceOfBailBondDetails: data?.debtFromRecognizanceOfBailBondDetails || '',
    isDebtFromAlimony: data?.isDebtFromAlimony,
    debtFromAlimonyDetails: data?.debtFromAlimonyDetails || '',
    isDebtFromMaintenanceOfAffiliationOrder: data?.isDebtFromMaintenanceOfAffiliationOrder,
    debtFromMaintenanceOfAffiliationOrderDetails: data?.debtFromMaintenanceOfAffiliationOrderDetails || '',
    isDebtFromMaintenanceOfSupportOfSeparatedFamily: data?.isDebtFromMaintenanceOfSupportOfSeparatedFamily,
    debtFromMaintenanceOfSupportOfSeparatedFamilyDetails:
      data?.debtFromMaintenanceOfSupportOfSeparatedFamilyDetails || '',
    isDebtFromFraud: data?.isDebtFromFraud,
    debtFromFraudDetails: data?.debtFromFraudDetails || '',
    isDebtFromEmbezzlement: data?.isDebtFromEmbezzlement,
    debtFromEmbezzlementDetails: data?.debtFromEmbezzlementDetails || '',
    isDebtFromMisappropriation: data?.isDebtFromMisappropriation,
    debtFromMisappropriationDetails: data?.debtFromMisappropriationDetails || '',
    isDebtFromDefalcationWhileActingInAFiduciaryCapacity: data?.isDebtFromDefalcationWhileActingInAFiduciaryCapacity,
    debtFromDefalcationWhileActingInAFiduciaryCapacityDetails:
      data?.debtFromDefalcationWhileActingInAFiduciaryCapacityDetails || '',
    isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud:
      data?.isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud,
    debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails:
      data?.debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails || '',
    isDebtFromStudentLoansOutstanding: data?.isDebtFromStudentLoansOutstanding,
    debtFromStudentLoansOutstandingDetails: data?.debtFromStudentLoansOutstandingDetails || '',
    debtFromStudentLoansOutstandingDateCeasedBeingStudent:
      serverDateToInputFormat(data?.debtFromStudentLoansOutstandingDateCeasedBeingStudent) || null,
    isSpouseDebtFromFineOrPenaltyImposedByCourt: data?.isSpouseDebtFromFineOrPenaltyImposedByCourt,
    spouseDebtFromFineOrPenaltyImposedByCourtDetails: data?.spouseDebtFromFineOrPenaltyImposedByCourtDetails || '',
    isSpouseDebtFromRecognizanceOfBailBond: data?.isSpouseDebtFromRecognizanceOfBailBond,
    spouseDebtFromRecognizanceOfBailBondDetails: data?.spouseDebtFromRecognizanceOfBailBondDetails || '',
    isSpouseDebtFromAlimony: data?.isSpouseDebtFromAlimony,
    spouseDebtFromAlimonyDetails: data?.spouseDebtFromAlimonyDetails || '',
    isSpouseDebtFromMaintenanceOfAffiliationOrder: data?.isSpouseDebtFromMaintenanceOfAffiliationOrder,
    spouseDebtFromMaintenanceOfAffiliationOrderDetails: data?.spouseDebtFromMaintenanceOfAffiliationOrderDetails || '',
    isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily: data?.isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily,
    spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails:
      data?.spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails || '',
    isSpouseDebtFromFraud: data?.isSpouseDebtFromFraud,
    spouseDebtFromFraudDetails: data?.spouseDebtFromFraudDetails || '',
    isSpouseDebtFromEmbezzlement: data?.isSpouseDebtFromEmbezzlement,
    spouseDebtFromEmbezzlementDetails: data?.spouseDebtFromEmbezzlementDetails || '',
    isSpouseDebtFromMisappropriation: data?.isSpouseDebtFromMisappropriation,
    spouseDebtFromMisappropriationDetails: data?.spouseDebtFromMisappropriationDetails || '',
    isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity:
      data?.isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity,
    spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails:
      data?.spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails || '',
    isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud:
      data?.isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud,
    spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails:
      data?.spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails || '',
    isSpouseDebtFromStudentLoansOutstanding: data?.isSpouseDebtFromStudentLoansOutstanding,
    spouseDebtFromStudentLoansOutstandingDetails: data?.spouseDebtFromStudentLoansOutstandingDetails || '',
    spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent:
      serverDateToInputFormat(data?.spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent) || null,
    hasCoSignedOrGuaranteedLoan: data?.hasCoSignedOrGuaranteedLoan,
    coSignedOrGuaranteedLoans: data?.coSignedOrGuaranteedLoans?.map((item) => getCoSignedOrGuaranteedLoan(item)) || [],
    hasMadeExessPaymentsPast12Months: data?.hasMadeExessPaymentsPast12Months,
    hasMadeExessPaymentsPast12MonthsDetails: data?.hasMadeExessPaymentsPast12MonthsDetails || '',
    hasAssetsSiezedOrGarnishedPast12Months: data?.hasAssetsSiezedOrGarnishedPast12Months,
    hasAssetsSiezedOrGarnishedPast12MonthsDetail: data?.hasAssetsSiezedOrGarnishedPast12MonthsDetail || '',
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years,
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails || '',
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime,
    hasGivenGiftsOver500WhileInsolventPast5Years: data?.hasGivenGiftsOver500WhileInsolventPast5Years,
    hasGivenGiftsOver500WhileInsolventPast5YearsDetails:
      data?.hasGivenGiftsOver500WhileInsolventPast5YearsDetails || '',
    isExpectedToReceiveNonIncomeFundsNext12Months: data?.isExpectedToReceiveNonIncomeFundsNext12Months,
    isExpectedToReceiveNonIncomeFundsNext12MonthsDetails:
      data?.isExpectedToReceiveNonIncomeFundsNext12MonthsDetails || '',
    hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty:
      data?.hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty,
    hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails:
      data?.hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails || '',
    hasMadeArragementsToContinuePayingCreditors: data?.hasMadeArragementsToContinuePayingCreditors,
    hasMadeArragementsToContinuePayingCreditorsDetails: data?.hasMadeArragementsToContinuePayingCreditorsDetails || '',
    hasSpouseMadeExessPaymentsPast12Months: data?.hasSpouseMadeExessPaymentsPast12Months,
    hasSpouseMadeExessPaymentsPast12MonthsDetails: data?.hasSpouseMadeExessPaymentsPast12MonthsDetails || '',
    hasSpouseAssetsSiezedOrGarnishedPast12Months: data?.hasSpouseAssetsSiezedOrGarnishedPast12Months,
    hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail: data?.hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail || '',
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years,
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails || '',
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime,
    hasSpouseGivenGiftsOver500WhileInsolventPast5Years: data?.hasSpouseGivenGiftsOver500WhileInsolventPast5Years,
    hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails:
      data?.hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails || '',
    isSpouseExpectedToReceiveNonIncomeFundsNext12Months: data?.isSpouseExpectedToReceiveNonIncomeFundsNext12Months,
    isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails:
      data?.isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails || '',
    hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty:
      data?.hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty,
    hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails:
      data?.hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails || '',
    hasSpouseMadeArragementsToContinuePayingCreditors: data?.hasSpouseMadeArragementsToContinuePayingCreditors,
    hasSpouseMadeArragementsToContinuePayingCreditorsDetails:
      data?.hasSpouseMadeArragementsToContinuePayingCreditorsDetails || '',
    hasOwnedBusinessPast5Years: data?.hasOwnedBusinessPast5Years,
    hasSpouseOwnedBusinessPast5Years: data?.hasSpouseOwnedBusinessPast5Years,
    businesses: data?.businesses?.map((item) => getBusinessQuestionnaire(item)) || [],
    spouseBusinesses: data?.spouseBusinesses?.map((item) => getBusinessQuestionnaire(item)) || [],
    financialAdvices: data?.financialAdvices?.map((item) => getFinancialAdviceProviderQuestionnaire(item)) || [],
    spouseFinancialAdvices:
      data?.spouseFinancialAdvices?.map((item) => getFinancialAdviceProviderQuestionnaire(item)) || [],
    debtorHasAFinancialAdviceProvider: data?.debtorHasAFinancialAdviceProvider,
    debtorSpouseHasAFinancialAdviceProvider: data?.debtorSpouseHasAFinancialAdviceProvider,
    isConsentChecked: data?.isConsentChecked,
    consentDate: serverDateToInputFormat(data?.consentDate) || null,
    documentIds: data?.documentIds || [],
    documents,
  } as IFormQuestionnaireValues);

const convertQuestionnaireFormValuesToDto = (data?: IFormQuestionnaireValues) =>
  ({
    id: data?.id,
    fileId: data?.fileId,
    isMarried: data?.isMarried,
    isDebtFromFineOrPenaltyImposedByCourt: data?.isDebtFromFineOrPenaltyImposedByCourt,
    debtFromFineOrPenaltyImposedByCourtDetails: data?.debtFromFineOrPenaltyImposedByCourtDetails || undefined,
    isDebtFromRecognizanceOfBailBond: data?.isDebtFromRecognizanceOfBailBond,
    debtFromRecognizanceOfBailBondDetails: data?.debtFromRecognizanceOfBailBondDetails || undefined,
    isDebtFromAlimony: data?.isDebtFromAlimony,
    debtFromAlimonyDetails: data?.debtFromAlimonyDetails || undefined,
    isDebtFromMaintenanceOfAffiliationOrder: data?.isDebtFromMaintenanceOfAffiliationOrder,
    debtFromMaintenanceOfAffiliationOrderDetails: data?.debtFromMaintenanceOfAffiliationOrderDetails || undefined,
    isDebtFromMaintenanceOfSupportOfSeparatedFamily: data?.isDebtFromMaintenanceOfSupportOfSeparatedFamily,
    debtFromMaintenanceOfSupportOfSeparatedFamilyDetails:
      data?.debtFromMaintenanceOfSupportOfSeparatedFamilyDetails || undefined,
    isDebtFromFraud: data?.isDebtFromFraud,
    debtFromFraudDetails: data?.debtFromFraudDetails || undefined,
    isDebtFromEmbezzlement: data?.isDebtFromEmbezzlement,
    debtFromEmbezzlementDetails: data?.debtFromEmbezzlementDetails || undefined,
    isDebtFromMisappropriation: data?.isDebtFromMisappropriation,
    debtFromMisappropriationDetails: data?.debtFromMisappropriationDetails || undefined,
    isDebtFromDefalcationWhileActingInAFiduciaryCapacity: data?.isDebtFromDefalcationWhileActingInAFiduciaryCapacity,
    debtFromDefalcationWhileActingInAFiduciaryCapacityDetails:
      data?.debtFromDefalcationWhileActingInAFiduciaryCapacityDetails || undefined,
    isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud:
      data?.isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud,
    debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails:
      data?.debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails || undefined,
    isDebtFromStudentLoansOutstanding: data?.isDebtFromStudentLoansOutstanding,
    debtFromStudentLoansOutstandingDetails: data?.debtFromStudentLoansOutstandingDetails || undefined,
    debtFromStudentLoansOutstandingDateCeasedBeingStudent: inputDateToServerFormat(
      data?.debtFromStudentLoansOutstandingDateCeasedBeingStudent
    ),
    isSpouseDebtFromFineOrPenaltyImposedByCourt: data?.isSpouseDebtFromFineOrPenaltyImposedByCourt,
    spouseDebtFromFineOrPenaltyImposedByCourtDetails:
      data?.spouseDebtFromFineOrPenaltyImposedByCourtDetails || undefined,
    isSpouseDebtFromRecognizanceOfBailBond: data?.isSpouseDebtFromRecognizanceOfBailBond,
    spouseDebtFromRecognizanceOfBailBondDetails: data?.spouseDebtFromRecognizanceOfBailBondDetails || undefined,
    isSpouseDebtFromAlimony: data?.isSpouseDebtFromAlimony,
    spouseDebtFromAlimonyDetails: data?.spouseDebtFromAlimonyDetails || undefined,
    isSpouseDebtFromMaintenanceOfAffiliationOrder: data?.isSpouseDebtFromMaintenanceOfAffiliationOrder,
    spouseDebtFromMaintenanceOfAffiliationOrderDetails:
      data?.spouseDebtFromMaintenanceOfAffiliationOrderDetails || undefined,
    isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily: data?.isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily,
    spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails:
      data?.spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails || undefined,
    isSpouseDebtFromFraud: data?.isSpouseDebtFromFraud,
    spouseDebtFromFraudDetails: data?.spouseDebtFromFraudDetails || undefined,
    isSpouseDebtFromEmbezzlement: data?.isSpouseDebtFromEmbezzlement,
    spouseDebtFromEmbezzlementDetails: data?.spouseDebtFromEmbezzlementDetails || undefined,
    isSpouseDebtFromMisappropriation: data?.isSpouseDebtFromMisappropriation,
    spouseDebtFromMisappropriationDetails: data?.spouseDebtFromMisappropriationDetails || undefined,
    isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity:
      data?.isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity,
    spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails:
      data?.spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails || undefined,
    isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud:
      data?.isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud,
    spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails:
      data?.spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails || undefined,
    isSpouseDebtFromStudentLoansOutstanding: data?.isSpouseDebtFromStudentLoansOutstanding,
    spouseDebtFromStudentLoansOutstandingDetails: data?.spouseDebtFromStudentLoansOutstandingDetails || undefined,
    spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent: inputDateToServerFormat(
      data?.spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent
    ),
    hasCoSignedOrGuaranteedLoan: data?.hasCoSignedOrGuaranteedLoan,
    coSignedOrGuaranteedLoans:
      data?.coSignedOrGuaranteedLoans?.map((item) => convertCoSignedOrGuaranteedLoanFormValuesToDto(item)) || [],
    hasMadeExessPaymentsPast12Months: data?.hasMadeExessPaymentsPast12Months,
    hasMadeExessPaymentsPast12MonthsDetails: data?.hasMadeExessPaymentsPast12MonthsDetails || undefined,
    hasAssetsSiezedOrGarnishedPast12Months: data?.hasAssetsSiezedOrGarnishedPast12Months,
    hasAssetsSiezedOrGarnishedPast12MonthsDetail: data?.hasAssetsSiezedOrGarnishedPast12MonthsDetail || undefined,
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years,
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails || undefined,
    hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime:
      data?.hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime,
    hasGivenGiftsOver500WhileInsolventPast5Years: data?.hasGivenGiftsOver500WhileInsolventPast5Years,
    hasGivenGiftsOver500WhileInsolventPast5YearsDetails:
      data?.hasGivenGiftsOver500WhileInsolventPast5YearsDetails || undefined,
    isExpectedToReceiveNonIncomeFundsNext12Months: data?.isExpectedToReceiveNonIncomeFundsNext12Months,
    isExpectedToReceiveNonIncomeFundsNext12MonthsDetails:
      data?.isExpectedToReceiveNonIncomeFundsNext12MonthsDetails || undefined,
    hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty:
      data?.hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty,
    hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails:
      data?.hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails || undefined,
    hasMadeArragementsToContinuePayingCreditors: data?.hasMadeArragementsToContinuePayingCreditors,
    hasMadeArragementsToContinuePayingCreditorsDetails:
      data?.hasMadeArragementsToContinuePayingCreditorsDetails || undefined,
    hasSpouseMadeExessPaymentsPast12Months: data?.hasSpouseMadeExessPaymentsPast12Months,
    hasSpouseMadeExessPaymentsPast12MonthsDetails: data?.hasSpouseMadeExessPaymentsPast12MonthsDetails || undefined,
    hasSpouseAssetsSiezedOrGarnishedPast12Months: data?.hasSpouseAssetsSiezedOrGarnishedPast12Months,
    hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail:
      data?.hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail || undefined,
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years,
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails || undefined,
    hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime:
      data?.hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime,
    hasSpouseGivenGiftsOver500WhileInsolventPast5Years: data?.hasSpouseGivenGiftsOver500WhileInsolventPast5Years,
    hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails:
      data?.hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails || undefined,
    isSpouseExpectedToReceiveNonIncomeFundsNext12Months: data?.isSpouseExpectedToReceiveNonIncomeFundsNext12Months,
    isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails:
      data?.isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails || undefined,
    hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty:
      data?.hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty,
    hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails:
      data?.hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails || undefined,
    hasSpouseMadeArragementsToContinuePayingCreditors: data?.hasSpouseMadeArragementsToContinuePayingCreditors,
    hasSpouseMadeArragementsToContinuePayingCreditorsDetails:
      data?.hasSpouseMadeArragementsToContinuePayingCreditorsDetails || undefined,
    hasOwnedBusinessPast5Years: data?.hasOwnedBusinessPast5Years,
    hasSpouseOwnedBusinessPast5Years: data?.hasSpouseOwnedBusinessPast5Years,
    businesses: data?.businesses?.map((item) => convertBusinessFormValuesToDto(item)) || [],
    spouseBusinesses: data?.spouseBusinesses?.map((item) => convertBusinessFormValuesToDto(item)) || [],
    debtorHasAFinancialAdviceProvider: data?.debtorHasAFinancialAdviceProvider,
    debtorSpouseHasAFinancialAdviceProvider: data?.debtorSpouseHasAFinancialAdviceProvider,
    financialAdvices: data?.financialAdvices?.map((item) => convertFinancialAdviceProviderFormValuesToDto(item)) || [],
    spouseFinancialAdvices:
      data?.spouseFinancialAdvices?.map((item) => convertFinancialAdviceProviderFormValuesToDto(item)) || [],
    isConsentChecked: data?.isConsentChecked,
    consentDate: inputDateToServerFormat(data?.consentDate),
    documentIds: data?.documentIds || [],
  } as ClientPublicService.IAppFormQuestionnaireDto);

export {
  getBusinessQuestionnaire,
  getAppFormQuestionnaire,
  getCoSignedOrGuaranteedLoan,
  convertQuestionnaireFormValuesToDto,
  getFinancialAdviceProviderQuestionnaire,
};
