import { useMemo } from 'react';
import { Stack, Typography, useTheme, Skeleton } from '@mui/material';

import DataItem from '../../../components/DataItem';
import PaymentDataItemSkeleton from './PaymentDataItemSkeleton';

import useLocale from '../../../app/hooks/useLocale';

import { serverDateToInputFormat, DATE_FORMAT4, DATE_FORMAT } from '../../../app/utils/dateTimeHelpers';
import { currencyFormatter } from '../../../app/utils/helpers';
import { isUpcoming, isPast } from '../utils';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

interface IPaymentDataItem {
  data?: ClientPublicService.PADScheduleDto;
  extended?: boolean;
  clickable?: boolean;
  onClick?: () => void;
}

const PaymentDataItem = ({ data, extended = false, clickable = false, onClick }: IPaymentDataItem) => {
  const { t, getLocalizedDate } = useLocale();
  const theme = useTheme();

  const dueAmount = useMemo(() => (data?.amount || 0) - (data?.deposited || 0), [data?.amount, data?.deposited]);

  const isPaidInFull = useMemo(() => data?.isDeposited && dueAmount === 0, [data?.isDeposited, dueAmount]);

  const isOutstanding = useMemo(() => isPast(data?.dueDate) && dueAmount > 0, [data?.dueDate, dueAmount]);

  const isOnHold = useMemo(() => data?.state === ClientPublicService.PADScheduleStateEnum.OnHold, [data?.state]);

  const isHoldRequested = useMemo(
    () => data?.state === ClientPublicService.PADScheduleStateEnum.HoldRequested,
    [data?.state]
  );

  const description = useMemo(() => {
    if (isHoldRequested)
      return t.HOLD_REQUESTED_UNTIL?.replace(
        '{0}',
        getLocalizedDate(serverDateToInputFormat(data?.holdUntil), DATE_FORMAT) || ''
      );
    if (isOnHold)
      return t.ON_HOLD_UNTIL?.replace(
        '{0}',
        getLocalizedDate(serverDateToInputFormat(data?.holdUntil), DATE_FORMAT) || ''
      );
    if (isPaidInFull) return t.PAID;
    if (!isPaidInFull && !isUpcoming(data?.dueDate)) return t.OUTSTANDING;
    return '';
  }, [
    data?.dueDate,
    data?.holdUntil,
    getLocalizedDate,
    isHoldRequested,
    isOnHold,
    isPaidInFull,
    t.HOLD_REQUESTED_UNTIL,
    t.ON_HOLD_UNTIL,
    t.OUTSTANDING,
    t.PAID,
  ]);

  if (!data) return <PaymentDataItemSkeleton />;

  return (
    <DataItem
      title={data?.dueDate ? getLocalizedDate(serverDateToInputFormat(data?.dueDate), DATE_FORMAT4) : <Skeleton />}
      clickable={clickable}
      onClick={onClick}
      content={
        <Stack sx={{ fontSize: 14 }}>
          <Typography variant="body3" fontWeight={600}>
            {t.PREAUTHORIZED_PAYMENT}
          </Typography>
          {data && (
            <Typography
              variant="body3"
              fontWeight={600}
              style={{
                color:
                  isHoldRequested || isOnHold
                    ? theme.palette.cobalt
                    : isPaidInFull
                    ? theme.palette.successGreen
                    : theme.palette.urgentRed,
              }}
            >
              {description}
              {description && data?.hasReceipts && !isHoldRequested && !isOnHold ? ' - ' : ''}
              {data?.hasReceipts && !isHoldRequested && !isOnHold ? `${t.RECEIPT_AVAILABLE}` : ''}
            </Typography>
          )}
        </Stack>
      }
      actionComponent={
        <>
          {extended && isOutstanding ? (
            <Stack flex={1} alignSelf="flex-start" mt={0.75}>
              <Typography variant="body3" color="secondary" fontWeight={600} textAlign="center" noWrap>
                {t.AMOUNT_DUE}
              </Typography>

              <Typography variant="body3" fontWeight={600} textAlign="center" mt={0.25}>
                {currencyFormatter(data?.amount)}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          <Stack flex={1} alignSelf="flex-start" mt={0.75}>
            <Typography variant="body3" color="secondary" fontWeight={600} textAlign="right">
              {extended && isOutstanding ? t.OUTSTANDING : t.AMOUNT}
            </Typography>

            <Typography
              variant="body3"
              fontWeight={600}
              textAlign="right"
              mt={0.25}
              sx={{
                color: isUpcoming(data?.dueDate)
                  ? theme.palette.primary.main
                  : isPaidInFull
                  ? theme.palette.successGreen
                  : theme.palette.urgentRed,
              }}
            >
              {currencyFormatter(isPaidInFull ? data?.amount : dueAmount)}
            </Typography>
          </Stack>
        </>
      }
    />
  );
};

export default PaymentDataItem;
