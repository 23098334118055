import { useQuery, useMutation } from '@tanstack/react-query';

import {
  APPLICATION_FILE_QUERY_KEY,
  SUPPORTING_DOCUMENTS_QUERY_KEY,
  DOCUMENTS_TYPES_QUERY_KEY,
  DOCUMENTS_QUERY_KEY,
  DEBTOR_INFORMATION_QUERY_KEY,
} from '../../app/constants/reactQueryKeys';

import {
  argumentifyDocumentCreateParams,
  convertFormValuesToDocumentCreateParams,
} from '../../components/DocumentsUpload/utils';
import { IFormValuesDocumentItem } from '../../components/DocumentsUpload/types';

import { ISupportingDocumentUploadParams, IDocumentsSearchParams, ISupportingDocumentsSearchParams } from './types';
import {
  argumentifyDocumentsGetParams,
  argumentifySupportingDocumentUploadParams,
  argumentifySupportingDocumentsSearchParams,
} from './utils';
import { queryClient } from '../../app/config';
import { ClientPublicService } from '../../app/api/ClientPublicService';
import API from '../../app/api/api';
import { BaseService } from '../../app/api/BaseService';

export const useDebtorProfileQuery = () =>
  useQuery({
    queryKey: [DEBTOR_INFORMATION_QUERY_KEY],
    queryFn: async () => {
      const data = await API.getDebtorProfile();
      return data;
    },
  });

export const useApplicationFileQuery = () =>
  useQuery({
    queryKey: [APPLICATION_FILE_QUERY_KEY],
    queryFn: async () => {
      const data = await API.applicationFile();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useDocumentsTypesQuery = () =>
  useQuery({
    queryKey: [DOCUMENTS_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listTypes();
      return data;
    },
  });

export const useDocumentsQuery = (params: IDocumentsSearchParams) => {
  const args = argumentifyDocumentsGetParams({ ...params, isVisible: true });

  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, ...args],
    queryFn: async () => {
      const data = await API.documentsGET(...args);

      return data;
    },
    enabled: !!params?.fileId,
  });
};

export const useAllDocumentsCountQuery = (fileId?: string) => {
  const args = argumentifyDocumentsGetParams({ fileId, isVisible: true });

  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, ...args],
    queryFn: async () => {
      const data = await API.documentsGET(...args);

      return data?.totalCount;
    },
    enabled: !!fileId,
  });
};

export const useSupportingDocumentsQuery = (requestParams: ISupportingDocumentsSearchParams) =>
  useQuery({
    queryKey: [SUPPORTING_DOCUMENTS_QUERY_KEY, ...argumentifySupportingDocumentsSearchParams(requestParams)],
    queryFn: async () => {
      const data = await API.getAllSupportingdocuments(
        ...argumentifySupportingDocumentsSearchParams({
          ...requestParams,
          supportingDocumentStatus: ClientPublicService.SupportingDocumentStatusEnum.Pending,
        })
      );

      return data;
    },
    enabled: !!requestParams?.skipCount || !!requestParams?.maxResultCount,
  });

export const useDocumentsUploadMutation = () =>
  useMutation({
    mutationFn: async ({ fileId, files }: { fileId: string; files: IFormValuesDocumentItem[] }) => {
      const newFiles = (files || [])?.filter((item: IFormValuesDocumentItem) => !item.id);

      const documentIds: string[] = [];

      for (let file of newFiles) {
        const params = convertFormValuesToDocumentCreateParams({ fileId, values: file });
        const result = await API.documentsPOST(...argumentifyDocumentCreateParams(params)).catch((error) => error);
        if (result?.returnId) {
          documentIds.push(result.returnId);
        }
      }

      return documentIds;
    },
  });

export const useGeneralUploadMutation = () =>
  useMutation({
    mutationFn: (values: ISupportingDocumentUploadParams) => {
      return API.upload(...argumentifySupportingDocumentUploadParams(values));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SUPPORTING_DOCUMENTS_QUERY_KEY]);
      queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY]);
    },
  });

export const useSupportingDocumentUploadMutation = () =>
  useMutation({
    mutationFn: (body: ClientPublicService.SupportingDocumentDocumentUpdateDto) => {
      return API.updateDocumentsMany(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SUPPORTING_DOCUMENTS_QUERY_KEY]);
      queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY]);
    },
  });

export const useDownloadBlobMutation = () =>
  useMutation({
    mutationFn: async (documentId: string) => {
      const token = new BaseService().getAccessTokenHeader();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/client-service-public/documents/${documentId}/download`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: token,
          }),
        }
      );
      return response.blob();
    },
  });
