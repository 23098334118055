import { createContext, useState, useMemo, useCallback } from 'react';
import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

import BasicTab from './BasicTab';
import DetailsTab from './DetailsTab/DetailsTab';
import DocumentsTab from './DocumentsTab';

import useLocale from '../../../../../../app/hooks/useLocale';
import { IAssetModalTab } from './types';
import { useListPrefixesForAssetsQuery } from '../../queries';

export const BASIC_TAB = 'basic';
export const DETAILS_TAB = 'details';
export const DOCUMENTS_TAB = 'documents';

interface IEditAssetModalContext {
  tabs?: IAssetModalTab[];
  tabsCount: number;
  currentTab: number;
  goToNextTab?: () => void;
  goToPreviousTab?: () => void;
  assetId?: string;
  setAssetId: (assetId?: string) => void;
  assetDescriptionEnum?: ClientPublicService.AssetDescriptionEnum;
  setAssetDescriptionEnum: (assetDescriptionEnum?: ClientPublicService.AssetDescriptionEnum) => void;
  assetTypeEnum?: ClientPublicService.AssetTypeEnum;
  setAssetTypeEnum: (assetTypeEnum?: ClientPublicService.AssetTypeEnum) => void;
  isRetirementFund?: boolean;
  isRESP?: boolean;
  isTaxableInvestment?: boolean;
  isSharedInvestment?: boolean;
  isOwnershipInterest?: boolean;
  isTFSA?: boolean;
  isOther?: boolean;
  isLocked?: boolean;
}

export const initialEditAssetModalContextValues: IEditAssetModalContext = {
  tabs: [],
  tabsCount: 0,
  currentTab: 0,
  goToNextTab: () => {},
  goToPreviousTab: () => {},
  assetId: undefined,
  setAssetId: () => {},
  assetDescriptionEnum: undefined,
  setAssetDescriptionEnum: () => {},
  assetTypeEnum: undefined,
  setAssetTypeEnum: () => {},
  isRetirementFund: false,
  isRESP: false,
  isTaxableInvestment: false,
  isSharedInvestment: false,
  isOwnershipInterest: false,
  isTFSA: false,
  isOther: false,
  isLocked: false,
};

export const EditAssetModalContext = createContext<IEditAssetModalContext>(initialEditAssetModalContextValues);

interface IProps {
  isLocked?: boolean;
  children: React.ReactElement;
}

const EditAssetModalProvider = ({ isLocked, children }: IProps): React.ReactElement => {
  const { t } = useLocale();

  const [assetId, setAssetId] = useState<string>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [assetDescriptionEnum, setAssetDescriptionEnum] = useState<ClientPublicService.AssetDescriptionEnum>();
  const [assetTypeEnum, setAssetTypeEnum] = useState<ClientPublicService.AssetTypeEnum>();

  const { data: prefixesForAsset } = useListPrefixesForAssetsQuery(assetId);

  const isRetirementFund = useMemo(
    () =>
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RRSP ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RDSP,
    [assetDescriptionEnum]
  );

  const isRESP = useMemo(
    () => assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RESP,
    [assetDescriptionEnum]
  );

  const isTaxableInvestment = useMemo(
    () =>
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Shares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Bonds ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Investments ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RRIF ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.LIRA ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Pension,
    [assetDescriptionEnum]
  );

  const isSharedInvestment = useMemo(
    () =>
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.CoopShares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.CreditUnionShares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_InvestmentsOrPolicies,
    [assetDescriptionEnum]
  );

  const isOwnershipInterest = useMemo(
    () =>
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RESP ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Shares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Bonds ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Investments ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.RRIF ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.LIRA ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Pension ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.CoopShares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.CreditUnionShares ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_InvestmentsOrPolicies,
    [assetDescriptionEnum]
  );

  const isTFSA = useMemo(
    () => assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.TFSA,
    [assetDescriptionEnum]
  );

  const isOther = useMemo(
    () =>
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_CashOrSavings ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_Furniture ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_PersonalEffects ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_InvestmentsOrPolicies ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_RealEstate ||
      assetDescriptionEnum === ClientPublicService.AssetDescriptionEnum.Other_RecreationalEquipment,
    [assetDescriptionEnum]
  );

  const tabs = useMemo(
    () =>
      [
        {
          label: t.BASIC,
          key: BASIC_TAB,
          children: <BasicTab />,
          conditionalRender: true,
        },
        {
          label: t.DETAILS,
          key: DETAILS_TAB,
          children: <DetailsTab />,
          conditionalRender:
            assetTypeEnum === ClientPublicService.AssetTypeEnum.RealEstate ||
            assetTypeEnum === ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies ||
            assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles ||
            assetTypeEnum === ClientPublicService.AssetTypeEnum.RecreationalEquipment,
        },
        {
          label: t.DOCUMENTS,
          key: DOCUMENTS_TAB,
          children: <DocumentsTab />,
          conditionalRender: assetId
            ? prefixesForAsset && prefixesForAsset?.length > 0
            : isTFSA ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.RealEstate ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.RecreationalEquipment ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles,
        },
      ]?.filter((tab) => Boolean(tab?.conditionalRender)),
    [assetId, assetTypeEnum, isTFSA, prefixesForAsset, t.BASIC, t.DETAILS, t.DOCUMENTS]
  );

  const tabsCount = useMemo(() => tabs?.length || 0, [tabs]);

  const goToNextTab = useCallback(() => {
    setCurrentTab(currentTab < tabsCount - 1 ? currentTab + 1 : currentTab);
  }, [currentTab, tabsCount]);

  const goToPreviousTab = useCallback(() => {
    setCurrentTab(currentTab > 0 ? currentTab - 1 : 0);
  }, [currentTab]);

  return (
    <EditAssetModalContext.Provider
      value={{
        tabs,
        tabsCount,
        currentTab,
        goToNextTab,
        goToPreviousTab,
        assetId,
        setAssetId,
        assetDescriptionEnum,
        setAssetDescriptionEnum,
        assetTypeEnum,
        setAssetTypeEnum,
        isRetirementFund,
        isRESP,
        isTaxableInvestment,
        isSharedInvestment,
        isOwnershipInterest,
        isTFSA,
        isOther,
        isLocked,
      }}
    >
      {children}
    </EditAssetModalContext.Provider>
  );
};

export default EditAssetModalProvider;
