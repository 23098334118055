import { ClientPublicService } from '../../app/api/ClientPublicService';

import { IUnifiedExpenseDto } from '../../components/EditExpenseComponent/types';
import { IUnifiedIncomeDto } from '../../components/EditIncomeComponent/types';

const getExpenseFormItem = (item?: ClientPublicService.ExpenseSubmissionItemPublicDto) =>
  ({
    id: item?.id || undefined,
    expenseTypeId: item?.expenseType?.id || undefined,
    expenseType: item?.expenseType || undefined,
    expenseDescriptionId: item?.expenseDescription?.id || undefined,
    expenseDescription: item?.expenseDescription || undefined,
    specifiedDescription: item?.otherDescription || '',
    applicantAmount: item?.amountApplicant || undefined,
    spouseAmount: item?.amountSpouse || undefined,
    otherHouseholdMemberAmount: item?.amountOther || undefined,
    isMissingDocuments: item?.isMissingDocuments || undefined,
  } as IUnifiedExpenseDto);

const getIncomeFormItem = (item?: ClientPublicService.IncomeSubmissionItemPublicDto) =>
  ({
    id: item?.id || undefined,
    incomeTypeId: item?.incomeType?.id || undefined,
    incomeType: item?.incomeType || undefined,
    description: item?.description || '',
    applicantAmount: item?.amountApplicant || undefined,
    spouseAmount: item?.amountSpouse || undefined,
    otherHouseholdMemberAmount: item?.amountOther || undefined,
    isMissingDocuments: item?.isMissingDocuments || undefined,
  } as IUnifiedIncomeDto);

const convertIncomeFormItemToSaveDto = (formValue?: IUnifiedIncomeDto) =>
  ({
    id: undefined,
    incomeType: formValue?.incomeType,
    description: formValue?.description,
    amountApplicant: formValue?.applicantAmount || 0,
    amountSpouse: formValue?.spouseAmount || 0,
    amountOther: formValue?.otherHouseholdMemberAmount || 0,
    isMissingDocuments: formValue?.isMissingDocuments,
  } as ClientPublicService.IncomeSubmissionItemPublicDto);

const convertIncomeFormItemToCreateDto = (formIncomeValue?: ClientPublicService.IncomeSubmissionItemPublicDto) =>
  ({
    incomeTypeId: formIncomeValue?.incomeType?.id,
    description: formIncomeValue?.description || '',
    amountApplicant: formIncomeValue?.amountApplicant || 0,
    amountSpouse: formIncomeValue?.amountSpouse || 0,
    amountOther: formIncomeValue?.amountOther || 0,
  } as ClientPublicService.IIncomeSubmissionItemCreateDto);

const convertExpenseFormItemToSaveDto = (formValue?: IUnifiedExpenseDto) =>
  ({
    id: undefined,
    expenseType: formValue?.expenseType,
    expenseDescription: formValue?.expenseDescription,
    otherDescription: formValue?.specifiedDescription || '',
    amountApplicant: formValue?.applicantAmount || 0,
    amountSpouse: formValue?.spouseAmount || 0,
    amountOther: formValue?.otherHouseholdMemberAmount || 0,
    isMissingDocuments: formValue?.isMissingDocuments,
  } as ClientPublicService.ExpenseSubmissionItemPublicDto);

const convertExpenseFormItemToCreateDto = (formExpenseValue?: ClientPublicService.ExpenseSubmissionItemPublicDto) =>
  ({
    expenseTypeId: formExpenseValue?.expenseType?.id,
    expenseDescriptionId: formExpenseValue?.expenseDescription?.id,
    otherDescription: formExpenseValue?.otherDescription || '',
    amountApplicant: formExpenseValue?.amountApplicant || 0,
    amountSpouse: formExpenseValue?.amountSpouse || 0,
    amountOther: formExpenseValue?.amountOther || 0,
  } as ClientPublicService.IExpenseSubmissionItemCreateDto);

const getAmountByExpense = (item?: ClientPublicService.ExpenseSubmissionItemPublicDto) =>
  (item?.amountApplicant || 0) + (item?.amountSpouse || 0) + (item?.amountOther || 0);

const getAmountByIncome = (item?: ClientPublicService.IncomeSubmissionItemPublicDto) =>
  (item?.amountApplicant || 0) + (item?.amountSpouse || 0) + (item?.amountOther || 0);

const checkIfEditable = (report?: ClientPublicService.IncomeExpenseSubmissionPublicDto) => !Boolean(report?.id);

const convertPreviousSubmissionToInitial = (report?: ClientPublicService.IncomeExpenseSubmissionPublicDto) =>
  ({
    ...report,
    id: undefined,
    incomeItems: report?.incomeItems?.map((item) => ({ ...item, id: undefined, isInitial: true })),
    expenseItems: report?.expenseItems?.map((item) => ({ ...item, id: undefined, isInitial: true })),
  } as ClientPublicService.IncomeExpenseSubmissionPublicDto);

const convertFormValuesToCreateDto = (report: ClientPublicService.IncomeExpenseSubmissionPublicDto) => ({
  numberOfHousehold: report.numberOfHouseHold,
  documentIds: report.documentIds,
  incomeItems: report.incomeItems?.map((item) => convertIncomeFormItemToCreateDto(item)),
  expenseItems: report.expenseItems?.map((item) => convertExpenseFormItemToCreateDto(item)),
  externalIeSubmissionPeriodId: report.externalIeSubmissionPeriodId,
});

export {
  getExpenseFormItem,
  getIncomeFormItem,
  convertIncomeFormItemToSaveDto,
  convertExpenseFormItemToSaveDto,
  getAmountByExpense,
  getAmountByIncome,
  checkIfEditable,
  convertPreviousSubmissionToInitial,
  convertFormValuesToCreateDto,
};
