import { CircularProgress, useTheme, Stack } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';

import ConfirmationModal from '../../../components/ConfirmationModal';

import useLocale from '../../../app/hooks/useLocale';
import { useMemo } from 'react';

interface IUnsavedAppFormChangesModalProps {
  onLeave: () => void;
  onSaveAndLeave: () => void;
}

const UnsavedAppFormChangesModal = ({ onLeave, onSaveAndLeave }: IUnsavedAppFormChangesModalProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const mutatingCount = useIsMutating();

  const loading = useMemo(() => Boolean(mutatingCount), [mutatingCount]);

  const okButtonText = useMemo(() => {
    return loading ? (
      <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
        <CircularProgress sx={{ color: theme.palette.disabledGray }} size={24} />
        <span>{t.SAVING} ...</span>
      </Stack>
    ) : (
      t.SAVE_AND_LEAVE
    );
  }, [loading, t.SAVE_AND_LEAVE, t.SAVING, theme.palette.disabledGray]);

  return (
    <ConfirmationModal
      title={t.UNSAVED_CHANGED_TITLE}
      message={t.UNSAVED_CHANGED_MESSAGE}
      cancelButtonText={t.LEAVE}
      onCancel={onLeave}
      okButtonText={okButtonText}
      onOk={onSaveAndLeave}
      isOkButtonDisabled={loading}
    />
  );
};

export default UnsavedAppFormChangesModal;
