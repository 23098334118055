import { useMemo, useCallback, useEffect } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers, getIn } from 'formik';
import { Divider, Stack, Button, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  SelectField,
  TextField,
  CheckboxField,
  DatePickerField,
  YesNoToggleField,
  AutocompleteField,
} from '../../../../../components/FormFields';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';
import { LocationFields } from '../../../../../components/FormFieldsPresets';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { IFormValuesPersonalInfo } from '../types';

import {
  useAppFormPersonalInfoQuery,
  useEmploymentStatusesQuery,
  useIndustriesQuery,
  useMaritalStatusesQuery,
} from '../queries';
import { getEmploymentDetailsFormItem, getInsurancePeriodFormItem } from '../utils';
import { DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const Employment = () => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const { data: maritalStatuses } = useMaritalStatusesQuery() || {};

  const { data: personalInfo } = useAppFormPersonalInfoQuery(appFileData?.id) || {};

  const isMarried = useMemo(() => {
    const maritalStatusEnum = maritalStatuses?.find((status) => status.id === personalInfo?.maritalStatusId)?.enumValue;
    const isMarried =
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.Married ||
      maritalStatusEnum === ClientPublicService.MaritalStatusEnum.CommonLaw;
    return isMarried;
  }, [maritalStatuses, personalInfo?.maritalStatusId]);

  const { data: industries } = useIndustriesQuery() || {};
  const { data: employmentStatuses } = useEmploymentStatusesQuery() || {};

  const newEmploymentDetailItem = useMemo(() => getEmploymentDetailsFormItem(), []);
  const newInsurancePeriodItem = useMemo(() => getInsurancePeriodFormItem(), []);

  const handleIsReceivedEmploymentInsurancePastTwoYearsToggle = useCallback(
    (isReceivedEmploymentInsurancePastTwoYears?: boolean) => {
      const noApplicantInsurancePeriods =
        !values?.employmentInsurancePeriods || values?.employmentInsurancePeriods?.length === 0;
      if (isReceivedEmploymentInsurancePastTwoYears && noApplicantInsurancePeriods) {
        setFieldValue('employmentInsurancePeriods', [newInsurancePeriodItem]);
      }

      if (!isReceivedEmploymentInsurancePastTwoYears) {
        setFieldValue('employmentInsurancePeriods', []);
      }
    },
    [newInsurancePeriodItem, setFieldValue, values?.employmentInsurancePeriods]
  );

  const handleIsSpouseReceivedEmploymentInsurancePastTwoYearsToggle = useCallback(
    (isSpouseReceivedEmploymentInsurancePastTwoYears?: boolean) => {
      const noSpouseInsurancePeriods =
        !values?.spouseEmploymentInsurancePeriods || values?.spouseEmploymentInsurancePeriods?.length === 0;
      if (isSpouseReceivedEmploymentInsurancePastTwoYears && noSpouseInsurancePeriods) {
        setFieldValue('spouseEmploymentInsurancePeriods', [newInsurancePeriodItem]);
      }

      if (!isSpouseReceivedEmploymentInsurancePastTwoYears) {
        setFieldValue('spouseEmploymentInsurancePeriods', []);
      }
    },
    [newInsurancePeriodItem, setFieldValue, values?.spouseEmploymentInsurancePeriods]
  );

  useEffect(() => {
    if (!values.employmentDetails || values.employmentDetails.length === 0) {
      setFieldValue('employmentDetails', [newEmploymentDetailItem]);
    }

    if (!values?.spouseEmploymentDetails || values.spouseEmploymentDetails.length === 0) {
      setFieldValue('spouseEmploymentDetails', [newEmploymentDetailItem]);
    }
  }, [setFieldValue, newEmploymentDetailItem, values.employmentDetails, values.spouseEmploymentDetails]);

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>
      <FieldArray name="employmentDetails">
        {({ remove, insert }: ArrayHelpers) => (
          <>
            <Divider sx={{ mt: 2, mb: 1 }} />
            {values?.employmentDetails?.map((_, index: number) => (
              <Stack key={index}>
                <SelectField
                  label={t.EMPLOYMENT_STATUS}
                  name={`employmentDetails.${index}.employmentStatusId`}
                  options={employmentStatuses}
                  required
                  disabled={isLocked}
                />
                <TextField
                  name={`employmentDetails.${index}.employerName`}
                  label={t.EMPLOYER}
                  required
                  inputProps={{ maxLength: 100 }}
                  disabled={isLocked}
                />
                <AutocompleteField
                  label={t.INDUSTRY}
                  name={`employmentDetails.${index}.industryId`}
                  options={industries}
                  required
                  disabled={isLocked}
                />
                <TextField
                  label={t.OCCUPATION}
                  name={`employmentDetails.${index}.occupationName`}
                  required
                  inputProps={{ maxLength: 256 }}
                  disabled={isLocked}
                />
                <LocationFields
                  presetType="short"
                  addressFieldName={`employmentDetails.${index}.employerAddress`}
                  addressFieldLabel={t.EMPLOYER_ADDRESS}
                  unitFieldName={`employmentDetails.${index}.employerUnit`}
                  unitFieldLabel={t.EMPLOYER_ADDRESS_UNIT}
                  streetNameFieldName={`employmentDetails.${index}.employerStreetName`}
                  streetNumberFieldName={`employmentDetails.${index}.employerStreetNumber`}
                  cityFieldName={`employmentDetails.${index}.employerCity`}
                  provinceIdFieldName={`employmentDetails.${index}.employerProvinceId`}
                  countryIdFieldName={`employmentDetails.${index}.employerCountryId`}
                  postalCodeFieldName={`employmentDetails.${index}.employerPostalCode`}
                  disabledFields={isLocked ? 'all' : undefined}
                />
                <DatePickerField
                  name={`employmentDetails.${index}.startDate`}
                  label={t.START_DATE}
                  disableFuture
                  format={DATE_FORMAT}
                  disabled={isLocked}
                />
                <CheckboxField
                  name={`employmentDetails.${index}.isCurrentEmployer`}
                  label={t.CURRENTLY_EMPLOYED}
                  disabled={isLocked}
                />
                <DatePickerField
                  name={`employmentDetails.${index}.endDate`}
                  label={t.END_DATE}
                  disabled={Boolean(getIn(values, `employmentDetails.${index}.isCurrentEmployer`)) || isLocked}
                  disableFuture
                  format={DATE_FORMAT}
                />
                <IconButton
                  onClick={() => remove(index)}
                  disabled={values?.employmentDetails?.length === 1 || isLocked}
                  sx={{ mt: 2, alignSelf: 'start' }}
                  color="error"
                  aria-label="Delete"
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <Divider sx={{ mt: 2, mb: 1 }} />
              </Stack>
            ))}

            <Button
              variant="text"
              onClick={() => insert(values?.employmentDetails?.length || 0, newEmploymentDetailItem)}
              disabled={isLocked}
            >
              {t.ADD_EMPLOYMENT}
            </Button>
          </>
        )}
      </FieldArray>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <YesNoToggleField
        label={t.HAVE_RECIEVED_EI}
        required
        name="isReceivedEmploymentInsurancePastTwoYears"
        onChange={handleIsReceivedEmploymentInsurancePastTwoYearsToggle}
        disabled={isLocked}
      />

      {values?.isReceivedEmploymentInsurancePastTwoYears &&
        values?.employmentInsurancePeriods &&
        values?.employmentInsurancePeriods?.length > 0 && (
          <FieldArray name="employmentInsurancePeriods">
            {({ remove, insert }: ArrayHelpers) => (
              <>
                <Divider sx={{ mt: 2, mb: 1 }} />

                {values?.employmentInsurancePeriods?.map((_, index: number) => (
                  <Stack key={index}>
                    <DatePickerField
                      name={`employmentInsurancePeriods.${index}.startDate`}
                      label={t.START_DATE}
                      disableFuture
                      required
                      format={DATE_FORMAT}
                      disabled={isLocked}
                    />
                    <CheckboxField
                      name={`employmentInsurancePeriods.${index}.isCurrentPeriod`}
                      label={t.CURRENTLY_RECIEVING_EI}
                      disabled={isLocked}
                    />
                    <DatePickerField
                      name={`employmentInsurancePeriods.${index}.endDate`}
                      label={t.END_DATE}
                      disableFuture
                      disabled={
                        Boolean(getIn(values, `employmentInsurancePeriods.${index}.isCurrentPeriod`)) || isLocked
                      }
                      required={!Boolean(getIn(values, `employmentInsurancePeriods.${index}.isCurrentPeriod`))}
                      format={DATE_FORMAT}
                    />
                    <IconButton
                      onClick={() => remove(index)}
                      disabled={Boolean(values?.employmentInsurancePeriods?.length === 1) || isLocked}
                      sx={{ alignSelf: 'start' }}
                      color="error"
                      aria-label="Delete"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                  </Stack>
                ))}

                <Button
                  variant="text"
                  onClick={() => insert(values?.employmentInsurancePeriods?.length || 0, newInsurancePeriodItem)}
                  disabled={isLocked}
                >
                  {t.ADD_EI_PERIOD}
                </Button>
              </>
            )}
          </FieldArray>
        )}

      {isMarried && (
        <>
          <AppFormSectionSubtitle>{t.SPOUSE}</AppFormSectionSubtitle>
          <FieldArray name="spouseEmploymentDetails">
            {({ remove, insert }: ArrayHelpers) => (
              <>
                <Divider sx={{ mt: 2, mb: 1 }} />
                {values?.spouseEmploymentDetails?.map((_, index: number) => (
                  <Stack key={index}>
                    <SelectField
                      label={t.EMPLOYMENT_STATUS}
                      name={`spouseEmploymentDetails.${index}.employmentStatusId`}
                      options={employmentStatuses}
                      required={Boolean(isMarried)}
                      disabled={isLocked}
                    />
                    <TextField
                      name={`spouseEmploymentDetails.${index}.employerName`}
                      label={t.EMPLOYER}
                      required={Boolean(isMarried)}
                      inputProps={{ maxLength: 100 }}
                      disabled={isLocked}
                    />

                    <AutocompleteField
                      label={t.INDUSTRY}
                      name={`spouseEmploymentDetails.${index}.industryId`}
                      options={industries}
                      required={Boolean(isMarried)}
                      disabled={isLocked}
                    />

                    <TextField
                      label={t.OCCUPATION}
                      name={`spouseEmploymentDetails.${index}.occupationName`}
                      inputProps={{ maxLength: 256 }}
                      required={Boolean(isMarried)}
                      disabled={isLocked}
                    />

                    <LocationFields
                      presetType="short"
                      addressFieldName={`spouseEmploymentDetails.${index}.employerAddress`}
                      addressFieldLabel={t.EMPLOYER_ADDRESS}
                      unitFieldName={`spouseEmploymentDetails.${index}.employerUnit`}
                      unitFieldLabel={t.EMPLOYER_ADDRESS_UNIT}
                      streetNameFieldName={`spouseEmploymentDetails.${index}.employerStreetName`}
                      streetNumberFieldName={`spouseEmploymentDetails.${index}.employerStreetNumber`}
                      cityFieldName={`spouseEmploymentDetails.${index}.employerCity`}
                      provinceIdFieldName={`spouseEmploymentDetails.${index}.employerProvinceId`}
                      countryIdFieldName={`spouseEmploymentDetails.${index}.employerCountryId`}
                      postalCodeFieldName={`spouseEmploymentDetails.${index}.employerPostalCode`}
                      disabledFields={isLocked ? 'all' : undefined}
                    />
                    <DatePickerField
                      name={`spouseEmploymentDetails.${index}.startDate`}
                      label={t.START_DATE}
                      disableFuture
                      format={DATE_FORMAT}
                      disabled={isLocked}
                    />
                    <CheckboxField
                      name={`spouseEmploymentDetails.${index}.isCurrentEmployer`}
                      label={t.CURRENTLY_EMPLOYED}
                      disabled={isLocked}
                    />
                    <DatePickerField
                      name={`spouseEmploymentDetails.${index}.endDate`}
                      label={t.END_DATE}
                      disableFuture
                      disabled={
                        Boolean(getIn(values, `spouseEmploymentDetails.${index}.isCurrentEmployer`)) || isLocked
                      }
                      format={DATE_FORMAT}
                    />
                    <IconButton
                      onClick={() => remove(index)}
                      disabled={values?.spouseEmploymentDetails?.length === 1 || isLocked}
                      sx={{ alignSelf: 'start' }}
                      color="error"
                      aria-label="Delete"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                  </Stack>
                ))}

                <Button
                  variant="text"
                  onClick={() => insert(values?.spouseEmploymentDetails?.length || 0, newEmploymentDetailItem)}
                  disabled={isLocked}
                >
                  {t.ADD_EMPLOYMENT}
                </Button>
              </>
            )}
          </FieldArray>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <YesNoToggleField
            label={t.HAVE_RECIEVED_EI}
            required={Boolean(values?.isJointFile)}
            name="isSpouseReceivedEmploymentInsurancePastTwoYears"
            onClick={handleIsSpouseReceivedEmploymentInsurancePastTwoYearsToggle}
            disabled={isLocked}
          />

          {values?.isSpouseReceivedEmploymentInsurancePastTwoYears &&
            values?.spouseEmploymentInsurancePeriods &&
            values?.spouseEmploymentInsurancePeriods?.length > 0 && (
              <FieldArray name="spouseEmploymentInsurancePeriods">
                {({ remove, insert }: ArrayHelpers) => (
                  <>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    {values?.spouseEmploymentInsurancePeriods?.map((_, index: number) => (
                      <Stack key={index} alignItems="start">
                        <DatePickerField
                          name={`spouseEmploymentInsurancePeriods.${index}.startDate`}
                          label={t.START_DATE}
                          disableFuture
                          format={DATE_FORMAT}
                          disabled={isLocked}
                        />
                        <CheckboxField
                          name={`spouseEmploymentInsurancePeriods.${index}.isCurrentPeriod`}
                          label={t.CURRENTLY_RECIEVING_EI}
                          disabled={isLocked}
                        />
                        <DatePickerField
                          name={`spouseEmploymentInsurancePeriods.${index}.endDate`}
                          label={t.END_DATE}
                          disableFuture
                          disabled={
                            getIn(values, `spouseEmploymentInsurancePeriods.${index}.isCurrentPeriod`) || isLocked
                          }
                          format={DATE_FORMAT}
                        />
                        <IconButton
                          onClick={() => remove(index)}
                          disabled={values?.spouseEmploymentInsurancePeriods?.length === 1 || isLocked}
                          sx={{ mt: 2, alignSelf: 'start' }}
                          color="error"
                          aria-label="Delete"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                        <Divider sx={{ mt: 2, mb: 1 }} />
                      </Stack>
                    ))}

                    <Button
                      variant="text"
                      onClick={() =>
                        insert(values?.spouseEmploymentInsurancePeriods?.length || 0, newInsurancePeriodItem)
                      }
                      disabled={isLocked}
                    >
                      {t.ADD_EI_PERIOD}
                    </Button>
                  </>
                )}
              </FieldArray>
            )}
        </>
      )}
    </>
  );
};

export default Employment;
