import { useMemo, useContext } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';

import { SupportDocsUpload } from '../DocumentsUpload';

import useLocale from '../../app/hooks/useLocale';

import { EditExpenseContext } from './EditExpenseProvider';
import { IFormValues } from './types';
import { isExpenseFilled } from './utils';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const EditExpenseDocumentsTab = ({ ...props }: any) => {
  const { t } = useLocale();
  const { values } = useFormikContext<IFormValues>();
  const { isEditable } = useContext(EditExpenseContext);

  const isChildSupport = useMemo(
    () => isExpenseFilled(ClientPublicService.ExpenseDescriptionEnum.ChildSupportPayments, values),
    [values]
  );

  const isSpousalSupport = useMemo(
    () => isExpenseFilled(ClientPublicService.ExpenseDescriptionEnum.SpousalSupportPayments, values),
    [values]
  );

  const documentPrefixCodes = useMemo(() => {
    const codes = [];

    if (isChildSupport) {
      codes.push('Expense.ChildSupportPayments');
    }

    if (isSpousalSupport) {
      codes.push('Expense.SpousalSupportPayments');
    }

    return codes;
  }, [isChildSupport, isSpousalSupport]);

  const tooltip = useMemo(() => {
    const tooltipArray = [];

    if (isChildSupport) {
      tooltipArray.push(t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED);
    }

    if (isSpousalSupport) {
      tooltipArray.push(t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED);
    }

    return tooltipArray?.length ? tooltipArray : undefined;
  }, [
    isChildSupport,
    isSpousalSupport,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED,
  ]);

  return (
    <Box data-testid="expense-documents">
      <SupportDocsUpload
        {...props}
        tooltip={tooltip}
        documentFolderCode="surplus-ie"
        documentPrefixCodes={documentPrefixCodes}
        disabled={!isEditable}
      />
    </Box>
  );
};

export default EditExpenseDocumentsTab;
