import { Typography } from '@mui/material';

import ElevatedContainer from '../../../components/ElevatedContainer';
import NoAppointmentsCard from '../../../components/NoAppointmentsCard/NoAppointmentsCard';
import AppointmenDetails from '../../../components/AppointmentDetails/AppointmentDetails';

import useLocale from '../../../app/hooks/useLocale';

import { useUpcomingAppointmentsQuery } from '../queries';

const UpcomingList = () => {
  const { t } = useLocale();

  const { data } = useUpcomingAppointmentsQuery({}) || {};

  if (!data || !data?.items) return null;

  return (
    <>
      <Typography variant="body3" fontWeight={600} pb={2.5} color="secondary">
        {t.UPCOMING}
      </Typography>
      {data?.items && !data.items?.length ? (
        <ElevatedContainer sx={{ borderRadius: 1.5, pt: 3, pb: 3, mb: 2.5, maxWidth: 528 }}>
          <NoAppointmentsCard />
        </ElevatedContainer>
      ) : (
        data?.items?.map((item) => (
          <ElevatedContainer sx={{ borderRadius: 1.5, pt: 3, pb: 3, mb: 2.5, maxWidth: 528 }} key={item?.id}>
            <AppointmenDetails {...item} />
          </ElevatedContainer>
        ))
      )}
    </>
  );
};

export default UpcomingList;
