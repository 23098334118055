import { useOutletContext } from 'react-router-dom';

import { IAppointmentDetailsUpdate, IUserLocation } from '../types';

export interface IBookAppointmentContextProps {
  userLocation?: IUserLocation;
  setUserLocation?: (value?: IUserLocation) => void;
  appointmentDetails?: IAppointmentDetailsUpdate;
  resetAppointmentDetails?: (value?: IAppointmentDetailsUpdate) => void;
  updateAppointmentDetails?: (value?: IAppointmentDetailsUpdate) => void;
  isConfirmed?: boolean;
  setIsConfirmed?: (value?: boolean) => void;
}

const useBookAppointment = () => useOutletContext<IBookAppointmentContextProps>();

export default useBookAppointment;
