import { useContext } from 'react';
import { Stack, Typography, Button, useTheme, Skeleton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import useLocale from '../../../app/hooks/useLocale';

import { ROUTES } from '../../../app/routes';
import { useSupportingDocumentsCountQuery, useDocumentsCountQuery } from '../queries';
import { DashboardContext } from '../DashboardProvider';

const MyDocumentsCard = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const { debtorProfile, applicationFile } = useContext(DashboardContext);

  const { data: outstandingCount } = useSupportingDocumentsCountQuery() || {};
  const { data: uploadedCount } = useDocumentsCountQuery(applicationFile?.id) || {};

  if (!debtorProfile?.profile?.isFileStagePostSignup) {
    return (
      <>
        <Stack justifyContent="space-between" flex={1}>
          <Box>
            <Typography variant="h6" fontWeight={600} aria-label={t.MY_DOCUMENTS}>
              {t.MY_DOCUMENTS}
            </Typography>

            <Stack flexDirection="row" columnGap={0.75} alignItems="center">
              {Boolean(outstandingCount) && <ErrorIcon sx={{ color: theme.palette.urgentRed }} />}
              <Typography variant="body2" fontWeight={600} color="secondary" flex={1}>
                {outstandingCount === undefined ? (
                  <Skeleton width="60%" />
                ) : outstandingCount === 0 ? (
                  t.NO_UPLOADS_OUTSTANDING
                ) : (
                  t.N_UPLOADS_OUTSTANDING?.replace('{0}', String(outstandingCount))
                )}
              </Typography>
            </Stack>
          </Box>

          <Button
            variant="outlined"
            sx={{ alignSelf: 'center', mt: 3, width: 200 }}
            onClick={() => navigate(ROUTES.DOCUMENTS)}
          >
            {t.UPLOAD_DOCUMENTS}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Stack justifyContent="space-between" flex={1}>
        <Box>
          <Typography variant="h6" fontWeight={600} aria-label={t.MY_DOCUMENTS}>
            {t.MY_DOCUMENTS}
          </Typography>

          <Typography variant="body2" fontWeight={600} color="secondary">
            {uploadedCount !== undefined ? (
              t.N_FILES?.replace('{0}', String(uploadedCount || 0))
            ) : (
              <Skeleton width="60%" />
            )}
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{ alignSelf: 'center', width: 200, mt: 3 }}
          onClick={() => navigate(ROUTES.DOCUMENTS)}
        >
          {t.VIEW_ALL}
        </Button>
      </Stack>
    </>
  );
};

export default MyDocumentsCard;
