import { Stack, Typography, useTheme } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import ElevatedContainer from '../../../components/ElevatedContainer';

import useLocale from '../../../app/hooks/useLocale';
import moment, { DATE_FORMAT2, getDateNTimeunitsFromToday, disableDateAfter } from '../../../app/utils/dateTimeHelpers';

type IProps = {
  value: moment.Moment | null;
  onChange: (date: moment.Moment | null) => void;
};

const CustomDay = (props: PickersDayProps<moment.Moment>) => {
  const theme = useTheme();

  return (
    <PickersDay
      {...props}
      today={false}
      sx={{
        '&.MuiPickersDay-root:not(.Mui-selected)': {
          border: moment(props?.day?.format(DATE_FORMAT2)).isSame(moment(moment().format(DATE_FORMAT2)), 'd')
            ? `1px solid ${theme.palette.cobalt} !important`
            : 'transparent',
        },
      }}
    />
  );
};

const DateCalendarComponent = ({ value, onChange }: IProps) => {
  const { t } = useLocale();

  if (!value) return <></>;

  return (
    <Stack alignItems="center">
      <Typography variant="body1" fontWeight={500} textAlign="center" sx={{ pt: 2, pb: 1.5 }}>
        {t.SELECT_DATE}
      </Typography>
      <ElevatedContainer>
        <DateCalendar
          showDaysOutsideCurrentMonth
          fixedWeekNumber={6}
          shouldDisableDate={(date) =>
            moment(date?.format(DATE_FORMAT2))?.isBefore(moment(moment().format(DATE_FORMAT2)), 'day') ||
            disableDateAfter(date, getDateNTimeunitsFromToday(2, 'months'))
          }
          onChange={onChange}
          value={value}
          data-testid="calendar"
          views={['day']}
          slots={{
            day: CustomDay,
          }}
        />
      </ElevatedContainer>
    </Stack>
  );
};

export default DateCalendarComponent;
