import { createContext, useState } from 'react';

interface IPersonalInfoContext {
  id?: string;
  setId: (id?: string) => void;
  expanded?: string | false;
  setExpanded: (expanded: string | false) => void;
  isMarried?: boolean;
  setIsMarried: (isMarried: boolean) => void;
  showErrorsList: boolean;
  setShowErrorsList: (showErrorsList: boolean) => void;
}

export const initialPersonalInfoContextValues: IPersonalInfoContext = {
  id: undefined,
  setId: () => {},
  expanded: false,
  setExpanded: () => {},
  isMarried: false,
  setIsMarried: () => {},
  showErrorsList: false,
  setShowErrorsList: () => {},
};

export const PersonalInfoContext = createContext<IPersonalInfoContext>(initialPersonalInfoContextValues);

interface IProps {
  children: React.ReactElement;
}

const PersonalInfoProvider = ({ children }: IProps): React.ReactElement => {
  const [id, setId] = useState<string>();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isMarried, setIsMarried] = useState<boolean>(false);
  const [showErrorsList, setShowErrorsList] = useState<boolean>(false);

  return (
    <PersonalInfoContext.Provider
      value={{
        id,
        setId,
        expanded,
        setExpanded,
        isMarried,
        setIsMarried,
        showErrorsList,
        setShowErrorsList,
      }}
    >
      {children}
    </PersonalInfoContext.Provider>
  );
};

export default PersonalInfoProvider;
