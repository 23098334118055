import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useFormikContext, getIn } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';

import { TextField, AutocompleteField } from '../../FormFields';
import GoogleMapsLoaderWrapper from '../../GoogleMapsLoaderWrapper/GoogleMapsLoaderWrapper';
import AutofillSuggestions from './AutofillSuggestions';

import useLocale from '../../../app/hooks/useLocale';
import useUnsavedForm from '../../../features/AppForm/hooks/useUnsavedForm';

import { useCountriesQuery, useCanadaProvincesQuery, useUsaProvincesQuery } from './queries';
import { CANADA_COUNTRY_CODE, USA_COUNTRY_CODE } from './constants';
import { ILocationDto } from './types';
import { getLocation, getShortAddressLine } from './utils';
import { sortCountriesWithDefaultsOnTop } from '../../../app/utils/helpers';

interface IFormValues {
  [key: string]: any;
}

interface IAddressExtendedProps {
  addressFieldName: string;
  addressFieldLabel?: string;
  streetNumberFieldName: string;
  streetNameFieldName: string;
  unitFieldName: string;
  unitFieldLabel?: string;
  cityFieldName: string;
  cityFieldLabel?: string;
  provinceIdFieldName: string;
  provinceIdFieldLabel?: string;
  postalCodeFieldName: string;
  postalCodeFieldLabel?: string;
  countryIdFieldName: string;
  countryIdFieldLabel?: string;
  requiredFields?: string[];
  disabledFields?: string[] | 'all';
  presetType?: 'extended' | 'short';
  onLocationChange?: (location?: ILocationDto) => void;
}

const ExtendedAddressFields = ({
  addressFieldName,
  addressFieldLabel,
  streetNumberFieldName,
  streetNameFieldName,
  unitFieldName,
  unitFieldLabel,
  cityFieldName,
  cityFieldLabel,
  provinceIdFieldName,
  provinceIdFieldLabel,
  postalCodeFieldName,
  postalCodeFieldLabel,
  countryIdFieldName,
  countryIdFieldLabel,
  requiredFields,
  disabledFields,
  presetType = 'extended',
  onLocationChange,
}: IAddressExtendedProps) => {
  const { t } = useLocale();

  const { updateExclusionArray } = useUnsavedForm();
  const { values, setFieldValue, touched, validateOnBlur, validateOnChange, validateForm, setFieldTouched } =
    useFormikContext<IFormValues>();
  const [placesInput, setPlacesInput] = useState<string>('');

  const { data: countriesUnsorted } = useCountriesQuery() || {};

  const usaCountryId = useMemo(
    () => countriesUnsorted?.find((item) => item.code === USA_COUNTRY_CODE)?.id,
    [countriesUnsorted]
  );

  const canadaCountryId = useMemo(
    () => countriesUnsorted?.find((item) => item.code === CANADA_COUNTRY_CODE)?.id,
    [countriesUnsorted]
  );

  const isCountryUsaOrCanada = useMemo(
    () => getIn(values, countryIdFieldName) === canadaCountryId || getIn(values, countryIdFieldName) === usaCountryId,
    [canadaCountryId, countryIdFieldName, usaCountryId, values]
  );

  const countries = useMemo(() => sortCountriesWithDefaultsOnTop(countriesUnsorted), [countriesUnsorted]);

  const { data: canadaProvinces } = useCanadaProvincesQuery(canadaCountryId) || {};
  const { data: usaProvinces } = useUsaProvincesQuery(usaCountryId) || {};

  const provinces = useMemo(
    () => [...(canadaProvinces || []), ...(usaProvinces || [])],
    [canadaProvinces, usaProvinces]
  );

  const shouldValidate = useMemo(() => validateOnBlur || validateOnChange, [validateOnBlur, validateOnChange]);

  const handleSelect = useCallback(
    async (address?: string) => {
      if (!address) return;

      const result = await geocodeByAddress(address);

      if (result && result[0]) {
        const location = getLocation(result[0], provinces, countries);
        setFieldValue(addressFieldName, location?.address, shouldValidate);
        setFieldValue(streetNameFieldName, location?.streetName, shouldValidate);
        setFieldValue(streetNumberFieldName, location?.streetNumber, shouldValidate);
        setFieldValue(cityFieldName, location?.city, shouldValidate);
        setFieldValue(provinceIdFieldName, location?.provinceId, shouldValidate);
        setFieldValue(countryIdFieldName, location?.countryId, shouldValidate);
        setFieldValue(postalCodeFieldName, location?.postalCode, shouldValidate);

        onLocationChange?.(location);
      }
    },
    [
      provinces,
      countries,
      setFieldValue,
      addressFieldName,
      streetNameFieldName,
      streetNumberFieldName,
      cityFieldName,
      provinceIdFieldName,
      countryIdFieldName,
      postalCodeFieldName,
      onLocationChange,
      shouldValidate,
    ]
  );

  useEffect(() => {
    if (!values?.[countryIdFieldName] && !touched[countryIdFieldName] && countries?.length && presetType !== 'short') {
      setFieldValue(
        countryIdFieldName,
        countries?.find((item) => item?.code === CANADA_COUNTRY_CODE)?.id,
        shouldValidate
      );
      updateExclusionArray?.([countryIdFieldName]);
    }
  }, [countries, countryIdFieldName, presetType, setFieldValue, shouldValidate, touched, updateExclusionArray, values]);

  useEffect(() => {
    const value =
      presetType === 'extended'
        ? getShortAddressLine(getIn(values, streetNameFieldName), getIn(values, streetNumberFieldName))
        : getIn(values, addressFieldName);

    setPlacesInput(value);

    if (shouldValidate) validateForm();
  }, [addressFieldName, presetType, shouldValidate, streetNameFieldName, streetNumberFieldName, validateForm, values]);

  return (
    <Stack>
      <PlacesAutocomplete
        value={placesInput || ''}
        onChange={setPlacesInput}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions:
            presetType !== 'short'
              ? {
                  country:
                    countries?.find((item) => item?.id === values?.[countryIdFieldName])?.code?.toLowerCase() || 'ca',
                }
              : undefined,
          types: ['address'],
        }}
      >
        {({ getInputProps, ...rest }) => (
          <Stack>
            <TextField
              {...getInputProps()}
              isFastField={false}
              name={addressFieldName}
              label={addressFieldLabel || t.ADDRESS}
              required={requiredFields?.includes(addressFieldName)}
              disabled={disabledFields === 'all' || disabledFields?.includes(addressFieldName)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{
                      visibility: values?.[addressFieldName] ? 'visible' : 'hidden',
                      p: 0,
                    }}
                    onClick={() => {
                      setFieldValue(addressFieldName, '', shouldValidate);
                      setFieldValue(streetNameFieldName, '', shouldValidate);
                      setFieldValue(streetNumberFieldName, '', shouldValidate);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              InputLabelProps={{ htmlFor: 'location-input' }}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onBlur={() => {
                setFieldTouched(addressFieldName, true, shouldValidate);
                if (!placesInput) {
                  setFieldValue(addressFieldName, '', shouldValidate);
                  setFieldValue(streetNameFieldName, '', shouldValidate);
                  setFieldValue(streetNumberFieldName, '', shouldValidate);
                }
              }}
              inputProps={{ maxLength: 500, id: 'location-input' }}
            />
            <AutofillSuggestions {...rest} />
          </Stack>
        )}
      </PlacesAutocomplete>
      <TextField
        name={unitFieldName}
        label={unitFieldLabel || t.UNIT}
        required={requiredFields?.includes(unitFieldName)}
        disabled={disabledFields === 'all' || disabledFields?.includes(unitFieldName)}
        data-testid="unit"
        inputProps={{ maxLength: 100 }}
      />

      {presetType === 'extended' && (
        <>
          <TextField
            name={cityFieldName}
            label={cityFieldLabel || t.CITY}
            required={requiredFields?.includes(cityFieldName)}
            disabled={disabledFields === 'all' || disabledFields?.includes(cityFieldName)}
            data-testid="city"
          />
          <AutocompleteField
            name={provinceIdFieldName}
            label={provinceIdFieldLabel || t.PROVINCE}
            options={getIn(values, countryIdFieldName) === canadaCountryId ? canadaProvinces : usaProvinces}
            required={requiredFields?.includes(provinceIdFieldName) && isCountryUsaOrCanada}
            disabled={
              disabledFields === 'all' || disabledFields?.includes(provinceIdFieldName) || !isCountryUsaOrCanada
            }
            data-testid="provinceId"
          />
          <TextField
            name={postalCodeFieldName}
            label={postalCodeFieldLabel || t.POSTAL_CODE}
            required={requiredFields?.includes(postalCodeFieldName) && isCountryUsaOrCanada}
            disabled={disabledFields === 'all' || disabledFields?.includes(postalCodeFieldName)}
            data-testid="postalCode"
          />
          <AutocompleteField
            name={countryIdFieldName}
            label={countryIdFieldLabel || t.COUNTRY}
            required={requiredFields?.includes(countryIdFieldName)}
            options={countries}
            disabled={disabledFields === 'all' || disabledFields?.includes(countryIdFieldName)}
            onChange={() => {
              setFieldValue(cityFieldName, '', shouldValidate);
              setFieldValue(postalCodeFieldName, '', shouldValidate);
              setFieldValue(streetNameFieldName, '', shouldValidate);
              setFieldValue(streetNumberFieldName, '', shouldValidate);
              setFieldValue(unitFieldName, '', shouldValidate);
              setFieldValue(addressFieldName, '', shouldValidate);
              setFieldValue(provinceIdFieldName, '', shouldValidate);
            }}
            data-testid="countryId"
          />
        </>
      )}
    </Stack>
  );
};

const AddressExtended = (props: IAddressExtendedProps) => (
  <GoogleMapsLoaderWrapper component={<ExtendedAddressFields {...props} />} />
);

export default AddressExtended;
