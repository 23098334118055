import { useState, useEffect } from 'react';
import { Typography, Stack, useTheme, Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import PageContentContainer from '../../components/PageContentContainer';
import NotificationCard from './NotificationCard';

import useLocale from '../../app/hooks/useLocale';

import {
  useNewNotificationsQuery,
  usePreviousNotificationsMutation,
  useMarkNotificationsAsReadMutation,
} from './queries';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const DEFAULT_PAGE_SIZE = 10;

const Notifications = () => {
  const { t } = useLocale();
  const theme = useTheme();

  const [skipCount, setSkipCount] = useState(0);
  const [previousNotifications, setPreviousNotifications] = useState<ClientPublicService.NotificationDto[]>();
  const [previousNotificationsTotalCount, setPreviousNotificationsTotalCount] = useState<number>(0);

  const { data: newNotifications } = useNewNotificationsQuery() || {};

  const { mutateAsync: loadPreviousNotifications } = usePreviousNotificationsMutation() || {};

  const { mutate: markNotificationsAsRead } = useMarkNotificationsAsReadMutation() || {};

  useEffect(() => {
    loadPreviousNotifications(
      {
        isUnread: false,
        sorting: undefined,
        skipCount,
        maxResultCount: DEFAULT_PAGE_SIZE,
      },
      {
        onSuccess: (data) => {
          setPreviousNotifications((prev) => [...(prev || []), ...(data?.items || [])]);
          setPreviousNotificationsTotalCount(data?.totalCount || 0);
        },
      }
    );
  }, [loadPreviousNotifications, skipCount]);

  useEffect(() => {
    return () => {
      markNotificationsAsRead();
    };
  }, [markNotificationsAsRead]);

  return (
    <PageContentContainer headerProps={{ title: 'Notifications' }}>
      <Stack>
        <Typography variant="body3" sx={{ pt: 1.5 }} fontWeight={600} color="secondary">
          {newNotifications?.items?.length ? t.NEW : t.NO_NEW_NOTIFICATIONS}
        </Typography>
        <>
          {newNotifications?.items?.map((notification, index) => (
            <NotificationCard key={index} {...(notification as any)} />
          ))}
        </>
        {Boolean(previousNotificationsTotalCount) && (
          <>
            <Typography variant="body3" sx={{ pt: 2.5 }} fontWeight={600} color="secondary">
              {t.PREVIOUS}
            </Typography>
            <>
              {previousNotifications?.map((notification, index) => (
                <NotificationCard key={index} {...(notification as any)} />
              ))}

              {previousNotificationsTotalCount > (previousNotifications?.length || 0) ? (
                <Button
                  variant="text"
                  onClick={() => setSkipCount(skipCount + DEFAULT_PAGE_SIZE)}
                  sx={{
                    p: 0,
                    color: theme.palette.secondary.main,
                    '&.MuiButton-root:hover:not(.Mui-disabled)': { color: theme.palette.secondary.main },
                  }}
                >
                  <Stack alignItems="center" flexDirection="row" spacing={1}>
                    {<KeyboardArrowDown />}
                    {t.SHOW_MORE}
                  </Stack>
                </Button>
              ) : (
                <></>
              )}
            </>
          </>
        )}
      </Stack>
    </PageContentContainer>
  );
};

export default Notifications;
