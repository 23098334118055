import ToggleGroupField from './ToggleGroupField';

import useLocale from '../../app/hooks/useLocale';

const YesNoToggleField = (props: any) => {
  const { t } = useLocale();

  return (
    <ToggleGroupField
      {...props}
      exclusive
      data={[
        {
          value: true,
          label: t.YES,
        },
        {
          value: false,
          label: t.NO,
        },
      ]}
    />
  );
};

export default YesNoToggleField;
