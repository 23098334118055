import { useQuery } from '@tanstack/react-query';
import { GOOGLE_API_QUERY_KEY } from '../../app/constants/reactQueryKeys';

import API from '../../app/api/api';

export const useGoogleApiQuery = () =>
  useQuery({
    queryKey: [GOOGLE_API_QUERY_KEY],
    queryFn: async () => {
      return await API.getGoogleApiKey();
    },
    refetchOnWindowFocus: false,
  });
