import { useContext, useMemo, useEffect } from 'react';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Stack, Divider, Box, IconButton } from '@mui/material';

import { TextField, CheckboxField, DatePickerField } from '../../../../../components/FormFields';
import { LocationFields, PhoneTypeNumberFields } from '../../../../../components/FormFieldsPresets';
import AppFormSectionSubtitle from '../../../components/AppFormSectionSubtitle';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { PersonalInfoContext } from '../PersonalInformationProvider';
import { IFormValuesPersonalInfo, IFormValuesPreviousAddress } from '../types';
import { getDifference, getToday, DATE_FORMAT } from '../../../../../app/utils/dateTimeHelpers';
import { getPreviousAddressFormItem } from '../utils';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const ContactInformation = () => {
  const { t } = useLocale();

  const { isMarried } = useContext(PersonalInfoContext);
  const { values, setFieldValue } = useFormikContext<IFormValuesPersonalInfo>();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.personalInfoStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.personalInfoStatus]
  );

  const isLessThan2YearsAtCurrentAddress = useMemo(
    () => values?.residenceDate && getDifference(getToday(), values?.residenceDate, 'years') < 2,
    [values?.residenceDate]
  );

  const newPreviousAddressItem = useMemo(() => getPreviousAddressFormItem(), []);

  useEffect(() => {
    if (values?.isSpouseAddressSame) {
      setFieldValue('spouseAddress', values?.address);
      setFieldValue('spouseStreetName', values?.streetName);
      setFieldValue('spouseStreetNumber', values?.streetNumber);
      setFieldValue('spouseUnit', values?.unit);
      setFieldValue('spouseCity', values?.city);
      setFieldValue('spouseProvinceId', values?.provinceId);
      setFieldValue('spouseCountryId', values?.countryId);
      setFieldValue('spousePostalCode', values?.postalCode);
      setFieldValue('spouseMainPhoneTypeId', values?.mainPhoneTypeId);
      setFieldValue('spouseMainPhoneNumber', values?.mainPhoneNumber);
    }
  }, [
    setFieldValue,
    values?.address,
    values?.city,
    values?.countryId,
    values?.isSpouseAddressSame,
    values?.mainPhoneNumber,
    values?.mainPhoneTypeId,
    values?.postalCode,
    values?.provinceId,
    values?.streetName,
    values?.streetNumber,
    values?.unit,
  ]);

  return (
    <>
      <AppFormSectionSubtitle>{t.APPLICANT}</AppFormSectionSubtitle>

      <LocationFields
        addressFieldName="address"
        streetNameFieldName="streetName"
        streetNumberFieldName="streetNumber"
        unitFieldName="unit"
        cityFieldName="city"
        provinceIdFieldName="provinceId"
        countryIdFieldName="countryId"
        postalCodeFieldName="postalCode"
        requiredFields={['address', 'city', 'provinceId', 'countryId', 'postalCode']}
        disabledFields={isLocked ? 'all' : undefined}
      />

      <DatePickerField
        name="residenceDate"
        label={t.AT_THIS_ADDRESS_SINCE}
        data-testid="residenceDate"
        required
        disableFuture
        format={DATE_FORMAT}
        disabled={isLocked}
      />

      {isLessThan2YearsAtCurrentAddress && (
        <Box data-testid="previous-addresses">
          <FieldArray name="previousAddresses">
            {({ remove, insert }: ArrayHelpers) => (
              <>
                {values?.previousAddresses && values?.previousAddresses?.length > 0 && (
                  <>
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    {values?.previousAddresses?.map((_: IFormValuesPreviousAddress, index: number) => (
                      <Stack key={index}>
                        <LocationFields
                          presetType="short"
                          addressFieldName={`previousAddresses.${index}.address`}
                          addressFieldLabel={t.PREVIOUS_ADDRESS}
                          unitFieldName={`previousAddresses.${index}.unit`}
                          unitFieldLabel={t.PREVIOUS_ADDRESS_UNIT}
                          streetNameFieldName={`previousAddresses.${index}.streetName`}
                          streetNumberFieldName={`previousAddresses.${index}.streetNumber`}
                          cityFieldName={`previousAddresses.${index}.city`}
                          provinceIdFieldName={`previousAddresses.${index}.provinceId`}
                          countryIdFieldName={`previousAddresses.${index}.countryId`}
                          postalCodeFieldName={`previousAddresses.${index}.postalCode`}
                          disabledFields={isLocked ? 'all' : undefined}
                        />
                        <IconButton
                          onClick={() => remove(index)}
                          color="error"
                          sx={{ alignSelf: 'flex-start' }}
                          disabled={isLocked}
                          aria-label="Delete"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>

                        <Divider sx={{ mt: 2, mb: 1 }} />
                      </Stack>
                    ))}
                  </>
                )}
                <Button
                  variant="text"
                  onClick={() => insert(values?.previousAddresses?.length || 0, newPreviousAddressItem)}
                  disabled={isLocked}
                >
                  {t.ADD_PREVIOUS_ADDRESS}
                </Button>
              </>
            )}
          </FieldArray>
        </Box>
      )}

      <Divider sx={{ mt: 2, mb: 1 }} />
      <PhoneTypeNumberFields
        phoneTypeFieldName="mainPhoneTypeId"
        phoneTypeFieldProps={{ required: true, disabled: isLocked }}
        phoneNumberFieldName="mainPhoneNumber"
        phoneNumberFieldProps={{ required: true, disabled: isLocked }}
      />

      <TextField name="emailAddress" label={t.EMAIL} data-testid="emailAddress" recommended disabled />

      {isMarried && (
        <>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <AppFormSectionSubtitle>{t.SPOUSE_PARTNER}</AppFormSectionSubtitle>
          <CheckboxField
            name="isSpouseAddressSame"
            label={t.SAME_AS_APPLICANTS_CONTACT_INFORMATION}
            data-testid="isSpouseAddressSame"
            disabled={isLocked}
          />
          <LocationFields
            addressFieldName="spouseAddress"
            streetNameFieldName="spouseStreetName"
            streetNumberFieldName="spouseStreetNumber"
            unitFieldName="spouseUnit"
            cityFieldName="spouseCity"
            provinceIdFieldName="spouseProvinceId"
            countryIdFieldName="spouseCountryId"
            postalCodeFieldName="spousePostalCode"
            disabledFields={values?.isSpouseAddressSame || isLocked ? 'all' : undefined}
          />
          <DatePickerField
            name="spouseResidenceDate"
            label={t.AT_THIS_ADDRESS_SINCE}
            data-testid="spouseResidenceDate"
            disableFuture
            format={DATE_FORMAT}
            disabled={isLocked}
          />
          <PhoneTypeNumberFields
            phoneTypeFieldName="spouseMainPhoneTypeId"
            phoneTypeFieldProps={{ disabled: Boolean(values?.isSpouseAddressSame) || isLocked }}
            phoneNumberFieldName="spouseMainPhoneNumber"
            phoneNumberFieldProps={{ disabled: Boolean(values?.isSpouseAddressSame) || isLocked }}
          />
          <TextField name="spouseEmailAddress" label={t.EMAIL} data-testid="spouseEmailAddress" disabled={isLocked} />
        </>
      )}
    </>
  );
};

export default ContactInformation;
