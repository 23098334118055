import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
  IconButton,
  Typography,
  Divider,
  DialogProps,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import Loader from './Loader';

export interface IEditModalProps extends Omit<DialogProps, 'open' | 'title'> {
  title?: string | React.ReactElement;
  children?: React.ReactElement | React.ReactElement[] | string;
  loading?: boolean;
  onCancel?: () => void;
}

const EditModal = ({ title, children, loading, onCancel, ...props }: IEditModalProps) => {
  const theme = useTheme();

  const fetchingCount = useIsFetching();
  const mutatingCount = useIsMutating();

  return (
    <Dialog
      open
      keepMounted={false}
      onClose={onCancel}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        p: 0,
        '& .MuiDialog-paper': {
          width: { mobile: '100%' },
          maxWidth: { mobile: '100%', tablet: theme.breakpoints.values.tablet },
          p: 0,
          pt: 1,
          pb: 2,
        },
        '& .MuiDialogContent-root': {
          p: 0,
          pl: 2,
          pr: 2,
        },
        ...props?.sx,
      }}
    >
      <DialogTitle sx={{ p: 0, pl: 2, pr: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          {typeof title === 'string' ? (
            <Typography variant="body1" fontWeight={600}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <IconButton onClick={onCancel} edge="end" data-testid="close-button" aria-label="Close" sx={{ pt: 0.5 }}>
            <Close sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider light sx={{ mb: 1 }} />
      <DialogContent>{children}</DialogContent>
      <Loader loading={Boolean(fetchingCount) || Boolean(mutatingCount) || loading} />
    </Dialog>
  );
};

export default EditModal;
