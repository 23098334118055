import { TextField as FormikTextField } from 'formik-mui';

import RecommendableField, { IRecommendableFieldProp } from './RecommendableField';
import CustomLabel from '../CustomLabel';

const TextField = ({ label, required, recommended, ...props }: IRecommendableFieldProp) => {
  return (
    <RecommendableField
      {...props}
      component={FormikTextField}
      label={<CustomLabel label={label} required={required} recommended={recommended} />}
      multiline={props?.multiline}
      inputProps={{ ...props.inputProps }}
      sx={{ width: '100%', m: 1, marginLeft: 0, marginRight: 0, ...props.sx }}
    />
  );
};

export default TextField;
