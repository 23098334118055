import { useMemo } from 'react';
import { Stack } from '@mui/material';

import ProfileDataItem, { IProfileDataItemProps } from '../components/ProfileDataItem';

import useLocale from '../../../app/hooks/useLocale';

import {
  useDebtorProfileQuery,
  usePhoneTypesQuery,
  useCanadaProvincesQuery,
  useUsaProvincesQuery,
  useCountriesQuery,
} from '../queries';
import { CANADA_COUNTRY_CODE, USA_COUNTRY_CODE } from '../constants';
import { getCityLine, getAddressLine, getDateOfBirth, getPhoneLine, getNameLine } from '../utils';
import { ClientPublicService } from '../../../app/api/ClientPublicService';

const ProfileDetailsList = () => {
  const { t, getLocalizedDtoName } = useLocale();

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const { data: phoneTypes } = usePhoneTypesQuery() || {};
  const { data: countries } = useCountriesQuery() || {};

  const usaCountryId = useMemo(() => countries?.find((item) => item.code === USA_COUNTRY_CODE)?.id, [countries]);
  const canadaCountryId = useMemo(() => countries?.find((item) => item.code === CANADA_COUNTRY_CODE)?.id, [countries]);

  const { data: canadaProvinces } = useCanadaProvincesQuery(canadaCountryId) || {};
  const { data: usaProvinces } = useUsaProvincesQuery(usaCountryId) || {};

  const address = useMemo(() => {
    const addressPartsArray = [
      getAddressLine(debtorProfile?.profile?.streetName, debtorProfile?.profile?.streetNumber),

      getCityLine(
        debtorProfile?.profile?.city,
        getLocalizedDtoName(
          (debtorProfile?.profile?.countryId === canadaCountryId ? canadaProvinces : usaProvinces)?.find(
            (item: ClientPublicService.LookupDto) => item?.id === debtorProfile?.profile?.provinceId
          )
        ),
        getLocalizedDtoName(countries?.find((item) => item?.id === debtorProfile?.profile?.countryId))
      ),

      debtorProfile?.profile?.postalCode,
    ]?.filter(Boolean);

    return addressPartsArray?.length ? addressPartsArray : ['-'];
  }, [
    canadaCountryId,
    canadaProvinces,
    countries,
    debtorProfile?.profile?.city,
    debtorProfile?.profile?.countryId,
    debtorProfile?.profile?.postalCode,
    debtorProfile?.profile?.provinceId,
    debtorProfile?.profile?.streetName,
    debtorProfile?.profile?.streetNumber,
    getLocalizedDtoName,
    usaProvinces,
  ]);

  const profileListItems: IProfileDataItemProps[] = useMemo(
    () => [
      {
        title: t.NAME,
        content: debtorProfile?.profile
          ? getNameLine(debtorProfile?.profile?.firstName, debtorProfile?.profile?.lastName)
          : undefined,
      },
      {
        title: t.DATE_OF_BIRTH,
        content: debtorProfile?.profile ? getDateOfBirth(debtorProfile?.profile) : undefined,
      },
      {
        title: t.ADDRESS,
        content: debtorProfile?.profile ? address : undefined,
      },
      {
        title: t.PHONE,
        content: debtorProfile?.profile
          ? getPhoneLine(
              debtorProfile?.profile?.mainPhoneNumber,
              getLocalizedDtoName(phoneTypes?.find((item) => item?.id === debtorProfile?.profile?.mainPhoneTypeId))
            )
          : undefined,
      },
      {
        title: t.EMAIL,
        content: debtorProfile?.profile ? debtorProfile?.profile?.emailAddress || '-' : undefined,
      },
    ],

    [
      address,
      debtorProfile?.profile,
      getLocalizedDtoName,
      phoneTypes,
      t.ADDRESS,
      t.DATE_OF_BIRTH,
      t.EMAIL,
      t.NAME,
      t.PHONE,
    ]
  );

  return (
    <Stack data-testid="profile-details">
      {profileListItems?.map((item, index) => (
        <ProfileDataItem key={index} {...item} />
      ))}
    </Stack>
  );
};

export default ProfileDetailsList;
