import React from 'react';

export const FEATUREFLAG_LOGIN_BUTTON = process.env.REACT_APP_FEATUREFLAG_LOGIN_BUTTON === 'true';

interface FeatureFlagProps {
  flag: boolean;
  children: React.ReactNode;
}

const FeatureFlag = ({ flag, children }: FeatureFlagProps): React.ReactElement => {
  return flag ? <>{children}</> : <></>; // Return an empty fragment if flag is false
};

export default FeatureFlag;
