import { Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocale from '../../../app/hooks/useLocale';
import { ROUTES } from '../../../app/routes';

const ProgressAndStatusInitialStep = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  return (
    <>
      <Stack>
        <Typography variant="h6" fontWeight={600} aria-label={t.BOOK_INITIAL_CONSULTATION_TITLE}>
          {t.BOOK_INITIAL_CONSULTATION_TITLE}
        </Typography>

        <Typography variant="body2" sx={{ pb: 3, pt: 3, fontSize: 13 }} textAlign="center">
          {t.PROGRESS_STATUS_INITIALSTEP_DESCRIPTION}
        </Typography>

        <Button
          variant="contained"
          sx={{ alignSelf: 'center', width: 200 }}
          onClick={() => navigate(ROUTES.BOOK_APPOINTMENT)}
        >
          {t.BOOK_NOW}
        </Button>
      </Stack>
    </>
  );
};

export default ProgressAndStatusInitialStep;
