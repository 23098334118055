import { IMaskInput } from 'react-imask';
import { forwardRef, memo } from 'react';

import { TextField as FormikTextField } from 'formik-mui';
import RecommendableField from './RecommendableField';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneMaskComponent = memo(
  forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }),
  (prevProps, nextProps) => prevProps.name === nextProps.name && prevProps.onChange === nextProps.onChange
);

const PhoneField = (props: any) => (
  <RecommendableField
    component={FormikTextField}
    InputProps={{ inputComponent: PhoneMaskComponent as any }}
    inputProps={{ ...props.inputProps }}
    sx={{ width: '100%', m: 1, marginLeft: 0, marginRight: 0 }}
    {...props}
  />
);

export default PhoneField;
