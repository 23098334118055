import { useContext } from 'react';
import { Button, Grid, Box } from '@mui/material';

import { useFormikContext } from 'formik';

import useLocale from '../../app/hooks/useLocale';

import { EditExpenseContext } from './EditExpenseProvider';

import { IFormValues } from './types';

const EditExpenseFooter = ({ onCancel }: { onCancel?: () => void }) => {
  const { t } = useLocale();

  const { tabsCount, currentTab, isEditable } = useContext(EditExpenseContext);

  const { submitForm, isValid } = useFormikContext<IFormValues>();

  return (
    <Box data-testid="expense-footer">
      <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap">
        <Grid item mobile={isEditable ? 6 : 12} tablet={4}>
          <Button onClick={onCancel} variant="outlined" data-testid="cancel-button" fullWidth>
            {isEditable ? t.CANCEL : t.BACK}
          </Button>
        </Grid>
        {isEditable && (
          <Grid item mobile={6} tablet={4}>
            <Button variant="contained" data-testid="ok-button" fullWidth onClick={submitForm} disabled={!isValid}>
              {(currentTab?.orderNumber || 0) === tabsCount - 1 ? t.SAVE : t.NEXT}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EditExpenseFooter;
