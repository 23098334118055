import { useMemo } from 'react';
import { Typography, ButtonBase, Stack, useTheme, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ElevatedContainer from '../../components/ElevatedContainer';

import useLocale from '../../app/hooks/useLocale';

import moment, { DATE_TIME_FORMAT5 } from '../../app/utils/dateTimeHelpers';
import { ROUTES } from '../../app/routes';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const NotificationCard = (props?: ClientPublicService.INotificationDto) => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();

  const filingType = useMemo(() => {
    switch (props?.contentParameters?.FilingType) {
      case 'DIVISION_I':
        return `${t.CONSUMER_PROPOSAL} - ${t.DIVISION_I}`;
      case 'DIVISION_II':
        return `${t.CONSUMER_PROPOSAL} - ${t.DIVISION_II}`;
      case 'SUMMARY':
        return `${t.SUMMARY} ${t.BANKRUPTCY}`;
      case 'ORDINARY':
        return `${t.ORDINARY} ${t.BANKRUPTCY}`;
      default:
        return props?.contentParameters?.FilingType || '';
    }
  }, [
    props?.contentParameters?.FilingType,
    t.BANKRUPTCY,
    t.CONSUMER_PROPOSAL,
    t.DIVISION_I,
    t.DIVISION_II,
    t.ORDINARY,
    t.SUMMARY,
  ]);

  const message = useMemo(() => {
    const text = t?.[props?.messageKey as string] as string;
    switch (props?.messageKey) {
      case 'IncomeExpenseXXDaysReminder_ClientPortal_Body':
        return text
          ?.replace('{NumberOfDays}', props?.contentParameters?.NumberOfDays as string)
          ?.replace('{DueDate}', props?.contentParameters?.DueDate as string);

      case 'IncomeExpenseDueDateReminder_ClientPortal_Body':
        return text?.replace('{DueDate}', props?.contentParameters?.DueDate as string);

      case 'MissedIESubmissionAlert_ClientPortal_Body':
        return text?.replace('{DueDate}', props?.contentParameters?.DueDate as string);

      case 'TaxReturnReminderNotification_ClientPortal_Body':
        return text?.replace('{Year}', props?.contentParameters?.Year as string);

      case 'AdminInfo_Banking_PADInfoChangeRequest_RejectedClientPortal':
        return text?.replace(
          '{RejectionReason}',
          t[props?.contentParameters?.RejectionReason as string] ||
            (props?.contentParameters?.RejectionReason as string)
        );

      case 'AdminInfo_Banking_PADScheduleHoldRequest_RejectedClientPortal':
        return text?.replace(
          '{RejectionReason}',
          t[props?.contentParameters?.RejectionReason as string] ||
            (props?.contentParameters?.RejectionReason as string)
        );

      case 'SignupNotification_ClientPortal':
        return text?.replace('{FilingType}', filingType);

      case 'IncomeExpenseClientPortalComment_ClientPortal':
        return text
          ?.replace('{DueDate}', props?.contentParameters?.DueDate as string)
          ?.replace('{Comment}', props?.contentParameters?.Comment as string);

      case 'DocumentWasMadeVisible_ClientPortal':
        return text?.replace('{DocumentName}', props?.contentParameters?.DocumentName as string);

      case 'AppointmentCancelationNotification_ClientPortal':
        return text
          ?.replace(
            '{AppointmentType}',
            t[props?.contentParameters?.AppointmentType as string] ||
              (props?.contentParameters?.AppointmentType as string)
          )
          ?.replace('{Date}', props?.contentParameters?.Date as string);

      case 'AppointmentDetailUpdated_ClientPortal':
        return text?.replace(
          '{AppointmentType}',
          t[props?.contentParameters?.AppointmentType as string] ||
            (props?.contentParameters?.AppointmentType as string)
        );

      default:
        return text;
    }
  }, [
    filingType,
    props?.contentParameters?.AppointmentType,
    props?.contentParameters?.Comment,
    props?.contentParameters?.Date,
    props?.contentParameters?.DocumentName,
    props?.contentParameters?.DueDate,
    props?.contentParameters?.NumberOfDays,
    props?.contentParameters?.RejectionReason,
    props?.contentParameters?.Year,
    props?.messageKey,
    t,
  ]);

  const redirectUrl = useMemo(() => {
    switch (props?.messageKey) {
      // documents
      case 'DocumentWasMadeVisible_ClientPortal':
        return ROUTES.DOCUMENTS;
      case 'AdminInfo_Banking_PADSummaryUpdated_ClientPortal':
        return ROUTES.DOCUMENTS;

      // appointments
      case 'AppointmentCancelationNotification_ClientPortal':
        return ROUTES.APPOINTMENTS;
      case 'AppointmentDetailUpdated_ClientPortal':
        return ROUTES.APPOINTMENTS;

      // income & expense
      case 'ScheduleUpdatedNotification_ClientPortal_Body':
        return ROUTES.INCOME_EXPENSE_REPORTS;
      case 'IncomeExpenseXXDaysReminder_ClientPortal_Body':
        return ROUTES.INCOME_EXPENSE_REPORTS;
      case 'IncomeExpenseDueDateReminder_ClientPortal_Body':
        return ROUTES.INCOME_EXPENSE_REPORTS;
      case 'MissedIESubmissionAlert_ClientPortal_Body':
        return ROUTES.INCOME_EXPENSE_REPORTS;
      case 'IncomeExpenseClientPortalComment_ClientPortal':
        return ROUTES.INCOME_EXPENSE_REPORTS;

      // tax returns (not available yet)
      // case "TaxReturnReminderNotification_ClientPortal_Body": return ROUTES.TAX_RETURNS;
      // case "TaxReturnMissedNotification_ClientPortal_Body": return ROUTES.TAX_RETURNS;

      //banking
      case 'AdminInfo_Banking_PadInformation_UpdatedClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Banking_PADInfoChangeRequest_ApprovedClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Banking_PADInfoChangeRequest_RejectedClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Banking_PADScheduleHoldRequest_OnHoldClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Banking_PADScheduleHoldRequest_RejectedClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Proposal_ProposalStatePaymentArrear_ClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;
      case 'AdminInfo_Proposal_ProposalStatePaymentWarning_ClientPortal':
        return ROUTES.PAYMENTS_AND_BANKING;

      // dashboard
      case 'SignupNotification_ClientPortal':
        return ROUTES.DASHBOARD;
      case 'AdminInfo_Proposal_ProposalStateAccepted_ClientPortal':
        return ROUTES.DASHBOARD;
      case 'AdminInfo_Proposal_ProposalStateRejected_ClientPortal':
        return ROUTES.DASHBOARD;
      case 'AdminInfo_Proposal_ProposalStateWithdrawn_ClientPortal':
        return ROUTES.DASHBOARD;

      default:
        return ROUTES.DASHBOARD;
    }
  }, [props?.messageKey]);

  return (
    <ButtonContainer onClick={() => navigate(redirectUrl)}>
      <CardContainer
        sx={{
          border: props?.isUnread ? `1px solid ${theme.palette.urgentRed}` : 'none',
        }}
      >
        <Stack gap={1}>
          <Typography variant="body3" fontWeight={600} color="secondary">
            BDO
          </Typography>
          <Typography variant="body2" fontWeight={600}>
            {message}
          </Typography>
          <Typography variant="body3" color="secondary">
            {moment(props?.creationTime)?.format(DATE_TIME_FORMAT5)}
          </Typography>
        </Stack>
      </CardContainer>
    </ButtonContainer>
  );
};

export default NotificationCard;

const CardContainer = styled(ElevatedContainer)(() => ({
  padding: '8px 12px',
  borderRadius: 12,
}));

const ButtonContainer = styled(ButtonBase)(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  fontFamily: theme.typography.fontFamily,
  '& .MuiTouchRipple-root': {
    opacity: 0.1,
    borderRadius: 12,
  },
}));
