import { useEffect } from 'react';
import { Typography, Button, styled, useTheme, Link, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import img from '../../app/assets/images/getty-image.png';
import useLocale from '../../app/hooks/useLocale';
import useAuthService from '../../app/hooks/useAuthService';
import { ROUTES } from '../../app/routes/constants';

const WelcomePage = () => {
  const { t } = useLocale();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthService();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isAuthenticated, navigate]);

  return (
    <StyledWrapper data-testid="initial-page">
      <StyledTitle variant="h1" data-testid="title" color={theme.palette.white}>
        {t.LIVING_DEBTFREE_STARTS_HERE}
      </StyledTitle>

      <StyledSubtitle
        variant="h6"
        data-testid="text"
        color={theme.palette.white}
        aria-label={t.BOOK_YOUR_FREE_NOOBLIGATION_CONSULTATION_BELOW_OR_CALL}
      >
        {t.BOOK_YOUR_FREE_NOOBLIGATION_CONSULTATION_BELOW_OR_CALL}
        <PhoneLink href="tel:1-855-236-3328"> 1-855-BDO DEBT</PhoneLink>
      </StyledSubtitle>

      <Button
        variant="contained"
        data-testid="book-now-button"
        onClick={() => navigate(ROUTES.BOOK_APPOINTMENT)}
        size="large"
        sx={{ minWidth: 200 }}
      >
        {t.BOOK_NOW}
      </Button>
    </StyledWrapper>
  );
};

export default WelcomePage;

const backgroundImage = (percent: number): string =>
  `linear-gradient(35deg, #333333cc 0%, #333333cc ${percent}%, transparent ${percent + 0.1}%, transparent 100%), url(${
    process.env.PUBLIC_URL + img
  })`;

const StyledWrapper = styled(Container)(({ theme }) => ({
  minHeight: 'calc(100vh - 56px)',
  [theme.breakpoints.up('mobile')]: {
    backgroundImage: `${backgroundImage(62)}`,
  },
  [theme.breakpoints.up('tablet')]: {
    backgroundImage: `${backgroundImage(60)}`,
  },
  [theme.breakpoints.up('desktop')]: {
    backgroundImage: `${backgroundImage(45)}`,
  },
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  backgroundSize: 'cover',
  paddingTop: 0,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  maxWidth: 300,
  [theme.breakpoints.down('tablet')]: { maxWidth: 250 },
  lineHeight: 1,
  paddingBottom: 16,
  paddingTop: 40,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: { maxWidth: '100vw' },
  [theme.breakpoints.up('tablet')]: { maxWidth: 400 },
  fontWeight: 500,
  lineHeight: 1.3,
  paddingBottom: 12,
}));

const PhoneLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: theme.palette.white,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
