import { MenuItem, useTheme, CircularProgress, Box, Chip } from '@mui/material';
import { Select } from 'formik-mui';

import CustomLabel from '../CustomLabel';
import RecommendableField from './RecommendableField';

export interface ISelectItem {
  id?: string;
  name?: string;
}

type IFieldProps = any;

interface ISelectField extends IFieldProps {
  options?: ISelectItem[];
  loading?: boolean;
}

const ChipMultiselectField = ({ options, loading, ...props }: ISelectField) => {
  const theme = useTheme();

  return (
    <RecommendableField
      component={Select}
      formControl={{ fullWidth: true, margin: 'dense' }}
      {...props}
      label={<CustomLabel label={props?.label} required={props?.required} recommended={props?.recommended} />}
      type="text"
      multiple
      renderValue={(selected: ISelectItem[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => {
            const option = options?.find((item) => item.id === value);
            return <Chip key={option?.id} label={option?.name} />;
          })}
        </Box>
      )}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1.5 }}>
          <CircularProgress sx={{ color: theme.palette.cobalt }} size={24} />
        </Box>
      ) : (
        options?.map((item: ISelectItem) => (
          <MenuItem key={item?.id + ''} value={item?.id || ''}>
            {item?.name}
          </MenuItem>
        ))
      )}
    </RecommendableField>
  );
};

export default ChipMultiselectField;
