import { useCallback, useMemo } from 'react';
import { useTheme, Grid, useMediaQuery, Box, styled, Button } from '@mui/material';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { Formik } from 'formik';
import yup from '../../app/utils/customYup';
import { toast } from 'react-toastify';

import TableCard from '../../components/TableCard';
import SummaryCard from '../../components/SummaryCard';
import ElevatedContainer from '../../components/ElevatedContainer';
import LabelWithError from '../../components/LabelWithError';
import { NumberField } from '../../components/FormFields';

import useLocale from '../../app/hooks/useLocale';

import { validateGreaterThan, currencyFormatter } from '../../app/utils/helpers';
import { usePreviousSubmissionMutation, useSubmitReportMutation } from './queries';
import {
  getAmountByExpense,
  getAmountByIncome,
  checkIfEditable,
  convertPreviousSubmissionToInitial,
  convertFormValuesToCreateDto,
} from './utils';

import { ROUTES } from '../../app/routes';
import { ClientPublicService } from '../../app/api/ClientPublicService';

const DEFICIT_COLOR = 'red';
const PROFICIT_COLOR = 'green';

const EditIEReportSummary = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { submissionPeriodId } = useParams();
  const navigate = useNavigate();

  const { report, setPreviousSubmission } = useOutletContext<{
    report?: ClientPublicService.IncomeExpenseSubmissionPublicDto;
    setPreviousSubmission: (report?: ClientPublicService.IncomeExpenseSubmissionPublicDto) => void;
  }>();

  const { mutate: copyPreviousSubmission } = usePreviousSubmissionMutation() || {};

  const { mutate: submitReport } = useSubmitReportMutation() || {};

  const totalIncome = report?.incomeItems?.map((item) => getAmountByIncome(item)).reduce((sum, cur) => sum + cur, 0);

  const totalExpense = report?.expenseItems?.map((item) => getAmountByExpense(item)).reduce((sum, cur) => sum + cur, 0);

  const isEditable = useMemo(() => checkIfEditable(report), [report]);

  const isAnyMissingDocuments = useMemo(
    () =>
      Boolean(report?.incomeItems?.some((item) => item.isMissingDocuments)) ||
      Boolean(report?.expenseItems?.some((item) => item.isMissingDocuments)),
    [report]
  );

  const isSaveRequiredForSubmission = useMemo(() => Boolean(report?.isChangeRequired), [report?.isChangeRequired]);

  const handleEditIncomeButtonClick = useCallback(() => {
    navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}/${ROUTES.IE_REPORT_INCOME}`);
  }, [navigate, submissionPeriodId]);

  const handleEditExpensesButtonClick = useCallback(() => {
    navigate(`${ROUTES.IE_REPORT}/${submissionPeriodId}/${ROUTES.IE_REPORT_EXPENSE}`);
  }, [navigate, submissionPeriodId]);

  const handleCopyPrevious = useCallback(() => {
    copyPreviousSubmission(submissionPeriodId as string, {
      onSuccess: (response) => {
        if (response === null) {
          toast.error(t.NO_PREVIOUS_MONTH);
        } else {
          setPreviousSubmission(convertPreviousSubmissionToInitial(response));
        }
      },
    });
  }, [copyPreviousSubmission, setPreviousSubmission, submissionPeriodId, t.NO_PREVIOUS_MONTH]);

  const incomesColumns = [
    {
      title: t.TYPE,
      key: 'incomeType',
      dataIndex: 'incomeType',
      render: (item: ClientPublicService.LookupDto, record: ClientPublicService.ExpenseSubmissionItemPublicDto) => (
        <LabelWithError label={getLocalizedDtoName(item)} hasError={record?.isMissingDocuments} />
      ),
    },
    {
      title: t.AMOUNT,
      key: 'amount',
      dataIndex: 'amount',
      render: (_: any, record: ClientPublicService.IncomeSubmissionItemPublicDto) =>
        currencyFormatter(getAmountByIncome(record)),
      width: '40%',
    },
  ];

  const expensesColumns = [
    {
      title: t.DESCRIPTION,
      key: 'expenseDescription',
      dataIndex: 'expenseDescription',
      render: (_: string, record: ClientPublicService.ExpenseSubmissionItemPublicDto) => (
        <LabelWithError label={getLocalizedDtoName(record?.expenseDescription)} hasError={record?.isMissingDocuments} />
      ),
    },
    {
      title: t.AMOUNT,
      key: 'amount',
      dataIndex: 'amount',
      render: (_: any, record: ClientPublicService.ExpenseSubmissionItemPublicDto) =>
        currencyFormatter(getAmountByExpense(record)),
      width: '40%',
    },
  ];

  const validateNotZero = useCallback((value?: number) => validateGreaterThan(value, 0), []);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        numberOfHouseHold: yup
          .number()
          .test(
            'greater-than-zero',
            t.SHOULD_BE_GREATER_THAN.replace('{0}', t.PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT).replace(
              '{1}',
              '0'
            ),
            validateNotZero
          ),
      }),
    [t.PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT, t.SHOULD_BE_GREATER_THAN, validateNotZero]
  );

  const CardContainer = isDesktop ? RoundedCornersContainer : Box;

  const balance = useMemo(() => (totalIncome || 0) - (totalExpense || 0), [totalIncome, totalExpense]);

  return (
    <>
      <CardContainer>
        <SummaryCard
          items={[
            {
              label: t.MONTHLY_INCOME,
              value: totalIncome,
              labelColor: PROFICIT_COLOR,
            },
            {
              label: t.MONTHLY_EXPENSE,
              value: totalExpense,
              labelColor: DEFICIT_COLOR,
            },
            {
              label: balance >= 0 ? t.BUDGET_SURPLUS : t.BUDGET_DEFICIT,
              value: Math.abs(balance),
              labelColor: balance >= 0 ? PROFICIT_COLOR : DEFICIT_COLOR,
            },
          ]}
        />
      </CardContainer>
      <CardContainer>
        <Grid container rowSpacing={{ mobile: 1.5, desktop: 2 }} columnSpacing={{ mobile: 0, desktop: 4 }}>
          <Grid item mobile={12} desktop>
            <TableCard
              title={t.INCOME}
              hasEditButton={isEditable}
              onEditButtonClick={handleEditIncomeButtonClick}
              hasViewButton={!isEditable}
              onViewButtonClick={handleEditIncomeButtonClick}
              tableProps={{
                data: Boolean(totalIncome)
                  ? report?.incomeItems?.filter((item) => Boolean(getAmountByIncome(item)))
                  : [],
                columns: incomesColumns,
                emptyValue: t.CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_INCOME,
                onRowClick: handleEditIncomeButtonClick,
              }}
            />
          </Grid>
          <Grid item mobile={12} desktop>
            <TableCard
              title={t.EXPENSES}
              hasEditButton={isEditable}
              onEditButtonClick={handleEditExpensesButtonClick}
              hasViewButton={!isEditable}
              onViewButtonClick={handleEditExpensesButtonClick}
              tableProps={{
                data: Boolean(totalExpense)
                  ? report?.expenseItems?.filter((item) => Boolean(getAmountByExpense(item)))
                  : [],
                columns: expensesColumns,
                emptyValue: t.CLICK_ON_THE_EDIT_BUTTON_ABOVE_TO_ADD_YOUR_EXPENSES,
                onRowClick: handleEditExpensesButtonClick,
              }}
            />
          </Grid>
        </Grid>
      </CardContainer>

      <Formik
        initialValues={{ numberOfHouseHold: report?.numberOfHouseHold || 1 }}
        enableReinitialize
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        validateOnMount
        validateOnChange
        validateOnBlur
      >
        {({ isValid, setFieldValue, values }) => (
          <>
            <RoundedCornersContainer>
              <NumberField
                name="numberOfHouseHold"
                label={t.PERSONS_IN_HOUSEHOLD}
                required
                onChange={(e: any) => {
                  const value = e?.target?.value;
                  setFieldValue('numberOfHouseHold', value, true);
                }}
                disabled={!isEditable}
              />
            </RoundedCornersContainer>

            <Grid container flexDirection="row" gap={2} flex={1} justifyContent="center" wrap="nowrap" pt={2} pb={4}>
              {isEditable ? (
                <>
                  <Grid item mobile="auto" tablet={4}>
                    <Button onClick={handleCopyPrevious} variant="outlined" fullWidth>
                      {t.COPY_PREVIOUS_MONTH}
                    </Button>
                  </Grid>

                  <Grid item mobile tablet={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        const updated = convertFormValuesToCreateDto({
                          ...report,
                          numberOfHouseHold: values?.numberOfHouseHold,
                        } as ClientPublicService.IncomeExpenseSubmissionPublicDto);
                        submitReport(updated as ClientPublicService.IncomeExpenseSubmissionCreatePublicDto, {
                          onSuccess: () => {
                            navigate(ROUTES.INCOME_EXPENSE_REPORTS, { replace: true });
                          },
                        });
                      }}
                      disabled={!isValid || isAnyMissingDocuments || !totalIncome || isSaveRequiredForSubmission}
                    >
                      {t.SUBMIT}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item mobile={12} tablet={4}>
                  <Button
                    onClick={() => navigate(ROUTES.INCOME_EXPENSE_REPORTS, { replace: true })}
                    variant="outlined"
                    fullWidth
                  >
                    {t.BACK}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
};

export default EditIEReportSummary;

const RoundedCornersContainer = styled(ElevatedContainer)(() => ({
  borderRadius: 12,
}));
