import { IPaymentsSearchParams, IPaymentsCountSearchParams } from './types';

import moment, { DATE_FORMAT, convertValueToISOString } from '../../app/utils/dateTimeHelpers';

export const argumentifyPaymentsSearchParams = ({
  fileId,
  receiptDateFrom,
  receiptDateTo,
  isDeposited,
  sorting,
  skipCount,
  maxResultCount,
}: IPaymentsSearchParams) =>
  [fileId, receiptDateFrom, receiptDateTo, isDeposited, sorting, skipCount, maxResultCount] as const;

export const argumentifyCountPaymentsSearchParams = ({
  receiptDateFrom,
  receiptDateTo,
  isDeposited,
  sorting,
  skipCount,
  maxResultCount,
}: IPaymentsCountSearchParams) =>
  [receiptDateFrom, receiptDateTo, isDeposited, sorting, skipCount, maxResultCount] as const;

export const isUpcoming = (date?: moment.Moment) => {
  if (!date) return undefined;
  const dueDate = moment(convertValueToISOString(date, DATE_FORMAT));
  const today = moment(convertValueToISOString(moment(), DATE_FORMAT));
  return dueDate.isAfter(today, 'd');
};

export const isPast = (date?: moment.Moment) => {
  if (!date) return undefined;
  const dueDate = moment(convertValueToISOString(date, DATE_FORMAT));
  const today = moment(convertValueToISOString(moment(), DATE_FORMAT));
  return dueDate.isBefore(today, 'd');
};
