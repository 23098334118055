import { createContext, useState } from 'react';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import useLocale from '../hooks/useLocale';
import { ILocale } from '../providers/LocaleProvider/types';

interface IModalContext {
  showModal: (modal: React.ReactElement) => void;
  closeModal: () => void;
  modal: React.ReactElement | null;
}

export const defaultModalContext: IModalContext = {
  showModal: () => {},
  closeModal: () => {},
  modal: null,
};

export const ModalContext = createContext<IModalContext>(defaultModalContext);

interface IProps {
  children: React.ReactElement;
}

const ModalProvider = ({ children }: IProps): React.ReactElement => {
  const { locale } = useLocale();
  const [modal, setModal] = useState<React.ReactElement | null>(null);

  const showModal = (modal: React.ReactElement) => setModal(modal);

  const closeModal = () => setModal(null);

  return (
    <LocalizationProvider adapterLocale={locale === ILocale.en ? 'en-ca' : 'fr-ca'} dateAdapter={AdapterMoment}>
      <ModalContext.Provider value={{ showModal, closeModal, modal }}>{children}</ModalContext.Provider>
    </LocalizationProvider>
  );
};

export default ModalProvider;
