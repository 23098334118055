import { useEffect, useContext } from 'react';
import { useFormikContext } from 'formik';

import { TextField, SelectField, CurrencyField, ChipMultiselectField } from '../../../../../../components/FormFields';

import useLocale from '../../../../../../app/hooks/useLocale';

import { useAssetsTypesQuery, useOwnershipTypesQuery, useAssetsDescriptionsQuery } from '../../queries';
import { IAssetFormValues } from './types';
import { EditAssetModalContext } from './EditAssetModalProvider';

import { ClientPublicService } from '../../../../../../app/api/ClientPublicService';

const BasicTab = () => {
  const { t } = useLocale();
  const { values, setFieldValue } = useFormikContext<IAssetFormValues>();
  const { assetTypeEnum, setAssetDescriptionEnum, setAssetTypeEnum, isOther, isLocked } =
    useContext(EditAssetModalContext);

  const { data: assetsTypes, isLoading: isLoadingAssetsTypes } = useAssetsTypesQuery() || {};
  const { data: ownershipTypes, isLoading: isLoadingOwnershipTypes } = useOwnershipTypesQuery() || {};
  const { data: assetDescriptions, isLoading: isLoadingDescriptions } =
    useAssetsDescriptionsQuery(values?.assetTypeId) || {};

  useEffect(() => {
    const assetTypeEnum = assetsTypes?.find((item) => item.id === values?.assetTypeId)?.enumValue;
    setAssetTypeEnum(assetTypeEnum);
  }, [assetsTypes, setAssetTypeEnum, values?.assetTypeId]);

  useEffect(() => {
    const assetDescriptionEnum = assetDescriptions?.find((item) => item.id === values?.assetDescriptionId)?.enumValue;
    setAssetDescriptionEnum(assetDescriptionEnum);
  }, [assetDescriptions, setAssetDescriptionEnum, values?.assetDescriptionId]);

  return (
    <>
      <SelectField
        name="assetTypeId"
        label={t.TYPE}
        data-testid="assetTypeId"
        required
        options={assetsTypes}
        loading={isLoadingAssetsTypes}
        onChange={() => {
          setFieldValue('assetDescriptionId', '');
        }}
        disabled={isLocked}
      />

      <SelectField
        name="assetDescriptionId"
        label={t.DESCRIPTION}
        data-testid="assetDescriptionId"
        required
        options={assetDescriptions}
        loading={isLoadingDescriptions}
        disabled={!values?.assetTypeId || isLocked}
      />

      {isOther && (
        <TextField
          name="specifiedDescription"
          label={t.OTHER_SPECIFY}
          data-testid="specifiedDescription"
          inputProps={{ maxLength: 500 }}
          required
          disabled={isLocked}
        />
      )}

      <CurrencyField
        name="value"
        label={
          assetTypeEnum === ClientPublicService.AssetTypeEnum.InvestmentsOrPolicies
            ? t.ASSETS_CURRENT_MARKET_VALUE
            : assetTypeEnum === ClientPublicService.AssetTypeEnum.RealEstate ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.MotorVehicles ||
              assetTypeEnum === ClientPublicService.AssetTypeEnum.RecreationalEquipment
            ? t.ASSETS_ESTIMATED_RESALE_VALUE
            : t.VALUE
        }
        data-testid="value"
        required
        disabled={isLocked}
      />

      <SelectField
        name="ownershipTypeId"
        label={t.OWNERSHIP}
        data-testid="ownershipTypeId"
        options={ownershipTypes}
        loading={isLoadingOwnershipTypes}
        disabled={isLocked}
      />

      <TextField
        name="comments"
        label={t.COMMENTS}
        data-testid="comments"
        multiline
        minRows={3}
        maxRows={3}
        inputProps={{ maxLength: 200 }}
        disabled={isLocked}
      />

      {Boolean(values?.securedToDebts?.length) && (
        <ChipMultiselectField
          name="securedToIds"
          options={values?.securedToDebts?.map((item) => ({ name: item.displayName, id: item.id }))}
          label={t.SECURED_TO}
          disabled
        />
      )}
    </>
  );
};

export default BasicTab;
