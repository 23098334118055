import { useCallback } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import CustomToggleButton from '../../../components/CustomToggleButton';
import moment, { MEETING_TIME_FORMAT } from '../../../app/utils/dateTimeHelpers';

import useLocale from '../../../app/hooks/useLocale';

interface ITimeslotGroupProps {
  title: string;
  timeslots?: moment.Moment[];
  onTimeslotSelect?: (timeslot: moment.Moment) => void;
  selectedTimeslot?: moment.Moment;
  scheduledAppointmentTimeslot?: moment.Moment;
}
const TimeslotGroup = ({
  title,
  timeslots,
  onTimeslotSelect,
  selectedTimeslot,
  scheduledAppointmentTimeslot,
}: ITimeslotGroupProps) => {
  const { getLocalizedDate } = useLocale();

  const handleTimeslotSelect = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: moment.Moment) => {
      if (onTimeslotSelect) onTimeslotSelect(value);
    },
    [onTimeslotSelect]
  );

  if (!timeslots || !timeslots?.length) return <></>;

  return (
    <Stack direction="column" alignItems="center" sx={{ paddingBottom: 2 }}>
      <Typography variant="body2" fontWeight={500} sx={{ paddingBottom: 2 }}>
        {title}
      </Typography>
      <Grid container display="flex" flexDirection="row" spacing={2}>
        {timeslots?.map((item, i) => (
          <Grid item mobile={6} tablet={4} desktop={4} key={`timeslot-item-${i}`}>
            <CustomToggleButton
              fullWidth
              value={item}
              onClick={handleTimeslotSelect}
              selected={selectedTimeslot?.isSame(item)}
              disabled={scheduledAppointmentTimeslot?.isSame(item)}
            >
              {getLocalizedDate(item, MEETING_TIME_FORMAT)}
            </CustomToggleButton>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default TimeslotGroup;
