import { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';

import ElevatedContainer from '../../../../components/ElevatedContainer';
import BankingInfo from './components/BankingInfo';
import UnsavedForm from '../../components/UnsavedForm/UnsavedForm';
import ErrorAndWarningList from '../../components/ErrorAndWarningList';

import useLocale from '../../../../app/hooks/useLocale';
import useAppForm from '../../hooks/useAppForm';

import {
  SupportDocsUpload,
  useDocumentsUploadMutation,
  IFormValuesDocumentItem,
} from '../../../../components/DocumentsUpload';
import {
  useAppFormSupportingDocumentsQuery,
  useFoldersQuery,
  usePrefixesQuery,
} from '../../../../components/DocumentsUpload/queries';

import { useAppFormBakingInfoQuery, useUpdateBankingInfoMutation } from './queries';
import { getBankingInfoValues, convertFormValuesToAppFormBankingInfoDto } from './utils';
import { IFormValuesBankingInfo, IAppFormBankingInfoDetail } from './types';
import { useApplicationFileQuery } from '../../queries';
import { ROUTES } from '../../../../app/routes';
import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import SectionLockMessage from '../../components/SectionLockMessage';
import { AppformSection } from '../../../../components/AppFormProgress/components/ProgressBar';
import NavigationButtons from '../../components/NavigationButtons';

export const PERSONAL_DETAILS_PANEL = 'banking-information-panel';

const BANKING_DOCUMENTS_FOLDER_CODE = 'banking';

const BANKING_DOCUMENTS_PREFIX_CODES = ['Banking.Information.PreAuth-Form', 'Banking.Void-Cheque'];

const Banking = () => {
  const { t, getLocalizedDtoName } = useLocale();
  const navigate = useNavigate();
  const { appFormStatus, updateAppFormStatus } = useAppForm() || {};
  const [showErrorList, setShowErrorsList] = useState(false);

  const { data: appFile } = useApplicationFileQuery();

  const { data: bankingInfo, isSuccess } = useAppFormBakingInfoQuery(appFile?.id) || {};

  const { data: folders } = useFoldersQuery() || {};

  const documentFolderId = useMemo(
    () => folders?.find((folder) => folder.code === BANKING_DOCUMENTS_FOLDER_CODE)?.id,
    [folders]
  );

  const { data: prefixes } = usePrefixesQuery({ documentFolderId, prefixCodes: BANKING_DOCUMENTS_PREFIX_CODES }) || {};

  const { data: documents, isSuccess: isSuccessDocuments } =
    useAppFormSupportingDocumentsQuery(
      {
        documentFolderId,
        documentPrefixCodes: BANKING_DOCUMENTS_PREFIX_CODES,
        documentIds: bankingInfo?.documentIds,
      },
      true
    ) || {};

  const { mutateAsync: uploadDocuments } = useDocumentsUploadMutation() || {};

  const { mutateAsync: updateBankingInfo } = useUpdateBankingInfoMutation() || {};

  const initialValues = useMemo(
    () =>
      getBankingInfoValues(
        bankingInfo,
        documents?.items?.map(
          (item) =>
            ({
              ...item,
              documentPrefixName: getLocalizedDtoName(prefixes?.find((prefix) => prefix.id === item?.documentPrefixId)),
            } as ClientPublicService.DocumentDto)
        )
      ),
    [bankingInfo, documents?.items, getLocalizedDtoName, prefixes]
  );

  const isFormStarted = useMemo(
    () => appFormStatus?.bankingInformationStatus !== ClientPublicService.AppFormStatusEnum.NotStarted,
    [appFormStatus?.bankingInformationStatus]
  );

  const getRecommendedByLabel = useCallback(
    (fieldName: string) => t.FIELD_IS_RECOMMENDED.replace('{0}', fieldName),
    [t.FIELD_IS_RECOMMENDED]
  );

  const handleSubmit = useCallback(
    async (values: IFormValuesBankingInfo, onSuccess?: () => void) => {
      const newDocumentsIds = await uploadDocuments({
        fileId: appFile?.id as string,
        files: values?.documents as IFormValuesDocumentItem[],
      });

      updateBankingInfo(
        {
          id: bankingInfo?.id as string,
          fileId: appFile?.id as string,
          ...convertFormValuesToAppFormBankingInfoDto(values),
          documentIds: [...(values?.documentIds || []), ...newDocumentsIds] as string[],
        },
        {
          onSuccess: () => {
            updateAppFormStatus?.();
            toast.success(t.MODULE_SAVED_SUCCESSFULLY?.replace('{0}', t.BANKING_INFORMATION));

            if (onSuccess) {
              onSuccess();
            } else {
              navigate(`${ROUTES.APP_FORM}/${ROUTES.QUESTIONNAIRE}`);
            }
          },
          onError: (response: any) => {
            if (response?.error?.validationErrors?.[0]?.message && t[response?.error?.validationErrors?.[0]?.message]) {
              toast.error(t[response?.error?.validationErrors?.[0]?.message]);
            } else {
              toast.error(t.SOMETHING_WENT_WRONG);
            }
          },
        }
      );
    },
    [appFile?.id, bankingInfo?.id, navigate, t, updateAppFormStatus, updateBankingInfo, uploadDocuments]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        details: yup.array().of(
          yup.object().shape({
            institution: yup.string().recommended(getRecommendedByLabel(t.INSTITUTION)),
            accountNumber: yup.string().recommended(getRecommendedByLabel(t.ACCOUNT_NUMBER)),
          })
        ),
        documents: yup
          .array()
          .when(['details'], ([details], schema) =>
            details?.map((item?: IAppFormBankingInfoDetail) => item?.institution)?.filter(Boolean)?.length === 0 ||
            !appFormStatus ||
            appFormStatus?.bankingInformationStatus === ClientPublicService.AppFormStatusEnum.Complete
              ? schema
              : schema.min(1, t.SUPPORTING_DOCUMENTS_ARE_REQUIRED)
          ),
      }),
    [getRecommendedByLabel, t.INSTITUTION, t.ACCOUNT_NUMBER, t.SUPPORTING_DOCUMENTS_ARE_REQUIRED, appFormStatus]
  );

  const isLocked = useMemo(
    () =>
      appFile?.isLocked ||
      appFile?.isAppFormLocked ||
      appFormStatus?.bankingInformationStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFile?.isAppFormLocked, appFile?.isLocked, appFormStatus?.bankingInformationStatus]
  );

  useEffect(() => {
    setShowErrorsList(isFormStarted && isSuccess && !isLocked && isSuccessDocuments);
  }, [isFormStarted, isLocked, isSuccess, isSuccessDocuments]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(_: IFormValuesBankingInfo, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validateOnMount={showErrorList}
        validateOnChange
        validateOnBlur={showErrorList}
        validationSchema={validationSchema}
        data-testid="banking-information-form"
      >
        {({ submitForm, values }) => (
          <UnsavedForm onSubmit={handleSubmit}>
            {showErrorList ? <ErrorAndWarningList disabled={isLocked} /> : <></>}

            <RoundedCornersContainer>
              {isLocked && <SectionLockMessage />}

              <BankingInfo disabled={isLocked} />

              <Box
                sx={{
                  height: Boolean(values?.details?.find((item) => item?.institution)) ? 'auto' : 0,
                  visibility: Boolean(values?.details?.find((item) => item?.institution)) ? 'visible' : 'hidden',
                }}
              >
                <SupportDocsUpload
                  tooltip={[
                    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED,
                    t.PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM,
                  ]}
                  message={t.IF_YOU_HAVE_YOUR_DOCUMENTS_READY_CLICK_ON_THE_UPLOAD_BUTTON}
                  documentFolderCode={BANKING_DOCUMENTS_FOLDER_CODE}
                  documentPrefixCodes={BANKING_DOCUMENTS_PREFIX_CODES}
                  disabled={isLocked}
                  onlyRetrieveByDocumentIds={true}
                  documentIds={values?.documentIds}
                />
              </Box>
            </RoundedCornersContainer>

            <NavigationButtons
              onNext={async () => {
                if (isLocked) {
                  navigate(`${ROUTES.APP_FORM}/${ROUTES.QUESTIONNAIRE}`);
                } else {
                  await submitForm();
                  handleSubmit(values, () => {
                    navigate(`${ROUTES.APP_FORM}/${ROUTES.QUESTIONNAIRE}`);
                  });
                }
              }}
              onPrev={async () => {
                if (isLocked) {
                  navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_TAX}`);
                } else {
                  await submitForm();
                  handleSubmit(values, () => {
                    navigate(`${ROUTES.APP_FORM}/${ROUTES.INCOME_TAX}`);
                  });
                }
              }}
              currentSection={AppformSection.BankingInfo}
              disabled={isLocked}
            />
          </UnsavedForm>
        )}
      </Formik>
    </>
  );
};

export default Banking;

const RoundedCornersContainer = styled(ElevatedContainer)(() => ({
  borderRadius: 12,
}));
