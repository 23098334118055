import { useQuery } from '@tanstack/react-query';
import { PHONE_TYPES_QUERY_KEY } from '../../../app/constants/reactQueryKeys';

import API from '../../../app/api/api';

export const usePhoneTypesQuery = () =>
  useQuery({
    queryKey: [PHONE_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listPhoneTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });
