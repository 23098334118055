import * as yup from 'yup';

yup.addMethod(yup.string, 'recommended', function (message: string) {
  return this.required(`[recommended]${message}`);
});

yup.addMethod(yup.date, 'recommended', function (message: string) {
  return this.nullable().required(`[recommended]${message}`);
});

yup.addMethod(yup.number, 'recommended', function (message: string) {
  return this.required(`[recommended]${message}`);
});

yup.addMethod(yup.bool, 'recommended', function (message: string) {
  return this.required(`[recommended]${message}`);
});

export default yup;
