import { useMemo } from 'react';
import { Typography, Stack, Divider } from '@mui/material';

import useLocale from '../../../../../app/hooks/useLocale';
import useAppForm from '../../../hooks/useAppForm';

import { YesNoToggleField } from '../../../../../components/FormFields';
import { ClientPublicService } from '../../../../../app/api/ClientPublicService';

const CreditBureauConsent = () => {
  const { t } = useLocale();
  const { appFileData, appFormStatus } = useAppForm() || {};

  const isLocked = useMemo(
    () =>
      appFileData?.isLocked ||
      appFileData?.isAppFormLocked ||
      appFormStatus?.questionnaireStatus === ClientPublicService.AppFormStatusEnum.Complete,
    [appFileData?.isLocked, appFileData?.isAppFormLocked, appFormStatus?.questionnaireStatus]
  );

  return (
    <Stack>
      <Typography variant="body2" fontWeight={600}>
        {t.CONSENT_TEXT}
      </Typography>
      <YesNoToggleField label={t.I_UNDERSTAND} name="isConsentChecked" disabled={isLocked} />
      <Divider sx={{ mt: 2 }} />
    </Stack>
  );
};

export default CreditBureauConsent;
