import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import { IAppFormBankingInfoDetail, IFormValuesBankingInfo } from './types';

const getAppFormBankingInfoDetailDto = (data?: ClientPublicService.IAppFormBankingInfoDetailDto) =>
  ({
    id: data?.id || '',
    institution: data?.institution || '',
    accountNumber: data?.accountNumber || '',
    comments: data?.comments || '',
    isOverdraftOwed: typeof data?.isOverdraftOwed === 'boolean' ? data?.isOverdraftOwed : undefined,
    isJointAccount: typeof data?.isJointAccount === 'boolean' ? data?.isJointAccount : undefined,
  } as IAppFormBankingInfoDetail);

const getBankingInfoValues = (
  data?: ClientPublicService.IAppFormBankingInfoDto,
  documents?: ClientPublicService.DocumentDto[]
) =>
  ({
    id: data?.id || '',
    fileId: data?.fileId || '',
    isMarried: data?.isMarried || false,
    details:
      !data?.details || data?.details?.length === 0
        ? [getAppFormBankingInfoDetailDto()]
        : data?.details?.map((item) => getAppFormBankingInfoDetailDto(item)),
    spouseDetails:
      (!data?.spouseDetails || data?.spouseDetails?.length === 0) && data?.isMarried
        ? [getAppFormBankingInfoDetailDto()]
        : data?.spouseDetails?.map((item) => getAppFormBankingInfoDetailDto(item)),
    documentIds: data?.documentIds || [],
    documents,
  } as IFormValuesBankingInfo);

const convertFormValuesToAppFormBankingInfoDetailDto = (data?: IAppFormBankingInfoDetail) =>
  ({
    id: data?.id || undefined,
    institution: data?.institution || undefined,
    accountNumber: data?.accountNumber || undefined,
    comments: data?.comments || undefined,
    isOverdraftOwed: typeof data?.isOverdraftOwed === 'boolean' ? data?.isOverdraftOwed : undefined,
    isJointAccount: typeof data?.isJointAccount === 'boolean' ? data?.isJointAccount : undefined,
  } as ClientPublicService.IAppFormBankingInfoDetailDto);

const convertFormValuesToAppFormBankingInfoDto = (data?: IFormValuesBankingInfo) =>
  ({
    id: data?.id || undefined,
    fileId: data?.fileId || undefined,
    isMarried: data?.isMarried || undefined,
    details: data?.details?.map((item) => convertFormValuesToAppFormBankingInfoDetailDto(item)) || [],
    spouseDetails: data?.spouseDetails?.map((item) => convertFormValuesToAppFormBankingInfoDetailDto(item)) || [],
    documentIds: data?.documentIds || [],
  } as ClientPublicService.IAppFormBankingInfoDto);

export {
  getBankingInfoValues,
  getAppFormBankingInfoDetailDto,
  convertFormValuesToAppFormBankingInfoDto,
  convertFormValuesToAppFormBankingInfoDetailDto,
};
