import { useQuery, useMutation } from '@tanstack/react-query';

import {
  QUESTIONNAIRE_QUERY_KEY,
  BUSINESS_TYPES_QUERY_KEY,
  BUSINESS_OWNERSHIP_TYPES_QUERY_KEY,
} from '../../../../app/constants/reactQueryKeys';
import { queryClient } from '../../../../app/config';

import { ClientPublicService } from '../../../../app/api/ClientPublicService';
import API from '../../../../app/api/api';

export const useQuestionnaireQuery = (fileId?: string) =>
  useQuery({
    queryKey: [QUESTIONNAIRE_QUERY_KEY, fileId],
    queryFn: async () => {
      const data = await API.getAppFormQuestionnaire(fileId);

      return data;
    },
    enabled: !!fileId,
    refetchOnWindowFocus: false,
  });

export const useBusinessTypesQuery = () =>
  useQuery({
    queryKey: [BUSINESS_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listBusinessTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useBusinessOwnershipTypesQuery = () =>
  useQuery({
    queryKey: [BUSINESS_OWNERSHIP_TYPES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listBusinessOwnershipTypes();
      return data;
    },
    refetchOnWindowFocus: false,
  });

export const useUpdateQuestionnaireMutation = () =>
  useMutation({
    mutationFn: (questionnaire: ClientPublicService.IAppFormQuestionnaireDto) =>
      API.updateAppFormQuestionnaire(questionnaire as ClientPublicService.AppFormQuestionnaireDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUESTIONNAIRE_QUERY_KEY] }),
  });
