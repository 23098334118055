import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';

export enum AppformSection {
  PersonalInfo = 1,
  AssetDebts = 2,
  IncomeExpense = 3,
  IncomeTax = 4,
  BankingInfo = 5,
  Questionnaire = 6,
}

const ProgressBar = ({ section, activeSection }: any) => {
  const theme = useTheme();
  const boxColor = useMemo(() => {
    if (section === activeSection) return theme.palette.cobalt;
    else if (section > activeSection) return theme.palette.background.default;
    else return theme.palette.blueBar;
  }, [section, activeSection, theme.palette.cobalt, theme.palette.blueBar, theme.palette.background.default]);

  return (
    <Box
      height={6}
      width="16.5%"
      m={0.3}
      sx={{ borderRadius: '25px', backgroundColor: boxColor } as React.CSSProperties}
    ></Box>
  );
};

export default ProgressBar;
