import { useQuery } from '@tanstack/react-query';
import {
  CANADA_PROVINCES_QUERY_KEY,
  USA_PROVINCES_QUERY_KEY,
  COUNTRIES_QUERY_KEY,
} from '../../../app/constants/reactQueryKeys';

import API from '../../../app/api/api';

export const useCanadaProvincesQuery = (canadaCountryId?: string) =>
  useQuery({
    queryKey: [CANADA_PROVINCES_QUERY_KEY, canadaCountryId],
    queryFn: async () => {
      const data = await API.listProvinces(canadaCountryId);
      return data;
    },
    enabled: !!canadaCountryId,
    refetchOnWindowFocus: false,
  });

export const useUsaProvincesQuery = (usaCountryId?: string) =>
  useQuery({
    queryKey: [USA_PROVINCES_QUERY_KEY, usaCountryId],
    queryFn: async () => {
      const data = await API.listProvinces(usaCountryId);
      return data;
    },
    enabled: !!usaCountryId,
    refetchOnWindowFocus: false,
  });

export const useCountriesQuery = () =>
  useQuery({
    queryKey: [COUNTRIES_QUERY_KEY],
    queryFn: async () => {
      const data = await API.listCountries();
      return data;
    },
    refetchOnWindowFocus: false,
  });
