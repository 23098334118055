import { Grid, useMediaQuery, useTheme } from '@mui/material';

import ElevatedContainer from '../../../components/ElevatedContainer';

interface IResponsiveGridLayoutProps {
  items: React.ReactElement[];
}

const ResponsiveGridLayoutPaymentBanking = ({ items }: IResponsiveGridLayoutProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  return (
    <Grid container columns={1} spacing={2} sx={{ maxWidth: 600 + 24, pb: 4 }}>
      {items?.map((item, index) => (
        <Grid item mobile={1} tablet={1} desktop={1} key={`responsive-grid-item-${index}`}>
          <ElevatedContainer
            sx={{
              borderRadius: 1.5,
              m: 0,
              height: '100%',
              pt: 3,
              pb: 3,
              maxWidth: isDesktop ? 600 : 'auto',
            }}
          >
            {item}
          </ElevatedContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default ResponsiveGridLayoutPaymentBanking;
