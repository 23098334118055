import { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { List, Menu, MenuItem, useTheme, Box, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import AppFormMenuItem, { IAppFormMenuItem } from './AppFormMenuItem';
import UnsavedAppFormChangesModal from '../../features/AppForm/components/UnsavedAppFormChangesModal';

import useLocale from '../../app/hooks/useLocale';
import useModal from '../../app/hooks/useModal';

import { ROUTES } from '../../app/routes/constants';
import eventBus from '../../app/utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT, CHECK_IF_UNSAVED_CHANGES_EVENT } from '../../app/constants/eventBusKeys';
import { useAppFormStatusQuery, useApplicationFileQuery } from '../../features/AppForm/queries';

const PERSONAL_INFORMATION_KEY = 'personalInformation';
const ASSETS_DEBTS_KEY = 'assetsDebts';
const INCOME_EXPENSE_KEY = 'incomeExpense';
const INCOME_TAX_KEY = 'incomeTax';
const BANKING_INFORMATION_KEY = 'bankingInformation';
const QUESTIONNAIRE_KEY = 'questionnaire';

const AppFormMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useLocale();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const { showModal, closeModal } = useModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<IAppFormMenuItem>();

  const { data: appFileData } = useApplicationFileQuery() || {};

  const { data } = useAppFormStatusQuery(appFileData?.id) || {};

  const open = Boolean(anchorEl);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const setItemAndNavigate = useCallback(
    (item: IAppFormMenuItem) => {
      setSelected(item);

      if (item?.appModuleRoute) {
        navigate(`${ROUTES.APP_FORM}/${item?.appModuleRoute}`);
      }
    },
    [navigate]
  );

  const isTrackChangesAppFormRoute = useMemo(
    () =>
      selected?.id === PERSONAL_INFORMATION_KEY ||
      selected?.id === INCOME_TAX_KEY ||
      selected?.id === BANKING_INFORMATION_KEY ||
      selected?.id === QUESTIONNAIRE_KEY,
    [selected?.id]
  );

  const handleMenuItemClick = useCallback(
    (item: IAppFormMenuItem) => {
      setAnchorEl(null);
      if (item?.id === selected?.id) return;

      if (isTrackChangesAppFormRoute) {
        eventBus.dispatch(CHECK_IF_UNSAVED_CHANGES_EVENT, {
          onCheckSuccess: (isChangesUnsaved?: boolean) => {
            if (isChangesUnsaved) {
              showModal(
                <UnsavedAppFormChangesModal
                  onLeave={() => {
                    setItemAndNavigate(item);
                    closeModal();
                  }}
                  onSaveAndLeave={() => {
                    eventBus.dispatch(APPFORM_SAVE_AND_LEAVE_EVENT, {
                      onSaveSuccess: () => {
                        setItemAndNavigate(item);
                        closeModal();
                      },
                    });
                  }}
                />
              );
            } else {
              setItemAndNavigate(item);
            }
          },
        });
      } else {
        setItemAndNavigate(item);
      }
    },
    [closeModal, isTrackChangesAppFormRoute, selected, setItemAndNavigate, showModal]
  );

  const menu: IAppFormMenuItem[] = useMemo(
    () => [
      {
        id: PERSONAL_INFORMATION_KEY,
        appModuleName: t.PERSONAL_INFORMATION,
        appModuleStatus: data?.personalInfoStatus,
        appModuleRoute: ROUTES.PERSONAL_INFORMATION,
      },
      {
        id: ASSETS_DEBTS_KEY,
        appModuleName: t.ASSETS_DEBTS,
        appModuleStatus: data?.assetsDebtsStatus,
        appModuleRoute: ROUTES.ASSETS_DEBTS,
      },
      {
        id: INCOME_EXPENSE_KEY,
        appModuleName: t.INCOME_EXPENSE,
        appModuleStatus: data?.incomeExpenseStatus,
        appModuleRoute: ROUTES.INCOME_EXPENSE,
      },
      {
        id: INCOME_TAX_KEY,
        appModuleName: t.INCOME_TAX,
        appModuleStatus: data?.incomeTaxStatus,
        appModuleRoute: ROUTES.INCOME_TAX,
      },
      {
        id: BANKING_INFORMATION_KEY,
        appModuleName: t.BANKING_INFORMATION,
        appModuleStatus: data?.bankingInformationStatus,
        appModuleRoute: ROUTES.BANKING,
      },
      {
        id: QUESTIONNAIRE_KEY,
        appModuleName: t.QUESTIONNAIRE,
        appModuleStatus: data?.questionnaireStatus,
        appModuleRoute: ROUTES.QUESTIONNAIRE,
      },
    ],
    [
      data?.assetsDebtsStatus,
      data?.bankingInformationStatus,
      data?.incomeExpenseStatus,
      data?.incomeTaxStatus,
      data?.personalInfoStatus,
      data?.questionnaireStatus,
      t.ASSETS_DEBTS,
      t.BANKING_INFORMATION,
      t.INCOME_EXPENSE,
      t.INCOME_TAX,
      t.PERSONAL_INFORMATION,
      t.QUESTIONNAIRE,
    ]
  );

  useEffect(() => {
    const match = menu?.find((item) =>
      matchPath({ path: `${ROUTES.APP_FORM}/${item?.appModuleRoute}` }, location.pathname)
    );
    setSelected(match);
  }, [location.pathname, menu]);

  if (isDesktop) {
    return (
      <List sx={{ maxWidth: 250 }} disablePadding data-testid="desktop-app-form-menu" component="div">
        {menu?.map((item, index) => (
          <AppFormMenuItem
            key={index}
            appModuleName={item?.appModuleName}
            appModuleStatus={item?.appModuleStatus}
            onClick={() => handleMenuItemClick(item)}
            sx={{
              backgroundColor: item?.id === selected?.id ? theme.palette.secondLevelMenuBckg : 'transparent',
              borderRadius: 1.5,
            }}
          />
        ))}
      </List>
    );
  }

  return (
    <Box sx={{ pb: 2.5 }} data-testid="mobile-app-form-menu">
      <List
        component="div"
        disablePadding
        sx={{
          '.MuiListItemButton-root:hover': {
            backgroundColor: theme.palette.white,
          },
          '.MuiMenu-paper': {
            right: 16,
          },
        }}
      >
        <AppFormMenuItem
          aria-expanded={open ? 'true' : undefined}
          appModuleName={selected?.appModuleName}
          appModuleStatus={selected?.appModuleStatus}
          onClick={handleOpen}
          sx={{
            borderRadius: 1.5,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
          }}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </AppFormMenuItem>
      </List>
      <Menu
        id="app-form-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            '.MuiMenuItem-root': {
              p: 0,
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              right: 16,
              p: 0,
            },
          },
        }}
      >
        {menu?.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)} divider={index !== menu.length - 1}>
              <AppFormMenuItem appModuleName={item?.appModuleName} appModuleStatus={item?.appModuleStatus} />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default AppFormMenu;
