import { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

import { IAppointmentDetailsUpdate, IUserLocation } from './types';
import { IBookAppointmentContextProps } from './hooks/useBookAppointment';
import moment from 'moment';

const USER_LOCATION_SESSION_STORAGE_KEY = 'userLocation';
const APPOINTMENT_DETAILS_SESSION_STORAGE_KEY = 'appointmentDetails';

const BookAppointment = () => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const [userLocation, setUserLocation] = useState<IUserLocation>(() => {
    const savedUserLocation = sessionStorage.getItem(USER_LOCATION_SESSION_STORAGE_KEY);
    if (savedUserLocation) {
      const parsed = JSON.parse(savedUserLocation as string);
      return parsed;
    }
  });

  const [appointmentDetails, setAppointmentDetails] = useState<IAppointmentDetailsUpdate | undefined>(() => {
    const savedAppointmentDetails = sessionStorage.getItem(APPOINTMENT_DETAILS_SESSION_STORAGE_KEY);
    if (savedAppointmentDetails) {
      const parsed = JSON.parse(savedAppointmentDetails as string);
      return { ...parsed, dateTime: parsed?.dateTime ? moment(parsed?.dateTime) : undefined };
    }
  });

  useEffect(() => {
    if (userLocation) {
      sessionStorage.setItem(USER_LOCATION_SESSION_STORAGE_KEY, JSON.stringify(userLocation));
    } else {
      sessionStorage.removeItem(USER_LOCATION_SESSION_STORAGE_KEY);
    }
  }, [userLocation]);

  useEffect(() => {
    if (appointmentDetails) {
      sessionStorage.setItem(APPOINTMENT_DETAILS_SESSION_STORAGE_KEY, JSON.stringify(appointmentDetails));
    } else {
      sessionStorage.removeItem(APPOINTMENT_DETAILS_SESSION_STORAGE_KEY);
    }
  }, [appointmentDetails]);

  const resetAppointmentDetails = useCallback((value?: IAppointmentDetailsUpdate) => {
    setAppointmentDetails(value);
  }, []);

  const updateAppointmentDetails = useCallback((value?: IAppointmentDetailsUpdate) => {
    setAppointmentDetails((prev) => ({ ...prev, ...value }));
  }, []);

  return (
    <Outlet
      context={
        {
          userLocation,
          setUserLocation,
          appointmentDetails,
          resetAppointmentDetails,
          updateAppointmentDetails,
          isConfirmed,
          setIsConfirmed,
        } as IBookAppointmentContextProps
      }
    />
  );
};

export default BookAppointment;
