import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

import useLocale from '../app/hooks/useLocale';

interface IProps {
  title: string;
  message: string | React.ReactElement;
  hasOkButton?: boolean;
  okButtonText?: string | React.ReactElement;
  onOk?: () => void;
  hasCancelButton?: boolean;
  cancelButtonText?: string;
  onCancel?: () => void;
  open?: boolean;
  isOkButtonDisabled?: boolean;
}

const ConfirmationModal = ({
  title,
  message,
  hasOkButton = true,
  okButtonText,
  onOk,
  hasCancelButton = true,
  cancelButtonText,
  onCancel,
  open = true,
  isOkButtonDisabled = false,
}: IProps) => {
  const { t } = useLocale();
  return (
    <Dialog
      open={open}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          borderRadius: '20px 0',
          maxWidth: { mobile: '100%', tablet: 400 },
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flex="1" gap={2} p={2}>
          {hasCancelButton ? (
            <Button onClick={onCancel} variant="outlined" data-testid="cancel-button">
              {cancelButtonText || t.CANCEL}
            </Button>
          ) : null}
          {hasOkButton ? (
            <Button onClick={onOk} variant="contained" data-testid="ok-button" disabled={isOkButtonDisabled}>
              {okButtonText || t.OK2}
            </Button>
          ) : null}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
