import { useCallback } from 'react';
import { Button, Stack, Typography, useTheme, styled } from '@mui/material';

import CustomMenuIcon from './CustomMenuIcon';
import exitIcon from '../../../app/assets/icons/exit-icon.svg';

import useAuthService from '../../../app/hooks/useAuthService';
import useLocale from '../../../app/hooks/useLocale';

import { useDebtorProfileQuery } from '../queries';

const SignOutButton = () => {
  const { signout } = useAuthService();
  const theme = useTheme();
  const { t } = useLocale();

  const { data: debtorProfile } = useDebtorProfileQuery() || {};

  const handleSignOut = useCallback(() => {
    signout();
  }, [signout]);

  return (
    <SignoutButton
      variant="outlined"
      data-testid="sign-out-button"
      onClick={handleSignOut}
      endIcon={<CustomMenuIcon src={exitIcon} alt="sign-out-icon" />}
      fullWidth
    >
      <Stack sx={{ flex: 1 }} alignItems="start">
        <Typography variant="body2" fontWeight="bold">
          {t.SIGN_OUT}
        </Typography>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: 170, textAlign: 'left' }}>
          <Typography variant="body2" color={theme.palette.slate} noWrap>
            {`${debtorProfile?.profile?.firstName || ''} ${debtorProfile?.profile?.lastName || ''}`}
          </Typography>
        </div>
      </Stack>
    </SignoutButton>
  );
};

export default SignOutButton;

const SignoutButton = styled(Button)(({ theme }) => ({
  marginTop: 32,
  '&.MuiButton-root': {
    borderColor: theme.palette.paleCharcoal,
    '&:hover, &:active': {
      backgroundColor: theme.palette.paleCharcoal,
    },
  },
}));
